import { CellType } from 'config/cells';
import { deleteEventImpacts } from 'reduxStore/actions/eventMutations';
import { selectSingleCell } from 'reduxStore/reducers/pageSlice';
import { AppThunk } from 'reduxStore/store';
import {
  getSelectedTimelineCellEventsByMonthKey,
  selectedTimelineCellSelector,
} from 'selectors/selectedTimelineCellSelector';
import { pageSelectionSelector as prevailingSelectionSelector } from 'selectors/selectionSelector';

export const timelineCellNavigate =
  (direction: 'up' | 'down'): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    const timelineCell = selectedTimelineCellSelector(state);
    const selection = prevailingSelectionSelector(state);
    const blockId = selection != null && 'blockId' in selection ? selection.blockId : undefined;
    if (selection?.type !== 'eventsAndGroups' || timelineCell == null || blockId == null) {
      return;
    }

    const { rowKey, columnKey, type } = timelineCell.activeCell;

    if (type === CellType.ImpactDriver) {
      if (direction === 'up') {
        dispatch(
          selectSingleCell({
            blockId: selection.blockId,
            cellRef: {
              columnKey,
              type: CellType.Impact,
              rowKey: { type: 'driver', driverId: rowKey.driverId },
            },
          }),
        );
      }
    }

    if (type === CellType.Impact) {
      if (rowKey.type === 'objectField') {
        // unsupported
        return;
      }
      if (direction === 'down') {
        dispatch(
          selectSingleCell({
            blockId: selection.blockId,
            cellRef: {
              columnKey,
              type: CellType.ImpactDriver,
              rowKey: { driverId: rowKey.driverId },
            },
          }),
        );
      }
    }
  };

export const roadmapCellDeleteSelected = (): AppThunk => (dispatch, getState) => {
  const state = getState();

  const selectedEventsByMonthKey = getSelectedTimelineCellEventsByMonthKey(state);

  const deleteInputs = Object.entries(selectedEventsByMonthKey).map(([monthKey, event]) => ({
    eventId: event.id,
    monthKey,
  }));

  dispatch(deleteEventImpacts(deleteInputs));
};
