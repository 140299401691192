import { matchSorter } from 'match-sorter';

import { isNotNull } from 'helpers/typescript';

const DASH_REGEX = /-/g;

function searchWithMatchSorter<T extends { id: string } & NullableRecord<string, string>>(
  items: T[],
  searchKeys: string[],
  query: string,
) {
  if (query.length === 0) {
    return items;
  }

  return matchSorter(items, query.replace(DASH_REGEX, ' '), {
    keys: [
      (item) => {
        const matches = searchKeys.map((k) => (k in item ? item[k] : undefined)).filter(isNotNull);
        return matches.map((match) => match.replace(DASH_REGEX, ' ')).join(' ');
      },
    ],
  });
}

interface Opts<T> {
  query: string;
  items: T[];
  searchKeys: string[];
  maxSearchResults?: number;
  defaultResults?: T[];
}

export function useListSearch<T extends { id: string }>(opts: Opts<T>) {
  const { query, items, searchKeys, maxSearchResults, defaultResults } = opts;

  if (defaultResults != null && (query == null || query.length === 0)) {
    return { results: defaultResults };
  }

  const matches = searchWithMatchSorter(items, searchKeys, query);

  const results =
    maxSearchResults == null || maxSearchResults > matches.length
      ? matches
      : matches.slice(0, maxSearchResults);

  return { results };
}
