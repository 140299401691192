import { CloseIcon, UpDownIcon } from '@chakra-ui/icons';
import { useContext, useMemo } from 'react';

import { ContextMenuItem } from 'components/ContextMenuItems/ContextMenuItem';
import DriverDimensionsMenu from 'components/DriverDimensionsMenu/DriverDimensionsMenu';
import DriverFormatSelectMenu from 'components/DriverFormatSelectMenu/DriverFormatSelectMenu';
import MoveToSubmodelMenu from 'components/MoveToSubmodelMenu/MoveToSubmodelMenu';
import { DriverCellRef } from 'config/cells';
import { DriverGridContext } from 'config/driverGridContext';
import { DRIVER_FORMAT_ICONS, DRIVER_FORMAT_NAMES } from 'config/drivers';
import { DriverFormat, LeverType } from 'generated/graphql';
import { isRunwayEmployee } from 'helpers/user';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useOpenDetailViewContextMenu from 'hooks/useOpenDetailViewContextMenuItem';
import useRecalculateSelected from 'hooks/useRecalculateSelected';
import { triggerCopilotRequest } from 'reduxStore/actions/copilot';
import { deleteSelected } from 'reduxStore/actions/deleteSelected';
import { updateSelectedDriversLeverType } from 'reduxStore/actions/driverMutations';
import { removeSelectedDriversFromBlock } from 'reduxStore/actions/driverReferenceMutations';
import { duplicateSelectedDrivers } from 'reduxStore/actions/duplicateSelectedDrivers';
import { groupSelected } from 'reduxStore/actions/groupSelected';
import { BlockId } from 'reduxStore/models/blocks';
import { PaneType } from 'reduxStore/reducers/detailPaneSlice';
import { CopilotOperation } from 'reduxStore/reducers/pageSlice';
import { isCurrentPageDatabasePageSelector } from 'selectors/blocksPagesSelector';
import { blockConfigBusinessObjectSpecIdSelector } from 'selectors/blocksSelector';
import { isUsingBackendCalculatorSelector } from 'selectors/calculationEngineSelector';
import { driverSelectionLeverTypeSelector } from 'selectors/driverSelectionFacetSelector';
import { driverSelector } from 'selectors/driversSelector';
import {
  driverDecimalPlacesSelector,
  driverDisplayConfigurationSelector,
} from 'selectors/entityDisplayConfigurationSelector';
import { loggedInUserSelector } from 'selectors/loginSelector';
import { isCurrentPageWritableSelector } from 'selectors/pageAccessResourcesSelector';
import { isViewingUnlistedDriversPageSelector } from 'selectors/pageSelector';
import { numSelectedDriversSelector } from 'selectors/prevailingCellSelectionSelector';
import { Calculator } from 'vectors';
import DimensionIcon from 'vectors/Dimension';
import DuplicateIcon from 'vectors/Duplicate';
import Group from 'vectors/Group';
import Sparkles from 'vectors/Sparkles';
import Star from 'vectors/Star';
import TrashIcon from 'vectors/Trash';

const EDIT_SECTION = 'edit';
const DISPLAY_SECTION = 'display';
const ORGANIZE_SECTION = 'organize';
const CALC_SECTION = 'calculate';
const REMOVE_SECTION = 'remove';

export default function useDriverContextMenuItems(
  cellRef: DriverCellRef,
  blockId: BlockId,
): ContextMenuItem[] {
  const { driverId = '', layerId } = cellRef.rowKey;
  const { hasCreateButton, hasDimensionsButton, canDelete, canCreateDriverGroup } =
    useContext(DriverGridContext);
  const canEdit = layerId == null;
  const currentUser = useAppSelector(loggedInUserSelector);
  const isEmployee = isRunwayEmployee(currentUser);
  const dispatch = useAppDispatch();
  const isPageWritable = useAppSelector(isCurrentPageWritableSelector);
  const isViewingDatabaseDriverGrid = useAppSelector(
    (state) =>
      isCurrentPageDatabasePageSelector(state) &&
      blockConfigBusinessObjectSpecIdSelector(state, blockId) != null,
  );

  const format = useAppSelector(
    // Explicitly check if the format is not set and show "Auto" instead of
    // using the default value returned by the driverDisplayConfigurationSelector.
    (state) => driverSelector(state, { id: driverId })?.format ?? DriverFormat.Auto,
  );
  const displayConfiguration = useAppSelector((state) =>
    driverDisplayConfigurationSelector(state, driverId),
  );

  const driverDecimalPlaces = useAppSelector((state) =>
    driverDecimalPlacesSelector(state, driverId),
  );

  const numSelectedDrivers = useAppSelector(numSelectedDriversSelector);
  const commonLeverType = useAppSelector(driverSelectionLeverTypeSelector);
  const isViewingUnlistedDriversPage = useAppSelector(isViewingUnlistedDriversPageSelector);
  const isUsingBackendCalculator = useAppSelector(isUsingBackendCalculatorSelector);

  const openDriverDetailPaneOption = useOpenDetailViewContextMenu({
    type: PaneType.Driver,
    id: driverId,
  });

  const recalculateSelected = useRecalculateSelected(blockId);
  const items = useMemo(() => {
    const { currency } = displayConfiguration;
    const allItems: ContextMenuItem[] = isPageWritable
      ? [
          ...(openDriverDetailPaneOption ? [openDriverDetailPaneOption] : []),
          {
            text: `Duplicate ${numSelectedDrivers > 1 ? 'all' : ''}`,
            icon: <DuplicateIcon />,
            sectionId: EDIT_SECTION,
            multiSelect: true,
            isEdit: true,
            isHidden: !hasCreateButton,
            onSelect: () => {
              dispatch(duplicateSelectedDrivers({ blockId }));
            },
            shortcut: 'duplicate',
          },
          {
            text: 'Format',
            icon: DRIVER_FORMAT_ICONS[format],
            meta: DRIVER_FORMAT_NAMES[format],
            sectionId: DISPLAY_SECTION,
            multiSelect: true,
            isEdit: true,
            onSelect: null,
            submenu: () => (
              <DriverFormatSelectMenu
                driverId={driverId}
                cellRef={cellRef}
                currency={currency}
                decimalPlaces={driverDecimalPlaces}
                format={format}
              />
            ),
          },
          {
            text: `${commonLeverType === LeverType.Kpi ? 'Unmark' : 'Mark'} as KPI`,
            icon: <Star />,
            sectionId: DISPLAY_SECTION,
            multiSelect: true,
            isEdit: true,
            onSelect: () => {
              const newLeverType =
                commonLeverType === LeverType.Kpi ? LeverType.Unknown : LeverType.Kpi;
              dispatch(updateSelectedDriversLeverType(newLeverType));
            },
          },
          {
            text: 'Dimensions',
            icon: <DimensionIcon />,
            sectionId: EDIT_SECTION,
            multiSelect: true,
            isEdit: true,
            isHidden: !hasCreateButton,
            onSelect: null,
            submenu: () => (
              <DriverDimensionsMenu driverId={driverId} hasDimensionsButton={hasDimensionsButton} />
            ),
          },
          {
            text: 'Group',
            icon: <Group />,
            sectionId: ORGANIZE_SECTION,
            multiSelect: true,
            isEdit: true,
            isHidden: !canCreateDriverGroup,
            onSelect: () => {
              dispatch(groupSelected());
            },
            shortcut: 'group',
          },
          {
            text: 'Auto Group',
            icon: <Sparkles />,
            sectionId: ORGANIZE_SECTION,
            multiSelect: true,
            isEdit: true,
            keepOpenOnSelect: true,
            isHidden: !canCreateDriverGroup || numSelectedDrivers < 2,
            onSelect: () => {
              dispatch(triggerCopilotRequest({ operation: CopilotOperation.GroupDrivers }));
            },
          },
          {
            text: 'Move to',
            icon: <UpDownIcon />,
            sectionId: ORGANIZE_SECTION,
            multiSelect: true,
            isEdit: true,
            onSelect: null,
            isHidden: isViewingDatabaseDriverGrid,
            submenu: () => <MoveToSubmodelMenu />,
          },
          {
            text: `Re-calculate values`,
            icon: <Calculator />,
            sectionId: CALC_SECTION,
            multiSelect: true,
            isEdit: true,
            isHidden: !(isEmployee && isUsingBackendCalculator),
            onSelect: recalculateSelected,
          },
          {
            text: `Remove from block`,
            icon: <CloseIcon />,
            sectionId: REMOVE_SECTION,
            multiSelect: true,
            isEdit: true,
            destructive: true,
            isHidden: isViewingUnlistedDriversPage || isViewingDatabaseDriverGrid,
            onSelect: () => {
              dispatch(removeSelectedDriversFromBlock());
            },
            shortcut: 'delete',
          },
          {
            text: 'Delete',
            icon: <TrashIcon />,
            sectionId: REMOVE_SECTION,
            multiSelect: true,
            isEdit: true,
            isShown: canDelete || isViewingUnlistedDriversPage,
            destructive: true,
            onSelect: () => {
              dispatch(deleteSelected({ shift: !isViewingUnlistedDriversPage }));
            },
            shortcut: isViewingUnlistedDriversPage ? 'delete' : 'shiftDelete',
          },
        ]
      : [...(openDriverDetailPaneOption ? [openDriverDetailPaneOption] : [])];

    return allItems
      .filter((item) => numSelectedDrivers < 2 || item.multiSelect)
      .filter((item) => canEdit || !item.isEdit);
  }, [
    displayConfiguration,
    isPageWritable,
    openDriverDetailPaneOption,
    numSelectedDrivers,
    hasCreateButton,
    format,
    commonLeverType,
    canCreateDriverGroup,
    isEmployee,
    isUsingBackendCalculator,
    recalculateSelected,
    isViewingUnlistedDriversPage,
    isViewingDatabaseDriverGrid,
    canDelete,
    dispatch,
    blockId,
    driverId,
    cellRef,
    driverDecimalPlaces,
    hasDimensionsButton,
    canEdit,
  ]);

  return items;
}
