import { Box, BoxProps, Flex, TypographyProps, useDisclosure } from '@chakra-ui/react';
import { ReactNode, useEffect, useState } from 'react';

import { ChangeStatus } from '@features/CompareScenarios/compareLayers';
import { BadgeTheme } from 'config/badge';
import RawArrowIcon from 'vectors/Arrowhead';

type TextProps = {
  fontSize?: TypographyProps['fontSize'];
  fontWeight?: string;
  color?: string;
};

export const ToggleSection = ({
  heading,
  textProps = { fontSize: 'sm', fontWeight: '600', color: 'gray.600' },
  isDefaultOpen = false,
  isToggleable = true,
  showToggle = true,
  showBorderTop = false,
  showBorderBottom = false,
  isNotRenderedUntilOpen = false,
  sectionProps = {},
  toggleClassName,
  children,
}: {
  heading: ReactNode;
  fontSize?: TypographyProps['fontSize'];
  color?: string;
  textProps?: TextProps;
  fontWeight?: string;
  isDefaultOpen?: boolean;
  isToggleable?: boolean;
  showToggle?: boolean;
  showBorderTop?: boolean;
  showBorderBottom?: boolean;
  isNotRenderedUntilOpen?: boolean;
  sectionProps?: Partial<BoxProps>;
  toggleClassName?: string;
  children: ReactNode;
}) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: isDefaultOpen });

  const [hasRendered, setHasRendered] = useState(isOpen);
  useEffect(() => {
    if (isOpen) {
      setHasRendered(true);
    }
  }, [isOpen]);

  return (
    <Box
      borderTop={showBorderTop ? '1px solid' : undefined}
      borderBottom={showBorderBottom ? '10px solid' : undefined}
      borderColor="gray.300"
    >
      <Box>
        <Flex
          alignItems="center"
          cursor={isToggleable ? 'pointer' : undefined}
          onClick={isToggleable ? onToggle : undefined}
          padding="2"
          borderRadius={3}
          _hover={{ backgroundColor: isToggleable ? 'gray.100' : undefined }}
          className={toggleClassName}
        >
          {showToggle ? (
            <RawArrowIcon
              boxSize={2}
              color={isToggleable ? 'gray.600' : 'gray.400'}
              direction={isOpen ? 'down' : 'right'}
              marginRight="2"
            />
          ) : null}
          {typeof heading === 'string' ? <Box {...textProps}>{heading}</Box> : heading}
        </Flex>
      </Box>
      <Box
        paddingLeft="4"
        overflowX="visible"
        display={isOpen ? undefined : 'none'}
        {...sectionProps}
      >
        {(!isNotRenderedUntilOpen || hasRendered) && children}
      </Box>
    </Box>
  );
};

export const STATUS_TO_COLOR: Record<ChangeStatus, BadgeTheme> = {
  created: 'blue',
  updated: 'yellow',
  deleted: 'red',
};
