import { Flex } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import React, { useContext, useMemo } from 'react';

import AttributeBadge from 'components/AttributeBadge/AttributeBadge';
import DataSourceLogo from 'components/DataSourceLogo/DataSourceLogo';
import DimensionBadge from 'components/Formula/DimensionBadge';
import DateRangePill from 'components/FormulaInput/DateRangePill';
import FormulaSelectionContext from 'components/FormulaInput/FormulaSelectionContext';
import ReferencePill from 'components/FormulaInput/ReferencePill';
import { isAttribute } from 'helpers/dimensionalDrivers';
import useAppSelector from 'hooks/useAppSelector';
import useFormulaPillMouseDown from 'hooks/useFormulaPillMouseDown';
import { dimensionsByIdSelector } from 'selectors/dimensionsSelector';
import { DraftDecoratorComponentProps, ExtQueryRefMetadata } from 'types/formula';

const ExtQueryDecorator: React.FC<DraftDecoratorComponentProps> = ({
  contentState,
  entityKey,
  offsetKey,
}) => {
  const { activeEntity, keysInSelection } = useContext(FormulaSelectionContext);
  const dimById = useAppSelector(dimensionsByIdSelector);
  const entity = contentState.getEntity(entityKey ?? '');
  const { label, source, attributeFilters, dateRangeDisplay, error } =
    entity.getData() as ExtQueryRefMetadata;
  const isSelectedEntity = activeEntity?.key === entityKey;
  const onNameMouseDown = useFormulaPillMouseDown(entityKey, entity);
  const onDateMouseDown = useFormulaPillMouseDown(entityKey, entity, { submenu: 'timePeriod' });

  const areAttributesFiltersEmpty = useMemo(
    () => isEmpty(attributeFilters) || Object.values(attributeFilters).every(isEmpty),
    [attributeFilters],
  );
  if (entityKey == null) {
    return null;
  }

  const attributes = areAttributesFiltersEmpty ? null : (
    <Flex columnGap="2px">
      {Object.entries(attributeFilters!).flatMap(([dimId, attrs]) =>
        attrs.map((attr) =>
          isAttribute(attr) ? (
            <AttributeBadge key={attr.id} attribute={attr} size="xs" />
          ) : (
            <DimensionBadge key={dimId} dimension={dimById[dimId]} type={attr} />
          ),
        ),
      )}
    </Flex>
  );

  const isSelected = isSelectedEntity || keysInSelection.has(entityKey);
  return (
    <ReferencePill
      onMouseDown={onNameMouseDown}
      isSelected={isSelected}
      error={error}
      label={label}
      offsetKey={offsetKey}
      attributes={attributes}
      icon={source != null ? <DataSourceLogo source={source} size="xs" /> : undefined}
    >
      {dateRangeDisplay != null && (
        <DateRangePill
          onMouseDown={onDateMouseDown}
          label={dateRangeDisplay}
          isSelected={isSelected}
        />
      )}
    </ReferencePill>
  );
};

export default ExtQueryDecorator;
