import { datadogLogs } from '@datadog/browser-logs';

import { isUserBrowser } from 'helpers/environment';
import { PaneType } from 'reduxStore/reducers/detailPaneSlice';

export type PerfMonitorDetail = {
  id?: string;
  pageType?: string;
  paneType?: PaneType;
  entityId?: string;
};

export const START_PAGE_NAVIGATION = 'start-page-navigation';
export const START_DETAIL_PANE_OPEN = 'start-detail-pane-open';
export const START_FORMULA_OPEN = 'start-formula-open';

export const startPageNavigationPerfMonitor = (detail: PerfMonitorDetail) => {
  performance.mark(START_PAGE_NAVIGATION, { detail });
};

export const startDetailPaneOpenPerfMonitor = (detail: PerfMonitorDetail) => {
  performance.mark(START_DETAIL_PANE_OPEN, { detail });
};

export const startFormulaOpenPerfMonitor = () => {
  performance.mark(START_FORMULA_OPEN);
};

export function recordCalculationPerformance(elapsedTime: number, totalRequests: number) {
  const reportData = { elapsedTime, totalRequests };

  if (isUserBrowser()) {
    datadogLogs.logger.info('Calculation measurement', reportData);
  }
}
