import { isNotNull } from 'helpers/typescript';

export interface NameDeduper {
  dedupe: (name: string) => string;
  add: (name: string) => void;
}

const COPY_REGEX = / Copy ?\d*$/;
/**
 * @param name Any string that may or may not already be appended with a `Copy`
 *    suffix slug (e.g. Copy`, `Copy 2`)
 * @param otherNames Other strings of the same variety as `name`.
 * @returns A new string with the `Copy N` suffix added with an appropriate value for N.
 */
export function getNameWithCopySuffix(name: string, otherNames: string[]): string {
  const nameSansCopySuffix = name.replace(COPY_REGEX, '');
  const dedupeName = nameSansCopySuffix.length === 0 ? 'Copy' : `${nameSansCopySuffix} Copy`;
  return newNameDeduper([name, ...otherNames]).dedupe(dedupeName);
}

export function newNameDeduper(names: string[]): NameDeduper {
  const lowerCaseSet = new Set(names.map((s) => s.toLowerCase()));
  return {
    dedupe: (name: string) => {
      const lowerCase = name.toLowerCase();
      if (!lowerCaseSet.has(lowerCase)) {
        return name;
      }
      let i = 2;
      while (lowerCaseSet.has(`${lowerCase} ${i}`)) {
        i++;
      }

      return `${name} ${i}`;
    },
    add: (name: string) => lowerCaseSet.add(name.toLowerCase()),
  };
}

export function getNewDefaultName(defaultName: string, nameSet: Set<string>): string {
  if (!nameSet.has(defaultName)) {
    return defaultName;
  }

  let i = 2;
  while (nameSet.has(`${defaultName} ${i}`)) {
    i++;
  }

  return `${defaultName} ${i}`;
}

export function getInitialsFromName(name: string): string {
  const allInitials = name
    .trim()
    .split(/\s+/)
    .map((part) => (part.length > 0 ? part[0].toUpperCase() : null))
    .filter(isNotNull);
  const firstInitial = allInitials[0] ?? '';
  return allInitials.length >= 2
    ? `${firstInitial}${allInitials[allInitials.length - 1]}`
    : firstInitial;
}

export function checkNameIsUniqueCaseInsensitive(name: string, allNames: string[]) {
  const sanitizedName = name.toLowerCase().trim();
  return !allNames.some((n) => n.toLowerCase() === sanitizedName);
}
