import { produce } from 'immer';
import { isEqual, uniq, uniqBy } from 'lodash';
import keyBy from 'lodash/keyBy';
import noop from 'lodash/noop';
import partition from 'lodash/partition';
import xor from 'lodash/xor';
import { DateTime } from 'luxon';

import { objectGridColumnDefsSelector } from 'components/AgGridComponents/selectors/gridSelectors';
import { OBJECT_TABLE_BLOCK_UNDEFINED_OPTION_ID_PLACEHOLDER } from 'config/block';
import { BusinessObjectFieldCellRef, CellRef, CellSelection, DriverCellRef } from 'config/cells';
import {
  ACTUALS_FORMULA_COLUMN_TYPE,
  COLUMN_TYPE_TO_DEFAULT_WIDTH,
  FORECAST_FORMULA_COLUMN_TYPE,
  INITIAL_VALUE_COLUMN_TYPE,
  ModelViewColumnType,
  NAME_COLUMN_TYPE,
  PROPERTY_COLUMN_TYPE,
} from 'config/modelView';
import {
  BlockConfig,
  BlockCreateInput,
  BlockDeleteInput,
  BlockGroupByType,
  BlockRow,
  BlockSortType,
  BlockType,
  BlockUpdateInput,
  BlockViewOptions,
  DatasetMutationInput,
  DriverUpdateInput,
  DynamicDate,
  FileMetadata,
  ImpactSortExtension,
  ObjectSortExtension,
  ObjectSpecDisplayAsType,
  RollupType,
} from 'generated/graphql';
import { getUpdatedBlocksPageLayout } from 'helpers/blocks';
import {
  isBusinessObjectPropertyFieldCellRef,
  isDriverCellRef,
  isStickyColumnKey,
} from 'helpers/cells';
import { getISOTimeWithoutMs } from 'helpers/dates';
import { getNameWithCopySuffix } from 'helpers/naming';
import { computeSortIndexUpdates } from 'helpers/reorderList';
import {
  alignDate,
  alignDates,
  alignDatesWithRollupOverride,
  unitForRollupType,
} from 'helpers/rollups';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import { deleteBlocksUpdateBlocksPagesMutations } from 'reduxStore/actions/blocksPageMutations';
import {
  blockDeletionDriverReferenceMutations,
  blockDuplicateDriverReferenceMutations,
} from 'reduxStore/actions/driverReferenceMutations';
import { loadCompareBlocksDataAsync } from 'reduxStore/actions/namedVersions';
import {
  getMutationThunkAction,
  submitAutoLayerizedMutations,
  submitMutation,
} from 'reduxStore/actions/submitDatasetMutation';
import {
  trackAddMediaLink,
  trackCreateBlock,
  trackMoveBlock,
  trackToggleBlockHeader,
  trackUploadFile,
} from 'reduxStore/actions/trackEvent';
import { BlockId } from 'reduxStore/models/blocks';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import { DriverPropertyId } from 'reduxStore/models/collections';
import { DEFAULT_LAYER_ID } from 'reduxStore/models/layers';
import { isDataColumnType } from 'reduxStore/reducers/helpers/submodels';
import { isBarChart, isMultiDriverChart } from 'reduxStore/reducers/helpers/viewOptions';
import { endLiveEditing } from 'reduxStore/reducers/liveEditSlice';
import { clearSelection, setAutoFocus, setSelectedCells } from 'reduxStore/reducers/pageSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { AppThunk } from 'reduxStore/store';
import {
  blocksPageSelector,
  currentBlocksPageSelector,
  currentPageIdWithSubmodelsSelector,
} from 'selectors/blocksPagesSelector';
import {
  blockConfigBusinessObjectSpecIdSelector,
  blockConfigFiscalYearStartMonthSelector,
  blockConfigShowRestrictedSelector,
  blockNameSelector,
  blockSelector,
  blocksByIdSelector,
  sortedBlockIdsForPageSelector,
  sortedBlockRowsForPageSelector,
} from 'selectors/blocksSelector';
import { businessObjectFieldSpecByIdSelector } from 'selectors/businessObjectFieldSpecsSelector';
import { businessObjectSpecSelector } from 'selectors/businessObjectSpecsSelector';
import { driverPropertiesByIdSelector } from 'selectors/collectionSelector';
import {
  configurableColumnsSelector,
  configurableDatabaseViewObjectTableColumnsSelector,
  getDriverColumns,
} from 'selectors/configurableColumnsSelector';
import { enableAgChartsSelector, enableBlockLayoutsSelector } from 'selectors/launchDarklySelector';
import { isLayerNamedVersionSelector } from 'selectors/layerSelector';
import { objectTableBlockGroupsByKeySelector } from 'selectors/objectTableBlockSelector';
import { businessObjectSpecForBlockSelector } from 'selectors/orderedFieldSpecIdsSelector';
import {
  blockDateRangeDateTimeSelector,
  blockDateRangeTranslatedViewAtTimeSelector,
} from 'selectors/pageDateRangeSelector';
import { pageIdSelector } from 'selectors/pageSelector';
import {
  objectFieldCellSelectionSelector,
  prevailingCellSelectionSelector,
} from 'selectors/prevailingCellSelectionSelector';
import { rollupTypeForBlockSelector } from 'selectors/rollupSelector';
import {
  scenarioComparisonPageDatabaseBlockIdSelector,
  scenarioComparisonPageDriverBlockIdSelector,
} from 'selectors/scenarioComparisonSelector';
import { selectedOrgIdSelector } from 'selectors/selectedOrgSelector';

const mutationActions = {
  createBlock: getMutationThunkAction<Omit<BlockCreateInput, 'pageId'>>(
    (createInput, getState) => {
      const state = getState();
      const pageId = currentPageIdWithSubmodelsSelector(state);
      if (pageId == null) {
        return null;
      }

      return {
        newBlocks: [{ ...createInput, pageId }],
      };
    },
    (input) => {
      return (dispatch, getState) => {
        const state = getState();
        const orgId = selectedOrgIdSelector(state);

        dispatch(setAutoFocus({ type: 'block', id: input.id }));

        dispatch(trackCreateBlock(input.type));

        const layerIds = input?.blockConfig?.comparisons?.layerIds ?? [];
        const namedVersionIds = layerIds.filter((id) => isLayerNamedVersionSelector(state, id));
        dispatch(loadCompareBlocksDataAsync(orgId, namedVersionIds));
      };
    },
  ),
  updateBlocks: getMutationThunkAction<BlockUpdateInput[]>(
    (updateInputs) => {
      if (updateInputs.length === 0) {
        return null;
      }

      return {
        updateBlocks: updateInputs,
      };
    },
    (input) => {
      return (dispatch, getState) => {
        const state = getState();
        const orgId = selectedOrgIdSelector(state);

        const namedVersionIds = new Set<string>();
        for (const block of input) {
          if (block.blockConfig?.comparisons?.layerIds == null) {
            continue;
          }
          block.blockConfig.comparisons.layerIds.forEach((layerId) => {
            if (isLayerNamedVersionSelector(state, layerId)) {
              namedVersionIds.add(layerId);
            }
          });
        }

        dispatch(loadCompareBlocksDataAsync(orgId, Array.from(namedVersionIds)));
      };
    },
  ),
  updateBlock: getMutationThunkAction<BlockUpdateInput>(
    (updateInput) => {
      return {
        updateBlocks: [{ ...updateInput }],
      };
    },
    (input) => {
      return (dispatch, getState) => {
        const state = getState();
        const orgId = selectedOrgIdSelector(state);
        const layerIds = input.blockConfig?.comparisons?.layerIds ?? [];
        const namedVersionIds = layerIds.filter((id) => isLayerNamedVersionSelector(state, id));
        dispatch(loadCompareBlocksDataAsync(orgId, namedVersionIds));
      };
    },
  ),
};

const deleteBlocksMutation = (
  state: RootState,
  blockIds: BlockId[],
): { deleteBlocks: BlockDeleteInput[]; updateDrivers: DriverUpdateInput[] } => {
  return {
    deleteBlocks: blockIds.map((id) => ({ id })),
    ...blockDeletionDriverReferenceMutations(state, blockIds),
    ...deleteBlocksUpdateBlocksPagesMutations(state, blockIds),
  };
};

export const deleteBlock =
  (deleteInput: BlockDeleteInput): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    dispatch(
      submitAutoLayerizedMutations(
        'delete-block',
        [deleteBlocksMutation(state, [deleteInput.id])],
        {
          // Normally updateDrivers gets routed to the current layer, but in this
          // case we want it to follow delete blocks.
          updateDrivers: 'deleteBlocks',
        },
      ),
    );
  };

export const updateBlockSortingAndLayout = (
  blockId: BlockId,
  {
    targetBlockId,
    relativePosition,
  }: { targetBlockId: BlockId; relativePosition: RelativePosition },
  { beforeId, afterId }: { beforeId?: BlockId; afterId?: BlockId },
): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const pageId = pageIdSelector(state);
    if (pageId == null) {
      return;
    }

    const blocksPage = blocksPageSelector(state, pageId);

    const enableBlockLayouts = enableBlockLayoutsSelector(state);
    const sortedBlockIds = sortedBlockIdsForPageSelector(state, pageId);

    let newLayout: BlockRow[] | null = null;

    // We update the layout if the feature flag is enabled or if the page already has a layout
    // (due to the feature flag being enabled at some point). So that if the feature flag is
    // re-enabled, the layout won't be out-of-date.
    if (enableBlockLayouts || blocksPage?.layout != null) {
      const existingBlocksRows = sortedBlockRowsForPageSelector(state, pageId);
      newLayout = getUpdatedBlocksPageLayout(
        existingBlocksRows,
        'move-block',
        blockId,
        targetBlockId,
        relativePosition,
      );
    }

    const blocksById = blocksByIdSelector(state);
    const blocks = sortedBlockIds.map((id) => blocksById[id]);
    const updates = computeSortIndexUpdates(blocks, { toInsertId: blockId, beforeId, afterId });
    dispatch(trackMoveBlock(blockId, pageId, targetBlockId, relativePosition));
    dispatch(
      submitMutation(
        {
          updateBlocks: Object.entries(updates).map(([id, sortIndex]) => ({ id, sortIndex })),
          updateBlocksPages:
            newLayout != null
              ? [
                  {
                    id: pageId,
                    layout: newLayout,
                  },
                ]
              : undefined,
        },
        { forceLayerId: DEFAULT_LAYER_ID },
      ),
    );
  };
};

export type RelativePosition = 'above' | 'below' | 'right' | 'left' | 'replace';

type BlockCreateCommonParams = {
  blockCreateInput: Omit<BlockCreateInput, 'pageId'>;
  mergeMutation?: DatasetMutationInput;
};

type UpdateLayoutParams = {
  targetBlockId: BlockId;
  relativePosition: RelativePosition;
};

type UpdateSortIndexParams = {
  replaceBlockId?: BlockId;
  addBeforeBlockId?: BlockId;
  addAfterBlockId?: BlockId;
};

export const createBlockAndUpdateCurrentPageLayoutAndOrdering = (
  { blockCreateInput, mergeMutation }: BlockCreateCommonParams,
  { targetBlockId, relativePosition }: UpdateLayoutParams,
  { addBeforeBlockId, addAfterBlockId, replaceBlockId }: UpdateSortIndexParams,
): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const currentPage = currentBlocksPageSelector(state);
    if (currentPage == null) {
      return;
    }

    const { id: pageId, layout: existingPageLayout } = currentPage;

    const enableBlockLayouts = enableBlockLayoutsSelector(state);

    let newLayout: BlockRow[] | null = null;

    // We update the layout if the feature flag is enabled or if the page already has a layout
    // (due to the feature flag being enabled at some point). So that if the feature flag is
    // re-enabled, the layout won't be out-of-date.
    if (enableBlockLayouts || existingPageLayout != null) {
      const existingBlockRows = sortedBlockRowsForPageSelector(state, pageId);
      newLayout = getUpdatedBlocksPageLayout(
        existingBlockRows,
        'insert-block',
        blockCreateInput.id,
        targetBlockId,
        relativePosition,
      );
    }

    const sortedBlockIds = sortedBlockIdsForPageSelector(state, pageId);
    const blocksById = blocksByIdSelector(state);
    const blocks = sortedBlockIds
      .map((blockId) => blocksById[blockId])
      .filter(isNotNull)
      .filter((b) => replaceBlockId == null || replaceBlockId !== b.id);

    const updates = computeSortIndexUpdates(blocks, {
      toInsertId: blockCreateInput.id,
      beforeId: addBeforeBlockId,
      afterId: addAfterBlockId,
    });

    const blockCreationMutation: DatasetMutationInput = {
      newBlocks: [{ ...blockCreateInput, pageId, sortIndex: undefined }],
      updateBlocks: Object.entries(updates).map(([id, sortIndex]) => ({ id, sortIndex })),
      ...(relativePosition === 'replace' || replaceBlockId != null
        ? deleteBlocksMutation(state, uniq([targetBlockId, replaceBlockId].filter(isNotNull)))
        : {}),
      updateBlocksPages: newLayout != null ? [{ id: pageId, layout: newLayout }] : null,
    };

    dispatch(trackCreateBlock(blockCreateInput.type));
    dispatch(
      submitAutoLayerizedMutations('create-block', [blockCreationMutation, mergeMutation ?? {}], {
        // Normally updateDrivers gets routed to the current layer, but in this
        // case we want it to follow delete blocks.
        updateDrivers: 'deleteBlocks',
      }),
    );
  };
};

export const updateSort = ({
  blockId,
  sortBy,
}: {
  blockId: BlockId;
  sortBy:
    | {
        sortType: BlockSortType.Manual | BlockSortType.StartDate;
      }
    | { sortType: BlockSortType.Object; object: ObjectSortExtension }
    | { sortType: BlockSortType.Impact; impact: ImpactSortExtension };
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const { blockConfig } = blocksByIdSelector(state)[blockId] ?? {};
    dispatch(
      updateBlock({
        id: blockId,
        blockConfig: {
          ...blockConfig,
          sortBy: {
            ...sortBy,
          },
        },
      }),
    );
  };
};

export const updateTextBlockBody = ({
  blockId,
  textBlockBody,
}: {
  blockId: BlockId;
  textBlockBody: string;
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const block = blockSelector(state, blockId);
    if (block == null || block.blockConfig.textBlockBody === textBlockBody) {
      return;
    }

    dispatch(
      updateBlockConfig({
        blockId,
        fn: (bc) => {
          bc.textBlockBody = textBlockBody;
        },
      }),
    );
  };
};

export const updateBlockGroupBy = ({
  blockId,
  newGroupBy,
}: {
  blockId: BlockId;
  newGroupBy?:
    | { type: 'field'; fieldSpecId: BusinessObjectFieldSpecId }
    | { type: 'driver'; driverPropertyId: DriverPropertyId };
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const columns = configurableColumnsSelector(state, blockId);
    const columnsByKey = keyBy(columns, (c) => c.key);
    dispatch(
      updateBlockConfig({
        blockId,
        fn: (blockConfig) => {
          const oldFieldIdOrDriverPropertyId =
            blockConfig?.groupBy?.objectField?.businessObjectFieldId ??
            blockConfig?.groupBy?.driverProperty?.driverPropertyId;

          if (oldFieldIdOrDriverPropertyId != null) {
            // if a grouped-by column is hidden, show it when we remove the grouping
            const columnConfig = blockConfig.columns?.find(
              (c) => c.key === oldFieldIdOrDriverPropertyId,
            );
            const isGroupedColumnHidden =
              columnsByKey[oldFieldIdOrDriverPropertyId]?.visible === false;
            if (isGroupedColumnHidden && columnConfig) {
              columnConfig.visible = true;
            }
          }

          const newFieldIdOrDriverPropertyId =
            newGroupBy?.type === 'field' ? newGroupBy?.fieldSpecId : newGroupBy?.driverPropertyId;
          blockConfig.groupBy = undefined;
          if (newFieldIdOrDriverPropertyId == null) {
            return;
          }

          let columnConfig;
          let hideColumn = true;

          if (newGroupBy?.type === 'field') {
            const { fieldSpecId: businessObjectFieldId } = newGroupBy;
            const spec = businessObjectSpecForBlockSelector(state, blockId);
            const isPrimaryKey =
              (spec?.collection?.dimensionalProperties ?? []).find(
                (dimProp) =>
                  businessObjectFieldId != null &&
                  dimProp.id === businessObjectFieldId &&
                  dimProp.isDatabaseKey,
              ) != null;

            columnConfig = blockConfig.columns?.find((c) => c.key === businessObjectFieldId);
            hideColumn = !isPrimaryKey; // if a grouped-by column is visible, hide it unless it is a database key

            blockConfig.groupBy = {
              groupByType: BlockGroupByType.ObjectField,
              objectField: {
                businessObjectFieldId: newGroupBy.fieldSpecId,
                collapsedAttributeIds: [],
              },
            };
          }

          if (newGroupBy?.type === 'driver') {
            const { driverPropertyId } = newGroupBy;
            columnConfig = blockConfig.columns?.find((c) => c.key === driverPropertyId);
            blockConfig.groupBy = {
              groupByType: BlockGroupByType.DriverProperty,
              driverProperty: {
                driverPropertyId: newGroupBy.driverPropertyId,
                collapsedAttributeIds: [],
              },
            };
          }

          if (columnConfig == null) {
            blockConfig.columns = [
              ...(blockConfig.columns ?? []),
              {
                ...columnsByKey[newFieldIdOrDriverPropertyId],
                visible: !hideColumn,
              },
            ];
          } else {
            columnConfig.visible = !hideColumn;
          }
        },
      }),
    );
  };
};

export const updateBlockViewOptions = ({
  blockId,
  blockViewOptions,
}: {
  blockId: BlockId;
  blockViewOptions: BlockViewOptions;
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();

    const { blockConfig } = blocksByIdSelector(state)[blockId] ?? {};
    const newViewOptions = { ...blockConfig.blockViewOptions, ...blockViewOptions };

    const enableAgCharts = enableAgChartsSelector(state);
    if (!enableAgCharts) {
      // can't have aggregate values if its not a multi-driver chart
      if (!isMultiDriverChart(newViewOptions)) {
        newViewOptions.aggregateValues = false;
      } else if (isBarChart(newViewOptions)) {
        // if its both bar chart and mutli-driver, it has to be aggregated
        newViewOptions.aggregateValues = true;
      }
    }

    dispatch(
      updateBlockConfig({
        blockId,
        fn: (bc) => {
          bc.blockViewOptions = newViewOptions;
        },
      }),
    );
  };
};

export const updateBlockConfigMutation = (
  state: RootState,
  {
    blockId,
    fn,
  }: {
    blockId: BlockId;
    fn: (bc: BlockConfig) => void;
  },
): { updateBlocks: NonNullable<DatasetMutationInput['updateBlocks']> } | null => {
  const blockConfig = safeObjGet(blocksByIdSelector(state)[blockId])?.blockConfig ?? {};

  const newBlockConfig = produce(blockConfig, fn);
  if (isEqual(newBlockConfig, blockConfig)) {
    return null;
  }

  return {
    updateBlocks: [
      {
        id: blockId,
        blockConfig: newBlockConfig,
      },
    ],
  };
};

export const updateBlockConfig = ({
  blockId,
  fn,
}: {
  blockId: BlockId;
  fn: (bc: BlockConfig) => void;
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const mutation = updateBlockConfigMutation(state, { blockId, fn });

    if (mutation == null) {
      return;
    }

    dispatch(updateBlock(mutation.updateBlocks[0]));
  };
};

export const updateMediaBlockUrlAndWidth = ({
  blockId,
  type,
  url,
  width,
}: {
  blockId: BlockId;
  type: 'image' | 'video';
  url: string;
  width: number;
}): AppThunk => {
  return (dispatch) => {
    dispatch(
      updateBlockConfig({
        blockId,
        fn: (bc) => {
          bc.mediaWidth = width;
          if (type === 'video') {
            bc.videoBlockUrl = url;
          } else {
            bc.imageBlockUrl = url;
          }
        },
      }),
    );

    dispatch(trackAddMediaLink(type, blockId, url));
  };
};

export const updateBlockFileMetadataAndWidth = ({
  blockId,
  type,
  width,
  fileMetadata,
}: {
  blockId: BlockId;
  type: 'image' | 'video';
  width: number;
  fileMetadata: FileMetadata;
}): AppThunk => {
  return (dispatch) => {
    dispatch(
      updateBlockConfig({
        blockId,
        fn: (bc) => {
          bc.mediaWidth = width;
          bc.fileMetadata = fileMetadata;
        },
      }),
    );

    dispatch(trackUploadFile(type, blockId, fileMetadata));
  };
};

const MIN_PROPERTY_WIDTH = 50;
const MIN_INIT_VALUE_WIDTH = 20;
const MIN_DRIVER_WIDTH = MIN_PROPERTY_WIDTH + MIN_INIT_VALUE_WIDTH;
const MIN_WIDTH_BY_COLUMN_KEY: NullableRecord<string, number> = {
  [INITIAL_VALUE_COLUMN_TYPE]: MIN_INIT_VALUE_WIDTH,
  [NAME_COLUMN_TYPE]: MIN_DRIVER_WIDTH,
  [PROPERTY_COLUMN_TYPE]: MIN_PROPERTY_WIDTH,
};
const RESIZABLE_SCENARIO_COMPARISON_COLUMNS = new Set([
  NAME_COLUMN_TYPE,
  FORECAST_FORMULA_COLUMN_TYPE,
  ACTUALS_FORMULA_COLUMN_TYPE,
]);

export const resizeBlockColumn = ({
  blockId,
  columnKey,
  width,
}: {
  blockId: BlockId;
  columnKey: string;
  width: number;
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    let newWidth = Math.floor(width);
    const scenarioComparisonDriverBlockId = scenarioComparisonPageDriverBlockIdSelector(state);
    const scenarioComparisonDatabaseBlockId = scenarioComparisonPageDatabaseBlockIdSelector(state);
    // we want to keep the database block and driver block column widths in sync on the scenario comparison page
    const isResizingScenarioComparisonDriverBlock = blockId === scenarioComparisonDriverBlockId;
    const isResizingScenarioComparisonDatabaseBlock = blockId === scenarioComparisonDatabaseBlockId;
    if (
      (isResizingScenarioComparisonDriverBlock || isResizingScenarioComparisonDatabaseBlock) &&
      scenarioComparisonDriverBlockId != null &&
      scenarioComparisonDatabaseBlockId != null
    ) {
      // if the width of the driver name column is too narrow, we won't be able to practically fit the two
      // columns of the database block
      newWidth = Math.max(MIN_WIDTH_BY_COLUMN_KEY[columnKey] ?? 0, newWidth);
      const driverBlockConfig =
        blocksByIdSelector(state)[scenarioComparisonDriverBlockId ?? '']?.blockConfig;
      const databaseBlockConfig =
        blocksByIdSelector(state)[scenarioComparisonDatabaseBlockId ?? '']?.blockConfig;
      const initValueColWidth =
        columnKey === INITIAL_VALUE_COLUMN_TYPE
          ? newWidth
          : databaseBlockConfig?.columns?.find((c) => c.key === INITIAL_VALUE_COLUMN_TYPE)?.width;
      const propertyColWidth =
        columnKey === PROPERTY_COLUMN_TYPE
          ? newWidth
          : databaseBlockConfig?.columns?.find((c) => c.key === PROPERTY_COLUMN_TYPE)?.width;
      const nameColWidth =
        columnKey === NAME_COLUMN_TYPE
          ? newWidth
          : driverBlockConfig?.columns?.find((c) => c.key === NAME_COLUMN_TYPE)?.width;

      if (
        driverBlockConfig?.columns == null ||
        databaseBlockConfig?.columns == null ||
        initValueColWidth == null ||
        propertyColWidth == null ||
        nameColWidth == null
      ) {
        return;
      }

      let updates: BlockUpdateInput[] = [];
      if (
        isResizingScenarioComparisonDatabaseBlock &&
        (columnKey === PROPERTY_COLUMN_TYPE || columnKey === INITIAL_VALUE_COLUMN_TYPE)
      ) {
        updates = [
          {
            id: scenarioComparisonDatabaseBlockId,
            blockConfig: {
              ...databaseBlockConfig,
              columns: databaseBlockConfig.columns.map((col) => {
                return col.key === columnKey ? { ...col, width: newWidth } : col;
              }),
            },
          },
          {
            id: scenarioComparisonDriverBlockId,
            blockConfig: {
              ...driverBlockConfig,
              columns: driverBlockConfig.columns.map((col) => {
                return col.key === NAME_COLUMN_TYPE
                  ? { ...col, width: initValueColWidth + propertyColWidth }
                  : col;
              }),
            },
          },
        ];
      } else if (
        isResizingScenarioComparisonDriverBlock &&
        RESIZABLE_SCENARIO_COMPARISON_COLUMNS.has(columnKey)
      ) {
        let newInitValueColWidth = newWidth - propertyColWidth;
        let newPropertyColWidth = propertyColWidth;
        if (newInitValueColWidth < MIN_INIT_VALUE_WIDTH) {
          newInitValueColWidth = MIN_INIT_VALUE_WIDTH;
          newPropertyColWidth = newWidth - MIN_INIT_VALUE_WIDTH;
        }

        updates = [
          {
            id: scenarioComparisonDriverBlockId,
            blockConfig: {
              ...driverBlockConfig,
              columns: driverBlockConfig.columns.map((col) => {
                return col.key === columnKey ? { ...col, width: newWidth } : col;
              }),
            },
          },
          {
            id: scenarioComparisonDatabaseBlockId,
            blockConfig: {
              ...databaseBlockConfig,
              columns: databaseBlockConfig.columns.map((col) => {
                if (col.key === INITIAL_VALUE_COLUMN_TYPE) {
                  return { ...col, width: newInitValueColWidth };
                } else if (col.key === PROPERTY_COLUMN_TYPE) {
                  return { ...col, width: newPropertyColWidth };
                }
                return col;
              }),
            },
          },
        ];
      }

      dispatch(updateBlocks(updates));
    } else {
      dispatch(
        updateBlockConfig({
          blockId,
          fn: (bc) => {
            let newColumns = [...(bc.columns ?? [])];
            // If there are no config regarding column order, get and save the current column order; otherwise
            // the newly resized column will be moved to the start of the column order.
            if (newColumns.length === 0) {
              const objectSpecId = blockConfigBusinessObjectSpecIdSelector(state, blockId);
              const spec =
                objectSpecId != null ? businessObjectSpecSelector(state, objectSpecId) : null;
              newColumns = (spec?.fields ?? []).map((f) => ({ key: f.id, visible: true }));
            }

            const column = newColumns.find((col) => col.key === columnKey);
            if (column != null) {
              column.width = newWidth;
            } else {
              newColumns.push({ key: columnKey, width: newWidth, visible: true });
            }
            bc.columns = newColumns;
          },
        }),
      );
    }
    dispatch(endLiveEditing());
  };
};

export const toggleAttributeCollapsed = ({
  blockId,
  attributeId,
}: {
  blockId: BlockId;
  attributeId?: string;
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const cellSelection = objectFieldCellSelectionSelector(state);
    dispatch(
      updateBlockConfig({
        blockId,
        fn: (blockConfig) => {
          if (
            blockConfig.groupBy == null ||
            (blockConfig.groupBy.objectField == null && blockConfig.groupBy.driverProperty == null)
          ) {
            return;
          }

          const groupByKey =
            blockConfig.groupBy.objectField == null ? 'driverProperty' : 'objectField';
          const currCollapsedIds = blockConfig.groupBy[groupByKey]!.collapsedAttributeIds ?? [];
          blockConfig.groupBy[groupByKey]!.collapsedAttributeIds = xor(currCollapsedIds, [
            attributeId ?? OBJECT_TABLE_BLOCK_UNDEFINED_OPTION_ID_PLACEHOLDER,
          ]);
        },
      }),
    );
    // Clear the cell selection if collapsing will make the cell disappear
    if (cellSelection != null && cellSelection.blockId === blockId) {
      const objectTableBlockGroupsById = objectTableBlockGroupsByKeySelector(state, blockId);
      const activeCellGroupKey = cellSelection.activeCell.rowKey.groupKey;
      const groupForActiveCell = safeObjGet(objectTableBlockGroupsById[activeCellGroupKey]);
      if (
        groupForActiveCell == null ||
        (groupForActiveCell.isExpanded &&
          groupForActiveCell.groupInfo.groupingType === 'attributeObjectField' &&
          groupForActiveCell.groupInfo.attributeId === attributeId)
      ) {
        dispatch(clearSelection());
      }
    }
  };
};

export const setObjectSpecDisplayAs = ({
  blockId,
  displayAs,
}: {
  blockId: BlockId;
  displayAs: ObjectSpecDisplayAsType;
}): AppThunk => {
  return (dispatch) => {
    dispatch(
      updateBlockConfig({
        blockId,
        fn: (bc) => {
          bc.objectSpecDisplayAs = displayAs;
        },
      }),
    );
  };
};

export const toggleShowColumnAsTimeSeries = ({
  blockId,
  columnKey,
}: {
  blockId: BlockId;
  columnKey: string;
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const fieldSpecById = businessObjectFieldSpecByIdSelector(state);
    const driverPropertyById = driverPropertiesByIdSelector(state);

    const driverProperty = driverPropertyById[columnKey];
    const fieldSpec = fieldSpecById[columnKey];

    if (fieldSpec == null && driverProperty == null) {
      return;
    }

    dispatch(
      updateBlockConfig({
        blockId,
        fn: (bc) => {
          if (bc.objectFieldSpecAsTimeSeriesId === columnKey) {
            bc.objectFieldSpecAsTimeSeriesId = undefined;
            return;
          }
          bc.objectFieldSpecAsTimeSeriesId = columnKey;
        },
      }),
    );
  };
};

/**
 * This is only set up for Drivers and Databases - add more types if needed
 */
const columnKeysIncludeCell = (
  columnKeys: string[],
  cell: CellRef,
): cell is DriverCellRef | BusinessObjectFieldCellRef => {
  return (
    (isDriverCellRef(cell) &&
      isStickyColumnKey(cell.columnKey) &&
      columnKeys.includes(cell.columnKey.columnType)) ||
    (isBusinessObjectPropertyFieldCellRef(cell) &&
      columnKeys.includes(cell.columnKey.objectFieldSpecId))
  );
};

const getUpdatedCellSelectionWhenHidingColumn = (
  blockId: BlockId,
  columnKeys: string[],
  cellSelection: CellSelection<CellRef> | null,
): CellSelection<CellRef> | undefined => {
  if (cellSelection?.blockId !== blockId) {
    return undefined;
  }

  const [arrayWithThisCell, allOtherSelectedCells] = partition(
    cellSelection.selectedCells,
    (cell) => columnKeysIncludeCell(columnKeys, cell),
  );

  if (arrayWithThisCell.length === 0) {
    return undefined;
  }

  let activeCell: CellRef | undefined;

  if (columnKeysIncludeCell(columnKeys, cellSelection.activeCell)) {
    activeCell = {
      ...cellSelection.activeCell,
      columnKey: { columnType: NAME_COLUMN_TYPE, columnLayerId: undefined },
    };
  }

  const selectedCells = [...(activeCell != null ? [activeCell] : []), ...allOtherSelectedCells];

  return {
    ...cellSelection,
    selectedCells,
    activeCell: activeCell ?? cellSelection.activeCell,
  };
};

export const toggleColumnVisibility = ({
  blockId,
  columnKey,
  visible,
}: {
  blockId: BlockId;
  columnKey: string;
  visible: boolean;
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();

    if (!visible) {
      const cellSelection = prevailingCellSelectionSelector(state);
      const updatedCellSelection = getUpdatedCellSelectionWhenHidingColumn(
        blockId,
        [columnKey],
        cellSelection,
      );
      if (updatedCellSelection != null) {
        dispatch(setSelectedCells(updatedCellSelection));
      }
    }

    dispatch(
      updateBlockConfig({
        blockId,
        fn: (bc) => {
          const existingColumns = configurableColumnsSelector(state, blockId);

          const newColumns = Object.values(existingColumns).map((col) => ({ ...col }));
          const column = newColumns.find((col) => col.key === columnKey);

          if (column != null) {
            column.visible = visible;
          } else {
            newColumns.push({
              key: columnKey,
              width: COLUMN_TYPE_TO_DEFAULT_WIDTH[columnKey as ModelViewColumnType],
              visible,
            });
          }

          bc.columns = uniqBy([...newColumns, ...(bc.columns ?? [])], 'key');
        },
      }),
    );
  };
};

export const changeAllColumnVisibilities = ({
  blockId,
  visible,
}: {
  blockId: BlockId;
  visible: boolean;
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();

    const existingColumns = Object.values(configurableColumnsSelector(state, blockId));

    if (!visible) {
      const cellSelection = prevailingCellSelectionSelector(state);
      const updatedCellSelection = getUpdatedCellSelectionWhenHidingColumn(
        blockId,
        existingColumns.map((col) => col.key).filter((key) => key !== NAME_COLUMN_TYPE),
        cellSelection,
      );
      if (updatedCellSelection != null) {
        dispatch(setSelectedCells(updatedCellSelection));
      }
    }

    dispatch(
      updateBlockConfig({
        blockId,
        fn: (bc) => {
          const modifiedColumns = existingColumns.map((col) => ({
            ...col,
            // change all except the name and property columns
            visible: [NAME_COLUMN_TYPE, PROPERTY_COLUMN_TYPE].includes(col.key) ? true : visible,
          }));

          // preserve the existing columns in case we're switching from a driver grid to an object table
          bc.columns = uniqBy([...modifiedColumns, ...(bc.columns ?? [])], 'key');
        },
      }),
    );
  };
};

export const updateAgGridColumnPositionToIndex = ({
  blockId,
  columnKey,
  toIndex,
}: {
  blockId: BlockId;
  columnKey: string;
  toIndex: number;
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const columnDefs = objectGridColumnDefsSelector(state, blockId);
    const currentIdx = columnDefs.findIndex((col) => col.colId === columnKey);
    if (currentIdx === toIndex) {
      return;
    }

    let insertBeforeId =
      currentIdx > toIndex ? columnDefs[toIndex]?.colId : columnDefs[toIndex + 1]?.colId;

    if (insertBeforeId == null || insertBeforeId === 'addColumn') {
      insertBeforeId = 'end';
    }

    dispatch(
      updateColumnPosition({
        blockId,
        columnKey,
        insertBeforeId,
        currentOrder: columnDefs.map((c) => c.colId),
      }),
    );
  };
};

export const updateColumnPosition = ({
  blockId,
  columnKey,
  insertBeforeId,
  currentOrder,
}: {
  blockId: BlockId;
  columnKey: string;
  insertBeforeId: string | 'end';
  currentOrder?: string[];
}): AppThunk => {
  if (columnKey === insertBeforeId) {
    return noop;
  }
  return (dispatch) => {
    dispatch(
      updateBlockConfig({
        blockId,
        fn: (bc) => {
          const existingColumns = bc.columns ?? [];
          let columnOrder = currentOrder ?? existingColumns.map((c) => c.key);

          // Take out the updated column and reinsert it in the correct position
          columnOrder = columnOrder.filter((id) => id !== columnKey);
          const insertBeforePosition =
            insertBeforeId === 'end'
              ? columnOrder.length
              : columnOrder.findIndex((fId) => fId === insertBeforeId);
          columnOrder.splice(insertBeforePosition, 0, columnKey);

          const updatedColumns = columnOrder.map((fId) => {
            return (
              existingColumns.find((c) => c.key === fId) ?? {
                key: fId,
                width: COLUMN_TYPE_TO_DEFAULT_WIDTH[fId as ModelViewColumnType],
                visible: true,
              }
            );
          });

          // preserve the existing columns in case we're switching from a driver grid to an object table
          bc.columns = uniqBy([...updatedColumns, ...(bc.columns ?? [])], 'key');
        },
      }),
    );
  };
};

export const switchBlockType = ({
  blockId,
  toType,
}: {
  blockId: BlockId;
  toType: BlockType.DriverGrid | BlockType.ObjectTable;
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const { blockConfig, type } = blocksByIdSelector(state)[blockId] ?? {};

    if (!(type === BlockType.ObjectTable || type === BlockType.DriverGrid)) {
      throw new Error(`Switching from block type ${type} is not supported`);
    }

    const newBlockConfig = produce(blockConfig, (bc) => {
      const configColumns = (bc?.columns ?? []).filter((col) => !isDataColumnType(col.key));
      const columnsByKey = keyBy(configColumns, (c) => c.key);
      const columns =
        toType === BlockType.DriverGrid
          ? getDriverColumns(columnsByKey, configColumns)
          : configurableDatabaseViewObjectTableColumnsSelector(state, blockId);

      // we want to default to showing all columns when its the first time switching to driver grid
      const castedColumns = columns
        .filter((entry) => columnsByKey[entry.key] == null)
        .map((entry) => {
          return { ...entry, visible: entry.visible == null ? true : entry.visible };
        });

      // preserve the existing columns in case we're switching back and forth between driver grid and object table
      bc.columns = uniqBy([...castedColumns, ...(bc.columns ?? [])], 'key');
    });

    dispatch(
      updateBlock({
        id: blockId,
        type: toType,
        blockConfig: newBlockConfig,
      }),
    );
  };
};

export const duplicateBlock = ({ blockId }: { blockId: BlockId }): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();

    const blockToDuplicate = blockSelector(state, blockId);
    if (blockToDuplicate == null) {
      return;
    }

    const pageBlockNames = sortedBlockIdsForPageSelector(state, blockToDuplicate.pageId)
      .map((id) => blockNameSelector(state, id))
      .filter(isNotNull);
    const duplicateName = getNameWithCopySuffix(blockToDuplicate.name, pageBlockNames);

    const newBlockId = uuidv4();
    const updateDrivers: DriverUpdateInput[] = blockDuplicateDriverReferenceMutations(state, {
      [blockId]: newBlockId,
    });

    const blockCreateInput: Omit<BlockCreateInput, 'pageId'> = {
      id: newBlockId,
      name: duplicateName,
      type: blockToDuplicate.type,
      blockConfig: blockToDuplicate.blockConfig,
    };

    dispatch(
      createBlockAndUpdateCurrentPageLayoutAndOrdering(
        { blockCreateInput },
        {
          targetBlockId: blockId,
          relativePosition: 'below',
        },
        {
          addAfterBlockId: blockId,
        },
      ),
    );

    dispatch(
      submitAutoLayerizedMutations('duplicate-block', [
        {
          updateDrivers,
        },
      ]),
    );
  };
};

export const updateHideHeader = ({
  blockId,
  hideHeader,
}: {
  blockId: BlockId;
  hideHeader: boolean;
}): AppThunk => {
  return (dispatch) => {
    dispatch(trackToggleBlockHeader(hideHeader, blockId));
    dispatch(
      updateBlockConfig({
        blockId,
        fn: (blockConfig) => {
          blockConfig.hideHeader = hideHeader;
        },
      }),
    );
  };
};

export const updateBlockDateRange = ({
  blockId,
  newDateRange,
}: {
  blockId: BlockId;
  newDateRange: { start?: DateTime; end?: DateTime };
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const rollupType = rollupTypeForBlockSelector(state, blockId);
    const fiscalYearStartMonth = blockConfigFiscalYearStartMonthSelector(state, blockId);
    const currentDateRange = blockDateRangeDateTimeSelector(state, blockId);

    dispatch(
      updateBlockConfig({
        blockId,
        fn: (blockConfig) => {
          const [alignedStart, alignedEnd] = alignDates(
            newDateRange.start ?? currentDateRange[0],
            newDateRange.end ?? currentDateRange[1],
            rollupType,
            fiscalYearStartMonth,
          );

          blockConfig.dateRange = {
            start: getISOTimeWithoutMs(alignedStart.toISO()),
            end: getISOTimeWithoutMs(alignedEnd.toISO()),
          };
        },
      }),
    );
  };
};

export const toggleShowRestrictedFields = ({ blockId }: { blockId: BlockId }): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();

    const showRestricted = blockConfigShowRestrictedSelector(state, blockId);

    dispatch(
      updateBlockConfig({
        blockId,
        fn: (blockConfig) => {
          blockConfig.showRestricted = !showRestricted;
        },
      }),
    );
  };
};

export const setBlockRollupType = ({
  blockId,
  rollupType,
}: {
  blockId: BlockId;
  rollupType: RollupType;
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const fiscalYearStartMonth = blockConfigFiscalYearStartMonthSelector(state, blockId);
    const dateRange = blockDateRangeDateTimeSelector(state, blockId);

    dispatch(
      updateBlockConfig({
        blockId,
        fn: (blockConfig) => {
          blockConfig.rollupTypes = [rollupType];
          blockConfig.dateRange = alignDatesWithRollupOverride(
            dateRange,
            blockConfig?.rollupTypes,
            fiscalYearStartMonth,
          );
        },
      }),
    );
  };
};

export const setBlockFiscalYearStartMonth = ({
  blockId,
  dateTime,
}: {
  blockId: BlockId;
  dateTime: DateTime;
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const fiscalYearStartMonth = blockConfigFiscalYearStartMonthSelector(state, blockId);
    const dateRange = blockDateRangeDateTimeSelector(state, blockId);
    const rollupType = rollupTypeForBlockSelector(state, blockId);

    dispatch(
      updateBlockConfig({
        blockId,
        fn: (blockConfig) => {
          blockConfig.fiscalYearStartMonth = dateTime.month;
          const diff =
            unitForRollupType(rollupType) === 'month' ? 0 : dateTime.month - fiscalYearStartMonth;
          blockConfig.dateRange = {
            start: getISOTimeWithoutMs(dateRange[0].plus({ months: diff }).toISO()),
            end: getISOTimeWithoutMs(dateRange[1].plus({ months: diff }).toISO()),
          };
        },
      }),
    );
  };
};

export const setBlockMediaWidth = ({
  blockId,
  mediaWidth,
}: {
  blockId: BlockId;
  mediaWidth: number | null;
}): AppThunk => {
  return (dispatch, _) => {
    dispatch(
      updateBlockConfig({
        blockId,
        fn: (bc) => {
          bc.mediaWidth = mediaWidth;
        },
      }),
    );
  };
};

export const updateBlockViewAtTime = ({
  blockId,
  viewAtTime,
  dynamicTimePeriod,
}: {
  blockId: BlockId;
  viewAtTime?: DateTime | null;
  dynamicTimePeriod?: DynamicDate | null;
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const rollupType = rollupTypeForBlockSelector(state, blockId);
    const fiscalYearStartMonth = blockConfigFiscalYearStartMonthSelector(state, blockId);
    const currentTimePeriod = blockDateRangeTranslatedViewAtTimeSelector(state, blockId);

    dispatch(
      updateBlockConfig({
        blockId,
        fn: (blockConfig) => {
          const alignedViewAtTime =
            viewAtTime === null
              ? null
              : viewAtTime !== undefined
                ? getISOTimeWithoutMs(
                    alignDate(viewAtTime, true, rollupType, fiscalYearStartMonth).toISO(),
                  )
                : currentTimePeriod != null
                  ? getISOTimeWithoutMs(currentTimePeriod.toISO())
                  : null;

          if (alignedViewAtTime == null && dynamicTimePeriod == null) {
            blockConfig.viewAtTime = null;
          } else {
            blockConfig.viewAtTime = {
              time: alignedViewAtTime,
              dynamicDateType: dynamicTimePeriod ?? null,
            };
          }
        },
      }),
    );
  };
};

export const { createBlock, updateBlock, updateBlocks } = mutationActions;
