import { createSelector } from '@reduxjs/toolkit';
import { mapValues } from 'lodash';
import sortBy from 'lodash/sortBy';
import { createCachedSelector } from 're-reselect';

import {
  getDatabaseInternalPageType,
  getObjectSpecIdFromInternalPageType,
  isDatabasePage,
} from 'config/internalPages/databasePage';
import { getSubmodelInternalPageType } from 'config/internalPages/modelPage';
import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import { isNotNull } from 'helpers/typescript';
import { BlockId, BlocksPage, BlocksPageId } from 'reduxStore/models/blocks';
import { PageRef, isBlocksPage, isSubmodelPage } from 'reduxStore/reducers/pageSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { accessCapabilitiesSelector } from 'selectors/accessCapabilitiesSelector';
import {
  blocksPageByInternalPageTypeSelector,
  blocksPagesSelector,
  blocksPagesTableSelector,
} from 'selectors/blocksPagesTableSelector';
import {
  businessObjectSpecIdsForLayerSelector,
  businessObjectSpecsByIdForLayerSelector,
} from 'selectors/businessObjectSpecsSelector';
import { blocksPageIdSelector, paramSelector } from 'selectors/constSelectors';
import { navSubmodelIdSelector } from 'selectors/navSubmodelSelector';
import { pageSelector } from 'selectors/pageBaseSelector';
import { currentPageRefSelector, pageIdSelector, pageTypeSelector } from 'selectors/pageSelector';
import { ParametricSelector, Selector } from 'types/redux';

export const blocksPagesByIdSelector: Selector<Record<BlocksPageId, BlocksPage | undefined>> =
  createSelector(blocksPagesTableSelector, (pages) => pages.byId);

export const blocksPageSelector: ParametricSelector<BlocksPageId, BlocksPage | undefined> =
  createCachedSelector(
    blocksPagesByIdSelector,
    blocksPageIdSelector,
    (blockPages, blockPageId) => blockPages[blockPageId],
  )((_state, blockPageId) => blockPageId);

export const userCreatedBlockPagesSelector: Selector<BlocksPage[]> = createDeepEqualSelector(
  blocksPagesSelector,
  (pages) => {
    return pages.filter((page) => page.internalPageType == null);
  },
);

export const blocksPageNameSelector: ParametricSelector<BlocksPageId, string> =
  createCachedSelector(
    blocksPageSelector,
    (state: RootState) => businessObjectSpecsByIdForLayerSelector(state),
    (page, objectSpecsById) => {
      if (page == null) {
        return 'Deleted';
      } else if (isDatabasePage(page.internalPageType)) {
        const objectSpecId = getObjectSpecIdFromInternalPageType(page.internalPageType);
        return objectSpecsById[objectSpecId]?.name ?? 'Deleted';
      }

      return page.name;
    },
  )((_state, blockPageId) => blockPageId);

export const blocksPageForInternalPageTypeSelector: ParametricSelector<
  string,
  BlocksPage | undefined
> = createCachedSelector(
  blocksPageByInternalPageTypeSelector,
  paramSelector<string>(),
  (pagesByInternalPageType, internalPageType) => pagesByInternalPageType[internalPageType],
)((state, internalPageType) => internalPageType);

export const pageIdWithSubmodelsSelector: ParametricSelector<PageRef, string | null> =
  createSelector(
    paramSelector<PageRef>(),
    blocksPageByInternalPageTypeSelector,
    (pageRef, pagesByInternalPageType) => {
      switch (pageRef.type) {
        case 'blocksPage':
          return pageRef.id;
        case 'plansPage':
        case 'unlistedDriversPage':
          return pageRef.id ?? null;
        case 'submodelPage':
          if (pageRef.id == null) {
            return null;
          }
          return pagesByInternalPageType[getSubmodelInternalPageType(pageRef.id)]?.id ?? null;
        default:
          return null;
      }
    },
  );

export const currentPageIdWithSubmodelsSelector: Selector<string | null> = (state: RootState) => {
  const pageRef = currentPageRefSelector(state);
  if (pageRef == null) {
    return null;
  }
  return pageIdWithSubmodelsSelector(state, pageRef);
};

export const currentBlocksPageSelector: Selector<BlocksPage | null> = createSelector(
  blocksPagesByIdSelector,
  currentPageIdWithSubmodelsSelector,
  (blocksPagesById, pageId) => {
    return pageId == null ? null : blocksPagesById[pageId] ?? null;
  },
);

export const currentInternalPageTypeSelector: Selector<string | null> = createSelector(
  currentBlocksPageSelector,
  (currentPage) => {
    return currentPage?.internalPageType ?? null;
  },
);

export const isCurrentPageDatabasePageSelector: Selector<boolean> = createSelector(
  currentInternalPageTypeSelector,
  (internalPageType) => isDatabasePage(internalPageType),
);

export const isCurrentPageDataSourcePageSelector: Selector<boolean> = createSelector(
  pageTypeSelector,
  (pageType) => pageType === 'dataSourcePage',
);

export const isCurrentPagePlansPageSelector: Selector<boolean> = createSelector(
  pageTypeSelector,
  (pageType) => pageType === 'plansPage',
);

export const isCurrentPageSubmodelPageSelector: Selector<boolean> = createSelector(
  pageTypeSelector,
  (pageType) => pageType === 'submodelPage',
);

export const isDatabasePageSelector: ParametricSelector<BlocksPageId, boolean> =
  createCachedSelector(blocksPagesByIdSelector, blocksPageIdSelector, (blocksPagesById, pageId) =>
    isDatabasePage(blocksPagesById[pageId]?.internalPageType),
  )((_state, pageId) => pageId);

const currentlyViewingBlocksPageIdSelector: Selector<BlocksPageId | null> = createSelector(
  pageSelector,
  (pageState) => {
    if (!isBlocksPage(pageState)) {
      return null;
    }
    return pageState.id;
  },
);

export const pageTitleIsAutoFocusedSelector: Selector<boolean> = createSelector(
  pageSelector,
  navSubmodelIdSelector,
  currentlyViewingBlocksPageIdSelector,
  (page, navSubmodelId, currentPageId) =>
    (isBlocksPage(page) &&
      page.autoFocus != null &&
      page.autoFocus.type === 'blocksPage' &&
      page.autoFocus.id === currentPageId) ||
    (isSubmodelPage(page) &&
      page.autoFocus?.type === 'submodel' &&
      page.autoFocus.id === navSubmodelId),
);

export const autoFocusedBlockIdSelector: Selector<BlockId | null> = createSelector(
  pageSelector,
  (page) =>
    isBlocksPage(page) && page.autoFocus != null && page.autoFocus.type === 'block'
      ? page.autoFocus.id
      : null,
);

export const isSegmentByMenuAutoFocusedSelector: Selector<boolean> = createSelector(
  pageSelector,
  (page) => page?.autoFocus?.type === 'segmentByMenu',
);

export const isViewingModelPageSelector: Selector<boolean> = createSelector(
  pageTypeSelector,
  (pageType) => pageType === 'submodelPage',
);

export const orderedDatabasePagesSelector: Selector<BlocksPage[]> = createSelector(
  blocksPageByInternalPageTypeSelector,
  businessObjectSpecIdsForLayerSelector,
  (blocksPageByInternalPageType, specIds) => {
    const internalPageTypes = specIds.map((id) => getDatabaseInternalPageType(id));
    const databasePages = internalPageTypes
      .map((type) => blocksPageByInternalPageType[type])
      .filter(isNotNull);

    return sortBy(databasePages, (p) => p.sortIndex ?? 0);
  },
);

export const blocksPagesByBlockIdSelector: Selector<NullableRecord<BlockId, BlocksPage>> =
  createDeepEqualSelector(blocksPagesSelector, (blocksPages) => {
    const blocksPagesByBlockId: NullableRecord<BlockId, BlocksPage> = {};

    blocksPages.forEach((page) => {
      page.blockIds.forEach((blockId) => {
        blocksPagesByBlockId[blockId] = page;
      });
    });

    return blocksPagesByBlockId;
  });

export const blocksPageInternalPageTypeByBlockIdSelector: Selector<
  NullableRecord<BlockId, string | undefined>
> = createDeepEqualSelector(blocksPagesByBlockIdSelector, (blocksPagesByBlockId) => {
  return mapValues(blocksPagesByBlockId, (page) => page?.internalPageType);
});

export const blocksPageForBlockIdSelector: ParametricSelector<BlockId, BlocksPage | undefined> =
  createSelector(
    paramSelector<BlockId>(),
    blocksPagesByBlockIdSelector,
    (blockId, blocksPagesByBlockId) => {
      return blocksPagesByBlockId[blockId];
    },
  );

export const navPageNameSelector: Selector<string | undefined> = createSelector(
  blocksPagesByIdSelector,
  pageIdSelector,
  (blocksPagesById, pageId) => {
    if (pageId == null) {
      return undefined;
    }
    return blocksPagesById[pageId]?.name;
  },
);

export const accessiblePagesSelector: Selector<BlocksPage[]> = createDeepEqualSelector(
  blocksPagesByIdSelector,
  accessCapabilitiesSelector,
  (blocksPageById, capabilitiesProvider) => {
    return Object.values(blocksPageById)
      .filter(isNotNull)
      .filter((page) => capabilitiesProvider.canReadPage(page.id));
  },
);
