import { Box, TabList, TabPanels, Tabs, useMergeRefs } from '@chakra-ui/react';
import { PropGetter } from '@chakra-ui/react-utils';
import { Rect } from '@popperjs/core';
import { PayloadAction } from '@reduxjs/toolkit';
import Tippy, { TippyProps } from '@tippyjs/react';
import { motion } from 'framer-motion';
import { produce } from 'immer';
import React, { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import { useKey } from 'react-use';

import EditFormulasPopoverTab from 'components/EditFormulasPopover/EditFormulasPopoverTab';
import FormulaTabPanel from 'components/EditFormulasPopover/FormulaTabPanel';
import { OnChangeFormulaArgs } from 'components/FormulaInput/FormulaInput';
import { FORMULA_DROPDOWN_CLASS, FORMULA_REFERENCE_CLASS } from 'config/formula';
import { NO_FLIP_MODIFIER } from 'config/popper';
import theme from 'config/theme';
import { stopEventPropagation } from 'helpers/browserEvent';
import { areFormulasEqual } from 'helpers/formula';
import { getFormulaError } from 'helpers/formulaEvaluation/ForecastCalculator/ForecastCalculator';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { useMonitorRenderPerformanceOnFormulaOpen } from 'hooks/useMonitorRenderPerformance';
import { useShakeControls } from 'hooks/useShakeControls';
import useWindowSize from 'hooks/useWindowSize';
import {
  createNewDriversInContext,
  updateAllDriverFormulas,
} from 'reduxStore/actions/driverMutations';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { DriverPropertyId } from 'reduxStore/models/collections';
import { DriverId } from 'reduxStore/models/drivers';
import { businessObjectFieldSpecByIdSelector } from 'selectors/businessObjectFieldSpecsSelector';
import {
  businessObjectsByFieldIdForLayerSelector,
  businessObjectsByIdForLayerSelector,
} from 'selectors/businessObjectsSelector';
import {
  dimensionalPropertyEvaluatorSelector,
  driverPropertySelector,
} from 'selectors/collectionSelector';
import {
  attributesBySubDriverIdSelector,
  dimDriverSelector,
  driverActualsFormulaSelector,
  driverForecastFormulaSelector,
  driversByIdForCurrentLayerSelector,
} from 'selectors/driversSelector';
import { formulaEvaluatorForLayerSelector } from 'selectors/formulaEvaluatorSelector';
import { submodelIdByBlockIdSelector } from 'selectors/submodelPageSelector';
import { RawFormula } from 'types/formula';

const PADDING: [number, number] = [50, 430];
const RIGHT_PADDING = 50;

type FormulaType = 'forecast' | 'actuals';

type FormulaState = {
  rawFormula: RawFormula | null;
  matchesOriginal: boolean;
  error: string | undefined;
};

type FormulaPopoverState = {
  activeTab: FormulaType;
  forecast: FormulaState;
  actuals: FormulaState;
};

type SetActiveTab = PayloadAction<'forecast' | 'actuals', 'setActiveTab'>;
type SetErrorMessage = PayloadAction<string, 'setErrorMessage'>;
type ChangeFormula = PayloadAction<
  {
    rawFormula: RawFormula | null;
    matchesOriginal: boolean;
    isActuals: boolean;
  },
  'changeFormula'
>;

type Action = SetActiveTab | SetErrorMessage | ChangeFormula;

function reducer(state: FormulaPopoverState, action: Action) {
  const isForecastActive = state.activeTab === 'forecast';
  switch (action.type) {
    case 'setActiveTab': {
      return produce(state, (draftState) => {
        draftState.activeTab = action.payload;
      });
    }
    case 'setErrorMessage': {
      return produce(state, (draftState) => {
        if (isForecastActive) {
          draftState.forecast.error = action.payload;
        } else {
          draftState.actuals.error = action.payload;
        }
      });
    }
    case 'changeFormula': {
      return produce(state, (draftState) => {
        const { isActuals, rawFormula, matchesOriginal } = action.payload;
        if (!isActuals) {
          draftState.forecast = {
            rawFormula,
            matchesOriginal,
            error: undefined,
          };
        } else {
          draftState.actuals = {
            rawFormula,
            matchesOriginal,
            error: undefined,
          };
        }
      });
    }
    default: {
      return state;
    }
  }
}

export type OnSaveEditFormulaPopoverContent = (params: {
  driverId: string;
  rawActualsFormula: string | null;
  rawForecastFormula: string | null;
  activeTab: FormulaType;
}) => void;

type FormulaPopoverEventHandlers = {
  onTabChange: (index: number) => void;
  onChange: ({ newFormula, matchesOrig, isActuals }: OnChangeFormulaArgs) => void;
  onSave: () => void;
  onClickOutside: (e: MouseEvent) => void;
};

type FormulaPopoverStateAndEventHandlers = FormulaPopoverState & FormulaPopoverEventHandlers;

interface UseEditFormulasStateAndEventHandlersProps {
  driverId: DriverId;
  defaultActiveTab?: FormulaType;
  onClose: () => void;
  onSave?: OnSaveEditFormulaPopoverContent;
}

export const useEditFormulasStateAndEventHandlers = ({
  driverId,
  defaultActiveTab,
  onClose,
  onSave,
}: UseEditFormulasStateAndEventHandlersProps): FormulaPopoverStateAndEventHandlers => {
  const dispatch = useAppDispatch();

  const driversById = useAppSelector(driversByIdForCurrentLayerSelector);
  const fieldSpecsById = useAppSelector(businessObjectFieldSpecByIdSelector);
  const attributesBySubDriverId = useAppSelector(attributesBySubDriverIdSelector);
  const objectsByFieldId = useAppSelector(businessObjectsByFieldIdForLayerSelector);
  const objectsById = useAppSelector(businessObjectsByIdForLayerSelector);
  const submodelIdByBlockId = useAppSelector(submodelIdByBlockIdSelector);
  const evaluator = useAppSelector(formulaEvaluatorForLayerSelector);
  const savedRawActualsFormula = useAppSelector((state) =>
    driverActualsFormulaSelector(state, { id: driverId }),
  );
  const savedRawForecastFormula = useAppSelector((state) =>
    driverForecastFormulaSelector(state, { id: driverId }),
  );

  const [localState, localDispatch] = useReducer(reducer, undefined, (): FormulaPopoverState => {
    return {
      activeTab: defaultActiveTab ?? 'forecast',
      actuals: {
        matchesOriginal: true,
        rawFormula: savedRawActualsFormula,
        error: undefined,
      },
      forecast: {
        matchesOriginal: true,
        rawFormula: savedRawForecastFormula,
        error: undefined,
      },
    };
  });

  const { actuals, forecast, activeTab } = localState;
  const isEditingActuals = activeTab === 'actuals';
  const formulaState = isEditingActuals ? actuals : forecast;
  const actualsError = actuals.error;
  const forecastError = forecast.error;
  const actualsFormula = actuals.rawFormula;
  const forecastFormula = forecast.rawFormula;

  const currFormula = formulaState.rawFormula;
  const unchanged =
    (actuals.matchesOriginal || areFormulasEqual(savedRawActualsFormula, actualsFormula)) &&
    (forecast.matchesOriginal || areFormulasEqual(savedRawForecastFormula, forecastFormula));
  const actionRequiredTab =
    actualsError != null && !isEditingActuals
      ? 'actuals'
      : isEditingActuals && forecastError != null
        ? 'forecast'
        : null;

  const handleSave = useCallback(() => {
    if (actionRequiredTab != null) {
      localDispatch({ type: 'setActiveTab', payload: actionRequiredTab });
    } else {
      const error =
        currFormula != null
          ? getFormulaError({
              formulaEntityId: { type: 'driver', id: driverId },
              rawFormula: currFormula,
              isActualsFormula: isEditingActuals,
              driversById,
              fieldSpecsById,
              attributesBySubDriverId,
              objectsByFieldId,
              objectsById,
              submodelIdByBlockId,
              evaluator,
            })?.message
          : null;

      if (error != null) {
        localDispatch({ type: 'setErrorMessage', payload: error });
        return;
      }

      if (!unchanged) {
        if (onSave != null) {
          onSave({
            driverId,
            rawActualsFormula: actualsFormula,
            rawForecastFormula: forecastFormula,
            activeTab,
          });
        } else {
          dispatch(
            updateAllDriverFormulas({
              id: driverId,
              actualsFormula,
              forecastFormula: forecastFormula ?? '',
            }),
          );
        }
      }

      onClose();
    }
  }, [
    actionRequiredTab,
    forecastFormula,
    currFormula,
    driverId,
    isEditingActuals,
    driversById,
    fieldSpecsById,
    attributesBySubDriverId,
    objectsByFieldId,
    objectsById,
    submodelIdByBlockId,
    evaluator,
    unchanged,
    onClose,
    onSave,
    actualsFormula,
    activeTab,
    dispatch,
  ]);

  const handleChange = useCallback(
    ({ newFormula, matchesOrig, isActuals }: OnChangeFormulaArgs) => {
      localDispatch({
        type: 'changeFormula',
        payload: {
          isActuals,
          rawFormula: newFormula,
          matchesOriginal: matchesOrig,
        },
      });
    },
    [],
  );

  const handleTabChange = useCallback(
    (index: number) => {
      if (currFormula != null) {
        const errorMessage = getFormulaError({
          formulaEntityId: { type: 'driver', id: driverId },
          rawFormula: currFormula,
          isActualsFormula: isEditingActuals,
          driversById,
          fieldSpecsById,
          attributesBySubDriverId,
          objectsByFieldId,
          objectsById,
          submodelIdByBlockId,
          evaluator,
        })?.message;

        if (errorMessage != null) {
          localDispatch({ type: 'setErrorMessage', payload: errorMessage });
        }
      }

      localDispatch({ type: 'setActiveTab', payload: index === 0 ? 'forecast' : 'actuals' });
    },
    [
      currFormula,
      driverId,
      isEditingActuals,
      driversById,
      fieldSpecsById,
      attributesBySubDriverId,
      objectsByFieldId,
      objectsById,
      submodelIdByBlockId,
      evaluator,
    ],
  );

  useKey(
    'Tab',
    (ev) => {
      ev.preventDefault();
      handleTabChange(isEditingActuals ? 0 : 1);
    },
    undefined,
    [handleTabChange, isEditingActuals],
  );

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      // avoid closing formula popover when clicking on elements that can be added as a formula reference,
      // e.g. driver name cells
      const target = e.target as HTMLElement;
      if (
        target != null &&
        target.closest(`.${FORMULA_REFERENCE_CLASS}, .${FORMULA_DROPDOWN_CLASS}`) != null
      ) {
        return;
      }

      // stop the propagation in order to NOT trigger ag grid's automatic closing of editor
      // sometimes we want the editor to remain open (e.g. there's a malformed formula)
      e.stopPropagation();
      handleSave();
    },
    [handleSave],
  );

  return useMemo(
    () => ({
      onTabChange: handleTabChange,
      onChange: handleChange,
      onSave: handleSave,
      onClickOutside: handleClickOutside,
      ...localState,
    }),
    [handleChange, handleClickOutside, handleSave, handleTabChange, localState],
  );
};

export const EditFormulasPopoverContent = React.forwardRef<
  HTMLDivElement,
  FormulaPopoverStateAndEventHandlers & { driverId: DriverId; onClose: () => void }
>(({ driverId, activeTab, forecast, actuals, onClose, onChange, onTabChange, onSave }, ref) => {
  const isDimensionalDriver = useAppSelector(
    (state) => dimDriverSelector(state, { id: driverId }) != null,
  );

  const isEditingActuals = activeTab === 'actuals';
  const { controls, shake } = useShakeControls();

  useMonitorRenderPerformanceOnFormulaOpen(driverId);

  const hasForecastError = forecast.error != null;
  const hasActualsError = actuals.error != null;
  const hasError = hasForecastError || hasActualsError;
  useEffect(() => {
    if (hasError) {
      shake();
    }
  }, [hasError, shake]);

  return (
    <Box
      as={motion.div}
      animate={controls}
      ref={ref}
      borderRadius="md"
      bg="white"
      boxShadow="menu"
      onMouseDown={stopEventPropagation}
    >
      <Tabs w="full" index={isEditingActuals ? 1 : 0} onChange={onTabChange}>
        <TabList>
          <EditFormulasPopoverTab
            label={isDimensionalDriver ? 'Default Forecast' : 'Forecast'}
            isActive={!isEditingActuals}
            hasError={hasForecastError}
          />
          <EditFormulasPopoverTab
            label={isDimensionalDriver ? 'Default Actuals' : 'Actuals'}
            isActive={isEditingActuals}
            hasError={hasActualsError}
          />
        </TabList>
        <TabPanels>
          <FormulaTabPanel
            driverId={driverId}
            error={forecast.error}
            isActive={!isEditingActuals}
            isActuals={false}
            onCancel={onClose}
            onChange={onChange}
            onSave={onSave}
          />
          <FormulaTabPanel
            driverId={driverId}
            error={actuals.error}
            isActive={isEditingActuals}
            isActuals
            onCancel={onClose}
            onChange={onChange}
            onSave={onSave}
          />
        </TabPanels>
      </Tabs>
    </Box>
  );
});

const DimDatabaseEditFormulasPopoverContent: React.FC<
  FormulaPopoverStateAndEventHandlers & { onClose: () => void } & DimDatabaseIds
> = (props) => {
  const { driverPropertyId, objectId, subdriverId: newSubdriverId } = props;
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const subdriverCreateRequestRef = useRef(false);

  const dimDriverIdForProperty = useAppSelector((state) =>
    driverPropertySelector(state, driverPropertyId),
  )?.driverId;
  const dimDriverForProperty = useAppSelector((state) => {
    if (dimDriverIdForProperty == null) {
      return undefined;
    }
    return dimDriverSelector(state, { id: dimDriverIdForProperty });
  });

  const dimensionalPropertyEvaluator = useAppSelector(dimensionalPropertyEvaluatorSelector);

  const attributes = useMemo(() => {
    return dimensionalPropertyEvaluator.getKeyAttributePropertiesForBusinessObject(objectId);
  }, [dimensionalPropertyEvaluator, objectId]);

  const matchingSubDriverId = useMemo(() => {
    if (dimDriverIdForProperty == null) {
      return null;
    }

    return dimensionalPropertyEvaluator.getSubDriverIdForAttributeIds(
      dimDriverIdForProperty,
      attributes.map((attr) => attr.attribute.id),
    );
  }, [attributes, dimDriverIdForProperty, dimensionalPropertyEvaluator]);

  useEffect(() => {
    if (dimDriverForProperty == null) {
      return;
    }
    if (matchingSubDriverId == null && !subdriverCreateRequestRef.current) {
      subdriverCreateRequestRef.current = true;

      dispatch(
        createNewDriversInContext({
          // create new subdriver
          newDrivers: [
            {
              id: newSubdriverId,
              name: dimDriverForProperty.name,
              dimDriver: {
                driverId: dimDriverForProperty?.id,
                attributes: attributes.map((attr) => attr.attribute),
              },
            },
          ],
          context: {
            blockId,
            skipAddingToSubmodel: true,
          },
          select: false,
        }),
      );
    }
  }, [attributes, blockId, dimDriverForProperty, dispatch, newSubdriverId, matchingSubDriverId]);

  if (matchingSubDriverId == null) {
    return null;
  }

  return <EditFormulasPopoverContent {...props} driverId={matchingSubDriverId} />;
};

interface EditFormulasPopoverWrapperProps {
  isOpen: boolean;
  children: JSX.Element;
  popover: JSX.Element;
  offset?: [number, number];
  onClickOutside: (e: MouseEvent) => void;
}

const EditFormulasPopoverWrapper: React.FC<EditFormulasPopoverWrapperProps> = ({
  isOpen,
  children,
  offset = [0, 0],
  popover,
  onClickOutside,
}) => {
  const { height } = useWindowSize();
  const triggerRef = useRef<HTMLDivElement>(null);
  // N.B. it's possible for the reference rect to exit the DOM while the popper is still visible
  const referenceRectRef = useRef<Rect | null>(null);
  const child = React.Children.only(children) as React.ReactElement & {
    ref?: React.Ref<any>;
  };
  const mergedRef = useMergeRefs(child.ref, triggerRef);
  const trigger = React.cloneElement(child, {
    ...child.props,
    ref: mergedRef,
  } as ReturnType<PropGetter>);

  // limits the vertical position of the popper within the window as the user scrolls
  const getPopperOptionsForFormulaBar = (): TippyProps['popperOptions'] => {
    const calculatePosition = (popper: Rect, reference: Rect) => {
      if (height == null) {
        return {};
      }

      const referenceRect = reference.x === 0 ? referenceRectRef.current ?? reference : reference;
      if (referenceRect.x !== 0) {
        referenceRectRef.current = referenceRect;
      }

      const [offsetX, offsetY] = offset;
      const [topPadding, bottomPadding] = PADDING;

      // Ensure on smaller viewports the popper xPos adjust to the left but never goes off screen on the very left
      const xPos = Math.max(
        Math.min(
          window.innerWidth - popper.width - offsetX - RIGHT_PADDING,
          referenceRect.x + offsetX,
        ),
        0,
      );
      const yPos = Math.max(topPadding, referenceRect.y - popper.height / 2 + offsetY);

      if (yPos + bottomPadding > height) {
        return {
          transform: `translate(${xPos}px, ${height - bottomPadding}px)`,
          transition: '0.8s ease-in-out',
        };
      } else {
        return {
          transform: `translate(${xPos}px, ${yPos}px )`,
          transition: 'none',
        };
      }
    };

    return {
      modifiers: [
        NO_FLIP_MODIFIER,
        {
          name: 'computeStyles',
          fn({ state }) {
            return {
              ...state,
              styles: {
                ...state.styles,
                popper: {
                  ...state.styles.popper,
                  ...calculatePosition(state.rects.popper, state.rects.reference),
                },
              },
            };
          },
        },
      ],
    };
  };

  // N.B. we are forced to register this onClickOutside handler via Tippy because ag grid's automatic editor handling
  // is run before our own useOnClickOutside hook.
  const onClickOutsideCallback = useCallback(
    (_i: any, e: MouseEvent) => {
      onClickOutside(e);
    },
    [onClickOutside],
  );

  return (
    <>
      {trigger}
      {isOpen && triggerRef.current != null && (
        <Tippy
          interactive
          visible
          offset={offset}
          zIndex={theme.zIndices.popover}
          popperOptions={getPopperOptionsForFormulaBar()}
          appendTo={document.body}
          reference={triggerRef.current}
          render={() => popover}
          onClickOutside={onClickOutsideCallback}
        />
      )}
    </>
  );
};

const EditFormulasPopover: React.FC<
  UseEditFormulasStateAndEventHandlersProps &
    Omit<EditFormulasPopoverWrapperProps, 'popover' | 'onClickOutside'>
> = ({ isOpen, children, offset = [0, 0], ...props }) => {
  const editFormulasStateAndEventHandlers = useEditFormulasStateAndEventHandlers({
    driverId: props.driverId,
    defaultActiveTab: props.defaultActiveTab,
    onClose: props.onClose,
    onSave: props.onSave,
  });

  return (
    <EditFormulasPopoverWrapper
      isOpen={isOpen}
      offset={offset}
      onClickOutside={editFormulasStateAndEventHandlers.onClickOutside}
      popover={<EditFormulasPopoverContent {...props} {...editFormulasStateAndEventHandlers} />}
    >
      {children}
    </EditFormulasPopoverWrapper>
  );
};

const EditDisallowedPopoverContent: React.FC = () => {
  return (
    <Box borderRadius="md" bg="white" boxShadow="menu" p={3}>
      To use drivers in this database, select at least one dimension to segment drivers by.
    </Box>
  );
};

interface DimDatabaseIds {
  driverPropertyId: DriverPropertyId;
  objectId: BusinessObjectId;
  subdriverId: DriverId;
}

export const DimDatabaseEditFormulasPopover: React.FC<
  Omit<UseEditFormulasStateAndEventHandlersProps, 'driverId'> &
    DimDatabaseIds &
    Omit<EditFormulasPopoverWrapperProps, 'popover' | 'onClickOutside'>
> = ({ isOpen, children, offset = [0, 0], ...props }) => {
  const { objectId } = props;
  const dimensionalPropertyEvaluator = useAppSelector(dimensionalPropertyEvaluatorSelector);
  const hasNoAttributes = useMemo(() => {
    return (
      dimensionalPropertyEvaluator.getKeyAttributePropertiesForBusinessObject(objectId).length === 0
    );
  }, [dimensionalPropertyEvaluator, objectId]);
  const editFormulasStateAndEventHandlers = useEditFormulasStateAndEventHandlers({
    driverId: props.subdriverId,
    defaultActiveTab: props.defaultActiveTab,
    onClose: props.onClose,
    onSave: props.onSave,
  });

  if (hasNoAttributes) {
    return (
      <EditFormulasPopoverWrapper
        isOpen={isOpen}
        offset={offset}
        onClickOutside={editFormulasStateAndEventHandlers.onClickOutside}
        popover={<EditDisallowedPopoverContent />}
      >
        {children}
      </EditFormulasPopoverWrapper>
    );
  }
  return (
    <EditFormulasPopoverWrapper
      isOpen={isOpen}
      offset={offset}
      onClickOutside={editFormulasStateAndEventHandlers.onClickOutside}
      popover={
        <DimDatabaseEditFormulasPopoverContent {...props} {...editFormulasStateAndEventHandlers} />
      }
    >
      {children}
    </EditFormulasPopoverWrapper>
  );
};

export default React.memo(EditFormulasPopover);
