import { Box, Flex } from '@chakra-ui/react';
import React, { useMemo } from 'react';

import { CellRendererParams } from 'components/AgGridComponents/CellRenderer/CellRenderer';
import TimeSeriesDisplayValue from 'components/AgGridComponents/CellRenderer/TimeSeriesDisplayValue';
import {
  CELL_VALUE_DISPLAY_VALUE_STYLE,
  CELL_VALUE_STYLE_TIMESERIES_STYLE,
} from 'components/AgGridComponents/CellRenderer/constants';
import {
  agGridCellValueStyle,
  getDatabaseCellTestId,
} from 'components/AgGridComponents/CellRenderer/helpers';
import { Row } from 'components/AgGridComponents/types/DatabaseColumnDef';
import ValueTransition from 'components/BusinessObjectsEditableValues/ValueTransition';
import CellPaletteWithPlanPicker from 'components/CellPalette/CellPaletteWithPlanPicker';
import { ImpactType, ValueType } from 'generated/graphql';
import { nullSafeEqual } from 'helpers/typescript';
import { DisplayConfiguration } from 'reduxStore/models/value';
import { CalculationError } from 'types/dataset';

interface TimeseriesCellRendererProps extends CellRendererParams<Row['data'][string]> {
  preferredDisplayConfiguration: DisplayConfiguration;
  showCellPalette?: boolean;
}

export const isTimeSeriesCellError = (c: Row['data'][string]): c is CalculationError =>
  c != null && typeof c === 'object' && 'error' in c;

const TimeseriesCellRenderer: React.FC<TimeseriesCellRendererProps> = ({
  colDef,
  data,
  value,
  preferredDisplayConfiguration,
  showCellPalette = false,
}) => {
  const { backingType, cellAlignment, displayAs, afterLastActuals, monthKey } = colDef.fieldSpec;

  const cellRef = React.useRef<HTMLDivElement>(null);
  const valueStyle = agGridCellValueStyle(value, data, colDef);
  const displayAsTimeseries = displayAs === 'timeseries';

  const displayValueCell = useMemo(() => {
    const displayAsTransitionValue = Array.isArray(value) && !nullSafeEqual(value[0], value[1]);
    let vals = Array.isArray(value) ? value : [value];

    // Override the value if a plan is present.
    if (
      monthKey != null &&
      data != null &&
      data.curvePointsByMonthKey[monthKey]?.impactType === ImpactType.Set
    ) {
      vals = [data.curvePointsByMonthKey[monthKey]?.value];
    }

    const firstErr = vals.find(isTimeSeriesCellError);
    if (!displayAsTransitionValue || firstErr != null) {
      const displayValue = firstErr != null ? undefined : vals[0];

      return (
        <TimeSeriesDisplayValue
          value={{
            value: displayValue != null ? Number(displayValue) : undefined,
            type: ValueType.Number,
          }}
          error={firstErr}
          displayConfiguration={preferredDisplayConfiguration}
          style={CELL_VALUE_DISPLAY_VALUE_STYLE[valueStyle]}
          timeSeriesStyle={CELL_VALUE_STYLE_TIMESERIES_STYLE[valueStyle]}
          cellAlignment={cellAlignment ?? 'right'}
          backingType={backingType}
        />
      );
    }
    return (
      <Flex justifyContent="end" h="full">
        <ValueTransition
          originalValue={{
            type: ValueType.Number,
            value: Number(value[0]),
          }}
          newValue={{
            type: ValueType.Number,
            value: Number(value[1]),
          }}
          displayConfiguration={preferredDisplayConfiguration}
          backingType={backingType}
        />
      </Flex>
    );
  }, [
    value,
    monthKey,
    data,
    preferredDisplayConfiguration,
    backingType,
    valueStyle,
    cellAlignment,
  ]);

  const testId = getDatabaseCellTestId(colDef);

  if (displayAsTimeseries) {
    return (
      <>
        {showCellPalette ? (
          <CellPaletteWithPlanPicker
            tableCellRef={cellRef}
            omitColorPicker
            isEditingActiveCell={false}
          />
        ) : null}
        <Box
          data-testid={testId}
          data-isforecast={afterLastActuals}
          data-valuestyle={valueStyle}
          height="full"
          width="full"
          alignContent="center"
          ref={cellRef}
        >
          {displayValueCell}
        </Box>
      </>
    );
  }

  return (
    <Box data-testid={testId} height="full" width="full">
      {displayValueCell}
    </Box>
  );
};

export default TimeseriesCellRenderer;
