import { deepEqual } from 'fast-equals';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { BlockFilterContextProvider } from 'components/BlockFilterContext/BlockFilterContext';
import { useObjectSpecFilterItems } from 'components/DatabaseBlock/useObjectSpecFilterItems';
import FilterMenu from 'components/FilterMenu/FilterMenu';
import FormulaDropdownContext from 'components/FormulaInput/FormulaDropdownContext';
import FormulaSelectionContext from 'components/FormulaInput/FormulaSelectionContext';
import ObjectSpecPropertyMenu from 'components/ObjectSpecPropertyMenu/ObjectSpecPropertyMenu';
import SelectMenu, { SelectItem } from 'components/SelectMenu/SelectMenu';
import SelectMenuItem from 'components/SelectMenu/SelectMenuItem';
import useBlockContext from 'hooks/useBlockContext';
import { useSubmenuState } from 'hooks/useSubmenuState';
import { EntityInfo, filterIsComplete, FilterItem } from 'types/filtering';
import { ObjectSpecEntityData } from 'types/formula';
interface Props {
  entity: ObjectSpecEntityData;
}

type SubmenuType = 'property' | 'filters';
type SubmenuSelectMenuItem = SelectItem & { id: SubmenuType };

const getSubmenuType = (type: string | null): SubmenuType | null => {
  return type === 'filters' ? type : null;
};

const PROPERTY_ITEM: SubmenuSelectMenuItem = {
  id: 'property',
  name: 'Property',
  hasNextMenu: true,
};

const FILTERS_ITEM: SubmenuSelectMenuItem = {
  id: 'filters',
  name: 'Filters',
  hasNextMenu: true,
};

const EMPTY_FILTERS: FilterItem[] = [];
const MENU_ITEMS = [PROPERTY_ITEM, FILTERS_ITEM];

const ObjectSpecEntityDropdownMenu: React.FC<Props> = ({ entity }) => {
  const { blockId } = useBlockContext();
  const activeEntityKey = entity.data.id;
  const { resetSelectionState, defaultSubmenu } = useContext(FormulaSelectionContext);
  const initSubmenu = getSubmenuType(defaultSubmenu);
  const { submenu, setSubmenu, onSelect, clearSubmenu } = useSubmenuState<SubmenuType>(initSubmenu);

  useEffect(() => {
    setSubmenu(initSubmenu);
  }, [setSubmenu, initSubmenu, activeEntityKey]);

  const { onSelectObjectFilters } = useContext(FormulaDropdownContext);

  const specId = entity.data.id;
  const availableFilters = useObjectSpecFilterItems(specId, blockId);

  const [filters, setFilters] = useState<FilterItem[]>(() => {
    const formulaFilters = entity.data.filters ?? [];
    if (formulaFilters.length > 0) {
      return formulaFilters;
    }

    return EMPTY_FILTERS;
  });

  const onUpdateFilters = useCallback(
    (newFilters: FilterItem[]) => {
      setFilters(newFilters);
      const completedFilters = newFilters.filter(filterIsComplete);
      // Only update draft if completedFilters list has changed
      if (!deepEqual(entity.data.filters, completedFilters)) {
        onSelectObjectFilters(newFilters);
      }
    },
    [entity.data.filters, onSelectObjectFilters],
  );

  const blockFilterContext = useMemo(() => {
    const entityInfo: EntityInfo = {
      id: entity.data.id,
      label: entity.data.label ?? '',
      type: 'object',
    };

    return {
      entityInfo,
      filters,
      activeFilters: (entity.data.filters ?? []).filter(filterIsComplete),
      onUpdateFilters,
      onDoneFilters: () => {
        clearSubmenu();
        resetSelectionState();
      },
      availableFilters,
    };
  }, [
    entity.data.id,
    entity.data.label,
    entity.data.filters,
    filters,
    onUpdateFilters,
    availableFilters,
    clearSubmenu,
    resetSelectionState,
  ]);

  return (
    <>
      {submenu == null && (
        <SelectMenu
          items={MENU_ITEMS}
          onSelect={onSelect}
          onClose={resetSelectionState}
          startFocusIdx={-1}
        >
          {({ item, isFocused, idx }) => (
            <SelectMenuItem
              key={item.id}
              idx={idx}
              isFocused={isFocused}
              name={item.name}
              icon={item.icon}
              hasNextMenu={item.hasNextMenu}
              shortcutHint={null}
            />
          )}
        </SelectMenu>
      )}
      {submenu === 'property' && (
        <ObjectSpecPropertyMenu
          specId={specId}
          onClose={clearSubmenu}
          isThisRef={entity.data.isThisRef}
        />
      )}
      {submenu === 'filters' && (
        <BlockFilterContextProvider value={blockFilterContext}>
          <FilterMenu onClose={clearSubmenu} />
        </BlockFilterContextProvider>
      )}
    </>
  );
};

export default ObjectSpecEntityDropdownMenu;
