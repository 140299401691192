import { Flex, Popover, PopoverContent, PopoverTrigger, Portal } from '@chakra-ui/react';
import React, { useCallback, useMemo } from 'react';

import { ContextMenuItem } from 'components/ContextMenuItems/ContextMenuItem';
import ContextMenuItems from 'components/ContextMenuItems/ContextMenuItems';
import { useRemoveDriver } from 'components/CustomizeDriverChartsBlock/hooks';
import DragHandleButton from 'components/DragHandleButton/DragHandleButton';
import MoreMenuButton from 'components/MoreMenuButton/MoreMenuButton';
import useAppDispatch from 'hooks/useAppDispatch';
import useBlockContext from 'hooks/useBlockContext';
import useControlledPopover from 'hooks/useControlledPopover';
import useOpenDetailViewContextMenu from 'hooks/useOpenDetailViewContextMenuItem';
import { removeDriverReferenceFromBlock } from 'reduxStore/actions/driverReferenceMutations';
import { DriverId } from 'reduxStore/models/drivers';
import { PaneType } from 'reduxStore/reducers/detailPaneSlice';
import TrashIcon from 'vectors/Trash';

interface Props {
  driverId: DriverId;
  disableRemoving?: boolean;
  showOnGroupHover?: boolean;
  onOpenDetailPane?: () => void;
  canDrag: boolean;
}

const DragButton = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      driverId,
      disableRemoving = false,
      showOnGroupHover = true,
      onOpenDetailPane,
      canDrag = false,
    },
    ref,
  ) => {
    const dispatch = useAppDispatch();
    const { blockId, readOnly } = useBlockContext();
    const handleRemoveDriver = useRemoveDriver();

    const removeDriver = useCallback(() => {
      handleRemoveDriver(driverId);
      dispatch(removeDriverReferenceFromBlock({ driverId, blockId }));
    }, [handleRemoveDriver, dispatch, driverId, blockId]);

    const { isOpen, onOpen, onClose, contentRef, triggerRef } = useControlledPopover();

    const openDriverDetailPaneOption = useOpenDetailViewContextMenu({
      type: PaneType.Driver,
      id: driverId,
      onOpen: onOpenDetailPane,
      hideShortcut: true,
    });

    const menuItems: ContextMenuItem[] = useMemo(
      () => [
        ...(openDriverDetailPaneOption ? [openDriverDetailPaneOption] : []),
        ...(disableRemoving || readOnly
          ? []
          : [
              {
                text: 'Remove driver',
                onSelect: removeDriver,
                icon: <TrashIcon />,
                multiSelect: false,
                destructive: true,
              },
            ]),
      ],
      [openDriverDetailPaneOption, disableRemoving, readOnly, removeDriver],
    );

    return (
      <Popover
        isLazy
        placement="bottom-end"
        returnFocusOnClose={false}
        closeOnBlur
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      >
        <PopoverTrigger>
          <Flex
            ref={triggerRef}
            zIndex="modal"
            boxShadow="none"
            alignItems="center"
            justifyContent="center"
            visibility={isOpen || !showOnGroupHover ? 'visible' : 'hidden'}
            _groupHover={showOnGroupHover ? { visibility: 'visible' } : undefined}
          >
            {canDrag ? (
              <DragHandleButton ref={ref} bgColor="white" aria-label="Click to open menu" />
            ) : (
              <MoreMenuButton ref={ref} aria-label="Click to open menu" />
            )}
          </Flex>
        </PopoverTrigger>
        <Portal>
          <PopoverContent ref={contentRef} padding={0} boxShadow="none">
            <ContextMenuItems items={menuItems} closeMenu={onClose} />
          </PopoverContent>
        </Portal>
      </Popover>
    );
  },
);

export default DragButton;
