import { useBoolean } from '@chakra-ui/react';
import React, { useCallback, useContext } from 'react';
import { useKey } from 'react-use';

import { shouldOpenPlanPicker } from '@features/Plans';
import { CellValueContext } from 'config/cells';
import { DriverFormat } from 'generated/graphql';
import { isInputKeyboardEvent } from 'helpers/browserEvent';
import { isPrintableChar } from 'helpers/string';
import useAppDispatch from 'hooks/useAppDispatch';
import { handleCommandEnter, navigateToNextCell } from 'reduxStore/actions/cellNavigation';
import { Value } from 'reduxStore/models/value';
import {
  closePopover,
  setIsEditingActiveCell,
  setPlanPickerSelectedEventGroupId,
} from 'reduxStore/reducers/pageSlice';

const useActiveCell = <V extends Value>({
  onSave,
  onEditTriggered,
  onStartEditing,
  onEndEditing,
}: {
  onSave?: (value: V | undefined, formatUpdate: DriverFormat | undefined) => void;
  onEditTriggered?: (key: string) => void;
  onStartEditing?: () => void;
  onEndEditing?: () => void;
}) => {
  const dispatch = useAppDispatch();
  const isEditable = onSave != null;
  const [isEditing, setIsEditing] = useBoolean(false);
  const canDirectlyEdit = onStartEditing == null;
  const { markJustEdited } = useContext(CellValueContext);

  const onStartEditingCallback = useCallback(() => {
    if (isEditable) {
      setIsEditing.on();
      onStartEditing?.();
      dispatch(setIsEditingActiveCell());
    }
  }, [dispatch, isEditable, setIsEditing, onStartEditing]);

  useKey(
    (ev) => {
      return (
        !isInputKeyboardEvent(ev) &&
        !ev.metaKey &&
        !ev.ctrlKey &&
        !isEditing &&
        isEditable &&
        (isPrintableChar(ev.key) || ev.key === 'Enter' || ev.key === 'Delete')
      );
    },
    (ev) => {
      // if the cell is directly editable without going through a popover, let the
      // global key handler take care of it; otherwise, pop into editing mode
      if ((ev.key === 'Delete' || ev.key === 'Backspace') && canDirectlyEdit) {
        return;
      }

      ev.stopPropagation();
      ev.preventDefault();
      onStartEditingCallback();
      onEditTriggered?.(ev.key);
    },
    undefined,
    [isEditing, isEditable, onStartEditingCallback, canDirectlyEdit],
  );

  const onEndEditingCallback = useCallback(() => {
    setIsEditing.off();
    dispatch(setPlanPickerSelectedEventGroupId(undefined));
    onEndEditing?.();
  }, [dispatch, onEndEditing, setIsEditing]);

  const onSaveCallback = useCallback(
    (val: V | null | undefined, formatUpdate: DriverFormat | undefined) => {
      onSave?.(val ?? undefined, formatUpdate);
      markJustEdited();
      onEndEditingCallback();
    },
    [onSave, markJustEdited, onEndEditingCallback],
  );

  const onCancel = useCallback(() => {
    onEndEditingCallback();
    dispatch(closePopover());
  }, [onEndEditingCallback, dispatch]);

  const onKeyDown: React.KeyboardEventHandler = useCallback(
    (ev) => {
      const { shiftKey, key } = ev;

      if (shouldOpenPlanPicker(ev.nativeEvent)) {
        window.requestAnimationFrame(() => dispatch(handleCommandEnter({})));
        return;
      }

      switch (key) {
        case 'Enter': {
          if (shiftKey) {
            // Do not navigate to next cell
            break;
          }

          // Navigate to next cell
          // N.B. we requestAnimationFrame here to allow the previously selected cell to
          // save any pending changes on blur before it's unmounted
          window.requestAnimationFrame(() => dispatch(navigateToNextCell('right')));

          break;
        }
        case 'Tab': {
          const direction = shiftKey ? 'left' : 'right';

          // N.B. we requestAnimationFrame here to allow the previously selected cell to
          // save any pending changes on blur before it's unmounted
          window.requestAnimationFrame(() => dispatch(navigateToNextCell(direction)));
          break;
        }
        default: {
          break;
        }
      }
    },
    [dispatch],
  );

  return {
    isEditable,
    isEditing,
    onStartEditingCallback,
    onSaveCallback,
    onCancel,
    onEndEditingCallback,
    onKeyDown,
  };
};

export default useActiveCell;
