import { mapValues } from 'lodash';

import { Event, EventId } from 'reduxStore/models/events';
import { MonthKey } from 'types/datetime';

function getEventsByMonthKey(events: Event[]): Record<MonthKey, Event> {
  const eventsByMonthKey: Record<MonthKey, Event> = {};
  events.forEach((event) => {
    Object.keys(event.customCurvePoints ?? {}).forEach((mk) => {
      eventsByMonthKey[mk] = event;
    });
  });
  return eventsByMonthKey;
}

export function eventIdsByMonthKeys(events: Event[]): Record<MonthKey, EventId> {
  const eventsByMonthKey = getEventsByMonthKey(events);

  return mapValues(eventsByMonthKey, (event) => event.id);
}

export function getEventAndParentIdsByMonthKey(
  events: Event[],
): Record<MonthKey, Pick<Event, 'id' | 'parentId'>> {
  const eventsByMonthKey = getEventsByMonthKey(events);

  return mapValues(eventsByMonthKey, (event) => ({ id: event.id, parentId: event.parentId }));
}
