import { Flex } from '@chakra-ui/react';

import useAppSelector from 'hooks/useAppSelector';
import {
  currentLayerCreatedByUserSelector,
  currentLayerIsDraftSelector,
  currentLayerWasCreatedByUser,
} from 'selectors/layerSelector';

export const ScenarioCreatedByUserTag: React.FC = () => {
  const isDraft = useAppSelector(currentLayerIsDraftSelector);
  const isCreatedByLoggedInUser = useAppSelector(currentLayerWasCreatedByUser);
  const createdByUser = useAppSelector(currentLayerCreatedByUserSelector);

  if (!isDraft || isCreatedByLoggedInUser || createdByUser == null) {
    return null;
  }

  return (
    <Flex
      borderRadius="md"
      px={1}
      borderWidth="px"
      borderColor="gray.500"
      color="gray.600"
      fontSize="xxs"
      lineHeight="1rem"
      sx={{ color: 'gray.500', _parentAriaSelected: { color: 'white', borderColor: 'white' } }}
      align="center"
    >
      by {createdByUser.name}
    </Flex>
  );
};
