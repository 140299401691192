import { Flex } from '@chakra-ui/react';
import { Dictionary, isEqual, keyBy } from 'lodash';
import { ReactNode } from 'react';

import { Entity, FolderType } from 'reduxStore/models/folder';
import { Database, FormulaIcon, PageIcon } from 'vectors';

function areEntitiesEqual(first: Entity, second: Entity) {
  return (
    first.id === second.id &&
    first.type === second.type &&
    first.name === second.name &&
    first.image.type === second.image.type &&
    ((first.image.type === 'emoji' && first.image.content === second.image.content) ||
      first.image.type !== 'emoji') &&
    isEqual(first.permissions, second.permissions) &&
    first.context === second.context &&
    first.sortIndex === second.sortIndex &&
    first.parentId === second.parentId
  );
}

export function getFirstNavigableEntity(root: Entity): Entity | null {
  const entityStack = [root];
  while (entityStack.length > 0) {
    const entity = entityStack.pop();
    if (entity == null) {
      return null;
    }

    if (entity.type !== 'folder') {
      return entity;
    }

    if (entity.children.length > 0) {
      // reverse the order of children so that the first child is the next to be popped
      entityStack.push(...entity.children.toReversed());
    }
  }

  return null;
}

export function getParentFolder(entity: Entity) {
  if (entity.type === 'folder') {
    return entity;
  }

  if (entity.parent === null) {
    return null;
  }

  return getParentFolder(entity.parent);
}

export function getRootEntity(): Entity {
  return {
    id: 'root',
    type: 'folder',
    name: 'root',
    image: {
      type: 'emoji',
      content: null,
    },
    context: 'workspace',
    permissions: { read: true, index: true, edit: true, create: true, destroy: true },
    sortIndex: null,
    parentId: null,
    parent: null,
    children: [],
  };
}

export function buildNavigationTree(entities: Entity[]) {
  const root = getRootEntity();
  const byId = keyBy(entities, 'id');
  const folderByType = keyBy(entities, (entity) => {
    return entity.type === 'folder' ? entity.context : 'none';
  });

  // NOTE: this resets the mutable parts of an entity: the children and parent
  resetEntities(entities);

  const withChildren: Set<Entity> = new Set([root]);
  for (const entity of entities) {
    let parent = entity.parentId != null ? byId[entity.parentId] : null;
    if (parent == null) {
      if (entity.type === 'folder') {
        entity.parent = root;
        parent = root;
      } else if (entity.parentId == null) {
        const folder = folderByType[entity.type];
        entity.parent = folder;
        parent = folder;
      } else {
        const folder = folderByType.uncategorized;
        entity.parent = folder;
        parent = folder;
      }
    } else {
      entity.parent = parent;
    }

    parent.children.push(entity);
    withChildren.add(parent);
  }

  // NOTE: there is a rare possibility that circular dependency may occur
  // the changes to parent id are not checked for circularity on the backend
  // this removes the circularity by pretending there is no parent.
  const circularEntities = removeCircularity(entities);
  if (circularEntities.length > 0) {
    return buildNavigationTree(entities);
  }

  for (const entity of withChildren) {
    entity.children.sort((first, second) => {
      const firstSortIndex = first.type === 'page' ? first.sortIndex : first.sortIndex ?? 0;
      const secondSortIndex = second.type === 'page' ? second.sortIndex : second.sortIndex ?? 0;
      if (firstSortIndex === null || secondSortIndex === null) {
        return 1;
      }
      return firstSortIndex > secondSortIndex ? 1 : -1;
    });
  }

  return root;
}

function resetEntities(entities: Entity[]) {
  for (const entity of entities) {
    entity.parent = null;
    entity.children = [];
  }
}

function removeCircularity(entities: Entity[]): Entity[] {
  const circularEntities = [];
  for (const entity of entities) {
    if (isCircular(entity)) {
      circularEntities.push(entity);
    }
  }

  for (const entity of circularEntities) {
    entity.parentId = null;
  }
  return circularEntities;
}

function isCircular(entity: Entity) {
  let tortoise: Entity | null = entity;
  let hare: Entity | null = entity;

  while (hare !== null && hare.parent) {
    tortoise = tortoise?.parent ?? null;
    hare = hare.parent?.parent ?? null;

    if (tortoise === null || hare === null) {
      return false;
    }

    if (tortoise.id === hare.id) {
      return true;
    }
  }

  return false;
}

export const DEFAULT_ICON_MAP: Partial<Record<FolderType, ReactNode>> = {
  model: (
    <Flex
      color="gray.500"
      width={6}
      height={6}
      fontSize="sm"
      justifyContent="center"
      alignItems="center"
    >
      <FormulaIcon boxSize="4" />
    </Flex>
  ),
  database: (
    <Flex
      color="gray.500"
      width={6}
      height={6}
      fontSize="sm"
      justifyContent="center"
      alignItems="center"
    >
      <Database boxSize="4" />
    </Flex>
  ),
  page: (
    <Flex
      color="gray.500"
      width={6}
      height={6}
      fontSize="sm"
      justifyContent="center"
      alignItems="center"
    >
      <PageIcon boxSize="4" />
    </Flex>
  ),
};

// NOTE: the code below is attempting to change as few of the references to entities as possible
// an existing entity will be reused
// this is an attempt at making individual entities immutable
export function computeEntityDiff(
  computedEntities: Entity[],
  existingEntities: Entity[],
  entitiesById: Dictionary<Entity>,
): Entity[] {
  let useExisting = computedEntities.length === Object.values(entitiesById).length;

  const revised: Entity[] = [];
  for (const computed of computedEntities) {
    if (!(computed.id in entitiesById)) {
      useExisting = false;
      revised.push(computed);
      continue;
    }

    const existing = entitiesById[computed.id];
    if (!areEntitiesEqual(existing, computed)) {
      useExisting = false;
      revised.push(computed);
      continue;
    }

    revised.push(existing);
  }

  if (useExisting) {
    return existingEntities;
  }

  return revised;
}
