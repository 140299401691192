import { Box } from '@chakra-ui/react';
import React, { useContext } from 'react';

import DriverEntityDropdownMenu from 'components/FormulaDropdown/DriverEntityDropdownMenu';
import ObjectSpecEntityDropdownMenu from 'components/FormulaDropdown/ObjectSpecEntityDropdownMenu';
import ThisSegmentDropdownMenu from 'components/FormulaDropdown/ThisSegmentDropdownMenu';
import FormulaDropdownContext from 'components/FormulaInput/FormulaDropdownContext';
import FormulaSelectionContext from 'components/FormulaInput/FormulaSelectionContext';
import FormulaReferenceMenu, {
  FormulaReferenceMenuProps,
} from 'components/FormulaReferenceMenu/FormulaReferenceMenu';
import EntityTimePeriodSelectionMenu from 'components/TimePeriodSelectionMenu/EntityTimePeriodSelectionMenu';
import { FORMULA_DROPDOWN_CLASS } from 'config/formula';
import {
  isDriverEntityData,
  isExtDriverEntityData,
  isObjectSpecEntityData,
  isSubmodelEntityData,
} from 'helpers/formula';

const FormulaDropdown: React.FC<FormulaReferenceMenuProps> = (props) => {
  const { activeEntity } = useContext(FormulaDropdownContext);
  const { defaultSubmenu } = useContext(FormulaSelectionContext);
  return (
    <Box
      boxShadow="menu"
      borderRadius="md"
      className={FORMULA_DROPDOWN_CLASS}
      data-testid="formula-dropdown"
    >
      {defaultSubmenu === 'thisSegment' ? (
        <ThisSegmentDropdownMenu />
      ) : activeEntity != null ? (
        <>
          {isDriverEntityData(activeEntity) && <DriverEntityDropdownMenu entity={activeEntity} />}
          {(isExtDriverEntityData(activeEntity) || isSubmodelEntityData(activeEntity)) && (
            <EntityTimePeriodSelectionMenu entity={activeEntity} />
          )}
          {isObjectSpecEntityData(activeEntity) && (
            <ObjectSpecEntityDropdownMenu entity={activeEntity} />
          )}
        </>
      ) : (
        <FormulaReferenceMenu {...props} />
      )}
    </Box>
  );
};

export default React.memo(FormulaDropdown);
