import { Box, Flex, StyleProps } from '@chakra-ui/react';
import React from 'react';

import TimeSeriesColumnHeaders from 'components/TimeSeriesColumnHeaders/TimeSeriesColumnHeaders';
import { TimeSeriesHeaderDataType } from 'components/TimeSeriesHeaderLabel/TimeSeriesHeaderLabel';
import { CELL_HEIGHT_IN_PX } from 'config/cells';
import { TimeSeriesColumn } from 'helpers/rollups';
import { toPxString } from 'helpers/styles';

interface Props {
  columns: TimeSeriesColumn[];
  dataType?: TimeSeriesHeaderDataType;
  children?: React.ReactNode;
  pl?: StyleProps['pl'];
}

const StickyColumnsAndTimeSeriesHeader: React.FC<Props> = ({
  columns,
  children,
  dataType = 'driver',
  pl,
}) => {
  const hasSubLabels = columns.some((col) => col.subLabel != null);

  return (
    <Flex width="fit-content" bgColor="white" id="sticky-columns-and-timeseries-header-wrapper">
      {children != null && (
        <Flex
          flexDir="column"
          left={0}
          position="sticky"
          zIndex="sticky"
          pt={hasSubLabels ? toPxString(CELL_HEIGHT_IN_PX) : undefined}
          bgColor="white"
          pl={pl}
          id="sticky-column-headers"
        >
          {children}
          <Box
            id="last-sticky-column-right-border"
            h="full"
            w="px"
            position="absolute"
            right={0}
            bgColor="gray.400"
            top={0}
          />
        </Flex>
      )}
      <TimeSeriesColumnHeaders columns={columns} dataType={dataType} />
    </Flex>
  );
};

export default React.memo(StickyColumnsAndTimeSeriesHeader);
