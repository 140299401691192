import { Box, Flex, LayoutProps } from '@chakra-ui/react';
import { noop } from 'lodash';
import React, { useCallback, useRef, useState } from 'react';
import { useEffectOnce } from 'react-use';

import DatabasePropertyNameSelectItem from 'components/BusinessObjectTable/DatabasePropertyNameSelectItem';
import LookupSubMenu from 'components/BusinessObjectTable/SettingsPopoverContents/DimensionalDatabaseNewProperty/LookupSubMenu';
import {
  ObjectFieldPopupItem,
  RenamePropertyItem,
} from 'components/BusinessObjectTable/SettingsPopoverContents/useContextMenuItems';
import SelectMenu, { Section } from 'components/SelectMenu/SelectMenu';
import SelectMenuItem from 'components/SelectMenu/SelectMenuItem';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import { DimensionalPropertyId, DriverPropertyId } from 'reduxStore/models/collections';

function isRenamePropertyItem(item: ObjectFieldPopupItem): item is RenamePropertyItem {
  return item.id === 'renameProperty';
}

interface Props {
  isAutoFocused: boolean;
  items: ObjectFieldPopupItem[];
  sections: Section[];
  onClose?: () => void;
  isOpen?: boolean;
  fieldSpecId?: BusinessObjectFieldSpecId;
  dimPropertyId?: DimensionalPropertyId;
  driverPropertyId?: DriverPropertyId;
  width?: LayoutProps['width'];
}

const SettingsPopoverContentBaseComponent = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      onClose,
      isOpen,
      isAutoFocused,
      items,
      sections,
      fieldSpecId,
      dimPropertyId,
      driverPropertyId,
      width = 'fit-content',
    },
    passedRef,
  ) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [isLookupSubMenuOpen, setIsLookupSubMenuOpen] = useState<boolean>(false);
    const onSelect = useCallback(
      (item: ObjectFieldPopupItem) => {
        if (isRenamePropertyItem(item) || item.onSelect == null) {
          return;
        }
        if (item.id === 'lookup') {
          setIsLookupSubMenuOpen(true);
          return;
        }
        item.onSelect();
        if (item.shouldClose == null || item.shouldClose) {
          onClose?.();
        }
      },
      [onClose],
    );

    const onLookupMenuGoBack = useCallback(() => {
      setIsLookupSubMenuOpen(false);
    }, []);

    const onLookupMenuClose = useCallback(() => {
      setIsLookupSubMenuOpen(false);
      onClose?.();
    }, [onClose]);

    useEffectOnce(() => {
      if (isAutoFocused) {
        // hacky: ensure the input is rendered before selecting content
        window.requestAnimationFrame(() => inputRef.current?.select());
      }
    });

    if (!isOpen) {
      return null;
    }

    return (
      <Flex
        flexDirection="column"
        height="fit-content"
        bgColor="white"
        borderRadius="base"
        boxShadow="menu"
        ref={passedRef}
      >
        {isLookupSubMenuOpen && dimPropertyId != null && (
          <Box padding={2}>
            <LookupSubMenu
              onBack={onLookupMenuGoBack}
              onClose={onLookupMenuClose}
              dimensionalPropertyId={dimPropertyId}
            />
          </Box>
        )}
        {!isLookupSubMenuOpen && (
          <SelectMenu
            items={items}
            sections={sections}
            onSelect={onSelect}
            onClose={onClose}
            width={width}
            maxHeight="unset"
          >
            {({ item, isFocused, idx }) =>
              isRenamePropertyItem(item) ? (
                <DatabasePropertyNameSelectItem
                  ref={inputRef}
                  idx={idx}
                  isFocused={isFocused}
                  fieldSpecId={fieldSpecId}
                  dimPropertyId={dimPropertyId}
                  driverPropertyId={driverPropertyId}
                  onClose={onClose ?? noop}
                />
              ) : (
                <SelectMenuItem
                  key={item.id}
                  name={item.name}
                  hasNextMenu={item.hasNextMenu}
                  icon={item.icon}
                  iconColor={item.iconColor}
                  isDisabled={item.isDisabled}
                  isChecked={item.isChecked}
                  checkedStyle={item.checkedStyle}
                  meta={item.meta}
                  idx={idx}
                  isFocused={isFocused}
                  shortcutHint={null}
                />
              )
            }
          </SelectMenu>
        )}
      </Flex>
    );
  },
);

export default React.memo(SettingsPopoverContentBaseComponent);
