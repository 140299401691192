import { useCallback, useState } from 'react';

import { OnChangeFormulaArgs } from 'components/FormulaInput/FormulaInput';
import { areFormulasEqual } from 'helpers/formula';
import { getFormulaError } from 'helpers/formulaEvaluation/ForecastCalculator/ForecastCalculator';
import { FormulaDisplay } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaDisplayListener';
import { FormulaEntityTypedId } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import useAppSelector from 'hooks/useAppSelector';
import { businessObjectFieldSpecByIdSelector } from 'selectors/businessObjectFieldSpecsSelector';
import { businessObjectsByFieldIdForLayerSelector } from 'selectors/businessObjectsSelector';
import {
  attributesBySubDriverIdSelector,
  driversByIdForCurrentLayerSelector,
} from 'selectors/driversSelector';
import { formulaEvaluatorForLayerSelector } from 'selectors/formulaEvaluatorSelector';
import { submodelIdByBlockIdSelector } from 'selectors/submodelPageSelector';
import { RawFormula } from 'types/formula';

type Args = {
  formulaEntityId: FormulaEntityTypedId;
  isActuals: boolean;
  rawFormula: RawFormula | null;
  formulaDisplay: FormulaDisplay | null;
  onSaveFormulaWithError: () => void;
  onClose: () => void;
  onSaveFormula: (newFormula: RawFormula | null) => void;
};

export default function useFormulaInput({
  formulaEntityId,
  isActuals,
  rawFormula,
  formulaDisplay,
  onSaveFormulaWithError,
  onSaveFormula,
  onClose,
}: Args) {
  const driversById = useAppSelector(driversByIdForCurrentLayerSelector);
  const fieldSpecsById = useAppSelector(businessObjectFieldSpecByIdSelector);
  const attributesBySubDriverId = useAppSelector(attributesBySubDriverIdSelector);
  const objectsByFieldId = useAppSelector(businessObjectsByFieldIdForLayerSelector);
  const objectsById = useAppSelector(businessObjectsByFieldIdForLayerSelector);
  const submodelIdByBlockId = useAppSelector(submodelIdByBlockIdSelector);
  const evaluator = useAppSelector(formulaEvaluatorForLayerSelector);
  const [currFormula, setCurrFormula] = useState<RawFormula | null>(null);
  const [matchesOriginal, setMatchesOriginal] = useState<boolean>(true);
  const savedFormulaError = formulaDisplay?.error;
  const [formulaError, setFormulaError] = useState<string | undefined>(savedFormulaError);
  const onCloseCallback = useCallback(() => {
    onClose();
  }, [onClose]);

  const onCancelCallback = useCallback(() => {
    setCurrFormula(null);
    setMatchesOriginal(true);
    setFormulaError(savedFormulaError);
    onCloseCallback();
  }, [savedFormulaError, onCloseCallback]);

  const onSaveCallback = useCallback(() => {
    if (currFormula != null) {
      const errorMessage = getFormulaError({
        formulaEntityId,
        rawFormula: currFormula,
        isActualsFormula: isActuals,
        driversById,
        fieldSpecsById,
        attributesBySubDriverId,
        objectsByFieldId,
        objectsById,
        submodelIdByBlockId,
        evaluator,
      })?.message;

      if (errorMessage != null) {
        if (errorMessage !== formulaError) {
          setFormulaError(errorMessage);
        }
        onSaveFormulaWithError();
        return;
      }
    }

    if (matchesOriginal || areFormulasEqual(currFormula, rawFormula)) {
      onCancelCallback();
      return;
    }

    onCloseCallback();
    onSaveFormula(currFormula);
  }, [
    currFormula,
    matchesOriginal,
    rawFormula,
    onCloseCallback,
    onSaveFormula,
    formulaEntityId,
    isActuals,
    driversById,
    fieldSpecsById,
    attributesBySubDriverId,
    objectsByFieldId,
    objectsById,
    submodelIdByBlockId,
    evaluator,
    formulaError,
    onSaveFormulaWithError,
    onCancelCallback,
  ]);

  const onChange = useCallback(({ newFormula, matchesOrig }: OnChangeFormulaArgs) => {
    setCurrFormula(newFormula);
    setMatchesOriginal(matchesOrig);
    setFormulaError(undefined);
  }, []);

  return {
    onSave: onSaveCallback,
    onCancel: onCancelCallback,
    onChange,
    formulaError,
  };
}
