const DEFAULT_SHADOW =
  '0 4px 10px rgba(18, 29, 43, 0.1), 0 0 2px rgba(21, 30, 40, 0.2), 0 10px 20px rgba(27, 30, 34, 0.16)';

const shadows = {
  button: '0px 1px 0px rgba(34, 45, 68, 0.04)',
  primaryButton: '0px 1px 0px rgba(215, 239, 254, 0.25)',
  card: '0 0 2px rgba(51, 81, 119, 0.2), 0 5px 15px rgba(51, 81, 119, 0.05)',
  menu: DEFAULT_SHADOW,
  modal: DEFAULT_SHADOW,
  tooltip: DEFAULT_SHADOW,
  popover: DEFAULT_SHADOW,
  timelinePill: '0px 2px 4px 0px #45516124, 0px 0px 1px 0px #4551613D',
  timelinePillEnhanced:
    '0px 0px 1px 0px rgba(69, 81, 97, 0.5), 0px 2px 4px 0px rgba(69, 81, 97, 0.25)',
  appContentShadow:
    '0px -2px 5px 0px rgba(143, 143, 143, 0.04), 0px -8px 8px 0px rgba(143, 143, 143, 0.03), 0px -18px 11px 0px rgba(143, 143, 143, 0.02), 0px -33px 13px 0px rgba(143, 143, 143, 0.01), 0px -51px 14px 0px rgba(143, 143, 143, 0.00)',
};

export default shadows;
