import { PropsWithChildren, useMemo } from 'react';

import { CellType } from 'config/cells';
import { DriverRowContext, DriverRowContextValue } from 'config/driverRowContext';
import { ComparisonColumn, ComparisonTimePeriod } from 'generated/graphql';
import { getRequiresBaselineLayer, getRequiresVariance } from 'helpers/comparisons';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import { DriverId } from 'reduxStore/models/drivers';
import { LayerId } from 'reduxStore/models/layers';
import {
  driverRollupReducerSelector,
  timeSeriesColumnsForBlockSelector,
} from 'selectors/rollupSelector';

interface Props {
  driverId: DriverId;
  comparisonRowLayerId?: LayerId;
  groupId?: DriverGroupId;
  comparisonType?: ComparisonColumn;
  comparisonTimePeriod?: ComparisonTimePeriod;
  rowType?: CellType.Driver | CellType.ImpactDriver;
}

const DriverRowContextProvider: React.FC<PropsWithChildren<Props>> = ({
  driverId,
  comparisonRowLayerId,
  groupId,
  comparisonType,
  comparisonTimePeriod,
  rowType,
  children,
}) => {
  const { blockId } = useBlockContext();
  const columns = useAppSelector((state) => timeSeriesColumnsForBlockSelector(state, blockId));
  const rollupReducer = useAppSelector((state) =>
    driverRollupReducerSelector(state, { id: driverId }),
  );

  const driverRowContextValue: DriverRowContextValue = useMemo(() => {
    const requiresBaselineLayer =
      getRequiresBaselineLayer(comparisonType) ||
      columns.some(({ subLabel }) => getRequiresBaselineLayer(subLabel?.column));
    const requiresVariance = columns.some(({ subLabel }) => getRequiresVariance(subLabel?.column));
    const monthKeys = columns.flatMap(({ mks }) => mks);

    return {
      driverId,
      comparisonRowLayerId,
      groupId,
      requiresBaselineLayer,
      requiresVariance,
      monthKeys,
      comparisonType,
      rollupReducer,
      rowType,
      comparisonTimePeriod,
    };
  }, [
    comparisonType,
    columns,
    driverId,
    comparisonRowLayerId,
    groupId,
    rollupReducer,
    rowType,
    comparisonTimePeriod,
  ]);

  return (
    <DriverRowContext.Provider value={driverRowContextValue}>{children}</DriverRowContext.Provider>
  );
};

export default DriverRowContextProvider;
