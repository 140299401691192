import { deepEqual } from 'fast-equals';
import uniqBy from 'lodash/uniqBy';

import {
  DRIVER_FORMULA_COLUMNS,
  scenarioComparisonPage,
} from 'config/internalPages/scenarioComparisonPage';
import { ACTUALS_FORMULA_COLUMN_TYPE, FORECAST_FORMULA_COLUMN_TYPE } from 'config/modelView';
import {
  BlockColumnOptions,
  BlockComparisonLayout,
  BlockComparisonsInput,
  ComparisonColumn,
  Maybe,
} from 'generated/graphql';
import { updateBlocks } from 'reduxStore/actions/blockMutations';
import { createInternalPage } from 'reduxStore/actions/internalPages';
import { navigateToScenarioComparisonModal } from 'reduxStore/actions/navigateTo';
import { DEFAULT_LAYER_ID, LayerId } from 'reduxStore/models/layers';
import { openCompareScenariosModal } from 'reduxStore/reducers/pageSlice';
import { AppThunk } from 'reduxStore/store';
import { blocksPageForInternalPageTypeSelector } from 'selectors/blocksPagesSelector';
import { blocksByIdSelector } from 'selectors/blocksSelector';
import { currentLayerIdSelector, parentLayerSelector } from 'selectors/layerSelector';
import {
  additionalLayerIdsToCompareSelector,
  fixedLayerIdsToCompareSelector,
  nextAdditionalLayerIdToCompareOptionSelector,
  scenarioComparisonPageDatabaseBlockIdSelector,
  scenarioComparisonPageDriverBlockIdSelector,
} from 'selectors/scenarioComparisonSelector';

export const openScenarioComparisonModal =
  ({ compareToLayerIds }: { compareToLayerIds: LayerId[] }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const layerId = currentLayerIdSelector(state);
    const additionalLayerIdsToCompare = compareToLayerIds.filter((id) => id !== DEFAULT_LAYER_ID);
    dispatch(updateComparisonLayersOnScenarioComparisonBlock({ additionalLayerIdsToCompare }));
    dispatch(openCompareScenariosModal({ showMerge: layerId !== DEFAULT_LAYER_ID }));
  };

const patchBlockColumnOptionsIfNecessary = (columns?: Maybe<BlockColumnOptions[]>) => {
  if (columns == null) {
    return columns;
  }
  return columns.map((col) => {
    if (!Object.hasOwn(col, 'visible')) {
      return {
        ...col,
        visible: true, // missing visibility property should default to true
      };
    }
    return col;
  });
};
export const updateComparisonLayersOnScenarioComparisonBlock =
  ({ additionalLayerIdsToCompare }: { additionalLayerIdsToCompare: LayerId[] }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const parentLayer = parentLayerSelector(state);
    const fixedLayerIdsToCompare = fixedLayerIdsToCompareSelector(state);
    const additionalLayerIds = additionalLayerIdsToCompare.filter(
      (id) => !fixedLayerIdsToCompare.includes(id),
    );
    const driverBlockId = scenarioComparisonPageDriverBlockIdSelector(state);
    const databaseBlockId = scenarioComparisonPageDatabaseBlockIdSelector(state);
    const comparisons: BlockComparisonsInput = {
      layerIds: [...fixedLayerIdsToCompare, ...additionalLayerIds],
      columns: [ComparisonColumn.RowVersion, ComparisonColumn.BaselineVersion],
      layout: BlockComparisonLayout.Rows,
      baselineLayerId: parentLayer?.id ?? DEFAULT_LAYER_ID,
    };
    const { internalPageType, version, makeWithBlockComparisons } = scenarioComparisonPage;
    const page = blocksPageForInternalPageTypeSelector(state, internalPageType);
    // if scenario comparison page has not been created, create it.
    if (
      driverBlockId == null ||
      databaseBlockId == null ||
      page == null ||
      (page.internalPageVersion ?? 0) < version
    ) {
      dispatch(createInternalPage(makeWithBlockComparisons({ comparisons })));
    } else {
      const blocksById = blocksByIdSelector(state);
      const driverBlockConfig = blocksById[driverBlockId].blockConfig;
      const databaseBlockConfig = blocksById[databaseBlockId].blockConfig;
      if (
        !deepEqual(driverBlockConfig.comparisons, comparisons) ||
        !deepEqual(blocksById[databaseBlockId].blockConfig.comparisons, comparisons)
      ) {
        dispatch(
          updateBlocks([
            {
              id: databaseBlockId,
              blockConfig: {
                ...databaseBlockConfig,
                columns: patchBlockColumnOptionsIfNecessary(databaseBlockConfig.columns),
                comparisons,
              },
            },
            {
              id: driverBlockId,
              blockConfig: {
                ...driverBlockConfig,
                columns: patchBlockColumnOptionsIfNecessary(driverBlockConfig.columns),
                comparisons,
              },
            },
          ]),
        );
      }

      // For orgs with existing scenario comparison pages, we need to add the formula columns
      // if the FF is enabled.
      const shouldAddFormulaColumns =
        !driverBlockConfig.columns?.some((col) => col.key === FORECAST_FORMULA_COLUMN_TYPE) ||
        !driverBlockConfig.columns?.some((col) => col.key === ACTUALS_FORMULA_COLUMN_TYPE);

      const columns = uniqBy(
        [...(blocksById[driverBlockId].blockConfig.columns ?? []), ...DRIVER_FORMULA_COLUMNS],
        'key',
      );
      if (shouldAddFormulaColumns) {
        dispatch(
          updateBlocks([
            {
              id: driverBlockId,
              blockConfig: {
                ...driverBlockConfig,
                columns: patchBlockColumnOptionsIfNecessary(columns),
              },
            },
          ]),
        );
      }
    }
  };

export const addNewComparisonLayer = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const additionalLayerIdsToCompare = additionalLayerIdsToCompareSelector(state);
  const layerId = nextAdditionalLayerIdToCompareOptionSelector(state);
  if (layerId == null) {
    return;
  }
  const newCompareToLayerIds = [DEFAULT_LAYER_ID, ...additionalLayerIdsToCompare, layerId];
  dispatch(
    navigateToScenarioComparisonModal({
      compareToLayerIds: newCompareToLayerIds,
    }),
  );
};

export const removeComparisonLayer =
  (layerId: LayerId): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const additionalLayerIdsToCompare = additionalLayerIdsToCompareSelector(state);
    const index = additionalLayerIdsToCompare.findIndex((elem) => elem === layerId);
    if (index < 0) {
      return;
    }
    const newCompareToLayerIds = [
      DEFAULT_LAYER_ID,
      ...additionalLayerIdsToCompare.slice(0, index),
      ...additionalLayerIdsToCompare.slice(index + 1),
    ];
    dispatch(
      navigateToScenarioComparisonModal({
        compareToLayerIds: newCompareToLayerIds,
      }),
    );
  };

export const replaceComparisonLayer =
  ({ layerId, newLayerId }: { layerId: LayerId; newLayerId: LayerId }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const additionalLayerIdsToCompare = additionalLayerIdsToCompareSelector(state);
    const index = additionalLayerIdsToCompare.findIndex((elem) => elem === layerId);
    if (index < 0) {
      return;
    }
    const newCompareToLayerIds = [
      DEFAULT_LAYER_ID,
      ...additionalLayerIdsToCompare
        .slice(0, index)
        .filter((elem) => elem !== layerId && elem !== newLayerId),
      newLayerId,
      ...additionalLayerIdsToCompare
        .slice(index + 1)
        .filter((elem) => elem !== layerId && elem !== newLayerId),
    ];
    dispatch(
      navigateToScenarioComparisonModal({
        compareToLayerIds: newCompareToLayerIds,
      }),
    );
  };
