import { Draft } from '@reduxjs/toolkit';
import keyBy from 'lodash/keyBy';

import {
  BusinessObjectCreateInput,
  BusinessObjectDeleteInput,
  BusinessObjectFieldInput,
  BusinessObjectFieldUpdateInput,
  BusinessObjectFieldValueInput,
  BusinessObjectFieldValueUpdateInput,
  BusinessObjectMetadataInput,
  BusinessObjectUpdateInput,
  EventDeleteInput,
  EventGroupDeleteInput,
  ExtObjectDeleteInput,
  BusinessObjectField as GqlBusinessObjectField,
  BusinessObjectFieldValue as GqlBusinessObjectFieldValue,
  ValueType,
} from 'generated/graphql';
import { extractMonthKey, inferDate } from 'helpers/dates';
import { getAttributeValueString } from 'helpers/dimensionalDrivers';
import { convertTimeSeries, isDefinedTimeseriesPoint } from 'helpers/gqlDataset';
import { getNumber } from 'helpers/number';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import {
  BusinessObjectFieldSpec,
  BusinessObjectFieldSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import {
  BusinessObject,
  BusinessObjectField,
  BusinessObjectFieldValue,
} from 'reduxStore/models/businessObjects';
import { DatasetSnapshot, ToValueTimeSeries } from 'reduxStore/models/dataset';
import { Dimension, DimensionId } from 'reduxStore/models/dimensions';
import { isObjectFieldEvent } from 'reduxStore/models/events';
import { DefaultLayer, Layer, LightLayer } from 'reduxStore/models/layers';
import {
  NullableValue,
  toAttributeValue,
  toNumberValue,
  toTimestampValue,
  toValueType,
} from 'reduxStore/models/value';
import {
  mapCollectionEntry,
  updateCollectionEntryFromInput,
} from 'reduxStore/reducers/helpers/collections';
import { handleDeleteEventGroups, handleDeleteEvents } from 'reduxStore/reducers/helpers/events';
import { handleDeleteExtObjects } from 'reduxStore/reducers/helpers/extObjects';

export function setBusinessObjectsFromDatasetSnapshot(
  layer: Draft<LightLayer>,
  defaultLayer: Draft<DefaultLayer>,
  dataset: DatasetSnapshot,
) {
  if (dataset == null) {
    layer.businessObjects = { byId: {}, allIds: [] };
    return;
  }

  const { businessObjects } = dataset;
  const businessObjectList = businessObjects
    .map((gqlBusinessObject) => {
      const { id, name, specId, fields, defaultEventGroupId, metadata, collectionEntry } =
        gqlBusinessObject;
      const businessObject: BusinessObject = {
        id,
        name,
        specId,
        defaultEventGroupId: defaultEventGroupId ?? undefined,
        fields: fields?.map(mapBusinessObjectFieldFromSnapshot) ?? [],
        ...convertMetadataForBusinessObject(metadata),
        collectionEntry: collectionEntry
          ? mapCollectionEntry(collectionEntry, defaultLayer.attributes.byId)
          : undefined,
      };

      return businessObject;
    })
    .filter(isNotNull);

  layer.businessObjects = {
    byId: keyBy(businessObjectList, 'id'),
    allIds: businessObjectList.map((o) => o.id),
  };
}

export function handleCreateBusinessObject(
  layer: Draft<Layer>,
  defaultLayer: Draft<DefaultLayer>,
  newObjectInput: BusinessObjectCreateInput,
) {
  const { id, name, specId, fields, defaultEventGroupId, metadata, collectionEntry } =
    newObjectInput;

  // It is possible that we're on a layer where we have created an object
  // before someone then removed  the spec from the main layer. Since we rebase
  // layers automatically, this will create an object for a non existent spec
  // which can cause issues elsewhere. For instance, in the "merge scenarios"
  // modal.
  const spec = layer.businessObjectSpecs.byId[specId];
  if (spec == null) {
    return;
  }
  const dimensionPropertyIds = spec.collection?.dimensionalProperties.map((p) => p.id) ?? [];

  const newBusinessObject: BusinessObject = {
    id,
    name,
    specId,
    fields: fields?.map(mapBusinessObjectFieldInput) ?? [],
    defaultEventGroupId: defaultEventGroupId ?? undefined,
    remoteId: metadata?.remoteId ?? undefined,
    extKey: metadata?.extKey ?? undefined,
    ...convertMetadataForBusinessObject(metadata),
  };

  if (collectionEntry != null) {
    newBusinessObject.collectionEntry = mapCollectionEntry(
      {
        attributeProperties:
          collectionEntry.attributeProperties != null
            ? collectionEntry.attributeProperties.filter((p) =>
                dimensionPropertyIds.includes(p.dimensionalPropertyId),
              )
            : undefined,
      },
      defaultLayer.attributes.byId,
    );
  }

  layer.businessObjects.byId[id] = newBusinessObject;
  layer.businessObjects.allIds.push(id);
}

export function handleDeleteBusinessObjects(
  layer: Draft<Layer>,
  defaultLayer: Draft<DefaultLayer>,
  inputs: BusinessObjectDeleteInput[],
) {
  const objectIdsToDelete = new Set(inputs.map((input) => input.id));
  const objsToDelete = inputs
    .map((obj) => safeObjGet(layer.businessObjects.byId[obj.id]))
    .filter(isNotNull);

  const extObjectsToDelete: ExtObjectDeleteInput[] = objsToDelete
    .map((obj) => (obj.extKey != null ? { extKey: obj.extKey } : null))
    .filter(isNotNull);

  const eventsToDelete: EventDeleteInput[] = [];
  const eventGroupsToDelete: EventGroupDeleteInput[] = [];

  layer.businessObjects.allIds = layer.businessObjects.allIds.filter(
    (id) => !objectIdsToDelete.has(id),
  );

  const events = Object.values(layer.events.byId);
  const eventGroupsById = layer.eventGroups.byId;
  objsToDelete.forEach((object) => {
    // delete events and default event group associated to this object
    const objectFieldIds = object.fields.map((f) => f.id);
    Object.values(events).forEach((event) => {
      if (isObjectFieldEvent(event) && objectFieldIds.includes(event.businessObjectFieldId)) {
        eventsToDelete.push({ id: event.id });
      }
    });

    if (object.defaultEventGroupId != null) {
      const eventGroupToDelete = eventGroupsById[object.defaultEventGroupId];
      if (eventGroupToDelete != null) {
        eventGroupsToDelete.push({ id: eventGroupToDelete.id });
      }
    }

    delete layer.businessObjects.byId[object.id];
  });

  handleDeleteEvents(layer, eventsToDelete);
  handleDeleteEventGroups(layer, eventGroupsToDelete);
  handleDeleteExtObjects(defaultLayer, extObjectsToDelete);
}

export function handleUpdateBusinessObject(
  layer: Draft<LightLayer>,
  defaultLayer: Draft<DefaultLayer>,
  updateObjectInput: BusinessObjectUpdateInput,
) {
  const {
    id,
    name,
    fields,
    addFields,
    deleteFields,
    defaultEventGroupId,
    metadata,
    updateCollectionEntry,
  } = updateObjectInput;

  const object = layer.businessObjects.byId[id];
  if (object == null) {
    return;
  }

  // It is possible that we're on a layer where we have created an object
  // before someone then removed  the spec from the main layer. Since we rebase
  // layers automatically, this will update an object for a non existent spec
  // which can cause issues elsewhere.
  const spec = layer.businessObjectSpecs.byId[object.specId];
  if (spec == null) {
    return;
  }

  if (name != null && name.trim() !== '') {
    object.name = name;
  }

  Object.assign(object, convertMetadataForBusinessObject(metadata));

  if (defaultEventGroupId != null) {
    object.defaultEventGroupId = defaultEventGroupId;
  }

  // TODO (T-17861): This is patching a data issue where we are trying to add
  // redundant fields to an object. This results in two fields existing for the
  // same field spec id which has caused issues (see ticket). We should fix the
  // underlying issue after we get out of trouble.
  //
  // Add fields need to be processed before editing fields in case
  // both occur in the same batch.
  if (addFields != null && addFields.length > 0) {
    const fieldIdxBySpecID: Map<BusinessObjectFieldSpecId, number> = new Map();
    object.fields.forEach((field, idx) => {
      fieldIdxBySpecID.set(field.fieldSpecId, idx);
    });

    addFields.forEach((fieldAdd) => {
      const mapped = mapBusinessObjectFieldInput(fieldAdd);
      const existingIdx = fieldIdxBySpecID.get(fieldAdd.fieldSpecId);
      if (existingIdx != null) {
        object.fields[existingIdx] = mapped;
      } else {
        // N.B. Since we push to the end of the array, we don't need to update
        // the indexes that are used in the case above.
        object.fields.push(mapped);
      }
    });
  }

  fields?.forEach((fieldUpdate) => {
    const existingField = object.fields.find((m) => m.id === fieldUpdate.id);
    if (existingField == null) {
      return;
    }
    applyFieldUpdate(existingField, fieldUpdate);
  });

  if (deleteFields != null && deleteFields.length > 0) {
    object.fields = object.fields.filter((f) => !deleteFields.includes(f.id));

    // Clean up events on these fields
    const allEvents = Object.values(layer.events.byId);
    const eventsToCleanup = allEvents.filter(
      (e) => isObjectFieldEvent(e) && deleteFields.includes(e.businessObjectFieldId),
    );
    handleDeleteEvents(
      layer,
      eventsToCleanup.map((e) => ({ id: e.id })),
    );
  }

  if (updateCollectionEntry != null) {
    object.collectionEntry = updateCollectionEntryFromInput(
      defaultLayer,
      updateCollectionEntry,
      object.collectionEntry,
      spec.collection,
    );
  }
}

function applyFieldUpdate(
  existingField: BusinessObjectField,
  fieldUpdate: BusinessObjectFieldUpdateInput,
): void {
  if (fieldUpdate.value == null) {
    return;
  }
  if (existingField.value == null) {
    existingField.value = mapBusinessObjectFieldValue(fieldUpdate.value);
  } else {
    const actualsUpdate = fieldUpdate.value.actuals;
    const existingActuals = existingField.value.actuals;
    if (actualsUpdate != null) {
      if (actualsUpdate.formula == null || actualsUpdate.formula === '') {
        existingActuals.formula = undefined;
      } else {
        existingActuals.formula = actualsUpdate.formula;
      }

      actualsUpdate.timeSeries?.forEach((point) => {
        if (existingActuals.timeSeries == null) {
          existingActuals.timeSeries = {};
        }

        const monthKey = extractMonthKey(point.time);
        if (!isDefinedTimeseriesPoint(point)) {
          delete existingActuals.timeSeries[monthKey];
        } else {
          existingActuals.timeSeries[monthKey] = toValueType(
            point.value,
            existingField.value?.type ?? ValueType.Number,
          );
        }
      });
    }
    const initialValueUpdate = fieldUpdate.value.initialValue;
    if (initialValueUpdate != null) {
      if (initialValueUpdate === '') {
        existingField.value.initialValue = undefined;
      } else {
        existingField.value.initialValue = initialValueUpdate;
      }
    }
  }
}

function mapBusinessObjectFieldInput(input: BusinessObjectFieldInput): BusinessObjectField {
  return {
    id: input.id,
    fieldSpecId: input.fieldSpecId,
    value: mapBusinessObjectFieldValue(input.value),
  };
}

export function mapBusinessObjectFieldValue(
  input:
    | BusinessObjectFieldValueInput
    | GqlBusinessObjectFieldValue
    | BusinessObjectFieldValueUpdateInput,
): BusinessObjectFieldValue {
  const actualsTimeSeries =
    input.actuals?.timeSeries != null
      ? convertTimeSeries(input.actuals.timeSeries, input.type)
      : {};
  return {
    type: input.type,
    actuals: {
      formula: input.actuals?.formula ?? undefined,
      timeSeries: actualsTimeSeries,
    },
    initialValue: input.initialValue ?? undefined,
  };
}

export function mapBusinessObjectFieldValueFromSnapshot(
  input: ToValueTimeSeries<GqlBusinessObjectFieldValue>,
): BusinessObjectFieldValue {
  const res = {
    type: input.type,
    actuals: {
      formula: input.actuals?.formula ?? undefined,
      timeSeries: input.actuals?.timeSeries ?? {},
    },
    initialValue: input.initialValue ?? undefined,
  };
  return res;
}

function mapBusinessObjectFieldFromSnapshot(
  gqlObjectField: ToValueTimeSeries<GqlBusinessObjectField>,
): BusinessObjectField {
  const gqlValue = gqlObjectField.value;
  const value = gqlValue != null ? mapBusinessObjectFieldValueFromSnapshot(gqlValue) : undefined;

  return {
    id: gqlObjectField.id,
    fieldSpecId: gqlObjectField.fieldSpecId,
    value,
  };
}

export function convertFieldInputStringToValue(
  inputStr: string,
  fieldSpec: BusinessObjectFieldSpec,
  dimensionsById: Record<DimensionId, Dimension>,
): NullableValue | undefined {
  if (inputStr === null || inputStr === '') {
    return { type: fieldSpec.type, value: undefined };
  }
  if (fieldSpec.type === ValueType.Attribute) {
    return convertDimensionInputStringToValue(inputStr, fieldSpec.dimensionId, dimensionsById);
  } else if (fieldSpec.type === ValueType.Timestamp) {
    const dt = inferDate(inputStr);
    if (dt == null) {
      return undefined;
    }
    return toTimestampValue(dt.startOf('day'));
  } else {
    const format = fieldSpec.numericFormat;
    const val = getNumber(inputStr, format);
    if (isNaN(val)) {
      return undefined;
    }
    return toNumberValue(val);
  }
}

export function convertDimensionInputStringToValue(
  inputStr: string,
  dimensionId: DimensionId,
  dimensionsById: Record<DimensionId, Dimension>,
): NullableValue | undefined {
  if (inputStr === null || inputStr === '') {
    return { type: ValueType.Attribute, value: undefined };
  }
  const dim = dimensionsById[dimensionId];
  if (dim == null) {
    return undefined;
  }
  const matchingAttr = dim.attributes.find(
    (attr) => getAttributeValueString(attr).toLowerCase() === inputStr.toLowerCase(),
  );
  if (matchingAttr == null) {
    return undefined;
  }
  return toAttributeValue(matchingAttr.id);
}

function convertMetadataForBusinessObject(
  metadata: BusinessObjectMetadataInput | null | undefined,
) {
  const attributes: { remoteId?: string; extKey?: string; databaseConfigId?: string } = {};
  if (metadata == null) {
    return {};
  }
  if (metadata.remoteId != null) {
    attributes.remoteId = metadata.remoteId;
  }
  if (metadata.extKey != null) {
    attributes.extKey = metadata.extKey;
  }
  if (metadata.databaseConfigId != null) {
    attributes.databaseConfigId = metadata.databaseConfigId;
  }
  return attributes;
}
