import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'RepeatClockIcon',
  viewBox: '0 0 16 16',
  defaultProps: {
    fill: '#464A53',
  },
  path: (
    <>
      <path d="M9.55 10.78C9.78 10.78 10 10.68 10.15 10.49C10.4 10.16 10.34 9.68999 10.01 9.43999L8.35 8.15999V5.73999C8.35 5.32999 8.01 4.98999 7.6 4.98999C7.19 4.98999 6.85 5.32999 6.85 5.73999V8.52999C6.85 8.75999 6.96 8.97999 7.14 9.11999L9.1 10.62C9.24 10.72 9.4 10.78 9.56 10.78H9.55Z" />
      <path d="M8.12 1.03003C4.34 1.03003 1.27 4.11003 1.27 7.88003C1.27 9.21003 1.67 10.5 2.38 11.6H1.78C1.37 11.6 1.03 11.94 1.03 12.35C1.03 12.76 1.37 13.1 1.78 13.1H4.33C4.74 13.1 5.08 12.76 5.08 12.35V9.98003C5.08 9.57003 4.74 9.23003 4.33 9.23003C3.92 9.23003 3.58 9.57003 3.58 9.98003V10.7C3.06 9.86003 2.77 8.88003 2.77 7.88003C2.77 4.93003 5.17 2.53003 8.12 2.53003C11.07 2.53003 13.47 4.93003 13.47 7.89003C13.47 10.85 11.08 13.23 8.12 13.23C7.71 13.23 7.37 13.57 7.37 13.98C7.37 14.39 7.71 14.73 8.13 14.73C11.9 14.73 14.97 11.66 14.97 7.88003C14.97 4.10003 11.9 1.03003 8.12 1.03003Z" />
    </>
  ),
});
