import { groupBy, keyBy } from 'lodash';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import {
  ComparatorType,
  DatabaseConfigForDatabaseSource,
  DatabaseConfigForExtTableSource,
  DatabaseConfigSourceType,
  ExtTableColumn,
} from 'generated/graphql';
import { DimensionalProperty } from 'reduxStore/models/collections';

export const InvalidColumnErrors = ['time', 'driver', 'segmentedBy', 'metadata', 'filter'] as const;
export type InvalidColumnErrorType = (typeof InvalidColumnErrors)[number];
const COLUMN_TYPE_TO_DISPLAY_NAME: Record<InvalidColumnErrorType, string> = {
  time: 'date',
  driver: 'driver',
  segmentedBy: 'segmented by',
  metadata: 'metadata',
  filter: 'filter',
};

export class InvalidDatabaseConfigError extends Error {
  all: InvalidDatabaseConfigElementError[];
  byType: Partial<Record<string, InvalidDatabaseConfigElementError[]>>;

  constructor(errors: InvalidDatabaseConfigElementError[]) {
    super('Invalid database config');
    this.all = errors;
    this.byType = groupBy(errors, ({ type }) => type) as Partial<
      Record<string, InvalidDatabaseConfigElementError[]>
    >;
  }

  getFirst(type?: InvalidColumnErrorType | string): InvalidDatabaseConfigElementError | undefined {
    if (type == null) {
      return this.all[0];
    }
    return (this.byType[type] ?? [])[0];
  }

  has(type: InvalidColumnErrorType | string) {
    return !this.isEmpty(type);
  }

  isEmpty(type?: InvalidColumnErrorType | string) {
    if (type == null) {
      return this.all.length === 0;
    }
    return (this.byType[type] ?? []).length === 0;
  }
}

type InvalidDatabaseConfigElementError = InvalidDatabaseError | InvalidColumnError;
export class InvalidDatabaseError extends Error {
  type: string;
  constructor(message: string, type: string) {
    super(message);
    this.type = type;
  }
}
export class InvalidColumnError extends Error {
  type: InvalidColumnErrorType;
  constructor(message: string, type: InvalidColumnErrorType) {
    super(message);
    this.type = type;
  }
}

const DatabaseConfigErrorsContext = createContext<
  [InvalidDatabaseConfigError, Dispatch<SetStateAction<InvalidDatabaseConfigError>>]
>([new InvalidDatabaseConfigError([]), () => {}]);

export const DatabaseConfigErrorsProvider = ({ children }: { children: ReactNode }) => {
  const [errors, setErrors] = useState<InvalidDatabaseConfigError>(
    new InvalidDatabaseConfigError([]),
  );
  return (
    <DatabaseConfigErrorsContext.Provider value={[errors, setErrors]}>
      {children}
    </DatabaseConfigErrorsContext.Provider>
  );
};

function isDimensionalProperty(
  value: DimensionalProperty | ExtTableColumn,
): value is DimensionalProperty {
  return (value as DimensionalProperty).dimension !== undefined;
}
export function useErrors() {
  const [errors, setErrors] = useContext(DatabaseConfigErrorsContext);

  const clearErrors = useCallback(
    (...types: string[]) => {
      if (types.length !== 0) {
        setErrors((currentErrors) => {
          return new InvalidDatabaseConfigError(
            currentErrors.all.filter((error) => {
              return !types.includes(error.type);
            }),
          );
        });
      } else {
        setErrors(new InvalidDatabaseConfigError([]));
      }
    },
    [setErrors],
  );

  const validate = useCallback(
    (
      type: DatabaseConfigSourceType,
      config: DatabaseConfigForExtTableSource | DatabaseConfigForDatabaseSource,
      availableDimensionColumns?: DimensionalProperty[] | ExtTableColumn[],
    ) => {
      try {
        if (type === DatabaseConfigSourceType.Database) {
          validateDatabaseConfiguration(
            config as DatabaseConfigForDatabaseSource,
            availableDimensionColumns?.filter(isDimensionalProperty),
          );
        }
        if (type === DatabaseConfigSourceType.ExtTable) {
          validateExtTableConfiguration(config as DatabaseConfigForExtTableSource);
        }
      } catch (error) {
        if (error instanceof InvalidDatabaseConfigError) {
          setErrors(error);
        }
        throw error;
      }
    },
    [setErrors],
  );

  return useMemo(() => {
    return { errors, clearErrors, validate, setErrors };
  }, [errors, clearErrors, validate, setErrors]);
}

function validateDatabaseConfiguration(
  { segmentedByColumns: dimensionColumns, metadataColumns }: DatabaseConfigForDatabaseSource,
  availableDimensionColumns?: DimensionalProperty[],
): void {
  const errors: InvalidColumnError[] = [];
  const newColumnsById = keyBy(
    dimensionColumns,
    ({ sourceDimensionalPropertyId }) => sourceDimensionalPropertyId,
  );
  if (metadataColumns != null) {
    const dimensionColumnsById = keyBy(availableDimensionColumns ?? [], ({ id }) => id);
    for (const metadataColumn of metadataColumns) {
      if (newColumnsById[metadataColumn.sourceDimensionalPropertyId] != null) {
        const dimProp = dimensionColumnsById[metadataColumn.sourceDimensionalPropertyId];
        const dimPropName = dimProp?.name ?? 'Unknown';

        errors.push(
          new InvalidColumnError(
            `Column "${dimPropName}" is already used as a segmented by column.`,
            'segmentedBy',
          ),
        );
      }
    }
  }
  if (errors.length > 0) {
    throw new InvalidDatabaseConfigError(errors);
  }
}

function validateExtTableConfiguration({
  timeColumn,
  driverColumns,
  segmentedByColumns: dimensionColumns,
  metadataColumns,
  filters,
}: DatabaseConfigForExtTableSource): void {
  const errors: InvalidColumnError[] = [];

  // validate each column is only used once
  const columnNameToColumnType: Record<string, InvalidColumnErrorType> = {};
  const addColumnNameAndTypeOrPushError = (
    columnName: string,
    columnType: InvalidColumnErrorType,
  ) => {
    if (columnNameToColumnType[columnName] != null) {
      const existingColumnType = columnNameToColumnType[columnName];
      const error = new InvalidColumnError(
        `Column "${columnName}" is already used as a ${COLUMN_TYPE_TO_DISPLAY_NAME[existingColumnType]} column.`,
        columnType,
      );
      errors.push(error);
      return;
    }
    columnNameToColumnType[columnName] = columnType;
  };
  if (timeColumn != null) {
    addColumnNameAndTypeOrPushError(timeColumn.extTableColumnId, 'time');
  }
  if (driverColumns != null) {
    for (const driverColumn of driverColumns) {
      addColumnNameAndTypeOrPushError(driverColumn.extTableColumnId, 'driver');
    }
  }
  if (dimensionColumns != null) {
    for (const dimensionColumn of dimensionColumns) {
      addColumnNameAndTypeOrPushError(dimensionColumn.extTableColumnId, 'segmentedBy');
    }
  }
  if (metadataColumns != null) {
    for (const metadataColumn of metadataColumns) {
      addColumnNameAndTypeOrPushError(metadataColumn.extTableColumnId, 'metadata');
    }
  }

  // validate time column exists if driver columns exist
  if (timeColumn == null && (driverColumns ?? []).length > 0) {
    errors.push(new InvalidColumnError('Please select a column.', 'time'));
  }

  for (const filter of filters ?? []) {
    if (filter.comparator === ComparatorType.Eq || filter.comparator === ComparatorType.Neq) {
      continue;
    }

    if (filter.compareTo === '' || isNaN(parseFloat(filter.compareTo))) {
      errors.push(
        new InvalidColumnError(
          `Filter on "${filter.extTableColumnId}" needs a number to compare against.`,
          'filter',
        ),
      );
    }
  }

  if (errors.length > 0) {
    throw new InvalidDatabaseConfigError(errors);
  }
}
