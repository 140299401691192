import * as Sentry from '@sentry/nextjs';
import { minBy, uniq, xor } from 'lodash';

import {
  AiGroupEventsDocument,
  AiGroupEventsQuery,
  AiGroupEventsQueryVariables,
  BlockType,
  DatasetMutationInput,
  EventGroupCreateInput,
  EventGroupUpdateInput,
  EventUpdateInput,
} from 'generated/graphql';
import { eventToAiEventInput, getCommonAncestor } from 'helpers/events';
import { mergeMutations } from 'helpers/mergeMutations';
import { peekMutationStateChange } from 'helpers/sortIndex';
import { isNotNull } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import { type CopilotRequest } from 'reduxStore/actions/copilot';
import { submitMutation } from 'reduxStore/actions/submitDatasetMutation';
import {
  DEFAULT_EVENT_GROUP_ID,
  EventGroupId,
  getFlattenedEventIdsForGroup,
} from 'reduxStore/models/events';
import { bulkCreateEventsAndGroupsMutations } from 'reduxStore/reducers/helpers/bulkCreateEventsAndGroupsMutations';
import { setSelectedEventsAndGroups } from 'reduxStore/reducers/pageSlice';
import { expandPlans } from 'reduxStore/reducers/roadmapSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { aiGroupEventsContextSelector } from 'selectors/aiSelector';
import { blocksByIdSelector } from 'selectors/blocksSelector';
import { businessObjectFieldSpecByIdSelector } from 'selectors/businessObjectFieldSpecsSelector';
import { businessObjectSpecsByIdForLayerSelector } from 'selectors/businessObjectSpecsSelector';
import { businessObjectsByFieldIdForLayerSelector } from 'selectors/businessObjectsSelector';
import { attributesByIdSelector } from 'selectors/dimensionsSelector';
import { basicDriverResolvedNamesByIdSelector } from 'selectors/driversSelector';
import {
  eventGroupsWithoutLiveEditsByIdForLayerSelector,
  eventsWithoutLiveEditsByIdForLayerSelector,
  populatedEventGroupsWithoutLiveEditsByIdForLayerSelector,
} from 'selectors/eventsAndGroupsSelector';
import { authenticatedUserSelector } from 'selectors/loginSelector';
import { pageIdSelector } from 'selectors/pageSelector';
import {
  flattenedSelectedEventIdsSelector,
  selectedEventGroupIdsWithoutSelectedParentSelector,
  selectedEventIdsWithoutSelectedParentSelector,
} from 'selectors/selectedEventSelector';

const getPlanTimelineBlock = (state: RootState) => {
  const blocksById = blocksByIdSelector(state);
  const pageId = pageIdSelector(state);
  const planTimelineBlocks = Object.values(blocksById).filter(
    (block) => block.type === BlockType.PlanTimeline && block.pageId === pageId,
  );
  if (planTimelineBlocks.length !== 1) {
    throw new Error('Expected a unique plan timeline block for this page');
  }
  return planTimelineBlocks[0];
};

export const aiGroupEvents: CopilotRequest<AiGroupEventsQuery, AiGroupEventsQueryVariables> = {
  query: AiGroupEventsDocument,
  getContext: (state: RootState) => {
    return aiGroupEventsContextSelector(state);
  },
  getPrompt: (state: RootState) => {
    const eventsById = eventsWithoutLiveEditsByIdForLayerSelector(state);
    const resolvedDriverNamesById = basicDriverResolvedNamesByIdSelector(state);
    const businessObjectsByFieldId = businessObjectsByFieldIdForLayerSelector(state);
    const businessObjectFieldSpecById = businessObjectFieldSpecByIdSelector(state);
    const businessObjectSpecsById = businessObjectSpecsByIdForLayerSelector(state);
    const attributesById = attributesByIdSelector(state);
    const targetEventIds = flattenedSelectedEventIdsSelector(state);
    return targetEventIds
      .map((id) => eventsById[id])
      .filter(isNotNull)
      .map((event) => {
        return eventToAiEventInput(event, {
          attributesById,
          resolvedDriverNamesById,
          businessObjectsByFieldId,
          businessObjectFieldSpecById,
          businessObjectSpecsById,
        });
      })
      .filter(isNotNull);
  },
  extractCursor: (data) => data.aiGroupEvents?.cursor,
  handleResponse: (data, dispatch, operation, getState) => {
    const state = getState();
    const authenticatedUser = authenticatedUserSelector(state);
    if (authenticatedUser == null) {
      throw new Error('Invalid selection');
    }

    let generatedGroups = (data?.aiGroupEvents?.groups ?? []).filter(
      (g) => g.events.length > 0 && g.name.length > 0,
    );
    if (generatedGroups.length === 0) {
      throw new Error('No generated groups');
    }

    const groupedEventIds = generatedGroups.flatMap((g) => g.events.map((ev) => ev.id));
    const targetEventIds = flattenedSelectedEventIdsSelector(state);

    const ungrouped = xor(groupedEventIds, targetEventIds);

    // Only error when the AI grouping truly failed.
    if (ungrouped.length === targetEventIds.length) {
      Sentry.withScope((scope: Sentry.Scope) => {
        scope.setLevel('warning');
        Sentry.captureMessage('Mismatching IDs returned from AI event grouping');
      });
      throw new Error('Mismatching IDs returned from AI event grouping');
    }

    // In the case where only a few events were not grouped, place them in an "other" group.
    if (ungrouped.length > 0) {
      generatedGroups = [
        ...generatedGroups,
        {
          name: 'Other',
          events: ungrouped.map((id) => ({ id })),
        },
      ];
    }

    const eventGroupsById = eventGroupsWithoutLiveEditsByIdForLayerSelector(state);
    const populatedGroupsById = populatedEventGroupsWithoutLiveEditsByIdForLayerSelector(state);
    const eventsById = eventsWithoutLiveEditsByIdForLayerSelector(state);
    const eventIds = selectedEventIdsWithoutSelectedParentSelector(state);
    const eventGroupIds = selectedEventGroupIdsWithoutSelectedParentSelector(state);
    const blockId = getPlanTimelineBlock(state).id;
    const selectedEvents = eventIds.map((eventId) => eventsById[eventId]).filter(isNotNull);
    const selectedEventGroups = eventGroupIds
      .map((groupId) => eventGroupsById[groupId])
      .filter(isNotNull);

    const impactedEventGroupIds = uniq(
      targetEventIds
        .map((eventId) => eventsById[eventId])
        .filter(isNotNull)
        .map((event) => event.parentId)
        .filter(isNotNull),
    );

    const parentIds = uniq([
      ...selectedEvents.map((ev) => ev.parentId),
      ...selectedEventGroups.map((g) => g.parentId),
    ]);
    const parentId = getCommonAncestor(parentIds, populatedGroupsById, []);
    const insertBefore = minBy(
      [...selectedEvents, ...selectedEventGroups].filter(
        (evOrGroup) => evOrGroup.parentId === parentId,
      ),
      'sortIndex',
    );

    let activeId: EventGroupId | undefined;
    const idsToExpand: EventGroupId[] = [];
    let mutation: DatasetMutationInput | undefined;

    const isAllGroups = selectedEvents.length === 0;

    // if there's only a single plan selected and it's not the default plan, rename it using the results
    // (the default plan cannot be renamed/updated)
    if (
      isAllGroups &&
      selectedEventGroups.length === 1 &&
      selectedEventGroups[0].id !== DEFAULT_EVENT_GROUP_ID
    ) {
      const eventGroup = selectedEventGroups[0];
      activeId = eventGroup.id;
      const updateMutation: DatasetMutationInput = {
        updateEventGroups: [
          {
            id: eventGroup.id,
            name: generatedGroups[0].name,
            eventIds: generatedGroups[0].events.map((ev) => ev.id),
          },
        ],
        updateEvents: generatedGroups.flatMap((g) =>
          g.events.map((ev) => ({
            id: ev.id,
            parentId: eventGroup.id,
          })),
        ),
      };
      idsToExpand.push(eventGroup.id);

      const createEventGroupInputs: EventGroupCreateInput[] = [];
      if (generatedGroups.length > 1) {
        // if there are multiple groups, create them under the common parent
        generatedGroups.slice(1).forEach(({ name, events }) => {
          const id = uuidv4();
          const eventGroupInput: EventGroupCreateInput = {
            id,
            name,
            eventIds: events.map((ev) => ev.id),
            eventGroupIds: [],
            parentId,
            hidden: false,
            ownerName: authenticatedUser?.name ?? '',
            ownerId: authenticatedUser?.id ?? '',
          };
          createEventGroupInputs.push(eventGroupInput);
          idsToExpand.push(id);
        });
      }

      mutation = bulkCreateEventsAndGroupsMutations(
        state,
        { groups: createEventGroupInputs, events: [], extras: updateMutation },
        insertBefore?.id,
      );
    } else {
      // create new groups under the common parent
      const eventUpdatesInput: EventUpdateInput[] = [];
      const createEventGroupInputs: EventGroupCreateInput[] = [];
      generatedGroups.forEach(({ name, events }) => {
        const id = uuidv4();
        idsToExpand.push(id);
        eventUpdatesInput.push(...events.map((ev) => ({ id: ev.id, parentId: id })));
        createEventGroupInputs.push({
          id,
          name,
          eventIds: events.map((ev) => ev.id),
          eventGroupIds: [],
          parentId,
          ownerName: authenticatedUser?.name ?? '',
          ownerId: authenticatedUser?.id ?? '',
        });
      });

      // make the relevant updates to the parent event groups
      const eventGroupUpdatesInput: EventGroupUpdateInput[] = [];
      parentIds.filter(isNotNull).forEach((pId) => {
        const parentGroup = eventGroupsById[pId];
        if (parentGroup == null) {
          return;
        }

        eventGroupUpdatesInput.push({
          id: pId,
          eventGroupIds: parentGroup.eventGroupIds.filter((id) => !eventGroupIds.includes(id)),
          eventIds: parentGroup.eventIds.filter((id) => !eventIds.includes(id)),
        });
      });

      if (parentId != null) {
        const parentGroupIds = eventGroupsById[parentId]?.eventGroupIds;
        // add the new groups to the parent
        if (parentGroupIds != null) {
          eventGroupUpdatesInput.push({
            id: parentId,
            eventGroupIds: [...parentGroupIds, ...idsToExpand],
          });
        }
      }

      mutation = bulkCreateEventsAndGroupsMutations(
        state,
        {
          groups: createEventGroupInputs,
          events: [],
          extras: { updateEvents: eventUpdatesInput, updateEventGroups: eventGroupUpdatesInput },
        },
        insertBefore?.id,
      );

      activeId = idsToExpand[0];
    }

    if (mutation == null || activeId == null) {
      throw new Error('No mutation found');
    }

    const newState = peekMutationStateChange(state, mutation);
    const newPopulatedEventGroupsById = eventGroupsWithoutLiveEditsByIdForLayerSelector(newState);
    const emptyEventGroupIds = impactedEventGroupIds
      .map((id) => newPopulatedEventGroupsById[id])
      .filter(isNotNull)
      .filter((g) => getFlattenedEventIdsForGroup(g.id, newPopulatedEventGroupsById).length === 0)
      .map((g) => g.id);
    const eventGroupIdsToDelete = emptyEventGroupIds.filter((id) => id !== DEFAULT_EVENT_GROUP_ID);

    if (eventGroupIdsToDelete.length > 0) {
      mutation = mergeMutations(mutation, {
        deleteEventGroups: eventGroupIdsToDelete.map((id) => ({ id })),
      });
    }

    dispatch(submitMutation(mutation, { isEphemeral: true }));
    dispatch(expandPlans({ blockId, itemIds: idsToExpand }));
    dispatch(
      setSelectedEventsAndGroups({
        blockId,
        active: { type: 'group', id: activeId },
        refs: idsToExpand.map((id) => ({ type: 'group', id })),
      }),
    );
  },
};
