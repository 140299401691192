import { isEqual, range, times, trimEnd, uniq, uniqWith, zip, zipObject } from 'lodash';
import { DateTime } from 'luxon';

import {
  BusinessObjectFieldCellRef,
  BusinessObjectFieldSpecColumnKey,
  CellRef,
  CellSelection,
  CellType,
  MonthColumnKey,
  NameColumnKey,
} from 'config/cells';
import { MAX_DATE } from 'config/datetime';
import { DEFAULT_DRIVER_FORMAT } from 'config/drivers';
import { NAME_COLUMN_TYPE } from 'config/modelView';
import { EventUpdateInput, ValueType } from 'generated/graphql';
import {
  isBusinessObjectFieldSpecColumnKey,
  isBusinessObjectTableColumnKey,
  isColumnKeyEqual,
  isDriverRowKey,
  isFormulaColumn,
  isMonthColumnKey,
  isObjectFieldNameColumnKey,
  isObjectRowKey,
  isRowKeyEqual,
} from 'helpers/cells';
import { getDateTimeFromMonthKey, getMonthKeysForRange, nextMonthKey } from 'helpers/dates';
import { inferDriverFormatFromString } from 'helpers/drivers';
import evaluate from 'helpers/formulaEvaluation/ForecastCalculator/ForecastCalculator';
import { convertTimeSeriesToGql } from 'helpers/gqlDataset';
import { getContiguousKeys } from 'helpers/gridKeys';
import { mergeMutations } from 'helpers/mergeMutations';
import { newNameDeduper } from 'helpers/naming';
import { getNumber } from 'helpers/number';
import { isNotNull } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import { updateBlockDateRange } from 'reduxStore/actions/blockMutations';
import {
  createNewDriversInContext,
  driverForecastsUpdateMutations,
  updateDriversFormulas,
} from 'reduxStore/actions/driverMutations';
import { updateEvents } from 'reduxStore/actions/eventMutations';
import { isFormulaSelection } from 'reduxStore/actions/formulaCopyPaste';
import {
  ObjectTablePasteUpdate,
  ObjectTimeSeriesUpdate,
  isObjectPasteCreateUpdate,
  objectTablePasteUpdateMutations,
} from 'reduxStore/actions/objectTableCopyPaste';
import { submitAutoLayerizedMutations } from 'reduxStore/actions/submitDatasetMutation';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { AttributeId, DimensionId } from 'reduxStore/models/dimensions';
import { DriverFormat, DriverId } from 'reduxStore/models/drivers';
import { isDriverEvent } from 'reduxStore/models/events';
import { ValueTimeSeries } from 'reduxStore/models/timeSeries';
import {
  DisplayConfiguration,
  NullableValue,
  Value,
  formatValueToString,
  toAttributeValue,
  toNumberValue,
} from 'reduxStore/models/value';
import {
  convertDimensionInputStringToValue,
  convertFieldInputStringToValue,
} from 'reduxStore/reducers/helpers/businessObjects';
import { driverActualsUpdateMutations } from 'reduxStore/reducers/helpers/drivers';
import { clearCopiedCells, setSelectedCells } from 'reduxStore/reducers/pageSlice';
import { setPasteData } from 'reduxStore/reducers/pasteModalSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { AppThunk } from 'reduxStore/store';
import { selectedBlockCellKeysSelector } from 'selectors/activeCellSelectionSelector';
import {
  blockConfigBusinessObjectSpecIdSelector,
  blockConfigObjectFieldSpecAsTimeSeriesIdSelector,
} from 'selectors/blocksSelector';
import { isBusinessObjectFieldSpecRestrictedSelector } from 'selectors/businessObjectFieldSpecRestrictedSelector';
import {
  businessObjectFieldByIdForLayerSelector,
  businessObjectFieldSpecSelector,
} from 'selectors/businessObjectFieldSpecsSelector';
import { isBusinessObjectNameRestrictedSelector } from 'selectors/businessObjectNameRestrictionsSelector';
import {
  dimensionalPropertyEvaluatorSelector,
  dimensionalPropertySelector,
  driverPropertySelector,
} from 'selectors/collectionSelector';
import { dimensionsByIdSelector } from 'selectors/dimensionsSelector';
import {
  allDriverNamesSelector,
  driversByIdForCurrentLayerSelector,
} from 'selectors/driversSelector';
import { formulaDisplayListenerSelector } from 'selectors/formulaDisplaySelector';
import { isPasteDataSetSelector, isPasteModalOpenSelector } from 'selectors/pasteModalSelector';
import { prevailingCellSelectionSelector } from 'selectors/prevailingCellSelectionSelector';
import {
  resolvedBusinessObjectFieldSpecFormatSelector,
  resolvedDriverFormatSelector,
} from 'selectors/resolvedEntityFormatSelector';
import { getSelectedTimelineCellEventsByMonthKey } from 'selectors/selectedTimelineCellSelector';
import {
  pageSelectionBlockIdSelector,
  prevailingSelectionBlockIdSelector,
  prevailingSelectionSelector,
} from 'selectors/selectionSelector';
import { MonthKey } from 'types/datetime';
import { FormulaUpdate } from 'types/formula';

export const TAB = '\t';

const processPastedValue = (pasted: string): string[][] => {
  return trimEnd(pasted, '\n')
    .split('\n')
    .map((line) => line.split(TAB).map((v) => v.trim()));
};

const getSelectedColumnKeys = (cellSelection: CellSelection) => {
  return uniqWith(
    cellSelection.selectedCells.map((ref) => ref.columnKey),
    isEqual,
  );
};

export const handleStringPaste =
  (pasted: string): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const selection = prevailingSelectionSelector(state);
    const cellSelection = prevailingCellSelectionSelector(state);

    const isPasteModalOpen = isPasteModalOpenSelector(state);
    const isPasteDataSet = isPasteDataSetSelector(state);
    if (isPasteModalOpen) {
      if (!isPasteDataSet) {
        dispatch(setPasteData(processPastedValue(pasted)));
      }
      // If PasteModal is open we don't want any active cell pasting happening!
      return;
    }

    if (selection == null || cellSelection == null) {
      return;
    }

    if (selection.type === 'cell') {
      dispatch(
        handleStringPasteCell({
          pasted,
          cellSelection,
        }),
      );
      return;
    }

    if (selection.type === 'eventsAndGroups') {
      dispatch(handleStringPasteEventsAndGroups({ pasted, cellSelection }));
    }
  };

const handleStringPasteCell =
  ({ pasted, cellSelection }: { pasted: string; cellSelection: CellSelection }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const { selectedCells, activeCell } = cellSelection;
    const activeCellColumnKey = activeCell.columnKey;

    const pastedValues = processPastedValue(pasted);
    const numPastedRows = pastedValues.length;
    const numPastedColumns = pastedValues[0].length;
    const getPastedRow = (rowIdx: number) => {
      return pastedValues[rowIdx % numPastedRows];
    };
    const getPastedValue = (rowIdx: number, colIdx: number) => {
      return getPastedRow(rowIdx)[colIdx % numPastedColumns];
    };
    // Repeat paste pattern as many times as possible to fit into target paste cells.
    // Paste pattern 1 time if paste pattern is longer than selected cells.
    // e.g. paste [1,2] into columns [Sep '21, Oct' 21, Nov '21, Dec '21, Jan '22] => [1,2,1,2]
    // e.g. paste [1,2,3] into columns [Sep '21] => [1,2,3]
    const { orderedRowKeys, orderedColumnKeys } = selectedBlockCellKeysSelector(state);

    // handle multiline pasting
    const activeCellRowKey = activeCell.rowKey;
    const selectedRowKeys = uniq(selectedCells.map((c) => c.rowKey));
    const contiguousRows = getContiguousKeys(
      selectedRowKeys.map((key) => ({
        key,
        position: orderedRowKeys.findIndex((rk) => isRowKeyEqual(rk, key)),
      })),
      activeCellRowKey,
    );

    const selectedColumnKeys = getSelectedColumnKeys(cellSelection);

    const contiguousColumns = getContiguousKeys(
      selectedColumnKeys.map((key) => ({
        key,
        position: orderedColumnKeys.findIndex((ck) => isColumnKeyEqual(ck, key)),
      })),
      activeCellColumnKey,
    );

    const isNonContiguousRangeSelected =
      selectedCells.length !== contiguousRows.length * contiguousColumns.length;
    const hasSinglePastedValue = numPastedRows === 1 && numPastedColumns === 1;
    const nonContiguousPaste = pastedValues.some((row) => row.length !== numPastedColumns);
    // bail out if we're trying to paste complex patterns into a non-continguous range, or if we're
    // trying to do paste a non-contiguous collection
    if ((isNonContiguousRangeSelected && !hasSinglePastedValue) || nonContiguousPaste) {
      return;
    }

    const rowRepeat = Math.max(Math.floor(contiguousRows.length / numPastedRows), 1);
    const columnRepeat = Math.max(Math.floor(contiguousColumns.length / numPastedColumns), 1);
    const getRowIndices = () => {
      return range(rowRepeat * numPastedRows);
    };
    const getColIndices = () => {
      return range(columnRepeat * numPastedColumns);
    };

    const startRowIdx = orderedRowKeys.findIndex((rk) => isRowKeyEqual(rk, contiguousRows[0]));
    const startColIdx = orderedColumnKeys.findIndex((ck) =>
      isColumnKeyEqual(ck, contiguousColumns[0]),
    );

    const relevantRowKeys = orderedRowKeys.slice(
      startRowIdx,
      startRowIdx + rowRepeat * numPastedRows,
    );
    const relevantColKeys = orderedColumnKeys.slice(
      startColIdx,
      startColIdx + Math.max(contiguousColumns.length, numPastedColumns),
    );

    // Currently, you are not able to paste across blocks. This means that
    // you will not have a sitation where some row/column keys are for driver
    // and others are for object table cells. This simplifies the
    // implentation a decent amount. This may not always be the case though
    // and in that case we'll have to handle mixed row types in which case
    // we'll need to refactor our actions to allow updating multiple types of
    // data in a single batch.

    if (
      startRowIdx + numPastedRows <= orderedRowKeys.length &&
      relevantRowKeys.every(isDriverRowKey) &&
      relevantColKeys.every(isMonthColumnKey)
    ) {
      dispatch(
        handleStringPasteCellDriverTable({
          orderedRowKeys,
          orderedColumnKeys,
          relevantColKeys,
          cellSelection,
          getPastedRow,
          getRowIndices,
          numPastedColumns,
          columnRepeat,
          startRowIdx,
          startColIdx,
        }),
      );
    } else if (
      relevantRowKeys.every(isObjectRowKey) &&
      relevantColKeys.every(isBusinessObjectTableColumnKey)
    ) {
      dispatch(
        handleStringPasteCellObjectTable({
          orderedRowKeys,
          relevantColKeys,
          cellSelection,
          getPastedValue,
          getRowIndices,
          getColIndices,
          startRowIdx,
        }),
      );
    } else if (isFormulaSelection(cellSelection)) {
      dispatch(
        handleStringPasteCellFormulaSelection({
          getPastedValue,
          getRowIndices,
          getColIndices,
          hasSinglePastedValue,
          orderedRowKeys,
          orderedColumnKeys,
          startRowIdx,
          startColIdx,
          cellSelection,
        }),
      );
    }
  };

/**
 * This function expects the selection to be a contiguous range of driver cells and/or a single paste value.
 */
const handleStringPasteCellDriverTable =
  ({
    orderedRowKeys,
    orderedColumnKeys,
    relevantColKeys,
    cellSelection,
    getPastedRow,
    getRowIndices,
    numPastedColumns,
    columnRepeat,
    startRowIdx,
    startColIdx,
  }: {
    orderedRowKeys: ReturnType<typeof selectedBlockCellKeysSelector>['orderedRowKeys'];
    orderedColumnKeys: ReturnType<typeof selectedBlockCellKeysSelector>['orderedColumnKeys'];
    relevantColKeys: MonthColumnKey[];
    cellSelection: CellSelection;
    getPastedRow: (rowIdx: number) => string[];
    getRowIndices: () => number[];
    numPastedColumns: number;
    columnRepeat: number;
    startRowIdx: number;
    startColIdx: number;
  }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    const monthKeys = relevantColKeys.map((c) => c.monthKey);
    // extend the date range of the target block, if necessary to fit the pasted selection
    const blockId = pageSelectionBlockIdSelector(state);
    const overflowMonths = startColIdx + numPastedColumns - orderedColumnKeys.length;
    if (overflowMonths > 0 && relevantColKeys.length > 0 && blockId != null) {
      const lastColKey = relevantColKeys[relevantColKeys.length - 1];
      const lastColDate = getDateTimeFromMonthKey(lastColKey.monthKey);
      const extendedLastDate = DateTime.min(MAX_DATE, lastColDate.plus({ months: overflowMonths }));
      const extraMonthKeys = getMonthKeysForRange(
        lastColDate.plus({ months: 1 }),
        extendedLastDate,
      );
      monthKeys.push(...extraMonthKeys);
      relevantColKeys.push(
        ...extraMonthKeys.map((mk) => {
          return { ...lastColKey, monthKey: mk };
        }),
      );
      dispatch(updateBlockDateRange({ blockId, newDateRange: { end: extendedLastDate } }));
    }

    const rowSet = new Set<CellRef['rowKey']>();
    const updates = getRowIndices()
      .map((idx) => {
        const rowKey = orderedRowKeys[startRowIdx + idx];
        if (rowKey == null || !isDriverRowKey(rowKey) || rowKey.driverId == null) {
          return undefined;
        }

        const { driverId } = rowKey;
        rowSet.add(rowKey);

        const format = resolvedDriverFormatSelector(state, driverId);
        const inferredFormats = uniq(
          getPastedRow(idx).map((value) => inferDriverFormatFromString(value)),
        );
        const newFormat = inferredFormats.length === 1 ? inferredFormats[0] : undefined;
        const numberCellValues = times(columnRepeat, () =>
          getPastedRow(idx).map((valueStr) => {
            const value =
              valueStr != null && valueStr.trim() !== ''
                ? getNumber(valueStr, newFormat ?? format)
                : undefined;
            return value == null || !Number.isFinite(value) ? undefined : value;
          }),
        ).flat();

        const values = zipObject(monthKeys, numberCellValues);
        return {
          id: driverId,
          values,
          newFormat,
        };
      })
      .filter(isNotNull);

    // Determine the mutations before dispatching them so that the synchronous behavior and asynchronous behavior
    //are consistent. If we dispatch the actuals first, the forecast can be affected by the new actuals and the
    // synchronous calculation value can be different from the async value, which uses the old actuals.
    const actualsMutations = driverActualsUpdateMutations(state, updates);
    const forecastMutations = driverForecastsUpdateMutations(state, updates);
    const driverMutations = mergeMutations(actualsMutations ?? {}, forecastMutations ?? {});

    dispatch(submitAutoLayerizedMutations('cell-paste-string', [driverMutations]));

    dispatch(
      setSelectedCells({
        ...cellSelection,
        isBackground: true,
        selectedCells: relevantColKeys.flatMap((columnKey) =>
          [...rowSet].map(
            (rowKey) =>
              ({
                ...cellSelection.activeCell,
                rowKey,
                columnKey,
              }) as CellRef,
          ),
        ),
      }),
    );
  };

/**
 * This function expects the selection to be a contiguous range of driver cells and/or a single paste value.
 */
const handleStringPasteCellObjectTable =
  ({
    orderedRowKeys,
    relevantColKeys,
    cellSelection,
    getPastedValue,
    getRowIndices,
    getColIndices,
    startRowIdx,
  }: {
    orderedRowKeys: ReturnType<typeof selectedBlockCellKeysSelector>['orderedRowKeys'];
    relevantColKeys: Array<BusinessObjectFieldCellRef['columnKey']>;
    cellSelection: CellSelection;
    getPastedValue: (rowIdx: number, colIdx: number) => string;
    getRowIndices: () => number[];
    getColIndices: () => number[];
    startRowIdx: number;
  }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    // We don't want to create duplicate attributes for the same dim+value combo.
    const newAttrIdsForDimIdsByVal: NullableRecord<
      DimensionId,
      NullableRecord<string, AttributeId>
    > = {};
    const rowSet = new Set<CellRef['rowKey']>();

    const blockId = prevailingSelectionBlockIdSelector(state);
    const objectSpecId = blockConfigBusinessObjectSpecIdSelector(state, blockId ?? '');

    if (objectSpecId == null || orderedRowKeys[startRowIdx] == null) {
      return;
    }

    const timeSeriesUpdates: NullableRecord<string, ObjectTimeSeriesUpdate> = {};
    const updates: ObjectTablePasteUpdate[] = getRowIndices()
      .flatMap((rowIdx) => {
        const isUpdatingFieldRow = 'fieldSpecId' in orderedRowKeys[startRowIdx];
        if (isUpdatingFieldRow || startRowIdx + rowIdx < orderedRowKeys.length - 1) {
          const rowKey = orderedRowKeys[startRowIdx + rowIdx];
          if (!isObjectRowKey(rowKey) || rowKey?.objectId == null) {
            return [];
          }

          const objectId = rowKey?.objectId;
          rowSet.add(rowKey);

          return getColIndices().map((colIdx) => {
            const pastedCellValue = getPastedValue(rowIdx, colIdx);
            const valueStr = pastedCellValue.trim();
            const columnKey = relevantColKeys[colIdx];
            if (
              columnKey == null ||
              ('columnType' in columnKey &&
                (columnKey.columnType === 'addNewColumn' || columnKey.columnType === 'options'))
            ) {
              return null;
            }
            return handlePastedObjectFieldValue({
              state,
              objectSpecId,
              objectId,
              pastedVal: valueStr,
              columnKey,
              rowKey,
              newAttrIdsForDimIdsByVal,
              timeSeriesUpdates,
            });
          });
        } else {
          const newObjectId = uuidv4();
          const innerPasteUpdates = getColIndices()
            .map((colIdx) => {
              const pastedCellValue = getPastedValue(rowIdx, colIdx);
              const valueStr = pastedCellValue.trim();
              const columnKey = relevantColKeys[colIdx];
              if (
                columnKey == null ||
                ('columnType' in columnKey &&
                  (columnKey.columnType === 'addNewColumn' || columnKey.columnType === 'options'))
              ) {
                return undefined;
              }

              return handlePastedObjectFieldValue({
                state,
                objectSpecId,
                objectId: newObjectId,
                pastedVal: valueStr,
                columnKey,
                newAttrIdsForDimIdsByVal,
                timeSeriesUpdates,
              });
            })
            .filter(isNotNull);
          return [
            {
              type: 'create' as const,
              objectId: newObjectId,
              objectSpecId,
              fields: innerPasteUpdates,
            },
          ];
        }
      })
      .filter(isNotNull);

    if (updates.length === 0) {
      return;
    }
    const { layerMutation, defaultLayerMutation } = objectTablePasteUpdateMutations({
      state,
      objectSpecId,
      updates,
    });

    dispatch(
      submitAutoLayerizedMutations('cell-paste-string', [defaultLayerMutation, layerMutation]),
    );

    const { activeCell } = cellSelection;
    const updatedCellRefs: CellRef[] = relevantColKeys.flatMap((columnKey) =>
      [...rowSet].map(
        (rowKey) =>
          ({
            ...activeCell,
            rowKey,
            columnKey,
          }) as CellRef,
      ),
    );
    const createdCellRefs: CellRef[] = updates
      .filter(isObjectPasteCreateUpdate)
      .flatMap((newObject) => {
        return newObject.fields
          .map((f) => {
            const baseRef = {
              ...activeCell,
              rowKey: {
                objectId: newObject.objectId,
                groupKey: 'groupKey' in activeCell.rowKey ? activeCell.rowKey.groupKey : '',
              },
            };
            if (f.type === 'field') {
              return {
                ...baseRef,
                columnKey: {
                  objectFieldSpecId: f.fieldSpecId,
                },
              } as CellRef;
            } else if (f.type === 'rename') {
              return {
                ...baseRef,
                columnKey: {
                  columnType: NAME_COLUMN_TYPE,
                },
              } as CellRef;
            }
            return null;
          })
          .filter(isNotNull);
      });
    dispatch(
      setSelectedCells({
        ...cellSelection,
        isBackground: true,
        selectedCells: updatedCellRefs.concat(createdCellRefs),
      }),
    );
  };

/**
 * This function expects the selection to be a contiguous range of driver cells and/or a single paste value.
 */
const handleStringPasteCellFormulaSelection =
  ({
    getPastedValue,
    getRowIndices,
    getColIndices,
    hasSinglePastedValue,
    orderedRowKeys,
    orderedColumnKeys,
    startRowIdx,
    startColIdx,
    cellSelection,
  }: {
    getPastedValue: (rowIdx: number, colIdx: number) => string;
    getRowIndices: () => number[];
    getColIndices: () => number[];
    hasSinglePastedValue: boolean;
    orderedRowKeys: ReturnType<typeof selectedBlockCellKeysSelector>['orderedRowKeys'];
    orderedColumnKeys: ReturnType<typeof selectedBlockCellKeysSelector>['orderedColumnKeys'];
    startRowIdx: number;
    startColIdx: number;
    cellSelection: CellSelection;
  }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const driversById = driversByIdForCurrentLayerSelector(state);

    const driversToUpdate: Array<{
      driverId: DriverId;
      value: string;
      type: 'actuals' | 'forecast';
    }> = [];
    if (hasSinglePastedValue) {
      cellSelection.selectedCells.forEach((cellRef) => {
        if (cellRef.type !== CellType.Driver || !isFormulaColumn(cellRef.columnKey)) {
          return;
        }
        const type: 'actuals' | 'forecast' =
          cellRef.columnKey.columnType === 'formula' ? 'forecast' : 'actuals';
        const driverId = cellRef.rowKey.driverId;

        if (driverId == null) {
          return;
        }
        const driver = driversById[driverId];
        if (driver == null) {
          return;
        }

        const pastedValue = getPastedValue(0, 0);
        driversToUpdate.push({ driverId, type, value: pastedValue });
      });
    } else {
      getRowIndices().forEach((rowIdx) => {
        const rowKey = orderedRowKeys[startRowIdx + rowIdx];
        if (rowKey == null || !isDriverRowKey(rowKey) || rowKey.driverId == null) {
          return;
        }

        const { driverId } = rowKey;
        getColIndices().forEach((colIdx) => {
          const colKey = orderedColumnKeys[startColIdx + colIdx];
          if (!isFormulaColumn(colKey)) {
            return;
          }
          const type = colKey.columnType === 'formula' ? 'forecast' : 'actuals';
          const pastedValue = getPastedValue(rowIdx, colIdx);
          driversToUpdate.push({
            driverId,
            value: pastedValue,
            type,
          });
        });
      });
    }

    const formulaUpdates: FormulaUpdate[] = driversToUpdate
      .map(({ driverId, value, type }) => {
        let formulaDisplay;
        try {
          const formulaDisplayListener = formulaDisplayListenerSelector(state, {
            type: 'driver',
            id: driverId,
          });

          formulaDisplay = evaluate(value, formulaDisplayListener);
        } catch (e) {
          /* empty */
        }

        if (formulaDisplay && formulaDisplay.error === undefined) {
          return {
            driverId,
            type,
            formula: value,
          };
        }

        return null;
      })
      .filter(isNotNull);

    if (formulaUpdates.length > 0) {
      dispatch(
        updateDriversFormulas({
          updates: formulaUpdates,
        }),
      );
      dispatch(clearCopiedCells());
    }
  };

const handleStringPasteEventsAndGroups =
  ({ pasted, cellSelection }: { pasted: string; cellSelection: CellSelection }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const pastedValues = processPastedValue(pasted);

    // only one single row
    const values = pastedValues[0];

    const selectedColumnKeys = getSelectedColumnKeys(cellSelection);
    const selectedMonthKeys = selectedColumnKeys
      .map((key) => (isMonthColumnKey(key) ? key.monthKey : undefined))
      .filter(isNotNull);
    const numTimesToRepeat = Math.max(Math.floor(selectedMonthKeys.length / values.length), 1);
    const cellValues = range(numTimesToRepeat).flatMap(() => values);
    const selectedEventsByMonthKey = getSelectedTimelineCellEventsByMonthKey(state);

    if (Object.keys(selectedEventsByMonthKey).length === 0) {
      // No selected events
      return;
    }

    // It's safe to assume that all selected events have the same format,
    // since they should be part of the same entity.
    const firstEvent = Object.values(selectedEventsByMonthKey)[0];
    let format = DEFAULT_DRIVER_FORMAT;
    if (isDriverEvent(firstEvent)) {
      format = resolvedDriverFormatSelector(state, firstEvent.driverId);
    } else {
      const field =
        businessObjectFieldByIdForLayerSelector(state)[firstEvent.businessObjectFieldId];
      if (field != null && field.fieldSpec.type === ValueType.Number) {
        format = resolvedBusinessObjectFieldSpecFormatSelector(state, field.fieldSpec.id);
      }
    }

    const timeSeries = getTimeSeriesForSelectedCells({
      cellValues,
      format,
      selectedMonthKeys,
    });

    const selectedEvents = Object.values(selectedEventsByMonthKey);
    const updates: EventUpdateInput[] = selectedEvents.map((event) => {
      const ts: ValueTimeSeries = {};
      Object.entries(event.customCurvePoints ?? {}).forEach(([mk, value]) => {
        // Use the newly pasted value if it exists, otherwise use the existing value
        ts[mk] = timeSeries[mk] ?? value;
      });
      return {
        id: event.id,
        customCurvePoints: convertTimeSeriesToGql(ts),
      };
    });
    dispatch(updateEvents(updates));
  };

export const formatDriverCellValuesForClipboard = (
  values: Array<Value | undefined>,
  displayConfiguration: DisplayConfiguration,
): string => {
  const valuesStr = values.map((value) =>
    value != null
      ? formatValueToString(value, displayConfiguration, { abbreviate: false, includeCents: true })
      : '',
  );

  return valuesStr.join(TAB);
};

export const handleDriverNamePaste =
  (pasted: string): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const blockId = pageSelectionBlockIdSelector(state);
    if (blockId == null) {
      return;
    }

    const cellSelection = prevailingCellSelectionSelector(state);
    const activeCell = cellSelection?.activeCell;
    if (
      activeCell == null ||
      isMonthColumnKey(activeCell.columnKey) ||
      activeCell.type !== CellType.Driver ||
      activeCell.columnKey.columnType !== NAME_COLUMN_TYPE
    ) {
      return;
    }

    const { driverId, groupId } = activeCell.rowKey;

    const allDriverNames = allDriverNamesSelector(state);
    const deduper = newNameDeduper(allDriverNames);
    const driverNames = pasted
      .split('\n')
      .map((s) => s.trim())
      .filter((s) => s.length > 0)
      .map((name) => {
        const newName = deduper.dedupe(name);
        deduper.add(newName);
        return newName;
      });

    dispatch(
      createNewDriversInContext({
        newDrivers: driverNames.map((name) => ({ name, id: uuidv4() })),
        context: {
          belowDriverId: driverId,
          groupId,
          blockId,
        },
        select: true,
      }),
    );
  };

const getTimeSeriesForSelectedCells = ({
  cellValues,
  format,
  selectedMonthKeys,
}: {
  cellValues: string[];
  format: DriverFormat;
  selectedMonthKeys: MonthKey[];
}) => {
  const numberCellValues = cellValues
    .map((valueStr) => {
      const value =
        valueStr != null && valueStr.trim() !== '' ? getNumber(valueStr, format) : undefined;
      if (value == null || (value != null && !Number.isFinite(value))) {
        return null;
      }
      return toNumberValue(value);
    })
    .filter(isNotNull);
  let lastMonthKey = selectedMonthKeys[0];
  const timeSeries: ValueTimeSeries = {};
  zip(numberCellValues, selectedMonthKeys).forEach(([value, monthKey]) => {
    if (value != null) {
      lastMonthKey = monthKey ?? nextMonthKey(lastMonthKey);
      timeSeries[lastMonthKey] = value;
    }
  });
  return timeSeries;
};

function handlePastedObjectFieldValue({
  state,
  objectSpecId,
  objectId,
  pastedVal,
  columnKey,
  rowKey,
  newAttrIdsForDimIdsByVal,
  timeSeriesUpdates,
}: {
  state: RootState;
  objectSpecId: BusinessObjectSpecId;
  objectId: BusinessObjectId;
  pastedVal: string;
  columnKey: BusinessObjectFieldSpecColumnKey | NameColumnKey | MonthColumnKey;
  rowKey?: CellRef['rowKey'];
  newAttrIdsForDimIdsByVal: NullableRecord<DimensionId, NullableRecord<string, AttributeId>>;
  timeSeriesUpdates: NullableRecord<string, ObjectTimeSeriesUpdate>;
}): ObjectTablePasteUpdate | undefined {
  const selection = prevailingCellSelectionSelector(state);
  const blockId = selection?.blockId;

  // case 1: pasting into a name cell
  if (isObjectFieldNameColumnKey(columnKey)) {
    const isRestricted = isBusinessObjectNameRestrictedSelector(state, {
      objectSpecId,
      blockId: blockId ?? undefined,
    });
    if (isRestricted) {
      return undefined;
    }
    return { type: 'rename', objectId, name: pastedVal };
  }

  let timeSeriesBlockId = blockId;
  if (timeSeriesBlockId == null) {
    timeSeriesBlockId = prevailingSelectionBlockIdSelector(state);
  }
  // useful helper for the remaining 2 cases
  const getNewAttrAndValueForField = (
    fieldSpecId: BusinessObjectFieldSpecId,
  ): { newAttribute: string | undefined; newValue: NullableValue | undefined } => {
    let newAttribute: string | undefined;
    let newValue: NullableValue | undefined;
    let dimensionId: DimensionId | undefined;

    const dimProperty = dimensionalPropertySelector(state, fieldSpecId);
    const fieldSpec = businessObjectFieldSpecSelector(state, { id: fieldSpecId });
    const dimensionsById = dimensionsByIdSelector(state);

    const dimDriverId = driverPropertySelector(state, fieldSpecId)?.driverId;
    const dimDriver =
      dimDriverId == null ? null : driversByIdForCurrentLayerSelector(state)[dimDriverId];
    const dimensionalPropertyEvaluator = dimensionalPropertyEvaluatorSelector(state);
    const attributes =
      dimensionalPropertyEvaluator.getKeyAttributePropertiesForBusinessObject(objectId);

    if (fieldSpec != null) {
      dimensionId = fieldSpec.type === ValueType.Attribute ? fieldSpec.dimensionId : undefined;
      newValue = convertFieldInputStringToValue(pastedVal, fieldSpec, dimensionsById);
    } else if (dimProperty != null) {
      dimensionId = dimProperty.dimension.id;
      newValue = convertDimensionInputStringToValue(pastedVal, dimensionId, dimensionsById);
    } else if (attributes.length > 0) {
      const val = getNumber(pastedVal, dimDriver?.format);
      newValue = toNumberValue(val);
    }

    // Create new attribute if we couldn't find an existing matching one
    if (newValue == null && dimensionId != null) {
      // But first, check if we're creating one already within this paste so
      // that we don't create duplicates.
      const newlyCreatedAttrId = newAttrIdsForDimIdsByVal[dimensionId]?.[pastedVal];
      if (newlyCreatedAttrId != null) {
        // Don't set newAttribute as that is used to create a new instance of
        // an attribute with that value.
        newValue = toAttributeValue(newlyCreatedAttrId);
      } else {
        const attrId = uuidv4();
        newValue = toAttributeValue(attrId);
        newAttribute = pastedVal;

        const newAttrIdsForDimIds = newAttrIdsForDimIdsByVal[dimensionId] ?? {};
        newAttrIdsForDimIds[pastedVal] = attrId;
        newAttrIdsForDimIdsByVal[dimensionId] = newAttrIdsForDimIds;
      }
    }

    return { newValue, newAttribute };
  };

  // case 2: pasting into a field value cell
  if (isBusinessObjectFieldSpecColumnKey(columnKey)) {
    const { objectFieldSpecId } = columnKey;
    if (objectFieldSpecId == null) {
      return undefined;
    }

    if (
      isBusinessObjectFieldSpecRestrictedSelector(state, {
        fieldSpecId: objectFieldSpecId,
        blockId: timeSeriesBlockId != null ? timeSeriesBlockId : undefined,
      })
    ) {
      return undefined;
    }

    const { newAttribute, newValue } = getNewAttrAndValueForField(objectFieldSpecId);
    if (newValue == null) {
      return undefined;
    }

    return {
      type: 'field',
      newValue,
      objectSpecId,
      objectId,
      fieldSpecId: objectFieldSpecId,
      newAttribute,
      blockId: timeSeriesBlockId ?? '',
    };
  }

  // case 3: pasting into a field time series cell
  const { monthKey } = columnKey;
  const fieldSpecId =
    rowKey != null && 'fieldSpecId' in rowKey
      ? rowKey.fieldSpecId
      : blockId != null
        ? blockConfigObjectFieldSpecAsTimeSeriesIdSelector(state, blockId)
        : undefined;
  if (fieldSpecId == null) {
    return undefined;
  }

  if (
    blockId != null &&
    isBusinessObjectFieldSpecRestrictedSelector(state, {
      fieldSpecId,
      blockId,
    })
  ) {
    return undefined;
  }

  // Should have just one updated time series for each field
  const timeSeriesUpdateKey = `${objectId}-${fieldSpecId}`;
  let timeSeriesUpdate: ObjectTimeSeriesUpdate | undefined = timeSeriesUpdates[timeSeriesUpdateKey];
  const updatingExistingValue = timeSeriesUpdate != null;
  if (timeSeriesUpdate == null) {
    timeSeriesUpdate = {
      type: 'timeSeries',
      objectSpecId,
      objectId,
      fieldSpecId,
      values: {},
      newAttributes: {},
    };
    timeSeriesUpdates[timeSeriesUpdateKey] = timeSeriesUpdate;
  }

  const { newValue, newAttribute } = getNewAttrAndValueForField(fieldSpecId);
  if (newValue == null) {
    return undefined;
  }

  timeSeriesUpdate.values[monthKey] = newValue.value == null ? undefined : newValue;
  if (newAttribute != null && newValue.type === ValueType.Attribute && newValue.value != null) {
    timeSeriesUpdate.newAttributes[newValue.value] = newAttribute;
  }

  return updatingExistingValue ? undefined : timeSeriesUpdate;
}
