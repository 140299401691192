import { Box, Flex, Popover, PopoverContent, PopoverTrigger, Portal } from '@chakra-ui/react';
import React, { useCallback, useEffect } from 'react';

import Reorderable from 'components/Reorderable/Reorderable';
import { COLUMN_HEADER_CELL_HEIGHT_PX } from 'config/cells';
import { toPxString } from 'helpers/styles';
import useControlledPopover from 'hooks/useControlledPopover';
import { BaseDragItem, UseReorderableProps } from 'hooks/useReorderable';

const OFFSET: [number, number] = [0, 0];

interface Props<T extends BaseDragItem> extends Omit<UseReorderableProps<T>, 'itemType'> {
  isAutoFocused?: boolean;
  canDrag: boolean;
  disabled: boolean;
  isLast: boolean;
  children: React.ReactNode;
  popoverContent: JSX.Element;
  orientation?: React.ComponentProps<typeof Reorderable>['orientation'];
  onClose?: () => void;
}

const InteractiveColumnHeaderCell = <T extends BaseDragItem>(props: Props<T>) => {
  const {
    children,
    item,
    canDrag,
    isLast,
    onDrop,
    orientation = 'horizontal',
    ...restProps
  } = props;
  return (
    <ColumnHeaderCellWithMenu {...restProps}>
      <Reorderable
        item={item}
        disabled={!canDrag}
        itemType={item.type}
        isLast={isLast}
        onDrop={onDrop}
        orientation={orientation}
      >
        {children}
      </Reorderable>
    </ColumnHeaderCellWithMenu>
  );
};

interface ColumnHeaderCellWithMenuProps {
  isAutoFocused?: boolean;
  disabled: boolean;
  children: React.ReactNode;
  popoverContent: JSX.Element;
  onClose?: () => void;
}

export const ColumnHeaderCellWithMenu: React.FC<ColumnHeaderCellWithMenuProps> = (props) => {
  const { children, popoverContent, isAutoFocused, disabled } = props;

  const onCloseProp = props.onClose;
  const { contentRef, triggerRef, isOpen, onOpen, onClose } = useControlledPopover({
    onClickOutside: onCloseProp,
  });

  const closePopover = useCallback(() => {
    onClose();
    onCloseProp?.();
  }, [onClose, onCloseProp]);

  const onContextMenu = useCallback<React.MouseEventHandler>(
    (ev) => {
      ev.preventDefault();
      onOpen();
    },
    [onOpen],
  );

  useEffect(() => {
    if (isAutoFocused) {
      onOpen();
    }
  }, [isAutoFocused, onOpen]);

  const popoverContentExtraProps = React.cloneElement(popoverContent, {
    ...popoverContent.props,
    onClose: closePopover,
    isOpen,
  } as typeof popoverContent);

  return (
    <Flex
      alignItems="flex-end"
      data-testid="column-header-cell"
      width="full"
      flexGrow={1}
      height={toPxString(COLUMN_HEADER_CELL_HEIGHT_PX)}
    >
      {disabled ? (
        children
      ) : (
        <Popover
          isLazy
          placement="bottom-start"
          offset={OFFSET}
          returnFocusOnClose={false}
          closeOnBlur={false}
          autoFocus={false}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={closePopover}
        >
          <PopoverTrigger>
            <Box
              ref={triggerRef}
              data-testid="column-header-handle"
              bgColor={isOpen ? 'gray.100' : 'white'}
              borderTopRadius="md"
              onContextMenu={onContextMenu}
              position="relative"
              w="full"
              _hover={{ bgColor: 'gray.100' }}
            >
              {children}
            </Box>
          </PopoverTrigger>
          <Portal>
            <PopoverContent padding={0} ref={contentRef}>
              {popoverContentExtraProps}
            </PopoverContent>
          </Portal>
        </Popover>
      )}
    </Flex>
  );
};

export default InteractiveColumnHeaderCell;
