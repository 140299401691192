import { Flex, useMergeRefs } from '@chakra-ui/react';
import React, { Ref } from 'react';
import { useDrop } from 'react-dnd';

import { BaseDragItem } from 'hooks/useReorderable';

interface DroppableProps<T extends BaseDragItem> {
  dropItemType: string | string[];
  onDrop: (item: T) => void;
  children: React.ReactNode;
}

// NB: we have to do this hacky stuff instead of React.forwardRef
// because you cannot have a generic props type with React.forwardRef
// Got this solution from: https://stackoverflow.com/a/58473012
const Droppable = React.forwardRef(
  <T extends BaseDragItem>(
    props: React.PropsWithChildren<DroppableProps<T>>,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const { dropItemType, onDrop, children } = props;

    const [{ isOver }, dropRef] = useDrop<T, unknown, { isOver: boolean }>(
      () => ({
        accept: dropItemType,
        drop: onDrop,
        collect: (monitor) => ({
          isOver: monitor.isOver(),
        }),
      }),
      [dropItemType, onDrop],
    );

    const mergedRef = useMergeRefs(ref, dropRef);
    return (
      <Flex
        ref={mergedRef}
        width="full"
        flexShrink={0}
        borderColor={isOver ? 'selection.500' : 'transparent'}
        borderRadius="md"
      >
        {children}
      </Flex>
    );
  },
) as <T extends BaseDragItem>(p: DroppableProps<T> & { ref?: Ref<HTMLDivElement> }) => JSX.Element;

export default Droppable;
