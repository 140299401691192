import { formatDriverValue } from 'helpers/formatting';
import { Attribute } from 'reduxStore/models/dimensions';
import { DisplayConfiguration } from 'reduxStore/models/value';

/**
 * Renders a tooltip for an AgChart.
 *
 * @param seriesName - The title of the series.
 * @param datum - The data object containing the id, key, and value.
 * @param displayConfiguration - The display configuration for formatting the value.
 * @param attributes - An array of attributes to be appended to the title.
 * @returns An object containing the title and content of the tooltip.
 */
export function renderTooltip(
  seriesName: string | undefined,
  datum: { id: string; key: string; value: number },
  displayConfiguration: DisplayConfiguration,
  attributes: Attribute[] | undefined,
) {
  const content = formatDriverValue(datum.value, displayConfiguration, {
    abbreviate: true,
  });

  if (attributes != null && attributes.length > 0) {
    for (const attr of attributes) {
      seriesName += ` (${attr.value})`;
    }
  }

  return {
    title: seriesName,
    content,
  };
}
