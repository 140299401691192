import { Checkbox, Flex, Text } from '@chakra-ui/react';

interface Props {
  id: string;
  value: string;
  isSelected: boolean;
  onChange: (id: string) => void;
  variant?: 'primary' | 'secondary';
}

const MultiSelectSearchMenuItem = ({ id, value, isSelected, variant, onChange }: Props) => (
  <Flex
    key={id}
    gap={2}
    py={1}
    px={2}
    minHeight="28px"
    alignItems="center"
    _hover={{
      backgroundColor: 'gray.100',
      cursor: 'pointer',
    }}
    onMouseUp={() => {
      onChange(id);
    }}
    data-testid="multi-select-search-menu-item"
  >
    <Checkbox
      isChecked={isSelected}
      borderColor="gray.300"
      _checked={{
        '& .chakra-checkbox__control': {
          borderWidth: '1px',
          borderRadius: '4px',
          background: 'selection.500',
          borderColor: 'selection.500',
        },
      }}
    />
    <Text
      fontWeight="medium"
      fontSize="xs"
      color={variant === 'secondary' ? 'gray.500' : 'gray.600'}
      fontStyle={variant === 'secondary' ? 'italic' : 'normal'}
      textOverflow="ellipsis"
      whiteSpace="wrap"
      maxW="40vh"
    >
      {value}
    </Text>
  </Flex>
);

export default MultiSelectSearchMenuItem;
