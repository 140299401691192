import { Box } from '@chakra-ui/react';

const MainScenarioTag: React.FC = () => {
  return (
    <Box
      borderRadius="md"
      px={1}
      borderWidth="px"
      borderColor="gray.500"
      color="gray.600"
      fontSize="xxs"
      lineHeight="1rem"
      sx={{ color: 'gray.500', _parentAriaSelected: { color: 'white', borderColor: 'white' } }}
    >
      Main
    </Box>
  );
};

export default MainScenarioTag;
