import { createSelector } from 'reselect';

import { eventGroupIdFromWithEventGroup, WithEventGroup } from '@features/Plans';
import { EventGroupId } from 'reduxStore/models/events';
import { CellPalettePopoverState, isCellPalettePopover } from 'reduxStore/reducers/pageSlice';
import { popoverStateSelector } from 'selectors/pageSelector';
import { Selector } from 'types/redux';

const cellPalettePopoverSelector: Selector<CellPalettePopoverState | null> = createSelector(
  popoverStateSelector,
  (popoverState) => {
    return isCellPalettePopover(popoverState) ? popoverState : null;
  },
);

export const planPickerSelectedWithEventGroupSelector: Selector<WithEventGroup | undefined> =
  createSelector(cellPalettePopoverSelector, (cellPalettePopover) => {
    return cellPalettePopover?.planPickerSelectedWithEventGroup;
  });

export const planPickerSelectedEventGroupIdSelector: Selector<EventGroupId | undefined> =
  createSelector(planPickerSelectedWithEventGroupSelector, (withEventGroup) => {
    return eventGroupIdFromWithEventGroup(withEventGroup);
  });

export const multiPlanPickerSelectedEventGroupsSelector: Selector<
  Array<WithEventGroup | null> | undefined
> = createSelector(cellPalettePopoverSelector, (cellPalettePopover) => {
  return cellPalettePopover?.multiPlanPickerSelectedEventGroups;
});

export const isCellPalettePopoverOpenSelector = createSelector(
  cellPalettePopoverSelector,
  (cellPaletteState) => {
    return cellPaletteState != null;
  },
);

export const isPlanPickerOpenSelector = createSelector(
  cellPalettePopoverSelector,
  (cellPaletteState) => {
    return cellPaletteState != null && cellPaletteState.state === 'plans';
  },
);
