// import { RowGroup } from 'components/AgGridComponents/helpers/gridDatasource/types';
import { RowGroup } from 'components/AgGridComponents/helpers/gridDatasource/types';
export const ADD_ITEM_TYPE = 'addItem';
export const ADD_PROPERTY_TYPE = 'addProperty';
export const ADD_DRIVER_TYPE = 'addDriver';
export const FORECAST_FORMULA_KEY = 'forecastFormula';
export const ACTUALS_FORMULA_KEY = 'actualsFormula';
export const EMPTY_ROW_GROUP: RowGroup = [[], 0];

// Number of rows that can be reasonably rendered in the DOM before virtualization.
// This is only applicable to databases on pages.
export const GRID_DOM_LAYOUT_ROW_LIMIT = 25;
export const GRID_TIMESERIES_DOM_LAYOUT_ROW_LIMIT = 12;
export const GRID_ON_PAGE_VIRTUALIZED_HEIGHT_PX = '600px';

// Warning: If you change any of these, you must also change the class name in
// AgGridStyleOverrides.scss
export enum ClassName {
  AddCell = 'add-cell',
  LastCloseCell = 'last-close-cell',
  NameCell = 'name-cell',
  OptionsCell = 'options-cell',
  UnclickableCell = 'unclickable-cell',
  TimeseriesHeaderCell = 'timeseries-header-cell',
  TimeseriesCell = 'timeseries-cell',
  TimeseriesCellHardCodedActual = 'timeseries-cell-hardcoded-actual',
  CumulativeCell = 'cumulative-cell',
  SegmentByCell = 'segment-by-cell',
}
