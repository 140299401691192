import { useMemo } from 'react';

import { ValueType } from 'generated/graphql';
import useAppSelector from 'hooks/useAppSelector';
import { BlockId } from 'reduxStore/models/blocks';
import { BusinessObjectSpecId } from 'reduxStore/models/businessObjectSpecs';
import {
  businessObjectSpecNonRestrictedFieldsSelector,
  isBusinessObjectNameRestrictedSelector,
} from 'selectors/businessObjectNameRestrictionsSelector';
import { dimensionalPropertiesForBusinessObjectSpecIdSelector } from 'selectors/collectionSelector';
import { driversByIdForLayerSelector } from 'selectors/driversSelector';
import { EntityIdFilterItem, FilterItem, FilterValueTypes } from 'types/filtering';

const OBJ_SPEC_INSTANCE_FILTER_NAME = 'Name';
export const useObjectSpecFilterItems = (
  specId: BusinessObjectSpecId,
  blockId: BlockId,
): FilterItem[] => {
  const driversById = useAppSelector(driversByIdForLayerSelector);

  const nonRestrictedFields = useAppSelector((state) =>
    businessObjectSpecNonRestrictedFieldsSelector(state, {
      objectSpecId: specId,
      blockId,
    }),
  );

  const isNameRestricted = useAppSelector((state) =>
    isBusinessObjectNameRestrictedSelector(state, {
      objectSpecId: specId,
      blockId,
    }),
  );

  // TODO (andyy): merge this into a single selector
  const dimPropertiesById = useAppSelector((state) =>
    dimensionalPropertiesForBusinessObjectSpecIdSelector(state, specId),
  );

  const items: FilterItem[] = useMemo(() => {
    const idFilter: EntityIdFilterItem = {
      valueType: FilterValueTypes.ENTITY,
      filterKey: FilterValueTypes.ENTITY,
      label: OBJ_SPEC_INSTANCE_FILTER_NAME,
      entityType: 'object',
      specId,
      // Can't model "not equal" when filtering for specific instances due to a
      // limitation of the formula grammar.
      isNotNegatable: true,
      // ID is always required
      isNotNullable: true,
    };
    const filterItems: FilterItem[] = [];

    if (!isNameRestricted) {
      filterItems.push(idFilter);
    }

    Object.values(dimPropertiesById).forEach((property) => {
      if (property == null) {
        return;
      }
      filterItems.push({
        filterKey: property.id,
        label: property.name,
        dimensionId: property.dimension.id,
        valueType: ValueType.Attribute,
      });
    });

    Object.values(nonRestrictedFields).forEach((fieldInfo) => {
      if (fieldInfo == null) {
        return;
      }

      let item: FilterItem | undefined;
      const itemCommon = {
        filterKey: fieldInfo.id,
        label: fieldInfo.name,
      };
      if (fieldInfo.type === 'fieldSpec') {
        const fieldSpec = fieldInfo.fieldSpec;
        if (fieldSpec.type === ValueType.Attribute) {
          item = {
            ...itemCommon,
            dimensionId: fieldSpec.dimensionId,
            valueType: ValueType.Attribute,
          };
        } else {
          item = {
            ...itemCommon,
            valueType: fieldSpec.type,
          };
        }
      } else if (fieldInfo.type === 'dimensionalProperty') {
        const dimensionProperty = fieldInfo.dimensionalProperty;
        item = {
          ...itemCommon,
          dimensionId: dimensionProperty.dimension.id,
          valueType: ValueType.Attribute,
        };
      } else if (fieldInfo.type === 'driverProperty') {
        const valueTypeExtension =
          driversById[fieldInfo.driverProperty.driverId]?.valueTypeExtension;
        const valueType = valueTypeExtension?.type ?? ValueType.Number;
        const dimId = valueTypeExtension?.attributeTypeExtension?.dimensionId;

        if (valueType === ValueType.Attribute && dimId != null) {
          item = {
            ...itemCommon,
            dimensionId: dimId,
            valueType,
          };
        } else if (valueType === ValueType.Timestamp) {
          item = {
            ...itemCommon,
            valueType,
          };
        } else {
          item = {
            ...itemCommon,
            valueType: ValueType.Number,
          };
        }
      }

      if (item != null) {
        filterItems.push(item);
      }
    });

    return filterItems;
  }, [specId, isNameRestricted, dimPropertiesById, nonRestrictedFields, driversById]);

  return items;
};
