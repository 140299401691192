import { datadogLogs } from '@datadog/browser-logs';
import afterFrame from 'afterframe';
import { useEffect } from 'react';

import { isUserBrowser } from 'helpers/environment';
import {
  PerfMonitorDetail,
  START_DETAIL_PANE_OPEN,
  START_FORMULA_OPEN,
  START_PAGE_NAVIGATION,
} from 'helpers/performanceMonitor';

export const useMonitorRenderPerformanceOnPageNavigation = (pageId: string | null | undefined) => {
  useMonitorRenderPerformance(START_PAGE_NAVIGATION, pageId);
};

export const useMonitorRenderPerformanceOnDetailPaneOpen = (entityId: string | null) => {
  useMonitorRenderPerformance(START_DETAIL_PANE_OPEN, entityId);
};

export const useMonitorRenderPerformanceOnFormulaOpen = (driverId: string) => {
  useMonitorRenderPerformance(START_FORMULA_OPEN, driverId);
};

function useMonitorRenderPerformance(workflowName: string, monitorId: string | null | undefined) {
  useEffect(() => {
    afterFrame(() => {
      const marks = performance.getEntriesByName(workflowName, 'mark');
      if (marks.length === 0) {
        return;
      }

      const mark = marks[0] as PerformanceMark;
      const detail = mark.detail as PerfMonitorDetail;
      const measure = performance.measure(`measure-${workflowName}`, workflowName);

      const reportData = {
        ...detail,
        workflowName,
        elapsedTime: measure.duration,
      };

      if (isUserBrowser()) {
        datadogLogs.logger.info('Render measurement', reportData);
      }

      performance.clearMarks(workflowName);
    });
  }, [workflowName, monitorId]);
}
