import { ArrowForwardIcon, CheckIcon } from '@chakra-ui/icons';
import {
  Center,
  Flex,
  ListItem,
  Spinner,
  StyleProps,
  Switch,
  Text,
  useListStyles,
  useMergeRefs,
} from '@chakra-ui/react';
import { isString } from 'lodash';
import React from 'react';

import DragHandleButton from 'components/DragHandleButton/DragHandleButton';
import KeyboardShortcutHint from 'components/KeyboardShortcutHint/KeyboardShortcutHint';
import MoreMenuButton, {
  MoreMenuOptionsColorScheme,
} from 'components/MoreMenuButton/MoreMenuButton';
import { KeyboardShortcut } from 'config/shortcuts';
import { useSelectMenuItem } from 'hooks/useSelectMenuItem';

export interface SelectMenuItemProps {
  idx: number;

  isFocused: boolean;
  isDisabled?: boolean;
  hasNextMenu?: boolean;
  submenuType?: 'submenu' | 'options';
  isNextMenuOpen?: boolean;
  isChecked?: boolean;
  checkedStyle?: 'check' | 'switch' | 'none';
  isDraggable?: boolean;
  draggingDisabled?: boolean;

  name: string | React.ReactNode;
  icon?: JSX.Element;
  iconColor?: StyleProps['color'];
  meta?: React.ReactNode;
  isMetaLoading?: boolean;
  alwaysShowMeta?: boolean;
  onlyEnableMeta?: boolean;

  shortcutHint?: KeyboardShortcut | null;

  // Will otherwise only show on focus
  alwaysShowHint?: boolean;
}

const OPTIONS_BUTTON_COLOR_SCHEME: MoreMenuOptionsColorScheme = {
  selected: {
    color: 'white',
    backgroundColor: 'selection.600',
  },
  unselected: {
    color: 'white',
    hoverBackgroundColor: 'selection.600',
    activeBackgroundColor: 'selection.600',
  },
};

const SelectMenuItem = React.forwardRef<HTMLLIElement, SelectMenuItemProps>(
  (
    {
      idx,
      icon,
      iconColor,
      name,
      isFocused = false,
      isDisabled = false,
      hasNextMenu = false,
      submenuType = 'submenu',
      isChecked,
      isDraggable = false,
      draggingDisabled,
      meta,
      isMetaLoading,
      alwaysShowMeta = true,
      shortcutHint = 'enter',
      alwaysShowHint = false,
      checkedStyle = 'check',
      onlyEnableMeta = false,
    },
    ref,
  ) => {
    const { itemRef, onMouseDown, onMouseLeave, onMouseEnter, onOptionsMouseDown, showSubmenu } =
      useSelectMenuItem({
        isFocused,
        idx,
        showSubmenuOnHover: submenuType === 'submenu',
      });
    const listStyles = useListStyles();
    const mergedRef = useMergeRefs(ref, itemRef);

    return (
      <Flex
        onMouseEnter={onMouseEnter}
        onMouseOut={onMouseLeave}
        pointerEvents={isDisabled ? 'none' : undefined}
        opacity={isDisabled ? 0.5 : 1}
        w="full"
      >
        {isDraggable && (
          <DragHandleButton aria-label="Drag to move" isDisabled={draggingDisabled} />
        )}
        <ListItem
          data-testid="select-menu-list-item"
          ref={mergedRef}
          aria-selected={isFocused}
          role="group"
          {...(isFocused && { 'data-focus': true })}
          onMouseDown={onMouseDown}
          lineHeight="1.375rem"
          columnGap={2}
          w="full"
          _active={{ bgColor: onlyEnableMeta ? 'gray.100' : 'inherit' }}
        >
          {isChecked != null && checkedStyle === 'check' && (
            <Flex
              alignItems="center"
              minWidth="20px"
              justifyContent="center"
              id="check-icon-container"
            >
              {isChecked && <CheckIcon ml={2} mr="-1px" boxSize={3} fill="gray.600" />}
            </Flex>
          )}
          {icon != null && (
            <Center
              ml={1}
              flexShrink={0}
              width={4}
              height={4}
              sx={{
                ...listStyles.submenuLabel,
                color: iconColor ?? listStyles.submenuLabel.color,
                fill: iconColor ?? listStyles.submenuLabel.color,
              }}
            >
              {icon}
            </Center>
          )}
          <Flex
            opacity={onlyEnableMeta ? 0.5 : 1}
            justifyContent="space-between"
            alignItems="center"
            columnGap={1}
            flex={1}
          >
            {isString(name) ? <Text noOfLines={1}>{name}</Text> : name}
          </Flex>
          <Flex
            color="gray.400"
            flexShrink={0}
            minWidth={0}
            sx={listStyles.submenuLabel}
            alignItems="center"
            columnGap={1}
          >
            {isMetaLoading != null && isMetaLoading ? (
              <Spinner />
            ) : meta != null ? (
              alwaysShowMeta || isFocused ? (
                isString(meta) ? (
                  <Text data-testid="meta-value" fontSize="xs" flexShrink={0}>
                    {meta}
                  </Text>
                ) : (
                  meta
                )
              ) : null
            ) : (
              (alwaysShowHint || isFocused) &&
              !hasNextMenu &&
              shortcutHint != null && <KeyboardShortcutHint shortcut={shortcutHint} />
            )}
            {hasNextMenu &&
              (submenuType === 'options' ? (
                <MoreMenuButton
                  onMouseDown={onOptionsMouseDown}
                  isSelected={isFocused && showSubmenu}
                  colorScheme={isFocused ? OPTIONS_BUTTON_COLOR_SCHEME : undefined}
                  orientation="horizontal"
                />
              ) : (
                <ArrowForwardIcon />
              ))}
            {isChecked != null && checkedStyle === 'switch' && <Switch isChecked={isChecked} />}
          </Flex>
        </ListItem>
      </Flex>
    );
  },
);

SelectMenuItem.displayName = 'SelectMenuItem';

export default React.memo(SelectMenuItem);
