import * as Sentry from '@sentry/nextjs';
import {
  CharStreams,
  CommonTokenStream,
  Lexer,
  LexerNoViableAltException,
  RecognitionException,
  Recognizer,
  Token,
} from 'antlr4ts';
import { ParseTree } from 'antlr4ts/tree/ParseTree';
import { ParseTreeWalker } from 'antlr4ts/tree/ParseTreeWalker';
import isEqual from 'lodash/isEqual';
import isNumber from 'lodash/isNumber';
import memoize from 'lodash/memoize';
import pick from 'lodash/pick';

import { DataArbiter } from 'components/AgGridComponents/helpers/gridDatasource/DataArbiter';
import { CalculationErrorType, DriverType, ImpactType, ValueType } from 'generated/graphql';
import {
  CURRENT_MONTH_KEY,
  extractMonthKey,
  getMonthKeyRange,
  nextYearMonthKey,
} from 'helpers/dates';
import { resolveDependencyDateRange } from 'helpers/dependencies';
import { DependencyListenerEvaluator } from 'helpers/formulaEvaluation/DependenciesListenerEvaluator';
import { CalculatorLexer } from 'helpers/formulaEvaluation/ForecastCalculator/CalculatorLexer';
import { CalculatorListener } from 'helpers/formulaEvaluation/ForecastCalculator/CalculatorListener';
import {
  BlockFilterContext,
  CalculatorContext,
  CalculatorParser,
  StringCalculatorContext,
  TimeRangeContext,
  TimestampCalculatorContext,
} from 'helpers/formulaEvaluation/ForecastCalculator/CalculatorParser';
import { DependencyListener } from 'helpers/formulaEvaluation/ForecastCalculator/DependencyListener';
import { ForecastCalculatorListener } from 'helpers/formulaEvaluation/ForecastCalculator/ForecastCalculatorListener';
import {
  CacheKey,
  getFormulaCacheKey,
} from 'helpers/formulaEvaluation/ForecastCalculator/FormulaCache';
import { FormulaCalculationContext } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaCalculationContext';
import FormulaDisplayListener, {
  FormulaDisplay,
  FormulaDisplayChunk,
  FormulaDisplayChunkType,
  ObjectFormulaDisplayChunk,
} from 'helpers/formulaEvaluation/ForecastCalculator/FormulaDisplayListener';
import { FormulaErrorListener } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaErrorListener';
import { FormulaEvaluator } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaEvaluator';
import { ModelingError } from 'helpers/formulaEvaluation/ForecastCalculator/ModelingError';
import {
  FormulaEntityTypedId,
  ReferenceEvaluator,
} from 'helpers/formulaEvaluation/ReferenceEvaluator';
import { isNotNull } from 'helpers/typescript';
import { BlockId } from 'reduxStore/models/blocks';
import {
  BusinessObjectFieldSpec,
  BusinessObjectFieldSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import {
  BusinessObject,
  BusinessObjectFieldId,
  BusinessObjectId,
} from 'reduxStore/models/businessObjects';
import { Attribute } from 'reduxStore/models/dimensions';
import { Driver, DriverId } from 'reduxStore/models/drivers';
import { EventId } from 'reduxStore/models/events';
import { SubmodelId } from 'reduxStore/models/submodels';
import { toValueType } from 'reduxStore/models/value';
import {
  DateRange,
  ValueOrCalculationError,
  ValueWithCalculationContext,
  isCalculationError,
} from 'types/dataset';
import { MonthKey } from 'types/datetime';
import { CalculableDependency, Dependency, isCalculableDependency } from 'types/dependencies';
import { FormulaTimeRange, RawFormula } from 'types/formula';

export interface Listener<T> extends CalculatorListener {
  getResult: () => T;
  getTimestampResult: () => T;
}

const EXPECTING_EXPRESSION_REGEX = /expecting {.*sum.*}/;
const EXPECTING_OPERATOR_REGEX = /expecting {.*\+.*}/;
const EXPECTING_AGGREGATOR_REGEX = /mismatched input '(?:filter|dimDriver|submodel)\('/;
const EXPECTING_OBJECT_AGGREGATOR_REGEX = /mismatched input 'objectSpec\('/;

function simplifyAntlrErrorMsg(msg: string): string {
  let simplifiedMsg = msg;
  if (msg.includes('mismatched input')) {
    if (EXPECTING_OPERATOR_REGEX.test(simplifiedMsg)) {
      simplifiedMsg = 'Missing operator';
    } else if (EXPECTING_AGGREGATOR_REGEX.test(simplifiedMsg)) {
      simplifiedMsg = 'Missing aggregator (e.g. SUM)';
    } else if (EXPECTING_OBJECT_AGGREGATOR_REGEX.test(simplifiedMsg)) {
      simplifiedMsg = 'Missing aggregator (e.g. COUNT or SUM)';
    } else {
      simplifiedMsg = 'Missing expression';
    }
    if (msg.includes('<EOF>')) {
      simplifiedMsg += ' at end of formula';
    }
  } else if (EXPECTING_EXPRESSION_REGEX.test(simplifiedMsg)) {
    simplifiedMsg = simplifiedMsg.replace(EXPECTING_EXPRESSION_REGEX, 'expecting expression');
  }
  simplifiedMsg = simplifiedMsg.replace("'<EOF>'", 'end of formula');
  simplifiedMsg = simplifiedMsg.replace('<EOF>', 'end of formula');

  return simplifiedMsg;
}

// eslint-disable-next-line max-params
function handleParserError(
  recognizer: Recognizer<Token, any>,
  offendingSymbol: Token | undefined,
  _line: number,
  charPositionInLine: number,
  msg: string,
  _e: RecognitionException | undefined,
) {
  if (offendingSymbol == null) {
    return;
  }
  const startPos = offendingSymbol.startIndex;
  const len = offendingSymbol.stopIndex - startPos + 1;
  const simplifiedMsg = simplifyAntlrErrorMsg(msg);
  throw new ModelingError(simplifiedMsg, startPos, len);
}

// eslint-disable-next-line max-params
function handleLexerError(
  recognizer: Recognizer<number, any>,
  // ANTLR's docs specify that this is always undefined for a lexer but the
  // types require this to have a non undefined value in the union
  _offendingSymbol: number | undefined,
  _line: number,
  charPositionInLine: number,
  msg: string,
  e: RecognitionException | undefined,
) {
  // Can't do this in the parameters because of the generated types
  if (!(recognizer instanceof Lexer)) {
    return;
  }

  let startPos = charPositionInLine;
  if (e instanceof LexerNoViableAltException) {
    // If we have this info we may as well use it
    startPos = e.startIndex;
  }

  const endPos = e?.inputStream?.index;
  const len = endPos != null ? endPos - startPos : 1;

  const simplifiedMsg = simplifyAntlrErrorMsg(msg);
  throw new ModelingError(simplifiedMsg, startPos, len);
}

interface GetFormulaEntityDependencyNodesProps {
  attributesBySubDriverId: Record<DriverId, Attribute[] | undefined>;
  driversById: Record<DriverId, Driver | undefined>;
  evaluator: FormulaEvaluator;
  fieldSpecsById: Record<BusinessObjectFieldSpecId, BusinessObjectFieldSpec | undefined>;
  formulaEntityId: FormulaEntityTypedId;
  isActualsFormula: boolean;
  objectsByFieldId: Record<BusinessObjectFieldId, BusinessObject>;
  objectsById: Record<BusinessObjectId, BusinessObject>;
  submodelIdByBlockId: NullableRecord<BlockId, SubmodelId>;
  rawFormula: RawFormula;
}

export interface FormulaErrorEvaluationProps extends GetFormulaEntityDependencyNodesProps {
  skipDependencyLoopCheck?: boolean;
}

export function getFormulaError(props: FormulaErrorEvaluationProps): ModelingError | undefined {
  const {
    rawFormula,
    formulaEntityId,
    evaluator,
    driversById,
    skipDependencyLoopCheck = false,
  } = props;

  const trimmedFormula = rawFormula.trim();
  if (trimmedFormula === '') {
    return undefined;
  }

  let valueType: ValueType | undefined;
  if (formulaEntityId.type === 'objectField') {
    valueType = evaluator.getBusinessObjectFieldSpecByFieldId(formulaEntityId.id)?.type;
  } else if (formulaEntityId.type === 'objectFieldSpec') {
    valueType = evaluator.getBusinessObjectFieldSpecById(formulaEntityId.id)?.type;
  } else if (driversById[formulaEntityId.id]?.valueTypeExtension?.type != null) {
    valueType = driversById[formulaEntityId.id]?.valueTypeExtension?.type;
  }

  try {
    const listener = new FormulaErrorListener(rawFormula, driversById);

    evaluate(rawFormula, listener, valueType);

    if (!skipDependencyLoopCheck) {
      throwOnDependencyLoop(props);
    }
    return undefined;
  } catch (e) {
    if (e instanceof ModelingError) {
      if (valueType === ValueType.Timestamp) {
        return { ...e, message: 'Formula must evaluate to a date' };
      }
      return e;
    }
    throw e;
  }
}

const getFormulaParseTree = memoize((formula: string): CalculatorContext => {
  const parser = getParser(formula);
  return parser.calculator();
});

const getBlockFilterParseTree = memoize((filter: string): BlockFilterContext => {
  const parser = getParser(filter);
  return parser.blockFilter();
});

const getTimeRangeParseTree = memoize((formula: string): TimeRangeContext => {
  const parser = getParser(formula);
  return parser.timeRange();
});

const getStringFormulaParseTree = memoize((formula: string): StringCalculatorContext => {
  const parser = getParser(formula);
  return parser.stringCalculator();
});

const getTimestampFormulaParseTree = memoize((formula: string): TimestampCalculatorContext => {
  const parser = getParser(formula);
  return parser.timestampCalculator();
});

const getParser = (input: string): CalculatorParser => {
  const inputStream = CharStreams.fromString(input);
  const lexer = new CalculatorLexer(inputStream);
  lexer.removeErrorListeners();

  const commonTokenStream = new CommonTokenStream(lexer);
  const parser = new CalculatorParser(commonTokenStream);
  parser.removeErrorListeners();

  lexer.addErrorListener({ syntaxError: handleLexerError });
  parser.addErrorListener({ syntaxError: handleParserError });
  return parser;
};

export default function evaluate<T>(
  formula: string,
  interpreter: Listener<T>,
  valueType: ValueType = ValueType.Number,
): T {
  if (formula === '') {
    return interpreter.getResult();
  }

  if (valueType === ValueType.Timestamp) {
    const parseTree = getTimestampFormulaParseTree(formula);
    ParseTreeWalker.DEFAULT.walk(interpreter, parseTree);
    return interpreter.getTimestampResult();
  }

  const parseTree = getFormulaParseTree(formula);
  ParseTreeWalker.DEFAULT.walk(interpreter, parseTree);
  return interpreter.getResult();
}

export function evaluateFormulaDisplay(
  formula: string,
  interpreter: FormulaDisplayListener,
  valueType?: ValueType,
): FormulaDisplay {
  try {
    return evaluate(formula, interpreter, valueType);
  } catch (e) {
    const errMsg = e instanceof Error ? e.message : 'Unknown error';
    const chunk: FormulaDisplayChunk = {
      type: FormulaDisplayChunkType.Invalid,
      text: formula,
      error: errMsg,
    };
    return {
      chunks: [chunk],
      isEditingSupported: true,
      error: errMsg,
    };
  }
}

type CalculateArgs = {
  evaluator: ReferenceEvaluator;
  context?: FormulaCalculationContext;
  entityId: FormulaEntityTypedId;
  monthKey: MonthKey;
  visited: Set<CacheKey>;
  forceComputeForecast?: boolean;
  ignoreEventIds?: Set<EventId>;
  newlyAddedCacheKeys: Set<CacheKey>;
};

export function calculate({
  evaluator,
  context,
  entityId,
  monthKey,
  visited,
  ignoreEventIds,
  forceComputeForecast = false,
  newlyAddedCacheKeys,
}: CalculateArgs): ValueOrCalculationError | undefined {
  DataArbiter.get().updateEntityIndices({
    entityId: entityId.id,
    dateRange: [monthKey, monthKey],
    layerId: evaluator.getLayerId(),
  });
  const cache = evaluator.getFormulaCache();

  context?.push({
    id: entityId,
    dateRange: { start: monthKey, end: monthKey },
  });

  const ignoreEventIdsArray = ignoreEventIds != null ? Array.from(ignoreEventIds) : [];
  const cacheKey = getFormulaCacheKey(entityId.id, monthKey, ignoreEventIdsArray);
  const [cachedVal, isCached] = cache.get(cacheKey);

  if (isCached) {
    context?.pop({ cacheHit: true });
    return cachedVal;
  }

  const entity = evaluator.getEntityByKey(entityId);
  if (entity == null) {
    context?.pop({ cacheHit: false });
    return undefined;
  }

  // For now only using actuals if time is before the last actual datetime
  let actualsValue: ValueWithCalculationContext | undefined;
  const shouldUseActual = evaluator.shouldUseActual(monthKey);
  if (shouldUseActual) {
    const point = evaluator.getActualTimeSeriesData(entityId, monthKey);
    if (point != null) {
      if (isCalculationError(point)) {
        context?.pop({ cacheHit: false });
        return undefined;
      }

      actualsValue = { ...point, cacheKey };

      if (!forceComputeForecast) {
        context?.pop({ cacheHit: false });
        cache.set(cacheKey, actualsValue);
        return actualsValue;
      }
    }
  }

  // Values should start on the cohort month e.g. NewCustomers[Aug 22] starts Aug 22
  // We do allow setting actuals before the start of a cohort
  const cohortMonth = evaluator.getDriverCohortMonth(entityId.id);
  if (cohortMonth != null && monthKey < cohortMonth) {
    context?.pop({ cacheHit: false });
    return actualsValue != null ? { ...actualsValue, cacheKey } : undefined;
  }

  let formula: string | undefined;
  if (entity.valueType != null) {
    const listener = new ForecastCalculatorListener({
      entityId,
      evaluator,
      context,
      monthKey,
      visited,
      ignoreEventIds,
      cacheKey,
      newlyAddedCacheKeys,
    });

    try {
      formula = evaluator.getFormula(entityId, monthKey);

      let res: ValueOrCalculationError | undefined;
      if (formula != null) {
        if (entity.valueType === ValueType.Number) {
          try {
            res = evaluate<ValueOrCalculationError | undefined>(formula, listener);
          } catch (e) {
            if (e instanceof ModelingError) {
              res = { error: CalculationErrorType.Unexpected, details: e.message };
            } else {
              throw e;
            }
          }
        } else if (entity.valueType === ValueType.Timestamp && entityId.type === 'driver') {
          let parseTree;
          if (shouldUseActual) {
            //TODO (@ahmed): remove quotes once we have formulas with more than a single value
            parseTree = getTimestampFormulaParseTree(`'${formula}'`);
          } else {
            parseTree = getFormulaParseTree(formula);
          }

          ParseTreeWalker.DEFAULT.walk(
            listener as Listener<ValueOrCalculationError | undefined>,
            parseTree as ParseTree,
          );
          res = listener.getTimestampResult();
        } else if (entity.valueType === ValueType.Timestamp) {
          const parseTree = getTimestampFormulaParseTree(formula);
          ParseTreeWalker.DEFAULT.walk(
            listener as Listener<ValueOrCalculationError | undefined>,
            parseTree as ParseTree,
          );
          res = listener.getTimestampResult();
        } else if (entity.valueType === ValueType.Attribute && entityId.type === 'driver') {
          let parseTree;
          if (shouldUseActual) {
            //TODO (@ahmed): remove quotes once we have formulas with more than a single value
            parseTree = getStringFormulaParseTree(`'${formula}'`);
          } else {
            parseTree = getFormulaParseTree(formula);
          }

          ParseTreeWalker.DEFAULT.walk(
            listener as Listener<ValueOrCalculationError | undefined>,
            parseTree as ParseTree,
          );
          res = listener.getStringValue();
        } else {
          const parseTree = getStringFormulaParseTree(formula);
          ParseTreeWalker.DEFAULT.walk(
            listener as Listener<ValueOrCalculationError | undefined>,
            parseTree as ParseTree,
          );
          res = listener.getStringValue();
        }
      }

      if (isCalculationError(res)) {
        context?.pop({ cacheHit: false });
        return res;
      }

      if (res?.value != null && res.type !== entity.valueType) {
        context?.pop({ cacheHit: false });
        return { error: CalculationErrorType.Unexpected, details: 'Unexpected value type' };
      }

      const noActualsPresent =
        evaluator.getRawActualsFormula(entityId) == null && actualsValue == null;
      if (!shouldUseActual || noActualsPresent || entity.isStartField) {
        const impact = evaluator.getImpact(entityId, monthKey, ignoreEventIds);
        if (impact != null) {
          if (impact.type === ImpactType.Delta) {
            // Delta impacts cannot be applied to null values
            if (res == null) {
              context?.pop({ cacheHit: false });
              return res;
            }
            if (
              !isCalculationError(res) &&
              res.type === ValueType.Number &&
              impact.impact.type === ValueType.Number
            ) {
              res.value += impact.impact.value;
            }
          } else if (!isCalculationError(res) && entity.valueType === impact.impact.type) {
            res = { cacheKey, ...res, ...impact.impact };
          }
        }
      }

      // if we force computation of the forecast to compute object dependencies, make sure that
      // we return the appropriate result
      if (actualsValue != null && isNumber(actualsValue.value)) {
        res = { ...actualsValue, cacheKey };
      }

      // If value wasn't filled by formula or by hardcoded actuals, fill with initial value
      if (res?.value == null && entity.initialValue != null) {
        const startFieldId = entity.initialValue.startFieldId;
        const value = toValueType(entity.initialValue.value, entity.valueType);
        if (startFieldId == null) {
          res = { cacheKey, ...res, ...value };
        } else {
          const isAfterStartDate = () => {
            const startRes = calculate({
              evaluator,
              context,
              entityId: { type: 'objectField', id: startFieldId },
              monthKey,
              visited,
              ignoreEventIds,
              forceComputeForecast,
              newlyAddedCacheKeys,
            });
            if (isCalculationError(startRes)) {
              context?.pop({ cacheHit: false });
              return false;
            }
            // No start date should fill all of time
            if (startRes?.value == null) {
              context?.pop({ cacheHit: false });
              return true;
            }
            if (startRes.type === ValueType.Timestamp) {
              const startMonthKey = extractMonthKey(startRes.value);
              if (startMonthKey <= monthKey) {
                context?.pop({ cacheHit: false });
                return true;
              }
            }
            return false;
          };

          if (isAfterStartDate()) {
            res = { cacheKey, ...res, ...value };
          }
        }
      }

      cache.set(cacheKey, res);
      newlyAddedCacheKeys.add(cacheKey);

      context?.pop({ cacheHit: false });

      return res;
    } catch (e) {
      // TODO (demc): T-17887
      context?.pop({ cacheHit: false });
      return undefined;
    }
  } else {
    Sentry.withScope((scope: Sentry.Scope) => {
      scope.setLevel('warning');
      scope.setExtra('entityId', entityId.id);
      scope.setExtra('entityType', entityId.type);
      Sentry.captureMessage('Calculate called on entity without value type');
    });
    return actualsValue != null ? { ...actualsValue, cacheKey } : undefined;
  }
}

export function evaluateTimeRange({
  evaluator,
  context,
  blockId,
  timeRangeFormula,
}: {
  evaluator: ReferenceEvaluator;
  context?: FormulaCalculationContext;
  blockId: BlockId;
  timeRangeFormula: string;
}): DateRange | undefined {
  const monthKey = CURRENT_MONTH_KEY;
  const cacheKey = getFormulaCacheKey(blockId, monthKey, []);

  const listener = new ForecastCalculatorListener({
    entityId: { type: 'block', id: blockId },
    evaluator,
    context,
    monthKey,
    visited: new Set(),
    cacheKey,
    newlyAddedCacheKeys: new Set(),
  });

  try {
    const parseTree = getTimeRangeParseTree(timeRangeFormula);
    ParseTreeWalker.DEFAULT.walk(
      listener as Listener<ValueOrCalculationError | undefined>,
      parseTree as ParseTree,
    );
    return listener.getDateRange();
  } catch (err) {
    return undefined;
  }
}

export function filterObjects({
  evaluator,
  context,
  blockId,
  filter,
  monthKey,
  visited,
  ignoreEventIds,
  newlyAddedCacheKeys,
}: {
  evaluator: ReferenceEvaluator;
  context?: FormulaCalculationContext;
  blockId: BlockId;
  filter: string;
  monthKey: MonthKey;
  visited: Set<CacheKey>;
  ignoreEventIds?: Set<EventId>;
  newlyAddedCacheKeys: Set<CacheKey>;
}): BusinessObjectId[] | undefined {
  const cacheKey = getFormulaCacheKey(blockId, monthKey, []);

  const listener = new ForecastCalculatorListener({
    entityId: { type: 'block', id: blockId },
    evaluator,
    context,
    monthKey,
    visited,
    ignoreEventIds,
    cacheKey,
    newlyAddedCacheKeys,
  });

  try {
    const parseTree = getBlockFilterParseTree(filter);
    ParseTreeWalker.DEFAULT.walk(
      listener as Listener<ValueOrCalculationError | undefined>,
      parseTree as ParseTree,
    );
    return listener.getFilteredObjectIds();
  } catch (err) {
    return undefined;
  }
}

export function objectsDisplay(
  filterExpression: string,
  listener: FormulaDisplayListener,
): ObjectFormulaDisplayChunk | undefined {
  try {
    const parseTree = getBlockFilterParseTree(filterExpression);

    ParseTreeWalker.DEFAULT.walk(
      listener as Listener<ValueOrCalculationError | undefined>,
      parseTree as ParseTree,
    );
    return listener.getObjectDisplay();
  } catch (err) {
    return undefined;
  }
}

export function formulaTimeRange(
  timeRangeExpression: string,
  listener: FormulaDisplayListener,
): FormulaTimeRange | undefined {
  try {
    const parseTree = getTimeRangeParseTree(timeRangeExpression);
    ParseTreeWalker.DEFAULT.walk(
      listener as Listener<ValueOrCalculationError | undefined>,
      parseTree as ParseTree,
    );
    return listener.getFormulaTimeRange();
  } catch (err) {
    return undefined;
  }
}

type FormulaDependencyGraphNode = Pick<Dependency, 'id' | 'type'> & {
  formula: string;
  valueType: ValueType;
  formulaMonthKey: MonthKey;
};

// When updating forecast formula, monthKey should be a future month key
// When updating actuals formula, monthKey should be a past month key
function throwOnDependencyLoop(props: FormulaErrorEvaluationProps) {
  const { driversById, evaluator, fieldSpecsById, formulaEntityId, isActualsFormula, rawFormula } =
    props;
  const lastActualsMonthKey = evaluator.getLastActualsMonthKey();
  // N.B. for forecast, check a date a year in the future to ensure adequate room to detect cycles
  const monthKey = isActualsFormula ? lastActualsMonthKey : nextYearMonthKey(lastActualsMonthKey);

  let depType: Dependency['type'] = 'driver';
  let depId = formulaEntityId.id;
  let valueType = ValueType.Number;
  // If not a formula, no need to check for cycles
  if (formulaEntityId.type === 'objectFieldSpec' || formulaEntityId.type === 'objectField') {
    depType = 'objectFieldSpecFormula';
    const fieldSpec = fieldSpecsById[formulaEntityId.id];
    if (fieldSpec == null || !fieldSpec.isFormula) {
      return;
    }
    depId = fieldSpec.id;
    valueType = fieldSpec.type;
  }

  const initialFormulaNode: FormulaDependencyGraphNode = {
    id: depId,
    type: depType,
    formula: rawFormula,
    formulaMonthKey: monthKey,
    valueType,
  };

  const circularDependentNode = containsCycle(initialFormulaNode, props);

  if (circularDependentNode != null) {
    // TODO: support non drivers
    let driverName: string | undefined;

    if (circularDependentNode?.type === 'driver') {
      driverName = driversById[circularDependentNode.id]?.name;
    }

    const errorMessage =
      driverName != null
        ? `Dependency loop with driver: ${driverName}`
        : `Dependency loop detected`;
    throw new ModelingError(errorMessage, -1, 1);
  }
}

function getCacheKey(node: FormulaDependencyGraphNode): string {
  return `${node.id}.${node.formulaMonthKey}`;
}

export function containsCycle(
  rootNode: FormulaDependencyGraphNode,
  props: FormulaErrorEvaluationProps,
): FormulaDependencyGraphNode | null {
  const stack = [rootNode];
  const nodesInCallStack = new Map<string, Set<string>>();
  const visited = new Set<string>();
  const dependencyListenerEvaluator = new DependencyListenerEvaluator(props);

  while (stack.length > 0) {
    const currNode = stack.pop()!;
    const cacheKey = getCacheKey(currNode);

    const nodesInCallStackForCurrentNode = nodesInCallStack.get(cacheKey) ?? new Set();

    // If we've seen this node in the call stack, there is a cycle
    if (nodesInCallStackForCurrentNode.has(cacheKey)) {
      return currNode;
    }

    // If we've visited this node before, assume it's already been checked
    if (visited.has(cacheKey)) {
      return null;
    }

    // Mark the current node as visited and part of recursion stack
    visited.add(cacheKey);
    const dependencies = getFormulaEntityDependencyNodes(
      dependencyListenerEvaluator,
      currNode,
      props,
    );
    for (let i = 0; i < dependencies.length; i++) {
      const child = dependencies[i];
      stack.push(child);

      const nodesInCallStackForChildren = new Set([
        ...nodesInCallStackForCurrentNode.values(),
        cacheKey,
      ]);
      nodesInCallStack.set(getCacheKey(child), nodesInCallStackForChildren);
    }

    nodesInCallStack.delete(cacheKey);
  }
  return null;
}

function getFormula(
  entityId: FormulaEntityTypedId,
  depMonthKey: string,
  { evaluator, formulaEntityId, isActualsFormula, rawFormula }: FormulaErrorEvaluationProps,
): string | undefined {
  // There are three cases in which we should use the newly input formula instead of the
  // old one provided by the evaluator.
  // The new formula is:
  //  1. An actuals formula and this monthKey is an actuals
  //  2. A forecast formula and this monthKey is a forecast
  //  3. A forecast formula and this monthKey is an actuals AND there is no old actuals formula.
  // In the third case, since the evaluator falls back to the forecast formula if there is no actuals formula,
  // we need to use the new forecast formula instead.
  const shouldUseNewFormula =
    isEqual(entityId, formulaEntityId) &&
    (evaluator.shouldUseActual(depMonthKey) === isActualsFormula ||
      (evaluator.shouldUseActual(depMonthKey) &&
        evaluator.getActualsFormulaForCalculations(formulaEntityId) == null));
  // Also use getFormula because formula could be different for forecasts or actuals
  return shouldUseNewFormula ? rawFormula : evaluator.getFormula(entityId, depMonthKey);
}

function getFormulaEntityDependencyNodes(
  dependencyListenerEvaluator: DependencyListenerEvaluator,
  node: FormulaDependencyGraphNode,
  props: FormulaErrorEvaluationProps,
): FormulaDependencyGraphNode[] {
  const { driversById, evaluator } = props;

  const listener = new DependencyListener({
    entityId: node.id,
    evaluator: dependencyListenerEvaluator,
    dimensionalPropertyEvaluator: evaluator.getDimPropertyEvaluator(),
  });

  const dependencies = evaluate(node.formula, listener, node.valueType).filter(
    isCalculableDependency,
  );

  const earliestAllowMonthKey = evaluator.getEarliestAllowMonthKey();
  const latestAllowMonthKey = evaluator.getLatestAllowMonthKey();

  return (
    dependencies
      // First, flatten any dependencies that represent more than one entity.
      // For now, this is just dimensional drivers.
      .flatMap<CalculableDependency | null>((dep) => {
        const { id, dateRange } = dep;

        if (dep.type !== 'driver' && dep.type !== 'extDriver') {
          return [dep];
        }

        const driverEntity = driversById[id];
        if (driverEntity == null || dateRange == null) {
          return null;
        }

        if (driverEntity.type === DriverType.Dimensional) {
          return driverEntity.subdrivers.map((subDriver) => {
            return { type: 'driver', id: subDriver.driverId, dateRange };
          });
        }

        return [dep];
      })
      .filter(isNotNull)
      // Add each monthKey as a separate node
      .flatMap((dep) => {
        const { id, dateRange } = dep;

        if (dateRange == null) {
          return [];
        }

        const resolvedMonthRange = resolveDependencyDateRange(dateRange, node.formulaMonthKey);
        const monthKeys = getMonthKeyRange(resolvedMonthRange.start, resolvedMonthRange.end);
        return monthKeys
          .map((depMonthKey) => {
            if (depMonthKey < earliestAllowMonthKey || depMonthKey > latestAllowMonthKey) {
              return null;
            }

            const formula =
              dep.type === 'objectFieldSpecFormula'
                ? dep.id === node.id
                  ? node.formula
                  : evaluator.getBusinessObjectFieldSpecById(dep.id)?.defaultForecast?.formula
                : getFormula({ type: 'driver', id }, depMonthKey, props);
            const depValueType =
              dep.type === 'objectFieldSpecFormula'
                ? evaluator.getBusinessObjectFieldSpecById(dep.id)?.type ?? ValueType.Number
                : ValueType.Number;

            if (formula == null) {
              return null;
            }

            const depNode: FormulaDependencyGraphNode = {
              ...pick(dep, 'id', 'type'),
              formula,
              formulaMonthKey: depMonthKey,
              valueType: depValueType,
            };

            return depNode;
          })
          .filter(isNotNull);
      })
  );
}
