import {
  Button,
  Divider,
  Flex,
  List,
  Menu,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Switch,
  Tab,
  TabList,
  Tabs,
  Text,
  useMultiStyleConfig,
  useTab,
} from '@chakra-ui/react';
import { DateTime } from 'luxon';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { Tooltip } from 'chakra/tooltip';
import { ORDERED_ROLLUP_TYPES, ROLLUP_LABELS } from 'components/AgGridComponents/AgChart/agCharts';
import { CheckListItem } from 'components/CheckItem/CheckItem';
import CustomizeBlockList from 'components/CustomizeBlock/CustomizeBlockList';
import DriverChartForecastComparisonSelection from 'components/CustomizeBlock/DriverChartForecastComparisonSelection';
import useCustomizeBlockDateRangeContext from 'components/CustomizeBlock/useCustomizeBlockDateRangeContext';
import { CustomizeDateRangeMenuContext } from 'components/CustomizeDateRangeMenu/CustomizeDateRangeMenuContext';
import DateRangeOptionRows from 'components/CustomizeOptionRows/DateRangeOptionRows';
import OptionRows, { OptionMenuRow } from 'components/CustomizeOptionRows/OptionRows';
import DriverAttributeBadges from 'components/DriverAttributeBadges/DriverAttributeBadges';
import { useDriverChartCardsDnD } from 'components/DriverChartCard/DriverChartCard';
import DragButton from 'components/DriverCharts/DragButton';
import MenuHeader from 'components/MenuHeader/MenuHeader';
import { MenuSection } from 'components/MenuSection/MenuSection';
import MonthPicker from 'components/MonthPicker/MonthPicker';
import Reorderable from 'components/Reorderable/Reorderable';
import { SelectItem } from 'components/SelectMenu/SelectMenu';
import SubmenuListItem from 'components/SubmenuListItem/SubmenuListItem';
import { DEFAULT_DRIVER_CHART_SIZE, DEFAULT_DRIVER_VIEW_AS_TYPE } from 'config/block';
import { MAX_DATE, MIN_DATE } from 'config/datetime';
import {
  BlockViewAsType,
  ChartGroupingType,
  ChartSize,
  DateRangeComparisonType,
} from 'generated/graphql';
import { stopEventPropagation } from 'helpers/browserEvent';
import {
  getCurrentMonthKey,
  getFinancialQuarter,
  getFinancialYear,
  getMonthKey,
  previousMonthKey,
} from 'helpers/dates';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import useCloseCustomizeMenu from 'hooks/useCloseCustomizeMenu';
import useControlledPopover from 'hooks/useControlledPopover';
import useHover from 'hooks/useHover';
import { updateBlockConfig, updateBlockViewOptions } from 'reduxStore/actions/blockMutations';
import { trackChartEdit } from 'reduxStore/actions/trackEvent';
import { DriverId } from 'reduxStore/models/drivers';
import {
  isBarChart,
  isDriverValueChart,
  isLineChart,
  isMultiDriverChart,
} from 'reduxStore/reducers/helpers/viewOptions';
import {
  UsableChartGroupingType,
  blockConfigChartGroupingTypeSelector,
  blockConfigViewOptionsSelector,
} from 'selectors/blocksSelector';
import { attributesForSubDriverIdSelector, driverNameSelector } from 'selectors/driversSelector';
import { lastActualsMonthKeyForLayerSelector } from 'selectors/lastActualsSelector';
import { comparisonLayerIdsForBlockSelector } from 'selectors/scenarioComparisonSelector';
import BarChartIcon from 'vectors/BarChart';
import CurrentValueIcon from 'vectors/CurrentValueChart';
import Help from 'vectors/Help';
import LineChartIcon from 'vectors/LineChart';

import { DriversList } from './DriversList';

const VIEW_AS_OPTION_TO_TEXT = {
  [BlockViewAsType.Chart]: 'Line',
  [BlockViewAsType.LineChart]: 'Line',
  [BlockViewAsType.CurrentValue]: 'Value',
  [BlockViewAsType.BarChart]: 'Bar',
  [BlockViewAsType.ComboChart]: 'Combo',
  [BlockViewAsType.AreaChart]: 'Area',
  [BlockViewAsType.PieChart]: 'Pie',
  [BlockViewAsType.DonutChart]: 'Donut',
  [BlockViewAsType.NightingaleChart]: 'Nightingale',
  [BlockViewAsType.TreemapChart]: 'Treemap',
};

const VIEW_AS_OPTION_TO_ICON = {
  [BlockViewAsType.Chart]: <LineChartIcon />,
  [BlockViewAsType.LineChart]: <LineChartIcon />,
  [BlockViewAsType.CurrentValue]: <CurrentValueIcon />,
  [BlockViewAsType.BarChart]: <BarChartIcon />,
  [BlockViewAsType.ComboChart]: <BarChartIcon />,
  [BlockViewAsType.AreaChart]: <LineChartIcon />,
  [BlockViewAsType.PieChart]: <LineChartIcon />,
  [BlockViewAsType.DonutChart]: <LineChartIcon />,
  [BlockViewAsType.NightingaleChart]: <LineChartIcon />,
  [BlockViewAsType.TreemapChart]: <LineChartIcon />,
};

const CHART_SIZE_OPTION_TO_TEXT = {
  [ChartSize.Medium]: 'Medium',
  [ChartSize.Large]: 'Large',
  [ChartSize.ExtraLarge]: 'Extra Large',
};

const ORDERED_GROUPING_TYPES: UsableChartGroupingType[] = [
  ChartGroupingType.Single,
  ChartGroupingType.Multi,
];

const CHART_GROUPING_OPTION_TO_TEXT: Record<UsableChartGroupingType, string> = {
  [ChartGroupingType.Single]: 'One',
  [ChartGroupingType.Multi]: 'Multiple',
};

const ORDERED_VIEW_AS = [
  BlockViewAsType.Chart,
  BlockViewAsType.BarChart,
  BlockViewAsType.CurrentValue,
];

const ORDERED_CHART_SIZES = [ChartSize.Medium, ChartSize.Large];

export const getDateRangeFromRollupPeriod = ({
  rollupType,
  lastCloseMonthKey,
  customMonth,
}: {
  rollupType: DateRangeComparisonType;
  lastCloseMonthKey: string;
  customMonth?: DateTime | undefined;
}) => {
  const currMonth = getCurrentMonthKey();
  if (rollupType === DateRangeComparisonType.CurrentMonth) {
    const currPeriod = { start: currMonth, end: currMonth };
    const prevMonth = previousMonthKey(currMonth);
    const prevPeriod = { start: prevMonth, end: prevMonth };
    return { currPeriod, prevPeriod };
  }
  if (rollupType === DateRangeComparisonType.CurrentQuarter) {
    const startQuarterMonth = getFinancialQuarter(0, currMonth, false);
    const endQuarterMonth = getFinancialQuarter(0, currMonth, true);

    const prevStartQuarterMonth = getFinancialQuarter(-1, currMonth, false);
    const prevEndQuarterMonth = getFinancialQuarter(-1, currMonth, true);

    const currPeriod = { start: startQuarterMonth, end: endQuarterMonth };
    const prevPeriod = { start: prevStartQuarterMonth, end: prevEndQuarterMonth };

    return { currPeriod, prevPeriod };
  }
  if (rollupType === DateRangeComparisonType.CurrentYear) {
    const startYearMonth = getFinancialYear(0, currMonth, false);
    const endYearMonth = getFinancialYear(0, currMonth, true);

    const prevStartYearMonth = getFinancialYear(-1, currMonth, false);
    const prevEndYearMonth = getFinancialYear(-1, currMonth, true);

    const currPeriod = { start: startYearMonth, end: endYearMonth };
    const prevPeriod = { start: prevStartYearMonth, end: prevEndYearMonth };

    return { currPeriod, prevPeriod };
  }
  if (rollupType === DateRangeComparisonType.LastMonth) {
    const prevMonth = previousMonthKey(currMonth);

    const prevPrevMonth = previousMonthKey(prevMonth);
    const currPeriod = { start: prevMonth, end: prevMonth };
    const prevPeriod = { start: prevPrevMonth, end: prevPrevMonth };
    return { currPeriod, prevPeriod };
  }
  if (rollupType === DateRangeComparisonType.LastClose) {
    const prevMonth = previousMonthKey(lastCloseMonthKey);
    const currPeriod = { start: lastCloseMonthKey, end: lastCloseMonthKey };
    const prevPeriod = { start: prevMonth, end: prevMonth };
    return { currPeriod, prevPeriod };
  }

  if (rollupType === DateRangeComparisonType.LastQuarter) {
    const startQuarterMonth = getFinancialQuarter(-1, currMonth, false);
    const endQuarterMonth = getFinancialQuarter(-1, currMonth, true);

    const prevStartQuarterMonth = getFinancialQuarter(-2, currMonth, false);
    const prevEndQuarterMonth = getFinancialQuarter(-2, currMonth, true);

    const currPeriod = { start: startQuarterMonth, end: endQuarterMonth };
    const prevPeriod = { start: prevStartQuarterMonth, end: prevEndQuarterMonth };
    return { currPeriod, prevPeriod };
  }
  if (rollupType === DateRangeComparisonType.LastYear) {
    const startYearMonth = getFinancialYear(-1, currMonth, false);
    const endYearMonth = getFinancialYear(-1, currMonth, true);

    const prevStartYearMonth = getFinancialYear(-2, currMonth, false);
    const prevEndYearMonth = getFinancialYear(-2, currMonth, true);

    const currPeriod = { start: startYearMonth, end: endYearMonth };
    const prevPeriod = { start: prevStartYearMonth, end: prevEndYearMonth };
    return { currPeriod, prevPeriod };
  }
  if (rollupType === DateRangeComparisonType.CustomMonth && customMonth != null) {
    const currMonthKey = getMonthKey(customMonth);
    const prevMonthKey = previousMonthKey(currMonthKey);
    const currPeriod = { start: currMonthKey, end: currMonthKey };
    const prevPeriod = { start: prevMonthKey, end: prevMonthKey };
    return { currPeriod, prevPeriod };
  }

  return {
    currPeriod: { start: null, end: null },
    prevPeriod: { start: null, end: null },
  };
};

interface Props {
  text: string;
  icon: ReactNode;
}

const SelectionTab = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { icon, text } = props;
  const tabProps = useTab({ ...props, ref });
  const isSelected = !!tabProps['aria-selected'];

  const styles = useMultiStyleConfig('Tabs', tabProps);

  return (
    <Tab
      width="5rem"
      height="3.5rem"
      borderWidth={isSelected ? 2 : 0}
      borderColor="selection.500"
      borderRadius="md"
      background={isSelected ? 'selection.100' : ''}
      color={isSelected ? 'selection.500' : 'gray.500'}
      pt={3}
      pb={2}
      __css={styles.tab}
      _hover={{ color: 'selection.500' }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      {...tabProps}
    >
      {icon}
      <Text fontSize="xxs" fontWeight="semibold" marginTop="0.25rem !important" whiteSpace="nowrap">
        {text}
      </Text>
    </Tab>
  );
});

const ViewAsOption: React.FC = () => {
  const { blockId } = useBlockContext();
  const dispatch = useAppDispatch();
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));
  const currentViewAsType = viewOptions?.viewAsType ?? DEFAULT_DRIVER_VIEW_AS_TYPE;

  const onSelect = useCallback(
    ({ viewAsType }: { viewAsType: BlockViewAsType }) => {
      dispatch(
        updateBlockViewOptions({
          blockId,
          blockViewOptions: {
            viewAsType,
          },
        }),
      );

      dispatch(
        trackChartEdit({
          chartType: viewAsType,
        }),
      );
    },
    [blockId, dispatch],
  );

  return (
    <Tabs
      align="center"
      py={1}
      variant="unstyled"
      onChange={(index) => onSelect({ viewAsType: ORDERED_VIEW_AS[index] })}
      index={ORDERED_VIEW_AS.indexOf(currentViewAsType)}
    >
      <TabList justifyContent="space-between">
        {ORDERED_VIEW_AS.map((viewAsType) => (
          <SelectionTab
            key={VIEW_AS_OPTION_TO_TEXT[viewAsType]}
            icon={VIEW_AS_OPTION_TO_ICON[viewAsType]}
            text={VIEW_AS_OPTION_TO_TEXT[viewAsType]}
          />
        ))}
      </TabList>
    </Tabs>
  );
};

type RollupItemOption = {
  id: DateRangeComparisonType;
  name: string;
  isSelected: boolean;
  hasNextMenu: boolean;
};

const ChartRollupOption: React.FC = () => {
  const { blockId } = useBlockContext();
  const dispatch = useAppDispatch();
  const blockViewOptions = useAppSelector((state) =>
    blockConfigViewOptionsSelector(state, blockId),
  );

  const currentRollupType =
    blockViewOptions?.dateRangeComparison?.type ?? DateRangeComparisonType.CurrentMonth;
  const currentSelectedMonthString =
    blockViewOptions?.dateRangeComparison?.selectedPeriod?.start ?? undefined;

  const currentSelectedMonthDateTime =
    currentRollupType === DateRangeComparisonType.CustomMonth && currentSelectedMonthString != null
      ? DateTime.fromISO(currentSelectedMonthString)
      : undefined;

  const [currentMenu, setCurrentMenu] = useState<string>('root');
  const [selectedMonth, setSelectedMonth] = useState<DateTime | undefined>(
    currentSelectedMonthDateTime,
  );
  const lastCloseMonthKey = useAppSelector(lastActualsMonthKeyForLayerSelector);

  const onSelectCustomMonth = (month: DateTime) => {
    setSelectedMonth(month);
    const { currPeriod, prevPeriod } = getDateRangeFromRollupPeriod({
      rollupType: DateRangeComparisonType.CustomMonth,
      lastCloseMonthKey,
      customMonth: month,
    });

    dispatch(
      updateBlockConfig({
        blockId,
        fn: (blockConfig) => {
          blockConfig.dateRange = currPeriod;
          blockConfig.blockViewOptions = {
            ...blockConfig.blockViewOptions,
            dateRangeComparison: {
              type: DateRangeComparisonType.CustomMonth,
              selectedPeriod: currPeriod,
              comparisonPeriod: prevPeriod,
            },
          };
        },
      }),
    );
  };
  const { isOpen, onOpen, onClose, triggerRef, contentRef } = useControlledPopover();

  const rollupLabel = useMemo(() => {
    if (currentRollupType === DateRangeComparisonType.CustomMonth && selectedMonth != null) {
      return selectedMonth?.toFormat('MMMM yyyy') ?? '';
    }
    return ROLLUP_LABELS[currentRollupType];
  }, [currentRollupType, selectedMonth]);

  const items: RollupItemOption[] = useMemo(
    () =>
      ORDERED_ROLLUP_TYPES.filter(
        (rollupType) => rollupType !== DateRangeComparisonType.CustomMonth,
      ).map((rollupType) => ({
        id: rollupType,
        name: ROLLUP_LABELS[rollupType],
        isSelected: rollupType === currentRollupType,
        hasNextMenu: rollupType === DateRangeComparisonType.CustomMonth,
      })),
    [currentRollupType],
  );

  const customMonthItem: RollupItemOption = {
    id: DateRangeComparisonType.CustomMonth,
    name: 'Custom Month',
    isSelected: currentRollupType === DateRangeComparisonType.CustomMonth,
    hasNextMenu: true,
  };

  const onSelect = useCallback(
    (item: RollupItemOption) => {
      const { id: itemRollupType } = item;
      if (itemRollupType === DateRangeComparisonType.CustomMonth) {
        setCurrentMenu('monthPicker');
        return;
      }
      const { currPeriod, prevPeriod } = getDateRangeFromRollupPeriod({
        rollupType: itemRollupType,
        lastCloseMonthKey,
      });
      setSelectedMonth(undefined);
      dispatch(
        updateBlockConfig({
          blockId,
          fn: (blockConfig) => {
            blockConfig.dateRange = currPeriod;
            blockConfig.blockViewOptions = {
              ...blockConfig.blockViewOptions,
              dateRangeComparison: {
                type: itemRollupType,
                selectedPeriod: currPeriod,
                comparisonPeriod: prevPeriod,
              },
            };
          },
        }),
      );
    },
    [blockId, dispatch, lastCloseMonthKey],
  );

  return (
    <Flex display="flex" flexGrow={1} alignItems="center" justifyContent="space-between">
      <Text>Rollup</Text>
      <Popover
        isLazy
        isOpen={isOpen}
        onOpen={onOpen}
        placement="bottom-start"
        closeOnBlur={false}
        returnFocusOnClose={false}
        onClose={onClose}
        autoFocus={false}
      >
        <PopoverTrigger>
          <Button height="1.75rem" px={2}>
            <Flex
              flexDirection="row"
              alignItems="center"
              alignContent="center"
              justifyContent="space-between"
              minWidth="0"
              flexGrow={1}
              ref={triggerRef}
              fontSize="xs"
            >
              {rollupLabel}
            </Flex>
          </Button>
        </PopoverTrigger>
        <PopoverContent onClick={stopEventPropagation} ref={contentRef} p={1}>
          <Menu variant="light" isLazy closeOnSelect>
            {({ onClose: onCloseMenu }) => (
              <CustomizeBlockList>
                {currentMenu === 'root' && (
                  <>
                    <MenuHeader title="Rollup" onClose={onCloseMenu} />
                    {items.map((item) => (
                      <CheckListItem
                        key={item.id}
                        name={item.name}
                        isSelected={item.isSelected}
                        onClick={() => onSelect(item)}
                        showSubmenuArrow={item.hasNextMenu}
                      />
                    ))}
                    <Divider />
                    <SubmenuListItem
                      name={customMonthItem.name}
                      isSelected={customMonthItem.isSelected}
                      submenuLabel={selectedMonth?.toFormat('MMMM yyyy') ?? ''}
                      onClick={() => onSelect(customMonthItem)}
                    />
                  </>
                )}
                {currentMenu === 'monthPicker' && (
                  <>
                    <MenuHeader title="Select a Month" onBack={() => setCurrentMenu('root')} />
                    <MonthPicker
                      minDate={MIN_DATE}
                      maxDate={MAX_DATE}
                      selected={selectedMonth}
                      onDateSelect={onSelectCustomMonth}
                    />
                  </>
                )}
              </CustomizeBlockList>
            )}
          </Menu>
        </PopoverContent>
      </Popover>
    </Flex>
  );
};

const ChartGroupingOption: React.FC = () => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const currentChartGroupingType = useAppSelector((state) =>
    blockConfigChartGroupingTypeSelector(state, blockId),
  );

  const items: Array<SelectItem & { chartGroupingType: UsableChartGroupingType }> = useMemo(
    () =>
      ORDERED_GROUPING_TYPES.map((chartGroupingType) => ({
        chartGroupingType,
        id: chartGroupingType,
        name: CHART_GROUPING_OPTION_TO_TEXT[chartGroupingType],
        isChecked: chartGroupingType === currentChartGroupingType,
      })),
    [currentChartGroupingType],
  );

  const onSelect = useCallback(
    ({ chartGroupingType }: { chartGroupingType: UsableChartGroupingType }) => {
      dispatch(
        updateBlockViewOptions({
          blockId,
          blockViewOptions: {
            chartGroupingType,
          },
        }),
      );

      dispatch(
        trackChartEdit({
          chartDisplay: chartGroupingType,
        }),
      );
    },
    [blockId, dispatch],
  );

  return (
    <OptionMenuRow
      currentValue={CHART_GROUPING_OPTION_TO_TEXT[currentChartGroupingType]}
      title="Drivers per chart"
      items={items}
      onSelectItem={onSelect}
    />
  );
};

const ChartSizeOption: React.FC = () => {
  const { blockId } = useBlockContext();
  const dispatch = useAppDispatch();
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));
  const currentChartSize = viewOptions?.chartSize ?? DEFAULT_DRIVER_CHART_SIZE;
  const items: Array<SelectItem & { chartSize: ChartSize }> = useMemo(
    () =>
      ORDERED_CHART_SIZES.map((chartSize) => ({
        chartSize,
        id: chartSize,
        name: CHART_SIZE_OPTION_TO_TEXT[chartSize],
        isChecked: chartSize === currentChartSize,
      })),
    [currentChartSize],
  );
  const onSelect = useCallback(
    ({ chartSize }: { chartSize: ChartSize }) => {
      dispatch(updateBlockViewOptions({ blockId, blockViewOptions: { chartSize } }));
    },
    [blockId, dispatch],
  );
  return (
    <OptionMenuRow
      currentValue={CHART_SIZE_OPTION_TO_TEXT[currentChartSize]}
      title="Chart size"
      items={items}
      onSelectItem={onSelect}
    />
  );
};

const ChartAggregateOption: React.FC = () => {
  const { blockId } = useBlockContext();
  const dispatch = useAppDispatch();
  const [hoverRef, isHovered] = useHover<SVGSVGElement>();
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));

  let currentAggregateValue: boolean;

  if (viewOptions == null ?? viewOptions.aggregateValues == null) {
    currentAggregateValue = false;
  } else {
    currentAggregateValue = viewOptions.aggregateValues;
  }

  const onSelect = useCallback(() => {
    dispatch(
      updateBlockViewOptions({
        blockId,
        blockViewOptions: { aggregateValues: !currentAggregateValue },
      }),
    );
  }, [blockId, currentAggregateValue, dispatch]);

  return isBarChart(viewOptions) ? null : (
    <Flex alignItems="center" justifyContent="space-between" pt={1}>
      <Flex direction="row">
        <Text>Stack chart</Text>
        <Tooltip label="Display drivers as an aggregated, stacked graph" isOpen={isHovered}>
          <Help color="gray.500" ml={1} ref={hoverRef} lineHeight={3} my="auto" />
        </Tooltip>
      </Flex>
      <Switch
        isDisabled={isBarChart(viewOptions)}
        isChecked={currentAggregateValue}
        onChange={() => dispatch(onSelect)}
      />
    </Flex>
  );
};

const DriverItem: React.FC<{
  driverId: DriverId;
  isLast: boolean;
  onOpenDetailPane?: () => void;
}> = ({ driverId, isLast, onOpenDetailPane }) => {
  const attributes = useAppSelector((state) => attributesForSubDriverIdSelector(state, driverId));
  const name = useAppSelector((state) => driverNameSelector(state, { id: driverId }));
  const { onDrop, dragItem, itemType } = useDriverChartCardsDnD(driverId);

  return (
    <Reorderable item={dragItem} itemType={itemType} isLast={isLast} onDrop={onDrop}>
      <Flex flexDir="row" alignItems="center" py={1} columnGap={1} overflowY="auto">
        <DragButton
          driverId={driverId}
          showOnGroupHover={false}
          onOpenDetailPane={onOpenDetailPane}
          canDrag
        />
        <Text noOfLines={1}>{name}</Text>
        <DriverAttributeBadges attributes={attributes} driverId={driverId} />
      </Flex>
    </Reorderable>
  );
};

type CurrentMenu = 'root' | 'comparisons';

const CustomizeDriverChartsBlock: React.FC = () => {
  const { blockId } = useBlockContext();
  const onClose = useCloseCustomizeMenu();
  const dispatch = useAppDispatch();

  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));

  const shouldShowChartSizeOptions =
    !isDriverValueChart(viewOptions) && !isMultiDriverChart(viewOptions);
  const shouldShowGroupingOptions = isLineChart(viewOptions) || isBarChart(viewOptions);
  const shouldShowAggregateOption = isMultiDriverChart(viewOptions);
  const shouldShowRollupOptions = isDriverValueChart(viewOptions);

  const currentChartGroupingType = useAppSelector((state) =>
    blockConfigChartGroupingTypeSelector(state, blockId),
  );
  const shouldShowForecastComparison =
    isLineChart(viewOptions) && currentChartGroupingType === ChartGroupingType.Single;
  const shouldShowDateRangeOption = !isDriverValueChart(viewOptions);
  const dateRangeMenuContext = useCustomizeBlockDateRangeContext(blockId);
  const [currentMenu, setCurrentMenu] = useState<CurrentMenu>('root');
  const comparisonLayerIds = useAppSelector((state) =>
    comparisonLayerIdsForBlockSelector(state, blockId),
  );

  const clearAllChartComparisons = useCallback(() => {
    dispatch(
      updateBlockConfig({
        blockId,
        fn: (blockConfig) => {
          if (blockConfig.comparisons != null) {
            blockConfig.comparisons.layerIds = [];
          }
        },
      }),
    );
  }, [blockId, dispatch]);

  useEffect(() => {
    if (!shouldShowForecastComparison) {
      clearAllChartComparisons();
    }
  }, [clearAllChartComparisons, shouldShowForecastComparison]);

  return (
    <CustomizeBlockList maxHeight="30rem" overflow="scrollY">
      {currentMenu === 'root' && (
        <>
          <MenuHeader title="Customize charts" onClose={onClose} showDivider={false} />
          <Flex direction="column" px={2}>
            <MenuSection title="Chart Options">
              <OptionRows>
                <ViewAsOption />
                {shouldShowGroupingOptions && <ChartGroupingOption />}
                {shouldShowChartSizeOptions && <ChartSizeOption />}
                {shouldShowAggregateOption && <ChartAggregateOption />}
              </OptionRows>
              {shouldShowRollupOptions && <ChartRollupOption />}
              {shouldShowForecastComparison && (
                <List mx={-3} mt={-1}>
                  <SubmenuListItem
                    name="Compare"
                    submenuLabel={
                      comparisonLayerIds.length === 0
                        ? 'None'
                        : `${comparisonLayerIds.length - 1} visible`
                    }
                    onClick={() => setCurrentMenu('comparisons')}
                  />
                </List>
              )}
            </MenuSection>
            {shouldShowDateRangeOption && (
              <MenuSection title="date range">
                <CustomizeDateRangeMenuContext.Provider value={dateRangeMenuContext}>
                  <DateRangeOptionRows />
                </CustomizeDateRangeMenuContext.Provider>
              </MenuSection>
            )}
            <DriversList onOpenDetailPane={onClose} Item={DriverItem} />
          </Flex>
        </>
      )}
      {currentMenu === 'comparisons' && (
        <>
          <MenuHeader
            title="Compare"
            onClose={onClose}
            onBack={() => setCurrentMenu('root')}
            showDivider={false}
          />
          <DriverChartForecastComparisonSelection />
        </>
      )}
    </CustomizeBlockList>
  );
};

export default CustomizeDriverChartsBlock;
