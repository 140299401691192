import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import pluralize from 'pluralize';
import React, { useMemo } from 'react';

import { ColorLocation, formatColorForUsage } from 'helpers/color';
import { getDateTimeFromMonthKey, shortMonthFormat } from 'helpers/dates';
import { Coloring } from 'reduxStore/models/common';
import { ValueTimeSeries } from 'reduxStore/models/timeSeries';

interface Props {
  coloring: Coloring;
}
const ColoringInfo: React.FC<Props> = ({ coloring }) => {
  const hasMonthOverrides = Object.values(coloring.cells ?? {}).length > 0;
  if (coloring.row == null && !hasMonthOverrides) {
    return (
      <Text fontSize="xs" fontWeight="medium">
        None
      </Text>
    );
  }

  return (
    <Flex flexDirection="row" gap={2} fontSize="xs" fontWeight="medium">
      {coloring.row != null && <ColorInfo label="Row color" color={coloring.row} />}
      {coloring.row != null && hasMonthOverrides && <span>+</span>}
      {coloring.cells != null && <CellColors cells={coloring.cells} />}
    </Flex>
  );
};

const ColorInfo = ({ color, label }: { color: string; label: string }) => {
  const displayColor = formatColorForUsage(color, ColorLocation.Accessory);
  return (
    <Flex gap={1} fontSize="xs">
      <Box
        bgColor={displayColor}
        borderRadius={100}
        w={3}
        h={3}
        borderWidth="1px"
        borderStyle="solid"
        borderColor={displayColor}
        alignSelf="center"
      />
      <Text>{label}</Text>
    </Flex>
  );
};

const CellColors = ({ cells }: { cells: ValueTimeSeries }) => {
  const numMonthOverrides = Object.keys(cells).length;

  const toolTipContent = useMemo(() => {
    return (
      <Flex flexDirection="column" gap={1} fontSize="xs">
        {Object.entries(cells).map(([monthKey, color]) => {
          const key = `${monthKey}.${color.value}`;
          const formattedDate = shortMonthFormat(getDateTimeFromMonthKey(monthKey));
          return <ColorInfo key={key} label={formattedDate} color={color.value.toString()} />;
        })}
      </Flex>
    );
  }, [cells]);
  return (
    <Box>
      <Tooltip label={toolTipContent}>
        <Text
          fontSize="xs"
          cursor="pointer"
          _hover={{ color: 'blue.500' }}
        >{`${numMonthOverrides} month ${pluralize('color', numMonthOverrides)}`}</Text>
      </Tooltip>
    </Box>
  );
};

export default React.memo(ColoringInfo);
