import { createSelector } from 'reselect';

import { LiveEditType } from 'reduxStore/reducers/liveEditSlice';
import { accessCapabilitiesSelector } from 'selectors/accessCapabilitiesSelector';
import { calculationEngineSelector } from 'selectors/calculationEngineSelector';
import { isDetailPaneOpenSelector } from 'selectors/detailPaneSelectors';
import { liveEditTypeSelector } from 'selectors/liveEditSelector';
import { hasSelectedEventsSelector } from 'selectors/selectedEventSelector';
import { pageSelectionSelector } from 'selectors/selectionSelector';
import { Selector } from 'types/redux';

const isSingleImpactSelectedSelector = createSelector(
  pageSelectionSelector,
  (pageSelection) =>
    pageSelection?.type === 'eventsAndGroups' &&
    pageSelection.refs.length === 1 &&
    (pageSelection.refs[0].type === 'event' || pageSelection.refs[0].type === 'entity'),
);

export const showInspectorSelector: Selector<boolean> = createSelector(
  isSingleImpactSelectedSelector,
  isDetailPaneOpenSelector,
  accessCapabilitiesSelector,
  (isSingleImpactSelected, isDetailPaneOpen, { isOrgMember }) => {
    return isSingleImpactSelected && !isDetailPaneOpen && isOrgMember;
  },
);

export const shouldDoSynchronousCalculationsSelector: Selector<boolean> = createSelector(
  showInspectorSelector,
  liveEditTypeSelector,
  hasSelectedEventsSelector,
  calculationEngineSelector,
  (showInspector, liveEditType, hasSelectedEvents, calculationEngine) => {
    const isLiveEditing =
      liveEditType === LiveEditType.Event || liveEditType === LiveEditType.MultiEvent;
    if (calculationEngine === 'backend-only') {
      return false;
    }
    return !self.isMainThread || showInspector || isLiveEditing || hasSelectedEvents;
  },
);
