import ImpactBadge from 'components/ImpactBadge/ImpactBadge';
import { ValueType } from 'generated/graphql';
import { getTotalDeltaImpact, getTotalImpactDisplay } from 'helpers/events';
import useAppSelector from 'hooks/useAppSelector';
import { DriverEvent, isDeltaImpactEvent } from 'reduxStore/models/events';
import { getLastValueInTimeSeries } from 'reduxStore/models/timeSeries';
import { driverDisplayConfigurationSelector } from 'selectors/entityDisplayConfigurationSelector';

interface Props {
  event: DriverEvent;
}

const TotalDriverImpactBadge: React.FC<Props> = ({ event }) => {
  const { driverId } = event;
  const displayConfiguration = useAppSelector((state) =>
    driverDisplayConfigurationSelector(state, driverId),
  );

  const totalImpact = isDeltaImpactEvent(event)
    ? getTotalDeltaImpact(event)
    : getLastValueInTimeSeries(event.customCurvePoints) ?? {
        type: ValueType.Number,
        value: 0,
      };
  const { fullDisplayString, abbreviatedDisplayString } = getTotalImpactDisplay(
    totalImpact,
    displayConfiguration,
    {},
    event.impactType,
  );

  return (
    <ImpactBadge
      fullDisplayString={fullDisplayString}
      abbreviatedString={abbreviatedDisplayString}
    />
  );
};

export default TotalDriverImpactBadge;
