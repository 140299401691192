import { createSelector } from '@reduxjs/toolkit';

import type { LaunchDarklyFlags } from 'reduxStore/reducers/launchDarklySlice';
import { Selector } from 'types/redux';

export const launchDarklySelector: Selector<LaunchDarklyFlags> = (state) =>
  state.dataset.launchDarklyFlags.flags;

export const isLaunchDarklyInitializedSelector: Selector<boolean> = (state) =>
  state.dataset.launchDarklyFlags.isInitialized;

export const isLaunchDarklyEnabledSelector: Selector<boolean> = (state) =>
  state.dataset.launchDarklyFlags.isEnabled;

export const earliestFormulaEvaluationMonthKeySelector = createSelector(
  launchDarklySelector,
  (ldFlags) => ldFlags.earliestFormulaEvaluationMonthKey ?? '2018-01',
);

export const disableAGGridCSVExportSelector = createSelector(launchDarklySelector, (ldFlags) => {
  return ldFlags.disableAgGridCsvExport ?? true;
});

export const enableBranchingFromLayersSelector = createSelector(
  launchDarklySelector,
  (ldFlags) => ldFlags.enableBranchingFromLayers ?? false,
);

export const enableDatabaseDriverAggregationsSelector = createSelector(
  launchDarklySelector,
  (ldFlags) => ldFlags.enableDatabaseDriverAggregations ?? false,
);
export const enableAgChartsSelector = createSelector(
  launchDarklySelector,
  (ldFlags) => ldFlags.enableAgCharts ?? false,
);

export const enableAgGridDriverTablesSelector = createSelector(
  launchDarklySelector,
  (ldFlags) => ldFlags.enableAgGridDriverTables ?? false,
);

export const enableDatabaseShapingSelector = createSelector(
  launchDarklySelector,
  (ldFlags) => ldFlags.enableDatabaseShaping ?? false,
);

export const enableBlockLayoutsSelector = createSelector(
  launchDarklySelector,
  (ldFlags) => ldFlags.enableBlockLayouts ?? false,
);

export const enableNewMergeScreenSelector = createSelector(
  launchDarklySelector,
  (ldFlags) => ldFlags.enableNewMergeScreen ?? false,
);

export const enableDefaultFormulaSelector = createSelector(
  launchDarklySelector,
  (ldFlags) => ldFlags.enableDefaultFormula ?? false,
);

export const enableEagerSubDriverInitializationSelector = createSelector(
  launchDarklySelector,
  (ldFlags) => ldFlags.enableEagerSubDriverInitialization,
);

export const enableTreemapChartSelector = createSelector(
  launchDarklySelector,
  (ldFlags) => ldFlags.enableTreemapCharts ?? false,
);

export const enableVideoUploadSelector = createSelector(
  launchDarklySelector,
  (ldFlags) => ldFlags.enableVideoUpload ?? false,
);

export const enableImageBlockSelector = createSelector(
  launchDarklySelector,
  (ldFlags) => ldFlags.enableImageBlock ?? false,
);

export const enableTimePeriodComparisonsSelector = createSelector(
  launchDarklySelector,
  (ldFlags) => ldFlags.enableTimePeriodComparisons ?? false,
);

export const enableDriverThisSegmentSelector = createSelector(
  launchDarklySelector,
  (ldFlags) => ldFlags.enableDriverThisSegment ?? false,
);

export const enableStackedImpactsSelector = createSelector(
  launchDarklySelector,
  (ldFlags) => ldFlags.enableStackedImpacts ?? false,
);

export const enableBvAInDatabasesSelector = createSelector(
  launchDarklySelector,
  (ldFlags) => ldFlags.enableBvaInDatabases ?? false,
);
