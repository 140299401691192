import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'WarnIcon',
  viewBox: '0 0 16 16',
  defaultProps: {
    fill: 'none',
  },
  d: 'M14.7217 7.32773L8.67198 1.27803C8.30097 0.90702 7.69896 0.90702 7.32742 1.27803L1.27825 7.32773C0.907249 7.69873 0.907249 8.30074 1.27825 8.67229L7.32796 14.722C7.69896 15.093 8.30097 15.093 8.67251 14.722L14.7222 8.67229C15.0927 8.30074 15.0927 7.69873 14.7217 7.32773ZM8.65259 4.23045L8.5449 9.07668H7.45504L7.34734 4.23045H8.65259ZM8.00158 11.8708C7.55573 11.8708 7.28919 11.6333 7.28919 11.2343C7.28919 10.8278 7.55519 10.5903 8.00158 10.5903C8.4442 10.5903 8.71021 10.8278 8.71021 11.2343C8.71021 11.6333 8.4442 11.8708 8.00158 11.8708Z',
});
