import { Flex, Text } from '@chakra-ui/react';
import React from 'react';

import { isThisMonth } from 'helpers/formula';
import { FormulaTimeRange } from 'types/formula';

interface Props {
  icon?: JSX.Element;
  label: string;
  timeRange: FormulaTimeRange | undefined;
  timeRangeDisplay: string | undefined;
  attributes?: React.ReactNode;
  placeholderBadge?: React.ReactNode;
  children?: React.ReactNode;
}

const FormulaEntityChunk: React.FC<Props> = ({
  icon,
  attributes,
  placeholderBadge,
  label,
  timeRange,
  timeRangeDisplay,
  children,
}) => {
  const hasChildren = React.Children.count(children) > 0 && Boolean(children);
  return (
    <Flex
      data-testid="formula-entity-chunk"
      alignItems="center"
      bgColor="white"
      borderColor="gray.300"
      borderRadius="xl"
      borderWidth="px"
      borderRightWidth={hasChildren ? 0 : 'px'}
      columnGap={1}
      display="inline-flex"
      fontSize="xxs"
      fontWeight="medium"
      height={6}
      pl={2}
      pr={hasChildren ? 0 : 2}
    >
      {icon}
      <Text noOfLines={1} wordBreak="break-all">
        {label}
      </Text>
      {attributes}
      {placeholderBadge}
      {(timeRange == null || !isThisMonth(timeRange)) && (
        <Text
          as="span"
          fontStyle="italic"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          {timeRangeDisplay}
        </Text>
      )}
      {children}
    </Flex>
  );
};

export default React.memo(FormulaEntityChunk);
