import { ComparisonColumn } from 'generated/graphql';

const REQUIRES_BASELINE_LAYER = [
  ComparisonColumn.BaselineVersion,
  ComparisonColumn.LatestVersion,
  ComparisonColumn.Variance,
  ComparisonColumn.VariancePercentage,
];

const REQUIRES_VARIANCE = [ComparisonColumn.Variance, ComparisonColumn.VariancePercentage];

export const getRequiresBaselineLayer = (
  comparisonColumn: ComparisonColumn | undefined,
): boolean => {
  return comparisonColumn != null && REQUIRES_BASELINE_LAYER.includes(comparisonColumn);
};

export const getRequiresVariance = (comparisonColumn: ComparisonColumn | undefined): boolean => {
  return comparisonColumn != null && REQUIRES_VARIANCE.includes(comparisonColumn);
};
