import { GridApi } from 'ag-grid-community';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';

import { AgGridDatabaseContext } from 'components/AgGridComponents/types/DatabaseColumnDef';
import {
  BusinessObjectFieldCellRef,
  BusinessObjectFieldMonthColumnKey,
  CellColumnKey,
  CellRef,
  CellRowKey,
  CellSelection,
  CellType,
  DriverCellNameRef,
} from 'config/cells';
import { RollupType } from 'generated/graphql';
import {
  isBusinessObjectPropertyFieldCellRef,
  isCellRefEqual,
  isColumnKeyEqual,
  isDriverCellRef,
  isDriverNameCellRef,
  isMonthColumnKey,
  isRowKeyEqual,
  isSqlCellRef,
  isStickyColumnKey,
} from 'helpers/cells';
import { monthKeyRange } from 'helpers/dates';
import { getContiguousKeys } from 'helpers/gridKeys';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import { updateBlockConfig } from 'reduxStore/actions/blockMutations';
import { selectPlanTimelineInitiative } from 'reduxStore/actions/blockSelection';
import { incrementDecrementObjectSpecDecimalPlaces } from 'reduxStore/actions/businessObjectSpecMutations';
import { navigateDetailPane } from 'reduxStore/actions/detailPaneNavigation';
import { incrementDecrementSelectedDriverDecimalPlaces } from 'reduxStore/actions/driverMutations';
import { duplicateSelectedBusinessObjects } from 'reduxStore/actions/duplicateSelectedBusinessObjects';
import { duplicateSelectedDrivers } from 'reduxStore/actions/duplicateSelectedDrivers';
import { duplicateGroup } from 'reduxStore/actions/eventMutations';
import {
  navigateToDriverDetailPane,
  navigateToObjectDetailPane,
  navigateToPlanDetailPane,
} from 'reduxStore/actions/navigateTo';
import { timelineCellNavigate } from 'reduxStore/actions/timelineCells';
import { BlockId } from 'reduxStore/models/blocks';
import {
  DEFAULT_EVENT_GROUP_ID,
  PlanTimelineItemRef,
  refFromPlanTimelineItem,
} from 'reduxStore/models/events';
import {
  openPlanPickerPopover,
  selectSingleCell,
  setSelectedCells,
} from 'reduxStore/reducers/pageSlice';
import { AppThunk } from 'reduxStore/store';
import { accessCapabilitiesSelector } from 'selectors/accessCapabilitiesSelector';
import { selectedBlockCellKeysSelector } from 'selectors/activeCellSelectionSelector';
import { blockConfigDriverIndentsSelector } from 'selectors/blocksSelector';
import { businessObjectSpecIdForObjectIdSelector } from 'selectors/businessObjectsSelector';
import { isNavigableDetailPaneOpen } from 'selectors/detailPaneNavigationSelector';
import { isDetailPaneOpenSelector } from 'selectors/detailPaneSelectors';
import { isGroupIdForCurrentSubmodelPageCollapsedSelector } from 'selectors/driverGroupSelector';
import { isEditableDriverForecastSelector } from 'selectors/eventsAndGroupsSelector';
import { hasExistingEntitySelectedSelector } from 'selectors/hasExistingEntitySelectedSelector';
import { isModalOpenSelector } from 'selectors/isModalOpenSelector';
import { isCurrentPageWritableSelector } from 'selectors/pageAccessResourcesSelector';
import { planTimelineRowsSelector } from 'selectors/planTimelineSelector';
import { isPopoverOpenSelector } from 'selectors/popoverStateSelector';
import {
  cellSelectionIncludesActualsSelector,
  cellSelectionMonthKeysSelector,
  driverCellSelectionSelector,
  objectFieldCellSelectionSelector,
  prevailingActiveCellDriverIdSelector,
  prevailingActiveCellObjectFieldId,
  prevailingActiveCellSelector,
  prevailingCellSelectionBlockIdSelector,
  prevailingCellSelectionSelector,
} from 'selectors/prevailingCellSelectionSelector';
import { activeInitiativeIdSelector } from 'selectors/selectedEventSelector';
import {
  activeEventGroupIdSelector,
  pageSelectionSelector,
  prevailingSelectionSelector,
  prevailingSelectionTypeSelector,
  selectedPlanTimelineItemRefSelector,
} from 'selectors/selectionSelector';

type Direction = 'left' | 'right' | 'up' | 'down';

const planTimelineRowChange =
  (
    direction: 'up' | 'down',
    blockId: BlockId,
    { range = false, skip = false }: { range?: boolean; skip?: boolean } = {},
  ): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const timelineRows = planTimelineRowsSelector(state, blockId);
    const activeInitiativeId = activeInitiativeIdSelector(state);
    const selection = selectedPlanTimelineItemRefSelector(state);
    if (activeInitiativeId == null || selection == null) {
      return;
    }

    const activeInitiativeIdx = timelineRows.findIndex((r) => r.id === activeInitiativeId);
    let nextIdx = activeInitiativeIdx;
    if (skip) {
      nextIdx = direction === 'up' ? 0 : timelineRows.length - 1;
    } else {
      if (direction === 'up' && activeInitiativeIdx > 0) {
        nextIdx -= 1;
      } else if (direction === 'down' && activeInitiativeIdx < timelineRows.length - 1) {
        nextIdx += 1;
      }

      if (nextIdx === activeInitiativeIdx) {
        return;
      }
    }

    if (skip || !range) {
      const nextPlanTimelineItem = timelineRows[nextIdx];
      const nextRef = refFromPlanTimelineItem(nextPlanTimelineItem);
      dispatch(selectPlanTimelineInitiative(nextRef, blockId, { range }));
      return;
    }

    // handle range selection without skipping
    const orderedInitiativeIds = timelineRows.map((r) => r.id);
    const selectedInitiativeIds = selection.refs.map((r) => r.id);
    const newSelectedRefs = getContiguousKeys(
      selectedInitiativeIds.map((key) => ({
        key,
        position: orderedInitiativeIds.indexOf(key),
      })),
      activeInitiativeId,
    );

    const aboveActive = newSelectedRefs.filter(
      (rk) => orderedInitiativeIds.indexOf(rk) < activeInitiativeIdx,
    );
    const belowActive = newSelectedRefs.filter(
      (rk) => orderedInitiativeIds.indexOf(rk) > activeInitiativeIdx,
    );

    let planTimelineRowToSelect: PlanTimelineItemRef | undefined;
    // We're either adding or removing rows
    let isAddingRows = false;

    if (direction === 'up') {
      if (belowActive.length > 0) {
        // remove from the bottom
        const bottomRef = selection.refs.find(
          (ref) => ref.id === belowActive[belowActive.length - 1],
        );
        planTimelineRowToSelect = bottomRef;
        isAddingRows = false;
      } else {
        // add to the top
        const topIndex =
          aboveActive.length > 0
            ? orderedInitiativeIds.indexOf(aboveActive[0])
            : activeInitiativeIdx;
        if (topIndex > 0) {
          const newTimelineRow = timelineRows.find(
            (ref) => ref.id === orderedInitiativeIds[topIndex - 1],
          );
          if (newTimelineRow) {
            planTimelineRowToSelect = refFromPlanTimelineItem(newTimelineRow);
            isAddingRows = true;
          }
        }
      }
    } else if (direction === 'down') {
      if (aboveActive.length > 0) {
        // remove from the top
        const topRef = selection.refs.find((ref) => ref.id === aboveActive[0]);
        planTimelineRowToSelect = topRef;
        isAddingRows = false;
      } else {
        // add to the bottom
        const bottomIndex =
          belowActive.length > 0
            ? orderedInitiativeIds.indexOf(belowActive[belowActive.length - 1])
            : activeInitiativeIdx;
        if (bottomIndex < orderedInitiativeIds.length - 1) {
          const newTimelineRow = timelineRows.find(
            (ref) => ref.id === orderedInitiativeIds[bottomIndex + 1],
          );
          if (newTimelineRow) {
            planTimelineRowToSelect = refFromPlanTimelineItem(newTimelineRow);
            isAddingRows = true;
          }
        }
      }
    }

    if (planTimelineRowToSelect) {
      dispatch(
        selectPlanTimelineInitiative(
          planTimelineRowToSelect,
          blockId,
          isAddingRows ? { range: true } : { toggle: true },
        ),
      );
    }
  };

const navigateSingleCell = (
  {
    orderedColumnKeys,
    filteredOrderedRowKeys,
    cellSelection,
    currRowKeyIdx,
    currColumnKeyIdx,
    skip,
    direction,
  }: {
    orderedColumnKeys: CellColumnKey[];
    filteredOrderedRowKeys: CellRowKey[];
    cellSelection: CellSelection;
    currRowKeyIdx: number;
    currColumnKeyIdx: number;
    skip: boolean;
    direction: Direction;
  },
  // eslint-disable-next-line max-params
): CellRef | undefined => {
  const { activeCell, selectedCells } = cellSelection;

  switch (direction) {
    case 'left': {
      if (currColumnKeyIdx <= 0) {
        return undefined;
      }

      let columnKey: CellColumnKey | undefined;

      if (skip) {
        const isDataSelected = selectedCells.every((ref) => isMonthColumnKey(ref.columnKey));
        columnKey = safeObjGet(
          isDataSelected ? orderedColumnKeys.find(isMonthColumnKey) : orderedColumnKeys[0],
        );
      } else {
        columnKey = safeObjGet(orderedColumnKeys[currColumnKeyIdx - 1]);
      }

      if (columnKey == null) {
        return undefined;
      }

      return {
        ...activeCell,
        columnKey,
      } as CellRef;
    }
    case 'right': {
      const lastColIdx = orderedColumnKeys.length - 1;
      if (currColumnKeyIdx >= lastColIdx) {
        return undefined;
      }

      const nextColIdx = skip ? lastColIdx : currColumnKeyIdx + 1;
      const columnKey = safeObjGet(orderedColumnKeys[nextColIdx]);
      if (columnKey == null) {
        return undefined;
      }

      return {
        ...activeCell,
        columnKey,
      } as CellRef;
    }
    case 'up': {
      if (currRowKeyIdx <= 0) {
        return undefined;
      }
      const prevRowIdx = skip ? 0 : currRowKeyIdx - 1;
      const rowKey = safeObjGet(filteredOrderedRowKeys[prevRowIdx]);
      if (rowKey == null) {
        return undefined;
      }
      return {
        ...activeCell,
        rowKey,
      } as CellRef;
    }
    case 'down': {
      const lastRowIdx = filteredOrderedRowKeys.length - 1;
      if (currRowKeyIdx >= lastRowIdx) {
        return undefined;
      }

      const nextRowIdx = skip ? lastRowIdx : currRowKeyIdx + 1;
      const rowKey = safeObjGet(filteredOrderedRowKeys[nextRowIdx]);
      if (rowKey == null) {
        return undefined;
      }
      return {
        ...activeCell,
        rowKey,
      } as CellRef;
    }
    default: {
      return undefined;
    }
  }
};

export const cellNavigate =
  (
    direction: Direction,
    { range = false, skip = false }: { range?: boolean; skip?: boolean } = {},
  ): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const prevailingCellSelection = prevailingCellSelectionSelector(state);
    const cellSelection = prevailingCellSelectionSelector(state);
    if (prevailingCellSelection == null || cellSelection == null) {
      return;
    }

    const { orderedRowKeys, orderedColumnKeys } = selectedBlockCellKeysSelector(state);

    // the filteredOrderedRowKeys pertain to the entire block, not just the group
    const filteredOrderedRowKeys = orderedRowKeys.filter((rowKey) => {
      if ('groupId' in rowKey && rowKey.groupId != null) {
        return !isGroupIdForCurrentSubmodelPageCollapsedSelector(state, rowKey.groupId);
      }

      return true;
    });

    const { activeCell, selectedCells } = cellSelection;

    //currRowKeyIdx is with respect to the block, not the group
    const currRowKeyIdx = filteredOrderedRowKeys.findIndex((rk) =>
      isRowKeyEqual(rk, activeCell.rowKey),
    );
    const currColumnKeyIdx = orderedColumnKeys.findIndex((ck) =>
      isColumnKeyEqual(ck, activeCell.columnKey),
    );

    const blockId = cellSelection.blockId;

    if (skip) {
      // filtering rows by groupId of the activeCell, so we need to find the index of the active cell within the filtered list
      const filteredOrderedRowKeysByGroupId = filteredOrderedRowKeys.filter((rowKey) => {
        if ('groupId' in rowKey && 'groupId' in activeCell.rowKey) {
          return rowKey.groupId === activeCell.rowKey.groupId;
        }
        return true;
      });
      const currRowKeyIdxWithinGroup = filteredOrderedRowKeysByGroupId.findIndex((rk) =>
        isRowKeyEqual(rk, activeCell.rowKey),
      );
      const cellRef = navigateSingleCell({
        orderedColumnKeys,
        filteredOrderedRowKeys: filteredOrderedRowKeysByGroupId,
        cellSelection,
        currRowKeyIdx: currRowKeyIdxWithinGroup,
        currColumnKeyIdx,
        skip,
        direction,
      });

      if (cellRef != null) {
        dispatch(selectCell(blockId, cellRef, { range }));
      }
      return;
    }

    const isSelectingSingleCell = !range;
    if (isSelectingSingleCell) {
      const cellRef = navigateSingleCell({
        orderedColumnKeys,
        filteredOrderedRowKeys,
        cellSelection,
        currRowKeyIdx,
        currColumnKeyIdx,
        skip,
        direction,
      });

      if (cellRef != null) {
        dispatch(selectCell(blockId, cellRef));
      }
      return;
    }

    const activeCellRowKey = activeCell.rowKey;
    const selectedRowKeys = uniq(selectedCells.map((c) => c.rowKey));
    const newSelectedRows = getContiguousKeys(
      selectedRowKeys.map((key) => ({
        key,
        position: filteredOrderedRowKeys.findIndex((rk) => isRowKeyEqual(rk, key)),
      })),
      activeCellRowKey,
    );

    const selectedMonthKeys = uniq(selectedCells.map((c) => c.columnKey));
    const activeCellMonthKey = activeCell.columnKey;
    const newSelectedMonths = getContiguousKeys(
      selectedMonthKeys.map((key) => ({
        key,
        position: orderedColumnKeys.findIndex((ck) => isColumnKeyEqual(ck, key)),
      })),
      activeCellMonthKey,
    );

    if (direction === 'up' || direction === 'down') {
      const aboveActive = newSelectedRows.filter(
        (rk) => filteredOrderedRowKeys.findIndex((k) => isRowKeyEqual(k, rk)) < currRowKeyIdx,
      );
      const belowActive = newSelectedRows.filter(
        (rk) => filteredOrderedRowKeys.findIndex((k) => isRowKeyEqual(k, rk)) > currRowKeyIdx,
      );
      if (direction === 'up') {
        if (belowActive.length > 0) {
          // remove from the bottom
          dispatch(
            setSelectedCells({
              ...cellSelection,
              selectedCells: selectedCells.filter(
                (ref) =>
                  [...aboveActive, ...belowActive.slice(0, -1), activeCellRowKey].includes(
                    ref.rowKey,
                  ) && newSelectedMonths.includes(ref.columnKey),
              ),
            }),
          );
        } else {
          // add to the top
          const topIndex =
            aboveActive.length > 0
              ? filteredOrderedRowKeys.findIndex((k) => isRowKeyEqual(k, aboveActive[0]))
              : currRowKeyIdx;
          if (topIndex > 0) {
            const newRowKey = filteredOrderedRowKeys[topIndex - 1];
            dispatch(
              setSelectedCells({
                ...cellSelection,
                selectedCells: [
                  ...newSelectedMonths.map(
                    (columnKey) =>
                      ({
                        ...activeCell,
                        rowKey: newRowKey,
                        columnKey,
                      }) as CellRef,
                  ),
                  ...selectedCells,
                ],
              }),
            );
          }
        }
      } else if (direction === 'down') {
        if (aboveActive.length > 0) {
          // remove from the top
          dispatch(
            setSelectedCells({
              ...cellSelection,
              selectedCells: selectedCells.filter(
                (ref) =>
                  [...belowActive, ...aboveActive.slice(1), activeCellRowKey].includes(
                    ref.rowKey,
                  ) && newSelectedMonths.includes(ref.columnKey),
              ),
            }),
          );
        } else {
          // add to the bottom
          const bottomIndex =
            belowActive.length > 0
              ? filteredOrderedRowKeys.findIndex((k) =>
                  isRowKeyEqual(k, belowActive[belowActive.length - 1]),
                )
              : currRowKeyIdx;
          if (bottomIndex < filteredOrderedRowKeys.length - 1) {
            const newRowKey = filteredOrderedRowKeys[bottomIndex + 1];
            dispatch(
              setSelectedCells({
                ...cellSelection,
                selectedCells: [
                  ...newSelectedMonths.map(
                    (columnKey) =>
                      ({
                        ...activeCell,
                        rowKey: newRowKey,
                        columnKey,
                      }) as CellRef,
                  ),
                  ...selectedCells,
                ],
              }),
            );
          }
        }
      }
    }

    if (direction === 'left' || direction === 'right') {
      const isDataSelected = selectedCells.every((ref) => isMonthColumnKey(ref.columnKey));
      if (!isDataSelected) {
        return;
      }

      const leftOfActive = newSelectedMonths.filter(
        (mk) => orderedColumnKeys.findIndex((ck) => isColumnKeyEqual(ck, mk)) < currColumnKeyIdx,
      );
      const rightOfActive = newSelectedMonths.filter(
        (mk) => orderedColumnKeys.findIndex((ck) => isColumnKeyEqual(ck, mk)) > currColumnKeyIdx,
      );
      if (direction === 'left') {
        if (rightOfActive.length > 0) {
          // remove from the right
          dispatch(
            setSelectedCells({
              ...cellSelection,
              selectedCells: selectedCells.filter(
                (ref) =>
                  [...leftOfActive, ...rightOfActive.slice(0, -1), activeCellMonthKey].includes(
                    ref.columnKey,
                  ) && newSelectedRows.includes(ref.rowKey),
              ),
            }),
          );
        } else {
          // add to the left
          const leftIndex =
            leftOfActive.length > 0
              ? orderedColumnKeys.findIndex((k) => isColumnKeyEqual(k, leftOfActive[0]))
              : currColumnKeyIdx;
          if (leftIndex > 0 && isMonthColumnKey(orderedColumnKeys[leftIndex - 1])) {
            const newColumnKey = orderedColumnKeys[leftIndex - 1];
            dispatch(
              setSelectedCells({
                ...cellSelection,
                selectedCells: [
                  ...newSelectedRows.map(
                    (rowKey) =>
                      ({
                        ...activeCell,
                        rowKey,
                        columnKey: newColumnKey,
                      }) as CellRef,
                  ),
                  ...selectedCells,
                ],
              }),
            );
          }
        }
      } else if (direction === 'right') {
        if (leftOfActive.length > 0) {
          // remove from the left
          dispatch(
            setSelectedCells({
              ...cellSelection,
              selectedCells: selectedCells.filter(
                (ref) =>
                  [...rightOfActive, ...leftOfActive.slice(1), activeCellMonthKey].includes(
                    ref.columnKey,
                  ) && newSelectedRows.includes(ref.rowKey),
              ),
            }),
          );
        } else {
          // add to the right
          const rightIndex =
            rightOfActive.length > 0
              ? orderedColumnKeys.findIndex((k) =>
                  isColumnKeyEqual(k, rightOfActive[rightOfActive.length - 1]),
                )
              : currColumnKeyIdx;
          if (rightIndex < orderedColumnKeys.length - 1) {
            const newColumnKey = orderedColumnKeys[rightIndex + 1];
            dispatch(
              setSelectedCells({
                ...cellSelection,
                selectedCells: [
                  ...newSelectedRows.map(
                    (rowKey) =>
                      ({
                        ...activeCell,
                        rowKey,
                        columnKey: newColumnKey,
                      }) as CellRef,
                  ),
                  ...selectedCells,
                ],
              }),
            );
          }
        }
      }
    }
  };

export const selectCell =
  (
    blockId: BlockId | null,
    cellRef: CellRef,
    {
      range = false,
      toggle = false,
      isDragging = false,
    }: { range?: boolean; toggle?: boolean; isDragging?: boolean } = {},
  ): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const selectionType = prevailingSelectionTypeSelector(state);
    const prevailingCellSelection = prevailingCellSelectionSelector(state);
    const cellSelection = prevailingCellSelectionSelector(state);
    const isPopoverOpen = isPopoverOpenSelector(state);
    const isSelectingSingleCell = !range && !toggle;
    const hasCurrentSelection = cellSelection != null;

    if (!hasCurrentSelection || isSelectingSingleCell) {
      if (
        prevailingCellSelection != null &&
        isPopoverOpen &&
        prevailingCellSelection.blockId === blockId &&
        prevailingCellSelection.selectedCells.some((ref) => isCellRefEqual(cellRef, ref))
      ) {
        // if the user selects a selected cell that's part of the block selection, just shift the
        // active cell selection
        dispatch(
          setSelectedCells({
            ...prevailingCellSelection,
            activeCell: cellRef,
            isDragging,
          }),
        );
      } else {
        dispatch(selectSingleCell({ blockId, cellRef }));
      }

      return;
    } else if (
      range &&
      prevailingCellSelection != null &&
      prevailingCellSelection.blockId !== blockId
    ) {
      // can't select a range across blocks
      return;
    }

    const { activeCell } = cellSelection;
    if (selectionType !== 'cell') {
      // can only select within a single row in the inspector
      if (
        !isRowKeyEqual(activeCell.rowKey, cellRef.rowKey) ||
        activeCell.type !== cellRef.type ||
        cellSelection.blockId !== blockId
      ) {
        dispatch(selectSingleCell({ blockId, cellRef }));
      } else if (
        range &&
        isMonthColumnKey(cellRef.columnKey) &&
        // we don't support multi-cell selection for sql result cells right now.
        !isSqlCellRef(activeCell) &&
        isMonthColumnKey(activeCell.columnKey)
      ) {
        const monthKeys = monthKeyRange(cellRef.columnKey.monthKey, activeCell.columnKey.monthKey);
        dispatch(
          setSelectedCells({
            ...cellSelection,
            selectedCells: monthKeys.map((monthKey) => {
              const cell = {
                ...activeCell,
                columnKey: { ...cellRef.columnKey, monthKey },
              };
              if (cell.type === CellType.ObjectField) {
                const objectCell: BusinessObjectFieldCellRef & {
                  columnKey: BusinessObjectFieldMonthColumnKey;
                } = {
                  ...cell,
                  columnKey: {
                    monthKey,
                    rollupType: RollupType.Month,
                    objectFieldSpecId:
                      activeCell.type === CellType.ObjectField &&
                      isMonthColumnKey(activeCell.columnKey)
                        ? activeCell.columnKey.objectFieldSpecId
                        : '',
                  },
                };
                return objectCell;
              }
              return cell as CellRef;
            }),
          }),
        );
      }
      return;
    }

    const { orderedRowKeys, orderedColumnKeys } = selectedBlockCellKeysSelector(state);
    if (orderedRowKeys == null || orderedColumnKeys == null) {
      return;
    }

    const isCellTypeMismatch =
      isMonthColumnKey(activeCell.columnKey) !== isMonthColumnKey(cellRef.columnKey) ||
      (isStickyColumnKey(activeCell.columnKey) &&
        isStickyColumnKey(cellRef.columnKey) &&
        (activeCell.columnKey.columnType === 'name') !== (cellRef.columnKey.columnType === 'name'));
    if (isCellTypeMismatch) {
      dispatch(selectSingleCell({ blockId, cellRef }));
      return;
    }

    const currRowKeyIdx = orderedRowKeys.findIndex((rk) => isRowKeyEqual(rk, activeCell.rowKey));
    const currColumnKeyIdx = orderedColumnKeys.findIndex((ck) =>
      isColumnKeyEqual(ck, activeCell.columnKey),
    );
    if (range) {
      const selectRowKeyIdx = orderedRowKeys.findIndex((rk) => isRowKeyEqual(rk, cellRef.rowKey));
      const selectedRowKeys = orderedRowKeys.slice(
        Math.min(currRowKeyIdx, selectRowKeyIdx),
        Math.max(currRowKeyIdx, selectRowKeyIdx) + 1,
      );

      const selectColumnKeyIdx = orderedColumnKeys.findIndex((ck) =>
        isColumnKeyEqual(ck, cellRef.columnKey),
      );
      const selectedColumnKeys = orderedColumnKeys.slice(
        Math.min(currColumnKeyIdx, selectColumnKeyIdx),
        Math.max(currColumnKeyIdx, selectColumnKeyIdx) + 1,
      );

      const selectedCells: CellRef[] = [];
      selectedRowKeys.forEach((rowKey) => {
        selectedColumnKeys.forEach((columnKey) => {
          selectedCells.push({ type: activeCell.type, rowKey, columnKey } as CellRef);
        });
      });

      dispatch(
        setSelectedCells({
          blockId,
          type: 'cell',
          activeCell,
          selectedCells,
          isDragging,
        }),
      );
    } else if (toggle) {
      const newSelectedCells = cellSelection.selectedCells.filter(
        (ref) => !isCellRefEqual(ref, cellRef),
      );
      const alreadySelected = newSelectedCells.length !== cellSelection.selectedCells.length;
      if (!alreadySelected) {
        // append to the selection
        dispatch(
          setSelectedCells({
            blockId,
            type: 'cell',
            activeCell: cellRef,
            selectedCells: [...newSelectedCells, cellRef],
            isDragging,
          }),
        );
      } else if (newSelectedCells.length === 0) {
        // nothing left to select; clear the selection
        dispatch(setSelectedCells(null));
      } else {
        const deselectActive = isCellRefEqual(cellRef, cellSelection.activeCell);
        const newActiveCell = deselectActive
          ? orderBy(newSelectedCells, [
              (ref) => orderedRowKeys.findIndex((rk) => isRowKeyEqual(rk, ref.rowKey)),
              (ref) => orderedColumnKeys.findIndex((ck) => isColumnKeyEqual(ck, ref.columnKey)),
            ])[0]
          : activeCell;

        dispatch(
          setSelectedCells({
            type: 'cell',
            blockId,
            activeCell: newActiveCell,
            selectedCells: newSelectedCells,
            isDragging,
          }),
        );
      }
    }
  };

export const handleArrowUpKey =
  (
    preventDefault: () => void,
    { shift = false, command = false }: { shift?: boolean; command?: boolean } = {},
  ): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const selection = prevailingSelectionSelector(state);
    if (!selection) {
      return;
    }

    preventDefault();
    if (selection.type === 'cell') {
      dispatch(cellNavigate('up', { range: shift, skip: command }));
    } else if (selection.type === 'eventsAndGroups') {
      const cellSelection = prevailingCellSelectionSelector(state);
      if (cellSelection != null) {
        dispatch(timelineCellNavigate('up'));
      } else if (selection.blockId != null) {
        dispatch(planTimelineRowChange('up', selection.blockId, { range: shift, skip: command }));
      }
    }
  };

export const handleArrowDownKey =
  (
    preventDefault: () => void,
    { shift = false, command = false }: { shift?: boolean; command?: boolean } = {},
  ): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const selection = prevailingSelectionSelector(state);
    if (!selection) {
      return;
    }

    preventDefault();
    const cellSelection = prevailingCellSelectionSelector(state);

    switch (selection.type) {
      case 'cell': {
        dispatch(cellNavigate('down', { range: shift, skip: command }));
        break;
      }
      case 'eventsAndGroups': {
        if (cellSelection != null) {
          dispatch(timelineCellNavigate('down'));
        } else if (selection.blockId != null) {
          dispatch(
            planTimelineRowChange('down', selection.blockId, { range: shift, skip: command }),
          );
        }
        break;
      }
      default:
        break;
    }
  };

export const handleArrowLeftKey =
  (
    preventDefault: () => void,
    { shift = false, command = false }: { shift?: boolean; command?: boolean } = {},
  ): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const selection = prevailingSelectionSelector(state);
    const cellSelection = prevailingCellSelectionSelector(state);
    if (selection == null || cellSelection == null) {
      return;
    }

    preventDefault();
    dispatch(cellNavigate('left', { range: shift, skip: command }));
  };

export const handleArrowRightKey =
  (
    preventDefault: () => void,
    { shift = false, command = false }: { shift?: boolean; command?: boolean } = {},
  ): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const selection = prevailingSelectionSelector(state);
    const cellSelection = prevailingCellSelectionSelector(state);
    if (selection == null || cellSelection == null) {
      return;
    }

    preventDefault();
    dispatch(cellNavigate('right', { range: shift, skip: command }));
  };

export const handleTabKey =
  (preventDefault: () => void, { shift = false }: { shift?: boolean } = {}): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const selection = prevailingSelectionSelector(state);
    const cellSelection = prevailingCellSelectionSelector(state);

    if (selection == null || cellSelection == null) {
      return;
    }

    preventDefault();
    dispatch(cellNavigate(shift ? 'left' : 'right'));
  };

export const navigateToNextCell =
  (direction?: Direction): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const selection = prevailingSelectionSelector(state);
    const cellSelection = prevailingCellSelectionSelector(state);
    if (selection == null || cellSelection == null) {
      return;
    }

    const fallback = selection.type === 'cell' ? 'down' : 'right';
    dispatch(cellNavigate(direction ?? fallback));
  };

export const handleDuplicateKey =
  (preventDefault: () => void): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const isModalOpen = isModalOpenSelector(state);
    const isPageWritable = isCurrentPageWritableSelector(state);
    const hasExistingEntitySelected = hasExistingEntitySelectedSelector(state);
    if (!isPageWritable || !hasExistingEntitySelected || isModalOpen) {
      return;
    }

    preventDefault();

    const selection = pageSelectionSelector(state);
    const duplicateCell = () => {
      const cellRef = prevailingActiveCellSelector(state);
      switch (cellRef?.type) {
        case CellType.Driver: {
          const driverId = cellRef.rowKey.driverId;
          if (driverId != null && selection?.blockId != null) {
            dispatch(duplicateSelectedDrivers({ blockId: selection?.blockId }));
          }
          break;
        }
        case CellType.ObjectField: {
          const objectId = cellRef.rowKey.objectId;
          if (objectId != null) {
            dispatch(duplicateSelectedBusinessObjects());
          }
          break;
        }
        default:
          break;
      }
    };

    switch (selection?.type) {
      case 'eventsAndGroups': {
        if (selection.refs.length !== 1 || selection.refs[0].type !== 'group') {
          return;
        }
        if (selection.refs[0].id === DEFAULT_EVENT_GROUP_ID) {
          return;
        }

        dispatch(duplicateGroup(selection.refs[0].id));
        break;
      }
      case 'cell': {
        duplicateCell();
        break;
      }
      default: {
        break;
      }
    }
  };

export const handleDetailsKey =
  (preventDefault: () => void): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const { isOrgMember } = accessCapabilitiesSelector(state);
    const isModalOpen = isModalOpenSelector(state);
    const isDetailPaneOpen = isDetailPaneOpenSelector(state);
    const hasExistingEntitySelected = hasExistingEntitySelectedSelector(state);
    if (!hasExistingEntitySelected || !isOrgMember || (isModalOpen && !isDetailPaneOpen)) {
      return;
    }

    const driverCellSelection = driverCellSelectionSelector(state);
    const driverId = driverCellSelection?.activeCell.rowKey.driverId;
    if (driverCellSelection?.selectedCells.length === 1 && driverId != null) {
      preventDefault();
      dispatch(navigateToDriverDetailPane({ driverId }, { openInNewTab: false }));
      return;
    }

    const activeEventGroupId = activeEventGroupIdSelector(state);
    if (activeEventGroupId != null) {
      preventDefault();
      dispatch(
        navigateToPlanDetailPane({ eventGroupId: activeEventGroupId }, { openInNewTab: false }),
      );
    }

    const objectSelection = objectFieldCellSelectionSelector(state);
    if (objectSelection?.activeCell != null && objectSelection.activeCell.rowKey.objectId != null) {
      preventDefault();
      dispatch(
        navigateToObjectDetailPane(
          { objectId: objectSelection.activeCell.rowKey.objectId },
          { openInNewTab: false },
        ),
      );
    }
  };

export const handleIncrementDecrementPrecision =
  ({ decrement = false }: { decrement?: boolean } = {}): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const activeCell = prevailingActiveCellSelector(state);
    const isDriverCell = isDriverCellRef(activeCell);
    const isBusinessObjectFieldCell = isBusinessObjectPropertyFieldCellRef(activeCell);

    if (isDriverCell) {
      dispatch(incrementDecrementSelectedDriverDecimalPlaces({ decrement }));
    } else if (isBusinessObjectFieldCell) {
      const objectId = activeCell.rowKey.objectId;
      const fieldSpecId = activeCell.columnKey.objectFieldSpecId;

      if (objectId == null || fieldSpecId == null) {
        return;
      }

      const objectSpecId = businessObjectSpecIdForObjectIdSelector(state, objectId);

      if (objectSpecId == null) {
        return;
      }

      dispatch(incrementDecrementObjectSpecDecimalPlaces({ objectSpecId, fieldSpecId, decrement }));
    }
  };

export const handleJKey =
  (
    preventDefault: () => void,
    { shift = false, ctrl = false }: { shift?: boolean; ctrl?: boolean } = {},
  ): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const canNavDetailPane = isNavigableDetailPaneOpen(state);
    if (canNavDetailPane && shift && ctrl) {
      preventDefault();
      dispatch(navigateDetailPane('down'));
    }
  };

export const handleKKey =
  (
    preventDefault: () => void,
    { shift = false, ctrl = false }: { shift?: boolean; ctrl?: boolean } = {},
  ): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const canNavDetailPane = isNavigableDetailPaneOpen(state);
    if (canNavDetailPane && shift && ctrl) {
      preventDefault();
      dispatch(navigateDetailPane('up'));
    }
  };

export const handleDriverIndentKey =
  ({ increase }: { increase?: boolean }, preventDefault: () => void): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const prevailingCellSelection = prevailingCellSelectionSelector(state);
    const cellSelection = prevailingCellSelectionSelector(state);

    if (cellSelection == null) {
      return;
    }

    const { blockId } = cellSelection;

    const selectedCells = cellSelection.selectedCells.concat(
      prevailingCellSelection?.selectedCells ?? [],
    );

    const selectedDriverNameCells: DriverCellNameRef[] = selectedCells.filter(isDriverNameCellRef);

    if (selectedDriverNameCells.length === 0 || blockId == null) {
      return;
    }

    preventDefault();

    const blockConfigIndentations = blockConfigDriverIndentsSelector(state, blockId);

    const driverIdsToUpdate = selectedDriverNameCells
      .map((cell) => cell.rowKey.driverId)
      .filter(isNotNull);

    // Update all the existing drivers that are selected
    const blockConfigIndentationUpdates = blockConfigIndentations.map((blockConfigIndentation) => {
      const { driverId, level } = blockConfigIndentation;

      if (driverIdsToUpdate.includes(driverId)) {
        driverIdsToUpdate.splice(driverIdsToUpdate.indexOf(driverId), 1);

        return {
          driverId,
          level: Math.max(0, increase ? level + 1 : level - 1),
        };
      } else {
        return blockConfigIndentation;
      }
    });

    // Add any new drivers that are selected
    driverIdsToUpdate.forEach((driverId) => {
      blockConfigIndentationUpdates.push({
        driverId,
        level: increase ? 1 : 0,
      });
    });

    if (!isEqual(blockConfigIndentationUpdates, blockConfigIndentations)) {
      dispatch(
        updateBlockConfig({
          blockId,
          fn: (blockConfig) => {
            blockConfig.driverIndentations = blockConfigIndentationUpdates;
          },
        }),
      );
    }
  };

export const handleCommandEnter =
  ({
    preventDefault,
    stopPropagation,
    agGridContext,
  }: {
    agGridContext?: {
      gridApi: GridApi;
      context: AgGridDatabaseContext;
    };
    preventDefault?: () => void;
    stopPropagation?: () => void;
  }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    // Can only set plan on timeseries cells
    const selectedMonthKeys = cellSelectionMonthKeysSelector(state);
    if (selectedMonthKeys.length === 0) {
      return;
    }

    // Can only open plan popover on forecast cells
    const selectedCellsIncludeActuals = cellSelectionIncludesActualsSelector(state);
    if (selectedCellsIncludeActuals) {
      return;
    }

    const blockId = prevailingCellSelectionBlockIdSelector(state);
    if (blockId == null) {
      return;
    }

    let canTagEventGroup = false;
    const driverId = prevailingActiveCellDriverIdSelector(state);
    if (driverId != null) {
      canTagEventGroup = isEditableDriverForecastSelector(state, {
        blockId,
        driverId,
      });
    } else {
      const objectFieldId = prevailingActiveCellObjectFieldId(state);
      if (objectFieldId == null) {
        return;
      }
      canTagEventGroup = true;
    }

    if (canTagEventGroup) {
      dispatch(openPlanPickerPopover());

      if (agGridContext != null) {
        const { gridApi, context } = agGridContext;

        gridApi.setGridOption('context', {
          ...context,
          isShowingCellPalette: true,
        });

        // Refresh the selected cell
        const { cellPaletteAnchorCell } = context;

        if (cellPaletteAnchorCell == null) {
          return;
        }

        gridApi.refreshCells({
          rowNodes: [cellPaletteAnchorCell.rowNode],
          columns: [cellPaletteAnchorCell.colId],
          force: true,
        });
      }

      stopPropagation?.();
      preventDefault?.();
    }
  };
