import React, { useCallback, useContext, useMemo } from 'react';

import FormulaDropdownContext from 'components/FormulaInput/FormulaDropdownContext';
import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import SelectMenu, { SelectItem } from 'components/SelectMenu/SelectMenu';
import useAppSelector from 'hooks/useAppSelector';
import { numericDimDriversForLayerSelector } from 'selectors/accessibleDriversSelector';

const SEARCH_KEYS = ['searchableName', 'name'];
const DRIVER_SECTION_ID = 'driver';
const SECTIONS = [
  {
    id: DRIVER_SECTION_ID,
    name: 'Drivers',
    maxResults: 5,
    showMore: true,
  },
];

const ThisSegmentDropdownMenu: React.FC = () => {
  const { onSelectThisSegmentEntity } = useContext(FormulaDropdownContext);

  const numericDimDrivers = useAppSelector(numericDimDriversForLayerSelector);

  const driverItems = useMemo<SelectItem[]>(
    () =>
      numericDimDrivers.map((driver) => {
        const { id, name } = driver;
        return {
          type: 'driver',
          id,
          key: id,
          name,
          sectionId: DRIVER_SECTION_ID,
        };
      }),
    [numericDimDrivers],
  );

  const onSelect = useCallback(
    (item: SelectItem) => {
      onSelectThisSegmentEntity({ dimDriverId: item.id });
    },
    [onSelectThisSegmentEntity],
  );

  return (
    <SelectMenu
      items={driverItems}
      onSelect={onSelect}
      searchKeys={SEARCH_KEYS}
      sections={SECTIONS}
      tabToSelect
      width="40rem"
    >
      {BaseSelectMenuItem}
    </SelectMenu>
  );
};

export default React.memo(ThisSegmentDropdownMenu);
