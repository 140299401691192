import React from 'react';

import { DriverFormat, ValueType } from 'generated/graphql';
import AutomaticIcon from 'vectors/Automatic';
import DollarSignIcon from 'vectors/DollarSign';
import NumberSignIcon from 'vectors/NumberSign';
import PercentageSignIcon from 'vectors/PercentageSign';

export const DEFAULT_DRIVER_FORMAT = DriverFormat.Number;

export const DRIVER_DRAG_ITEM_TYPE = 'driver';

export const DRIVER_FORMATS: DriverFormat[] = [
  DriverFormat.Auto,
  DriverFormat.Currency,
  DriverFormat.Number,
  DriverFormat.Integer,
  DriverFormat.Percentage,
];

export const DRIVER_FORMAT_NAMES: Record<DriverFormat, string> = {
  [DriverFormat.Auto]: 'Automatic',
  [DriverFormat.Currency]: 'Currency',
  [DriverFormat.Number]: 'Number',
  [DriverFormat.Integer]: 'Integer',
  [DriverFormat.Percentage]: 'Percentage',
};

export const DRIVER_FORMAT_ICONS: Record<DriverFormat, React.ReactElement> = {
  [DriverFormat.Auto]: <AutomaticIcon />,
  [DriverFormat.Currency]: <DollarSignIcon />,
  [DriverFormat.Number]: <NumberSignIcon />,
  [DriverFormat.Integer]: <NumberSignIcon />,
  [DriverFormat.Percentage]: <PercentageSignIcon />,
};

export const VALUE_TYPE_NAMES: Record<ValueType, string> = {
  [ValueType.Number]: 'Number',
  [ValueType.Timestamp]: 'Timestamp',
  [ValueType.Attribute]: 'Attribute',
  [ValueType.Boolean]: 'Boolean',
};
