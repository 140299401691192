import { Box, Tooltip } from '@chakra-ui/react';
import React from 'react';

import ExtSourceTooltip from 'components/BusinessObjectTable/ExtSourceTooltip';
import CalcErrTooltip from 'components/CalcErrTooltip/CalcErrTooltip';
import { CellValueTooltipData } from 'config/cells';
import { CELL_TOOLTIP_OPEN_DELAY } from 'config/tooltips';

const CellValueTooltip: React.FC<CellValueTooltipData & { children: JSX.Element }> = (props) => {
  const { content, children } = props;
  const { type } = content;

  switch (type) {
    case 'driver_calc_error':
      return <CalcErrTooltip {...content}>{children}</CalcErrTooltip>;

    case 'ext_source':
      return (
        <Tooltip
          openDelay={CELL_TOOLTIP_OPEN_DELAY}
          placement="top"
          label={<ExtSourceTooltip extSource={content.extSource} />}
        >
          <Box w="inherit" h="full">
            {children}
          </Box>
        </Tooltip>
      );

    case 'simple_msg':
      return (
        <Tooltip openDelay={CELL_TOOLTIP_OPEN_DELAY} placement="top" label={content.msg}>
          <Box w="inherit" h="full">
            {children}
          </Box>
        </Tooltip>
      );
    default:
      return null;
  }
};

const CellValueTooltipContainer: React.FC<{
  tooltipData: CellValueTooltipData | undefined;
  children: JSX.Element;
}> = ({ tooltipData, children }) => {
  if (tooltipData != null) {
    return <CellValueTooltip {...tooltipData}>{children}</CellValueTooltip>;
  }

  return children;
};

export default CellValueTooltipContainer;
