import { DateTime } from 'luxon';

import { WithEventGroup } from '@features/Plans';
import { DEFAULT_PLAN_MONTHS } from 'config/planTimeline';
import {
  EventGroupCreateInput,
  EventGroupUpdateInput,
  ImpactType,
  ValueType,
} from 'generated/graphql';
import { numMonthsBetweenDates } from 'helpers/dates';
import { isEventGroupEmpty } from 'helpers/eventGroups';
import { Range } from 'helpers/events';
import { mergeMutations } from 'helpers/mergeMutations';
import { firstName } from 'helpers/names';
import { peekMutationStateChange, sortIndexMutationsForInsertion } from 'helpers/sortIndex';
import { isNotNull } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import {
  NewEventForDriver,
  NewEventForObjectField,
  createNewEventsWithEventGroup,
  updateEventsAndGroups,
} from 'reduxStore/actions/eventMutations';
import { getMutationThunkAction } from 'reduxStore/actions/submitDatasetMutation';
import { BlockId } from 'reduxStore/models/blocks';
import { EventGroupId, EventId } from 'reduxStore/models/events';
import { NonValueTimeSeries } from 'reduxStore/models/timeSeries';
import {
  clearAutoFocus,
  selectPlanTimelineItemRef,
  setAutoFocus,
} from 'reduxStore/reducers/pageSlice';
import { createDraftInitiative, toggleExpand } from 'reduxStore/reducers/roadmapSlice';
import { AppThunk } from 'reduxStore/store';
import { businessObjectFieldForecastTimeSeriesSelector } from 'selectors/businessObjectTimeSeriesSelector';
import { driverValueForMonthKeySelector } from 'selectors/driverTimeSeriesSelector';
import {
  cellSelectionMonthKeysWithObjectPropertiesSelector,
  curvePointImpactingDriverAndMonthKeySelector,
  curvePointImpactingObjectFieldAndMonthKeySelector,
  eventGroupsByIdForLayerSelector,
  eventImpactingDriverAndMonthKeySelector,
  eventImpactingObjectFieldAndMonthKeySelector,
} from 'selectors/eventsAndGroupsSelector';
import { authenticatedUserSelector } from 'selectors/loginSelector';
import { blockDateRangeDateTimeSelector } from 'selectors/pageDateRangeSelector';
import { detailPanePlanIdSelector } from 'selectors/planDetailPaneSelector';
import {
  cellSelectionMonthKeysByDriverIdSelector,
  prevailingCellSelectionBlockIdSelector,
} from 'selectors/prevailingCellSelectionSelector';

const createEventGroup = getMutationThunkAction<
  EventGroupCreateInput & { insertBeforeId?: EventId | EventGroupId | 'start' | 'end' }
>(({ insertBeforeId, ...input }, getState) => {
  let state = getState();
  const baseMutation = { newEventGroups: [input] };
  state = peekMutationStateChange(state, baseMutation);
  const backfillData = sortIndexMutationsForInsertion(state, input.id, insertBeforeId);
  return mergeMutations(baseMutation, backfillData);
});

export const updateEventGroups =
  (
    updates: Array<EventGroupUpdateInput & { insertBeforeId?: EventId | EventGroupId | 'end' }>,
  ): AppThunk =>
  (dispatch) => {
    dispatch(updateEventsAndGroups({ events: [], groups: updates }));
  };

export const updateEventGroup =
  (
    updateInput: EventGroupUpdateInput & { insertBeforeId?: EventId | EventGroupId | 'end' },
  ): AppThunk =>
  (dispatch) => {
    dispatch(updateEventsAndGroups({ events: [], groups: [updateInput] }));
  };

/**
 * Updates the event group for a renamed initiative. Conditionally creates a new draft
 * initiative as a child if the renamed initiative is empty.
 */
export const renameTimelineInitiative =
  ({ id, blockId, newName }: { id: EventGroupId; blockId: BlockId; newName: string }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const eventGroup = eventGroupsByIdForLayerSelector(state)[id];

    const cleanName = newName.trim();
    const oldName = eventGroup.name;
    if (oldName !== newName) {
      dispatch(updateEventGroup({ id, name: cleanName }));
    }

    if (isEventGroupEmpty(eventGroup)) {
      dispatch(createDraftInitiative({ parentId: id, blockId }));
    }
  };

export const cancelRenameTimelineInitiative =
  ({
    id,
    blockId,
    reason,
  }: {
    id: EventGroupId;
    blockId: BlockId;
    reason: 'noop' | 'escape';
  }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const eventGroup = eventGroupsByIdForLayerSelector(state)[id];

    dispatch(clearAutoFocus());
    if (reason === 'noop' && isEventGroupEmpty(eventGroup)) {
      dispatch(createDraftInitiative({ parentId: id, blockId }));
    }
  };

function getNewInitiativeDefaultDateRange(range: [DateTime, DateTime]): Range {
  // stick a plan on the timeline somewhere around the middle of the view range
  // the first two months are generally covered by the navigation menu
  let startDateTime = range[0].plus({ months: 2 });
  let endDateTime = range[1];
  const monthDiff = numMonthsBetweenDates(startDateTime, endDateTime);

  startDateTime = startDateTime
    .plus({ months: monthDiff / 2 - DEFAULT_PLAN_MONTHS / 2 })
    .startOf('month');
  endDateTime = startDateTime.plus({ months: DEFAULT_PLAN_MONTHS - 1 }).endOf('month');
  return {
    start: startDateTime.toISO(),
    end: endDateTime.toISO(),
  };
}

export const createNewEventGroup = (blockId: BlockId): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const authenticatedUser = authenticatedUserSelector(state);
    const blockDateTimeRange = blockDateRangeDateTimeSelector(state, blockId);
    const currentFocusedPlanId = detailPanePlanIdSelector(state);
    if (!authenticatedUser) {
      return;
    }

    const { start, end } = getNewInitiativeDefaultDateRange(blockDateTimeRange);
    const newGroupId = uuidv4();
    dispatch(
      createEventGroup({
        id: newGroupId,
        name: `${firstName(authenticatedUser.name)}'s Plan`,
        ownerName: authenticatedUser.name,
        ownerId: authenticatedUser.id,
        defaultStart: start,
        defaultEnd: end,
        parentId: currentFocusedPlanId ?? undefined,
      }),
    );
    dispatch(selectPlanTimelineItemRef({ type: 'group', blockId, id: newGroupId }));
    dispatch(setAutoFocus({ type: 'eventGroup', blockId, id: newGroupId }));
    dispatch(toggleExpand({ blockId, itemId: newGroupId }));
  };
};

export const tagCellSelectionWithEventGroup = (withEventGroup: WithEventGroup): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();

    const selectedMonthKeysByDriverId = cellSelectionMonthKeysByDriverIdSelector(state);

    const newDriverEvents = Object.entries(selectedMonthKeysByDriverId)
      .flatMap(([driverId, monthKeys]) => {
        return monthKeys.map((monthKey) => {
          const event = eventImpactingDriverAndMonthKeySelector(state, { driverId, monthKey });
          const existingCurvePoint = curvePointImpactingDriverAndMonthKeySelector(state, {
            driverId,
            monthKey,
          });

          // Existing curve point on cell
          if (event != null && existingCurvePoint?.type === ValueType.Number) {
            const eventToCreate: NewEventForDriver = {
              type: 'driver',
              driverId,
              impactType: event.impactType,
              customCurvePoints: {
                [monthKey]: existingCurvePoint.value,
              },
            };

            return eventToCreate;
          }

          // New curve point on cell
          const value = driverValueForMonthKeySelector(state, { driverId, monthKey });

          if (value == null) {
            return null;
          }

          const eventToCreate: NewEventForDriver = {
            type: 'driver',
            driverId,
            impactType: ImpactType.Set,
            customCurvePoints: {
              [monthKey]: value,
            },
          };

          return eventToCreate;
        });
      })
      .filter(isNotNull);

    const selectedMonthKeysWithObjectField =
      cellSelectionMonthKeysWithObjectPropertiesSelector(state);

    const newObjectFieldEvents = selectedMonthKeysWithObjectField
      .flatMap(({ objectFieldId, objectId, objectFieldSpecId, monthKeys }) => {
        return monthKeys.map((monthKey) => {
          const event = eventImpactingObjectFieldAndMonthKeySelector(state, {
            objectFieldId,
            monthKey,
          });
          const existingCurvePoint = curvePointImpactingObjectFieldAndMonthKeySelector(state, {
            objectFieldId,
            monthKey,
          });

          // Existing curve point on cell
          if (event != null && existingCurvePoint != null) {
            const eventToCreate: NewEventForObjectField = {
              type: 'objectField',
              objectId,
              fieldSpecId: objectFieldSpecId,
              objectFieldId,
              impactType: event.impactType,
              customCurvePoints: {
                [monthKey]: existingCurvePoint.value,
              } as NonValueTimeSeries,
            };

            return eventToCreate;
          }

          // New curve point on cell
          const blockId = prevailingCellSelectionBlockIdSelector(state);
          if (blockId == null) {
            return null;
          }

          const timeSeries = businessObjectFieldForecastTimeSeriesSelector(state, {
            businessObjectId: objectId,
            businessObjectFieldSpecId: objectFieldSpecId,
            blockId,
          });

          const value = timeSeries?.[monthKey];

          if (value == null) {
            return null;
          }

          const eventToCreate: NewEventForObjectField = {
            type: 'objectField',
            objectId,
            fieldSpecId: objectFieldSpecId,
            objectFieldId,
            impactType: ImpactType.Set,
            customCurvePoints: {
              [monthKey]: value.value,
            } as NonValueTimeSeries,
          };

          return eventToCreate;
        });
      })
      .filter(isNotNull);

    dispatch(
      createNewEventsWithEventGroup({
        newEvents: [...newDriverEvents, ...newObjectFieldEvents],
        withEventGroup,
      }),
    );
  };
};
