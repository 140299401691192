import { Flex } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useContext, useMemo } from 'react';

import { Tooltip } from 'chakra/tooltip';
import AttributeBadge from 'components/AttributeBadge/AttributeBadge';
import DimensionBadge from 'components/Formula/DimensionBadge';
import AttributePill from 'components/FormulaInput/AttributePill';
import DateRangePill from 'components/FormulaInput/DateRangePill';
import FormulaSelectionContext from 'components/FormulaInput/FormulaSelectionContext';
import ReferencePill from 'components/FormulaInput/ReferencePill';
import { isAttribute } from 'helpers/dimensionalDrivers';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { useEmptyAttributesFromFilters } from 'hooks/useEmptyAttributesFromFilters';
import useFormulaPillMouseDown from 'hooks/useFormulaPillMouseDown';
import { jumpToDriver } from 'reduxStore/actions/jumpToDriver';
import { dimensionsByIdSelector } from 'selectors/dimensionsSelector';
import { driverIsKpiSelector } from 'selectors/driversSelector';
import { DraftDecoratorComponentProps, DriverRefMetadata } from 'types/formula';
import Star from 'vectors/Star';

const DriverDecorator: React.FC<DraftDecoratorComponentProps> = ({
  contentState,
  entityKey,
  offsetKey,
}) => {
  const dispatch = useAppDispatch();
  const { activeEntity, closeFormulaInput, hasError, keysInSelection } =
    useContext(FormulaSelectionContext);
  const entity = contentState.getEntity(entityKey ?? '');
  const { id, label, dateRangeDisplay, error, attributeFilters, placeholder } =
    entity.getData() as DriverRefMetadata;
  const isSelectedEntity = activeEntity?.key === entityKey;
  const onCtrlClick = useCallback(() => {
    if (!hasError) {
      closeFormulaInput();
      dispatch(jumpToDriver({ driverId: id }));
    }
  }, [closeFormulaInput, hasError, dispatch, id]);
  const isKpi = useAppSelector((state) => driverIsKpiSelector(state, id));
  const onNameMouseDown = useFormulaPillMouseDown(entityKey, entity, { onCtrlClick });
  const onDateMouseDown = useFormulaPillMouseDown(entityKey, entity, { submenu: 'timePeriod' });

  const dimById = useAppSelector(dimensionsByIdSelector);
  const emptyAttributes = useEmptyAttributesFromFilters(attributeFilters);

  const areAttributesFiltersEmpty = useMemo(
    () => isEmpty(attributeFilters) || Object.values(attributeFilters).every(isEmpty),
    [attributeFilters],
  );

  if (entityKey == null) {
    return null;
  }

  const attributes = areAttributesFiltersEmpty ? null : (
    <Flex columnGap="0.125rem">
      {Object.entries(attributeFilters!).flatMap(([dimId, attrs]) =>
        attrs.map((attr) =>
          isAttribute(attr) ? (
            <AttributeBadge key={attr.id} attribute={attr} size="xs" />
          ) : (
            <DimensionBadge key={dimId} dimension={dimById[dimId]} type={attr} />
          ),
        ),
      )}
      {emptyAttributes.map((attr) => (
        <AttributeBadge key={attr.id} attribute={attr} size="xs" />
      ))}
    </Flex>
  );

  const placeholderBadge = placeholder != null ? <AttributePill label={placeholder} /> : null;

  let kpiIcon: JSX.Element | undefined;
  if (isKpi) {
    kpiIcon = (
      <Tooltip label="This driver is a KPI" placement="top">
        <Star mr={1} />
      </Tooltip>
    );
  }

  const isSelected = isSelectedEntity || keysInSelection.has(entityKey);
  return (
    <ReferencePill
      isSelected={isSelectedEntity || keysInSelection.has(entityKey)}
      error={error}
      label={label}
      icon={kpiIcon}
      attributes={attributes}
      placeholder={placeholderBadge}
      offsetKey={offsetKey}
      onMouseDown={onNameMouseDown}
    >
      {dateRangeDisplay != null && (
        <DateRangePill
          onMouseDown={onDateMouseDown}
          label={dateRangeDisplay}
          isSelected={isSelected}
        />
      )}
    </ReferencePill>
  );
};

export default DriverDecorator;
