import { createSelector } from '@reduxjs/toolkit';
import { createCachedSelector } from 're-reselect';

import { ValueType } from 'generated/graphql';
import { arraySameElements } from 'helpers/array';
import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import { getSubdriverKey } from 'helpers/dimensionalDrivers';
import {
  AttributePropertyKey,
  ComputedAttributeProperty,
  DimensionalPropertyEvaluator,
  getAttributePropertyKey,
} from 'helpers/formulaEvaluation/DimensionalPropertyEvaluator';
import {
  DatabaseFormulaProperty,
  DatabaseFormulaPropertyId,
} from 'helpers/formulaEvaluation/ReferenceEvaluator';
import {
  SelectorWithLayerParam,
  addLayerParams,
  getCacheKeyForLayerSelector,
} from 'helpers/layerSelectorFactory';
import { safeObjGet } from 'helpers/typescript';
import {
  BusinessObjectFieldSpec,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { BusinessObject, BusinessObjectId } from 'reduxStore/models/businessObjects';
import {
  AttributeProperty,
  Collection,
  CollectionEntry,
  DimensionalProperty,
  DimensionalPropertyId,
  DriverProperty,
  DriverPropertyId,
  isKeyDimensionalProperty,
} from 'reduxStore/models/collections';
import { DimensionId } from 'reduxStore/models/dimensions';
import { DimensionalSubDriver, Driver, DriverId } from 'reduxStore/models/drivers';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { businessObjectFieldSpecByIdSelector } from 'selectors/businessObjectFieldSpecsSelector';
import {
  businessObjectSpecSelector,
  businessObjectSpecsByIdForLayerSelector,
  businessObjectSpecsForLayerSelector,
} from 'selectors/businessObjectSpecsSelector';
import {
  businessObjectSelector,
  businessObjectSpecForObjectIdSelector,
  businessObjectsByIdForLayerSelector,
  businessObjectsBySpecIdForLayerSelector,
} from 'selectors/businessObjectsSelector';
import { fieldSelector, paramSelector } from 'selectors/constSelectors';
import { attributesByExtKeySelector } from 'selectors/dimensionsSelector';
import {
  allDimensionalDriversSelector,
  dimensionalDriversByIdSelector,
  driversByIdForLayerSelector,
  subDriversByDriverIdSelector,
} from 'selectors/driversSelector';
import { ColumnType } from 'selectors/scenarioComparisonSelector';
import { ParametricSelector } from 'types/redux';

export const collectionsByObjectSpecIdSelector: SelectorWithLayerParam<Record<string, Collection>> =
  createCachedSelector(addLayerParams(businessObjectSpecsForLayerSelector), (objectSpecs) => {
    const byId: Record<string, Collection> = {};
    objectSpecs.forEach((spec) => {
      if (spec.collection != null) {
        byId[spec.id] = spec.collection;
      }
    });
    return byId;
  })(getCacheKeyForLayerSelector);

export const dimensionalPropertiesForBusinessObjectSpecIdSelector: ParametricSelector<
  BusinessObjectSpecId,
  DimensionalProperty[]
> = createCachedSelector(businessObjectSpecSelector, (spec) => {
  if (spec == null || spec.collection == null) {
    return [];
  } else {
    return spec.collection.dimensionalProperties;
  }
})((_state, objectSpecId) => objectSpecId);

export const driverPropertiesForBusinessObjectSpecIdSelector: ParametricSelector<
  BusinessObjectSpecId,
  DriverProperty[]
> = createCachedSelector(businessObjectSpecSelector, (spec) => {
  if (spec == null || spec.collection == null) {
    return [];
  } else {
    return spec.collection.driverProperties;
  }
})((_state, objectSpecId) => objectSpecId);

export const dimensionalPropertiesByIdSelector: SelectorWithLayerParam<
  Record<DimensionalPropertyId, DimensionalProperty>
> = createCachedSelector(addLayerParams(collectionsByObjectSpecIdSelector), (collections) => {
  const byId: Record<DimensionalPropertyId, DimensionalProperty> = {};
  Object.values(collections).forEach((collection) => {
    collection.dimensionalProperties.forEach((dimProp) => {
      byId[dimProp.id] = dimProp;
    });
  });
  return byId;
})(getCacheKeyForLayerSelector);

const getDimensionalPropertyIdSelector: ParametricSelector<
  DimensionalPropertyId,
  DimensionalPropertyId
> = (_state, dimPropertyId: DimensionalPropertyId) => dimPropertyId;

export const dimensionalPropertySelector: ParametricSelector<
  DimensionalPropertyId,
  DimensionalProperty | undefined
> = createCachedSelector(
  (state) => dimensionalPropertiesByIdSelector(state),
  getDimensionalPropertyIdSelector,
  (dimPropertiesById, dimPropertyId) => {
    return dimPropertiesById[dimPropertyId];
  },
)((_state, dimPropertyId) => dimPropertyId);

export const driverPropertiesByIdSelector: SelectorWithLayerParam<
  Record<DriverPropertyId, DriverProperty | undefined>
> = createCachedSelector(addLayerParams(collectionsByObjectSpecIdSelector), (collections) => {
  const byId: Record<DriverPropertyId, DriverProperty | undefined> = {};
  Object.values(collections).forEach((collection) => {
    collection.driverProperties.forEach((driverProp) => {
      byId[driverProp.id] = driverProp;
    });
  });
  return byId;
})(getCacheKeyForLayerSelector);

const getDriverPropertyIdSelector: ParametricSelector<DriverPropertyId, DriverPropertyId> = (
  _state,
  driverPropertyId: DriverPropertyId,
) => driverPropertyId;

export const driverPropertySelector: ParametricSelector<
  DriverPropertyId,
  DriverProperty | undefined
> = createCachedSelector(
  (state) => driverPropertiesByIdSelector(state),
  getDriverPropertyIdSelector,
  (driverPropertiesById, driverPropertyId) => {
    return driverPropertiesById[driverPropertyId];
  },
)((_state, driverPropertyId) => driverPropertyId);

export const collectionEntryForBusinessObjectIdSelector: ParametricSelector<
  BusinessObjectId,
  CollectionEntry | null
> = createCachedSelector(businessObjectSelector, (object) => {
  return object?.collectionEntry ?? null;
})((_state, objectId) => objectId);

type AttributePropertyProps = {
  objectId: BusinessObjectId;
  dimensionalPropertyId: DimensionalPropertyId;
};

const attributePropertyByKeySelector: SelectorWithLayerParam<
  Record<AttributePropertyKey, AttributeProperty>
> = createCachedSelector(addLayerParams(businessObjectsByIdForLayerSelector), (objectsById) => {
  const res: Record<AttributePropertyKey, AttributeProperty> = {};

  Object.entries(objectsById).forEach(([objectId, object]) => {
    return object.collectionEntry?.attributeProperties.forEach((attributeProperty) => {
      const key = getAttributePropertyKey({
        objectId,
        dimensionalPropertyId: attributeProperty.dimensionalPropertyId,
      });
      res[key] = attributeProperty;
    });
  });
  return res;
})(getCacheKeyForLayerSelector);

export const databaseFormulaPropertiesByIdSelector: SelectorWithLayerParam<
  Record<DatabaseFormulaPropertyId, DatabaseFormulaProperty | undefined>
> = createCachedSelector(
  addLayerParams(dimensionalPropertiesByIdSelector),
  addLayerParams(driverPropertiesByIdSelector),
  addLayerParams(businessObjectFieldSpecByIdSelector),
  addLayerParams(driversByIdForLayerSelector),
  (dimensionalPropertiesById, driverPropertiesById, businessObjectFieldSpecById, driversById) => {
    const byId: Record<DatabaseFormulaPropertyId, DatabaseFormulaProperty | undefined> = {};
    Object.values(dimensionalPropertiesById).forEach((dimProperty) => {
      if (dimProperty == null) {
        return;
      }
      byId[dimProperty.id] = {
        id: dimProperty.id,
        name: dimProperty.name,
        type: 'dimensionalProperty',
        dimensionalProperty: dimProperty,
      };
    });
    Object.values(businessObjectFieldSpecById).forEach((businessObjectFieldSpec) => {
      if (businessObjectFieldSpec == null) {
        return;
      }
      byId[businessObjectFieldSpec.id] = {
        id: businessObjectFieldSpec.id,
        name: businessObjectFieldSpec.name,
        type: 'fieldSpec',
        fieldSpec: businessObjectFieldSpec,
      };
    });
    // adding driver properties last so that they take precedence over formula properties added by businessObjectFieldSpecById iteration step
    Object.values(driverPropertiesById).forEach((driverProperty) => {
      if (driverProperty == null) {
        return;
      }
      const driver = driversById[driverProperty.driverId];
      if (driver == null) {
        return;
      }
      byId[driverProperty.id] = {
        id: driverProperty.id,
        name: driver.name,
        type: 'driverProperty',
        driverProperty,
      };
    });
    return byId;
  },
)(getCacheKeyForLayerSelector);

export const dimensionIdByDatabasePropertyIdSelector: SelectorWithLayerParam<
  Record<DatabaseFormulaPropertyId, DimensionId | undefined>
> = createCachedSelector(
  addLayerParams(dimensionalPropertiesByIdSelector),
  addLayerParams(businessObjectFieldSpecByIdSelector),
  addLayerParams(driverPropertiesByIdSelector),
  addLayerParams(driversByIdForLayerSelector),
  (dimensionalPropertiesById, businessObjectFieldSpecById, driverPropertiesById, driversById) => {
    const byId: Record<DatabaseFormulaPropertyId, DimensionId | undefined> = {};
    Object.values(dimensionalPropertiesById).forEach((dimProperty) => {
      if (dimProperty == null) {
        return;
      }
      byId[dimProperty.id] = dimProperty.dimension.id;
    });
    Object.values(businessObjectFieldSpecById).forEach((businessObjectFieldSpec) => {
      if (businessObjectFieldSpec == null || businessObjectFieldSpec.type !== ValueType.Attribute) {
        return;
      }
      byId[businessObjectFieldSpec.id] = businessObjectFieldSpec.dimensionId;
    });
    Object.values(driverPropertiesById).forEach((driverProperty) => {
      if (driverProperty == null) {
        return;
      }
      const driver = driversById[driverProperty.driverId];
      if (driver == null) {
        return;
      }
      const dimensionId = driver.valueTypeExtension?.attributeTypeExtension?.dimensionId;
      if (dimensionId == null) {
        return;
      }
      byId[driverProperty.id] = dimensionId;
    });
    return byId;
  },
)(getCacheKeyForLayerSelector);

export const computedAttributePropertySelector: ParametricSelector<
  AttributePropertyProps,
  ComputedAttributeProperty | null
> = createCachedSelector(
  (state: RootState) => dimensionalPropertyEvaluatorSelector(state),
  paramSelector<AttributePropertyProps>(),
  (evaluator, attributePropertyProps) => {
    return evaluator.getAttributeProperty(attributePropertyProps);
  },
)((_state, { objectId, dimensionalPropertyId }) => [objectId, dimensionalPropertyId].join(','));

export const dimensionalPropertyEvaluatorSelector: SelectorWithLayerParam<DimensionalPropertyEvaluator> =
  createCachedSelector(
    addLayerParams(businessObjectsByIdForLayerSelector),
    addLayerParams(businessObjectsBySpecIdForLayerSelector),
    addLayerParams(dimensionalPropertiesByIdSelector),
    addLayerParams(attributePropertyByKeySelector),
    addLayerParams(collectionsByObjectSpecIdSelector),
    addLayerParams(attributesByExtKeySelector),
    addLayerParams(allDimensionalDriversSelector),
    // eslint-disable-next-line max-params
    function dimensionalPropertyEvaluatorSelector(
      businessObjectsById,
      businessObjectsBySpecId,
      dimensionalPropertiesById,
      attributePropertyByKey,
      collectionsByObjectSpecId,
      attributesByExtKey,
      allDimensionalDrivers,
    ) {
      return new DimensionalPropertyEvaluator({
        businessObjectsById,
        businessObjectsBySpecId,
        dimensionalPropertiesById,
        attributePropertyByKey,
        collectionsByObjectSpecId,
        attributesByExtKey,
        allDimensionalDrivers,
      });
    },
  )(getCacheKeyForLayerSelector);

export const computedAttributePropertiesForBusinessObjectSelector: ParametricSelector<
  BusinessObjectId,
  ComputedAttributeProperty[]
> = createCachedSelector(
  businessObjectSelector,
  (state: RootState) => businessObjectSpecsByIdForLayerSelector(state),
  (state: RootState) => dimensionalPropertyEvaluatorSelector(state),
  paramSelector<BusinessObjectId>(),
  (businessObject, businessObjectSpecs, evaluator, objectId) => {
    const spec = businessObjectSpecs[businessObject?.specId ?? ''];
    if (spec == null || spec.collection == null) {
      return [];
    }
    return evaluator.getKeyAttributePropertiesForBusinessObject(objectId);
  },
)((_state, objectId) => objectId);

export const subdriversByDimDriverIdForBusinessObjectSelector: ParametricSelector<
  BusinessObjectId,
  Record<DriverId, DimensionalSubDriver>
> = createCachedSelector(
  computedAttributePropertiesForBusinessObjectSelector,
  businessObjectSpecForObjectIdSelector,
  dimensionalDriversByIdSelector,
  dimensionalPropertyEvaluatorSelector,
  subDriversByDriverIdSelector,
  (
    computedAttributeProperties,
    businessObjectSpec,
    dimDriversById,
    dimensionalPropertyEvaluator,
    subDriversByDriverId,
  ) => {
    const computedAttributeIds = computedAttributeProperties.map((a) => a.attribute.id);
    return (
      businessObjectSpec?.collection?.driverProperties.reduce<
        Record<DriverId, DimensionalSubDriver>
      >(
        (res, driverProperty) => {
          const dimDriver = safeObjGet(dimDriversById[driverProperty.driverId]);
          if (dimDriver == null) {
            return res;
          }

          const subDriverId = dimensionalPropertyEvaluator.getSubDriverIdForAttributeIds(
            dimDriver.id,
            computedAttributeIds,
          );
          if (subDriverId == null) {
            return res;
          }

          const subDriver = safeObjGet(subDriversByDriverId[subDriverId]);
          if (subDriver == null) {
            return res;
          }
          res[dimDriver.id] = subDriver;
          return res;
        },
        {} as Record<DriverId, DimensionalSubDriver>,
      ) ?? {}
    );
  },
)((_state, objectId) => objectId);

const EMPTY_DRIVER_PROPERTY_ARRAY: DriverProperty[] = [];
export const driverPropertiesForBusinessObjectSpecSelector: ParametricSelector<
  BusinessObjectSpecId,
  DriverProperty[]
> = createCachedSelector(businessObjectSpecSelector, (spec) => {
  if (spec == null) {
    return EMPTY_DRIVER_PROPERTY_ARRAY;
  }
  return spec.collection?.driverProperties ?? EMPTY_DRIVER_PROPERTY_ARRAY;
})((_state, businessObjectSpecId) => businessObjectSpecId);

export const driverPropertiesByBusinessObjectSpecIdSelector: SelectorWithLayerParam<
  NullableRecord<BusinessObjectSpecId, DriverProperty[]>
> = createCachedSelector(businessObjectSpecsForLayerSelector, (objectSpecs) => {
  const byId: NullableRecord<BusinessObjectSpecId, DriverProperty[]> = {};
  objectSpecs.forEach((spec) => {
    if (spec.collection != null) {
      byId[spec.id] = spec.collection.driverProperties;
    }
  });
  return byId;
})({ keySelector: getCacheKeyForLayerSelector, selectorCreator: createDeepEqualSelector });

const EMPTY_DIMENSIONAL_PROPERTY_ARRAY: Array<DimensionalProperty & { isDatabaseKey: true }> = [];
export const keyDimensionalPropertiesForBusinessObjectSpecSelector: ParametricSelector<
  BusinessObjectSpecId,
  Array<DimensionalProperty & { isDatabaseKey: true }>
> = createCachedSelector(businessObjectSpecSelector, (spec) => {
  if (spec == null) {
    return EMPTY_DIMENSIONAL_PROPERTY_ARRAY;
  }
  return (
    spec.collection?.dimensionalProperties.filter(isKeyDimensionalProperty) ??
    EMPTY_DIMENSIONAL_PROPERTY_ARRAY
  );
})({
  keySelector: (_state, businessObjectSpecId) => businessObjectSpecId,
  selectorCreator: createDeepEqualSelector,
});

/**
 * Note: this assumes a 1:1 mapping between driver property and dim driver. This is true right now,
 * but this may not be the case in the future.
 */
export const driverPropertyIdByDimDriverIdSelector: SelectorWithLayerParam<
  Record<DriverId, DriverPropertyId>
> = createCachedSelector(businessObjectSpecsByIdForLayerSelector, (objectSpecsById) => {
  const driverPropertyIdsByDimDriverId: Record<DriverId, DriverPropertyId> = {};
  Object.values(objectSpecsById).forEach((objectSpec) => {
    objectSpec.collection?.driverProperties.forEach(({ id, driverId }) => {
      driverPropertyIdsByDimDriverId[driverId] = id;
    });
  });

  return driverPropertyIdsByDimDriverId;
})({ keySelector: getCacheKeyForLayerSelector, selectorCreator: createDeepEqualSelector });

const businessObjectSpecIdsByDimDriverIdSelector: SelectorWithLayerParam<
  NullableRecord<DriverId, BusinessObjectSpecId[]>
> = createCachedSelector(businessObjectSpecsByIdForLayerSelector, (objectSpecsById) => {
  const specIdsByDimDriverId: Record<DriverId, BusinessObjectSpecId[]> = {};
  Object.values(objectSpecsById).forEach((objectSpec) => {
    objectSpec.collection?.driverProperties.forEach(({ driverId }) => {
      specIdsByDimDriverId[driverId] ??= [];
      specIdsByDimDriverId[driverId].push(objectSpec.id);
    });
  });

  return specIdsByDimDriverId;
})({ keySelector: getCacheKeyForLayerSelector, selectorCreator: createDeepEqualSelector });

export const businessObjectSpecIdsBySubDriverIdSelector: SelectorWithLayerParam<
  NullableRecord<DriverId, BusinessObjectSpecId[]>
> = createCachedSelector(
  dimensionalPropertyEvaluatorSelector,
  allDimensionalDriversSelector,
  businessObjectSpecIdsByDimDriverIdSelector,
  (evaluator, dimDrivers, specIdsByDimDriverId) => {
    return Object.fromEntries(
      dimDrivers.flatMap((dimDriver) =>
        dimDriver.subdrivers.map((subdriver) => {
          const businessObjectSpecIds = specIdsByDimDriverId[dimDriver.id] ?? [];
          if (businessObjectSpecIds.length > 0) {
            const businessObjectId = evaluator.getBusinessObjectIdBySubDriverId(subdriver.driverId);
            if (businessObjectId == null) {
              return [subdriver.driverId, []];
            }
          }
          return [subdriver.driverId, businessObjectSpecIds];
        }),
      ),
    );
  },
)(getCacheKeyForLayerSelector);

type SubDriverLookupProps = {
  driverId: DriverId;
  businessObjectSpecId: BusinessObjectSpecId;
};
export const businessObjectForSubDriverIdSelector: ParametricSelector<
  SubDriverLookupProps,
  BusinessObject | null
> = createCachedSelector(
  (state: RootState) => businessObjectsBySpecIdForLayerSelector(state),
  (state: RootState) => dimensionalPropertyEvaluatorSelector(state),
  (state) => subDriversByDriverIdSelector(state),
  paramSelector<SubDriverLookupProps>(),
  (businessObjectsBySpecId, evaluator, subdriversById, { driverId, businessObjectSpecId }) => {
    const subdriver = subdriversById[driverId];
    if (subdriver == null) {
      return null;
    }
    const subdriverAttributes = subdriver.attributes.map((a) => a.id);
    const businessObjects = businessObjectsBySpecId[businessObjectSpecId] ?? [];

    const res = businessObjects.find((object) => {
      const objectAttributes = evaluator
        .getKeyAttributePropertiesForBusinessObject(object.id)
        .map((a) => a.attribute.id);
      return arraySameElements(subdriverAttributes, objectAttributes);
    });
    return res ?? null;
  },
)((_state, props) => `${props.driverId}_${props.businessObjectSpecId}`);

interface ColumnProps {
  columnId: string;
  columnType: ColumnType;
  layerId?: string;
}
interface CommonColumnProperties {
  name?: string;
}
type ColumnEntityOutput =
  | ({ type: 'driver'; driver: Driver | undefined } & CommonColumnProperties)
  | ({
      type: 'dimensionalProperty';
      property: DimensionalProperty | undefined;
    } & CommonColumnProperties)
  | ({ type: 'fieldSpec'; fieldSpec: BusinessObjectFieldSpec | undefined } & CommonColumnProperties)
  | undefined;

const cacheKeyForColumnForLayerSelector = (
  state: RootState,
  { layerId, columnId, columnType }: ColumnProps,
) => `${layerId}-${columnId}-${columnType}`;

export const objectSpecColumnEntitySelector: ParametricSelector<ColumnProps, ColumnEntityOutput> =
  createCachedSelector(
    (state: RootState, _: ColumnProps) => state,
    fieldSelector('columnId'),
    fieldSelector('columnType'),
    fieldSelector('layerId'),
    (state, columnId, columnType, layerId) => {
      if (columnType === 'driverProperty') {
        const driverPropertyById = driverPropertiesByIdSelector(state, { layerId });
        const driverProperty = safeObjGet(driverPropertyById[columnId]);
        if (driverProperty) {
          const driversById = driversByIdForLayerSelector(state, { layerId });
          const driver = safeObjGet(driversById[driverProperty.driverId]);
          return { type: 'driver' as const, driver, name: driver?.name };
        }
      } else if (columnType === 'dimensionalProperty') {
        const dimensionalPropertyById = dimensionalPropertiesByIdSelector(state, { layerId });
        const dimensionalProperty = safeObjGet(dimensionalPropertyById[columnId]);
        return {
          type: 'dimensionalProperty' as const,
          property: dimensionalProperty,
          name: dimensionalProperty?.name,
        };
      } else if (columnType === 'fieldSpec') {
        const fieldSpecById = businessObjectFieldSpecByIdSelector(state, { layerId });
        const fieldSpec = safeObjGet(fieldSpecById[columnId]);
        return { type: 'fieldSpec' as const, fieldSpec, name: fieldSpec?.name };
      }
      return undefined;
    },
  )(cacheKeyForColumnForLayerSelector);

export const objectSpecColumnEntityExistsSelector: ParametricSelector<ColumnProps, boolean> =
  createCachedSelector(objectSpecColumnEntitySelector, (columnEntity) => {
    let exists = false;
    if (columnEntity?.type === 'driver') {
      exists = columnEntity.driver != null;
    } else if (columnEntity?.type === 'fieldSpec') {
      exists = columnEntity.fieldSpec != null;
    } else if (columnEntity?.type === 'dimensionalProperty') {
      exists = columnEntity.property != null;
    }
    return exists;
  })(cacheKeyForColumnForLayerSelector);

export const subdriverForObjectSelector: ParametricSelector<
  { businessObjectId: BusinessObjectId; driverPropertyId: DriverPropertyId },
  DriverId | undefined
> = createCachedSelector(
  fieldSelector<{ businessObjectId: BusinessObjectId }, 'businessObjectId'>('businessObjectId'),
  (
    state: RootState,
    {
      driverPropertyId,
    }: { businessObjectId: BusinessObjectId; driverPropertyId: DriverPropertyId },
  ) => driverPropertySelector(state, driverPropertyId),
  dimensionalPropertyEvaluatorSelector,
  (businessObjectId, dimDriverProperty, dimensionalPropertyEvaluator) => {
    const dimDriverId = dimDriverProperty?.driverId;
    if (dimDriverId == null) {
      return undefined;
    }

    const attributes =
      dimensionalPropertyEvaluator.getKeyAttributePropertiesForBusinessObject(businessObjectId);

    return dimensionalPropertyEvaluator.getSubDriverIdForAttributeIds(
      dimDriverId,
      attributes.map((attr) => attr.attribute.id),
    );
  },
)((state, props) => `${props.businessObjectId}.${props.driverPropertyId}`);

export const objectKeyAttributeCountsByDimDriverIdForObjectSpecSelector: ParametricSelector<
  BusinessObjectSpecId,
  Record<DriverId, Record<string | 'none', number>>
> = createSelector(
  (state: RootState, specId: BusinessObjectSpecId) =>
    businessObjectsBySpecIdForLayerSelector(state)[specId],
  (state: RootState, specId: BusinessObjectSpecId) =>
    driverPropertiesForBusinessObjectSpecIdSelector(state, specId),
  dimensionalPropertyEvaluatorSelector,
  (objects, driverProperties, dimensionalPropertyEvaluator) => {
    const out: Record<DriverId, Record<string | 'none', number>> = {};

    for (const object of objects) {
      const keyAttributes = dimensionalPropertyEvaluator.getKeyAttributePropertiesForBusinessObject(
        object.id,
      );
      const key = getSubdriverKey(keyAttributes.map((attr) => attr.attribute.id));

      for (const { driverId: dimDriverId } of driverProperties) {
        out[dimDriverId] ??= {};
        out[dimDriverId][key] ??= 0;
        ++out[dimDriverId][key];
      }
    }

    return out;
  },
);
