import { Tooltip } from '@chakra-ui/react';
import React, { useCallback } from 'react';

import { GlobalNavIcon, TOOLTIP_OPEN_DELAY } from 'components/RunwayApp/GlobalNavigationMenu';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useControlledPopover from 'hooks/useControlledPopover';
import { toggleCollapsed } from 'reduxStore/reducers/navigationSidebarSlice';
import { isNavigationSidebarCollapsedSelector } from 'selectors/navigationSidebarSelector';
import SidebarIcon from 'vectors/Sidebar';

const ExpandCollapseNavigationSidebarButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const isNavigationSidebarCollapsed = useAppSelector(isNavigationSidebarCollapsedSelector);

  const { isOpen } = useControlledPopover();

  const toggleSidebarExpansion = useCallback(() => {
    dispatch(toggleCollapsed());
  }, [dispatch]);

  return (
    <Tooltip
      label={isNavigationSidebarCollapsed ? 'Expand sidebar' : 'Collapse sidebar'}
      placement="bottom-end"
      openDelay={TOOLTIP_OPEN_DELAY}
      closeOnPointerDown
      isDisabled={isOpen}
    >
      <GlobalNavIcon
        icon={<SidebarIcon height="17px" width="16px" paddingY="2px" paddingX="1px" />}
        aria-label={isNavigationSidebarCollapsed ? 'Expand sidebar' : 'Collapse sidebar'}
        onClick={toggleSidebarExpansion}
      />
    </Tooltip>
  );
};

export default ExpandCollapseNavigationSidebarButton;
