import { uuidv4 } from 'helpers/uuidv4';
import useAppSelector from 'hooks/useAppSelector';
import { Attribute } from 'reduxStore/models/dimensions';
import { dimensionsByIdSelector } from 'selectors/dimensionsSelector';
import { AttributeFilters } from 'types/formula';

export function useEmptyAttributesFromFilters(filters: AttributeFilters | undefined): Attribute[] {
  const dimById = useAppSelector((state) => dimensionsByIdSelector(state));

  if (filters == null) {
    return [];
  }

  const dimIdsForPseudoAttrs = Object.keys(filters).filter((key) => filters[key].length === 0);
  return dimIdsForPseudoAttrs.map((dimId) => ({
    id: uuidv4(),
    dimensionId: dimId,
    deleted: false,
    type: 'Pseudo',
    value: 'No ' + dimById[dimId].name,
  }));
}
