import { DEFAULT_EVENT_GROUP_ID, EventGroupId } from 'reduxStore/models/events';

// Every new event belongs to an event group, and there are three cases we handle:
// Putting the new event in an existing group, an explicitly created new group,
// or the default group.
export type WithEventGroup =
  | {
      type: 'existing';
      eventGroupId: EventGroupId;
    }
  | {
      type: 'new';
      newEventGroup: {
        id: string;
        name: string;
      };
    }
  | {
      type: 'default';
    };

export function eventGroupIdFromWithEventGroup(
  withEventGroup: WithEventGroup | undefined,
): EventGroupId | undefined {
  if (withEventGroup == null) {
    return undefined;
  }

  if (withEventGroup.type === 'existing') {
    return withEventGroup.eventGroupId;
  }

  if (withEventGroup.type === 'new') {
    return withEventGroup.newEventGroup.id;
  }

  if (withEventGroup.type === 'default') {
    return DEFAULT_EVENT_GROUP_ID;
  }

  return undefined;
}
