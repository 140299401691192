import { createSelector } from '@reduxjs/toolkit';
import uniq from 'lodash/uniq';
import { createCachedSelector } from 're-reselect';

import { DEFAULT_DRIVER_FORMAT } from 'config/drivers';
import { DriverFormat } from 'generated/graphql';
import {
  getDriverFormat,
  getObjectSpecFormat,
} from 'helpers/formulaEvaluation/DriverFormatResolver/DriverFormatEvaluator';
import { isNotNull } from 'helpers/typescript';
import { BlockId } from 'reduxStore/models/blocks';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { blockDriverIdsSelector } from 'selectors/blocksSelector';
import { businessObjectFieldSpecSelector } from 'selectors/businessObjectFieldSpecsSelector';
import { blockIdSelector } from 'selectors/constSelectors';
import { driverSelector, driversByIdForLayerSelector } from 'selectors/driversSelector';
import { ParametricSelector } from 'types/redux';

// This needs to be re-run not only when the driver itself changes, but also when
// the resolved format changes. An example is: If Driver A (with format "auto") depends
// on Driver B, and the format of Driver B changes, then the format of
// Driver A should be updated as well, even if Driver A itself didn't change.
export const resolvedDriverFormatSelector = createSelector(
  (state: RootState, driverId: string) => {
    const driver = driverSelector(state, { id: driverId });
    return getDriverFormat(driver);
  },
  function (driverFormat) {
    return driverFormat;
  },
);

export const resolvedGeneralFormatFromBlockIdSelector: ParametricSelector<BlockId, DriverFormat> =
  createCachedSelector(
    (state: RootState) => driversByIdForLayerSelector(state),
    blockDriverIdsSelector,
    (drivers, driverIdsForBlock) => {
      if (driverIdsForBlock == null || driverIdsForBlock.length === 0) {
        return DEFAULT_DRIVER_FORMAT;
      }

      const driverFormats = driverIdsForBlock
        .map((driverId) => {
          const driver = drivers[driverId];
          return getDriverFormat(driver);
        })
        .filter(isNotNull);

      if (uniq(driverFormats).length > 1) {
        return DriverFormat.Number;
      } else {
        return driverFormats[0];
      }
    },
  )(blockIdSelector);

// See documentation for resolvedDriverFormatSelector, about why this selector
// can't just depend on the field spec itself, but needs to depend on the resolved
// format
export const resolvedBusinessObjectFieldSpecFormatSelector: ParametricSelector<
  BusinessObjectFieldSpecId,
  DriverFormat
> = createSelector(
  (state: RootState, fieldSpecId: BusinessObjectFieldSpecId) => {
    const fieldSpec = businessObjectFieldSpecSelector(state, { id: fieldSpecId });
    return getObjectSpecFormat(fieldSpec);
  },
  function resolvedBusinessObjectFieldSpecFormatSelector(fieldSpecFormat) {
    return fieldSpecFormat;
  },
);
