import sizeof from 'object-sizeof';
import prettyBytes from 'pretty-bytes';

import { DataArbiter } from 'components/AgGridComponents/helpers/gridDatasource/DataArbiter';
import { DatasetSliceState } from 'reduxStore/reducers/datasetSlice';
import { ReduxStore } from 'reduxStore/store';

type SizeEntry = string | { totalSize: string; sizes: NullableRecord<string, SizeEntry> };

export const getDatasetSize = (
  dataset: DatasetSliceState,
): {
  totalSizeInBytes: number;
  totalSize: string;
  sizes: NullableRecord<string, SizeEntry>;
} => {
  const datasetSizes: NullableRecord<string, SizeEntry> = {};
  let totalDatasetSize = 0;

  const { layers, snapshots, ...rest } = dataset;

  for (const layer of Object.values(layers)) {
    const sizes: NullableRecord<string, string> = {};
    let totalSize = 0;

    for (const [key, value] of Object.entries(layer)) {
      const size = sizeof(value);
      sizes[key] = prettyBytes(size);
      totalSize += size;
    }

    datasetSizes[`layer.${layer.id}`] = {
      totalSize: prettyBytes(totalSize),
      sizes,
    };
    totalDatasetSize += totalSize;
  }

  for (const [id, snapshot] of Object.entries(snapshots)) {
    const sizes: NullableRecord<string, string> = {};
    let totalSize = 0;

    for (const [key, value] of Object.entries(snapshot as object)) {
      const size = sizeof(value);
      sizes[key] = prettyBytes(size);
      totalSize += size;
    }

    datasetSizes[`snapshot.${id}`] = {
      totalSize: prettyBytes(totalSize),
      sizes,
    };
    totalDatasetSize += totalSize;
  }

  for (const [key, entry] of Object.entries(rest)) {
    const size = sizeof(entry);
    datasetSizes[key] = prettyBytes(size);
    totalDatasetSize += size;
  }

  return {
    totalSize: prettyBytes(totalDatasetSize),
    totalSizeInBytes: totalDatasetSize,
    sizes: datasetSizes,
  };
};

export const initMemoryHelpers = (store: ReduxStore) => {
  const helpers = {
    getStoreMemoryOverhead() {
      const s = store.getState();
      let totalStoreSizeInBytes = 0;
      const sizes: NullableRecord<string, SizeEntry> = {};

      const calculationsSize = sizeof(s.calculations);
      sizes.calculations = prettyBytes(calculationsSize);
      totalStoreSizeInBytes += calculationsSize;

      const dataArbiterSize = DataArbiter.get().getCacheSize();
      sizes.dataArbiterCache = prettyBytes(dataArbiterSize);
      totalStoreSizeInBytes += calculationsSize;

      const datasetSize = getDatasetSize(s.dataset);
      sizes.dataset = {
        totalSize: datasetSize.totalSize,
        sizes: datasetSize.sizes,
      };
      totalStoreSizeInBytes += datasetSize.totalSizeInBytes;

      const sharingSize = sizeof(s.sharing);
      sizes.sharing = prettyBytes(sharingSize);
      totalStoreSizeInBytes += sharingSize;

      // eslint-disable-next-line no-console
      console.log({
        sizes,
        totalStoreSize: prettyBytes(totalStoreSizeInBytes),
      });
    },
  };

  window.memoryHelpers = helpers;
  return helpers;
};

// ts-prune does not realize that this is used in a .d.ts file
// ts-prune-ignore-next
export type MemoryHelpers = NonNullable<ReturnType<typeof initMemoryHelpers>>;
