import React from 'react';

import { type FormulaReferenceSelectItem } from 'components/FormulaReferenceMenu/FormulaReferenceMenu';
import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import SelectMenu from 'components/SelectMenu/SelectMenu';
import SelectMenuFooter from 'components/SelectMenu/SelectMenuFooter';
import SelectMenuContainer from 'components/SelectMenuContainer/SelectMenuContainer';
import { MATH_OPERATOR_SELECT_ITEMS } from 'config/formula';
import FormulaIcon from 'vectors/Formula';

const FUNCTION_SELECT_ITEMS = MATH_OPERATOR_SELECT_ITEMS.map<FormulaReferenceSelectItem>(
  ({ operator, name, help }) => ({
    type: 'math' as const,
    id: operator,
    name,
    icon: <FormulaIcon />,
    footer: <SelectMenuFooter icon={<FormulaIcon />} title={help.title} subtitle={help.subtitle} />,
    operator,
    shortcut: 'enter',
    sectionId: 'math',
  }),
);

interface Props {
  onSelect: (item: FormulaReferenceSelectItem) => void;
}

const FunctionsSelectMenu: React.FC<Props> = ({ onSelect }) => {
  return (
    <SelectMenuContainer>
      <SelectMenu items={FUNCTION_SELECT_ITEMS} onSelect={onSelect} width="18rem" tabToSelect>
        {BaseSelectMenuItem}
      </SelectMenu>
    </SelectMenuContainer>
  );
};

export default React.memo(FunctionsSelectMenu);
