import {
  Box,
  Center,
  Flex,
  Menu,
  MenuButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from '@chakra-ui/react';
import humanizeDuration from 'humanize-duration';
import Joi from 'joi';
import { useCallback, useContext, useMemo } from 'react';
import { isDesktop } from 'react-device-detect';

import EmojiIcon from 'components/EmojiWidget/EmojiIcon';
import EmojiWidget from 'components/EmojiWidget/EmojiWidget';
import EscapableInput from 'components/EscapableInput/EscapableInput';
import { ICON_BORDER_RADIUS } from 'components/RunwayApp/GlobalNavigationMenu';
import DefaultScenarioIcon, {
  DEFAULT_SCENARIO_ICON_COLOR,
} from 'components/ScenarioManagement/DefaultScenarioIcon';
import MainScenarioTag from 'components/ScenarioManagement/MainScenarioTag';
import { SCENARIO_BUTTON_HEIGHT } from 'components/ScenarioManagement/ScenarioManagement';
import { ScenarioContext } from 'components/ScenarioManagement/ScenariosContextProvider';
import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import SelectMenu, { SelectItem } from 'components/SelectMenu/SelectMenu';
import { getDiffFromNow } from 'helpers/dates';
import { addEmoji, extractEmoji } from 'helpers/emoji';
import { joiDefault } from 'helpers/errorMessages';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useControlledPopover from 'hooks/useControlledPopover';
import { navigateToLayer } from 'reduxStore/actions/navigateTo';
import { DEFAULT_LAYER_ID, LayerId } from 'reduxStore/models/layers';
import { currentPageIdWithSubmodelsSelector } from 'selectors/blocksPagesSelector';
import { enableBranchingFromLayersSelector } from 'selectors/launchDarklySelector';
import { currentUserPageAccessibleLayerIdsSelector } from 'selectors/layerAccessResourcesSelector';
import {
  currentLayerIdSelector,
  currentLayerIsDraftOfDefaultLayerSelector,
  currentLayerIsDraftSelector,
  currentLayerNameSelector,
  currentLayerWasCreatedByUser,
  draftLayersSelector,
  layerCreatedAtByIdSelector,
  layerNameByIdSelector,
  layerSelector,
  layersSelector,
} from 'selectors/layerSelector';
import { publishedLayersSelector } from 'selectors/layersAccessResourcesSelector';
import { pageNonDraftScenarioNamesSelector } from 'selectors/scenariosSelector';
import { usersInSelectedOrgByIdSelector } from 'selectors/selectedOrgSelector';
import LockIcon from 'vectors/Lock';
import PlusIcon from 'vectors/Plus';

export interface ScenarioItem extends SelectItem {
  isHidden: boolean;
  onSelect: (() => void) | null;
  parentLayerId?: LayerId;
}

const ALL_SCENARIOS_SECTION_ID = 'allScenarios';
const CURRENT_SCENARIO_SECTION_ID = 'currentScenario';

const DRAFT_EMOJI_HOVER_BG_COLOR = '#E6D1A8';

const LayerFooter: React.FC<{ layerId: LayerId }> = ({ layerId }) => {
  const layer = useAppSelector((state) => layerSelector(state, layerId));
  const usersById = useAppSelector(usersInSelectedOrgByIdSelector);
  const creator = layer?.createdByUserId != null ? usersById[layer.createdByUserId] : null;
  const { createdAt } = layer;
  const createdByUser = useAppSelector(currentLayerWasCreatedByUser);
  const creatorName = createdByUser ? 'you' : creator?.name;

  const text = useMemo(() => {
    let t = 'Created';
    if (creatorName != null) {
      t += ` by ${creatorName}`;
    }

    const age = getDiffFromNow(createdAt);
    const humanized = humanizeDuration.humanizer({
      largest: 1,
      round: true,
    })(age.toMillis());

    t += ` ${humanized} ago`;

    return t;
  }, [creatorName, createdAt]);

  if (layer == null) {
    return null;
  }

  return (
    <Text fontSize="xxs" fontWeight="medium">
      {text}
    </Text>
  );
};

interface Props {
  isCreatingNewLayer: boolean;
  onSave: (name: string) => void;
  reset: () => void;
  onNewLayerCreated: () => void;
  onDoubleClick: () => void;
}

const ScenarioSwitcher: React.FC<Props> = ({
  isCreatingNewLayer,
  onSave,
  onNewLayerCreated,
  reset,
  onDoubleClick,
}) => {
  const dispatch = useAppDispatch();
  const { isOpen, onToggle, onClose, contentRef, triggerRef } = useControlledPopover();
  const { isEditingCurrentLayerName, isUndraftingLayer } = useContext(ScenarioContext);

  const enableBranchingFromLayers = useAppSelector(enableBranchingFromLayersSelector);

  const currentLayerId = useAppSelector(currentLayerIdSelector);
  const currentLayerName = useAppSelector(currentLayerNameSelector);
  const [currentLayerEmoji, currentLayerDisplayName] = extractEmoji(currentLayerName);

  const currentLayerIsDraft = useAppSelector(currentLayerIsDraftSelector);
  const currentLayerIsDraftOfDefault = useAppSelector(currentLayerIsDraftOfDefaultLayerSelector);

  const layerNamesById = useAppSelector(layerNameByIdSelector);
  const layersCreatedAtById = useAppSelector(layerCreatedAtByIdSelector);

  const publishedLayers = useAppSelector(publishedLayersSelector);

  const nonDraftLayerNamesById = useAppSelector(pageNonDraftScenarioNamesSelector);
  const nonDraftLayerIds = Object.keys(nonDraftLayerNamesById);

  const draftLayers = useAppSelector(draftLayersSelector);
  const draftLayerIds = Object.keys(draftLayers);

  const allLayersInOrg = useAppSelector(layersSelector);

  const currentPageId = useAppSelector(currentPageIdWithSubmodelsSelector);

  const accessiblePageLayerIds = useAppSelector((state) =>
    currentUserPageAccessibleLayerIdsSelector(state, currentPageId ?? ''),
  );

  const onCreateNewLayer = useCallback(() => {
    onNewLayerCreated?.();
  }, [onNewLayerCreated]);

  const nonDraftScenarios = useMemo<Array<Pick<ScenarioItem, 'id' | 'parentLayerId'>>>(() => {
    return nonDraftLayerIds
      .sort((layerId1, layerId2) => {
        // default layer is always at the top
        if (layerId1 === DEFAULT_LAYER_ID) {
          return -1;
        }
        return (
          getDiffFromNow(layersCreatedAtById[layerId1]).toMillis() -
          getDiffFromNow(layersCreatedAtById[layerId2]).toMillis()
        );
      })
      .map((layerId) => {
        return {
          id: layerId,
          parentLayerId: allLayersInOrg[layerId].parentLayerId ?? DEFAULT_LAYER_ID,
        };
      })
      .filter((value) => value !== null);
  }, [nonDraftLayerIds, layersCreatedAtById, allLayersInOrg]);

  const draftScenarios = useMemo<Array<Pick<ScenarioItem, 'id' | 'parentLayerId'>>>(() => {
    return draftLayerIds.map((layerId) => {
      return {
        id: layerId,
        parentLayerId: draftLayers[layerId].parentLayerId ?? DEFAULT_LAYER_ID,
      };
    });
  }, [draftLayerIds, draftLayers]);

  const allAccessibleLayers = useMemo(() => {
    const accessiblePageLayerIdsSet = new Set(accessiblePageLayerIds);

    const allLayers = [...nonDraftScenarios, ...draftScenarios].map((layer) => {
      const { id: layerId } = layer;
      const layerName = layerNamesById[layerId];
      const [emoji, displayName] = extractEmoji(layerName);
      const isChecked = currentLayerId === layerId;
      const isDraft = draftLayers[layerId] != null;
      const isLocked = isDraft || publishedLayers[layerId] == null;
      const scenarioItem: ScenarioItem = {
        id: layerId,
        parentLayerId: layer.parentLayerId,
        name: displayName,
        meta:
          layerId === DEFAULT_LAYER_ID ? (
            <MainScenarioTag />
          ) : isLocked ? (
            <LockIcon sx={{ color: 'gray.500', _parentAriaSelected: { color: 'white' } }} />
          ) : undefined,
        icon: isChecked ? undefined : (
          <EmojiIcon size="sm" emoji={emoji} emptyIcon={<DefaultScenarioIcon color="inherit" />} />
        ),
        isChecked,
        iconColor: DEFAULT_SCENARIO_ICON_COLOR,
        checkedStyle: isChecked ? 'check' : 'none',
        onSelect: () => {
          dispatch(navigateToLayer(layerId));
        },
        sectionId: ALL_SCENARIOS_SECTION_ID,
        isHidden: false,
        footer: <LayerFooter layerId={layerId} />,
      };

      return scenarioItem;
    });

    if (currentPageId == null) {
      return allLayers;
    }

    const draftParentLayerIds = new Set(draftScenarios.map((draft) => draft.parentLayerId));

    return allLayers.filter(
      (layer) =>
        accessiblePageLayerIdsSet.has(layer.id) &&
        // Only show layers that don't have a child draft layer
        !draftParentLayerIds.has(layer.id),
    );
  }, [
    accessiblePageLayerIds,
    nonDraftScenarios,
    draftScenarios,
    currentPageId,
    layerNamesById,
    currentLayerId,
    draftLayers,
    publishedLayers,
    dispatch,
  ]);

  const scenarioMenuItems = useMemo(() => {
    const allItems: ScenarioItem[] = [
      ...allAccessibleLayers,
      {
        id: 'addScenario',
        name: (
          <Text sx={{ color: 'gray.500', _parentAriaSelected: { color: 'white' } }}>
            Add scenario
          </Text>
        ),
        icon: <PlusIcon />,
        iconColor: 'gray.500',
        isHidden: enableBranchingFromLayers && currentLayerIsDraft,
        onSelect: () => onCreateNewLayer(),
        sectionId: ALL_SCENARIOS_SECTION_ID,
      },
    ];
    return allItems.filter((item) => !item.isHidden);
  }, [allAccessibleLayers, currentLayerIsDraft, enableBranchingFromLayers, onCreateNewLayer]);

  const onSelect = useCallback(
    (item: ScenarioItem) => {
      item.onSelect?.();
      onClose();
    },
    [onClose],
  );

  // We require users to first type in a name before they can pick an emoji, otherwise
  // can get into state where name is only an emoji with no subsequent text. We also disable
  // editing the emoji on the default layer or a draft of the default layer.
  const isEditEmojiDisabled =
    currentLayerIsDraftOfDefault || isCreatingNewLayer || isUndraftingLayer;

  const onSaveNameCallback = useCallback(
    (newLayerDisplayName: string): void => {
      const emoji = isEditEmojiDisabled ? null : currentLayerEmoji;
      onSave(addEmoji(emoji, newLayerDisplayName));
    },
    [currentLayerEmoji, isEditEmojiDisabled, onSave],
  );

  const onSelectEmojiCallback = useCallback(
    (newEmoji: string | null): void => {
      onSave(addEmoji(newEmoji, currentLayerDisplayName));
    },
    [onSave, currentLayerDisplayName],
  );

  const sections = useMemo(() => {
    return [
      {
        id: ALL_SCENARIOS_SECTION_ID,
      },
      {
        id: CURRENT_SCENARIO_SECTION_ID,
        name: currentLayerName,
      },
    ];
  }, [currentLayerName]);

  const nameValidator = useMemo(() => {
    const otherLayerNames = Object.entries(nonDraftLayerNamesById)
      .filter(([id]) => isCreatingNewLayer || id !== currentLayerId)
      .map(([_id, name]) => name);

    return Joi.object({
      layerName: Joi.string()
        .required()
        .insensitive()
        .invalid(...otherLayerNames)
        .min(1)
        .trim()
        .messages(joiDefault('scenario')),
    });
  }, [currentLayerId, isCreatingNewLayer, nonDraftLayerNamesById]);

  const backgroundColor = !isEditingCurrentLayerName ? 'white' : 'inherit';

  return (
    <Popover isOpen={isOpen} isLazy placement="bottom-start">
      <PopoverTrigger>
        <Flex
          ref={triggerRef}
          gap="1px"
          paddingLeft="2px"
          paddingRight="6px"
          borderRadius={ICON_BORDER_RADIUS}
          onClick={onToggle}
        >
          {isEditEmojiDisabled ? (
            <Center borderRadius="md" w={6} h={6}>
              <EmojiIcon emoji={null} size="sm" emptyIcon={<DefaultScenarioIcon />} />
            </Center>
          ) : (
            <EmojiWidget
              emoji={currentLayerEmoji}
              size="sm"
              onChange={onSelectEmojiCallback}
              emptyIcon={<DefaultScenarioIcon />}
              borderRadius="4px"
              hoverBgColor={currentLayerIsDraft ? DRAFT_EMOJI_HOVER_BG_COLOR : undefined}
              activeBgColor={currentLayerIsDraft ? DRAFT_EMOJI_HOVER_BG_COLOR : undefined}
            />
          )}
          <Flex alignItems="center" onDoubleClick={onDoubleClick}>
            <Menu variant="light" isOpen={isOpen}>
              <MenuButton
                as={Box}
                data-testid="scenario-switcher-button"
                className="electron-no-drag"
                cursor="pointer"
                userSelect="none"
                height={SCENARIO_BUTTON_HEIGHT}
                display="flex"
                alignItems="center"
              >
                {isEditingCurrentLayerName ? (
                  <EscapableInput
                    key={currentLayerId} // N.B. get a new component when the layer switches, for correct selectOnFocus behavior
                    autoSizing
                    validationSchema={nameValidator}
                    onSave={onSaveNameCallback}
                    onCancel={reset}
                    isRequired
                    name="layerName"
                    variant="inline"
                    placeholder={isCreatingNewLayer ? 'New scenario' : 'Scenario name'}
                    defaultValue={
                      isCreatingNewLayer || isUndraftingLayer ? '' : currentLayerDisplayName
                    }
                    autoFocus
                    fontSize="xs"
                    fontWeight="medium"
                    borderWidth={0}
                    bgColor={backgroundColor}
                    height={SCENARIO_BUTTON_HEIGHT}
                  />
                ) : (
                  <Text lineHeight="initial" p={0} fontSize="xs" fontWeight="medium" noOfLines={1}>
                    {isDesktop ? currentLayerDisplayName : undefined}
                  </Text>
                )}
              </MenuButton>
            </Menu>
          </Flex>
        </Flex>
      </PopoverTrigger>
      <Portal>
        <PopoverContent ref={contentRef} p={0}>
          <SelectMenu
            items={scenarioMenuItems}
            startFocusIdx={-1}
            sections={sections}
            onSelect={onSelect}
            width={enableBranchingFromLayers ? '365px' : '14rem'}
            maxHeight="30rem"
          >
            {BaseSelectMenuItem}
          </SelectMenu>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default ScenarioSwitcher;
