import React, { useContext } from 'react';

import DateRangePill from 'components/FormulaInput/DateRangePill';
import FormulaSelectionContext from 'components/FormulaInput/FormulaSelectionContext';
import ReferencePill from 'components/FormulaInput/ReferencePill';
import useFormulaPillMouseDown from 'hooks/useFormulaPillMouseDown';
import { DraftDecoratorComponentProps, ThisSegmentMetadata } from 'types/formula';
import NumberSignIcon from 'vectors/NumberSign';

const ThisSegmentDecorator: React.FC<DraftDecoratorComponentProps> = ({
  contentState,
  entityKey,
  offsetKey,
}) => {
  const { activeEntity, keysInSelection } = useContext(FormulaSelectionContext);
  const entity = contentState.getEntity(entityKey ?? '');
  const { label, dateRangeDisplay, error } = entity.getData() as ThisSegmentMetadata;
  const isSelectedEntity = activeEntity?.key === entityKey;

  const onNameMouseDown = useFormulaPillMouseDown(entityKey, entity, { submenu: 'thisSegment' });
  const onDateMouseDown = useFormulaPillMouseDown(entityKey, entity, { submenu: 'timePeriod' });

  if (entityKey == null) {
    return null;
  }

  const isSelected = isSelectedEntity || keysInSelection.has(entityKey);
  return (
    <ReferencePill
      isSelected={isSelected}
      error={error}
      label={label}
      icon={<NumberSignIcon color="gray.500" />}
      offsetKey={offsetKey}
      onMouseDown={onNameMouseDown}
    >
      {dateRangeDisplay != null && (
        <DateRangePill
          onMouseDown={onDateMouseDown}
          label={dateRangeDisplay}
          isSelected={isSelected}
        />
      )}
    </ReferencePill>
  );
};

export default React.memo(ThisSegmentDecorator);
