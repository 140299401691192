import keyBy from 'lodash/keyBy';
import { createCachedSelector } from 're-reselect';

import { DEFAULT_OBJECT_FIELD_COLUMN_WIDTH } from 'config/businessObjects';
import { CELL_DATA_COLUMN_WIDTH_IN_PX } from 'config/cells';
import {
  DRIVER_COLUMN_TYPES,
  INITIAL_VALUE_COLUMN_TYPE,
  MODEL_VIEW_DATA_COLUMN_TYPE,
  NAME_COLUMN_TYPE,
  PROPERTY_COLUMN_TYPE,
} from 'config/modelView';
import { BlockColumnOptions, BlockType, ObjectSpecDisplayAsType } from 'generated/graphql';
import { toSortedArray } from 'helpers/array';
import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import { BlockId, OBJECT_TABLE_BLOCK_NAME_COLUMN_KEY } from 'reduxStore/models/blocks';
import { isDataColumnType } from 'reduxStore/reducers/helpers/submodels';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import {
  blockConfigObjectFieldSpecAsTimeSeriesIdSelector,
  blockConfigObjectSpecDisplayAsSelector,
  blockConfigSelector,
  blockTypeSelector,
} from 'selectors/blocksSelector';
import {
  isNewDimensionalTableSelector,
  orderedColumnKeysForObjectTableSelector,
} from 'selectors/collectionBlocksSelector';
import { driverPropertiesByIdSelector } from 'selectors/collectionSelector';
import { blockIdSelector, paramSelector } from 'selectors/constSelectors';
import { ParametricSelector } from 'types/redux';

const DATABASE_TIMESERIES_EXTRA_COLS = [PROPERTY_COLUMN_TYPE, INITIAL_VALUE_COLUMN_TYPE];
const OBJECT_DETAIL_PANE_COLS = [PROPERTY_COLUMN_TYPE, INITIAL_VALUE_COLUMN_TYPE];

const getDatabaseViewObjectTableColumns = (
  blockConfigColumnsByKey: NullableRecord<string, BlockColumnOptions>,
  orderedObjectTableColumns: string[],
  objectTableDisplayAs: ObjectSpecDisplayAsType,
  isDimensionalTable: boolean,
  driverPropertyShownAsTimeSeries: boolean,
) => {
  const timeSeriesFieldSpecFields = driverPropertyShownAsTimeSeries
    ? ['actualsFormula', 'formula']
    : [];
  const columns = [
    OBJECT_TABLE_BLOCK_NAME_COLUMN_KEY,
    ...(objectTableDisplayAs === ObjectSpecDisplayAsType.Timeseries
      ? DATABASE_TIMESERIES_EXTRA_COLS
      : []),
    ...orderedObjectTableColumns,
    ...timeSeriesFieldSpecFields,
  ];

  const mappedColumns = columns.map((key) => {
    const savedColumn = blockConfigColumnsByKey[key];

    const isAlwaysVisibleColumn = [
      ...timeSeriesFieldSpecFields,
      NAME_COLUMN_TYPE,
      ...(objectTableDisplayAs === ObjectSpecDisplayAsType.Timeseries
        ? [PROPERTY_COLUMN_TYPE]
        : []),
    ].includes(key);

    // the initial value column is hidden by default
    const isInitialValueColumn = key === INITIAL_VALUE_COLUMN_TYPE;

    return {
      key,
      visible: isAlwaysVisibleColumn || (savedColumn?.visible ?? !isInitialValueColumn),
      width: savedColumn?.width ?? DEFAULT_OBJECT_FIELD_COLUMN_WIDTH,
    };
  });

  // TODO: this check is a temporary way of enabling ID cells for dimensional tables.
  // This flag effectively turns the name cell into an ID cell with constant width.
  if (isDimensionalTable) {
    const column = mappedColumns.find((c) => c.key === NAME_COLUMN_TYPE);
    if (column) {
      column.width = CELL_DATA_COLUMN_WIDTH_IN_PX;
    }
  }

  return mappedColumns;
};

export const getDriverColumns = (
  columnsByKey: NullableRecord<string, BlockColumnOptions>,
  configColumns: BlockColumnOptions[],
) => {
  const columnOrder = [
    NAME_COLUMN_TYPE,
    ...configColumns
      .filter((c) => c.key !== NAME_COLUMN_TYPE && c.key !== MODEL_VIEW_DATA_COLUMN_TYPE)
      .map((c) => c.key),
    MODEL_VIEW_DATA_COLUMN_TYPE,
  ];

  const sortedColumnTypes = toSortedArray(DRIVER_COLUMN_TYPES, columnOrder);

  return sortedColumnTypes.map((colType) => columnsByKey[colType] ?? { key: colType });
};

const getObjectDetailsViewColumns = (columnsByKey: NullableRecord<string, BlockColumnOptions>) => {
  return OBJECT_DETAIL_PANE_COLS.map(
    (colType) => columnsByKey[colType] ?? { key: colType, visible: true },
  );
};

export const configurableDatabaseViewObjectTableColumnsSelector: ParametricSelector<
  BlockId,
  BlockColumnOptions[]
> = createCachedSelector(
  blockConfigSelector,
  orderedColumnKeysForObjectTableSelector,
  blockConfigObjectSpecDisplayAsSelector,
  isNewDimensionalTableSelector,
  blockConfigObjectFieldSpecAsTimeSeriesIdSelector,
  driverPropertiesByIdSelector,
  (
    blockConfig,
    orderedObjectTableColumns,
    objectTableDisplayAs,
    isNewDimensionalTable,
    timeSeriesFieldSpecId,
    driverPropertiesById,
    // eslint-disable-next-line max-params
  ) => {
    const configColumns = (blockConfig?.columns ?? []).filter((col) => !isDataColumnType(col.key));
    const blockConfigColumnsByKey = keyBy(configColumns, (c) => c.key);

    const driverPropShownAsTimeSeries = driverPropertiesById[timeSeriesFieldSpecId ?? ''] != null;

    return getDatabaseViewObjectTableColumns(
      blockConfigColumnsByKey,
      orderedObjectTableColumns,
      objectTableDisplayAs,
      isNewDimensionalTable,
      driverPropShownAsTimeSeries,
    );
  },
)({
  keySelector: blockIdSelector,
  selectorCreator: createDeepEqualSelector,
});

export const configurableColumnsSelector: ParametricSelector<BlockId, BlockColumnOptions[]> =
  createCachedSelector(
    paramSelector<BlockId>(),
    blockTypeSelector,
    blockConfigSelector,
    (state: RootState) => state,
    (blockId, blockType, blockConfig, state) => {
      const configColumns = (blockConfig?.columns ?? []).filter(
        (col) => !isDataColumnType(col.key),
      );
      const columnsByKey = keyBy(configColumns, (c) => c.key);

      if (blockType === BlockType.ObjectTable) {
        return configurableDatabaseViewObjectTableColumnsSelector(state, blockId);
      }

      if (blockType === BlockType.ObjectGrid) {
        return getObjectDetailsViewColumns(columnsByKey);
      }

      if (blockType === BlockType.DriverGrid) {
        return getDriverColumns(columnsByKey, configColumns);
      }

      return configColumns;
    },
  )({
    keySelector: blockIdSelector,
    selectorCreator: createDeepEqualSelector,
  });
