import { Divider, Flex } from '@chakra-ui/react';
import React, { useCallback, useMemo, useState } from 'react';

import MultiSelectResultsList from 'components/SearchableMultiSelectMenu/MultiSelectResultsList';
import MultiSelectSearchBar from 'components/SearchableMultiSelectMenu/MultiSelectSearchBar';
import {
  SearchableMultiSelectMenuContext,
  SearchableMultiSelectMenuContextState,
} from 'components/SearchableMultiSelectMenu/SearchableMultiSelectMenuContext';
import SingleSelectSearchBar from 'components/SearchableMultiSelectMenu/SingleSelectSearchBar';
import { SelectItem } from 'components/SelectMenu/SelectMenu';
import { enqueue } from 'helpers/array';
import { stopEventPropagation } from 'helpers/browserEvent';
import { useListSearch } from 'hooks/useListSearch';
import { useRovingFocus } from 'hooks/useRovingFocus';

export type MultiSelectMenuItem = Omit<SelectItem, 'sectionId'> & {
  name: string;
  before?: React.ReactNode;
  after?: React.ReactNode;
};

const SEARCH_KEYS = ['name'];
export const MULTI_SELECT_CUSTOM_OPTION_ID = 'custom_option';

export interface CustomMultiSelectOption {
  position?: 'first' | 'last';
  onSelect: (query: string) => void;
  render: (props: { query: string; isFocused: boolean; idx: number }) => JSX.Element | null;
}

interface Props {
  items: MultiSelectMenuItem[];
  selectedItemIds: string[];
  onUpdateSelectedItemIds: (newItemIds: string[]) => void;
  children: Array<React.ReactElement | null>;
  onClose: () => void;

  includeSelectedItemsInResults?: boolean;
  /**
   * Max number of items that can be selected. If max number of
   * items are selected and user selects another item, remove the earliest
   * selected item and add the new item (FIFO). By default, this is undefined
   * which means that any number of items can be selected.
   */
  maxSelectedItems?: number;
  customOption?: CustomMultiSelectOption;
  initialQuery?: string;
  /**
   * custom options that appear when no query is entered. if not provided, the list
   * of all items will render.
   */
  defaultOptions?: MultiSelectMenuItem[];
}

const SearchableMultiSelectMenu: React.FC<Props> = ({
  items,
  selectedItemIds,
  onUpdateSelectedItemIds,
  children,
  onClose,
  includeSelectedItemsInResults = false,
  maxSelectedItems,
  customOption,
  initialQuery = '',
  defaultOptions,
}) => {
  const [query, setQuery] = useState(initialQuery);
  const filteredItems = useMemo(
    () =>
      includeSelectedItemsInResults
        ? items
        : items.filter((item) => !selectedItemIds.includes(item.id)),
    [includeSelectedItemsInResults, items, selectedItemIds],
  );
  const { results } = useListSearch<MultiSelectMenuItem>({
    items: filteredItems,
    query,
    searchKeys: SEARCH_KEYS,
    defaultResults: defaultOptions,
  });
  const resultsWithCustomOption = useMemo(() => {
    const newResults = [...results];
    if (customOption != null && query.length > 0) {
      const customOptionIdx = customOption.position === 'first' ? 0 : results.length;
      newResults.splice(customOptionIdx, 0, {
        id: MULTI_SELECT_CUSTOM_OPTION_ID,
        name: query,
      });
    }
    return newResults;
  }, [customOption, query, results]);
  const onSelectIdx = useCallback(
    (idx: number) => {
      const item = resultsWithCustomOption[idx];
      if (item == null) {
        return;
      }
      const { id } = item;
      if (id === MULTI_SELECT_CUSTOM_OPTION_ID && customOption != null) {
        customOption.onSelect(item.name);
      } else if (!selectedItemIds.includes(id)) {
        const newSelectedItems = enqueue(selectedItemIds, id, maxSelectedItems);
        onUpdateSelectedItemIds(newSelectedItems);
      }
      setQuery('');
    },
    [
      resultsWithCustomOption,
      customOption,
      selectedItemIds,
      maxSelectedItems,
      onUpdateSelectedItemIds,
    ],
  );
  const clearLastItem = useCallback(() => {
    if (selectedItemIds.length === 0) {
      return;
    }
    onUpdateSelectedItemIds(selectedItemIds.slice(0, -1));
  }, [onUpdateSelectedItemIds, selectedItemIds]);
  const { focusIdx, setFocusIdx, onKeyDown } = useRovingFocus(resultsWithCustomOption.length);
  const handleKeyDown = useCallback(
    (ev: KeyboardEvent | React.KeyboardEvent) => {
      onKeyDown(ev);
      switch (ev.key) {
        case 'Enter': {
          ev.preventDefault();
          if (focusIdx != null && resultsWithCustomOption[focusIdx] != null) {
            onSelectIdx(focusIdx);
          }
          break;
        }
        case 'Backspace': {
          if (query.length === 0) {
            clearLastItem();
          }
          break;
        }
        case 'Escape': {
          ev.preventDefault();
          onClose();
          break;
        }
        default: {
          break;
        }
      }
    },
    [
      clearLastItem,
      focusIdx,
      onClose,
      onKeyDown,
      onSelectIdx,
      query.length,
      resultsWithCustomOption,
    ],
  );
  const multiSelectContextValue = useMemo<SearchableMultiSelectMenuContextState>(() => {
    return {
      query,
      setQuery,
      onKeyDown: handleKeyDown,
      focusIdx,
      setFocusIdx,
      onSelectIdx,
      results: resultsWithCustomOption,
      itemCount: items.length,
      customOption,
    };
  }, [
    customOption,
    focusIdx,
    handleKeyDown,
    items.length,
    onSelectIdx,
    query,
    resultsWithCustomOption,
    setFocusIdx,
  ]);
  const searchBar = children.find(
    (child) => child?.type === MultiSelectSearchBar || child?.type === SingleSelectSearchBar,
  );
  const resultsList = children.find((child) => child?.type === MultiSelectResultsList);

  return (
    <SearchableMultiSelectMenuContext.Provider value={multiSelectContextValue}>
      <Flex flexDir="column" w="full" onKeyDown={stopEventPropagation}>
        {searchBar}
        {(searchBar?.type == null || searchBar?.type === MultiSelectResultsList) && <Divider />}
        {resultsList}
      </Flex>
    </SearchableMultiSelectMenuContext.Provider>
  );
};

export default SearchableMultiSelectMenu;
