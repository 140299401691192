import uniq from 'lodash/uniq';
import { createCachedSelector } from 're-reselect';

import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import {
  getDirectAndIndirectDependencies,
  getDriverIdsOfDependencies,
} from 'helpers/driverDependencies';
import { DependencyListenerEvaluator } from 'helpers/formulaEvaluation/DependenciesListenerEvaluator';
import { DimensionalPropertyEvaluator } from 'helpers/formulaEvaluation/DimensionalPropertyEvaluator';
import { EvaluatorDriver } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import { SelectorWithLayerParam, getCacheKeyForLayerSelector } from 'helpers/layerSelectorFactory';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { DriverId, DriverType } from 'reduxStore/models/drivers';
import { LayerId } from 'reduxStore/models/layers';
import { NumericTimeSeriesWithEmpty } from 'reduxStore/models/timeSeries';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { dimensionalPropertyEvaluatorSelector } from 'selectors/collectionSelector';
import { fieldSelector } from 'selectors/constSelectors';
import { dependenciesListenerEvaluatorSelector } from 'selectors/dependenciesListenerEvaluatorSelector';
import {
  getObjectCollectionKeyString,
  objectDependenciesSelectorForDetailPaneDriver,
  reverseDirectDriverDependencyFlattenedDimMatrixSelector,
} from 'selectors/dependenciesSelector';
import { mustDetailPaneDriverIdSelector } from 'selectors/driverDetailPaneSelector';
import { evaluatorDriversByIdForLayerSelector } from 'selectors/driversSelector';
import { ParametricSelector, Selector } from 'types/redux';

const getFlattenedDeps = (
  driverId: DriverId,
  driversById: NullableRecord<string, EvaluatorDriver | undefined>,
  evaluator: DependencyListenerEvaluator,
  dimensionalPropertyEvaluator: DimensionalPropertyEvaluator,
) => {
  // could be a deleted driver
  const driver = driversById[driverId];
  if (driver == null) {
    return [];
  }

  if (driver.type === DriverType.Dimensional) {
    return getDriverIdsOfDependencies({
      driver,
      evaluator,
      dimensionalPropertyEvaluator,
    });
  }

  return [driverId];
};

export const directDriverDependenciesSelector: Selector<DriverId[]> = createDeepEqualSelector(
  mustDetailPaneDriverIdSelector,
  evaluatorDriversByIdForLayerSelector,
  dependenciesListenerEvaluatorSelector,
  dimensionalPropertyEvaluatorSelector,
  (driverId, driversById, evaluator, dimensionalPropertyEvaluator) => {
    const driver = driversById[driverId];
    if (driver == null) {
      return [];
    }

    const directDeps = getDriverIdsOfDependencies({
      driver,
      evaluator,
      dimensionalPropertyEvaluator,
    }).filter((id) => id !== driverId);

    return directDeps
      .flatMap((id) => getFlattenedDeps(id, driversById, evaluator, dimensionalPropertyEvaluator))
      .filter((id) => id !== driverId);
  },
);

export const directDriverDependentsSelector: Selector<DriverId[]> = createDeepEqualSelector(
  mustDetailPaneDriverIdSelector,
  reverseDirectDriverDependencyFlattenedDimMatrixSelector,
  (driverId, reverseDirectDriverDependencyMatrix) => {
    return uniq(reverseDirectDriverDependencyMatrix[driverId] ?? []).filter(
      (id) => id !== driverId,
    );
  },
);

interface DriverDependencyCollectionIds {
  specId: BusinessObjectSpecId;
  fieldSpecId: BusinessObjectFieldSpecId | undefined;
  objectIds: BusinessObjectId[];
  // This is only populated if the evaluated field is a driver property
  subDriverIds?: DriverId[];
}

// Slice the big dependency data blob for a layer to get just the IDs we care about
export const driverObjectDependencyItemIdsSelector: SelectorWithLayerParam<
  Map<string, DriverDependencyCollectionIds>
> = createCachedSelector(objectDependenciesSelectorForDetailPaneDriver, (depData) => {
  const processed: Map<string, DriverDependencyCollectionIds> = new Map();
  for (const [
    key,
    { specId, fieldSpecId, objectTimeSeriesById, subDriverIds },
  ] of depData.entries()) {
    processed.set(key, {
      specId,
      fieldSpecId,
      objectIds: Object.keys(objectTimeSeriesById),
      subDriverIds,
    });
  }
  return processed;
})(getCacheKeyForLayerSelector);

interface ObjectCollectionKey {
  layerId?: LayerId;
  specId: BusinessObjectSpecId;
  fieldSpecId: BusinessObjectFieldSpecId | undefined;
}

interface ObjectKey extends ObjectCollectionKey {
  objectId: BusinessObjectId;
}

const EMPTY_RECORD = {};

// Slice the big dependency data blob to get specific time series for the
// aggregate collections for easy access in components.
export const driverObjectCollectionTimeSeriesSelector: ParametricSelector<
  ObjectCollectionKey,
  NumericTimeSeriesWithEmpty
> = createCachedSelector(
  fieldSelector<ObjectCollectionKey, 'specId'>('specId'),
  fieldSelector('fieldSpecId'),
  (state: RootState, key: ObjectCollectionKey) =>
    objectDependenciesSelectorForDetailPaneDriver(
      state,
      key.layerId != null ? { layerId: key.layerId } : undefined,
    ),
  (specId, fieldSpecId, dependencyEvalData) => {
    const depData = dependencyEvalData.get(getObjectCollectionKeyString(specId, fieldSpecId));
    return depData?.timeSeries ?? EMPTY_RECORD;
  },
)((_state, props) => `${props.specId},${props.fieldSpecId},${props.layerId}`);

// Slice the big dependency data blob to get specific time series for the
// object instances that are returned by driver formula filter for easy access
// in components.
export const driverObjectTimeSeriesSelector: ParametricSelector<
  ObjectKey,
  NumericTimeSeriesWithEmpty
> = createCachedSelector(
  fieldSelector<ObjectKey, 'specId'>('specId'),
  fieldSelector('fieldSpecId'),
  fieldSelector('objectId'),
  (state: RootState, key: ObjectKey) =>
    objectDependenciesSelectorForDetailPaneDriver(
      state,
      key.layerId != null ? { layerId: key.layerId } : undefined,
    ),
  (specId, fieldSpecId, objectId, dependencyEvalData) => {
    const depData = dependencyEvalData.get(getObjectCollectionKeyString(specId, fieldSpecId));
    return depData?.objectTimeSeriesById[objectId] ?? EMPTY_RECORD;
  },
)((_state, props) => `${props.specId},${props.fieldSpecId},${props.objectId},${props.layerId}`);

export const indirectDriverDependenciesSelector: Selector<DriverId[]> = createDeepEqualSelector(
  mustDetailPaneDriverIdSelector,
  evaluatorDriversByIdForLayerSelector,
  dependenciesListenerEvaluatorSelector,
  directDriverDependenciesSelector,
  dimensionalPropertyEvaluatorSelector,
  (driverId, driversById, evaluator, directDriverDependencies, dimensionalPropertyEvaluator) => {
    const driver = driversById[driverId];
    if (driver == null) {
      return [];
    }

    const directSet = new Set(directDriverDependencies);
    const directAndIndirect = uniq(
      getDirectAndIndirectDependencies({
        driver,
        driversById,
        evaluator,
        dimensionalPropertyEvaluator,
      }).filter((id) => id !== driverId && !directSet.has(id)),
    );

    return directAndIndirect
      .flatMap((id) => getFlattenedDeps(id, driversById, evaluator, dimensionalPropertyEvaluator))
      .filter((id) => id !== driverId);
  },
);
