import { Area } from '@visx/shape';
import clamp from 'lodash/clamp';
import last from 'lodash/last';
import React, { useMemo } from 'react';

import theme from 'config/theme';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import useChartActualsData from 'hooks/useChartActualsData';
import useChartContext from 'hooks/useChartContext';
import useChartForecastData from 'hooks/useChartForecastData';
import { useRequestCellValue } from 'hooks/useRequestCellValue';
import useSmoothedTimeseries from 'hooks/useSmoothedTimeSeries';
import { DriverId } from 'reduxStore/models/drivers';
import {
  entityLoadingForPageDateRangeSelector,
  impactLoadingForPageDateRangeSelector,
} from 'selectors/calculationsForPageDateRangeSelector';
import {
  driverTimeSeriesForImpactShadingSelector,
  driverTimeSeriesForLayerSelector,
  driverTimeSeriesSelector,
} from 'selectors/driverTimeSeriesSelector';
import { pageDateRangeDateTimeSelector } from 'selectors/pageDateRangeSelector';
import { selectedEventIdsMaybeWithRowEventsSelector } from 'selectors/planTimelineSelector';

const { colors } = theme;

interface Props {
  // Need last actual so we can attach the shaded area back to the actuals.
  driverId: DriverId;
  includeEventsInSameRow?: boolean;
}

const EventDriverImpact: React.FC<Props> = ({ driverId, includeEventsInSameRow = false }) => {
  const {
    valueScale,
    timeScale,
    format,
    startDateTime,
    endDateTime,
    monthKeysWithForecastData,
    baselineLayerId,
  } = useChartContext();
  const { blockId } = useBlockContext();

  const driverTimeSeries = useAppSelector((state) =>
    driverTimeSeriesSelector(state, {
      id: driverId,
    }),
  );
  const driverTimeSeriesLoading = useAppSelector((state) =>
    entityLoadingForPageDateRangeSelector(state, driverId),
  );

  // THIS IS SO STUPID
  const forecastDataWithoutSelectedEvent = useAppSelector((state) =>
    driverTimeSeriesForImpactShadingSelector(state, { driverId, blockId, includeEventsInSameRow }),
  );

  const ignoreEventIds = useAppSelector((state) =>
    selectedEventIdsMaybeWithRowEventsSelector(state, { blockId, includeEventsInSameRow }),
  );

  const impactLoading = useAppSelector((state) =>
    impactLoadingForPageDateRangeSelector(state, {
      ignoreEventIds,
      id: driverId,
    }),
  );
  const dateRange = useAppSelector((state) => pageDateRangeDateTimeSelector(state));
  useRequestCellValue({
    id: driverId,
    type: 'driver',
    requireBackendOnly: true,
    dateRange,
    ignoreEventIds,
  });

  const smoothedDriverTimeSeries = useSmoothedTimeseries(driverTimeSeries, driverTimeSeriesLoading);
  const smoothedDriverTimeSeriesWithoutSelectedEvent = useSmoothedTimeseries(
    forecastDataWithoutSelectedEvent,
    impactLoading,
  );

  const baselineTimeSeries = useAppSelector((state) =>
    driverTimeSeriesForLayerSelector(state, {
      id: driverId,
      layerId: baselineLayerId,
    }),
  );

  // Get time series data for actuals
  const actualsLineData = useChartActualsData(
    startDateTime,
    baselineTimeSeries,
    format,
    endDateTime,
  );

  const lastActual = last(actualsLineData);

  const monthsWithForecast =
    lastActual != null ? monthKeysWithForecastData.slice(1) : monthKeysWithForecastData;

  const eventLine = useChartForecastData({
    forecastData: smoothedDriverTimeSeries,
    monthKeys: monthsWithForecast,
    format,
    lastActual,
  });
  const lineWithoutSelectedEvent = useChartForecastData({
    forecastData: smoothedDriverTimeSeriesWithoutSelectedEvent,
    monthKeys: monthsWithForecast,
    format,
    lastActual,
  });

  // Get a line without the selected event so we can shade the area between this and the event line
  const [yMin, yMax] = valueScale.domain();
  const areaDatums = useMemo(() => {
    if (lineWithoutSelectedEvent == null) {
      return null;
    }
    return eventLine.map((datum, idx) => {
      const x = datum.x;

      // keep impact shading within the y bounds
      const y0 = clamp(datum.y, yMin, yMax);
      const y1 = clamp(lineWithoutSelectedEvent[idx]?.y ?? y0, yMin, yMax);

      return { x, y0, y1 };
    });
  }, [lineWithoutSelectedEvent, eventLine, yMin, yMax]);

  if (areaDatums == null) {
    return null;
  }

  return (
    <Area
      fill={colors.eventImpact}
      data={areaDatums}
      x={(d) => timeScale(d.x)}
      y0={(d) => valueScale(d.y0)}
      y1={(d) => valueScale(d.y1)}
      pointerEvents="none"
    />
  );
};

export default EventDriverImpact;
