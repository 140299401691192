import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import pluralize from 'pluralize';
import { ReactNode, useMemo } from 'react';

import {
  Lever,
  Months,
  Plans,
  References,
  WithColor,
} from '@features/CompareScenarios/comparators/DriverComparator';
import {
  ColumnDimension,
  Currency,
  DecimalPlaces,
  FieldType,
  Formula,
  NumericFormat,
} from '@features/CompareScenarios/comparators/common/types';
import Badge from 'components/Badge/Badge';
import ColoringInfo from 'components/CompareScenariosModalContent/ColoringInfo';
import FormulaRenderer from 'components/Formula/Formula';
import { BadgeTheme, ThemeColors } from 'config/badge';
import { CURRENCY_BY_ISO } from 'config/currency';
import { convertColorToBadgeTheme } from 'config/dimensionColors';
import { DriverFormat, LeverType, ValueType } from 'generated/graphql';
import { formatMonthKeyOrRange, getNumMonthsInclusive, toMonthKeyOrRangeList } from 'helpers/dates';
import useAppSelector from 'hooks/useAppSelector';
import {
  driverActualsFormulaDisplaySelector,
  driverForecastFormulaDisplaySelector,
  fieldSpecFormulaDisplaySelector,
} from 'selectors/formulaDisplaySelector';
import { Calendar, Cube, DollarSign, NumberSign, PercentageSign, Star } from 'vectors';
import Automatic from 'vectors/Automatic';

type ChangeCellValue =
  | string
  | boolean
  | number
  | DecimalPlaces
  | Formula
  | NumericFormat
  | ColumnDimension
  | FieldType
  | Currency
  | Lever
  | WithColor
  | References
  | Plans
  | Months
  | null
  | undefined;

export const ChangeCell = ({ value }: { value: ChangeCellValue }) => {
  if (value == null) {
    return null;
  }

  if (typeof value === 'boolean') {
    return <BooleanColumn column={value} />;
  }

  if (typeof value === 'string') {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{value}</>;
  }

  if (typeof value === 'number') {
    return <Flex justifyContent="end">{value.toString()}</Flex>;
  }

  if (value.type === 'dimension') {
    let theme: BadgeTheme | ThemeColors = 'outlineWhite';
    if (value.value.color != null) {
      theme = convertColorToBadgeTheme(value.value.color);
    }
    return (
      <Badge
        leftMeta={<Cube color={value.value.color != null ? 'gray.600' : 'gray.500'} />}
        theme={theme}
        fontSize="xs"
        borderRadius="md"
        text={value.value.name}
      />
    );
  }

  if (value.type === 'decimalPlaces') {
    const number = 1;
    return (
      <Flex justifyContent="end">
        <Box>{number.toFixed(value.value)}</Box>
      </Flex>
    );
  }

  if (value.type === 'currency') {
    const currency = CURRENCY_BY_ISO[value.value];
    return (
      <Text whiteSpace="nowrap">
        {currency.symbol} (${currency.isoCode})
      </Text>
    );
  }

  if (value.type === 'formula') {
    return <FormulaValue value={value.value} />;
  }

  if (value.type === 'valueType') {
    return <ValueTypeValue value={value} />;
  }

  if (value.type === 'numericFormat') {
    return <NumericFormatValue value={value} />;
  }

  if (value.type === 'leverType') {
    return <LeverValue value={value.value} />;
  }

  if (value.type === 'coloring') {
    return <ColoringInfo coloring={value.value} />;
  }

  if (value.type === 'references') {
    return <ReferencesValue value={value.value} />;
  }

  if (value.type === 'months') {
    return <MonthsValue value={value.value} />;
  }

  if (value.type === 'plans') {
    return <PlansValue value={value.value} />;
  }

  return <>Unknown Value</>;
};

const BooleanColumn = ({ column }: { column: boolean }) => {
  let value = 'Yes';
  if (!column) {
    value = 'No';
  }

  return (
    <Box textAlign="left" borderRadius="md" fontSize="xs" fontWeight="500">
      {value}
    </Box>
  );
};

type FieldValueType = ValueType.Number | ValueType.Timestamp | ValueType.Attribute;
const VALUE_TYPE_TO_PROPERTIES: Record<FieldValueType, { text: string; icon: ReactNode }> = {
  [ValueType.Number]: {
    text: 'Number',
    icon: <NumberSign color="gray.500" />,
  },
  [ValueType.Timestamp]: {
    text: 'Date',
    icon: <Calendar color="gray.500" />,
  },
  [ValueType.Attribute]: {
    text: 'Dimension',
    icon: <Cube color="gray.500" />,
  },
};

const ValueTypeValue = ({ value }: { value: FieldType }) => {
  const { text, icon } = VALUE_TYPE_TO_PROPERTIES[value.value];

  return (
    <Flex
      alignItems="center"
      justifyContent="flex-start"
      columnGap={1}
      fontWeight="500"
      fontSize="xs"
    >
      {icon}
      {text}
    </Flex>
  );
};

const NUMERIC_FORMAT_TO_PROPERTIES: Record<DriverFormat, { text: string; icon: ReactNode }> = {
  [DriverFormat.Auto]: {
    text: 'Automatic',
    icon: <Automatic color="gray.500" />,
  },
  [DriverFormat.Currency]: {
    text: 'Currency',
    icon: <DollarSign color="gray.500" />,
  },
  [DriverFormat.Integer]: {
    text: 'Integer',
    icon: <NumberSign color="gray.500" />,
  },
  [DriverFormat.Number]: {
    text: 'Number',
    icon: <NumberSign color="gray.500" />,
  },
  [DriverFormat.Percentage]: {
    text: 'Percentage',
    icon: <PercentageSign color="gray.500" />,
  },
};

const NumericFormatValue = ({ value }: { value: NumericFormat }) => {
  const { text, icon } = NUMERIC_FORMAT_TO_PROPERTIES[value.value];

  return (
    <Flex
      alignItems="center"
      justifyContent="flex-start"
      columnGap={1}
      fontWeight="500"
      fontSize="xs"
    >
      {icon}
      {text}
    </Flex>
  );
};

const LeverValue = ({ value }: { value: LeverType | null }) => {
  if (value == null) {
    return null;
  }

  if (value !== LeverType.Kpi) {
    return null;
  }

  return (
    <Flex alignItems="center" justifyContent="flex-start" columnGap={1}>
      <Star color="gray.600" />
      KPI
    </Flex>
  );
};

const ReferencesValue = ({ value }: { value: References['value'] }) => {
  return (
    <Flex alignItems="flex-start" justifyContent="flex-start" fontSize="xs">
      {value.length} {pluralize('reference', value.length)}
    </Flex>
  );
};

const MAX_MONTHS_OR_RANGES_TO_DISPLAY = 3;

const MonthsValue = ({ value }: { value: Months['value'] }) => {
  const monthKeyOrRangeList = useMemo(() => toMonthKeyOrRangeList(value), [value]);
  const monthsOrRangesToDisplay = monthKeyOrRangeList.slice(0, MAX_MONTHS_OR_RANGES_TO_DISPLAY);

  const countAdditionalMonths = useMemo(() => {
    if (monthKeyOrRangeList.length <= MAX_MONTHS_OR_RANGES_TO_DISPLAY) {
      return 0;
    }

    let countMonths = 0;
    monthKeyOrRangeList.slice(MAX_MONTHS_OR_RANGES_TO_DISPLAY).forEach((monthKeyOrRange) => {
      if (monthKeyOrRange.type === 'month') {
        countMonths += 1;
      } else {
        countMonths += getNumMonthsInclusive(monthKeyOrRange.from, monthKeyOrRange.to);
      }
    });

    return countMonths;
  }, [monthKeyOrRangeList]);

  return (
    <Text>
      {monthsOrRangesToDisplay.map(formatMonthKeyOrRange).join(', ')}
      {countAdditionalMonths !== 0 ? `, +${countAdditionalMonths} more` : ''}
    </Text>
  );
};

const PlansValue = ({ value }: { value: Plans['value'] }) => {
  const numPlansUpdated = value.length;

  return (
    <Tooltip label={value.join(', ')}>
      <Text>
        {numPlansUpdated} {pluralize('plan', numPlansUpdated)} updated
      </Text>
    </Tooltip>
  );
};

const FormulaValue = ({ value }: { value: Formula['value'] }) => {
  const formulaDisplay = useAppSelector((state) =>
    value.type === 'objectFieldSpec'
      ? fieldSpecFormulaDisplaySelector(state, {
          id: value.id,
          layerId: value.layerId,
        })
      : value.formulaType === 'forecast'
        ? driverForecastFormulaDisplaySelector(state, {
            id: value.id,
            layerId: value.layerId,
          })
        : driverActualsFormulaDisplaySelector(state, {
            id: value.id,
            layerId: value.layerId,
          }),
  );

  if (formulaDisplay != null) {
    return (
      <Flex minWidth="150px">
        <FormulaRenderer formulaDisplay={formulaDisplay} />
      </Flex>
    );
  }
  return <>Formula Not Found</>;
};
