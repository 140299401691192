import { EventEntity } from '@features/Plans/EventEntity';
import { ImpactType, ValueType } from 'generated/graphql';
import { formatDriverValue } from 'helpers/formatting';
import {
  AtomicNumberDisplayChunk,
  FormulaDisplay,
  FormulaDisplayChunkType,
} from 'helpers/formulaEvaluation/ForecastCalculator/FormulaDisplayListener';
import { isPrintableChar } from 'helpers/string';
import { CurvePointTyped } from 'reduxStore/models/events';
import { DisplayConfiguration } from 'reduxStore/models/value';

const EMPTY_FORMULA_DISPLAY = { chunks: [] };

const DISPLAY_OPTIONS = {
  includeCents: true,
  abbreviate: false,
};

function getComputedFromRowFormulaChunk(value: string): AtomicNumberDisplayChunk {
  return {
    type: FormulaDisplayChunkType.AtomicNumber,
    value,
    tooltip: 'Computed from row formula',
    icon: 'formula',
  };
}

function getDeltaFormulaDisplay({
  value,
  curvePoint,
  displayConfiguration,
}: {
  value: number;
  curvePoint: CurvePointTyped;
  displayConfiguration: DisplayConfiguration;
}): FormulaDisplay {
  if (curvePoint.type !== ValueType.Number) {
    // Delta events can only be of type Number
    return { ...EMPTY_FORMULA_DISPLAY };
  }

  const valueWithoutDelta = value - curvePoint.value;
  const formattedValueWithoutDelta = formatDriverValue(
    valueWithoutDelta,
    displayConfiguration,
    DISPLAY_OPTIONS,
  );

  const formattedDelta = formatDriverValue(
    Math.abs(curvePoint.value),
    displayConfiguration,
    DISPLAY_OPTIONS,
  );

  return {
    chunks: [
      getComputedFromRowFormulaChunk(formattedValueWithoutDelta),
      {
        type: FormulaDisplayChunkType.Operation,
        text: curvePoint.value >= 0 ? '+' : '-',
      },
      {
        type: FormulaDisplayChunkType.Number,
        text: formattedDelta,
      },
    ],
  };
}

export function getActiveCellFormulaDisplay({
  eventEntity,
  value,
  curvePoint,
  isForecast,
  displayConfiguration,
  startEditingKey,
}: {
  eventEntity: EventEntity | null | undefined;
  value: number | null | undefined;
  curvePoint: CurvePointTyped | undefined;
  isForecast: boolean;
  displayConfiguration: DisplayConfiguration;
  startEditingKey: string | null;
}): FormulaDisplay {
  // If a user triggers edit mode by entering a printable character, that character should be the initial
  // value shown in the active cell.
  if (startEditingKey != null && isPrintableChar(startEditingKey)) {
    return {
      chunks: [
        {
          type: FormulaDisplayChunkType.Number,
          text: startEditingKey,
        },
      ],
    };
  }

  if (value == null) {
    return { ...EMPTY_FORMULA_DISPLAY };
  }

  const formattedValue = formatDriverValue(value, displayConfiguration, DISPLAY_OPTIONS);

  if (!isForecast || eventEntity == null) {
    // For actuals and cells that don't support events, always show static number value
    return {
      chunks: [
        {
          type: FormulaDisplayChunkType.Number,
          text: formattedValue,
        },
      ],
    };
  }

  if (curvePoint == null) {
    return {
      chunks: [getComputedFromRowFormulaChunk(formattedValue)],
    };
  }

  if (curvePoint.impactType === ImpactType.Delta) {
    return getDeltaFormulaDisplay({ value, curvePoint, displayConfiguration });
  }

  return {
    chunks: [
      {
        type: FormulaDisplayChunkType.Number,
        text: formattedValue,
      },
    ],
  };
}
