import { CloseIcon } from '@chakra-ui/icons';
import { Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react';
import React from 'react';

import SearchIcon from 'vectors/Search';

export const SEARCH_INPUT_ID = 'search-input';

interface Props {
  placeholder: string;
  query: string;
  setQuery: (q: string) => void;
  onKeyDown?: (ev: KeyboardEvent | React.KeyboardEvent) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onClear?: () => void;
  size?: 'sm' | 'md' | 'lg';
}

function getSize(size: 'sm' | 'md' | 'lg' | undefined) {
  switch (size) {
    case 'sm':
      return 8;
    case 'md':
      return 10;
    case 'lg':
      return 12;
    default:
      return 8;
  }
}
const SearchInput = React.forwardRef<HTMLInputElement, Props>(
  ({ placeholder, query, size, setQuery, onKeyDown, onBlur, onClear }, ref) => {
    return (
      <InputGroup>
        <InputLeftElement pointerEvents="none" h="full">
          <SearchIcon color="gray.400" boxSize={4} />
        </InputLeftElement>
        <Input
          id={SEARCH_INPUT_ID}
          data-testid="search-input"
          ref={ref}
          placeholder={placeholder}
          borderColor="gray.300"
          focusBorderColor="selection.500"
          fontSize="xs"
          value={query}
          height={getSize(size)}
          onKeyDown={(ev) => onKeyDown?.(ev)}
          onChange={(ev) => setQuery(ev.target.value)}
          pl={9}
          fontWeight="medium"
          autoFocus
          onBlur={onBlur}
        />
        {onClear && Boolean(query) && (
          <InputRightElement h="full">
            <CloseIcon
              color="gray.500"
              cursor="pointer"
              boxSize={3}
              style={{ cursor: 'pointer' }}
              onClick={onClear}
            />
          </InputRightElement>
        )}
      </InputGroup>
    );
  },
);

export default SearchInput;
