import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import mapValues from 'lodash/mapValues';

import { safeLocalStorage } from 'helpers/localStorage';
import { uuidv4 } from 'helpers/uuidv4';
import {
  InitializeFeatureFlagsMessage,
  ToggleFeatureFlagMessage,
} from 'workers/formulaCalculator/types';

export const FEATURE_FLAG_DESCRIPTIONS = {
  disableInternalMetadata: '🕴️ Disable internal metadata',
  enableIntercom: '💬 Enable Intercom',
  logMutations: '🪵 Log mutations (development mode only)',
  enableDemoMode: '🤖 Enable AI planning and integrations demo',
  enableTimeTravels: '⏳ Enable time travel',
  enableNonNumericDrivers: '🛗 Enable non-numeric drivers',

  enableCleanupDimensions: '🧹 Enable cleanup dimensions',
  enableTemplates: '👁 Enable Example Library',
  enableAiSqlEditor: '✍️ Enable AI SQL editor',
  enableFieldLevelIntegrationForecastOverride:
    '🌤️ Enable Field-Level Integration Forecast Override',
  enableIntegrationLevelPropagateActuals: '➡️ Enable Integration-Level Propagate Actuals',
  enableParityTesting: '🟰 Enable parity testing',
  enableBlockParityTesting: '🟰 Enable blockeval parity testing',
  enableFormulaCalculationExplanations: '⁉️ Enable formula calculation explanations',
  enableFullReduxLogging: '📜 Enable full Redux logging in redux devtools (requires hard refresh)',

  showDebugInfoInUI: '🐛 Show debug info in UI',
};

export interface FeatureFlagsState {
  initialized: boolean;
  flags: FeatureFlagsRecord;
}

const INITIAL_FEATURE_FLAGS_RECORD = mapValues(FEATURE_FLAG_DESCRIPTIONS, () => false);

export const INITIAL_FEATURE_FLAGS_STATE: FeatureFlagsState = {
  initialized: false,
  flags: INITIAL_FEATURE_FLAGS_RECORD,
};

export type FeatureFlagsRecord = Record<keyof typeof FEATURE_FLAG_DESCRIPTIONS, boolean>;
export type FeatureFlag = keyof FeatureFlagsRecord;

export const EMPLOYEE_ONLY_FEATURE_FLAGS: FeatureFlag[] = [
  'enableIntercom',
  'logMutations',
  'enableDemoMode',
  'disableInternalMetadata',
  'enableAiSqlEditor',
  'enableFieldLevelIntegrationForecastOverride',
  'enableIntegrationLevelPropagateActuals',
  'enableParityTesting',
  'enableBlockParityTesting',
  'enableNonNumericDrivers',
  'enableFormulaCalculationExplanations',
  'enableTimeTravels',
  'enableFullReduxLogging',
  'showDebugInfoInUI',
];

const RUNWAY_FLAGS_KEY = '@Runway:Flags';

// This flag affects how we handle the redux store itself. It is needed
// before the store is created, so we allow direct access to it from
// local storage.
export function enableFullReduxLogging(): boolean {
  return readFlagsFromLocalStorage()?.enableFullReduxLogging ?? false;
}

function readFlagsFromLocalStorage(): FeatureFlagsRecord | null {
  try {
    const flagsString = safeLocalStorage.getItem(RUNWAY_FLAGS_KEY);

    if (flagsString == null) {
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return JSON.parse(flagsString);
  } catch {
    return null;
  }
}

function writeFlagsToLocalStorage(flagsRecord: Partial<FeatureFlagsRecord>): void {
  try {
    const existingFlags = readFlagsFromLocalStorage() ?? {};
    safeLocalStorage.setItem(
      RUNWAY_FLAGS_KEY,
      JSON.stringify({ ...existingFlags, ...flagsRecord }),
    );
  } catch {
    console.warn('could not set local feature flags');
  }
}

const featureFlagsSlice = createSlice({
  name: 'featureFlag',
  initialState: INITIAL_FEATURE_FLAGS_STATE,
  reducers: {
    initializeFlagsFromLocalStorage(state) {
      const flagsFromLocalStorage = readFlagsFromLocalStorage();

      if (flagsFromLocalStorage) {
        Object.entries(flagsFromLocalStorage).forEach((entry) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const [key, value]: [FeatureFlag, boolean] = entry as any;
          if (key in FEATURE_FLAG_DESCRIPTIONS) {
            state.flags[key] = value;
          }
        });
      }
      state.initialized = true;

      const msg: InitializeFeatureFlagsMessage = {
        type: 'initializeFeatureFlags',
        data: { ...state.flags },
        requestId: uuidv4(),
      };
      window.formulaCalculator?.postMessage(msg);
    },
    toggleFeatureFlagInternal(state, action: PayloadAction<FeatureFlag>) {
      const flag = action.payload;
      const newValue = !state.flags[flag];
      state.flags[flag] = newValue;
      writeFlagsToLocalStorage({ [flag]: newValue });

      const msg: ToggleFeatureFlagMessage = {
        type: 'toggleFeatureFlag',
        data: {
          name: flag,
          value: newValue,
        },
        requestId: uuidv4(),
      };
      window.formulaCalculator?.postMessage(msg);
    },
  },
});

export const { initializeFlagsFromLocalStorage, toggleFeatureFlagInternal } =
  featureFlagsSlice.actions;

export default featureFlagsSlice.reducer;
