import { Draft } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/nextjs';
import { createDraft } from 'immer';
import { DateTime } from 'luxon';

import {
  ExtTableSnapshot,
  LayerCreateInput,
  LayerDeleteInput,
  LayerUpdateInput,
} from 'generated/graphql';
import { getCurrentState } from 'helpers/immer';
import { getEntitiesInLightLayer } from 'helpers/layers';
import { nullSafeEqual } from 'helpers/typescript';
import { DatasetSnapshot } from 'reduxStore/models/dataset';
import {
  DEFAULT_LAYER_ID,
  DEFAULT_LAYER_NAME,
  DefaultLayer,
  Layer,
  LayerId,
} from 'reduxStore/models/layers';
import { MutationBatch, MutationId } from 'reduxStore/models/mutations';
import { DatasetSliceState } from 'reduxStore/reducers/datasetSlice';
import {
  handleCreateBlock,
  handleDeleteBlock,
  handleUpdateBlock,
} from 'reduxStore/reducers/helpers/blocks';
import {
  handleCreateBlocksPage,
  handleDeleteBlocksPage,
  handleUpdateBlocksPage,
} from 'reduxStore/reducers/helpers/blocksPages';
import {
  handleCreateBusinessObjectSpec,
  handleDeleteBusinessObjectSpec,
  handleUpdateBusinessObjectSpec,
} from 'reduxStore/reducers/helpers/businessObjectSpecs';
import {
  handleCreateBusinessObject,
  handleDeleteBusinessObjects,
  handleUpdateBusinessObject,
} from 'reduxStore/reducers/helpers/businessObjects';
import {
  handleCreateDatabaseConfig,
  handleDeleteDatabaseConfig,
  handleUpdateDatabaseConfig,
} from 'reduxStore/reducers/helpers/databaseConfigs';
import { setCurrentLayerId } from 'reduxStore/reducers/helpers/datasetSlice';
import {
  handleCreateDimension,
  handleDeleteDimension,
  handleRestoreDimension,
  handleUpdateDimension,
} from 'reduxStore/reducers/helpers/dimensions';
import {
  handleCreateDriverGroup,
  handleDeleteDriverGroup,
  handleRenameDriverGroup,
} from 'reduxStore/reducers/helpers/driverGroup';
import {
  handleCreateDrivers,
  handleDeleteDrivers,
  handleUpdateDrivers,
} from 'reduxStore/reducers/helpers/drivers';
import {
  handleCreateEvent,
  handleCreateEventGroup,
  handleDeleteEventGroups,
  handleDeleteEvents,
  handleUpdateEvent,
  handleUpdateEventGroup,
} from 'reduxStore/reducers/helpers/events';
import {
  handleCreateExtDriver,
  handleDeleteExtDriver,
  handleUpdateExtDriver,
} from 'reduxStore/reducers/helpers/extDrivers';
import {
  handleCreateExtObjectSpec,
  handleDeleteExtObjectSpec,
  handleUpdateExtObjectSpec,
} from 'reduxStore/reducers/helpers/extObjectSpecs';
import {
  handleCreateExtObject,
  handleDeleteExtObjects,
} from 'reduxStore/reducers/helpers/extObjects';
import {
  handleCreateExtQuery,
  handleDeleteExtQuery,
  handleUpdateExtQuery,
} from 'reduxStore/reducers/helpers/extQueries';
import {
  handleCreateExtTable,
  handleCreateExtTableRun,
  handleDeleteExtTable,
  handleUpdateComputedSnapshotForExtTables,
  handleUpdateExtTable,
} from 'reduxStore/reducers/helpers/extTables';
import {
  handleCreateIntegrationQuery,
  handleDeleteIntegrationQuery,
  handleUpdateIntegrationQuery,
} from 'reduxStore/reducers/helpers/integrationQueries';
import {
  handleCreateMilestone,
  handleDeleteMilestones,
  handleUpdateMilestone,
} from 'reduxStore/reducers/helpers/milestones';
import {
  handleCreateSubmodel,
  handleDeleteSubmodel,
  handleUpdateSubmodel,
} from 'reduxStore/reducers/helpers/submodels';

import { handleCreateFolder, handleDeleteFolder, handleUpdateFolder } from './folders';

export function handleCreateLayer(
  state: Draft<DatasetSliceState>,
  newLayerInput: LayerCreateInput,
) {
  const { id, name, isDraft, parentLayerId: parentLayerIdInput, userId } = newLayerInput;
  if (state.layers[id] != null) {
    return;
  }
  let parentLayerId = DEFAULT_LAYER_ID;
  if (parentLayerIdInput != null && parentLayerIdInput !== '') {
    parentLayerId = parentLayerIdInput;
  }
  const parentLayer = state.layers[parentLayerId];
  if (parentLayer?.isDraft) {
    throw new Error('Cannot branch a layer off of a draft layer');
  }

  const parentLayerEntities = getEntitiesInLightLayer(parentLayer);
  const newLayer: Layer = {
    ...emptyLayer(id, name),
    isDraft,
    createdByUserId: userId ?? undefined,
    createdAt: DateTime.now().toISO(),
    parentLayerId: parentLayerId ?? undefined,
    mutationBatches: [],
    isDeleted: false,
    lockedMutationId: undefined,
    ...parentLayerEntities,
  };
  state.layers[id] = newLayer;
}

export function handleDeleteLayer(
  state: Draft<DatasetSliceState>,
  deleteLayerInput: LayerDeleteInput,
) {
  const { layerId } = deleteLayerInput;
  if (layerId === DEFAULT_LAYER_ID) {
    throw new Error('cannot delete default layer');
  }
  const layer = state.layers[layerId];

  const hasChildLayers = Object.values(state.layers).some(
    (l) => !l.isDeleted && l.parentLayerId === layerId,
  );
  if (hasChildLayers) {
    throw new Error('cannot delete layer with child layers');
  }

  if (layer != null) {
    layer.isDeleted = true;
  }
}

export function undoDeleteLayer(state: Draft<DatasetSliceState>, layerId: LayerId) {
  const layer = state.layers[layerId];
  if (layer != null) {
    layer.isDeleted = false;
    if (
      layer.isDraft &&
      nullSafeEqual(layer.parentLayerId, state.layers[state.currentLayerId].parentLayerId)
    ) {
      setCurrentLayerId(state, layerId);
    }
  }
}

export function handleUpdateLayer(
  state: Draft<DatasetSliceState>,
  updateLayerInput: LayerUpdateInput,
) {
  const { layerId, name, isDraft, description } = updateLayerInput;
  const layer = state.layers[layerId];
  if (layer == null) {
    return;
  }

  if (name != null) {
    layer.name = name;
  }

  if (description != null) {
    layer.description = description;
  }

  if (isDraft != null) {
    if (layerId === DEFAULT_LAYER_ID && isDraft) {
      throw new Error('cannot make the default layer a draft');
    }

    layer.isDraft = isDraft;
  }
}

export function applyNonLayerModificationMutationBatch(
  state: Draft<DatasetSliceState>,
  layerData: Draft<Layer>,
  mutationBatch: MutationBatch,
) {
  applyLayerScopedMutations(state, layerData, mutationBatch);
  applyDatasetScopedMutations(state, mutationBatch);
}

function applyDatasetScopedMutations(
  state: Draft<DatasetSliceState>,
  mutationBatch: MutationBatch,
) {
  const {
    updateLastActualsTime,
    newBlocks,
    updateBlocks,
    deleteBlocks,
    newBlocksPages,
    updateBlocksPages,
    deleteBlocksPages,
    newFolders,
    updateFolders,
    deleteFolders,
  } = mutationBatch.mutation;

  if (updateLastActualsTime != null) {
    state.lastActualsTime = updateLastActualsTime;
  }

  // Folders
  newFolders?.forEach((newFolder) => handleCreateFolder(state, newFolder));
  updateFolders?.forEach((updateFolder) => handleUpdateFolder(state, updateFolder));
  deleteFolders?.forEach((deleteFolder) => handleDeleteFolder(state, deleteFolder));

  // Blocks Pages
  newBlocksPages?.forEach((newBlocksPage) => handleCreateBlocksPage(state, newBlocksPage));

  // Blocks
  newBlocks?.forEach((newBlock) => handleCreateBlock(state, newBlock));

  updateBlocksPages?.forEach((updateBlocksPage) => handleUpdateBlocksPage(state, updateBlocksPage));
  deleteBlocksPages?.forEach((deleteBlocksPage) => handleDeleteBlocksPage(state, deleteBlocksPage));

  updateBlocks?.forEach((updateBlock) => handleUpdateBlock(state, updateBlock));
  deleteBlocks?.forEach((deleteBlock) => handleDeleteBlock(state, deleteBlock));
}

function applyLayerScopedMutations(
  state: Draft<DatasetSliceState>,
  layerData: Draft<Layer>,
  mutationBatch: MutationBatch,
) {
  const {
    newDimensions,
    updateDimensions,
    deleteDimensions,
    restoreDimensions,
    newEvents,
    updateEvents,
    deleteEvents,
    newEventGroups,
    updateEventGroups,
    deleteEventGroups,
    newDrivers,
    updateDrivers,
    deleteDrivers,
    newSubmodels,
    updateSubmodels,
    deleteSubmodels,
    deleteDriverGroups,
    newMilestones,
    updateMilestones,
    deleteMilestones,
    newBusinessObjectSpecs,
    updateBusinessObjectSpecs,
    deleteBusinessObjectSpecs,
    newBusinessObjects,
    updateBusinessObjects,
    deleteBusinessObjects,
    createExtDrivers,
    updateExtDrivers,
    deleteExtDrivers,
    createExtObjectSpecs,
    deleteExtObjectSpecs,
    updateExtObjectSpecs,
    createExtObjects,
    deleteExtObjects,
    createIntegrationQueries,
    updateIntegrationQueries,
    deleteIntegrationQueries,
    createExtTables,
    updateExtTables,
    deleteExtTables,
    createExtTableRuns,
    newDriverGroups,
    renameDriverGroups,
    createDatabaseConfigs,
    updateDatabaseConfigs,
    deleteDatabaseConfigs,
    createExtQueries,
    updateExtQueries,
    deleteExtQueries,
  } = mutationBatch.mutation;

  // Dimensions
  // These should only run on the default layer.
  // Dimensions have to be created before drivers
  if (layerData.id === DEFAULT_LAYER_ID) {
    assertDefaultLayerDraft(layerData);

    newDimensions?.forEach((newDimensionInput) =>
      handleCreateDimension(layerData, newDimensionInput),
    );
    updateDimensions?.forEach((updateDimensionInput) =>
      handleUpdateDimension(layerData, updateDimensionInput),
    );
    deleteDimensions?.forEach((delDimInput) => {
      handleDeleteDimension(layerData, delDimInput);
    });
    restoreDimensions?.forEach((restoreDimInput) => {
      handleRestoreDimension(layerData, restoreDimInput);
    });
  }

  const defaultLayer =
    layerData.id === DEFAULT_LAYER_ID ? layerData : state.layers[DEFAULT_LAYER_ID];
  assertDefaultLayerDraft(defaultLayer);

  // Drivers
  handleCreateDrivers(layerData, defaultLayer, newDrivers ?? []);
  handleUpdateDrivers(layerData, defaultLayer, updateDrivers ?? []);
  handleDeleteDrivers(layerData, deleteDrivers ?? []);

  newDriverGroups?.forEach((newGroupInput) => handleCreateDriverGroup(layerData, newGroupInput));
  renameDriverGroups?.forEach((renameDriverGroupInput) =>
    handleRenameDriverGroup(layerData, renameDriverGroupInput),
  );
  deleteDriverGroups?.forEach((deleteDriverGroupInput) =>
    handleDeleteDriverGroup(layerData, deleteDriverGroupInput),
  );

  // Submodels
  newSubmodels?.forEach((newSubmodelInput) => handleCreateSubmodel(layerData, newSubmodelInput));
  updateSubmodels?.forEach((updateSubmodelInput) =>
    handleUpdateSubmodel(layerData, updateSubmodelInput),
  );
  deleteSubmodels?.forEach((deleteSubmodelInput) =>
    handleDeleteSubmodel(layerData, deleteSubmodelInput),
  );

  // These should only run on the default layer.
  // Named versions are static and thus can not receive mutations.
  if (layerData.id === DEFAULT_LAYER_ID) {
    assertDefaultLayerDraft(layerData);

    // External Drivers
    createExtDrivers?.forEach((createExtDriversInput) =>
      handleCreateExtDriver(layerData, createExtDriversInput),
    );
    updateExtDrivers?.forEach((updateExtDriversInput) =>
      handleUpdateExtDriver(layerData, updateExtDriversInput),
    );
    deleteExtDrivers?.forEach((deleteExtDriversInput) =>
      handleDeleteExtDriver(layerData, deleteExtDriversInput),
    );

    // Ext Object Spec
    // N.B. we should always handle deletes first, since we recreate ext object specs
    // by deleting and creating in the same mutation batch
    deleteExtObjectSpecs?.forEach((deleteExtObjectSpecInput) =>
      handleDeleteExtObjectSpec(layerData, deleteExtObjectSpecInput),
    );
    createExtObjectSpecs?.forEach((createExtObjectSpecInput) =>
      handleCreateExtObjectSpec(layerData, createExtObjectSpecInput),
    );
    updateExtObjectSpecs?.forEach((updateExtObjectSpecInput) =>
      handleUpdateExtObjectSpec(layerData, updateExtObjectSpecInput),
    );

    // Ext Object
    // N.B. we should always handle deletes first, since we recreate ext objects
    // by deleting and creating in the same mutation batch
    handleDeleteExtObjects(layerData, deleteExtObjects ?? []);

    createExtObjects?.forEach((createExtObjectInput) =>
      handleCreateExtObject(layerData, createExtObjectInput),
    );
  }

  // Integration Queries
  deleteIntegrationQueries?.forEach((deleteIntegrationQueriesInput) =>
    handleDeleteIntegrationQuery(layerData, deleteIntegrationQueriesInput),
  );
  createIntegrationQueries?.forEach((createIntegrationQueriesInput) =>
    handleCreateIntegrationQuery(layerData, createIntegrationQueriesInput),
  );
  updateIntegrationQueries?.forEach((updateIntegrationQueriesInput) =>
    handleUpdateIntegrationQuery(layerData, updateIntegrationQueriesInput),
  );

  // Ext Tables
  createExtTables?.forEach((createExtTableInput) =>
    handleCreateExtTable(layerData, createExtTableInput),
  );
  updateExtTables?.forEach((updateExtTableInput) =>
    handleUpdateExtTable(layerData, updateExtTableInput),
  );
  deleteExtTables?.forEach((deleteExtTableInput) =>
    handleDeleteExtTable(layerData, deleteExtTableInput),
  );
  createExtTableRuns?.forEach((createExtTableRunInput) =>
    handleCreateExtTableRun(layerData, createExtTableRunInput),
  );

  // BusinessObjectSpecs
  newBusinessObjectSpecs?.forEach((newBusinessObjectSpec) =>
    handleCreateBusinessObjectSpec(layerData, defaultLayer, newBusinessObjectSpec),
  );
  updateBusinessObjectSpecs?.forEach((updateBusinessObjectSpec) =>
    handleUpdateBusinessObjectSpec(layerData, defaultLayer, updateBusinessObjectSpec),
  );
  deleteBusinessObjectSpecs?.forEach((deleteBusinessObjectSpec) =>
    handleDeleteBusinessObjectSpec(layerData, defaultLayer, deleteBusinessObjectSpec),
  );

  // Database Configurations
  createDatabaseConfigs?.forEach((createDatabaseConfig) => {
    handleCreateDatabaseConfig(layerData, createDatabaseConfig);
  });
  updateDatabaseConfigs?.forEach((updateDatabaseConfig) => {
    handleUpdateDatabaseConfig(layerData, updateDatabaseConfig);
  });
  deleteDatabaseConfigs?.forEach((deleteDatabaseConfig) => {
    handleDeleteDatabaseConfig(layerData, deleteDatabaseConfig);
  });

  // ExtQueries
  createExtQueries?.forEach((createExtQuery) => {
    handleCreateExtQuery(layerData, createExtQuery);
  });
  updateExtQueries?.forEach((updateExtQuery) => {
    handleUpdateExtQuery(layerData, updateExtQuery);
  });
  deleteExtQueries?.forEach((deleteExtQuery) => {
    handleDeleteExtQuery(layerData, deleteExtQuery);
  });

  // BusinessObjects
  newBusinessObjects?.forEach((newBusinessObject) =>
    handleCreateBusinessObject(layerData, defaultLayer, newBusinessObject),
  );
  updateBusinessObjects?.forEach((updateBusinessObject) =>
    handleUpdateBusinessObject(layerData, defaultLayer, updateBusinessObject),
  );

  handleDeleteBusinessObjects(layerData, defaultLayer, deleteBusinessObjects ?? []);

  const enableStackedImpacts = state.launchDarklyFlags.flags.enableStackedImpacts ?? false;

  newEvents?.forEach((newEventInput) =>
    handleCreateEvent(layerData, newEventInput, enableStackedImpacts),
  );
  newEventGroups?.forEach((newEventGroupInput) =>
    handleCreateEventGroup(layerData, newEventGroupInput),
  );
  updateEvents?.forEach((updateEventInput) =>
    handleUpdateEvent(layerData, updateEventInput, enableStackedImpacts),
  );

  handleDeleteEvents(layerData, deleteEvents ?? []);

  updateEventGroups?.forEach((updateEventGroupInput) =>
    handleUpdateEventGroup(layerData, updateEventGroupInput),
  );

  handleDeleteEventGroups(layerData, deleteEventGroups ?? []);

  // Milestones
  newMilestones?.forEach((newMilestoneInput) =>
    handleCreateMilestone(layerData, newMilestoneInput),
  );
  updateMilestones?.forEach((updateMilestoneInput) =>
    handleUpdateMilestone(layerData, updateMilestoneInput),
  );

  handleDeleteMilestones(layerData, deleteMilestones ?? []);
}

export function handleCommitLayer(
  state: Draft<DatasetSliceState>,
  layerReceivingCommitDraft: Draft<Layer>,
  layerToCommitId: LayerId,
  options?: { isNested: boolean },
) {
  if (layerToCommitId === DEFAULT_LAYER_ID) {
    throw new Error('cannot commit default layer');
  }
  const layerToCommit = state.layers[layerToCommitId];
  if (layerToCommit == null) {
    const err = new Error('Could not find layer to commit');
    Sentry.withScope((scope) => {
      scope.setExtras({
        layerReceivingCommitId: layerReceivingCommitDraft.id,
        layerToCommitId,
        layerNotFound: 'committing',
        isNested: options?.isNested ?? false,
      });
      scope.setLevel('warning');
      Sentry.captureException(err);
    });
    throw err;
  }

  const isLayerToCommitUpToDate = state.isLayerUpToDate[layerToCommitId] ?? false;
  let layerReceivingCommit: Draft<Layer> = layerReceivingCommitDraft;
  // it is most likely the case that if you are committing a layer you are actively working on, it is up to date
  if (isLayerToCommitUpToDate) {
    // replace the entities of the layerReceivingCommit with the entities contained in the layerToCommit
    layerReceivingCommit = createDraft({
      ...getCurrentState(layerReceivingCommit),
      ...getEntitiesInLightLayer(layerToCommit),
    });
  } else {
    // sometimes the layer to commit is not up to date because it was not loaded into memory if it was not an active layer. For such a case,
    // we need to replay mutations from the layerToCommit on top of the layerReceivingCommit

    // Get all mutations from the layer to commit and apply them.
    const mutationsToCommit = layerToCommit.mutationBatches;

    // NOTE: if layers can create layers then this won't work because it doesn't
    // handle layer creation.
    mutationsToCommit.forEach((m) => {
      const { commitLayerId } = m.mutation;
      if (commitLayerId != null) {
        layerReceivingCommit = handleCommitLayer(state, layerReceivingCommit, commitLayerId, {
          isNested: true,
        });
      }
      applyNonLayerModificationMutationBatch(state, layerReceivingCommit, {
        ...m,
        layerId: layerReceivingCommit.id,
      });
    });
  }

  // Reparent all child layers
  Object.values(state.layers).forEach((l) => {
    if (l.parentLayerId === layerToCommitId) {
      l.parentLayerId = layerReceivingCommit.id;
    }
  });

  // Mark as committed. We need to keep the layers around for replaying commit
  // mutations until the app is reloaded.
  layerToCommit.isDeleted = true;
  // Also delete all layers that might've been drafts of the committed layer
  Object.values(state.layers)
    .filter((l) => l.parentLayerId === layerToCommitId && l.isDraft)
    .forEach((l) => {
      l.isDeleted = true;
    });

  return layerReceivingCommit;
}

// Layers are a bit of a weird case right now. We don't want to fully reset
// them to the snapshot as we want to preserve the mutation lists BUT we want
// to ensure that we can undo things like renames. So, we selectively set
// certain fields.
export function setLayersMetadataFromSnapshot(
  state: Draft<DatasetSliceState>,
  dataset: DatasetSnapshot,
) {
  if (dataset == null) {
    return;
  }
  const snapshotLayers = dataset.layers;
  Object.values(state.layers).forEach((layer) => {
    if (layer.isDeleted) {
      return;
    }
    const snapshotLayer = snapshotLayers.find((l) => l.id === layer.id);
    if (!snapshotLayer) {
      return;
    }

    layer.name = snapshotLayer.name;
    layer.description = snapshotLayer.description ?? undefined;
    layer.isDraft = snapshotLayer.isDraft;
    layer.createdByUserId = snapshotLayer.createdByUserId ?? undefined;
  });
}

export function emptyLayer(id: string, name: string, lockedMutationId?: MutationId): Layer {
  return {
    id,
    name,
    description: undefined,
    isDraft: false,
    createdAt: DateTime.now().toISO(),
    events: { byId: {}, allIds: [] },
    eventGroups: { byId: {}, allIds: [] },
    drivers: { byId: {}, allIds: [] },
    dimensions: { byId: {}, allIds: [] },
    attributes: { byId: {}, allIds: [] },
    milestones: { byId: {}, allIds: [] },
    businessObjectSpecs: { byId: {}, allIds: [] },
    businessObjects: { byId: {}, allIds: [] },
    pageData: { byId: {}, allIds: [] },
    extDrivers: { byId: {}, allIds: [] },
    extObjectSpecs: { byKey: {}, allKeys: [] },
    extObjects: { byKey: {}, allKeys: [] },
    extQueries: { byId: {}, allIds: [] },
    extTables: { byKey: {}, allKeys: [] },
    submodels: { byId: {}, allIds: [] },
    driverGroups: { byId: {}, allIds: [] },
    integrationQueries: { byId: {}, allIds: [] },
    mutationBatches: [],
    isDeleted: false,
    deletedIdentifiers: {},
    lockedMutationId,
    eventsByEntityId: {},
    databaseConfigs: { byId: {}, allIds: [] },
  };
}

export function setLayersFromDatasetSnapshot(
  state: Draft<DatasetSliceState>,
  dataset: DatasetSnapshot,
) {
  const initLayers: Record<LayerId, Layer> = {
    [DEFAULT_LAYER_ID]: emptyLayer(DEFAULT_LAYER_ID, DEFAULT_LAYER_NAME),
  };

  if (dataset == null) {
    state.layers = initLayers;
    return;
  }

  state.layers = dataset.namedVersions.reduce(
    (result, namedVersion) => ({
      [namedVersion.mutationId]: emptyLayer(
        namedVersion.mutationId,
        namedVersion.name,
        namedVersion.mutationId,
      ),
      ...result,
    }),
    dataset.layers.reduce(
      (result, layer) => ({
        [layer.id]: {
          ...emptyLayer(layer.id, layer.name),
          description: layer.description ?? undefined,
          createdAt: layer.createdAt,
          isDraft: layer.isDraft,
          parentLayerId: layer.parentLayerId ?? undefined,
          mutationBatches: layer.scopedMutations.map((mutation) => ({
            id: mutation.id,
            layerId: layer.id,
            isUndone: mutation.deletedAt != null,
            mutation,
          })),
        },
        ...result,
      }),
      initLayers,
    ),
  );
}

export function setComputedSnapshotForExtTables({
  state,
  snapshotBySourceKey,
}: {
  state: DatasetSliceState;
  snapshotBySourceKey: NullableRecord<string, ExtTableSnapshot>;
}) {
  const layer = state.layers[DEFAULT_LAYER_ID];
  handleUpdateComputedSnapshotForExtTables(layer, snapshotBySourceKey);
}

export function assertDefaultLayerDraft(layer: Draft<Layer>): asserts layer is Draft<DefaultLayer> {
  if (layer.id !== DEFAULT_LAYER_ID) {
    throw new Error('Expected default_layer');
  }
}
