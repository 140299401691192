import { createSelector } from 'reselect';

import { CellType } from 'config/cells';
import {
  businessObjectsByFieldIdForLayerSelector,
  businessObjectsByIdForLayerSelector,
} from 'selectors/businessObjectsSelector';
import { driversByIdForLayerSelector } from 'selectors/driversSelector';
import {
  eventGroupsByIdForLayerSelector,
  eventsByIdForLayerSelector,
} from 'selectors/eventsAndGroupsSelector';
import { extDriversByIdSelector } from 'selectors/extDriversSelector';
import { prevailingCellSelectionSelector } from 'selectors/prevailingCellSelectionSelector';
import { activePlanTimelineItemRefSelector } from 'selectors/selectionSelector';
import { Selector } from 'types/redux';

export const hasExistingEntitySelectedSelector: Selector<boolean> = createSelector(
  activePlanTimelineItemRefSelector,
  prevailingCellSelectionSelector,
  eventsByIdForLayerSelector,
  eventGroupsByIdForLayerSelector,
  driversByIdForLayerSelector,
  businessObjectsByIdForLayerSelector,
  extDriversByIdSelector,
  businessObjectsByFieldIdForLayerSelector,
  (
    activePlanTimelineItemRef,
    cellSelection,
    eventsById,
    eventGroupsById,
    driversById,
    businessObjectsById,
    extDriversById,
    businessObjectsByFieldId,
    // eslint-disable-next-line max-params
  ) => {
    if (activePlanTimelineItemRef != null) {
      if (activePlanTimelineItemRef.type === 'group') {
        return eventGroupsById[activePlanTimelineItemRef.id] != null;
      }

      if (activePlanTimelineItemRef.type === 'event') {
        return eventsById[activePlanTimelineItemRef.id] != null;
      }

      if (activePlanTimelineItemRef.type === 'entity') {
        const { entityId } = activePlanTimelineItemRef;
        return driversById[entityId] != null || businessObjectsById[entityId] != null;
      }
    }

    if (cellSelection != null) {
      const { activeCell } = cellSelection;
      switch (activeCell.type) {
        case CellType.Impact: {
          const { rowKey } = activeCell;
          return rowKey.type === 'driver'
            ? driversById[rowKey.driverId] != null
            : businessObjectsByFieldId[rowKey.objectFieldId] != null;
        }
        case CellType.ImpactDriver:
        case CellType.Driver: {
          return driversById[activeCell.rowKey.driverId ?? ''] != null;
        }
        case CellType.ObjectFieldTimeSeries:
        case CellType.ObjectField: {
          return businessObjectsById[activeCell.rowKey.objectId ?? ''] != null;
        }
        case CellType.ExtDriver: {
          return extDriversById[activeCell.rowKey.extDriverId ?? ''] != null;
        }
        default: {
          break;
        }
      }
    }

    return false;
  },
);
