import { Circle } from '@visx/shape';
import last from 'lodash/last';
import React, { useCallback } from 'react';

import ChartLinePath from 'components/DriverChart/ChartLinePath';
import {
  DASH_ARRAY_BY_SIZE,
  DriverChartDatum,
  EVENT_POINT_RADIUS,
  STROKE_WIDTH_BY_SIZE,
} from 'config/driverChart';
import theme from 'config/theme';
import { CurveType } from 'generated/graphql';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import useChartActualsData from 'hooks/useChartActualsData';
import useChartContext from 'hooks/useChartContext';
import useForecastLineData from 'hooks/useForecastLineData';
import useIsKeyPressed from 'hooks/useIsKeyPressed';
import { DriverId } from 'reduxStore/models/drivers';
import { isSetImpactEvent } from 'reduxStore/models/events';
import { LayerId } from 'reduxStore/models/layers';
import { cursorMonthKeySelector, cursorOnDragHandleSelector } from 'selectors/cursorSelector';
import { driverTimeSeriesForLayerSelector } from 'selectors/driverTimeSeriesSelector';
import { comparisonLayerColorByIdForBlockSelector } from 'selectors/rollupSelector';
import { singleSelectedDriverEventSelector } from 'selectors/selectedEventSelector';
import { MonthKey } from 'types/datetime';

const LINE_STROKE_COLOR = 'white';
const DOT_STROKE_COLOR = 'white';
const DEFAULT_LINE_COLOR = theme.colors.forecast;

interface ActiveForecastLinePathProps {
  data: DriverChartDatum[];
  alwaysCustom: boolean;
  color?: string;
}

const ActiveForecastLinePath: React.FC<ActiveForecastLinePathProps> = ({
  data,
  alwaysCustom,
  color = DEFAULT_LINE_COLOR,
}) => {
  const { timeScale, valueScale, size } = useChartContext();
  const cursorMonthKey = useAppSelector(cursorMonthKeySelector);
  const onDragHandle = useAppSelector(cursorOnDragHandleSelector);
  const selectedEvent = useAppSelector(singleSelectedDriverEventSelector);
  const customKeyMatcher = useCallback(
    (ev: KeyboardEvent) => ev.metaKey || ev.shiftKey || ev.ctrlKey,
    [],
  );
  const customKeyPressed = useIsKeyPressed(customKeyMatcher) || alwaysCustom;

  if (data.length === 0) {
    return null;
  }

  const isHovered =
    onDragHandle &&
    cursorMonthKey != null &&
    data.some(({ monthKey }) => monthKey === cursorMonthKey);

  const hoveredMonth =
    (isHovered &&
      selectedEvent != null &&
      (isSetImpactEvent(selectedEvent) || selectedEvent?.curveType === CurveType.Custom)) ||
    customKeyPressed
      ? cursorMonthKey
      : isHovered
        ? 'all'
        : null;

  const strokeWidth = STROKE_WIDTH_BY_SIZE[size];

  return (
    <>
      <ChartLinePath data={data} strokeColor={LINE_STROKE_COLOR} strokeWidth={strokeWidth + 2} />
      <ChartLinePath
        data={data}
        strokeColor={color}
        strokeWidth={strokeWidth}
        strokeDasharray={DASH_ARRAY_BY_SIZE[size]}
      />
      <>
        {data.slice(1).map((pt, idx) => (
          <React.Fragment key={`dots${idx}`}>
            <Circle
              cx={timeScale(pt.x)}
              cy={valueScale(pt.y)}
              r={EVENT_POINT_RADIUS + 2}
              fill={DOT_STROKE_COLOR}
              pointerEvents="none"
            />
            <Circle
              cx={timeScale(pt.x)}
              cy={valueScale(pt.y)}
              r={EVENT_POINT_RADIUS}
              fill={color}
              pointerEvents="none"
            />
          </React.Fragment>
        ))}
        {data
          .slice(1)
          .map((pt, idx) =>
            hoveredMonth === 'all' || hoveredMonth === pt.monthKey ? null : (
              <Circle
                key={idx}
                cx={timeScale(pt.x)}
                cy={valueScale(pt.y)}
                r={EVENT_POINT_RADIUS - 2}
                fill={DOT_STROKE_COLOR}
                pointerEvents="none"
              />
            ),
          )}
      </>
    </>
  );
};

interface ForecastLinePathProps {
  data: DriverChartDatum[];
  color?: string;
}

const ForecastLinePath: React.FC<ForecastLinePathProps> = React.memo(
  ({ data, color = DEFAULT_LINE_COLOR }) => {
    const { size } = useChartContext();
    if (data.length === 0) {
      return null;
    }

    const strokeWidth = STROKE_WIDTH_BY_SIZE[size];
    return (
      <>
        <ChartLinePath
          data={data}
          strokeColor={LINE_STROKE_COLOR}
          strokeWidth={strokeWidth + 2}
          strokeDasharray={DASH_ARRAY_BY_SIZE[size]}
        />
        <ChartLinePath
          data={data}
          strokeColor={color}
          strokeWidth={strokeWidth}
          strokeDasharray={DASH_ARRAY_BY_SIZE[size]}
          opacity={1}
        />
      </>
    );
  },
);

interface ReadonlyForecastLineProps {
  monthKeys: MonthKey[];
  lastActual: DriverChartDatum | undefined;
  layerId?: LayerId;
  color?: string;
  driverId: DriverId;
}

const ReadonlyForecastLine: React.FC<ReadonlyForecastLineProps> = ({
  lastActual,
  monthKeys,
  layerId,
  color,
  driverId,
}) => {
  const forecastLineData = useForecastLineData(monthKeys, lastActual, driverId, layerId);

  return <ForecastLinePath data={forecastLineData} color={color} />;
};

interface ActiveForecastLineProps {
  monthKeys: MonthKey[];
  lastActual: DriverChartDatum | undefined;
  alwaysCustom: boolean;
  driverId: DriverId;
  color?: string;
  layerId: LayerId;
}

const ActiveForecastLine: React.FC<ActiveForecastLineProps> = ({
  lastActual,
  monthKeys,
  alwaysCustom,
  driverId,
  color,
  layerId,
}) => {
  const forecastLineData = useForecastLineData(monthKeys, lastActual, driverId, layerId);

  return (
    <ActiveForecastLinePath data={forecastLineData} alwaysCustom={alwaysCustom} color={color} />
  );
};

interface Props {
  isActiveChart: boolean;
  alwaysCustom?: boolean;
  driverId: DriverId;
  layerId: LayerId;
}

const ForecastLine: React.FC<Props> = ({
  isActiveChart,
  driverId,
  alwaysCustom = false,
  layerId,
}) => {
  const { blockId } = useBlockContext();
  const {
    monthKeysWithForecastData,
    startDateTime,
    endDateTime,
    baselineLayerId,
    driverIdsToColors,
    format,
  } = useChartContext();

  const comparisonLayerColor = useAppSelector(
    (state) => comparisonLayerColorByIdForBlockSelector(state, blockId)[layerId],
  );

  const ts = useAppSelector((state) =>
    driverTimeSeriesForLayerSelector(state, { id: driverId, layerId: layerId ?? baselineLayerId }),
  );

  // Get time series data for actuals
  const actualsLineData = useChartActualsData(startDateTime, ts, format, endDateTime);

  const lastActual = last(actualsLineData);

  const color = comparisonLayerColor ?? driverIdsToColors[driverId];

  return isActiveChart ? (
    <ActiveForecastLine
      lastActual={lastActual}
      monthKeys={monthKeysWithForecastData}
      alwaysCustom={alwaysCustom}
      driverId={driverId}
      color={color}
      layerId={layerId}
    />
  ) : (
    <ReadonlyForecastLine
      lastActual={lastActual}
      monthKeys={monthKeysWithForecastData}
      driverId={driverId}
      color={color}
      layerId={layerId}
    />
  );
};

export default ForecastLine;
