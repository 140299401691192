import { Flex, Text, Tooltip } from '@chakra-ui/react';
import pluralize from 'pluralize';
import React, { useMemo } from 'react';

import RowText from 'components/CompareScenariosModalContent/RowText';
import { DriverReference } from 'generated/graphql';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import useAppSelector from 'hooks/useAppSelector';
import { blocksPagesByBlockIdSelector } from 'selectors/blocksPagesSelector';
import { driverGroupsByIdSelector } from 'selectors/driverGroupSelector';
import { driverSelector } from 'selectors/driversSelector';
import { layerNameByIdSelector } from 'selectors/layerSelector';
import {
  isBaselineLayerSelector,
  scenarioComparisonBaselineLayerIdSelector,
} from 'selectors/scenarioComparisonSelector';

interface Props {
  driverId: string;
  layerId: string;
}
const DriverReferencesInfo: React.FC<Props> = ({ layerId, driverId }) => {
  const baselineLayerId = useAppSelector(scenarioComparisonBaselineLayerIdSelector);
  const layerNameById = useAppSelector(layerNameByIdSelector);
  const baselineDriver = useAppSelector((state) =>
    driverSelector(state, { id: driverId, layerId: baselineLayerId }),
  );
  const comparisonDriver = useAppSelector((state) =>
    driverSelector(state, { id: driverId, layerId }),
  );

  const isBaselineLayer = useAppSelector((state) => isBaselineLayerSelector(state, layerId));
  const isIdenticalToBaseline = useMemo(() => {
    const getKey = (ref: DriverReference) => `${ref.blockId}-${ref.groupId}`;
    const baselineReferences = new Set(
      (baselineDriver?.driverReferences ?? []).map((ref) => getKey(ref)),
    );
    const filtered = (comparisonDriver?.driverReferences ?? []).filter((ref) => {
      return !baselineReferences.has(getKey(ref));
    });
    return filtered.length === 0 && !isBaselineLayer;
  }, [baselineDriver?.driverReferences, comparisonDriver?.driverReferences, isBaselineLayer]);

  const blockPagesByBlockId = useAppSelector(blocksPagesByBlockIdSelector);
  const groupsById = useAppSelector((state) => driverGroupsByIdSelector(state, { layerId }));

  const pageReferences = useMemo(() => {
    const uniquePageIds: Set<string> = new Set();
    return (comparisonDriver?.driverReferences ?? [])
      .map((reference) => {
        const blockPage = safeObjGet(blockPagesByBlockId[reference.blockId]);
        if (blockPage != null && !uniquePageIds.has(blockPage.id) && reference.groupId == null) {
          uniquePageIds.add(blockPage.id);
          return {
            pageId: blockPage.id,
            pageName: blockPage.name,
          };
        }
        return null;
      })
      .filter(isNotNull)
      .sort((a, b) => a.pageName.localeCompare(b.pageName));
  }, [blockPagesByBlockId, comparisonDriver?.driverReferences]);

  const driverGroupReferences = useMemo(() => {
    return (comparisonDriver?.driverReferences ?? [])
      .map((reference) => {
        const group = reference.groupId != null ? safeObjGet(groupsById[reference.groupId]) : null;
        if (group != null) {
          const blockPage = safeObjGet(blockPagesByBlockId[reference.blockId]);
          return {
            groupId: group.id,
            groupName: group.name,
            pageName: blockPage?.name,
          };
        }
        return null;
      })
      .filter(isNotNull)
      .sort((a, b) => a.groupName.localeCompare(b.groupName));
  }, [blockPagesByBlockId, comparisonDriver?.driverReferences, groupsById]);

  const pagesTooltipContent = useMemo(() => {
    return (
      <Flex flexDirection="column">
        {pageReferences.map((ref) => (
          <Text alignSelf="start" key={ref.pageId}>
            {ref.pageName}
          </Text>
        ))}
      </Flex>
    );
  }, [pageReferences]);

  const groupsTooltipContent = useMemo(() => {
    return (
      <Flex flexDirection="column">
        {driverGroupReferences.map((ref) => (
          <Text alignSelf="start" key={ref.groupId}>
            {`${ref.groupName} group in ${ref.pageName ?? 'unknown'} page`}
          </Text>
        ))}
      </Flex>
    );
  }, [driverGroupReferences]);

  if (isIdenticalToBaseline) {
    return <RowText text={`Same as ${layerNameById[baselineLayerId]}`} />;
  }
  return (
    <Flex width="fit-content" flexDirection="row" fontSize="xs" fontWeight="medium">
      {pageReferences.length > 0 && (
        <Tooltip label={pagesTooltipContent}>
          <Text as="em" cursor="pointer" _hover={{ color: 'blue.500' }}>
            {pageReferences.length} {`${pluralize('page', pageReferences.length)}`}
          </Text>
        </Tooltip>
      )}
      {driverGroupReferences.length > 0 && (
        <>
          {pageReferences.length > 0 && <>&nbsp;and</>}
          <Tooltip label={groupsTooltipContent}>
            <Text as="em" cursor="pointer" _hover={{ color: 'blue.500' }}>
              {driverGroupReferences.length}
              {` ${pluralize('group', driverGroupReferences.length)}`}{' '}
            </Text>
          </Tooltip>
        </>
      )}
    </Flex>
  );
};

export default React.memo(DriverReferencesInfo);
