import { BoxProps, Flex } from '@chakra-ui/react';
import React from 'react';

import AtomicNumberChunk from 'components/Formula/AtomicNumberChunk';
import BaseFormulaChunk from 'components/Formula/BaseFormulaChunk';
import BusinessObjectChunk from 'components/Formula/BusinessObjectChunk';
import DriverChunk from 'components/Formula/DriverChunk';
import SubmodelChunk from 'components/Formula/DriverGroupChunk';
import ExtDriverChunk from 'components/Formula/ExtDriverChunk';
import ExtQueryChunk from 'components/Formula/ExtQueryChunk';
import InvalidChunk from 'components/Formula/InvalidChunk';
import NumberChunk from 'components/Formula/NumberFormulaDisplayChunk';
import OperationChunk from 'components/Formula/OperationChunk';
import StringChunk from 'components/Formula/StringFormulaDisplayChunk';
import ThisSegmentChunk from 'components/Formula/ThisSegmentChunk';
import { NUMERIC_FONT_SETTINGS } from 'config/styles';
import {
  FormulaDisplay,
  FormulaDisplayChunkType,
} from 'helpers/formulaEvaluation/ForecastCalculator/FormulaDisplayListener';

interface Props {
  formulaDisplay: FormulaDisplay;
  color?: BoxProps['color'];
}

const Formula: React.FC<Props> = ({ formulaDisplay, color }) => {
  return (
    <Flex
      sx={NUMERIC_FONT_SETTINGS}
      alignItems="center"
      data-testid="formula"
      fontSize="xs"
      fontWeight="500"
      height="full"
      lineHeight={2}
      columnGap="2px"
      color={color}
    >
      {formulaDisplay.chunks.map((chunk, idx) => {
        switch (chunk.type) {
          case FormulaDisplayChunkType.Number:
            return <NumberChunk key={idx} text={chunk.text} />;
          case FormulaDisplayChunkType.String:
            return <StringChunk key={idx} text={chunk.text} />;
          case FormulaDisplayChunkType.Invalid:
            return <InvalidChunk key={idx} text={chunk.text} />;
          case FormulaDisplayChunkType.Operation:
            return <OperationChunk key={idx} text={chunk.text} />;
          case FormulaDisplayChunkType.Object:
            return <BusinessObjectChunk key={idx} chunk={chunk} />;
          case FormulaDisplayChunkType.ExtDriver:
            return <ExtDriverChunk key={idx} chunk={chunk} />;
          case FormulaDisplayChunkType.ExtQuery:
            return <ExtQueryChunk key={idx} chunk={chunk} />;
          case FormulaDisplayChunkType.Submodel:
            return <SubmodelChunk key={idx} chunk={chunk} />;
          case FormulaDisplayChunkType.AtomicNumber:
            return <AtomicNumberChunk key={idx} chunk={chunk} />;
          case FormulaDisplayChunkType.ThisSegment:
            return <ThisSegmentChunk key={idx} chunk={chunk} />;
          case FormulaDisplayChunkType.Driver:
          default: {
            return <DriverChunk key={idx} chunk={chunk} textColor={color} />;
          }
        }
      })}
      {formulaDisplay.chunks.length === 0 && <BaseFormulaChunk text={' '} />}
    </Flex>
  );
};

export default Formula;
