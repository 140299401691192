import { Group } from '@visx/group';
import { Circle } from '@visx/shape';
import React from 'react';

interface Props {
  x: number;
  y: number;
  color: string;
}

const CursorPoint: React.FC<Props> = ({ x, y, color }) => {
  return (
    <Group left={x} top={y}>
      <Circle r={4} fill={color} stroke="white" strokeWidth={2} pointerEvents="none" />
    </Group>
  );
};

export default React.memo(CursorPoint);
