import { Flex, Text } from '@chakra-ui/react';
import { BodyScrollEvent, ICellRendererParams } from 'ag-grid-community';
import { debounce, isString, noop } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import TimeSeriesDisplayValue, {
  TimeSeriesDisplayValueProps,
} from 'components/AgGridComponents/CellRenderer/TimeSeriesDisplayValue';
import {
  CELL_VALUE_DISPLAY_VALUE_STYLE,
  CELL_VALUE_STYLE_TIMESERIES_STYLE,
} from 'components/AgGridComponents/CellRenderer/constants';
import { cellValueStyle } from 'components/AgGridComponents/CellRenderer/helpers';
import { CellValueStyle } from 'components/AgGridComponents/CellRenderer/types';
import { useDatabaseContextMenu } from 'components/AgGridComponents/DatabaseTableAgGrid/DatabaseContextMenu';
import { DatabaseHeaderFormulaButton } from 'components/AgGridComponents/DatabaseTableAgGrid/DatabaseHeader';
import ObjectSubDriverFormulaCellRenderer from 'components/AgGridComponents/ObjectPropertyCellRenderer/ObjectSubDriverFormulaCellRenderer';
import { isShowingCellPaletteOnCurrentCell } from 'components/AgGridComponents/helpers/cellPaletteAnchorCell';
import { CellValue } from 'components/AgGridComponents/types/DatabaseColumnDef';
import {
  AgGridTimeseriesContext,
  TimeseriesColumnDef,
  TimeseriesPropertyRow,
} from 'components/AgGridComponents/types/TimeseriesColumnDef';
import { useContextMenu } from 'components/ContextMenu/ContextMenu';
import ObjectFieldTypeIcon from 'components/ObjectFieldTypeIcon/ObjectFieldTypeIcon';
import {
  ACTUALS_FORMULA_COLUMN_TYPE,
  FORECAST_FORMULA_COLUMN_TYPE,
  OPTIONS_COLUMN_TYPE,
  PROPERTY_COLUMN_TYPE,
} from 'config/modelView';
import { ValueType } from 'generated/graphql';
import { HIDDEN_DATA_TEXT } from 'helpers/permissions';
import { safeObjGet } from 'helpers/typescript';
import useAppSelector from 'hooks/useAppSelector';
import { attributesByIdSelector } from 'selectors/dimensionsSelector';
import { CalculationError } from 'types/dataset';

type ObjectPropertyCellRendererParams<T = any> = Omit<
  ICellRendererParams<TimeseriesPropertyRow, T, AgGridTimeseriesContext & { left: number }>,
  'colDef'
> & {
  colDef: TimeseriesColumnDef;
};

export const isObjectPropertyRowCellError = (c: CellValue): c is CalculationError =>
  c != null && typeof c === 'object' && 'error' in c;

export const objectPropertyDeriveValueStyle = (
  value: CellValue,
  row: TimeseriesPropertyRow | null | undefined,
  colDef: TimeseriesColumnDef,
): CellValueStyle | null => {
  const { monthKey, afterLastActuals } = colDef.meta;
  return row == null
    ? null
    : isObjectPropertyRowCellError(value)
      ? 'error'
      : cellValueStyle({
          backingType: 'objectField',
          isActuals: !afterLastActuals,
          driverHasActualsFormula: row.actualsFormula != null,
          fieldHasIntegrationActuals: row.meta.isIntegrationProperty,
          hasHardcodedActual: monthKey != null && row.hardCodedActuals.has(monthKey),
          hasImpact: monthKey != null && row.curvePointsByMonthKey[monthKey] != null,
        });
};

const ObjectPropertyCellRendererContent: React.FC<
  ObjectPropertyCellRendererParams<string | CalculationError>
> = (props) => {
  const { data: row, value, api, colDef, node, context } = props;
  const attributesById = useAppSelector(attributesByIdSelector);
  const isAbsolutelyPositioned = row?.meta.isDimensionalProperty || row?.meta.isStartDateField;

  const showCellPalette = isShowingCellPaletteOnCurrentCell({ rowNode: node, colDef }, context);

  // Keep the horizonal scroll in sync for start field and dimensional properties.
  const [left, setLeft] = useState(0);
  useEffect(() => {
    if (!isAbsolutelyPositioned) {
      return noop;
    }

    const handleScroll = debounce((e: BodyScrollEvent) => {
      setLeft(e.left);
    }, 100);
    api.addEventListener('bodyScroll', handleScroll);

    return () => {
      if (api.isDestroyed()) {
        return;
      }
      api.removeEventListener('bodyScroll', handleScroll);
    };
  }, [api, context, isAbsolutelyPositioned]);

  if (
    row?.meta.isRestricted &&
    colDef.colId !== OPTIONS_COLUMN_TYPE &&
    colDef.colId !== PROPERTY_COLUMN_TYPE
  ) {
    return <Text color="gray.500">{HIDDEN_DATA_TEXT}</Text>;
  }

  // The formula editor has some odd internal behaviors that makes keeping the
  // most recent formula value difficult to keep in sync.
  // Thus the formula cell renderer uses a Redux selector internally to derive
  // the formula strings intead of relying on the data source internal
  if (
    colDef.colId === ACTUALS_FORMULA_COLUMN_TYPE ||
    colDef.colId === FORECAST_FORMULA_COLUMN_TYPE
  ) {
    const p = { ...props, value: !isObjectPropertyRowCellError(value) ? value : undefined };
    return <ObjectSubDriverFormulaCellRenderer {...p} />;
  }

  const valueStyle = objectPropertyDeriveValueStyle(value, row, colDef);

  if (row == null || value == null || valueStyle == null) {
    return null;
  }

  const cellAlignment = row.valueType === ValueType.Attribute ? 'left' : 'right';

  const commonTimeSeriesDisplayValueProps: Pick<
    TimeSeriesDisplayValueProps,
    'style' | 'timeSeriesStyle' | 'cellAlignment' | 'error' | 'showCellPalette'
  > = {
    cellAlignment,
    timeSeriesStyle: CELL_VALUE_STYLE_TIMESERIES_STYLE[valueStyle],
    style: CELL_VALUE_DISPLAY_VALUE_STYLE[valueStyle],
    showCellPalette,
    error: isObjectPropertyRowCellError(value) ? value : undefined,
  };

  if (colDef.colId === PROPERTY_COLUMN_TYPE && isString(value)) {
    return (
      <Flex justifyContent="space-between" w="full" h="full">
        <Text gap={1} display="flex" alignItems="center" color="gray.500">
          <ObjectFieldTypeIcon fieldSpecId={row.fieldSpecId ?? ''} />
          {value}
        </Text>
        <DatabaseHeaderFormulaButton driverPropertyId={row.fieldSpecId ?? ''} />
      </Flex>
    );
  }

  if (row.valueType === ValueType.Attribute && isString(value)) {
    const attribute = safeObjGet(attributesById[value]);
    if (attribute == null || attribute.type !== 'UserAdded') {
      return null;
    }

    return (
      <TimeSeriesDisplayValue
        value={{ value: attribute.value, type: ValueType.Attribute }}
        attributeValueLabel={attribute.value}
        badgeTheme={attribute.deleted ? 'error' : 'timeSeries'}
        {...commonTimeSeriesDisplayValueProps}
        cellAlignment={isAbsolutelyPositioned ? 'absolute' : 'left'}
        left={left + 10}
      />
    );
  }

  if (row.valueType === ValueType.Number) {
    return (
      <TimeSeriesDisplayValue
        value={{ value: value == null ? undefined : Number(value), type: ValueType.Number }}
        {...commonTimeSeriesDisplayValueProps}
        backingType="driver"
        displayConfiguration={row.displayConfiguration}
        showCellPalette={showCellPalette}
      />
    );
  }

  if (row.valueType === ValueType.Timestamp) {
    return (
      <TimeSeriesDisplayValue
        value={{ value: String(value), type: ValueType.Timestamp }}
        {...commonTimeSeriesDisplayValueProps}
        cellAlignment={isAbsolutelyPositioned ? 'absolute' : 'right'}
        left={left + 10}
      />
    );
  }

  return <Text data-sentry-mask>{isObjectPropertyRowCellError(value) ? undefined : value}</Text>;
};

const ObjectPropertyCellRenderer: React.FC<ObjectPropertyCellRendererParams> = (props) => {
  const { openContextMenu } = useContextMenu();
  const setDatabaseContextMenuIds = useDatabaseContextMenu();

  const { colId } = props.colDef;
  const handleOpenContextMenu = useCallback(
    (event: React.MouseEvent) => {
      if (colId !== PROPERTY_COLUMN_TYPE || props.data == null) {
        return;
      }
      const { fieldSpecId, objectSpecId } = props.data;
      event.preventDefault();
      event.stopPropagation();
      openContextMenu();
      if (fieldSpecId != null) {
        setDatabaseContextMenuIds({ fieldSpecId, objectSpecId });
      }
    },
    [colId, openContextMenu, props.data, setDatabaseContextMenuIds],
  );

  const propertyName = props.data?.data.property;

  return (
    <Flex
      px="2"
      h="full"
      w="full"
      alignItems="center"
      onClick={handleOpenContextMenu}
      onContextMenu={handleOpenContextMenu}
      data-testid={`object-property-cell-${propertyName}-${colId}`}
    >
      <ObjectPropertyCellRendererContent {...props} />
    </Flex>
  );
};

export default ObjectPropertyCellRenderer;
