import {
  DependencyListener,
  DependencyListenerArgs,
} from 'helpers/formulaEvaluation/ForecastCalculator/DependencyListener';
import evaluate from 'helpers/formulaEvaluation/ForecastCalculator/ForecastCalculator';
import { ModelingError } from 'helpers/formulaEvaluation/ForecastCalculator/ModelingError';
import { Dependency } from 'types/dependencies';

export function getDependencies(args: { formula: string } & DependencyListenerArgs): Dependency[] {
  const { formula, ...restArgs } = args;
  const listener = new DependencyListener(restArgs);
  try {
    return evaluate(formula, listener);
  } catch (e) {
    if (e instanceof ModelingError) {
      return [];
    } else {
      throw e;
    }
  }
}
