import React from 'react';

import ChartLinePath from 'components/DriverChart/ChartLinePath';
import {
  DASH_ARRAY_BY_SIZE,
  DriverChartDatum,
  EVENT_COMPARE_LINE_COLOR,
  STROKE_WIDTH_BY_SIZE,
} from 'config/driverChart';
import theme from 'config/theme';
import useChartContext from 'hooks/useChartContext';

const { colors } = theme;

type ChartLineDataType = 'actuals' | 'layer' | 'forecast' | 'placeholder';
interface Props {
  data: DriverChartDatum[];
  type: ChartLineDataType;
  color?: string;
}

export const DATA_TYPE_TO_COLOR: Record<ChartLineDataType, string> = {
  actuals: colors.actuals,
  layer: EVENT_COMPARE_LINE_COLOR,
  forecast: colors.forecast,
  placeholder: colors.gray[400],
};

const ChartLine: React.FC<Props> = ({ data, type, color }) => {
  const { size } = useChartContext();
  const strokeWidth = STROKE_WIDTH_BY_SIZE[size];
  const dashArray = type === 'forecast' || type === 'layer' ? DASH_ARRAY_BY_SIZE[size] : 'initial';
  return (
    <>
      <ChartLinePath
        data={data}
        strokeColor="white"
        strokeWidth={strokeWidth + 1}
        strokeDasharray={dashArray}
      />
      <ChartLinePath
        data={data}
        strokeColor={color != null ? color : DATA_TYPE_TO_COLOR[type]}
        strokeWidth={strokeWidth}
        strokeDasharray={dashArray}
      />
    </>
  );
};

export default ChartLine;
