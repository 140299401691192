import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

// This function creates a set of function that helps us create multipart component styles.
const helpers = createMultiStyleConfigHelpers(['overlay', 'dialog', 'dialogContainer']);

const Modal = helpers.defineMultiStyleConfig({
  baseStyle: {
    overlay: { bg: 'transparent' },
    dialog: {
      borderRadius: 'md',
      shadow: 'modal',
      my: 4,
    },
  },
  sizes: {
    detailPane: {
      dialogContainer: {
        height: 'auto',
        minHeight: '80vh',
      },
    },
  },
  variants: {
    overlay: {
      overlay: {
        bgColor: 'rgba(0, 0, 0, 50%)',
      },
    },
  },
});

export default Modal;
