import { Box, Center, Text } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';

import { Tooltip } from 'chakra/tooltip';
import { TOOLTIP_OPEN_DELAY } from 'components/RunwayApp/GlobalNavigationMenu';

interface Props {
  error?: string;
  tooltip?: string;
  isSelected: boolean;
  offsetKey?: string;
  label: string;
  attributes?: React.ReactNode;
  placeholder?: React.ReactNode;
  icon?: JSX.Element;
  onMouseDown?: React.MouseEventHandler;
  children: React.ReactNode;
}

const ReferencePill = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      error,
      tooltip,
      isSelected,
      offsetKey,
      label,
      icon,
      attributes,
      placeholder,
      children,
      onMouseDown,
    },
    ref,
  ) => {
    const hasIcon = icon != null;
    const hasError = error != null;
    const hasTooltip = tooltip != null;

    const [isTextTruncated, setIsTextTruncated] = useState(false);
    const textRef = useRef<HTMLSpanElement>(null);
    useEffect(() => {
      if (textRef.current == null) {
        return;
      }
      const { scrollWidth, clientWidth } = textRef.current;
      setIsTextTruncated(scrollWidth > clientWidth);
    }, []);

    let text = (
      <Text
        ref={textRef}
        as="span"
        flexShrink={0}
        maxWidth="37rem"
        isTruncated
        pr={attributes == null ? 2 : 0}
        py="0.125rem"
        userSelect="none"
        wordBreak="break-all"
      >
        {label}
      </Text>
    );
    if (isTextTruncated) {
      text = (
        <Tooltip placement="top" label={label}>
          {text}
        </Tooltip>
      );
    }

    return (
      // The error tooltip takes precedence above the user-provided tooltip
      <Tooltip
        isDisabled={!hasError && !hasTooltip}
        placement="top"
        label={error ?? tooltip}
        openDelay={TOOLTIP_OPEN_DELAY}
      >
        <Box
          data-offset-key={offsetKey}
          ref={ref}
          as="span"
          contentEditable={false}
          display="inline-flex"
          fontSize="xxs"
          fontWeight="medium"
          overflow="hidden"
          height={6}
          mr="2px"
          _focusVisible={{ outline: 'none' }}
        >
          <Box
            as="span"
            alignItems="center"
            bgColor="white"
            borderColor={isSelected ? 'selection.300' : hasError ? 'failure' : 'gray.300'}
            borderRadius="xl"
            borderWidth="px"
            color={hasError ? 'failure' : 'gray.600'}
            cursor="pointer"
            display="inline-flex"
            flexShrink={0}
            onMouseDown={onMouseDown}
            zIndex={5}
            pl={2}
            columnGap="0.125rem"
            _hover={{ bgColor: isSelected ? 'selection.100' : 'gray.200' }}
          >
            {/* This is a hack, but the parent span needs to have a text as its first child to force it to be
            centered with the rest of the pills */}
            <Text as="span" width={0} visibility="hidden">
              {' '}
            </Text>
            {hasIcon && <Center boxSize="0.875rem">{icon}</Center>}
            {text}
            {attributes}
            {placeholder}
          </Box>
          {children}
        </Box>
      </Tooltip>
    );
  },
);

export default ReferencePill;
