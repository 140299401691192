import { CharStreams, CommonTokenStream, RecognitionException, Recognizer, Token } from 'antlr4ts';
import { ParseTreeWalker } from 'antlr4ts/tree/ParseTreeWalker';
import { toNumber } from 'lodash';

import { CURRENCY_CONFIGS } from 'config/currency';
import { ImpactType } from 'generated/graphql';
import { ImpactParserLexer } from 'helpers/formulaEvaluation/ImpactParser/ImpactParserLexer';
import { ImpactParserListener } from 'helpers/formulaEvaluation/ImpactParser/ImpactParserListener';
import { ImpactParserListenerImpl } from 'helpers/formulaEvaluation/ImpactParser/ImpactParserListenerImpl';
import { ImpactParserParser } from 'helpers/formulaEvaluation/ImpactParser/ImpactParserParser';
import { CurvePointTyped } from 'reduxStore/models/events';

export const DELTA_WRONG_SIDE_ERR = `Can only add arithmetic to the right side of the row formula value (but we're fixing that soon!)`;
export const INVALID_DELTA_FORMAT_ERR = `Can only add or subtract from row formula value (but we're fixing that soon!)`;
export const INVALID_ARITHMETIC_ERR = 'Formula must be a valid arithmetic expression';
const GENERIC_ERR = 'Invalid plans formula';

// This mapping from ANTLR err to a user-friendly error message is a bit
// like whack-a-mole. We can improve these as we go.
function simplifyAntlrErrorMsg(msg: string): string {
  if (msg.startsWith('mismatched input')) {
    if (msg.startsWith(`mismatched input '+'`) || msg.startsWith(`mismatched input '-'`)) {
      return DELTA_WRONG_SIDE_ERR;
    }
    if (msg.endsWith('expecting <EOF>')) {
      return INVALID_DELTA_FORMAT_ERR;
    }
    return INVALID_ARITHMETIC_ERR;
  }

  if (msg.startsWith('extraneous input')) {
    if (msg.endsWith('expecting <EOF>')) {
      return INVALID_ARITHMETIC_ERR;
    }
  }

  return GENERIC_ERR;
}

// eslint-disable-next-line max-params
function handleParserError(
  _recognizer: Recognizer<Token, any>,
  _offendingSymbol: Token | undefined,
  _line: number,
  _charPositionInLine: number,
  msg: string,
  _e: RecognitionException | undefined,
) {
  const simplifiedMsg = simplifyAntlrErrorMsg(msg);
  throw new Error(simplifiedMsg);
}

// Mostly copy-pasta'd from ForecastCalculator.ts
const getParser = (input: string): ImpactParserParser => {
  const inputStream = CharStreams.fromString(input);
  const lexer = new ImpactParserLexer(inputStream);

  const commonTokenStream = new CommonTokenStream(lexer);
  const parser = new ImpactParserParser(commonTokenStream);
  parser.removeErrorListeners();
  parser.addErrorListener({ syntaxError: handleParserError });

  return parser;
};

// We order the symbols by length so that we look to replace "NZ$", "A$", etc. before "$".
const CURRENCY_SYMBOL_REGEX = new RegExp(
  CURRENCY_CONFIGS.map(({ symbol }) => `\\${symbol.replace(/\$/g, '\\$')}`)
    .sort((a, b) => b.length - a.length)
    .join('|'),
  'ig',
);

function removeCurrencySymbols(formula: string): string {
  return formula.replace(CURRENCY_SYMBOL_REGEX, '');
}

// Calculator can't handle expressions starting with "+", e.g. "+ 1"
function removeLeadingPlus(formula: string): string {
  if (formula.startsWith('+')) {
    return formula.slice(1);
  }

  return formula;
}

function cleanFormula(formula: string | null | undefined): string | undefined {
  if (formula == null) {
    return undefined;
  }

  const formulaWithoutLeadingPlus = removeLeadingPlus(formula);
  const cleanedFormula = removeCurrencySymbols(formulaWithoutLeadingPlus);

  return cleanedFormula.trim();
}

export function parseImpact(rawFormula: string | null | undefined): CurvePointTyped | undefined {
  const formula = cleanFormula(rawFormula);

  if (formula == null || formula === '') {
    // No impact
    return undefined;
  }

  const parser = getParser(formula);
  const interpreter = new ImpactParserListenerImpl();
  ParseTreeWalker.DEFAULT.walk(interpreter as ImpactParserListener, parser.calculator());

  return interpreter.getResult(formula);
}

// We want to support a similar experience inputting actuals as inputting impacts
// (allowing users to type formulas, error messaging, etc). The primary difference
// is that actuals don't support Delta impacts. This should not actually be possible
// (since we don't inject atomicNumber tokens into actuals (see
// getActiveCellFormulaDisplay), so the thrown error is primarily a type guard.
export function parseNumber(formula: string | null | undefined): number | null {
  const parsedAsImpact = parseImpact(formula);

  if (parsedAsImpact?.impactType === ImpactType.Delta) {
    throw new Error('Unexpected impact type after parsing actuals');
  }

  const val = parsedAsImpact?.value;

  return val != null ? toNumber(val) : null;
}
