import isEqual from 'lodash/isEqual';
import last from 'lodash/last';
import pluralize from 'pluralize';

import {
  formatRelativeDate,
  LAST_MONTH_TIME_RANGE,
  TIME_PERIOD_DISPLAY_NAME,
  TIME_PERIOD_TO_RELATIVE_MONTHS,
  TIME_RANGE_DISPLAY_NAME,
} from 'config/datetime';
import { FORMULA_EDITOR_CLASS } from 'config/formula';
import { CELL_POPOVER_CLASS } from 'config/submodels';
import { BlockFilterOperator, BuiltInDimensionType, ValueType } from 'generated/graphql';
import { getDateTimeFromMonthKey, shortMonthFormat } from 'helpers/dates';
import { getMatchingSubDrivers, isAttribute } from 'helpers/dimensionalDrivers';
import { isNotNull } from 'helpers/typescript';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { Driver, DriverId, DriverType } from 'reduxStore/models/drivers';
import { ExtDriver } from 'reduxStore/models/extDrivers';
import { RelativeDate, TimePeriod, TimeRange, TimeUnit } from 'types/datetime';
import { FilterItem, isEntityIdFilterItem } from 'types/filtering';
import {
  ANY_ATTR,
  AtomicNumberEntityData,
  AtomicNumberMetadata,
  AttributeFilters,
  DateReference,
  DriverEntityData,
  DriverRefMetadata,
  EntityData,
  EntityType,
  ExtDriverEntityData,
  ExtDriverRefMetadata,
  ExtQueryEntityData,
  ExtQueryRefMetadata,
  FormulaBooleanOperator,
  FormulaTimeRange,
  FormulaTimeSeriesOperator,
  ObjectSpecEntityData,
  ObjectSpecRefMetadata,
  RawFormula,
  RawTimeInput,
  SubmodelEntityData,
  SubmodelRefMetadata,
  ThisSegmentEntityData,
  ThisSegmentMetadata,
} from 'types/formula';

export function getTimeRangeInput(timeRange: FormulaTimeRange): RawTimeInput {
  if (timeRange.type === 'relativeVariable') {
    return `relative(-driver(${timeRange.start}, relative(0, 0)),-driver(${timeRange.end}, relative(0, 0)))`;
  }
  if (timeRange.type === 'range') {
    const start = getDateReferenceFormulaString(timeRange.start);
    const end = getDateReferenceFormulaString(timeRange.end);
    return `range(${start},${end})`;
  }
  const { start, end, type } = timeRange;
  return `${type}(${start},${end})`;
}

function getDateReferenceFormulaString(dateRef: DateReference): string {
  if (dateRef.type === 'relative') {
    const relDate = dateRef.val;
    return `rel${pluralize(relDate.unit)}(${relDate.val})`;
  }
  return dateRef.val;
}

export function hasFormulaInputOpen() {
  return document.querySelector(`.${FORMULA_EDITOR_CLASS}`) != null;
}

export function hasTableCellPopoverOpen() {
  return hasFormulaInputOpen() || document.querySelector(`.${CELL_POPOVER_CLASS}`) != null;
}

export function createDriverRef({
  id,
  dateRange,
  operator,
  refType,
  attributeFilters,
}: Pick<DriverRefMetadata, 'id' | 'dateRange' | 'operator' | 'attributeFilters'> & {
  refType: 'driver' | 'dimDriver';
}): string {
  return updateWithOperator(
    updateWithFilter(
      `${refType}(${id},${dateRange != null ? getTimeRangeInput(dateRange) : 'null}'})`,
      refType,
      attributeFilters,
    ),
    operator,
  );
}

// TODO: refactor this to be composed via updateWithFilter() once we have a better idea of the interface
function createSubmodelRef(data: SubmodelRefMetadata) {
  const dateRange = data.dateRange;
  const dateRangePart = dateRange != null ? `, ${getTimeRangeInput(dateRange)}` : '';
  return `filter(submodel(${data.id}${dateRangePart}), DRIVER_GROUP:${data.driverGroupFilter})`;
}

export function createExtDriverRef(data: ExtDriverRefMetadata) {
  const dateRange = data.dateRange;
  return `extDriver(${data.id}, ${dateRange != null ? getTimeRangeInput(dateRange) : 'null'})`;
}

function createAtomicNumber(data: AtomicNumberMetadata) {
  return `atomicNumber(${data.label})`;
}

function createThisSegment(data: ThisSegmentMetadata) {
  const dateRange = data.dateRange;

  if (data.thisEntityType === EntityType.ObjectSpec && data.fieldId != null) {
    return `match(objectSpec(${data.id}, ${createObjectFieldRef(
      data.fieldId,
      dateRange,
    )}), attributeList(ALL_CONTEXT_ATTRIBUTES))`;
  }
  if (data.thisEntityType === EntityType.Driver) {
    const dateRangePart = dateRange != null ? `, ${getTimeRangeInput(dateRange)}` : '';
    return `match(dimDriver(${data.id}${dateRangePart}), attributeList(ALL_CONTEXT_ATTRIBUTES))`;
  }
  // Returning this will result in the editor showing an error to give the user a chance to fix it
  return 'match()';
}

function createObjectFieldRef(
  fieldId: BusinessObjectFieldSpecId,
  dateRange: FormulaTimeRange | undefined,
) {
  return `field(${fieldId}, ${dateRange != null ? getTimeRangeInput(dateRange) : 'null'})`;
}

export function getBlockFilterExpression(id: BusinessObjectSpecId, filters: FilterItem[]) {
  return `objectSpec(${id} ${createObjectFilter(filters)})`;
}

export function createObjectSpec(data: ObjectSpecRefMetadata) {
  const { dateRange, fieldId, isThisRef } = data;

  const filters = `${data.filters != null ? createObjectFilter(data.filters) : ''}`;
  if (isThisRef) {
    if (fieldId == null) {
      throw new Error('expected object self reference to have a field id');
    }
    return `object(this, ${createObjectFieldRef(fieldId, dateRange)}${filters})`;
  }

  return `objectSpec(${data.id}, ${
    fieldId != null
      ? createObjectFieldRef(fieldId, dateRange)
      : dateRange != null
        ? getTimeRangeInput(dateRange)
        : 'null'
  }${filters})`;
}

function createExtQueryRef(data: ExtQueryRefMetadata) {
  const dateRange = data.dateRange;
  const dateRangeStr = dateRange != null ? getTimeRangeInput(dateRange) : 'null';
  const filters =
    data.attributeFilters != null ? attributeFiltersToAntlr(data.attributeFilters) : undefined;
  const filterStr = filters != null ? `, filter(${filters})` : '';
  return `extQuery(${data.id}, ${dateRangeStr}${filterStr})`;
}

const BLOCK_FILTER_OP_TO_FORMULA_BOOLEAN_OP: Record<BlockFilterOperator, FormulaBooleanOperator> = {
  [BlockFilterOperator.Equals]: FormulaBooleanOperator.Equals,
  [BlockFilterOperator.NotEquals]: FormulaBooleanOperator.NotEquals,
  [BlockFilterOperator.GreaterThan]: FormulaBooleanOperator.GreaterThan,
  [BlockFilterOperator.GreaterThanOrEqualTo]: FormulaBooleanOperator.GreaterThanOrEqualTo,
  [BlockFilterOperator.LessThan]: FormulaBooleanOperator.LessThan,
  [BlockFilterOperator.LessThanOrEqualTo]: FormulaBooleanOperator.LessThanOrEqualTo,
  [BlockFilterOperator.IsNotNull]: FormulaBooleanOperator.NotEquals,
  [BlockFilterOperator.IsNull]: FormulaBooleanOperator.Equals,
};

function createObjectFilter(filters: FilterItem[]) {
  if (filters.length === 0) {
    return '';
  }

  const filterStrings = filters
    .map((filter) => {
      let comparisonString = `"${filter.expected}"`;
      if (filter.valueType === ValueType.Number && filter.expected != null) {
        comparisonString = `'${filter.expected}'`;
      }
      if (filter.valueType === ValueType.Timestamp && filter.expected != null) {
        comparisonString = getTimeRangeInput(filter.expected);
      }
      if (filter.valueType === ValueType.Attribute && filter.expected != null) {
        comparisonString = filter.expected.join('|');
      }
      if (isEntityIdFilterItem(filter) && filter.expected != null) {
        return filter.expected.join(',');
      }
      if (
        filter.operator === BlockFilterOperator.IsNull ||
        filter.operator === BlockFilterOperator.IsNotNull
      ) {
        comparisonString = 'NULL';
      }

      if (filter.operator == null) {
        return null;
      }

      const booleanOp = BLOCK_FILTER_OP_TO_FORMULA_BOOLEAN_OP[filter.operator];

      return `field(${filter.filterKey}, relative(0,0)) ${booleanOp} ${comparisonString}`;
    })
    .filter(isNotNull);

  return `, filter(${filterStrings.join(' && ')})`;
}

function updateWithOperator(ref: string, operator: string | undefined): string {
  if (operator != null && operator.length > 0) {
    return `${operator}(${ref})`;
  }
  return ref;
}

function updateWithFilter(
  ref: string,
  refType: 'driver' | 'dimDriver',
  filters?: AttributeFilters,
) {
  if (refType === 'driver' || filters == null || Object.values(filters).length === 0) {
    return ref;
  }
  const filterStr = attributeFiltersToAntlr(filters);
  return `filter(${ref}, ${filterStr})`;
}

function attributeFiltersToAntlr(filters: AttributeFilters) {
  return Object.entries(filters)
    .flatMap(([dimId, attrs]) => {
      const convertedDimId =
        dimId === BuiltInDimensionType.CalendarTime
          ? 'CALENDAR'
          : dimId === BuiltInDimensionType.RelativeTime
            ? 'RELATIVE'
            : dimId;
      return attrs.map((attr) => `${convertedDimId}:${isAttribute(attr) ? attr.id : attr}`);
    })
    .join(' ');
}

// Ignore spaces when comparing two formulas; spacing may change on loading the cell
export const WHITESPACE_REGEX = /\s/g;
// But do distinguish between 1,100 and 1, 100
const NUMBER_SEPARATOR_REGEX = /(\d),\s+(\d)/g;
const NUMBER_SEPARATOR_PLACEHOLDER = '$1<NUMBER_SEPARATOR>$2';

function transformFormulaForCompare(formula: RawFormula): RawFormula {
  return formula
    .replaceAll(NUMBER_SEPARATOR_REGEX, NUMBER_SEPARATOR_PLACEHOLDER)
    .replaceAll(WHITESPACE_REGEX, '');
}

export const areFormulasEqual = (
  formula1: RawFormula | undefined | null,
  formula2: RawFormula | undefined | null,
): boolean => {
  if (formula1 == null && formula2 == null) {
    return true;
  } else if (formula1 == null || formula2 == null) {
    return false;
  }

  return transformFormulaForCompare(formula1) === transformFormulaForCompare(formula2);
};

function getDriverRefType(driver?: Driver) {
  if (driver == null) {
    return 'driver';
  }
  if (driver.type === DriverType.Dimensional) {
    return 'dimDriver';
  }
  return 'driver';
}

export function getSingleSubdriver(driver: Driver, attrs?: AttributeFilters) {
  if (driver.type !== DriverType.Dimensional || attrs == null) {
    return null;
  }

  if (
    Object.values(attrs).some(
      (filter) => filter.length > 1 || filter.some((attr) => attr === ANY_ATTR),
    )
  ) {
    return null;
  }

  const subdrivers = getMatchingSubDrivers(driver, attrs);

  if (subdrivers.length !== 1) {
    return null;
  }
  return subdrivers[0];
}

export function entityToAntlr(
  entity: EntityData,
  driversById: Record<DriverId, Driver | undefined>,
): string {
  if (isDriverEntityData(entity)) {
    const driver = driversById[entity.data.id];
    const subdriver =
      driver != null ? getSingleSubdriver(driver, entity.data.attributeFilters) : undefined;
    const resolvedDriver = subdriver != null ? driversById[subdriver.driverId] : driver;
    return createDriverRef({
      ...entity.data,
      id: resolvedDriver?.id ?? entity.data.id,
      refType: getDriverRefType(resolvedDriver),
      attributeFilters: entity.data.attributeFilters,
    });
  }

  if (isSubmodelEntityData(entity)) {
    return createSubmodelRef(entity.data);
  }

  if (isExtDriverEntityData(entity)) {
    return createExtDriverRef(entity.data);
  }

  if (isObjectSpecEntityData(entity)) {
    return createObjectSpec(entity.data);
  }

  if (isExtQueryEntityData(entity)) {
    return createExtQueryRef(entity.data);
  }

  if (isAtomicNumberData(entity)) {
    return createAtomicNumber(entity.data);
  }

  if (isThisSegmentEntityData(entity)) {
    return createThisSegment(entity.data);
  }

  throw new Error(`unsupported entity: ${entity}`);
}

export function getPeriodFormulaTimeRange(period: TimePeriod): FormulaTimeRange | null {
  const start = TIME_PERIOD_TO_RELATIVE_MONTHS[period];
  if (start == null) {
    return null;
  }

  return { type: 'relative', start, end: start };
}

export function getRangeFormulaTimeRange(range: TimeRange): FormulaTimeRange | null {
  switch (range) {
    case TimeRange.LastSixMonths:
      return {
        type: 'range',
        start: { type: 'relative', val: { unit: TimeUnit.Month, val: -6, reference: 'today' } },
        end: { type: 'relative', val: { unit: TimeUnit.Month, val: -1, reference: 'today' } },
      };
    case TimeRange.LastTwelveMonths:
      return {
        type: 'range',
        start: { type: 'relative', val: { unit: TimeUnit.Month, val: -12, reference: 'today' } },
        end: { type: 'relative', val: { unit: TimeUnit.Month, val: -1, reference: 'today' } },
      };
    case TimeRange.YearToDate:
      return {
        type: 'range',
        start: { type: 'relative', val: { unit: TimeUnit.Year, val: 0, reference: 'yearStart' } },
        end: { type: 'relative', val: { unit: TimeUnit.Month, val: 0, reference: 'today' } },
      };
    case TimeRange.QuarterToDate:
      return {
        type: 'range',
        start: { type: 'relative', val: { unit: TimeUnit.Quarter, val: 0, reference: 'today' } },
        end: { type: 'relative', val: { unit: TimeUnit.Month, val: 0, reference: 'today' } },
      };
    default:
      break;
  }
  return null;
}

export function isThisMonth(range: FormulaTimeRange) {
  return range.type === 'relative' && range.end === 0 && range.start === 0;
}
export function getFormulaDateRangeDisplay(
  dateRange: FormulaTimeRange,
  driverNamesById: Record<DriverId, string>,
  operator?: FormulaTimeSeriesOperator | undefined,
): string {
  const selectedTimePeriod = Object.values(TimePeriod)
    .filter((period) => period !== TimePeriod.Custom)
    .find((period) => isEqual(getPeriodFormulaTimeRange(period), dateRange));
  if (selectedTimePeriod != null) {
    return TIME_PERIOD_DISPLAY_NAME[selectedTimePeriod];
  }

  const selectedTimeRange = Object.values(TimeRange)
    .filter((range) => range !== TimeRange.Custom)
    .find((range) => isEqual(getRangeFormulaTimeRange(range), dateRange));
  if (selectedTimeRange != null && operator != null) {
    return TIME_RANGE_DISPLAY_NAME[selectedTimeRange];
  }

  if (dateRange.type === 'relative') {
    let { start, end } = dateRange;

    if (start === end) {
      start *= -1;
      end *= -1;
      const relativeDisplayName = 'months ago';
      return `${start} ${relativeDisplayName}`;
    }

    // for deprecated relative range, format the same as DateReference ranges below
    const relativeMonthsToDateRef = (numMonths: number): DateReference => {
      return { type: 'relative', val: relativeMonthsToRelativeDate(numMonths) };
    };
    return `${formatDateReference(relativeMonthsToDateRef(start))} - ${formatDateReference(
      relativeMonthsToDateRef(end),
    )}`;
  }

  if (dateRange.type === 'relativeVariable') {
    const { start, end } = dateRange;
    const startDisplay =
      driverNamesById[start] != null ? `(-${driverNamesById[start]})` : undefined;
    const endDisplay = driverNamesById[end] != null ? `(-${driverNamesById[end]})` : undefined;
    if (start === end) {
      return `${startDisplay} month offset`;
    }

    return `${startDisplay} to ${endDisplay} month offset`;
  }

  if (dateRange.type === 'range') {
    const { start, end } = dateRange;
    if (isEqual(start, end)) {
      return formatDateReference(start);
    }
    // last N months
    if (
      start.type === 'relative' &&
      end.type === 'relative' &&
      end.val.reference === 'today' &&
      end.val.unit === TimeUnit.Month &&
      end.val.val === -1 &&
      start.val.unit === TimeUnit.Month &&
      start.val.val < 0
    ) {
      return `Last ${Math.abs(start.val.val)} months`;
    }
    // year-to-date
    if (isEqual(dateRange, getRangeFormulaTimeRange(TimeRange.YearToDate))) {
      return 'Year-to-date';
    }

    // Quarter-to-date
    if (isEqual(dateRange, getRangeFormulaTimeRange(TimeRange.QuarterToDate))) {
      return 'Quarter-to-date';
    }
    return `${formatDateReference(start)} to ${formatDateReference(end)}`;
  }

  return 'Custom';
}

function formatDateReference(dateRef: DateReference) {
  return dateRef.type === 'absolute'
    ? shortMonthFormat(getDateTimeFromMonthKey(dateRef.val))
    : formatRelativeDate(dateRef.val);
}

export function isDriverEntityData(entityData: EntityData): entityData is DriverEntityData {
  return entityData.type === EntityType.Driver;
}

export function isSubmodelEntityData(entityData: EntityData): entityData is SubmodelEntityData {
  return entityData.type === EntityType.Submodel;
}

export function isExtDriverEntityData(entityData: EntityData): entityData is ExtDriverEntityData {
  return entityData.type === EntityType.ExtDriver;
}

export function isObjectSpecEntityData(entityData: EntityData): entityData is ObjectSpecEntityData {
  return entityData.type === EntityType.ObjectSpec;
}

export function isExtQueryEntityData(entityData: EntityData): entityData is ExtQueryEntityData {
  return entityData.type === EntityType.ExtQuery;
}

export function isAtomicNumberData(entityData: EntityData): entityData is AtomicNumberEntityData {
  return entityData.type === EntityType.AtomicNumber;
}

export function isThisSegmentEntityData(
  entityData: EntityData,
): entityData is ThisSegmentEntityData {
  return entityData.type === EntityType.ThisSegment;
}

export function getCustomRelativeFormulaTimeRange(
  startDatePrior: Pick<RelativeDate, 'unit' | 'val'>,
  endDatePrior?: Pick<RelativeDate, 'unit' | 'val'>,
): FormulaTimeRange {
  const endPrior = endDatePrior ?? startDatePrior;
  const start: RelativeDate = {
    unit: startDatePrior.unit,
    val: startDatePrior.val,
    reference: 'today',
  };
  const end: RelativeDate = {
    unit: endPrior.unit,
    val: endPrior.val,
    reference: 'today',
  };
  return {
    type: 'range',
    start: { type: 'relative', val: start },
    end: { type: 'relative', val: end },
  };
}

export const THIS_MONTH_DATE_RANGE = {
  dateRange: { type: 'relative' as const, start: 0, end: 0 },
  dateRangeDisplay: TIME_PERIOD_DISPLAY_NAME[TimePeriod.ThisMonth],
};

export function defaultDateRange(
  formulaDriverId: DriverId,
  refDriverIds: DriverId[],
): { dateRange: FormulaTimeRange; dateRangeDisplay: string } {
  if (refDriverIds.some((driverId) => driverId === formulaDriverId)) {
    return {
      dateRange: { type: 'relative', start: -1, end: -1 },
      dateRangeDisplay: TIME_PERIOD_DISPLAY_NAME[TimePeriod.LastMonth],
    };
  }

  return THIS_MONTH_DATE_RANGE;
}

export function defaultLinkedDriverActualsFormula(
  extDriver: ExtDriver,
  currentActualsFormula?: RawFormula,
) {
  const { id, source } = extDriver;
  const extDriverRef = createExtDriverRef({
    id,
    source,
    label: last(extDriver?.path) ?? 'unknown',
    dateRange: THIS_MONTH_DATE_RANGE.dateRange,
  });
  const formula =
    currentActualsFormula != null ? `${currentActualsFormula} + ${extDriverRef}` : extDriverRef;
  return formula;
}

function relativeMonthsToRelativeDate(months: number): RelativeDate {
  return {
    unit: TimeUnit.Month,
    val: months,
    reference: 'today',
  };
}

export function getRelativeDateRangeFromFormulaTimeRange(
  formulaTimeRange: FormulaTimeRange,
): { start: RelativeDate; end: RelativeDate } | undefined {
  if (formulaTimeRange.type === 'range') {
    if (
      formulaTimeRange.start.type === 'relative' &&
      formulaTimeRange.end.type === 'relative' &&
      formulaTimeRange.start.val.reference === 'today' &&
      formulaTimeRange.end.val.reference === 'today' &&
      formulaTimeRange.start.val.unit === formulaTimeRange.end.val.unit
    ) {
      return { start: formulaTimeRange.start.val, end: formulaTimeRange.end.val };
    }
  }
  return undefined;
}

export const lastMonthSelfDriverRef = (id: DriverId) =>
  createDriverRef({
    id,
    dateRange: LAST_MONTH_TIME_RANGE,
    refType: 'driver',
  });

export const getDefaultMappedDriverFormula = (mappedDriverId: DriverId, attrListString: string) =>
  `sum(filter(dimDriver(${mappedDriverId},relative(0,0)), ${attrListString}))`;
