import { Action, isAnyOf } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/nextjs';
import { isEqual, max, min, omit, pick, uniq, uniqBy } from 'lodash';

import {
  BusinessObjectSpecDeleteInput,
  BusinessObjectSpecUpdateInput,
  BusinessObjectUpdateInput,
  DatasetMutationInput,
  DriverGroupDeleteInput,
  DriverType,
  DriverUpdateInput,
  EventGroupDeleteInput,
  ExtDriverDeleteInput,
  ExtObjectSpecDeleteInput,
  ExtObjectSpecUpdateInput,
  SubmodelDeleteInput,
} from 'generated/graphql';
import { extractMonthKey, getMonthKeyRange } from 'helpers/dates';
import { getImpactedEntityId } from 'helpers/events';
import { AutoFormatCacheSingleton } from 'helpers/formulaEvaluation/DriverFormatResolver/AutoFormatCache';
import {
  ALL_LAYERS_KEY,
  ALL_MONTHS_KEY,
  CacheKey,
  FormulaCacheSingleton,
  getFormulaCacheKey,
} from 'helpers/formulaEvaluation/ForecastCalculator/FormulaCache';
import { FormulaEntityTypedId } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import { nullSafeEqual, safeObjGet } from 'helpers/typescript';
import {
  BusinessObjectFieldSpec,
  BusinessObjectFieldSpecId,
  BusinessObjectSpec,
} from 'reduxStore/models/businessObjectSpecs';
import {
  BusinessObject,
  BusinessObjectFieldId,
  BusinessObjectId,
} from 'reduxStore/models/businessObjects';
import { AttributeId } from 'reduxStore/models/dimensions';
import { BasicDriver, DimensionalDriver, Driver, DriverId } from 'reduxStore/models/drivers';
import { Event, EventId, isDriverEvent, isObjectFieldEvent } from 'reduxStore/models/events';
import { DEFAULT_LAYER_ID, LayerId } from 'reduxStore/models/layers';
import { MutationBatch } from 'reduxStore/models/mutations';
import {
  NonNumericTimeSeries,
  NumericTimeSeries,
  ValueTimeSeries,
} from 'reduxStore/models/timeSeries';
import {
  calculationsSliceDeleteTempKeys,
  calculationsSliceInvalidateKeysForLayers,
  calculationsSliceResetCacheForLayers,
} from 'reduxStore/reducers/calculationsSlice';
import {
  applyMutationLocally_INTERNAL,
  initializeLockedSnapshot,
  initializeWithSnapshot,
  setCurrentLayer,
  undoMutationLocally_INTERNAL,
} from 'reduxStore/reducers/datasetSlice';
import rootReducer from 'reduxStore/reducers/index';
import {
  eventLiveEditPoint,
  startLiveEditingExistingEvent,
  startLiveEditingMultipleEvents,
} from 'reduxStore/reducers/liveEditSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { AppDispatch } from 'reduxStore/store';
import {
  businessObjectFieldByIdForLayerSelector,
  businessObjectFieldIdsByFieldSpecIdSelector,
  businessObjectFieldSpecByIdSelector,
  businessObjectFieldsForObjectIdAndLayerSelector,
} from 'selectors/businessObjectFieldSpecsSelector';
import { businessObjectSpecsByIdForLayerSelector } from 'selectors/businessObjectSpecsSelector';
import {
  businessObjectForFieldIdForLayerSelector,
  businessObjectsByIdForLayerSelector,
} from 'selectors/businessObjectsSelector';
import {
  dimensionalDriversBySubDriverIdSelector,
  driversByIdForLayerSelector,
} from 'selectors/driversSelector';
import {
  eventGroupsByIdForLayerSelector,
  eventsByIdForLayerSelector,
} from 'selectors/eventsAndGroupsSelector';
import { extDriversByIdSelector } from 'selectors/extDriversSelector';
import { extObjectSpecsByKeySelector } from 'selectors/extObjectSpecsSelector';
import {
  activeUnlockedLayerIdsSelector,
  currentLayerIdSelector,
  isLayerNamedVersionSelector,
} from 'selectors/layerSelector';
import { liveEditingEventIdsSelector, liveEditingEventsSelector } from 'selectors/liveEditSelector';
import { submodelIdByBlockIdSelector } from 'selectors/submodelPageSelector';
import { driversBySubmodelIdSelector } from 'selectors/submodelSelector';
import { MonthKey } from 'types/datetime';

export type KeysToInvalidate = {
  resetAllLayers: boolean;
  resetLayers: Set<LayerId>;
  allResolvedDeletedKeys: LayerKeys;
  deleteTempKeys: boolean;
  inferredFormatCacheKeysToDelete: Set<CacheKey>;
};

type LayerKeys = Record<LayerId, { keys: Set<CacheKey>; layerId: LayerId }>;

type GetKeysToInvalidateReturnType = {
  keysToInvalidate: KeysToInvalidate | null;
  newState: RootState | null;
};
const EMPTY_KEYS_TO_INVALIDATE: GetKeysToInvalidateReturnType = {
  keysToInvalidate: null,
  newState: null,
};
export function getKeysToInvalidate(
  state: RootState,
  action: Action,
): GetKeysToInvalidateReturnType {
  if (
    !isAnyOf(
      applyMutationLocally_INTERNAL,
      undoMutationLocally_INTERNAL,
      initializeWithSnapshot,
      initializeLockedSnapshot,
      setCurrentLayer,
      eventLiveEditPoint,
      startLiveEditingExistingEvent,
      startLiveEditingMultipleEvents,
    )(action)
  ) {
    return EMPTY_KEYS_TO_INVALIDATE;
  }

  const extractedMutation = extractMutation(state, action);
  const mutation = extractedMutation?.mutation;

  // Will contain all keys that are deleted including the dependent keys
  let allResolvedDeletedKeys: LayerKeys = {};
  let resetAllLayers = false;
  const resetLayers: Set<LayerId> = new Set();
  let deleteTempKeys = false;
  let inferredFormatCacheKeysToDelete = new Set<FormulaEntityTypedId['id']>();
  let newState: RootState | null = null;

  if (isAnyOf(setCurrentLayer, initializeWithSnapshot)(action)) {
    resetAllLayers = true;
  } else if (isAnyOf(initializeLockedSnapshot)(action)) {
    resetLayers.add(action.payload.mutationId);
  } else if (
    isAnyOf(
      eventLiveEditPoint,
      startLiveEditingExistingEvent,
      startLiveEditingMultipleEvents,
    )(action)
  ) {
    // Handling live updates separately. Live updates don't use ephemeral
    // mutations so they don't update the dataset, instead they have a
    // separate live update slice
    let livekeysToDelete: string[] = [];
    const layerId = currentLayerIdSelector(state);
    if (isAnyOf(eventLiveEditPoint)(action)) {
      const liveEditingEventIds = liveEditingEventIdsSelector(state);
      // for now, cache bust all keys associated with live edited events
      livekeysToDelete = handleLiveEvents(state, liveEditingEventIds).keysToDelete;
    } else if (isAnyOf(startLiveEditingExistingEvent)(action)) {
      const eventId = action.payload.originalEvent.id;
      livekeysToDelete = handleLiveEvents(state, [eventId]).keysToDelete;
    } else if (isAnyOf(startLiveEditingMultipleEvents)(action)) {
      const eventIds = action.payload.updates.map((update) => update.originalEvent.id);
      livekeysToDelete = handleLiveEvents(state, eventIds).keysToDelete;
    }

    allResolvedDeletedKeys = getAllKeysToInvalidate(
      state,
      asLayerKeys(layerId, new Set(livekeysToDelete)),
    );
  } else if (mutation != null) {
    // on all mutations, purge any keys related to selection state and live editing
    // we do this to limit the number of cache entries that we have related to transient state
    deleteTempKeys = true;
    const layerId = mutation.layerId ?? DEFAULT_LAYER_ID;
    // Don't peek at the next state if we're not actually going to inspect it.
    const shouldLoadNewState = Object.keys(mutation.mutation).some((key) => {
      const handler = CACHE_INVALIDATOR_OPERATION_HANDLERS[key as keyof DatasetMutationInput];
      return handler != null && handler !== 'noop' && handler !== 'reset';
    });

    newState = shouldLoadNewState ? rootReducer(state, action) : null;

    const result = handleMutationBatch(state, newState, mutation.mutation, layerId);

    inferredFormatCacheKeysToDelete = new Set();
    result.inferredFormatCacheKeysToDelete.forEach((id) => {
      FormulaCacheSingleton.forLayer(layerId)
        .dependencyGraph.getAllDependentIds(newState ?? state, id)
        .forEach((innerDependentId) => {
          inferredFormatCacheKeysToDelete.add(innerDependentId);
        });
    });

    result.layersToReset.forEach((lId) => resetLayers.add(lId));

    if (result.resetCache) {
      if (layerId === DEFAULT_LAYER_ID) {
        resetAllLayers = true;
      } else {
        resetLayers.add(layerId);
      }
    } else {
      allResolvedDeletedKeys = getAllKeysToInvalidate(state, result.keysToDelete);

      const isLiveEditing = liveEditingEventsSelector(state) != null;
      if (isLiveEditing) {
        // mutations usually clear out the live events, so clear related caches
        // for now, cache bust all keys associated with live edited events
        const liveEditingEventIds = liveEditingEventIdsSelector(state);
        // for now, cache bust all keys associated with live edited events
        const liveEventKeysToDelete = handleLiveEvents(state, liveEditingEventIds).keysToDelete;
        const currentLayerId = currentLayerIdSelector(state);
        const allResolvedLiveEventKeys = getAllKeysToInvalidate(
          state,
          // Live events only affect the current layer
          asLayerKeys(currentLayerId, new Set(liveEventKeysToDelete)),
        );

        allResolvedDeletedKeys = mergeLayerKeys(allResolvedDeletedKeys, allResolvedLiveEventKeys);
      }
    }
  }

  return {
    keysToInvalidate: {
      resetAllLayers,
      resetLayers,
      allResolvedDeletedKeys,
      deleteTempKeys,
      inferredFormatCacheKeysToDelete,
    },
    newState,
  };
}

export function clearReduxCache(
  dispatch: AppDispatch,
  { resetAllLayers, resetLayers, allResolvedDeletedKeys, deleteTempKeys }: KeysToInvalidate,
): void {
  if (resetAllLayers) {
    dispatch(calculationsSliceResetCacheForLayers([ALL_LAYERS_KEY]));
  } else if (resetLayers.size > 0) {
    dispatch?.(calculationsSliceResetCacheForLayers(Array.from(resetLayers)));
  }

  const transformedKeys = Object.values(allResolvedDeletedKeys)
    .filter(({ keys, layerId }) => {
      return !resetLayers.has(layerId) && keys.size > 0;
    })
    .map(({ keys, layerId }) => ({ layerId, keysToDelete: Array.from(keys) }));

  dispatch(calculationsSliceInvalidateKeysForLayers(transformedKeys));

  if (deleteTempKeys) {
    dispatch(calculationsSliceDeleteTempKeys());
  }
}

export const handleClearCacheForFormulaCacheSingleton = ({
  thread,
  state,
  keysToInvalidate,
}: (
  | {
      thread: 'main';
      state: RootState;
    }
  | {
      thread: 'webworker';
      // The `state` is only used for updating the AutoFormatCache, which is only relevant for UI purposes.
      // Thus, we don't need it when clearing web worker cache.
      state: null;
    }
) & {
  keysToInvalidate: KeysToInvalidate;
}) => {
  const { resetAllLayers, resetLayers, allResolvedDeletedKeys, deleteTempKeys } = keysToInvalidate;

  if (resetAllLayers) {
    FormulaCacheSingleton.resetAll();
  } else if (resetLayers.size > 0) {
    resetLayers.forEach((layerId) => {
      FormulaCacheSingleton.forLayer(layerId).reset();
    });
  }

  const transformedKeys = Object.values(allResolvedDeletedKeys)
    .filter(({ keys, layerId }) => {
      return !resetLayers.has(layerId) && keys.size > 0;
    })
    .map(({ keys, layerId }) => ({ layerId, keysToDelete: Array.from(keys) }));

  transformedKeys.forEach(({ keysToDelete, layerId }) => {
    keysToDelete.forEach((key) => FormulaCacheSingleton.forLayer(layerId).invalidateKey(key));
  });

  if (deleteTempKeys) {
    FormulaCacheSingleton.deleteTempKeys();
  }
  // Since AutoFormatCache is only used for UI purposes, we don't need
  // to maintain it in the web worker.
  if (thread === 'main') {
    updateAutoFormatCache({ ...keysToInvalidate, state });
  }
};

function updateAutoFormatCache({
  state,
  resetAllLayers,
  resetLayers,
  inferredFormatCacheKeysToDelete,
}: KeysToInvalidate & { state: RootState }): void {
  if (resetAllLayers || resetLayers.size > 0) {
    // TODO: We should layerize AutoFormatCacheSingleton, so if we need
    // to reset a layer we don't have to throw everything entirely away.
    // This isn't a big deal though, since the cache is fairly cheap to instantiate.
    AutoFormatCacheSingleton.reset(state);
    return;
  }

  const updated: Set<string> = new Set();
  inferredFormatCacheKeysToDelete.forEach((id) => {
    if (updated.has(id)) {
      return;
    }
    AutoFormatCacheSingleton.updateEntityFormat({
      state,
      entityId: id,
    });
    updated.add(id);
  });
}

const mergeLayerKeys = (layerKeys1: LayerKeys, layerKeys2: LayerKeys): LayerKeys => {
  const merged = { ...layerKeys1 };
  Object.values(layerKeys2).forEach(({ layerId, keys }) => {
    if (merged[layerId] == null) {
      merged[layerId] = {
        layerId,
        keys,
      };
    } else {
      keys.forEach((k) => merged[layerId].keys.add(k));
    }
  });
  return merged;
};

type HandlerResults = {
  keysToDelete: LayerKeys;
  resetCache: boolean;
  layersToReset: Set<LayerId>;
  inferredFormatCacheKeysToDelete: Set<FormulaEntityTypedId['id']>;
};

function handleMutationBatch(
  oldState: RootState,
  newState: RootState | null,
  mutation: DatasetMutationInput,
  layerId: LayerId,
): HandlerResults {
  const keysToDelete: LayerKeys = {};
  let resetCache = false;
  const layersToReset = new Set<LayerId>();
  const inferredFormatCacheKeysToDelete = new Set<FormulaEntityTypedId['id']>();

  for (const [key, value] of Object.entries(mutation)) {
    const handler =
      CACHE_INVALIDATOR_OPERATION_HANDLERS[
        key as keyof typeof CACHE_INVALIDATOR_OPERATION_HANDLERS
      ];

    if (
      value == null ||
      (Array.isArray(value) && value.length === 0) ||
      handler == null ||
      handler === 'noop'
    ) {
      continue;
    }

    if (handler === 'reset') {
      resetCache = true;
      break;
    }

    if (newState == null) {
      throw new Error('new state should exist');
    }

    // TypeScript can not infer the value type due to the type intersection for handlers.
    // Consider another pattern in the future.
    const results = handler(oldState, newState, layerId, value as never);

    if ('layersToReset' in results) {
      results.layersToReset.forEach((lId) => layersToReset.add(lId));
    } else {
      Object.entries(results.keysToDelete).forEach(([lId, { keys }]) => {
        keys.forEach((k) => {
          addToLayerKeys(keysToDelete, lId, k);
        });
      });
      results.inferredFormatCacheKeysToDelete?.forEach((id) =>
        inferredFormatCacheKeysToDelete.add(id),
      );
    }
  }

  return { keysToDelete, resetCache, layersToReset, inferredFormatCacheKeysToDelete };
}

type MutationInput<T extends keyof DatasetMutationInput> = NonNullable<DatasetMutationInput[T]>;
type CacheInvalidatorHandler<K extends keyof DatasetMutationInput, T extends MutationInput<K>> = (
  oldState: RootState,
  newState: RootState,
  layerId: LayerId,
  input: T,
  ...extraArgs: unknown[]
) => CacheInvalidatorHandlerReturn;

type CacheInvalidatorHandlerReturn =
  | {
      keysToDelete: LayerKeys;
      inferredFormatCacheKeysToDelete: Set<FormulaEntityTypedId['id']> | undefined;
    }
  | { layersToReset: LayerId[] };
/**
 * Updating BusinessObjects:
 * - for formulas that depends on any field spec => invalidate all businessObjectFieldSpecIds
 * - for businessObjectSpec based aggregates => invalidate businessObjectSpecId
 */
const handleUpdateBusinessObjects: CacheInvalidatorHandler<
  'updateBusinessObjects',
  BusinessObjectUpdateInput[]
> = (
  oldState: RootState,
  newState: RootState,
  layerId: LayerId,
  input: BusinessObjectUpdateInput[],
) => {
  // startDate updates are handled in handleEvents
  const inputExcludingStartEvents = input.filter((inputItem) => {
    return (
      (inputItem.fields ?? []).length > 0 ||
      !Object.values(omit(inputItem, 'fields', 'name', 'id')).every((change) => change == null)
    );
  });

  if (inputExcludingStartEvents.length === 0) {
    return {
      keysToDelete: asLayerKeys(layerId, new Set()),
      inferredFormatCacheKeysToDelete: undefined,
    };
  }

  const layerParam = { layerId };
  const oldBusinessObjectsById = businessObjectsByIdForLayerSelector(oldState, layerParam);
  const newBusinessObjectsById = businessObjectsByIdForLayerSelector(newState, layerParam);

  const oldObjectSpecsById = businessObjectSpecsByIdForLayerSelector(oldState, layerParam);
  const newObjectSpecsById = businessObjectSpecsByIdForLayerSelector(newState, layerParam);

  const keysToDelete = new Set<CacheKey>();

  const businessObjectIds = inputExcludingStartEvents.map(
    (businessObjectUpdateInput) => businessObjectUpdateInput.id,
  );

  for (const objectId of businessObjectIds) {
    const oldObject = safeObjGet(oldBusinessObjectsById[objectId]);
    const newObject = safeObjGet(newBusinessObjectsById[objectId]);

    if (oldObject == null && newObject == null) {
      continue;
    }

    const handleCreateOrDelete = () => {
      const result = handleCreateOrDeleteBusinessObjects(oldState, newState, layerId, [
        { id: objectId },
      ]);
      propagateResults({ result, layerId, addToKeysToDelete: (k) => keysToDelete.add(k) });
    };

    if (
      oldObject == null ||
      newObject == null ||
      !isEqual(objectMeta(oldObject), objectMeta(newObject))
    ) {
      // Object was deleted or added
      handleCreateOrDelete();
      continue;
    }

    if (!isEqual(objectMeta(oldObject), objectMeta(newObject))) {
      // or some important object metadata was modified, treat it as a "create"
      handleCreateOrDelete();
      continue;
    }

    const oldObjectSpec = safeObjGet(oldObjectSpecsById[oldObject.specId]);
    const newObjectSpec = safeObjGet(newObjectSpecsById[newObject.specId]);
    if (oldObjectSpec == null || newObjectSpec == null) {
      // Object spec was deleted or added at the same time
      handleCreateOrDelete();
      continue;
    }

    const oldFieldsBySpecId = Object.fromEntries(oldObject.fields.map((f) => [f.fieldSpecId, f]));
    const newFieldsBySpecId = Object.fromEntries(newObject.fields.map((f) => [f.fieldSpecId, f]));

    const allFieldSpecIds = uniq(
      [...newObjectSpec.fields, ...oldObjectSpec.fields].map((f) => f.id),
    );
    allFieldSpecIds.forEach((fieldSpecId) => {
      const oldField = safeObjGet(oldFieldsBySpecId[fieldSpecId]);
      const newField = safeObjGet(newFieldsBySpecId[fieldSpecId]);
      if (oldField == null && newField == null) {
        return;
      }
      const fieldId = (oldField ?? newField)?.id ?? '';
      if (!nullSafeEqual(oldField?.value?.initialValue, newField?.value?.initialValue)) {
        keysToDelete.add(getFormulaCacheKey(fieldId, ALL_MONTHS_KEY, []));
        return;
      }

      const oldActuals = oldField?.value?.actuals.timeSeries ?? {};
      const newActuals = newField?.value?.actuals.timeSeries ?? {};
      const changedMonthKeys = valueTimeSeriesChangedMonths(oldActuals, newActuals);
      changedMonthKeys.forEach((changedMonthKey) => {
        keysToDelete.add(getFormulaCacheKey(fieldId, changedMonthKey, []));
      });
    });
  }

  return {
    keysToDelete: asLayerKeys(layerId, keysToDelete),
    inferredFormatCacheKeysToDelete: undefined,
  };
};

const handleUpdateExtObjectSpecs: CacheInvalidatorHandler<
  'updateExtObjectSpecs',
  ExtObjectSpecUpdateInput[]
> = (
  _oldState: RootState,
  newState: RootState,
  layerId: LayerId,
  inputs: ExtObjectSpecUpdateInput[],
) => {
  const extObjectSpecKeys = new Set(inputs.map((input) => input.extKey));
  const extObjectSpecsByKey = extObjectSpecsByKeySelector(newState);

  const keysToDelete = new Set<CacheKey>();
  for (const extKey of extObjectSpecKeys) {
    const objectSpec = safeObjGet(extObjectSpecsByKey[extKey]);

    if (objectSpec == null) {
      continue;
    }

    keysToDelete.add(getFormulaCacheKey(extKey, ALL_MONTHS_KEY, []));
    objectSpec.fields.forEach((fieldSpec) => {
      keysToDelete.add(getFormulaCacheKey(fieldSpec.extKey, ALL_MONTHS_KEY, []));
    });
  }

  const businessObjectSpecsById = businessObjectSpecsByIdForLayerSelector(newState, { layerId });
  for (const id of Object.keys(businessObjectSpecsById)) {
    const businessObjectSpec = businessObjectSpecsById[id];

    const shouldEvict =
      (businessObjectSpec.extSpecKey != null &&
        extObjectSpecKeys.has(businessObjectSpec.extSpecKey)) ||
      !!businessObjectSpec.extSpecKeys?.some((extKey) => extObjectSpecKeys.has(extKey));

    if (!shouldEvict) {
      continue;
    }

    businessObjectSpec.fields.forEach((fieldSpec) => {
      keysToDelete.add(getFormulaCacheKey(fieldSpec.id, ALL_MONTHS_KEY, []));
    });
  }

  return {
    keysToDelete: asLayerKeys(layerId, keysToDelete),
    resetCache: false,
    inferredFormatCacheKeysToDelete: undefined,
  };
};

/**
 * Creating/Deleting BusinessObjects:
 * - update fieldSpec connection for all fields
 * - for formulas that depends on any field spec => invalidate all businessObjectFieldSpecIds
 * - for businessObjectSpec based aggregates => invalidate businessObjectSpecId
 */
const handleCreateOrDeleteBusinessObjects: CacheInvalidatorHandler<
  'newBusinessObjects' | 'deleteBusinessObjects',
  Array<{ id: BusinessObjectId }>
> = (
  oldState: RootState,
  newState: RootState,
  layerId: LayerId,
  input: Array<{ id: BusinessObjectId }>,
) => {
  const layerParam = { layerId };
  const oldBusinessObjectsById = businessObjectsByIdForLayerSelector(oldState, layerParam);
  const newBusinessObjectsById = businessObjectsByIdForLayerSelector(newState, layerParam);
  const keysToDelete = new Set<CacheKey>();

  const businessObjectIds = input.map((businessObjectCreateInput) => businessObjectCreateInput.id);

  for (const objectId of businessObjectIds) {
    const oldObject = safeObjGet(oldBusinessObjectsById[objectId]);
    const newObject = safeObjGet(newBusinessObjectsById[objectId]);
    const object = newObject ?? oldObject;

    if (object == null) {
      continue;
    }

    const fields = [
      ...businessObjectFieldsForObjectIdAndLayerSelector(newState, {
        layerId,
        objectId,
      }),
      ...businessObjectFieldsForObjectIdAndLayerSelector(oldState, {
        layerId,
        objectId,
      }),
    ];

    // for database aggregates
    keysToDelete.add(getFormulaCacheKey(object.specId, ALL_MONTHS_KEY, []));

    fields.forEach(({ id, fieldSpecId }) => {
      // create the fieldSpec connections for all future fields
      updateBusinessObjectFieldDependencies(newState, layerId, id, fieldSpecId);

      keysToDelete.add(getFormulaCacheKey(fieldSpecId, ALL_MONTHS_KEY, []));
    });
  }

  return {
    keysToDelete: asLayerKeys(layerId, keysToDelete),
    // object format is not inferred - it is always the same as the spec
    inferredFormatCacheKeysToDelete: undefined,
  };
};

/**
 * Deleting ExtObjectSpecs can change:
 * - formulas that depend on associated fields
 * - formulas that depend on the ext object spec itself (count or something like that)
 */

const handleDeleteExtObjectSpecs: CacheInvalidatorHandler<
  'deleteExtObjectSpecs',
  ExtObjectSpecDeleteInput[]
> = (
  oldState: RootState,
  _newState: RootState,
  layerId: LayerId,
  input: ExtObjectSpecDeleteInput[],
) => {
  const oldExtObjectSpecsByKey = extObjectSpecsByKeySelector(oldState);

  const keysToDelete = new Set<CacheKey>();

  const extObjectSpecKeys = input.map(
    (extObjectSpecDeleteInput) => extObjectSpecDeleteInput.extKey,
  );

  for (const extKey of extObjectSpecKeys) {
    const oldObjectSpec = safeObjGet(oldExtObjectSpecsByKey[extKey]);

    if (oldObjectSpec == null) {
      continue;
    }

    keysToDelete.add(getFormulaCacheKey(oldObjectSpec.extKey, ALL_MONTHS_KEY, []));
    oldObjectSpec.fields.forEach((fieldSpec) => {
      keysToDelete.add(getFormulaCacheKey(fieldSpec.extKey, ALL_MONTHS_KEY, []));
    });
  }

  return {
    keysToDelete: asLayerKeys(layerId, keysToDelete),
    resetCache: false,
    inferredFormatCacheKeysToDelete: undefined,
  };
};

/**
 * Deleting BusinessObjectSpecs can change:
 * - businessObjectSpec based aggregates => invalidate businessObjectSpecId (redundant if
 *    the spec has any fields)
 * - any formula that depends on any field spec => invalidate all businessObjectFieldSpecIds
 */
const handleCreateOrDeleteBusinessObjectSpecs: CacheInvalidatorHandler<
  'deleteBusinessObjects',
  BusinessObjectSpecDeleteInput[]
> = (
  oldState: RootState,
  newState: RootState,
  layerId: LayerId,
  input: BusinessObjectSpecDeleteInput[],
) => {
  const layerParam = { layerId };
  const oldBusinessObjectSpecsById = businessObjectSpecsByIdForLayerSelector(oldState, layerParam);
  const newBusinessObjectSpecsById = businessObjectSpecsByIdForLayerSelector(newState, layerParam);

  const keysToDelete = new Set<CacheKey>();
  const inferredFormatCacheKeysToDelete = new Set<FormulaEntityTypedId['id']>();

  const businessObjectSpecIds = input.map(
    (businessObjectSpecDeleteInput) => businessObjectSpecDeleteInput.id,
  );

  for (const id of businessObjectSpecIds) {
    const oldObjectSpec = safeObjGet(oldBusinessObjectSpecsById[id]);
    const newObjectSpec = safeObjGet(newBusinessObjectSpecsById[id]);
    const objectSpec = oldObjectSpec ?? newObjectSpec;

    if (objectSpec == null) {
      continue;
    }

    keysToDelete.add(getFormulaCacheKey(objectSpec.id, ALL_MONTHS_KEY, []));
    const allFieldSpecIds = uniq(
      [...(oldObjectSpec?.fields ?? []), ...(newObjectSpec?.fields ?? [])].map((f) => f.id),
    );

    allFieldSpecIds.forEach((fieldSpecId) => {
      // create the fieldSpec connections for all future fields
      updateBusinessObjectFieldDependencies(newState, layerId, id, fieldSpecId);
      keysToDelete.add(getFormulaCacheKey(fieldSpecId, ALL_MONTHS_KEY, []));
      inferredFormatCacheKeysToDelete.add(fieldSpecId);
    });

    const driverProperties = uniqBy(
      [
        ...(oldObjectSpec?.collection?.driverProperties ?? []),
        ...(newObjectSpec?.collection?.driverProperties ?? []),
      ],
      'id',
    );

    driverProperties.forEach((driverProperty) => {
      updateDriverDependencies({ state: newState, driverId: driverProperty.driverId, layerId });
      keysToDelete.add(getFormulaCacheKey(driverProperty.id, ALL_MONTHS_KEY, []));
      keysToDelete.add(getFormulaCacheKey(driverProperty.driverId, ALL_MONTHS_KEY, []));

      inferredFormatCacheKeysToDelete.add(driverProperty.driverId);
    });
  }

  return { keysToDelete: asLayerKeys(layerId, keysToDelete), inferredFormatCacheKeysToDelete };
};

/**
 * Updating BusinessObjectSpecs:
 * - for any field spec updates => update fieldSpec connection for all fields
 * - for field spec formula updates => invalidate businessObjectSpecId
 */
const handleUpdateBusinessObjectSpecs: CacheInvalidatorHandler<
  'updateBusinessObjectSpecs',
  BusinessObjectSpecUpdateInput[]
> = (
  oldState: RootState,
  newState: RootState,
  layerId: LayerId,
  input: BusinessObjectSpecUpdateInput[],
) => {
  const layerParam = { layerId };
  const oldBusinessObjectSpecsById = businessObjectSpecsByIdForLayerSelector(oldState, layerParam);
  const newBusinessObjectSpecsById = businessObjectSpecsByIdForLayerSelector(newState, layerParam);
  const oldFieldSpecsById = businessObjectFieldSpecByIdSelector(oldState, layerParam);
  const newFieldSpecsById = businessObjectFieldSpecByIdSelector(newState, layerParam);

  const layerKeysToDelete: LayerKeys = {};
  const inferredFormatCacheKeysToDelete = new Set<FormulaEntityTypedId['id']>();

  const businessObjectSpecIds = input.map(
    (businessObjectSpecDeleteInput) => businessObjectSpecDeleteInput.id,
  );

  for (const id of businessObjectSpecIds) {
    const oldObjectSpec = safeObjGet(oldBusinessObjectSpecsById[id]);
    const newObjectSpec = safeObjGet(newBusinessObjectSpecsById[id]);

    if (oldObjectSpec == null && newObjectSpec == null) {
      continue;
    }

    if (oldObjectSpec == null || newObjectSpec == null) {
      // ObjectSpec was deleted or added
      const result = handleCreateOrDeleteBusinessObjectSpecs(oldState, newState, layerId, [{ id }]);
      propagateResults({
        result,
        layerId,
        addToKeysToDelete: (k) => addToLayerKeys(layerKeysToDelete, layerId, k),
        inferredFormatCacheKeysToDelete,
      });
      continue;
    }

    if (!isEqual(objectSpecMeta(oldObjectSpec), objectSpecMeta(newObjectSpec))) {
      // Some important ObjectSpec metadata was modified, treat it as a "create"
      const res = handleCreateOrDeleteBusinessObjectSpecs(oldState, newState, layerId, [{ id }]);
      if ('keysToDelete' in res) {
        res.keysToDelete[layerId]?.keys.forEach((k) =>
          addToLayerKeys(layerKeysToDelete, layerId, k),
        );
      }
      continue;
    }

    const allFieldSpecIds = uniq(
      [...newObjectSpec.fields, ...oldObjectSpec.fields].map((f) => f.id),
    );

    allFieldSpecIds.forEach((fieldSpecId) => {
      const oldFieldSpec = oldFieldSpecsById[fieldSpecId];
      const newFieldSpec = newFieldSpecsById[fieldSpecId];
      if (
        newFieldSpec != null &&
        checkIfFieldFormatValueOrFormulaChanged(newFieldSpec, oldFieldSpec)
      ) {
        inferredFormatCacheKeysToDelete.add(fieldSpecId);
      }

      if (oldFieldSpec == null) {
        // Register any newly added fields
        updateBusinessObjectFieldDependenciesForFieldSpec(newState, layerId, fieldSpecId);
        updateBusinessObjectFieldSpecDependencies(newState, layerId, fieldSpecId);
        addToLayerKeys(
          layerKeysToDelete,
          layerId,
          getFormulaCacheKey(fieldSpecId, ALL_MONTHS_KEY, []),
        );
      } else if (newFieldSpec == null) {
        addToLayerKeys(
          layerKeysToDelete,
          layerId,
          getFormulaCacheKey(fieldSpecId, ALL_MONTHS_KEY, []),
        );
        inferredFormatCacheKeysToDelete.add(fieldSpecId);
      } else if (
        !nullSafeEqual(
          oldFieldSpec?.defaultForecast.formula,
          newFieldSpec.defaultForecast.formula,
        ) ||
        oldFieldSpec?.propagateIntegrationData !== newFieldSpec?.propagateIntegrationData ||
        oldFieldSpec?.integrationDataOverridesForecast !==
          newFieldSpec?.integrationDataOverridesForecast
      ) {
        updateBusinessObjectFieldSpecDependencies(newState, layerId, newFieldSpec.id);

        /**
         * We don't have an explicit dependency on fields to the fieldSpec
         * because field specs already have a dependency on the fields in order
         * to propagate cache invalidations to aggregations. If we also had a
         * dependency in the other direction, updating one field would
         * propagate invalidations across all fields of the same spec which is
         * not desired. So, we have to make sure to invalidate all the fields
         * when the default formula changes as it is effectively updating each
         * field's formula.
         **/

        const invalidateAllFieldsForLayer = (lId: LayerId) => {
          const fieldIdsByFieldSpecId = businessObjectFieldIdsByFieldSpecIdSelector(newState, {
            layerId: lId,
          });

          const fieldIds = fieldIdsByFieldSpecId[newFieldSpec.id] ?? [];
          fieldIds.forEach((fieldId) => {
            addToLayerKeys(
              layerKeysToDelete,
              layerId,
              getFormulaCacheKey(fieldId, ALL_MONTHS_KEY, []),
            );
          });
        };

        activeUnlockedLayerIdsSelector(newState).forEach((lId) => invalidateAllFieldsForLayer(lId));
      }
    });
  }

  return { keysToDelete: layerKeysToDelete, inferredFormatCacheKeysToDelete };
};

/**
 * Updating a driver:
 * - for any formula that depends the driver => invalidate driverId
 * - for any formula that depends on the group => invalidate group Id
 * - any formula that depends on this drivers parent dim driver => invalidate dim driver id
 */
const handleUpdateDrivers: CacheInvalidatorHandler<'updateDrivers', DriverUpdateInput[]> = (
  oldState: RootState,
  newState: RootState,
  layerId: LayerId,
  input: DriverUpdateInput[],
  forcedFormulaCacheUpdate = false,
) => {
  const layerParam = { layerId };
  const driverIds = input.map((d) => d.id);

  // N.B. dimensionalDriversBySubDriverIdSelector has driversByIdForLayerSelector as an input selector
  // calling them in the reverse order results in it being cache-busted and recomputed; calling them in this order does not
  // driversByIdForLayerSelector is different in oldState vs newState
  const oldDriversById = driversByIdForLayerSelector(oldState, layerParam);
  const oldDimDriversBySubDriverId = dimensionalDriversBySubDriverIdSelector(oldState, layerParam);
  const newDriversById = driversByIdForLayerSelector(newState, layerParam);
  const newDimDriversBySubDriverId = dimensionalDriversBySubDriverIdSelector(newState, layerParam);

  const keysToDelete = new Set<CacheKey>();
  const inferredFormatCacheKeysToDelete = new Set<FormulaEntityTypedId['id']>();

  for (const driverId of driverIds) {
    const oldDriver = safeObjGet(oldDriversById[driverId]);
    const newDriver = safeObjGet(newDriversById[driverId]);

    if (oldDriver == null && newDriver == null) {
      continue;
    }

    if ((oldDriver != null && newDriver == null) || (oldDriver == null && newDriver != null)) {
      // Driver was deleted or added
      const result = handleCreateOrDeleteDrivers(oldState, newState, layerId, [{ id: driverId }]);
      propagateResults({
        result,
        layerId,
        addToKeysToDelete: (k) => keysToDelete.add(k),
        inferredFormatCacheKeysToDelete,
      });
      continue;
    }

    if (oldDriver != null && newDriver != null) {
      if (checkIfDriverFormatValueOrFormulaChanged({ oldState, newState, oldDriver, newDriver })) {
        inferredFormatCacheKeysToDelete.add(newDriver.id);
      }

      if (newDriver.type === DriverType.Dimensional) {
        if (oldDriver.type !== DriverType.Dimensional) {
          // this shouldn't happen
          continue;
        }
        keysToDelete.add(getFormulaCacheKey(driverId, ALL_MONTHS_KEY, []));

        // invalidate cache when subdriver attributes change:
        const oldSubDriversIdToAttributeMap = oldDriver.subdrivers.reduce<
          Record<DriverId, AttributeId[]>
        >((out, { driverId: subdriverId, attributes }) => {
          out[subdriverId] = attributes.map((attr) => attr.id).toSorted();
          return out;
        }, {});
        const newSubDriversIdToAttributeMap = newDriver.subdrivers.reduce<
          Record<DriverId, AttributeId[]>
        >((out, { driverId: subdriverId, attributes }) => {
          out[subdriverId] = attributes.map((attr) => attr.id).toSorted();
          return out;
        }, {});

        const allSubDriverIds = uniq([
          ...Object.keys(oldSubDriversIdToAttributeMap),
          ...Object.keys(newSubDriversIdToAttributeMap),
        ]);

        allSubDriverIds.forEach((subDriverId) => {
          if (
            !isEqual(
              oldSubDriversIdToAttributeMap[subDriverId],
              newSubDriversIdToAttributeMap[subDriverId],
            )
          ) {
            keysToDelete.add(getFormulaCacheKey(subDriverId, ALL_MONTHS_KEY, []));
          }
        });

        const newSubdriverIds = newDriver.subdrivers.map((subdriver) => subdriver.driverId);
        const oldSubdrivers = oldDriver.subdrivers.map((subdriver) => subdriver.driverId);
        const allSubdriverIds = uniq([...newSubdriverIds, ...oldSubdrivers]);
        const formulaChanged =
          !nullSafeEqual(newDriver.defaultForecast?.formula, oldDriver.defaultForecast?.formula) ||
          !nullSafeEqual(newDriver.defaultActuals?.formula, oldDriver.defaultActuals?.formula);

        const result = handleUpdateDrivers(
          oldState,
          newState,
          layerId,
          allSubdriverIds.map((id) => ({ id })),
          formulaChanged,
        );
        propagateResults({
          result,
          layerId,
          addToKeysToDelete: (k) => keysToDelete.add(k),
          inferredFormatCacheKeysToDelete,
        });
        inferredFormatCacheKeysToDelete.add(newDriver.id);
      } else {
        if (oldDriver.type === DriverType.Dimensional) {
          // this shouldn't happen
          continue;
        }

        // Check separate from `driverMeta` since we need more from state to
        // figure out if a dimensional parent was switched.
        const oldDimParent = safeObjGet(oldDimDriversBySubDriverId[driverId]);
        const newDimParent = safeObjGet(newDimDriversBySubDriverId[driverId]);
        const switchedDimParent = !nullSafeEqual(oldDimParent?.id, newDimParent?.id);

        if (
          switchedDimParent ||
          !isEqual(driverMeta(oldState, oldDriver), driverMeta(newState, newDriver)) ||
          (forcedFormulaCacheUpdate as boolean)
        ) {
          // formula or dim parent changed, update dependencies
          const formulaChanged =
            !nullSafeEqual(newDriver.forecast.formula, oldDriver.forecast.formula) ||
            !nullSafeEqual(newDriver.actuals.formula, oldDriver.actuals.formula);
          if (switchedDimParent || formulaChanged || (forcedFormulaCacheUpdate as boolean)) {
            // TODO: parses formula again, ensures that graph is invalidated.
            updateDriverDependencies({ state: newState, driverId, layerId });
          }
          if (switchedDimParent) {
            // this might have been the last subdriver for the old parent, so we should check
            // if we need to invalidate the old parent
            const oldDimParentId = oldDimParent?.id;
            const oldDimParentUpdatedVersion =
              oldDimParentId != null ? newDriversById[oldDimParentId] : undefined;
            if (
              oldDimParentId != null &&
              oldDimParentUpdatedVersion != null &&
              checkIfDriverFormatValueOrFormulaChanged({
                oldState,
                newState,
                oldDriver: oldDimParent,
                newDriver: oldDimParentUpdatedVersion,
              })
            ) {
              inferredFormatCacheKeysToDelete.add(oldDimParentId);
            }
          }

          // Driver was modified, clear out the whole driver
          keysToDelete.add(getFormulaCacheKey(driverId, ALL_MONTHS_KEY, []));
          otherIdsToInvalidateForDriver(
            oldDriversById,
            newDriversById,
            oldDimDriversBySubDriverId,
            newDimDriversBySubDriverId,
            driverId,
          ).forEach((id) => keysToDelete.add(getFormulaCacheKey(id, ALL_MONTHS_KEY, [])));
          continue;
        }

        const oldActuals = 'actuals' in oldDriver ? oldDriver.actuals.timeSeries : null;
        const newActuals = 'actuals' in newDriver ? newDriver.actuals.timeSeries : null;

        const actualsMonthsChanged = numericTimeSeriesChangedMonths(
          oldActuals ?? {},
          newActuals ?? {},
        );

        if (actualsMonthsChanged.size > 0) {
          actualsMonthsChanged.forEach((mk) => {
            keysToDelete.add(getFormulaCacheKey(driverId, mk, []));
            otherIdsToInvalidateForDriver(
              oldDriversById,
              newDriversById,
              oldDimDriversBySubDriverId,
              newDimDriversBySubDriverId,
              driverId,
            ).forEach((id) => keysToDelete.add(getFormulaCacheKey(id, mk, [])));
          });
        }
      }
    }
  }

  return { keysToDelete: asLayerKeys(layerId, keysToDelete), inferredFormatCacheKeysToDelete };
};

/**
 * Creating/deleting a driver:
 * - for any formula that depends the driver => invalidate driverId
 * - for any formula that depends on the group => invalidate group Id
 * - any formula that depends on this drivers parent dim driver => invalidate dim driver id
 * - for dimensional driver run handleCreateOrDeleteDrivers for all subdrivers
 */
const handleCreateOrDeleteDrivers: CacheInvalidatorHandler<
  'newDrivers' | 'updateDrivers',
  Array<{ id: DriverId }>
> = (
  oldState: RootState,
  newState: RootState,
  layerId: LayerId,
  input: Array<{ id: DriverId }>,
) => {
  const layerParam = { layerId };
  const driverIds = input.map((d) => d.id);

  const oldDriversById = driversByIdForLayerSelector(oldState, layerParam);
  const oldDimDriversBySubDriverId = dimensionalDriversBySubDriverIdSelector(oldState, layerParam);
  const newDriversById = driversByIdForLayerSelector(newState, layerParam);
  const newDimDriversBySubDriverId = dimensionalDriversBySubDriverIdSelector(newState, layerParam);

  const keysToDelete = new Set<CacheKey>();
  const inferredFormatCacheKeysToDelete = new Set<CacheKey>();

  driverIds.forEach((driverId) => {
    keysToDelete.add(getFormulaCacheKey(driverId, ALL_MONTHS_KEY, []));
    otherIdsToInvalidateForDriver(
      oldDriversById,
      newDriversById,
      oldDimDriversBySubDriverId,
      newDimDriversBySubDriverId,
      driverId,
    ).forEach((id) => keysToDelete.add(getFormulaCacheKey(id, ALL_MONTHS_KEY, [])));

    const oldDriver = safeObjGet(oldDriversById[driverId]);
    const newDriver = safeObjGet(newDriversById[driverId]);

    inferredFormatCacheKeysToDelete.add(driverId);
    // Run all the drivers through the dependency graph to generate dependencies
    updateDriverDependencies({ state: newState, driverId, layerId });

    const driver = oldDriver ?? newDriver;

    if (driver == null) {
      return;
    }

    if (driver.type === DriverType.Dimensional) {
      // the subdrivers may be newly created or they may be moved from an existing driver -
      // handleUpdateDrivers handles both cases
      const result = handleUpdateDrivers(
        oldState,
        newState,
        layerId,
        driver.subdrivers.map((subdriver) => ({
          id: subdriver.driverId,
        })),
      );
      propagateResults({
        result,
        layerId,
        addToKeysToDelete: (k) => keysToDelete.add(k),
        inferredFormatCacheKeysToDelete,
      });
    }
  });

  return { keysToDelete: asLayerKeys(layerId, keysToDelete), inferredFormatCacheKeysToDelete };
};

const handleCreateOrDeleteSubmodels: CacheInvalidatorHandler<
  'deleteSubmodels',
  SubmodelDeleteInput[]
> = (oldState: RootState, newState: RootState, layerId: LayerId, input: SubmodelDeleteInput[]) => {
  const keysToDelete = new Set<CacheKey>();
  const inferredFormatCacheKeysToDelete = new Set<FormulaEntityTypedId['id']>();

  // TODO: remove when supporting legacy mutations on layers is no longer required.
  // eslint-disable-next-line deprecation/deprecation
  input.forEach(({ id, deleteDrivers }) => {
    const drivers = safeObjGet(driversBySubmodelIdSelector(oldState, { layerId })[id]);
    if (deleteDrivers && drivers != null) {
      const result = handleCreateOrDeleteDrivers(oldState, newState, layerId, drivers);
      propagateResults({
        result,
        layerId,
        addToKeysToDelete: (k) => keysToDelete.add(k),
        inferredFormatCacheKeysToDelete,
      });
    }
  });

  return {
    keysToDelete: asLayerKeys(layerId, keysToDelete),
    resetCache: false,
    inferredFormatCacheKeysToDelete,
  };
};

const handleCreateOrDeleteDriverGroups: CacheInvalidatorHandler<
  'deleteDriverGroups',
  DriverGroupDeleteInput[]
> = (
  _oldState: RootState,
  _newState: RootState,
  layerId: LayerId,
  input: DriverGroupDeleteInput[],
) => {
  const keysToDelete = new Set<CacheKey>();
  const driverGroupIds = input.map((d) => d.id);

  for (const id of driverGroupIds) {
    // Creating/deleting a driver group only affects drivers that calculate aggregates on that group. It doesn't affect
    // the drivers in the group. So while there isn't actually a cached value corresponding to the driver group,
    // `getAllKeysToInvalidate` finds all the drivers that depend on the group, which are then cache
    // invalidated.
    keysToDelete.add(getFormulaCacheKey(id, ALL_MONTHS_KEY, []));
  }

  return {
    keysToDelete: asLayerKeys(layerId, keysToDelete),
    inferredFormatCacheKeysToDelete: undefined,
  };
};

const handleCreateOrDeleteExtDrivers: CacheInvalidatorHandler<
  'deleteExtDrivers',
  ExtDriverDeleteInput[]
> = (oldState: RootState, newState: RootState, layerId: LayerId, input: ExtDriverDeleteInput[]) => {
  const oldExtDrivers = extDriversByIdSelector(oldState);
  const newExtDrivers = extDriversByIdSelector(newState);

  const extDriverIds = input.map((extDriverInput) => extDriverInput.id);

  const keysToDelete = new Set<CacheKey>();

  for (const id of extDriverIds) {
    const oldExtDriver = safeObjGet(oldExtDrivers[id]);
    const newExtDriver = safeObjGet(newExtDrivers[id]);
    const extDriver = oldExtDriver ?? newExtDriver;

    if (extDriver == null) {
      continue;
    }

    keysToDelete.add(getFormulaCacheKey(extDriver.id, ALL_MONTHS_KEY, []));
  }

  return {
    keysToDelete: asLayerKeys(layerId, keysToDelete),
    resetCache: false,
    inferredFormatCacheKeysToDelete: undefined,
  };
};

const handleLiveEvents = (state: RootState, eventIds: EventId[]) => {
  const keysToDelete = new Set<CacheKey>();
  const eventsById = eventsByIdForLayerSelector(state);

  eventIds.forEach((eventId) => {
    const event = safeObjGet(eventsById[eventId]);
    if (event == null) {
      return;
    }

    const monthKeys = getAllEventMonthKeys(event);
    const entityId = getImpactedEntityId(event);
    monthKeys.forEach((mk) => {
      // we always live edit selected events, so we don't need to delete any cache entries that
      // ignore selected events
      keysToDelete.add(getFormulaCacheKey(entityId, mk, []));
    });
  });

  return { keysToDelete: Array.from(keysToDelete) };
};

// This helper function returns all the month keys that are impacted by an event,
// accounting for the fact that the start & end may not be updated correctly while live editing.
const getAllEventMonthKeys = (event: Event): MonthKey[] => {
  const monthKeys = Object.keys(event.customCurvePoints ?? {});
  const startMonthKey =
    min([extractMonthKey(event.start), ...monthKeys]) ?? extractMonthKey(event.start);
  const endMonthKey = max([extractMonthKey(event.end), ...monthKeys]) ?? extractMonthKey(event.end);

  return getMonthKeyRange(startMonthKey, endMonthKey);
};

const handleEvents: CacheInvalidatorHandler<
  'newEvents' | 'updateEvents',
  Array<{ id: EventId }>
> = (oldState: RootState, newState: RootState, layerId: LayerId, input: Array<{ id: EventId }>) => {
  const keys = new Set<CacheKey>();
  const eventIds = input.map((event) => event.id);

  const layerParam = { layerId };

  const oldDriversById = driversByIdForLayerSelector(oldState, layerParam);
  const oldDimDriversBySubDriverId = dimensionalDriversBySubDriverIdSelector(oldState, layerParam);
  const newDriversById = driversByIdForLayerSelector(newState, layerParam);
  const newDimDriversBySubDriverId = dimensionalDriversBySubDriverIdSelector(newState, layerParam);

  const oldEventsById = eventsByIdForLayerSelector(oldState, layerParam);
  const newEventsById = eventsByIdForLayerSelector(newState, layerParam);

  const oldFieldsById = businessObjectFieldByIdForLayerSelector(oldState, layerParam);
  const newFieldsById = businessObjectFieldByIdForLayerSelector(newState, layerParam);

  const addKeysForImpactedEntity = (event: Event, monthKeys: MonthKey[]) => {
    const entityIds = new Set<string>();
    if (isDriverEvent(event)) {
      entityIds.add(event.driverId);
      otherIdsToInvalidateForDriver(
        oldDriversById,
        newDriversById,
        oldDimDriversBySubDriverId,
        newDimDriversBySubDriverId,
        event.driverId,
      ).forEach((id) => entityIds.add(id));
    } else {
      const fieldId = event.businessObjectFieldId;
      const field = safeObjGet(oldFieldsById[fieldId]) ?? safeObjGet(newFieldsById[fieldId]);

      entityIds.add(event.businessObjectFieldId);
      // This is for any aggregates on this database table
      // TODO this will actually cause all fields in the column to be invalidated. We should
      // have separate handling logic for fieldSpecFormula changes (which should invalidate all rows)
      // and just row value changes (which should invalidate just the row and any database aggregate
      // drivers)
      if (field?.fieldSpecId != null) {
        entityIds.add(field.fieldSpecId);
      }
    }

    if (isObjectFieldEvent(event)) {
      const fieldId = event.businessObjectFieldId;
      const field = safeObjGet(oldFieldsById[fieldId]) ?? safeObjGet(newFieldsById[fieldId]);
      if (field != null && field.isStartDate) {
        const oldObject = businessObjectForFieldIdForLayerSelector(oldState, fieldId);
        const newObject = businessObjectForFieldIdForLayerSelector(newState, fieldId);

        const object = newObject ?? oldObject;

        if (object == null) {
          Sentry.withScope((scope: Sentry.Scope) => {
            scope.setLevel('warning');
            scope.setExtras({
              businessObjectFieldId: fieldId,
              layerId,
              isCreate: oldObject == null,
              isDelete: newObject == null,
              orgId: oldState.selectedOrg?.id,
            });
            Sentry.captureMessage(
              'Corresponding object not found during event create/update/delete mutation',
            );
          });
          return;
        }

        const fields = [
          ...businessObjectFieldsForObjectIdAndLayerSelector(newState, {
            layerId,
            objectId: object.id,
          }),
          ...businessObjectFieldsForObjectIdAndLayerSelector(oldState, {
            layerId,
            objectId: object.id,
          }),
        ];

        fields.forEach(({ id }) => {
          keys.add(getFormulaCacheKey(id, ALL_MONTHS_KEY, []));
        });
        return;
      }
    }

    monthKeys.forEach((mk) => {
      entityIds.forEach((id) => keys.add(getFormulaCacheKey(id, mk, [])));
    });
  };

  eventIds.forEach((eventId) => {
    const oldEvent = safeObjGet(oldEventsById[eventId]);
    const newEvent = safeObjGet(newEventsById[eventId]);

    if (oldEvent == null && newEvent != null) {
      addKeysForImpactedEntity(newEvent, Object.keys(newEvent.customCurvePoints ?? {}));
    } else if (newEvent == null && oldEvent != null) {
      addKeysForImpactedEntity(oldEvent, Object.keys(oldEvent.customCurvePoints ?? {}));
    } else if (oldEvent != null && newEvent != null && !isEqual(oldEvent, newEvent)) {
      const changedMonths = Array.from(
        valueTimeSeriesChangedMonths(
          oldEvent.customCurvePoints ?? {},
          newEvent.customCurvePoints ?? {},
        ),
      );
      addKeysForImpactedEntity(oldEvent, changedMonths);
      addKeysForImpactedEntity(newEvent, changedMonths);
    }
  });

  return { keysToDelete: asLayerKeys(layerId, keys), inferredFormatCacheKeysToDelete: undefined };
};

const handleCreateOrDeleteEventGroups: CacheInvalidatorHandler<
  'deleteEventGroups',
  EventGroupDeleteInput[]
> = (
  oldState: RootState,
  newState: RootState,
  layerId: LayerId,
  input: EventGroupDeleteInput[],
) => {
  const keys = new Set<CacheKey>();
  const layerParam = { layerId };

  const oldEventGroupsById = eventGroupsByIdForLayerSelector(oldState, layerParam);
  const newEventGroupsById = eventGroupsByIdForLayerSelector(newState, layerParam);

  input.forEach(({ id }) => {
    const oldEventGroup = safeObjGet(oldEventGroupsById[id]);
    const newEventGroup = safeObjGet(newEventGroupsById[id]);

    const eventIds = [...(oldEventGroup?.eventIds ?? []), ...(newEventGroup?.eventIds ?? [])];
    if (eventIds.length === 0) {
      return;
    }
    const result = handleEvents(
      oldState,
      newState,
      layerId,
      eventIds.map((eventId) => ({ id: eventId })),
    );
    propagateResults({
      result,
      layerId,
      addToKeysToDelete: (k) => keys.add(k),
    });
  });

  return { keysToDelete: asLayerKeys(layerId, keys), inferredFormatCacheKeysToDelete: undefined };
};

const handleCreateOrDeleteLayers: CacheInvalidatorHandler<
  'deleteLayers',
  Array<{ layerId: string }>
> = (
  _oldState: RootState,
  _newState: RootState,
  _layerId: LayerId,
  input: Array<{ layerId: string }>,
) => {
  // Layers can not behave like forks: parent-child relationships do not need to be checked.
  // Just reset the cache for each layer.
  return { layersToReset: input.map((inp) => inp.layerId) };
};

// These are actions which we will have to check to see if they invalidate the
// cache. We will either handle them specifically or just allow the cache to be
// entirely reset.
//
// N.B.: Anything that changes the dependency graph of drivers/fields will
// require resetting the cache for now. This is because we lazily generate the
// graph as part of the calculator listener evaluations. This includes
// creating/deleting drivers, updating formulas, moving submodel groupings
// around, etc.
//
// TODO: We should track the dependency graph here and be able to update it
// when drivers change  independently of the formula calculator.
const CACHE_INVALIDATOR_OPERATION_HANDLERS: {
  [Key in keyof DatasetMutationInput]:
    | CacheInvalidatorHandler<Key, MutationInput<Key>>
    | 'noop'
    | 'reset';
} = {
  // Not relevant to formula cache.
  createExtDrivers: 'noop',
  createExtObjectSpecs: 'noop',
  createExtObjects: 'noop',
  createExtTables: 'noop',
  createExtTableRuns: 'noop',
  createIntegrationQueries: 'noop',
  createNamedDatasetVersions: 'noop',
  deleteBlocks: 'noop',
  deleteBlocksPages: 'noop',
  deleteExports: 'noop',
  deleteExtTables: 'noop',
  deleteIntegrationQueries: 'noop',
  deleteMilestones: 'noop',
  deleteNamedDatasetVersions: 'noop',
  newBlocks: 'noop',
  newBlocksPages: 'noop',
  newDimensions: 'noop',
  newDriverFieldSpecs: 'noop',
  newDriverGroups: 'noop',
  newEventGroups: 'noop',
  newExports: 'noop',
  newLayers: 'noop',
  newMilestones: 'noop',
  newSubmodels: 'noop',
  renameDriverGroups: 'noop',
  setDriverFields: 'noop',
  updateBlocks: 'noop',
  updateBlocksPages: 'noop',
  updateDimensions: 'noop',
  updateDriverFieldSpecs: 'noop',
  updateEventGroups: 'noop',
  updateExports: 'noop',
  updateExtObjectSpecs: handleUpdateExtObjectSpecs,
  updateExtTables: 'noop',
  updateIntegrationQueries: 'noop',
  updateLayers: 'noop',
  updateMilestones: 'noop',
  updateNamedDatasetVersions: 'noop',
  updateSubmodels: 'noop',

  // Until this ticket is done, these are noops
  // T-15460
  deleteDimensions: 'noop',
  restoreDimensions: 'noop',

  // We're not handling these kinds of mutations for now.
  // Until we add handlers for them, we just throw away entire cache.
  commitLayerId: 'reset',
  deleteExtObjects: 'reset',
  updateExtDrivers: 'reset',
  updateLastActualsTime: 'reset',

  // We are handling these and will selectively invalidate cache keys.
  deleteExtObjectSpecs: handleDeleteExtObjectSpecs,
  // Note: when undoing, creates can look like deletes and deletes can
  // look like creates, so it is best to write a symmetrical
  // `handleCreateOrDeleteXYZ` function
  deleteBusinessObjectSpecs: handleCreateOrDeleteBusinessObjectSpecs,
  deleteBusinessObjects: handleCreateOrDeleteBusinessObjects,
  deleteDriverGroups: handleCreateOrDeleteDriverGroups,
  deleteDrivers: handleCreateOrDeleteDrivers,
  deleteEventGroups: handleCreateOrDeleteEventGroups,
  deleteEvents: handleEvents,
  deleteExtDrivers: handleCreateOrDeleteExtDrivers,
  deleteLayers: handleCreateOrDeleteLayers,
  deleteSubmodels: handleCreateOrDeleteSubmodels,
  newBusinessObjectSpecs: handleCreateOrDeleteBusinessObjectSpecs,
  newBusinessObjects: handleCreateOrDeleteBusinessObjects,
  newDrivers: handleCreateOrDeleteDrivers,
  newEvents: handleEvents,
  updateBusinessObjectSpecs: handleUpdateBusinessObjectSpecs,
  updateBusinessObjects: handleUpdateBusinessObjects,
  updateDrivers: handleUpdateDrivers,
  updateEvents: handleEvents,
};

function otherIdsToInvalidateForDriver(
  oldDriversById: Record<string, Driver | undefined>,
  newDriversById: Record<string, Driver | undefined>,
  oldDimDriversBySubdriverId: Record<string, DimensionalDriver>,
  newDimDriversBySubdriverId: Record<string, DimensionalDriver>,
  driverId: DriverId,
) {
  const idsToInvalidate = new Set<string>();
  [
    [oldDriversById, oldDimDriversBySubdriverId],
    [newDriversById, newDimDriversBySubdriverId],
  ].forEach(([driversById, dimDriversBySubdriverId]) => {
    const driver = safeObjGet(driversById[driverId]);
    if (driver != null) {
      driver.driverReferences?.forEach((ref) => {
        if (ref.groupId != null) {
          idsToInvalidate.add(ref.groupId);
        }
      });
    }
    // to update any aggregators on dim drivers
    const dimDriver = dimDriversBySubdriverId[driverId];
    if (dimDriver != null) {
      idsToInvalidate.add(dimDriver.id);
    }
  });

  return idsToInvalidate;
}

function getAllKeysToInvalidate(state: RootState, layerKeysToDelete: LayerKeys): LayerKeys {
  const getAllKeysToInvalidateForSingleLayer = (singleLayerId: LayerId, keys: Set<CacheKey>) => {
    const isNamedVersion = isLayerNamedVersionSelector(state, singleLayerId);
    if (isNamedVersion) {
      return new Set<string>();
    }

    return FormulaCacheSingleton.forLayer(singleLayerId).getAllKeysToInvalidate(state, keys);
  };

  const allLayerIds = activeUnlockedLayerIdsSelector(state);

  const allResolvedKeys: LayerKeys = {};
  Object.keys(layerKeysToDelete).forEach((layerId) => {
    if (layerId === DEFAULT_LAYER_ID) {
      const defaultLayerKeys = layerKeysToDelete[layerId]?.keys;
      // Propagate default layer key invalidations to all layers
      allLayerIds.forEach((lId) => {
        const keys = getAllKeysToInvalidateForSingleLayer(lId, defaultLayerKeys);
        keys.forEach((k) => addToLayerKeys(allResolvedKeys, lId, k));
      });
    } else {
      const keys = getAllKeysToInvalidateForSingleLayer(layerId, layerKeysToDelete[layerId]?.keys);
      keys.forEach((k) => addToLayerKeys(allResolvedKeys, layerId, k));
    }
  });

  return allResolvedKeys;
}

function updateDriverDependencies({
  state,
  driverId,
  layerId,
}: {
  state: RootState;
  driverId: DriverId;
  layerId: LayerId;
}) {
  FormulaCacheSingleton.forLayer(layerId).dependencyGraph.updateDriverDependencies(state, driverId);
}

function updateBusinessObjectFieldSpecDependencies(
  state: RootState,
  layerId: LayerId,
  businessObjectFieldSpecId: BusinessObjectFieldSpecId,
) {
  const updateForSingleLayer = (singleLayerId: LayerId) => {
    FormulaCacheSingleton.forLayer(
      singleLayerId,
    ).dependencyGraph.updateBusinessObjectFieldSpecDependencies(state, businessObjectFieldSpecId);
  };

  if (layerId === DEFAULT_LAYER_ID) {
    const allLayerIds = activeUnlockedLayerIdsSelector(state);
    allLayerIds.forEach(updateForSingleLayer);
    return;
  }

  updateForSingleLayer(layerId);
}

function updateBusinessObjectFieldDependencies(
  state: RootState,
  layerId: LayerId,
  businessObjectFieldId: BusinessObjectFieldId,
  businessObjectFieldSpecId: BusinessObjectFieldSpecId,
) {
  const updateForSingleLayer = (singleLayerId: LayerId) => {
    FormulaCacheSingleton.forLayer(
      singleLayerId,
    ).dependencyGraph.updateBusinessObjectFieldDependencies(
      state,
      businessObjectFieldId,
      businessObjectFieldSpecId,
    );
  };

  if (layerId === DEFAULT_LAYER_ID) {
    const allLayerIds = activeUnlockedLayerIdsSelector(state);
    allLayerIds.forEach(updateForSingleLayer);
    return;
  }
  updateForSingleLayer(layerId);
}

function updateBusinessObjectFieldDependenciesForFieldSpec(
  state: RootState,
  layerId: LayerId,
  businessObjectFieldSpecId: BusinessObjectFieldSpecId,
) {
  const updateForSingleLayer = (singleLayerId: LayerId) => {
    const fieldIdsByFieldSpecId = businessObjectFieldIdsByFieldSpecIdSelector(state, {
      layerId: singleLayerId,
    });
    fieldIdsByFieldSpecId[businessObjectFieldSpecId]?.forEach((fieldId) => {
      FormulaCacheSingleton.forLayer(
        singleLayerId,
      ).dependencyGraph.updateBusinessObjectFieldDependencies(
        state,
        fieldId,
        businessObjectFieldSpecId,
      );
    });
  };

  if (layerId === DEFAULT_LAYER_ID) {
    const allLayerIds = activeUnlockedLayerIdsSelector(state);
    allLayerIds.forEach(updateForSingleLayer);
    return;
  }

  updateForSingleLayer(layerId);
}

function driverMeta(
  state: RootState,
  driver: Driver,
): { groupIds: string; submodelIds: string } & (
  | {
      forecastFormula: BasicDriver['forecast']['formula'];
      actualsFormula: BasicDriver['actuals']['formula'];
    }
  | Pick<DimensionalDriver, 'subdrivers'>
) {
  const submodelIdByBlockId = submodelIdByBlockIdSelector(state);
  const groupIds = new Set<string>();
  const submodelIds = new Set<string>();
  driver.driverReferences?.forEach((ref) => {
    if (ref.groupId != null) {
      groupIds.add(ref.groupId);
    }
    if (ref.blockId != null) {
      const submodelId = submodelIdByBlockId[ref.blockId];
      if (submodelId != null) {
        submodelIds.add(submodelId);
      }
    }
  });

  const meta = {
    groupIds: [...groupIds].toSorted().join('.'),
    submodelIds: [...submodelIds].toSorted().join('.'),
  };
  if (driver.type === DriverType.Basic) {
    const { forecast, actuals } = driver;
    return {
      ...meta,
      forecastFormula: forecast.formula,
      actualsFormula: actuals.formula,
    };
  }
  const { subdrivers } = driver;
  return { ...meta, subdrivers };
}

function objectMeta(
  businessObject: BusinessObject,
): Pick<
  BusinessObject,
  'specId' | 'defaultEventGroupId' | 'remoteId' | 'extKey' | 'collectionEntry'
> {
  return pick(
    businessObject,
    'specId',
    'defaultEventGroupId',
    'remoteId',
    'extKey',
    'collectionEntry',
  );
}

function objectSpecMeta(
  businessObjectSpec: BusinessObjectSpec,
): Pick<
  BusinessObjectSpec,
  'startFieldId' | 'extSource' | 'extSpecKey' | 'extSpecKeys' | 'collection'
> {
  return pick(
    businessObjectSpec,
    'startFieldId',
    'extSource',
    'extSpecKey',
    'extSpecKeys',
    'collection',
  );
}

function numericTimeSeriesChangedMonths(
  prev: NumericTimeSeries | NonNumericTimeSeries,
  curr: NumericTimeSeries | NonNumericTimeSeries,
): Set<MonthKey> {
  const changedMonths = new Set<MonthKey>();

  // get the set of month keys where the values are different between the two
  // time series. This should include the cases where a month key is absent
  // from one of the time series.
  const allMonths = new Set<MonthKey>([...Object.keys(prev), ...Object.keys(curr)]);
  allMonths.forEach((monthKey) => {
    if (!nullSafeEqual(prev[monthKey], curr[monthKey])) {
      changedMonths.add(monthKey);
    }
  });

  return changedMonths;
}

function valueTimeSeriesChangedMonths(prev: ValueTimeSeries, curr: ValueTimeSeries): Set<MonthKey> {
  const changedMonths = new Set<MonthKey>();

  // get the set of month keys where the values are different between the two
  // time series. This should include the cases where a month key is absent
  // from one of the time series.
  const allMonths = new Set<MonthKey>([...Object.keys(prev), ...Object.keys(curr)]);
  allMonths.forEach((monthKey) => {
    if (!nullSafeEqual(prev[monthKey]?.value, curr[monthKey]?.value)) {
      changedMonths.add(monthKey);
    }
  });

  return changedMonths;
}

function asLayerKeys(layerId: LayerId, keysToDelete: Set<CacheKey>): LayerKeys {
  return { [layerId]: { layerId, keys: keysToDelete } };
}

function addToLayerKeys(layerKeys: LayerKeys, layerId: LayerId, key: CacheKey) {
  if (!(layerId in layerKeys)) {
    layerKeys[layerId] = { layerId, keys: new Set<CacheKey>() };
  }
  layerKeys[layerId].keys.add(key);
}

/**
 * An entity's format can change if either its format field changes or its formula changes.
 * For dimensional driver, we use its first subdriver .
 */
function checkIfDriverFormatValueOrFormulaChanged({
  oldState,
  newState,
  newDriver,
  oldDriver,
}: {
  oldState: RootState;
  newState: RootState;
  newDriver: Driver | undefined;
  oldDriver: Driver | undefined;
}) {
  if (!nullSafeEqual(oldDriver?.format, newDriver?.format)) {
    return true;
  }

  if (newDriver?.type === DriverType.Basic && oldDriver?.type === DriverType.Basic) {
    return (
      !nullSafeEqual(oldDriver?.format, newDriver?.format) ||
      !nullSafeEqual(oldDriver?.forecast.formula, newDriver?.forecast.formula) ||
      !nullSafeEqual(oldDriver?.actuals.formula, newDriver?.actuals.formula)
    );
  }
  if (newDriver?.type === DriverType.Dimensional && oldDriver?.type === DriverType.Dimensional) {
    const oldDriversById = driversByIdForLayerSelector(oldState, { layerId: DEFAULT_LAYER_ID });
    const newDriversById = driversByIdForLayerSelector(oldState, { layerId: DEFAULT_LAYER_ID });

    const oldFirstSubdriver =
      oldDriver.subdrivers.length > 0
        ? oldDriversById[oldDriver.subdrivers[0].driverId]
        : undefined;
    const newFirstSubdriver =
      newDriver.subdrivers.length > 0
        ? newDriversById[newDriver.subdrivers[0].driverId]
        : undefined;

    return checkIfDriverFormatValueOrFormulaChanged({
      oldState,
      newState,
      oldDriver: oldFirstSubdriver,
      newDriver: newFirstSubdriver,
    });
  }
  return false;
}

/**
 * An entity's format can change if either its format field changes or its formula changes.
 */
function checkIfFieldFormatValueOrFormulaChanged(
  newFieldSpec: BusinessObjectFieldSpec,
  oldFieldSpec?: BusinessObjectFieldSpec,
) {
  return (
    !nullSafeEqual(oldFieldSpec?.numericFormat, newFieldSpec.numericFormat) ||
    !nullSafeEqual(oldFieldSpec?.defaultForecast.formula, newFieldSpec.defaultForecast.formula)
  );
}

function propagateResults({
  result,
  layerId,
  addToKeysToDelete,
  inferredFormatCacheKeysToDelete,
}: {
  result: CacheInvalidatorHandlerReturn;
  layerId: LayerId;
  addToKeysToDelete: (key: CacheKey) => void;
  inferredFormatCacheKeysToDelete?: Set<FormulaEntityTypedId['id']>;
}) {
  if ('keysToDelete' in result) {
    result.keysToDelete[layerId]?.keys.forEach(addToKeysToDelete);
    if (inferredFormatCacheKeysToDelete != null && result.inferredFormatCacheKeysToDelete != null) {
      result.inferredFormatCacheKeysToDelete.forEach((k) => inferredFormatCacheKeysToDelete.add(k));
    }
  }
}

type ExtractedMutation = {
  mutation: MutationBatch;
  isUndone: boolean;
};
export const extractMutation = (state: RootState, action: Action): ExtractedMutation | null => {
  let mutation: MutationBatch | null = null;
  let isUndone = false;
  if (isAnyOf(applyMutationLocally_INTERNAL)(action)) {
    mutation = action.payload.mutationBatch;
  } else if (isAnyOf(undoMutationLocally_INTERNAL)(action)) {
    isUndone = true;
    for (const mutationAction of state.undoRedo.undoStack) {
      const matchingMutation = mutationAction.mutationBatches.find(
        (mutationBatch) => mutationBatch.id === action.payload.toUndoId,
      );
      if (matchingMutation != null) {
        mutation = matchingMutation;
        break;
      }
    }
  }

  return mutation != null ? { mutation, isUndone } : null;
};
