import { ObjectFieldEventEntity } from '@features/Plans/EventEntity';
import { CellValueStyle } from 'components/AgGridComponents/CellRenderer/types';
import { ACTUALS_FORMULA_KEY } from 'components/AgGridComponents/config/grid';
import {
  BackingType,
  ColumnDef,
  Row,
  isColValueCalculationError,
} from 'components/AgGridComponents/types/DatabaseColumnDef';
import {
  TimeseriesColumnDef,
  TimeseriesPropertyRow,
} from 'components/AgGridComponents/types/TimeseriesColumnDef';
import { ACTUALS_FORMULA_COLUMN_TYPE, FORECAST_FORMULA_COLUMN_TYPE } from 'config/modelView';
import { safeObjGet } from 'helpers/typescript';
import { DriverId } from 'reduxStore/models/drivers';
import { MonthKey } from 'types/datetime';

export function getDatabaseCellTestId(colDef: ColumnDef): string {
  const { valueType, displayAs } = colDef.fieldSpec;

  if (valueType === 'formula') {
    return `ag-grid-formula-cell-${colDef.colId.includes(ACTUALS_FORMULA_KEY) ? 'actuals' : 'forecast'}`;
  }

  return displayAs === 'timeseries'
    ? 'ag-grid-timeseries-cell'
    : 'ag-grid-cumulative-timeseries-cell';
}

export function getTimeseriesCellTestId(colDef: TimeseriesColumnDef): string {
  const { colId } = colDef;

  const isActuals = colId === ACTUALS_FORMULA_COLUMN_TYPE;
  if (isActuals) {
    return 'ag-grid-formula-cell-actuals';
  }

  const isForecast = colId === FORECAST_FORMULA_COLUMN_TYPE;
  if (isForecast) {
    return 'ag-grid-formula-cell-forecast';
  }

  return 'ag-grid-timeseries-cell';
}

export function cellValueStyle({
  backingType,
  isActuals,
  hasHardcodedActual,
  driverHasActualsFormula,
  fieldHasIntegrationActuals,
  hasImpact,
}: {
  backingType: BackingType | undefined;
  isActuals: boolean;
  driverHasActualsFormula: boolean;
  fieldHasIntegrationActuals: boolean;
  hasHardcodedActual: boolean;
  hasImpact: boolean;
}): CellValueStyle {
  if (isActuals) {
    if (hasHardcodedActual) {
      return 'actuals-hardcoded';
    }

    const hasActualsFormula = driverHasActualsFormula || fieldHasIntegrationActuals;
    if (hasActualsFormula) {
      return 'actuals-formula';
    }

    if (hasImpact) {
      return 'forecast-impact';
    }

    // The objectField is a special case where, even if we're inheriting from the
    // forecast formula, we still want the styling to show as if it is an actuals
    // formula (i.e. black)
    return backingType === 'objectField' ? 'actuals-formula' : 'forecast-formula';
  }

  return hasImpact ? 'forecast-impact' : 'forecast-formula';
}

export function agGridCellValueStyle(
  value: Row['data'][string],
  row: Row | undefined,
  colDef: ColumnDef,
): CellValueStyle {
  if (row == null) {
    return 'forecast-formula';
  }

  if (isColValueCalculationError(value)) {
    return 'error';
  }

  const { hardCodedActuals, curvePointsByMonthKey, subDriverMetaByColId } = row;
  const { monthKey, afterLastActuals, backingType } = colDef.fieldSpec;

  const subdriverMeta = safeObjGet(subDriverMetaByColId[colDef.colId]);

  return cellValueStyle({
    backingType,
    isActuals: !afterLastActuals,
    driverHasActualsFormula: subdriverMeta?.actualsFormula != null,
    fieldHasIntegrationActuals: colDef.fieldSpec.isIntegration ?? false,
    hasHardcodedActual: monthKey != null && hardCodedActuals.has(monthKey),
    hasImpact: monthKey != null && curvePointsByMonthKey[monthKey] != null,
  });
}

export function getObjectProperties(
  row: Row | TimeseriesPropertyRow,
  col: ColumnDef | TimeseriesColumnDef,
): Omit<ObjectFieldEventEntity, 'type'> | undefined {
  if (row.type === 'objectRow') {
    // Kind of hard to strictly enforce these types, so this is basically saying:
    // "If the row is an objectRow, then the column must have a fieldSpecId"
    const fieldSpec = 'fieldSpec' in col ? col.fieldSpec : undefined;
    if (fieldSpec?.backingType !== 'objectField') {
      return undefined;
    }
    const fieldSpecId = fieldSpec.objectFieldSpecId;
    return {
      objectId: row.objectId,
      fieldSpecId,
      objectFieldId: row.fieldIdByFieldSpecId[fieldSpecId],
    };
  }

  if (row.fieldSpecId == null || row.fieldId == null) {
    return undefined;
  }

  return { objectId: row.objectId, fieldSpecId: row.fieldSpecId, objectFieldId: row.fieldId };
}

export function getDriverId(
  row: Row | TimeseriesPropertyRow,
  col: ColumnDef | TimeseriesColumnDef,
): DriverId | undefined {
  return row.type === 'propertyRow'
    ? row.driverId
    : row.subDriverMetaByColId?.[col.colId]?.subDriverId;
}

export function monthKeyFromColDef(colDef: ColumnDef | TimeseriesColumnDef): MonthKey | undefined {
  return 'meta' in colDef ? colDef.meta.monthKey : colDef.fieldSpec.monthKey;
}
