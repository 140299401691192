import { Center, Flex, Spinner, useBoolean } from '@chakra-ui/react';
import { localPoint } from '@visx/event';
import { Group } from '@visx/group';
import React, { useCallback, useRef } from 'react';

import CursorLine from 'components/DriverChart/CursorLine';
import CursorMonthTracker from 'components/DriverChart/CursorMonthTracker';
import { DriverAggregateLineChart } from 'components/DriverChart/DriverAggregateLineChart';
import DriverChartLegend from 'components/DriverChart/DriverChartLegend';
import { DriverSingleLineChart } from 'components/DriverChart/DriverSingleLineChart';
import EventDriverImpact from 'components/DriverChart/EventDriverImpact';
import TimeAxis from 'components/DriverChart/TimeAxis';
import ValueAxis from 'components/DriverChart/ValueAxis';
import { DEFAULT_DRIVER_CHART_SIZE } from 'config/block';
import theme from 'config/theme';
import { ChartSize } from 'generated/graphql';
import { getChartDimensions } from 'helpers/chart';
import { getDateTimeFromMonthKey, getMonthKeysForRange } from 'helpers/dates';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import useChartContext from 'hooks/useChartContext';
import { useIsActuals } from 'hooks/useIsActuals';
import { useRequestCellValue } from 'hooks/useRequestCellValue';
import { DriverId } from 'reduxStore/models/drivers';
import { setCursor } from 'reduxStore/reducers/cursorSlice';
import {
  isAggregatedValueChart,
  isMultiDriverChart,
} from 'reduxStore/reducers/helpers/viewOptions';
import { blockConfigViewOptionsSelector } from 'selectors/blocksSelector';
import { entityLoadingAnyMonthInRangeSelector } from 'selectors/calculationsSelector';
import {
  chartDisplayMonthKeySelector,
  chartDisplayMonthKeyWithDefaultSelector,
} from 'selectors/driverChartSelectors';
import { blockDateRangeDateTimeSelector } from 'selectors/pageDateRangeSelector';

interface Props {
  showEventImpact?: boolean;
  inView?: boolean;
}

const DriverChart: React.FC<Props> = ({ showEventImpact = true, inView = true }) => {
  const { blockId } = useBlockContext();
  const { driverIds, timeScale, isScenariosLegendVisible } = useChartContext();
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));
  const size = viewOptions.chartSize ?? DEFAULT_DRIVER_CHART_SIZE;
  const isMultiDriver = isMultiDriverChart(viewOptions);
  const aggregateValues = isAggregatedValueChart(viewOptions);
  const cursorMonthKey = useAppSelector(chartDisplayMonthKeySelector);
  const monthKey = useAppSelector(chartDisplayMonthKeyWithDefaultSelector);

  // Some charts will just have a single driver (non multiDriver charts)
  const firstDriverId = driverIds[0];

  const isActuals = useIsActuals(monthKey);

  const chartX = timeScale(
    getDateTimeFromMonthKey(monthKey).endOf('month').startOf('second').toJSDate(),
  );

  const [showLabels, setShowLabels] = useBoolean();
  const dispatch = useAppDispatch();

  const onMouseLeave = useCallback(() => {
    dispatch(setCursor(null));
    setShowLabels.off();
  }, [dispatch, setShowLabels]);

  const { chartMargins, chartWidth, chartHeight } = getChartDimensions(viewOptions);

  const svgRef = useRef<SVGSVGElement>(null);
  const getPoint = useCallback(
    (event: React.MouseEvent<SVGElement>) => {
      if (svgRef?.current == null) {
        return null;
      }

      return localPoint(svgRef.current, event);
    },
    [svgRef],
  );

  const dateTimeRange = useAppSelector((state) => blockDateRangeDateTimeSelector(state, blockId));
  const monthKeys = getMonthKeysForRange(dateTimeRange[0], dateTimeRange[1]);
  const isAnyDriverLoading = useAppSelector((state) =>
    driverIds.some((driverId) =>
      entityLoadingAnyMonthInRangeSelector(state, { id: driverId, monthKeys }),
    ),
  );

  const lineColor = isActuals ? theme.colors.actuals : theme.colors.forecast;

  return (
    <>
      {driverIds.map((driverId) => (
        <DriverWebWorkerLoader key={driverId} driverId={driverId} />
      ))}
      <Flex userSelect="none" position="relative" flexDirection="row" h="full" id="driver-chart">
        {isAnyDriverLoading ? (
          <Center w="full" h="full">
            <Spinner />
          </Center>
        ) : (
          <>
            <svg
              ref={svgRef}
              width={chartWidth}
              height={chartHeight}
              onMouseEnter={setShowLabels.on}
              onMouseLeave={onMouseLeave}
            >
              <Group left={chartMargins.left} top={chartMargins.top}>
                {size !== ChartSize.Medium && <ValueAxis driverId={firstDriverId} />}
                {showEventImpact && !isMultiDriver && (
                  <EventDriverImpact driverId={firstDriverId} />
                )}
                <TimeAxis showLabels={showLabels || size !== ChartSize.Medium} size={size} />
                {aggregateValues ? (
                  <DriverAggregateLineChart />
                ) : (
                  <DriverSingleLineChart inView={inView} />
                )}
                {cursorMonthKey != null && <CursorLine color={lineColor} x={chartX} />}
              </Group>
              {inView && <CursorMonthTracker getPoint={getPoint} />}
            </svg>
            {isMultiDriver && <DriverChartLegend type="driver" />}
            {isScenariosLegendVisible && <DriverChartLegend type="scenarios" />}
          </>
        )}
      </Flex>
    </>
  );
};

// It may have been more natural to put the loading within the
// DriverSingleLineChart but that would result in it mounting when the drivers
// are loaded which would then re-trigger a load thus causing a loop.
const DriverWebWorkerLoader: React.FC<{ driverId: DriverId }> = ({ driverId }) => {
  const { blockId } = useBlockContext();
  const dateRange = useAppSelector((state) => blockDateRangeDateTimeSelector(state, blockId));

  useRequestCellValue({
    id: driverId,
    type: 'driver',
    dateRange,
  });

  return null;
};

export default React.memo(DriverChart);
