import React, { useContext } from 'react';

import DateRangePill from 'components/FormulaInput/DateRangePill';
import FormulaSelectionContext from 'components/FormulaInput/FormulaSelectionContext';
import ObjectSpecFilterPill from 'components/FormulaInput/ObjectSpecFilterPill';
import ReferencePill from 'components/FormulaInput/ReferencePill';
import useFormulaPillMouseDown from 'hooks/useFormulaPillMouseDown';
import { DraftDecoratorComponentProps, ObjectSpecRefMetadata } from 'types/formula';
import { DatabaseFilled } from 'vectors';

const ObjectSpecDecorator: React.FC<DraftDecoratorComponentProps> = ({
  contentState,
  entityKey,
  offsetKey,
}) => {
  const { activeEntity, keysInSelection } = useContext(FormulaSelectionContext);
  const entity = contentState.getEntity(entityKey ?? '');
  const metadata = entity.getData() as ObjectSpecRefMetadata;
  const { label, dateRangeDisplay, error, filters } = metadata;
  const isSelectedEntity = activeEntity?.key === entityKey;
  const hasFilters = filters != null && filters.length > 0;
  const firstFilterError = filters?.find((filter) => filter.error);
  const onNameMouseDown = useFormulaPillMouseDown(entityKey, entity);
  const onDateMouseDown = useFormulaPillMouseDown(entityKey, entity, { submenu: 'filters' });
  const onFilterMouseDown = useFormulaPillMouseDown(entityKey, entity, { submenu: 'filters' });

  if (entityKey == null) {
    return null;
  }

  const isSelected = isSelectedEntity || keysInSelection.has(entityKey);
  return (
    <ReferencePill
      onMouseDown={onNameMouseDown}
      isSelected={isSelectedEntity}
      icon={<DatabaseFilled color="gray.500" />}
      error={error ?? firstFilterError?.error}
      label={label}
      offsetKey={offsetKey}
    >
      {dateRangeDisplay != null && (
        <DateRangePill
          onMouseDown={onDateMouseDown}
          label={dateRangeDisplay}
          isSelected={isSelected}
        />
      )}
      {hasFilters && (
        <ObjectSpecFilterPill
          onMouseDown={onFilterMouseDown}
          isSelected={isSelected}
          filters={filters}
          objectSpecId={metadata.id}
        />
      )}
    </ReferencePill>
  );
};

export default ObjectSpecDecorator;
