import { isEmpty } from 'lodash';

import { ImpactType, ValueType } from 'generated/graphql';
import { DimensionalPropertyEvaluator } from 'helpers/formulaEvaluation/DimensionalPropertyEvaluator';
import evaluate from 'helpers/formulaEvaluation/ForecastCalculator/ForecastCalculator';
import { ForecastCalculatorListener } from 'helpers/formulaEvaluation/ForecastCalculator/ForecastCalculatorListener';
import { FormulaCache } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaCache';
import { FormulaEvaluator } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaEvaluator';
import { INVALID_ARITHMETIC_ERR } from 'helpers/formulaEvaluation/ImpactParser/ImpactParser';
import { ImpactParserListener as ImpactParserListenerAntlr } from 'helpers/formulaEvaluation/ImpactParser/ImpactParserListener';
import { AtomicNumberContext } from 'helpers/formulaEvaluation/ImpactParser/ImpactParserParser';
import { CurvePointTyped } from 'reduxStore/models/events';
import { NumberValue } from 'reduxStore/models/value';
import { isCalculationError } from 'types/dataset';

// We only allow barebones calculator functionality for the time being,
// i.e. no references to other entities.
const BASIC_CALCULATOR = new ForecastCalculatorListener({
  evaluator: new FormulaEvaluator({
    drivers: [],
    lastActualsMonthKey: '',
    eventsByEntityId: {},
    extDrivers: [],
    dimsById: {},
    attributesByDriverId: {},
    fieldSpecsById: {},
    layerId: '',
    earliestFormulaEvaluationMonthKey: '',
    latestFormulaEvaluationMonthKey: '',
    layerFormulaCache: new FormulaCache().forLayer(''),
    dimPropertyEvaluator: new DimensionalPropertyEvaluator({
      businessObjectsById: {},
      businessObjectsBySpecId: {},
      dimensionalPropertiesById: {},
      attributePropertyByKey: {},
      collectionsByObjectSpecId: {},
      attributesByExtKey: {},
      allDimensionalDrivers: [],
    }),
    formulaEntitiesById: {},
    submodelIdByBlockId: {},
    extObjectFieldsByKey: {},
    objectIdsByFieldId: {},
    objectsById: {},
    databasesById: {},
  }),
  entityId: { type: 'driver', id: '' },
  monthKey: '',
  visited: new Set(),
  cacheKey: '',
  newlyAddedCacheKeys: new Set(),
});

// Takes a formula string and evaluates it using ForecastCalculator.
function evaluateFormula(rawFormula: string): NumberValue | undefined {
  let formula = rawFormula.trim();
  if (formula.startsWith('+')) {
    // Calculator can't handle expressions starting with "+", e.g. "+ 1"
    formula = formula.slice(1);
  }

  const res = evaluate(formula, BASIC_CALCULATOR);

  if (isCalculationError(res)) {
    throw new Error(res.error);
  }

  if (res?.type !== ValueType.Number) {
    // This can happen for inputs like: "atomicNumber(1) + abcd"
    throw new Error(INVALID_ARITHMETIC_ERR);
  }

  return res;
}

interface ImpactParserListener extends ImpactParserListenerAntlr {
  getResult: (rawFormula: string) => CurvePointTyped | undefined;
}
export class ImpactParserListenerImpl implements ImpactParserListener {
  private atomicNumberLastCharIndex: number | undefined;

  constructor() {
    this.atomicNumberLastCharIndex = undefined;
  }

  getResult(rawFormula: string): CurvePointTyped | undefined {
    if (
      isEmpty(rawFormula) ||
      // This occurs when the entire formula is an atomic number, e.g. `atomicNumber(1)`
      this.atomicNumberLastCharIndex === rawFormula.length - 1
    ) {
      // No impact
      return undefined;
    }

    // This occurs when there is no atomic number in the formula, e.g. `1 + 1`
    if (this.atomicNumberLastCharIndex == null) {
      // Set impact
      const setRes = evaluateFormula(rawFormula);
      return (
        setRes && {
          impactType: ImpactType.Set,
          type: setRes.type,
          value: setRes.value,
        }
      );
    }

    // This occurs when there is an atomic number in the formula AND a delta impact after it
    // e.g. `atomicNumber(1) + 1`
    // Delta impact
    const deltaFormula = rawFormula.slice(this.atomicNumberLastCharIndex + 1);
    const deltaRes = evaluateFormula(deltaFormula);
    return deltaRes && { impactType: ImpactType.Delta, type: deltaRes.type, value: deltaRes.value };
  }

  exitAtomicNumber(ctx: AtomicNumberContext): void {
    this.atomicNumberLastCharIndex = ctx.stop?.stopIndex;
  }
}
