import { Flex } from '@chakra-ui/react';
import React from 'react';

import { stopEventPropagation } from 'helpers/browserEvent';

interface Props {
  children: React.ReactNode;
}

const SelectItemPopoverContents = React.forwardRef<HTMLDivElement, Props>(({ children }, ref) => {
  return (
    <Flex
      ref={ref}
      onMouseDown={stopEventPropagation}
      bgColor="white"
      boxShadow="popover"
      borderRadius="base"
      flexDirection="column"
    >
      {children}
    </Flex>
  );
});

export default SelectItemPopoverContents;
