import { datadogLogs } from '@datadog/browser-logs';
import * as Sentry from '@sentry/nextjs';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { noop } from 'lodash';
import { useEffect } from 'react';

import { CLIENT_VERSION } from 'config/clientVersion';
import { appEnv, isDevelopment, isUserBrowser, platform } from 'helpers/environment';
import { trackSessionEnd, trackSessionStart } from 'helpers/segment';
import { isRunwayEmployee } from 'helpers/user';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { initializeFlagsFromLocalStorage } from 'reduxStore/reducers/featureFlagsSlice';
import { authenticatedUserSelector } from 'selectors/loginSelector';
import { loadedSelectedOrgSelector } from 'selectors/selectedOrgSelector';
import { InitializeSentryMessage } from 'workers/formulaCalculator/types';

const AnalyticsInitializer = () => {
  const selectedOrg = useAppSelector(loadedSelectedOrgSelector);
  const user = useAppSelector(authenticatedUserSelector);
  const ldClient = useLDClient();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isUserBrowser()) {
      return noop;
    }

    if (selectedOrg != null && user != null) {
      datadogLogs.init({
        clientToken: 'pub7d20755e7a72d62feb54cc76edea80a9',
        env: appEnv,
        version: CLIENT_VERSION,
        silentMultipleInit: true,
      });
      datadogLogs.setGlobalContext({ org_id: selectedOrg.id, org_slug: selectedOrg.slug });
      datadogLogs.setUser({ id: user.id, name: user.name, email: user.email });

      const nameParts = user.name.split(' ');
      const isEmployee = isRunwayEmployee(user);
      Sentry.setUser({
        id: user.id,
        email: user.email,
        name: user.name,
        isEmployee,
      });
      Sentry.setTag('org_id', selectedOrg.id);
      Sentry.setTag('org_slug', selectedOrg.slug);
      Sentry.setTag('is_employee', isEmployee);
      Sentry.setContext('org', {
        id: selectedOrg.id,
        name: selectedOrg.name,
      });

      const msg: InitializeSentryMessage = {
        type: 'initializeSentry',
        data: {
          user: {
            id: user.id,
            email: user.email,
            name: user.name,
            isEmployee,
          },
          orgId: selectedOrg.id,
          orgSlug: selectedOrg.slug,
          orgName: selectedOrg.name,
        },
      };
      window.formulaCalculator?.postMessage(msg);

      if (!isDevelopment) {
        window.analytics.identify(
          user.id,
          {
            organization_id: selectedOrg.id,
            organization_name: selectedOrg.name,
            name: user.name,
            first_name: nameParts[0],
            last_name: nameParts.slice(1).join(' '),
            company: {
              id: selectedOrg.id,
              name: selectedOrg.name,
            },
            email: user.email,
            is_employee: isRunwayEmployee(user),
            platform: platform(),
            created_at: String(user.createdAt) ?? new Date().toISOString(),
          },
          {},
          () => {
            window.analytics.group(selectedOrg.id, { name: selectedOrg.name });
            trackSessionStart();
          },
        );
      }

      window.addEventListener('beforeunload', trackSessionEnd);
    }

    dispatch(initializeFlagsFromLocalStorage());

    return () => {
      window.removeEventListener('beforeunload', trackSessionEnd);
    };
  }, [selectedOrg, user, dispatch, ldClient]);

  return null;
};

export default AnalyticsInitializer;
