import { ArrowRightIcon } from '@chakra-ui/icons';
import { Flex, Text } from '@chakra-ui/react';
import { noop } from 'lodash';
import { useContext, useMemo } from 'react';

import AutofillDimensionalPropertySelectMenu from 'components/AutofillDimensionalPropertySelectMenu/AutofillDimensionalPropertySelectMenu';
import UpdateDriverMappingPopover from 'components/BusinessObjectTable/SettingsPopoverContents/UpdateDriverMappingPopover';
import { LookupStatusIcon } from 'components/BusinessObjectTable/SettingsPopoverContents/useContextMenuItems/LookupStatusIcon';
import { DatabaseDriverFormatSelectMenu } from 'components/DriverFormatSelectMenu/DriverFormatSelectMenu';
import { SelectItem } from 'components/SelectMenu/SelectMenu';
import { DatabaseGroupKey } from 'config/businessObjects';
import { DatabaseTableContext } from 'config/databaseTableContext';
import { DRIVER_FORMAT_ICONS, DRIVER_FORMAT_NAMES } from 'config/drivers';
import { AccessResourceType, DriverFormat, DriverType, OrgRole } from 'generated/graphql';
import { extractEmoji } from 'helpers/emoji';
import { useAccessCapabilities } from 'hooks/useAccessCapabilities';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { useAutofillDimensionalPropertySelectItems } from 'hooks/useAutofillDimensionalPropertySelectItems';
import useBlockContext from 'hooks/useBlockContext';
import { useCustomizePropertyItems } from 'hooks/useCustomizePropertyItems';
import {
  toggleColumnVisibility,
  toggleShowColumnAsTimeSeries,
} from 'reduxStore/actions/blockMutations';
import {
  updateFieldShouldIntegrationDataOverridesForecast,
  updateFieldShouldPropagateIntegrationData,
  updateIsRestrictedField,
  updateIsRestrictedSpec,
} from 'reduxStore/actions/businessObjectSpecMutations';
import { convertFieldSpecToDimensionalDriver } from 'reduxStore/actions/convertFieldSpecToDimensionalDriver';
import { convertFieldSpecToDimensionalProperty } from 'reduxStore/actions/convertFieldSpecToDimensionalProperty';
import { navigateToDriverDetailPane } from 'reduxStore/actions/navigateTo';
import { toggleOrgRoleAccessEntry } from 'reduxStore/actions/permissions';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { DimensionalPropertyId, DriverPropertyId } from 'reduxStore/models/collections';
import { DriverId } from 'reduxStore/models/drivers';
import { openDeleteObjectTableColumnDialog } from 'reduxStore/reducers/alertDialogSlice';
import { setEditingPropertyFormula } from 'reduxStore/reducers/blockLocalStateSlice';
import { openSettingsModal } from 'reduxStore/reducers/pageSlice';
import { autofillPropertyOptionsBySpecIdForDimensionalPropertySelector } from 'selectors/autofillDimensionalPropertySelector';
import {
  blockCanHidePropertiesSelector,
  blockConfigBusinessObjectSpecStartFieldIdSelector,
  blockConfigObjectFieldSpecAsTimeSeriesIdSelector,
  blockConfigShowRestrictedSelector,
  isObjectTimeseriesViewSelector,
} from 'selectors/blocksSelector';
import { hasBusinessObjectFieldSpecRestrictsSelector } from 'selectors/businessObjectFieldSpecRestrictedSelector';
import {
  businessObjectFieldSpecIsLinkedSelector,
  businessObjectFieldSpecShouldIntegrationDataOverridesForecast,
  businessObjectFieldSpecShouldPropagateIntegrationDataSelector,
  canConvertFieldSpecToDimensionalDriverSelector,
  canConvertFieldSpecToDimensionalPropertySelector,
} from 'selectors/businessObjectFieldSpecsSelector';
import { hasBusinessObjectNameRestrictionsSelector } from 'selectors/businessObjectNameRestrictionsSelector';
import { businessObjectSpecNameSelector } from 'selectors/businessObjectSpecsSelector';
import {
  dimensionalPropertySelector,
  driverPropertySelector,
  subdriverForObjectSelector,
} from 'selectors/collectionSelector';
import { driverSelector, driversByIdForLayerSelector } from 'selectors/driversSelector';
import {
  driverDecimalPlacesSelector,
  driverDisplayConfigurationSelector,
} from 'selectors/entityDisplayConfigurationSelector';
import { featureFlagsSelector } from 'selectors/featureFlagsSelector';
import {
  enableDatabaseDriverAggregationsSelector,
  enableDefaultFormulaSelector,
} from 'selectors/launchDarklySelector';
import { resourceHasAccessEntriesSelector } from 'selectors/restrictedResourcesSelector';
import ArrowClockwise from 'vectors/ArrowClockwise';
import Chart from 'vectors/Chart';
import Eye from 'vectors/Eye';
import FormulaIcon from 'vectors/Formula';
import Lock from 'vectors/Lock';
import Pencil from 'vectors/Pencil';
import Sparkles from 'vectors/Sparkles';
import Trash from 'vectors/Trash';
import { default as TrendArrow, default as TrendArrowIcon } from 'vectors/TrendArrow';

export type RenamePropertyItem = { id: 'renameProperty'; name: null; sectionId: string };
interface ObjectFieldSelectMenuItem extends SelectItem {
  onSelect?: () => void;
  shouldClose?: boolean;
}

export type ObjectFieldPopupItem = RenamePropertyItem | ObjectFieldSelectMenuItem;

export const EDIT_SECTION_ID = 'edit';
export const INTEGRATION_SECTION_ID = 'integration';
export const DISPLAY_SECTION_ID = 'display';
export const FILTER_AND_SORT_SECTION_ID = 'filterAndSort';
export const DELETE_SECTION_ID = 'delete';

const useFormatItem = ({
  driverId,
}: {
  driverId: DriverId | null | undefined;
}): ObjectFieldPopupItem | null => {
  const driver = useAppSelector((state) =>
    driverId == null ? undefined : driversByIdForLayerSelector(state)[driverId],
  );

  const format = driver?.format ?? DriverFormat.Auto;
  const currency = useAppSelector((state) =>
    driverId == null ? undefined : driverDisplayConfigurationSelector(state, driverId).currency,
  );

  const driverDecimalPlaces = useAppSelector((state) =>
    driverId == null ? undefined : driverDecimalPlacesSelector(state, driverId),
  );

  if (driverId == null) {
    return null;
  }

  return {
    id: 'formatDriver',
    sectionId: EDIT_SECTION_ID,
    name: 'Format',
    icon: DRIVER_FORMAT_ICONS[format],
    meta: DRIVER_FORMAT_NAMES[format],
    onSelect: noop,
    submenu: () => (
      <DatabaseDriverFormatSelectMenu
        driverId={driverId}
        currency={currency}
        decimalPlaces={driverDecimalPlaces}
        format={format}
      />
    ),
  };
};

export const useDatabaseDriverPropertyContextMenuItems = ({
  driverPropertyId,
  businessObjectId,
  onClose,
}: {
  driverPropertyId: DriverPropertyId;
  businessObjectId?: BusinessObjectId | undefined;
  onClose?: () => void;
}) => {
  const { blockId } = useBlockContext();
  const dispatch = useAppDispatch();
  const { objectSpecId } = useContext(DatabaseTableContext);

  const asTimeSeriesFieldSpecId = useAppSelector((state) =>
    blockConfigObjectFieldSpecAsTimeSeriesIdSelector(state, blockId),
  );

  const enableDatabaseDriverAggregations = useAppSelector(enableDatabaseDriverAggregationsSelector);

  const isShowingAsTimeSeries = asTimeSeriesFieldSpecId === driverPropertyId;

  const dimDriverIdForProperty =
    useAppSelector((state) => driverPropertySelector(state, driverPropertyId))?.driverId ?? '';

  // the driverProperty.driver does not update as the referenced driver is updated, so it's important to get the
  // driver from directly from the state
  const dimDriverForProperty = useAppSelector((state) =>
    dimDriverIdForProperty == null ? null : driverSelector(state, { id: dimDriverIdForProperty }),
  );
  const hasDefaultFormula =
    dimDriverForProperty?.type === DriverType.Dimensional &&
    (dimDriverForProperty?.defaultForecast?.formula != null ||
      dimDriverForProperty?.defaultActuals?.formula != null);

  const mappedDriverId = dimDriverForProperty?.driverMapping?.driverId;

  const canEditDriverMapping = useMemo(() => {
    return enableDatabaseDriverAggregations && mappedDriverId != null;
  }, [enableDatabaseDriverAggregations, mappedDriverId]);

  const matchingSubDriverId = useAppSelector((state) =>
    businessObjectId == null
      ? null
      : subdriverForObjectSelector(state, { businessObjectId, driverPropertyId }),
  );
  const driverId = matchingSubDriverId ?? dimDriverIdForProperty;

  const canHideProperties = useAppSelector((state) =>
    blockCanHidePropertiesSelector(state, blockId),
  );

  const { canWritePermissions } = useAccessCapabilities();
  const hasRestrictions = useAppSelector((state) =>
    resourceHasAccessEntriesSelector(state, driverPropertyId),
  );

  const formatItem = useFormatItem({ driverId: dimDriverIdForProperty });

  const enableDefaultFormula = useAppSelector(enableDefaultFormulaSelector);

  const items = useMemo<ObjectFieldPopupItem[]>(() => {
    return [
      {
        id: 'renameProperty',
        sectionId: EDIT_SECTION_ID,
        name: null,
      },
      {
        id: 'openDetailView',
        sectionId: EDIT_SECTION_ID,
        name: 'Open detail view',
        icon: <TrendArrow direction="up" />,
        onSelect: () => {
          if (driverId != null) {
            dispatch(navigateToDriverDetailPane({ driverId }, { openInNewTab: false }));
          }
        },
      },
      ...(enableDefaultFormula
        ? [
            {
              id: 'editFormula',
              sectionId: EDIT_SECTION_ID,
              name: `${hasDefaultFormula ? 'Edit' : 'Add'} default formula`,
              icon: <FormulaIcon />,
              onSelect: () =>
                dispatch(
                  setEditingPropertyFormula({
                    blockId,
                    fieldSpecId: driverPropertyId,
                    groupKey: '',
                    objectId: businessObjectId,
                  }),
                ),
              shouldClose: false,
            },
          ]
        : []),
      ...(formatItem == null ? [] : [formatItem]),
      {
        id: 'showAsTimeSeries',
        sectionId: EDIT_SECTION_ID,
        name: isShowingAsTimeSeries ? 'Show as value' : 'Show as time series',
        icon: <Chart />,
        onSelect: () => {
          dispatch(toggleShowColumnAsTimeSeries({ blockId, columnKey: driverPropertyId }));
        },
      },
      ...(canEditDriverMapping
        ? [
            {
              id: 'updateDriverMapping',
              sectionId: EDIT_SECTION_ID,
              name: 'Edit driver',
              icon: <Pencil />,
              submenu: () => (
                <UpdateDriverMappingPopover
                  driverPropertyId={driverPropertyId}
                  mappedDriverId={mappedDriverId}
                  existingDriverId={dimDriverIdForProperty}
                  onClose={onClose}
                />
              ),
            },
          ]
        : []),
      ...(canHideProperties
        ? [
            {
              id: 'hide',
              name: 'Hide in block',
              sectionId: EDIT_SECTION_ID,
              icon: <Eye isOpen={false} />,
              onSelect: () => {
                dispatch(
                  toggleColumnVisibility({
                    blockId,
                    columnKey: driverPropertyId,
                    visible: false,
                  }),
                );
              },
            },
          ]
        : []),
      ...(canWritePermissions
        ? [
            {
              id: 'anonymize',
              name: 'Anonymize data',
              sectionId: EDIT_SECTION_ID,
              icon: <Lock />,
              isChecked: hasRestrictions,
              checkedStyle: 'switch' as const,
              shouldClose: false,
              onSelect: () => {
                dispatch(
                  toggleOrgRoleAccessEntry(
                    driverPropertyId,
                    AccessResourceType.ObjectFieldSpec,
                    OrgRole.Admin,
                  ),
                );
              },
            },
          ]
        : []),
      {
        id: 'delete',
        name: 'Delete property',
        sectionId: DELETE_SECTION_ID,
        icon: <Trash />,
        onSelect: () => {
          dispatch(
            openDeleteObjectTableColumnDialog({
              objectSpecId,
              deleteDriverPropertyId: driverPropertyId,
            }),
          );
        },
      },
    ];
  }, [
    enableDefaultFormula,
    hasDefaultFormula,
    formatItem,
    isShowingAsTimeSeries,
    canEditDriverMapping,
    canHideProperties,
    canWritePermissions,
    hasRestrictions,
    driverId,
    dispatch,
    blockId,
    driverPropertyId,
    businessObjectId,
    mappedDriverId,
    dimDriverIdForProperty,
    onClose,
    objectSpecId,
  ]);

  return items;
};

export const useDatabaseNameContextMenuItems = ({
  objectSpecId,
}: {
  objectSpecId: BusinessObjectSpecId;
}) => {
  const areNamesAnonymized = useAppSelector((state) =>
    hasBusinessObjectNameRestrictionsSelector(state, objectSpecId),
  );
  const dispatch = useAppDispatch();

  const items = useMemo<ObjectFieldPopupItem[]>(() => {
    return [
      {
        id: 'anonymize',
        name: 'Anonymize names',
        icon: <Lock />,
        isChecked: areNamesAnonymized,
        checkedStyle: 'switch' as const,
        shouldClose: false,
        onSelect: () => {
          dispatch(updateIsRestrictedSpec({ objectSpecId }));
        },
      },
    ];
  }, [areNamesAnonymized, dispatch, objectSpecId]);

  return items;
};

export const useDatabasePropertyContextMenuItems = ({
  fieldSpecId,
  groupKey,
  objectId,
}: {
  fieldSpecId: BusinessObjectFieldSpecId;
  groupKey: DatabaseGroupKey;
  objectId: BusinessObjectId | undefined;
}): ObjectFieldPopupItem[] => {
  const { blockId } = useBlockContext();
  const dispatch = useAppDispatch();
  const { objectSpecId } = useContext(DatabaseTableContext);

  const asTimeSeriesFieldSpecId = useAppSelector((state) =>
    blockConfigObjectFieldSpecAsTimeSeriesIdSelector(state, blockId),
  );
  const showRestricted = useAppSelector((state) =>
    blockConfigShowRestrictedSelector(state, blockId),
  );

  const isShowingAsTimeSeries = asTimeSeriesFieldSpecId === fieldSpecId;

  const objectSpecStartFieldId = useAppSelector((state) =>
    blockConfigBusinessObjectSpecStartFieldIdSelector(state, blockId),
  );

  const canShowSingleFieldAsTimeSeries = useAppSelector(
    (state) => !isObjectTimeseriesViewSelector(state, blockId),
  );

  const canShowAsTimeseries =
    canShowSingleFieldAsTimeSeries && fieldSpecId != null && fieldSpecId !== objectSpecStartFieldId;

  const canHideProperties = useAppSelector((state) =>
    blockCanHidePropertiesSelector(state, blockId),
  );

  const isLinked = useAppSelector((state) =>
    businessObjectFieldSpecIsLinkedSelector(state, { id: fieldSpecId }),
  );
  const shouldPropagateIntegrationData = useAppSelector((state) =>
    businessObjectFieldSpecShouldPropagateIntegrationDataSelector(state, { id: fieldSpecId }),
  );
  const shouldIntegrationDataOverridesForecast = useAppSelector((state) =>
    businessObjectFieldSpecShouldIntegrationDataOverridesForecast(state, { id: fieldSpecId }),
  );
  const { enableFieldLevelIntegrationForecastOverride } = useAppSelector(featureFlagsSelector);

  const { canWritePermissions } = useAccessCapabilities();
  const hasFieldRestricted = useAppSelector((state) =>
    hasBusinessObjectFieldSpecRestrictsSelector(state, fieldSpecId),
  );

  const customizePropertyItems = useCustomizePropertyItems({
    blockId,
    objectSpecId,
    fieldSpecId,
    groupKey,
    objectId,
  });

  const canConvertToDimensionalProperty = useAppSelector((state) =>
    canConvertFieldSpecToDimensionalPropertySelector(state, { id: fieldSpecId }),
  );

  const canConvertToDimensionalDriver = useAppSelector((state) =>
    canConvertFieldSpecToDimensionalDriverSelector(state, { fieldSpecId, objectSpecId }),
  );

  const items = useMemo<ObjectFieldPopupItem[]>(() => {
    return [
      {
        id: 'renameProperty',
        sectionId: EDIT_SECTION_ID,
        name: null,
      },
      ...customizePropertyItems.map((item) => ({ ...item, sectionId: EDIT_SECTION_ID })),
      ...(canShowAsTimeseries
        ? [
            {
              id: 'showAsTimeSeries',
              sectionId: DISPLAY_SECTION_ID,
              name: isShowingAsTimeSeries ? 'Show as value' : 'Show as time series',
              icon: <Chart />,
              onSelect: () => {
                dispatch(toggleShowColumnAsTimeSeries({ blockId, columnKey: fieldSpecId }));
              },
            },
          ]
        : []),
      ...(canHideProperties && !showRestricted
        ? [
            {
              id: 'hide',
              name: 'Hide in block',
              sectionId: DISPLAY_SECTION_ID,
              icon: <Eye isOpen={false} />,
              onSelect: () => {
                dispatch(
                  toggleColumnVisibility({ blockId, columnKey: fieldSpecId, visible: false }),
                );
              },
            },
          ]
        : []),
      ...(canWritePermissions
        ? [
            {
              id: 'anonymize',
              name: 'Anonymize data',
              sectionId: DISPLAY_SECTION_ID,
              icon: <Lock />,
              isChecked: hasFieldRestricted,
              checkedStyle: 'switch' as const,
              shouldClose: false,
              onSelect: () => {
                dispatch(
                  updateIsRestrictedField({
                    objectSpecId,
                    fieldSpecId,
                  }),
                );
              },
            },
          ]
        : []),
      ...(canConvertToDimensionalProperty
        ? [
            {
              id: 'convertToDimensionalProperty',
              name: 'Convert to Dimension',
              sectionId: EDIT_SECTION_ID,
              icon: <ArrowClockwise />,
              onSelect: () => {
                dispatch(convertFieldSpecToDimensionalProperty({ blockId, fieldSpecId }));
              },
            },
          ]
        : []),
      ...(canConvertToDimensionalDriver
        ? [
            {
              id: 'convertToDimensionalDriver',
              name: 'Convert to Dimensional Driver',
              sectionId: EDIT_SECTION_ID,
              icon: <ArrowClockwise />,
              onSelect: () => {
                dispatch(convertFieldSpecToDimensionalDriver({ blockId, fieldSpecId }));
              },
            },
          ]
        : []),
      ...(isLinked
        ? [
            {
              id: 'propagate integrated data',
              name: 'Propagate actuals data',
              sectionId: enableFieldLevelIntegrationForecastOverride
                ? INTEGRATION_SECTION_ID
                : EDIT_SECTION_ID,
              icon: <ArrowRightIcon />,
              isChecked: shouldPropagateIntegrationData,
              checkedStyle: 'switch' as const,
              shouldClose: false,
              onSelect: () => {
                dispatch(
                  updateFieldShouldPropagateIntegrationData({
                    objectSpecId,
                    fieldSpecId,
                    shouldPropagateIntegrationData: !shouldPropagateIntegrationData,
                  }),
                );
              },
            },
          ]
        : []),
      ...(isLinked && enableFieldLevelIntegrationForecastOverride
        ? [
            {
              id: 'overrideForecastData',
              name: 'Use integration data for forecast',
              sectionId: enableFieldLevelIntegrationForecastOverride
                ? INTEGRATION_SECTION_ID
                : EDIT_SECTION_ID,
              icon: <ArrowRightIcon />,
              isChecked: shouldIntegrationDataOverridesForecast,
              checkedStyle: 'switch' as const,
              shouldClose: false,
              onSelect: () => {
                dispatch(
                  updateFieldShouldIntegrationDataOverridesForecast({
                    objectSpecId,
                    fieldSpecId,
                    shouldIntegrationDataOverrideForecast: !shouldIntegrationDataOverridesForecast,
                  }),
                );
              },
            },
          ]
        : []),
      {
        id: 'delete',
        name: 'Delete property',
        sectionId: DELETE_SECTION_ID,
        icon: <Trash />,
        onSelect: () => {
          dispatch(
            openDeleteObjectTableColumnDialog({
              objectSpecId,
              deleteFieldId: fieldSpecId,
            }),
          );
        },
      },
    ];
  }, [
    customizePropertyItems,
    canShowAsTimeseries,
    isShowingAsTimeSeries,
    canHideProperties,
    showRestricted,
    canWritePermissions,
    hasFieldRestricted,
    canConvertToDimensionalProperty,
    canConvertToDimensionalDriver,
    isLinked,
    enableFieldLevelIntegrationForecastOverride,
    shouldPropagateIntegrationData,
    shouldIntegrationDataOverridesForecast,
    dispatch,
    blockId,
    fieldSpecId,
    objectSpecId,
  ]);

  return items;
};

export const useDimensionalPropertyContextMenuItems = ({
  dimensionalPropertyId,
  onClose,
}: {
  dimensionalPropertyId: DimensionalPropertyId;
  onClose: () => void;
}) => {
  const { blockId } = useBlockContext();
  const dispatch = useAppDispatch();
  const { objectSpecId } = useContext(DatabaseTableContext);
  const dimensionalProperty = useAppSelector((state) =>
    dimensionalPropertySelector(state, dimensionalPropertyId),
  );
  const lookupSpecId = dimensionalProperty?.mapping?.lookupSpecId;
  const hasLookup = lookupSpecId != null;
  const lookupSpecName = useAppSelector((state) =>
    lookupSpecId != null ? businessObjectSpecNameSelector(state, lookupSpecId) : undefined,
  );
  const [lookupSpecEmoji, lookupSpecNameWithoutEmoji] = useMemo(
    () => (lookupSpecName != null ? extractEmoji(lookupSpecName) : [undefined, undefined]),
    [lookupSpecName],
  );

  const canHideProperties = useAppSelector((state) =>
    blockCanHidePropertiesSelector(state, blockId),
  );

  const { canWritePermissions } = useAccessCapabilities();
  const hasRestrictions = useAppSelector((state) =>
    resourceHasAccessEntriesSelector(state, dimensionalPropertyId),
  );

  const autofillOptions = useAppSelector((state) =>
    autofillPropertyOptionsBySpecIdForDimensionalPropertySelector(state, dimensionalPropertyId),
  );
  const autofillSelectItems = useAutofillDimensionalPropertySelectItems(autofillOptions, onClose);

  const canEditMapping = useMemo(
    () =>
      dimensionalProperty?.mapping != null &&
      dimensionalProperty?.mapping?.searchDimensionPropertyId == null,
    [dimensionalProperty],
  );

  const dimensionId = dimensionalProperty?.dimension?.id;
  const dimensionName = dimensionalProperty?.dimension?.name;

  const items = useMemo<ObjectFieldPopupItem[]>(() => {
    return [
      {
        id: 'renameProperty',
        sectionId: EDIT_SECTION_ID,
        name: null,
      },
      ...(canWritePermissions && dimensionId != null
        ? [
            {
              id: 'manageDimension',
              name: `Manage ${dimensionName == null ? 'dimension' : `"${dimensionName}"`}`,
              sectionId: EDIT_SECTION_ID,
              icon: <TrendArrowIcon direction="up" />,
              onSelect: () => {
                dispatch(openSettingsModal({ tabIndex: 'dimensions', dimensionId }));
              },
            },
          ]
        : []),
      ...(canWritePermissions
        ? [
            {
              id: 'lookup',
              name: `${!hasLookup ? 'New' : 'Edit'} Lookup`,
              sectionId: EDIT_SECTION_ID,
              icon: (
                <LookupStatusIcon
                  databaseId={objectSpecId}
                  dimensionalProperty={dimensionalProperty}
                />
              ),
              onSelect: () => {},
              isDisabled: autofillSelectItems.length === 0,
            },
          ]
        : []),
      ...(canHideProperties
        ? [
            {
              id: 'hide',
              name: 'Hide in block',
              sectionId: EDIT_SECTION_ID,
              icon: <Eye isOpen={false} />,
              onSelect: () => {
                dispatch(
                  toggleColumnVisibility({
                    blockId,
                    columnKey: dimensionalPropertyId,
                    visible: false,
                  }),
                );
              },
            },
          ]
        : []),
      ...(canWritePermissions
        ? [
            ...(canEditMapping
              ? [
                  {
                    id: 'autofill',
                    name: 'Autofill using',
                    sectionId: EDIT_SECTION_ID,
                    icon: <Sparkles />,
                    hasNextMenu: true,
                    meta: (
                      <Flex align="center" columnGap={2}>
                        <Text opacity={0.5}>{lookupSpecEmoji}</Text>
                        <Text>{lookupSpecNameWithoutEmoji}</Text>
                      </Flex>
                    ),
                    submenu: () => (
                      <AutofillDimensionalPropertySelectMenu items={autofillSelectItems} />
                    ),
                    isDisabled: autofillSelectItems.length === 0,
                  },
                ]
              : []),
            {
              id: 'anonymize',
              name: 'Anonymize data',
              sectionId: EDIT_SECTION_ID,
              icon: <Lock />,
              isChecked: hasRestrictions,
              checkedStyle: 'switch' as const,
              shouldClose: false,
              onSelect: () => {
                dispatch(
                  toggleOrgRoleAccessEntry(
                    dimensionalPropertyId,
                    AccessResourceType.ObjectFieldSpec,
                    OrgRole.Admin,
                  ),
                );
              },
            },
          ]
        : []),
      {
        id: 'delete',
        name: 'Delete property',
        sectionId: DELETE_SECTION_ID,
        icon: <Trash />,
        onSelect: () => {
          dispatch(
            openDeleteObjectTableColumnDialog({
              objectSpecId,
              deleteDimPropertyId: dimensionalPropertyId,
            }),
          );
        },
      },
    ];
  }, [
    canWritePermissions,
    dimensionId,
    dimensionName,
    hasLookup,
    objectSpecId,
    dimensionalProperty,
    autofillSelectItems,
    canHideProperties,
    canEditMapping,
    lookupSpecEmoji,
    lookupSpecNameWithoutEmoji,
    hasRestrictions,
    dispatch,
    blockId,
    dimensionalPropertyId,
  ]);

  return items;
};
