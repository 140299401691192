import { NullableTimeSeriesPoint, ValueType } from 'generated/graphql';
import { getDateTimeFromMonthKey } from 'helpers/dates';
import { Value, toNumberValue, toValueType } from 'reduxStore/models/value';
import {
  CalculationError,
  ValueOrCalculationError,
  ValueWithCalculationContext,
  isCalculationError,
  isCalculationValue,
} from 'types/dataset';
import { MonthKey } from 'types/datetime';

export type ValueTimeSeries = Record<MonthKey, Value>;
export type ValueTimeSeriesWithEmpty = Record<MonthKey, Value | undefined>;
// TODO: combine both of these types once we've migrated all driver refrences to use non-numeric time series
export type NonNumericTimeSeries = Record<MonthKey, string>;
export type NumericTimeSeries = Record<MonthKey, number>;
export type NumericTimeSeriesWithEmpty = Record<MonthKey, number | undefined>;

export type NonValueTimeSeries = NonNumericTimeSeries | NumericTimeSeries;

export type ErrorTimeSeries = Record<MonthKey, CalculationError | undefined>;

export function valuesWithContextToNonValueTimeSeries(
  values: Map<MonthKey, ValueWithCalculationContext>,
): NonValueTimeSeries {
  const timeSeries: NonValueTimeSeries = {};
  values.forEach((value, monthKey) => {
    timeSeries[monthKey] = value.value;
  });
  return timeSeries;
}

// This is used to migrate from all components using a strictly numeric time series
// to the more generic ValueTimeSeries. Once NumericTimeSeries is deprecated, we can
// remove this as well.
export function numericToValueTimeSeries(ts: NumericTimeSeries): ValueTimeSeries {
  return Object.entries(ts).reduce<ValueTimeSeries>((newTS, val) => {
    newTS[val[0]] = toNumberValue(val[1]);
    return newTS;
  }, {});
}

export function convertToValueTimeSeries(
  ts: NumericTimeSeries | NonNumericTimeSeries,
  type: ValueType,
): ValueTimeSeries {
  return Object.entries(ts).reduce<ValueTimeSeries>((newTS, val) => {
    newTS[val[0]] = toValueType(String(val[1]), type);
    return newTS;
  }, {});
}

function valueToNumber(value: ValueOrCalculationError | undefined): number | undefined {
  if (isCalculationError(value)) {
    return undefined;
  }

  if (typeof value?.value === 'number') {
    return value.value;
  }

  return undefined;
}

// Remove this once NumericTimeSeries is deprecated
export function valueToNumericTimeSeries(ts: ValueTimeSeries): NumericTimeSeries {
  const keys = Object.keys(ts);
  return keys.reduce<NumericTimeSeries>((newTS, mk) => {
    const val = ts[mk];
    const num = valueToNumber(val);
    if (num != null) {
      newTS[mk] = num;
    }

    return newTS;
  }, {});
}

export function valueToNonNumericTimeSeries(ts: ValueTimeSeries): NonNumericTimeSeries {
  return Object.entries(ts).reduce<NonNumericTimeSeries>((newTS, val) => {
    const value = isCalculationValue(val[1]) ? val[1].value : undefined;
    if (value != null) {
      newTS[val[0]] = value as string;
    } else {
      delete newTS[val[0]];
    }
    return newTS;
  }, {});
}

export function valueTimeSeriesToInput(
  ts: ValueTimeSeries | undefined,
): NullableTimeSeriesPoint[] | undefined {
  if (ts == null) {
    return undefined;
  }
  return Object.entries(ts).map(([monthKey, value]) => ({
    time: getDateTimeFromMonthKey(monthKey).toISO(),
    value: isCalculationValue(value) ? value.value.toString() : null,
  }));
}

function getLastValueInTimeSeriesBeforeMonthKey(
  ts: ValueTimeSeries | undefined,
  beforeMonthKey?: MonthKey,
): Value | undefined {
  if (ts == null) {
    return undefined;
  }
  const sortedTimeSeriesBeforeMonthKey = Object.entries(ts)
    .filter(([monthKey]) => {
      return beforeMonthKey == null || monthKey <= beforeMonthKey;
    })
    // Sort by timestamp
    .sort((a, b) => (a[0] > b[0] ? -1 : 1))
    // Get value
    .map((p) => p[1]);

  const valOrErr =
    sortedTimeSeriesBeforeMonthKey.length > 0 ? sortedTimeSeriesBeforeMonthKey[0] : undefined;
  if (isCalculationError(valOrErr)) {
    return undefined;
  }

  return valOrErr;
}

export function getLastValueInTimeSeries(ts: ValueTimeSeries | undefined): Value | undefined {
  return getLastValueInTimeSeriesBeforeMonthKey(ts, undefined);
}
