import { isEmpty } from 'lodash';
import { createCachedSelector } from 're-reselect';

import { MonthColumnKey, StickyColumnKey } from 'config/cells';
import {
  BlockFilter,
  BlockFilterOperator,
  BlockFilterType,
  BlockType,
  DriverType,
  ValueType,
} from 'generated/graphql';
import { getMonthColumnKey } from 'helpers/cells';
import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import { filterDisplayToItem } from 'helpers/filterDisplayToItem';
import { objectsDisplay } from 'helpers/formulaEvaluation/ForecastCalculator/ForecastCalculator';
import { isExpressionBlockFilter } from 'helpers/isExpressionBlockFilter';
import { isNotNull } from 'helpers/typescript';
import { BlockId } from 'reduxStore/models/blocks';
import { DimensionId } from 'reduxStore/models/dimensions';
import { Driver, DriverId } from 'reduxStore/models/drivers';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import {
  blockConfigSelector,
  blockDriverIdsSelector,
  blocksByIdSelector,
} from 'selectors/blocksSelector';
import { databaseFormulaPropertiesByIdSelector } from 'selectors/collectionSelector';
import { blockIdSelector } from 'selectors/constSelectors';
import {
  attributesBySubDriverIdSelector,
  driversByIdForLayerSelector,
} from 'selectors/driversSelector';
import { formulaDisplayListenerSelector } from 'selectors/formulaDisplaySelector';
import { launchDarklySelector } from 'selectors/launchDarklySelector';
import { timeSeriesColumnsForBlockSelector } from 'selectors/rollupSelector';
import { driverPropertyColumnsSelector } from 'selectors/visibleColumnTypesSelector';
import {
  FilterItem,
  FilterValueTypes,
  FormulaFilterItem,
  isFormulaFilterItem,
  isValueFilterItem,
} from 'types/filtering';
import { ParametricSelector } from 'types/redux';

export const blockBVADriverExplanations: ParametricSelector<
  BlockId,
  NullableRecord<DriverId, string> | undefined
> = createCachedSelector(
  (state: RootState, blockId: BlockId) => blocksByIdSelector(state)[blockId],
  launchDarklySelector,
  (block, { enableAiExplanationsInFrontend }) => {
    if (!enableAiExplanationsInFrontend || block == null) {
      return undefined;
    }
    if (block.explanation == null) {
      return undefined;
    }
    if (block.type !== BlockType.DriverGrid) {
      return undefined;
    }

    const explanationsForBlock: NullableRecord<DriverId, string> = {};
    block.explanation.driverGridComparisonExplanations.forEach((explanation) => {
      explanationsForBlock[explanation.driverId] = explanation.explanation;
    });

    return explanationsForBlock;
  },
)(blockIdSelector);

export const orderedDriverGridBlockDriversSelector: ParametricSelector<BlockId, DriverId[]> =
  createCachedSelector(
    (state: RootState, blockId: BlockId) => blockDriverIdsSelector(state, blockId),
    (state: RootState, blockId: BlockId) => filtersForDriverGridBlockSelector(state, blockId),
    attributesBySubDriverIdSelector,
    driversByIdForLayerSelector,
    (driverIdsForDriverGridBlock, blockFilters, attributesBySubDriverId, driversByDriverId) => {
      let filteredDriverIds = driverIdsForDriverGridBlock;
      blockFilters.forEach((filter) => {
        if (isFormulaFilterItem(filter)) {
          filteredDriverIds = filteredDriverIds.filter((id) => {
            const driver = driversByDriverId[id];
            return evaluateFormulaFilter(filter, driver);
          });
        } else if (isValueFilterItem(filter) && filter.valueType === ValueType.Attribute) {
          filteredDriverIds = filteredDriverIds.filter((id) => {
            const attributes = attributesBySubDriverId[id];
            if (isEmpty(attributes)) {
              return false;
            }
            const matchingAttributes = attributes.filter((a) => filter.expected?.includes(a.id));
            if (filter.operator === BlockFilterOperator.Equals) {
              return matchingAttributes.length > 0;
            } else if (filter.operator === BlockFilterOperator.NotEquals) {
              return matchingAttributes.length === 0;
            } else if (filter.operator === BlockFilterOperator.IsNull) {
              return attributes.length === 0;
            } else if (filter.operator === BlockFilterOperator.IsNotNull) {
              return attributes.length > 0;
            }
            return true;
          });
        }
      });

      return filteredDriverIds;
    },
  )(blockIdSelector);

export const orderedBasicDriverGridBlockDriversSelector: ParametricSelector<BlockId, DriverId[]> =
  createCachedSelector(
    orderedDriverGridBlockDriversSelector,
    driversByIdForLayerSelector,
    (drivers, driversByDriverId) => {
      return drivers.filter((id) => {
        const driver = driversByDriverId[id];
        return driver?.type === DriverType.Basic;
      });
    },
  )(blockIdSelector);

function evaluateFormulaFilter(filter: FormulaFilterItem, driver?: Driver): boolean {
  if (driver == null || driver.type !== DriverType.Basic) {
    return true;
  }
  const formula =
    filter.filterKey === 'actualsFormula'
      ? driver.actuals.formula
      : filter.filterKey === 'formula'
        ? driver.forecast.formula
        : null;

  if (filter.operator === BlockFilterOperator.IsNull) {
    return formula == null;
  } else if (filter.operator === BlockFilterOperator.IsNotNull) {
    return formula != null;
  } else {
    return true;
  }
}

export const orderedDriverGridBlockColumnKeysSelector: ParametricSelector<
  BlockId,
  Array<MonthColumnKey | StickyColumnKey>
> = createCachedSelector(
  timeSeriesColumnsForBlockSelector,
  driverPropertyColumnsSelector,
  (tsColumns, propertyColumns) => {
    return [
      // sticky property columns
      // This also has info about month columns so don't double up on those.
      // It is missing sub-column (comparison) info though so we don't use it.
      ...propertyColumns.flatMap(({ type, layerIds }) =>
        layerIds.map((columnLayerId) => ({
          columnType: type,
          columnLayerId,
        })),
      ),
      // time series columns
      ...tsColumns.map((col) => getMonthColumnKey(col.mks[0], col.rollupType, col.subLabel)),
    ];
  },
)(blockIdSelector);

export const uniqueDimensionsForDriverGridBlockSelector: ParametricSelector<
  BlockId,
  DimensionId[]
> = createCachedSelector(
  (state: RootState, blockId: BlockId) => blockDriverIdsSelector(state, blockId),
  attributesBySubDriverIdSelector,
  (driverIdsForBlock, attributesBySubDriverId) => {
    return Array.from(
      driverIdsForBlock.reduce((acc, driverId) => {
        const attributes = attributesBySubDriverId[driverId] ?? [];
        attributes.forEach((a) => {
          acc.add(a.dimensionId);
        });
        return acc;
      }, new Set<DimensionId>()),
    );
  },
)(blockIdSelector);

export const filtersForDriverGridBlockSelector: ParametricSelector<BlockId, FilterItem[]> =
  createCachedSelector(
    blockIdSelector,
    blockConfigSelector,
    (state: RootState, blockId: BlockId) =>
      formulaDisplayListenerSelector(state, { type: 'block', id: blockId }),
    (state: RootState) => databaseFormulaPropertiesByIdSelector(state),
    (_blockId, blockConfig, displayListener, databaseFormulaPropertiesById) => {
      const { businessObjectSpecId, filterBy } = blockConfig ?? {};
      if (filterBy == null) {
        return [];
      }
      const driverFilters = driverGridFiltersFromBlockFilters(filterBy);
      const objectFilters =
        businessObjectSpecId != null
          ? filterBy
              .filter(isExpressionBlockFilter)
              .flatMap((filter) => {
                const display = objectsDisplay(filter.expression, displayListener);
                if (display?.objectSpecId !== businessObjectSpecId) {
                  return [];
                }
                return display.filters;
              })
              .map((display) =>
                filterDisplayToItem(databaseFormulaPropertiesById, businessObjectSpecId, display),
              )
              .filter(isNotNull)
              // Filter out filters that aren't valid, e.g. filtering on dimensions that don't
              // exist on the drivers
              .filter((filterItem) => {
                const property = databaseFormulaPropertiesById[filterItem.filterKey];
                if (property == null || property.type !== 'dimensionalProperty') {
                  return false;
                }
                return property.dimensionalProperty.isDatabaseKey;
              })
          : [];

      return [...driverFilters, ...objectFilters];
    },
  )({ keySelector: blockIdSelector, selectorCreator: createDeepEqualSelector });

function driverGridFiltersFromBlockFilters(blockFilters: BlockFilter[]): FilterItem[] {
  const validFilters: FilterItem[] = [];
  blockFilters.forEach((blockFilter) => {
    if (blockFilter.filterType !== BlockFilterType.KeyValue || blockFilter.filterKey == null) {
      return;
    }

    const filterItem: FilterItem =
      blockFilter.filterKey === 'actualsFormula' || blockFilter.filterKey === 'formula'
        ? {
            valueType: FilterValueTypes.FORMULA,
            filterKey: blockFilter.filterKey,
            label: blockFilter.filterKey,
            operator: blockFilter.filterOp ?? undefined,
            expected: blockFilter.filterValues ?? [],
          }
        : {
            filterKey: blockFilter.filterKey,
            label: blockFilter.filterKey,
            operator: blockFilter.filterOp ?? undefined,
            valueType: ValueType.Attribute,
            dimensionId: '',
            expected: blockFilter.filterValues ?? [],
          };

    validFilters.push(filterItem);
  });

  return validFilters;
}
