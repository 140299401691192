import { Theme, extendTheme, pseudoSelectors } from '@chakra-ui/react';

import { removeBannedThemeVariables } from './banned';
import components from './components';
import foundations from './foundations';
import styles from './styles';

const overrides = {
  ...foundations,
  components,
  styles,
  config: {
    useSystemColorMode: false,
    initialColorMode: 'light' as const,
  },
};

// N.B. Add our own pseudo selectors to match _groupHover
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
(pseudoSelectors as any)._parentAriaSelected = '[aria-selected=true] &';
(pseudoSelectors as any)._tabHover = '[role=tab]:hover &';
/* eslint-enable @typescript-eslint/no-unsafe-member-access */

const theme = extendTheme(overrides) as Theme & typeof overrides;
removeBannedThemeVariables(theme);

export default theme;
