import { AgCharts } from 'ag-charts-enterprise';

import {
  BlockViewAsType,
  ChartElementPosition,
  ChartSeriesType,
  ChartSize,
  DateRangeComparisonType,
} from 'generated/graphql';

AgCharts.setLicenseKey(process.env.NEXT_PUBLIC_AG_GRID_KEY as string);

export type SeriesColor = {
  name: string;
  value: string;
};

export const SERIES_COLORS: SeriesColor[] = [
  { name: 'Red 1', value: '#DE8378' },
  { name: 'Gray 1', value: '#929CA8' },
  { name: 'Yellow 1', value: '#E9B95D' },
  { name: 'Blue 1', value: '#72A0C5' },
  { name: 'Green 1', value: '#A2B76D' },
  { name: 'Orange 1', value: '#E59A64' },
  { name: 'Pink 1', value: '#D786A8' },
  { name: 'Teal 1', value: '#6CA3AE' },
  { name: 'Purple 1', value: '#BA84BF' },

  { name: 'Red 2', value: '#F39B90' },
  { name: 'Gray 2', value: '#A8B3C1' },
  { name: 'Yellow 2', value: '#F9D081' },
  { name: 'Blue 2', value: '#9AC8EE' },
  { name: 'Green 2', value: '#B0D394' },
  { name: 'Orange 2', value: '#F7AC75' },
  { name: 'Pink 2', value: '#E7A8C3' },
  { name: 'Teal 2', value: '#8BC1CC' },
  { name: 'Purple 2', value: '#CBA2CE' },
];

export const ChartElementPositionLabels: Record<ChartElementPosition, string> = {
  [ChartElementPosition.Bottom]: 'Bottom',
  [ChartElementPosition.Left]: 'Left',
  [ChartElementPosition.Right]: 'Right',
  [ChartElementPosition.Top]: 'Top',
};
export const CHART_DEFAULT_LEGEND_POSITION = ChartElementPosition.Right;

export const BLOCK_VIEW_AS_TO_DEFAULT_CHART_SERIES_TYPE: Record<
  BlockViewAsType,
  ChartSeriesType | null
> = {
  [BlockViewAsType.BarChart]: ChartSeriesType.Bar,
  [BlockViewAsType.ComboChart]: ChartSeriesType.Bar,
  [BlockViewAsType.LineChart]: ChartSeriesType.Line,
  [BlockViewAsType.Chart]: ChartSeriesType.Line,
  [BlockViewAsType.AreaChart]: ChartSeriesType.Area,
  [BlockViewAsType.PieChart]: ChartSeriesType.Pie,
  [BlockViewAsType.DonutChart]: ChartSeriesType.Donut,
  [BlockViewAsType.NightingaleChart]: ChartSeriesType.Nightingale,
  [BlockViewAsType.TreemapChart]: ChartSeriesType.Treemap,
  // Unsupported
  [BlockViewAsType.CurrentValue]: null,
};

export function toCartesianSeriesType(seriesType: ChartSeriesType): 'area' | 'bar' | 'line' {
  switch (seriesType) {
    case ChartSeriesType.Area:
      return 'area';
    case ChartSeriesType.Bar:
      return 'bar';
    case ChartSeriesType.Line:
      return 'line';
    default:
      throw new Error(`Unsupported cartiesian series type: ${seriesType}`);
  }
}

export const ORDERED_ROLLUP_TYPES: DateRangeComparisonType[] = [
  DateRangeComparisonType.CurrentMonth,
  DateRangeComparisonType.CurrentQuarter,
  DateRangeComparisonType.CurrentYear,
  DateRangeComparisonType.LastClose,
  DateRangeComparisonType.LastMonth,
  DateRangeComparisonType.LastQuarter,
  DateRangeComparisonType.LastYear,
  DateRangeComparisonType.CustomMonth,
];
export const ROLLUP_LABELS: Record<DateRangeComparisonType, string> = {
  [DateRangeComparisonType.CurrentMonth]: 'Current Month',
  [DateRangeComparisonType.CurrentQuarter]: 'Current Quarter',
  [DateRangeComparisonType.CurrentYear]: 'Current Year',
  [DateRangeComparisonType.LastClose]: 'Last Close',
  [DateRangeComparisonType.LastMonth]: 'Last Month',
  [DateRangeComparisonType.LastQuarter]: 'Last Quarter',
  [DateRangeComparisonType.LastYear]: 'Last Year',
  [DateRangeComparisonType.CustomMonth]: 'Custom Month',
};

export const CURRENCY_FORMAT = '$~s';
export const NUMBER_FORMAT = '~s';
export const PERCENT_FORMAT = '~%';

const MEDIUM_WIDTH = 360;
const MEDIUM_HEIGHT = 170;

const LARGE_WIDTH = 720;
const LARGE_HEIGHT = 240;

const X_LARGE_WIDTH = 1100;
const X_LARGE_HEIGHT = 360;

export function getWidth(chartSize: ChartSize | undefined | null) {
  switch (chartSize) {
    case ChartSize.ExtraLarge:
      return X_LARGE_WIDTH;
    case ChartSize.Large:
      return LARGE_WIDTH;
    case ChartSize.Medium:
    default:
      return MEDIUM_WIDTH;
  }
}

export function getHeight(chartSize: ChartSize | undefined | null) {
  switch (chartSize) {
    case ChartSize.ExtraLarge:
      return X_LARGE_HEIGHT;
    case ChartSize.Large:
      return LARGE_HEIGHT;
    case ChartSize.Medium:
    default:
      return MEDIUM_HEIGHT;
  }
}

export const CHART_TOOLTIP_CLASSNAME = 'runway-chart-tooltip';
