import { WarningTwoIcon } from '@chakra-ui/icons';
import { Tooltip } from '@chakra-ui/react';
import { memo } from 'react';

import { safeObjGet } from 'helpers/typescript';
import useAppSelector from 'hooks/useAppSelector';
import { BusinessObjectSpecId } from 'reduxStore/models/businessObjectSpecs';
import { DimensionalProperty } from 'reduxStore/models/collections';
import { businessObjectSpecsByIdForLayerSelector } from 'selectors/businessObjectSpecsSelector';
import { dimensionsByIdSelector } from 'selectors/dimensionsSelector';
import Search from 'vectors/Search';

export const LookupStatusIcon = memo(
  ({
    databaseId,
    dimensionalProperty,
  }: {
    databaseId: BusinessObjectSpecId;
    dimensionalProperty?: DimensionalProperty;
  }) => {
    const hasError = useHasLookupError(databaseId, dimensionalProperty);
    if (hasError) {
      return (
        <Tooltip label="Could not find the lookup for this column">
          <WarningTwoIcon
            color="failure"
            _groupHover={{ color: 'white' }}
            _groupFocus={{ color: 'white' }}
          />
        </Tooltip>
      );
    }
    return <Search />;
  },
);

export function useHasLookupError(
  databaseId: BusinessObjectSpecId,
  dimensionalProperty?: DimensionalProperty,
): boolean {
  const databasesById = useAppSelector(businessObjectSpecsByIdForLayerSelector);
  const dimensionsById = useAppSelector(dimensionsByIdSelector);
  if (dimensionalProperty == null || dimensionalProperty.mapping == null) {
    return false;
  }

  const mapping = dimensionalProperty.mapping;
  // searchDimensionPropertyId is a misleading name, it stores the common dimension used for lookup.
  const dimension =
    mapping.searchDimensionPropertyId != null
      ? dimensionsById[mapping.searchDimensionPropertyId]
      : null;

  const lookupDatabase = safeObjGet(databasesById[dimensionalProperty.mapping.lookupSpecId]);
  const resultProperty = (lookupDatabase?.collection?.dimensionalProperties ?? []).find(
    ({ id }) => {
      return mapping.resultPropertyId === id;
    },
  );

  return (
    lookupDatabase == null ||
    (mapping.searchDimensionPropertyId != null && dimension == null) ||
    resultProperty == null
  );
}
