import { Column, RangeSelectionChangedEvent } from 'ag-grid-community';
import { sortBy } from 'lodash';

import { ADD_PROPERTY_TYPE } from 'components/AgGridComponents/config/grid';
import { isOptionsColumn } from 'components/AgGridComponents/helpers/deriveColumnDef';
import { isAddItemRowId } from 'components/AgGridComponents/helpers/gridDatasource/DatabaseDataSource';
import {
  IDatabaseDataSource,
  ITimeseriesDataSource,
} from 'components/AgGridComponents/helpers/gridDatasource/types';
import {
  AgGridDatabaseContext,
  ColumnDef,
  Row,
} from 'components/AgGridComponents/types/DatabaseColumnDef';
import {
  AgGridTimeseriesContext,
  TimeseriesColumnDef,
  TimeseriesObjectRow,
  TimeseriesPropertyRow,
} from 'components/AgGridComponents/types/TimeseriesColumnDef';
import {
  BusinessObjectTimeSeriesCellRef,
  CellRef,
  CellSelection,
  CellType,
  DriverCellRef,
} from 'config/cells';
import { isCellRefEqual } from 'helpers/cells';
import { selectCell } from 'reduxStore/actions/cellNavigation';
import { BlockId } from 'reduxStore/models/blocks';
import { clearSelection, selectSingleCell, setSelectedCells } from 'reduxStore/reducers/pageSlice';
import { AppThunk } from 'reduxStore/store';
import { prevailingCellSelectionSelector } from 'selectors/prevailingCellSelectionSelector';

type CellValue = Row['data'][0];

export const selectCellIfUnselected =
  (blockId: BlockId, cellRef: CellRef, opts?: { range: boolean; toggle: boolean }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const cellSelection = prevailingCellSelectionSelector(state);
    const isSelected =
      cellSelection != null &&
      cellSelection.blockId === blockId &&
      cellSelection.selectedCells.some((ref) => isCellRefEqual(ref, cellRef));

    if (isSelected) {
      return;
    }

    if (opts == null) {
      dispatch(
        selectSingleCell({
          blockId,
          cellRef,
        }),
      );
    } else {
      dispatch(selectCell(blockId, cellRef, opts));
    }
  };

export const selectSingleCellIfNotActive =
  (blockId: BlockId, cellRef: CellRef): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const cellSelection = prevailingCellSelectionSelector(state);
    const isActive =
      cellSelection != null &&
      cellSelection.blockId === blockId &&
      isCellRefEqual(cellSelection.activeCell, cellRef) &&
      cellSelection.selectedCells.length === 1;

    if (isActive) {
      return;
    }

    dispatch(
      selectSingleCell({
        blockId,
        cellRef,
      }),
    );
  };

// see DatabaseTableAgGrid.tsx
export const updateDatabasePageSelectionFromRangeSelectionChangedEvent =
  (
    blockId: BlockId,
    datasource: IDatabaseDataSource | null,
    event: RangeSelectionChangedEvent,
  ): AppThunk =>
  (dispatch) => {
    if (datasource == null) {
      return;
    }

    const { api } = event;
    const context = event.context as AgGridDatabaseContext;
    const focusedCell = api.getFocusedCell();
    if (focusedCell == null) {
      dispatch(clearSelection());
      return;
    }

    const focusedCellRowIndex = focusedCell?.rowIndex;
    const focusedCellColId = focusedCell.column.getId();

    let activeCell: CellRef | undefined;
    const selectedCells: CellRef[] = [];

    const cellRanges = api.getCellRanges();
    // need to capture the row node immediately after selection for potential user elsewhere.
    // the user could possibly scroll the selected node out of view causing the node to be unobtainable
    // with getDisplayedRowAtIndex.
    const selectedRowNodes: AgGridDatabaseContext['selectedRowNodes'] = [];
    let selectedOnlyOptionsColumn = true;

    cellRanges?.forEach((range) => {
      const startIndex = range.startRow?.rowIndex;
      if (startIndex == null) {
        return;
      }

      const endIndex = range.endRow?.rowIndex ?? startIndex;
      const [lowerIndex, higherIndex] = sortBy([startIndex, endIndex]);
      for (let i = lowerIndex; i <= higherIndex; i++) {
        const row = api.getDisplayedRowAtIndex(i);
        if (row == null) {
          continue;
        }

        selectedRowNodes.push(row);
        (range.columns as Array<Column<CellValue>>).forEach((column) => {
          const colDef = column.getColDef() as ColumnDef;
          if (selectedOnlyOptionsColumn && !isOptionsColumn(colDef)) {
            selectedOnlyOptionsColumn = false;
          }
          const data = row.data as Row | null;
          if (data == null) {
            return;
          }

          const rowKey = data.rowKey[colDef.colId];
          const cellValue = api.getCellValue<CellValue>({ rowNode: row, colKey: column });
          if (
            rowKey != null &&
            !isAddItemRowId(('driverId' in rowKey ? rowKey.driverId : rowKey.objectId) ?? '')
          ) {
            // Disable clearing cells that are transition values or collapsed formula columns.
            const disableClearing =
              Array.isArray(cellValue) ||
              (colDef.fieldSpec.isFormula && colDef.fieldSpec.monthKey == null);
            const cellRef = {
              type: 'driverId' in rowKey ? CellType.Driver : CellType.ObjectField,
              rowKey,
              columnKey: colDef.columnKey,
              disableClearing,
            } as CellRef;

            selectedCells.push(cellRef);
            if (i === focusedCellRowIndex && colDef.colId === focusedCellColId) {
              activeCell = cellRef;
            }
          }
        });
      }
    });

    const isSingleCellSelection =
      cellRanges != null &&
      cellRanges.length === 1 &&
      cellRanges[0].columns.length === 1 &&
      cellRanges[0].startRow?.rowIndex === cellRanges[0].endRow?.rowIndex &&
      selectedRowNodes.length === 1;

    if (isSingleCellSelection && selectedOnlyOptionsColumn) {
      // options column clicked, selection technically changed, but we want to hold on
      // to the previous selection by row order
      context.staleSelectedRowNodes = true;
      context.selectedRowNodes = sortBy(context.selectedRowNodes, (n) => n.rowIndex);
    } else {
      context.staleSelectedRowNodes = false;
      context.selectedRowNodes = selectedRowNodes;
    }

    context.singleCellRangeSelection = isSingleCellSelection
      ? context.selectedRowNodes[0]
      : undefined;

    if (activeCell != null) {
      const cellSelection: CellSelection = {
        type: 'cell',
        blockId,
        activeCell,
        selectedCells,
      };
      dispatch(setSelectedCells(cellSelection));
    } else {
      dispatch(clearSelection());
    }
  };

// See DatabaseTimeSeriesAgGrid.
export const updateTimeseriesPageSelectionFromRangeSelectionChangedEvent =
  (
    blockId: BlockId,
    datasource: ITimeseriesDataSource | null,
    event: RangeSelectionChangedEvent,
  ): AppThunk =>
  (dispatch) => {
    if (datasource == null) {
      return;
    }

    const { api } = event;
    const context = event.context as AgGridTimeseriesContext;
    const focusedCell = api.getFocusedCell();
    if (focusedCell == null) {
      dispatch(clearSelection());
      return;
    }

    const focusedCellRowIndex = focusedCell?.rowIndex;
    const focusedCellColId = focusedCell.column.getId();

    let activeCell: CellRef | undefined;
    const selectedCells: CellRef[] = [];

    const cellRanges = api.getCellRanges();
    const selectedRowNodes: AgGridTimeseriesContext['selectedRowNodes'] = [];
    let selectedOnlyOptionsColumn = true;

    if (cellRanges == null) {
      context.selectedRowNodes = [];
      context.singleCellRangeSelection = undefined;
      dispatch(clearSelection());
      return;
    }

    for (const range of cellRanges) {
      const startIndex = range.startRow?.rowIndex;
      const endIndex = range.endRow?.rowIndex ?? startIndex;
      if (startIndex == null || endIndex == null) {
        continue;
      }

      const [start, end] = sortBy([startIndex, endIndex]);
      for (let i = start; i <= end; i++) {
        const rowNode = api.getDisplayedRowAtIndex(i);
        if (rowNode == null) {
          continue;
        }

        selectedRowNodes.push(rowNode);

        for (const column of range.columns) {
          const colDef = column.getColDef() as TimeseriesColumnDef;
          const { columnKey } = colDef;

          if (selectedOnlyOptionsColumn && !isOptionsColumn(colDef)) {
            selectedOnlyOptionsColumn = false;
          }

          const rowData = rowNode.data as TimeseriesObjectRow | TimeseriesPropertyRow;
          if (rowData == null) {
            continue;
          }

          const { rowKey } = rowData;

          if (
            columnKey != null &&
            rowKey != null &&
            rowData.type === 'propertyRow' &&
            !rowData.id.endsWith(ADD_PROPERTY_TYPE)
          ) {
            const disableClearing =
              rowData.meta.isIntegrationProperty || rowData.meta.isIntegrationObject;

            const ref =
              rowData.driverId != null
                ? ({
                    type: CellType.Driver,
                    columnKey,
                    rowKey,
                    disableClearing,
                  } as DriverCellRef)
                : ({
                    type: CellType.ObjectFieldTimeSeries,
                    columnKey,
                    rowKey,
                    disableClearing,
                  } as BusinessObjectTimeSeriesCellRef);

            selectedCells.push(ref);
            if (i === focusedCellRowIndex && colDef.colId === focusedCellColId) {
              activeCell = ref;
            }
          }
        }
      }

      const isSingleCellSelection =
        cellRanges != null &&
        cellRanges.length === 1 &&
        cellRanges[0].columns.length === 1 &&
        cellRanges[0].startRow?.rowIndex === cellRanges[0].endRow?.rowIndex &&
        selectedRowNodes.length === 1;

      if (isSingleCellSelection && selectedOnlyOptionsColumn) {
        // options column clicked, selection technically changed, but we want to hold on
        // to the previous selection by row order
        context.selectedRowNodes = sortBy(context.selectedRowNodes, (n) => n.rowIndex);
      } else {
        context.selectedRowNodes = selectedRowNodes;
      }

      context.singleCellRangeSelection = isSingleCellSelection
        ? context.selectedRowNodes[0]
        : undefined;

      if (activeCell != null) {
        const cellSelection: CellSelection = {
          type: 'cell',
          blockId,
          activeCell,
          selectedCells,
        };
        dispatch(setSelectedCells(cellSelection));
      } else {
        dispatch(clearSelection());
      }
    }
  };
