import { compact } from 'lodash';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { BlockFilterContextProvider } from 'components/BlockFilterContext/BlockFilterContext';
import FilterMenu from 'components/FilterMenu/FilterMenu';
import FormulaDropdownContext from 'components/FormulaInput/FormulaDropdownContext';
import FormulaSelectionContext from 'components/FormulaInput/FormulaSelectionContext';
import { BlockFilterOperator, ValueType } from 'generated/graphql';
import { isNotNull } from 'helpers/typescript';
import useAppSelector from 'hooks/useAppSelector';
import { useSubmenuState } from 'hooks/useSubmenuState';
import { Attribute, Dimension } from 'reduxStore/models/dimensions';
import {
  dimensionalDriversByIdSelector,
  dimensionalDriversBySubDriverIdSelector,
} from 'selectors/driversSelector';
import { AttributeFilterItem, EntityInfo, FilterItem } from 'types/filtering';
import { ANY_ATTR, DriverEntityData, NO_ATTR } from 'types/formula';

interface Props {
  entity: DriverEntityData;
}

const EMPTY_ARRAY: Dimension[] = [];

type SubmenuType = 'property' | 'filters';

const DEFAULT_MENU = 'filters';

const DriverEntityDropdownMenu: React.FC<Props> = ({ entity }) => {
  const activeEntityKey = entity.data.id;
  const { resetSelectionState } = useContext(FormulaSelectionContext);

  const { submenu, setSubmenu, clearSubmenu } = useSubmenuState<SubmenuType>(DEFAULT_MENU);

  useEffect(() => {
    setSubmenu(DEFAULT_MENU);
  }, [setSubmenu, activeEntityKey]);

  const dimensionalDriversBySubDriver = useAppSelector(dimensionalDriversBySubDriverIdSelector);
  const dimensionalDrivers = useAppSelector(dimensionalDriversByIdSelector);
  const { onSelectSubDriverAttributes } = useContext(FormulaDropdownContext);

  const dimensionalDriver =
    dimensionalDrivers[activeEntityKey] ?? dimensionalDriversBySubDriver[activeEntityKey];

  const dimensions = sortBy(
    dimensionalDriver != null ? dimensionalDriver.dimensions : EMPTY_ARRAY,
    'deleted',
  ).reverse();
  const dimsById = keyBy(dimensions, 'id');

  const [filters, setFilters] = useState<FilterItem[] | undefined>(undefined);

  const defaultFilters = useMemo(() => {
    return compact(
      Object.keys(entity?.data?.attributeFilters ?? {}).map((dimId) => {
        const dimension = dimsById[dimId];
        if (dimension == null) {
          return null;
        }
        const item: FilterItem = {
          dimensionId: dimId,
          filterKey: dimId,
          label: dimension.name,
          expected: (entity?.data?.attributeFilters || {})[dimId].filter(isNotNull).map((attr) => {
            if (attr === NO_ATTR || attr === ANY_ATTR) {
              return attr;
            }
            return attr.id;
          }),
          operator: BlockFilterOperator.Equals,
          valueType: ValueType.Attribute,
        };
        return item;
      }),
    );
  }, [dimsById, entity?.data?.attributeFilters]);

  const filtersToUse = filters || defaultFilters;

  const dimensionAttributesById = useMemo(() => {
    return keyBy(
      dimensions.flatMap((dim) => dim.attributes ?? []),
      'id',
    );
  }, [dimensions]);

  const availableFilters = useMemo(() => {
    return (dimensionalDriver?.dimensions ?? []).map(
      (dim) =>
        ({
          id: dim.id,
          filterKey: dim.id,
          dimensionId: dim.id,
          label: dim.name,
          valueType: ValueType.Attribute,
        }) as AttributeFilterItem,
    );
  }, [dimensionalDriver]);

  const onUpdateFilters = useCallback(
    (newFilters: FilterItem[]) => {
      setFilters(newFilters);

      const updatedAttributeFilters = newFilters.reduce(
        (acc, attributeFilter) => {
          const filter = attributeFilter as AttributeFilterItem;
          const dimensionId = filter.dimensionId;
          if (dimensionId == null) {
            return acc;
          }
          const expectedDimensionAttributeIds = filter?.expected;
          if (!Array.isArray(expectedDimensionAttributeIds)) {
            return acc;
          }
          const expectedDimensionAttributes = expectedDimensionAttributeIds.map(
            (attributeId: string) => {
              if (attributeId === ANY_ATTR) {
                return ANY_ATTR;
              } else if (attributeId !== NO_ATTR) {
                return dimensionAttributesById[attributeId];
              }
              return NO_ATTR;
            },
          );
          //NO_ATTRS_ID
          const dimension = dimsById[dimensionId];
          const numDimensionAttributes = dimension?.attributes?.length ?? 0;

          const allAttributesSelected =
            expectedDimensionAttributes.filter((f) => f !== NO_ATTR).length ===
            numDimensionAttributes;

          const noneAttributesSelected = expectedDimensionAttributes.some((f) => f === NO_ATTR);

          if (allAttributesSelected && noneAttributesSelected) {
            acc[dimensionId] = [ANY_ATTR, NO_ATTR];
          } else if (allAttributesSelected) {
            acc[dimensionId] = [ANY_ATTR];
          } else {
            acc[dimensionId] = expectedDimensionAttributes;
          }
          return acc;
        },
        {} as Record<string, Array<Attribute | typeof ANY_ATTR | typeof NO_ATTR>>,
      );

      onSelectSubDriverAttributes({ ...updatedAttributeFilters });
    },
    [dimensionAttributesById, dimsById, onSelectSubDriverAttributes],
  );

  const blockFilterContext = useMemo(() => {
    const entityInfo: EntityInfo = { id: entity.data.id, label: entity.data.label, type: 'driver' };
    return {
      entityInfo,
      filters: filtersToUse,
      availableFilters,
      activeFilters: [],
      onUpdateFilters,
      onDoneFilters: () => {
        clearSubmenu();
        resetSelectionState();
      },
    };
  }, [
    availableFilters,
    entity.data,
    filtersToUse,
    clearSubmenu,
    onUpdateFilters,
    resetSelectionState,
  ]);

  if (submenu !== DEFAULT_MENU) {
    return null;
  }
  return (
    <BlockFilterContextProvider value={blockFilterContext}>
      <FilterMenu onClose={clearSubmenu} />
    </BlockFilterContextProvider>
  );
};

export default DriverEntityDropdownMenu;
