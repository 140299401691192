import { Draft } from '@reduxjs/toolkit';
import { keyBy, remove } from 'lodash';

import {
  ExtTableCreateInput,
  ExtTableDeleteInput,
  ExtTableRunCreateInput,
  ExtTableSnapshot,
  ExtTableUpdateInput,
} from 'generated/graphql';
import { DatasetSnapshot } from 'reduxStore/models/dataset';
import { ExtTable } from 'reduxStore/models/extTables';
import { Layer } from 'reduxStore/models/layers';

export function setExtTablesFromDatasetSnapshot(layer: Draft<Layer>, dataset: DatasetSnapshot) {
  if (dataset == null || dataset.extTables == null) {
    layer.extTables = { byKey: {}, allKeys: [] };
    return;
  }

  const extTables = dataset.extTables.map((extTable) => {
    const { sourceKey, queryId, schema, computedSnapshot } = extTable;
    const extTableCopy: ExtTable = {
      sourceKey,
      queryId,
      schema,
      extKey: sourceKey,
      computedSnapshot: computedSnapshot ?? undefined,
      snapshotIsStale: false,
    };
    return extTableCopy;
  });

  layer.extTables = {
    byKey: keyBy(extTables, 'extKey'),
    allKeys: extTables.map((t) => t.extKey),
  };
}

export function handleCreateExtTable(layer: Draft<Layer>, input: ExtTableCreateInput) {
  const { sourceKey, queryId, schema } = input;
  const extTable: ExtTable = {
    sourceKey,
    queryId,
    schema,
    extKey: sourceKey,
    snapshotIsStale: false,
  };
  layer.extTables.byKey[sourceKey] = extTable;
  layer.extTables.allKeys.push(sourceKey);
}

export function handleUpdateExtTable(layer: Draft<Layer>, input: ExtTableUpdateInput) {
  const { sourceKey, schema } = input;
  const extTable = layer.extTables.byKey[sourceKey];
  if (extTable == null) {
    return;
  }
  if (schema != null) {
    extTable.schema = schema;
  }
}

export function handleDeleteExtTable(layer: Draft<Layer>, input: ExtTableDeleteInput) {
  const { sourceKey } = input;
  const extTable = layer.extTables.byKey[sourceKey];
  if (extTable == null) {
    return;
  }
  delete layer.extTables.byKey[sourceKey];
  remove(layer.extTables.allKeys, (k) => k === sourceKey);
}

export function handleCreateExtTableRun(layer: Draft<Layer>, input: ExtTableRunCreateInput) {
  const { sourceKey } = input;
  const extTable = layer.extTables.byKey[sourceKey];
  if (extTable == null) {
    return;
  }
  extTable.snapshotIsStale = true;
}

export function handleUpdateComputedSnapshotForExtTables(
  layer: Draft<Layer>,
  snapshotBySourceKey: NullableRecord<string, ExtTableSnapshot>,
) {
  Object.entries(snapshotBySourceKey).forEach(([sourceKey, snapshot]) => {
    const extTable = layer.extTables.byKey[sourceKey];
    if (extTable == null) {
      return;
    }
    extTable.computedSnapshot = snapshot;
  });
}
