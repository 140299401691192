import { groupBy, mapValues } from 'lodash';
import uniq from 'lodash/uniq';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import {
  BusinessObjectFieldCellRef,
  BusinessObjectTimeSeriesCellRef,
  CellRef,
  CellSelection,
  CopiedCells,
  DriverCellRef,
  DriverDataCellRef,
  ExtDriverCellRef,
} from 'config/cells';
import {
  getCellRefDriverId,
  getFieldSpecIdFromCellRef,
  isBusinessObjectFieldCellRef,
  isBusinessObjectFieldTimeSeriesCellRef,
  isBusinessObjectPropertyFieldCellRef,
  isDriverCellRef,
  isDriverDataCellRef,
  isExtDriverCellRef,
  isMonthColumnKey,
} from 'helpers/cells';
import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import { isNotNull } from 'helpers/typescript';
import { BlockId } from 'reduxStore/models/blocks';
import { BusinessObjectSpec } from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { DriverId } from 'reduxStore/models/drivers';
import { EventGroupId, EventId } from 'reduxStore/models/events';
import { ExtDriverId } from 'reduxStore/models/extDrivers';
import { LayerId } from 'reduxStore/models/layers';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { businessObjectSpecsByIdForLayerSelector } from 'selectors/businessObjectSpecsSelector';
import { fieldIdForFieldSpecIdAndObjectIdSelector } from 'selectors/businessObjectsSelector';
import { fieldSelector } from 'selectors/constSelectors';
import { lastActualsMonthKeyForLayerSelector } from 'selectors/lastActualsSelector';
import { pageSelector } from 'selectors/pageBaseSelector';
import { eventIdsFromSelection, prevailingSelectionSelector } from 'selectors/selectionSelector';
import { MonthKey } from 'types/datetime';
import { ParametricSelector, Selector } from 'types/redux';

export const prevailingCellSelectionSelector: Selector<CellSelection | null> = createSelector(
  prevailingSelectionSelector,
  (selection) => {
    switch (selection?.type) {
      case 'eventsAndGroups': {
        return selection.cellSelection ?? null;
      }
      case 'cell': {
        return selection;
      }
      default: {
        return null;
      }
    }
  },
);

export const prevailingActiveCellMonthKeySelector: Selector<MonthKey | null> = createSelector(
  prevailingCellSelectionSelector,
  (cellSelection) => {
    if (cellSelection == null) {
      return null;
    }

    const { activeCell } = cellSelection;
    if (activeCell == null || !isMonthColumnKey(activeCell.columnKey)) {
      return null;
    }
    return activeCell.columnKey.monthKey;
  },
);

export const driverCellSelectionSelector: Selector<CellSelection<DriverCellRef> | null> =
  createSelector(prevailingCellSelectionSelector, (cellSelection) => {
    if (cellSelection == null) {
      return null;
    }

    const { activeCell } = cellSelection;
    if (!isDriverCellRef(activeCell)) {
      return null;
    }

    // N.B. for now, avoid programatically checking that all cells are the right type
    return cellSelection as CellSelection<DriverCellRef>;
  });

export const cellSelectionMonthKeysByDriverIdSelector: Selector<Record<DriverId, string[]>> =
  createSelector(driverCellSelectionSelector, (driverCellSelection) => {
    const selectedCells = driverCellSelection?.selectedCells ?? [];

    const selectedCellMonthsAndDrivers = selectedCells
      ?.map((selectedCell) => {
        if (!isMonthColumnKey(selectedCell.columnKey)) {
          return null;
        }

        const driverId = getCellRefDriverId(selectedCell);
        if (driverId == null) {
          return null;
        }

        return {
          monthKey: selectedCell.columnKey.monthKey,
          driverId,
        };
      })
      .filter(isNotNull);

    const selectedCellMonthsAndDriversByDriverId = groupBy(
      selectedCellMonthsAndDrivers,
      ({ driverId }) => driverId,
    );
    const selectedMonthKeysByDriverId = mapValues(
      selectedCellMonthsAndDriversByDriverId,
      (monthsAndDrivers) => monthsAndDrivers.map(({ monthKey }) => monthKey),
    );

    return selectedMonthKeysByDriverId;
  });

export const cellSelectionMonthKeysSelector: Selector<MonthKey[]> = createSelector(
  prevailingCellSelectionSelector,
  (cellSelection) => {
    const selectedCells = cellSelection?.selectedCells ?? [];

    const selectedCellMonths = selectedCells
      ?.map((selectedCell) => {
        if (!isMonthColumnKey(selectedCell.columnKey)) {
          return null;
        }

        return selectedCell.columnKey.monthKey;
      })
      .filter(isNotNull);

    return selectedCellMonths;
  },
);

export const cellSelectionIncludesActualsSelector: Selector<boolean> = createSelector(
  cellSelectionMonthKeysSelector,
  lastActualsMonthKeyForLayerSelector,
  (monthKeys, lastActualsMonthKey) => {
    return monthKeys.some((monthKey) => monthKey <= lastActualsMonthKey);
  },
);

export const driverDataCellSelectionSelector: Selector<CellSelection<DriverDataCellRef> | null> =
  createSelector(driverCellSelectionSelector, (cellSelection) => {
    if (cellSelection == null) {
      return null;
    }

    const { activeCell } = cellSelection;
    if (
      !isDriverDataCellRef(activeCell) ||
      !cellSelection.selectedCells.every(isDriverDataCellRef)
    ) {
      return null;
    }

    return cellSelection as CellSelection<DriverDataCellRef>;
  });

const extDriverCellSelectionSelector: Selector<CellSelection<ExtDriverCellRef> | null> =
  createSelector(prevailingCellSelectionSelector, (cellSelection) => {
    if (cellSelection == null) {
      return null;
    }

    const { activeCell } = cellSelection;
    if (!isExtDriverCellRef(activeCell)) {
      return null;
    }

    // N.B. for now, avoid programatically checking that all cells are the right type
    return cellSelection as CellSelection<ExtDriverCellRef>;
  });

export const objectFieldCellSelectionSelector: Selector<CellSelection<BusinessObjectFieldCellRef> | null> =
  createSelector(prevailingCellSelectionSelector, (cellSelection) => {
    if (cellSelection == null) {
      return null;
    }

    const { activeCell } = cellSelection;
    if (!isBusinessObjectFieldCellRef(activeCell)) {
      return null;
    }

    return cellSelection as CellSelection<BusinessObjectFieldCellRef>;
  });

export const objectFieldTimeSeriesCellSelectionSelector: Selector<CellSelection<BusinessObjectTimeSeriesCellRef> | null> =
  createSelector(prevailingCellSelectionSelector, (cellSelection) => {
    if (cellSelection == null) {
      return null;
    }

    const { activeCell } = cellSelection;
    if (!isBusinessObjectFieldTimeSeriesCellRef(activeCell)) {
      return null;
    }

    return cellSelection as CellSelection<BusinessObjectTimeSeriesCellRef>;
  });

export const prevailingActiveCellSelector: Selector<CellRef | null> = createSelector(
  prevailingCellSelectionSelector,
  (cellSelection) => cellSelection?.activeCell ?? null,
);

export const isEditingPrevailingActiveCellSelector: Selector<boolean> = createSelector(
  prevailingCellSelectionSelector,
  (cellSelection) => cellSelection?.activeCell.isEditing ?? false,
);

export const prevailingActiveCellDriverIdSelector: Selector<string | undefined> = createSelector(
  prevailingActiveCellSelector,
  (activeCell) => {
    return getCellRefDriverId(activeCell);
  },
);

export function prevailingActiveCellObjectFieldId(state: RootState): string | undefined {
  const activeCell = prevailingActiveCellSelector(state);

  if (
    activeCell == null ||
    !(
      isBusinessObjectPropertyFieldCellRef(activeCell) ||
      isBusinessObjectFieldTimeSeriesCellRef(activeCell)
    )
  ) {
    return undefined;
  }

  const { objectId } = activeCell.rowKey;
  const objectFieldSpecId = getFieldSpecIdFromCellRef(activeCell);

  if (objectId == null || objectFieldSpecId == null) {
    return undefined;
  }

  const objectFieldId = fieldIdForFieldSpecIdAndObjectIdSelector(state, {
    businessObjectId: objectId,
    businessObjectFieldSpecId: objectFieldSpecId,
  });

  return objectFieldId;
}

export const prevailingCellSelectionBlockIdSelector: Selector<BlockId | null> = createSelector(
  prevailingCellSelectionSelector,
  (cellSelection) => cellSelection?.blockId ?? null,
);

export const prevailingSelectedObjectIdsSelector: Selector<BusinessObjectId[] | null> =
  createSelector(objectFieldCellSelectionSelector, (cellSelection) => {
    if (cellSelection == null) {
      return null;
    }

    return uniq(cellSelection.selectedCells.map((ref) => ref.rowKey.objectId).filter(isNotNull));
  });

export const copiedCellsSelector: Selector<CopiedCells> = createDeepEqualSelector(
  pageSelector,
  (page) => page?.copiedCells ?? null,
);

export const prevailingSelectedDriverIdsSelector: Selector<DriverId[]> = createDeepEqualSelector(
  driverCellSelectionSelector,
  (driverCellSelection) => {
    if (driverCellSelection == null) {
      return [];
    }

    return uniq(
      driverCellSelection.selectedCells.map((ref) => getCellRefDriverId(ref)).filter(isNotNull),
    );
  },
);

export const numSelectedDriversSelector: Selector<number> = createSelector(
  prevailingSelectedDriverIdsSelector,
  (ids) => ids.length,
);

export const prevailingSelectedExtDriverIdsSelector: Selector<ExtDriverId[]> =
  createDeepEqualSelector(extDriverCellSelectionSelector, (extDriverCellSelection) => {
    if (extDriverCellSelection == null) {
      return [];
    }
    return uniq(
      extDriverCellSelection.selectedCells.map((ref) => ref.rowKey.extDriverId).filter(isNotNull),
    );
  });

export const numSelectedExtDriversSelector: Selector<number> = createSelector(
  prevailingSelectedExtDriverIdsSelector,
  (ids) => ids.length,
);

const EMPTY_EVENT_IDS = { eventIds: [], eventGroupIds: [] };
export const prevailingSelectedEventsAndEventGroupsIdsSelector: Selector<{
  eventIds: EventId[];
  eventGroupIds: EventGroupId[];
}> = createSelector(prevailingSelectionSelector, (selection) => {
  if (selection?.type !== 'eventsAndGroups') {
    return EMPTY_EVENT_IDS;
  }

  const eventIds = eventIdsFromSelection(selection);
  const eventGroupIds = selection.refs.filter((ref) => ref.type === 'group').map((ref) => ref.id);

  return { eventIds, eventGroupIds };
});

type FocusFieldSpecIdProps = {
  specId: string;
  layerId: LayerId;
};

export const focusFieldSpecIdSelector: ParametricSelector<FocusFieldSpecIdProps, string | null> =
  createCachedSelector(
    businessObjectSpecsByIdForLayerSelector,
    (state: RootState) => objectFieldCellSelectionSelector(state)?.activeCell,
    fieldSelector<FocusFieldSpecIdProps, 'specId'>('specId'),
    (
      objectSpecsById: NullableRecord<string, BusinessObjectSpec>,
      activeCell: BusinessObjectFieldCellRef | undefined,
      specId: string,
    ) => {
      const spec = objectSpecsById[specId];

      if (spec == null) {
        return null;
      }

      // Try to infer which field we should select in the detail pane when it is
      // first opened.
      const selectedFieldSpecId =
        activeCell != null && 'objectFieldSpecId' in activeCell.columnKey
          ? activeCell.columnKey.objectFieldSpecId
          : null;

      const nonStartFields = spec.fields.filter((f) => f.id !== spec.startFieldId);
      const selectedValidFieldSpecId = nonStartFields.find((f) => f.id === selectedFieldSpecId)?.id;
      return selectedValidFieldSpecId ?? nonStartFields[0]?.id;
    },
  )(
    (state, FocusFieldSpecIdProps) =>
      `${FocusFieldSpecIdProps.layerId}${FocusFieldSpecIdProps.specId}`,
  );
