import { Box, Center, Flex, Text } from '@chakra-ui/react';
import React from 'react';

import EmojiIcon from 'components/EmojiWidget/EmojiIcon';
import DefaultScenarioIcon from 'components/ScenarioManagement/DefaultScenarioIcon';
import { DEFAULT_COMPARISON_LAYER_COLOR } from 'config/scenarioComparison';
import { extractEmoji } from 'helpers/emoji';
import useAppSelector from 'hooks/useAppSelector';
import { useOptionalBlockId } from 'hooks/useBlockContext';
import { LayerId } from 'reduxStore/models/layers';
import { isLayerNamedVersionSelector, layerNameByIdSelector } from 'selectors/layerSelector';
import { comparisonLayerColorByIdForBlockSelector } from 'selectors/rollupSelector';
import RepeatClock from 'vectors/RepeatClock';

interface Props {
  layerId: LayerId;
  noBgColor?: boolean;
  iconColor?: string;
  enableIconBgColor?: boolean; // Temporary prop until we add clearer legends to charts with scenarios
  fontColor?: string;
  fontWeight?: string;
  fontSize?: string;
  isBaselineLayer?: boolean;
  isComparisonLayout?: boolean;
  size?: 'sm' | 'md';
}

const LayerName: React.FC<Props> = ({
  layerId,
  noBgColor,
  iconColor,
  enableIconBgColor,
  isBaselineLayer,
  isComparisonLayout,
  fontSize = 'sm',
  size = 'md',
}) => {
  const blockId = useOptionalBlockId();

  const layerName = useAppSelector((state) =>
    layerId != null ? layerNameByIdSelector(state)[layerId] : undefined,
  );
  const [layerEmoji, layerDisplayName] =
    layerName != null ? extractEmoji(layerName) : [null, undefined];

  const layerColor = useAppSelector((state) => {
    if (noBgColor) {
      return undefined;
    }
    if (blockId == null) {
      return undefined;
    }
    return layerId != null
      ? comparisonLayerColorByIdForBlockSelector(state, blockId)[layerId] ??
          DEFAULT_COMPARISON_LAYER_COLOR
      : DEFAULT_COMPARISON_LAYER_COLOR;
  });

  const isLayerNamedVersion = useAppSelector((state) =>
    isLayerNamedVersionSelector(state, layerId),
  );

  if (size === 'sm') {
    return (
      <Flex
        columnGap={1}
        align="center"
        data-testid={`layer-name-${layerDisplayName}`}
        fontWeight="medium"
        id="layer-name-wrapper"
      >
        <Box
          bgColor={layerColor}
          w={2}
          h={2}
          border="px"
          borderRadius="sm"
          borderColor={layerColor}
        />
        <Text fontSize="xxs">{layerDisplayName}</Text>
      </Flex>
    );
  }

  return (
    <Flex
      columnGap={1}
      align="center"
      data-testid={`layer-name-${layerDisplayName}`}
      fontWeight={!isBaselineLayer ? 'semibold' : 'medium'}
      color={isComparisonLayout ? 'gray.500' : 'inherit'}
      gap={2}
      id="layer-name-wrapper"
    >
      <Center borderRadius="md" w={5} id="layer-icon-wrap">
        {isLayerNamedVersion ? (
          <Flex p="3px" borderRadius="md" bgColor="white" color="white">
            <RepeatClock color={iconColor} boxSize={4} />
          </Flex>
        ) : (
          <Center bgColor={enableIconBgColor ? layerColor : undefined} borderRadius="md">
            <EmojiIcon
              emoji={layerEmoji}
              size="sm"
              emptyIcon={<DefaultScenarioIcon color={enableIconBgColor ? 'white' : undefined} />}
            />
          </Center>
        )}
      </Center>
      <Text fontSize={fontSize}>{layerDisplayName}</Text>
    </Flex>
  );
};

export default LayerName;
