import { Flex, StyleProps, Text, TextProps, useDimensions } from '@chakra-ui/react';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import BaseAttributeBadges, { COL_GAP } from 'components/BaseAttributeBadges/BaseAttributeBadges';
import LightDriverAttributeBadge from 'components/LightDriverAttributeBadge/LightDriverAttributeBadge';
import { DriverRowContext } from 'config/driverRowContext';
import { toPxString } from 'helpers/styles';
import useAppSelector from 'hooks/useAppSelector';
import {
  attributesForSubDriverIdSelector,
  pseudoAttributeSelectorBySubDriverIdSelector,
} from 'selectors/driversSelector';

interface Props {
  name: string;
  indents?: number;
  isPlaceholder?: boolean;
  as?: TextProps['as'];
  fontSize?: StyleProps['fontSize'];
}

const INDENT_WIDTH = 20;

const DriverNameLight: React.FC<Props> = ({
  name,
  indents = 0,
  as = undefined,
  fontSize = 'xs',
}) => {
  const { driverId } = useContext(DriverRowContext);
  const attrs = useAppSelector((state) =>
    driverId != null ? attributesForSubDriverIdSelector(state, driverId) : undefined,
  );

  const emptyAttributes = useAppSelector((state) =>
    driverId != null ? pseudoAttributeSelectorBySubDriverIdSelector(state, driverId) : undefined,
  );
  const allAttrs = useMemo(
    () => [...(attrs ?? []), ...(emptyAttributes ?? [])],
    [attrs, emptyAttributes],
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);
  const containerDimensions = useDimensions(containerRef);
  const textDimensions = useDimensions(textRef);
  const [availableWidth, setAvailableWidth] = useState(0);

  useEffect(() => {
    if (!containerDimensions || !textDimensions) {
      return;
    }
    setAvailableWidth(
      Math.ceil(containerDimensions.contentBox.width - textDimensions.contentBox.width - COL_GAP),
    );
  }, [containerDimensions, textDimensions]);

  const hasAttributes = allAttrs.length > 0;
  return (
    <Flex
      ref={containerRef}
      flex={1}
      maxWidth="full"
      overflow="hidden"
      alignItems="center"
      columnGap={1}
    >
      <Text
        ref={textRef}
        flexShrink={0}
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        minWidth={4}
        fontSize={fontSize}
        textIndent={toPxString(indents * INDENT_WIDTH)}
        as={as}
      >
        {name}
      </Text>
      {hasAttributes && availableWidth > 0 && (
        <BaseAttributeBadges
          attributes={allAttrs}
          availableWidth={availableWidth}
          renderBadge={(props) => <LightDriverAttributeBadge {...props} />}
        />
      )}
    </Flex>
  );
};

export default React.memo(DriverNameLight);
