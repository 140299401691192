import { Box, Wrap, WrapItem, useBoolean } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';

import AddDriversToBlockPopover from 'components/AddDriversToBlockPopover/AddDriversToBlockPopover';
import AgChartBackfillSeriesConfig from 'components/AgGridComponents/AgChart/AgChartBackillSeriesConfig';
import AgChartMigrateBlockConfig from 'components/AgGridComponents/AgChart/AgChartMigrateBlockConfig';
import DriverChartsBlockEmptyState from 'components/DriverBlockEmptyState/DriverChartsBlockEmptyState';
import DriverChartCard from 'components/DriverChartCard/DriverChartCard';
import AddDriverChartCard from 'components/DriverCharts/AddDriverChartCard';
import DriverChartBorder from 'components/DriverCharts/DriverChartBorder';
import { AddDriversPopoverContextProvider } from 'config/driverGridContext';
import useAppSelector from 'hooks/useAppSelector';
import { useBlockAutoFocus } from 'hooks/useBlockAutoFocus';
import useBlockContext from 'hooks/useBlockContext';
import { DriverId } from 'reduxStore/models/drivers';
import { isMultiDriverChart } from 'reduxStore/reducers/helpers/viewOptions';
import { blockConfigViewOptionsSelector } from 'selectors/blocksSelector';
import { enableAgChartsSelector } from 'selectors/launchDarklySelector';

interface Props {
  driverIds: DriverId[];
  isEditable: boolean;
  showEventImpact?: boolean;
  /**
   * Overrides the enable-ag-charts feature flag for cases where
   * we should fallback onto the legacy charts.
   */
  disableAgCharts?: boolean;
}

const DriverCharts: React.FC<Props> = ({
  driverIds,
  isEditable,
  showEventImpact,
  disableAgCharts = false,
}) => {
  const { blockId, gutterWidthInPxString, readOnly } = useBlockContext();
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));
  const enableAgCharts = useAppSelector(enableAgChartsSelector);

  const canEdit = !readOnly && isEditable;
  const isMultiDriver = isMultiDriverChart(viewOptions);
  const [isAdding, setIsAdding] = useBoolean();
  useBlockAutoFocus(setIsAdding.on);

  // The first time a legacy chart block is loaded, migrate the block config.
  if (!disableAgCharts && enableAgCharts && !viewOptions.chartDisplay) {
    return (
      <Box ml={gutterWidthInPxString} position="relative">
        <AgChartMigrateBlockConfig />
      </Box>
    );
  }

  if (
    !disableAgCharts &&
    enableAgCharts &&
    viewOptions.chartDisplay?.series.some((s) => isEmpty(s.driverId))
  ) {
    return (
      <Box ml={gutterWidthInPxString} position="relative">
        <AgChartBackfillSeriesConfig />
      </Box>
    );
  }

  return (
    <AddDriversPopoverContextProvider>
      <Box ml={gutterWidthInPxString} position="relative">
        {/*
      visible overflow to show the slightly offset top borders. They are offset
      on the top so that we don't get the weird overlapping double border
      effect.
      */}
        <Wrap w="full" spacing={0} overflow="visible">
          {isMultiDriver && driverIds.length !== 0 ? (
            <WrapItem position="relative">
              <DriverChartCard
                driverIds={driverIds}
                isEditable={canEdit}
                isLast
                showEventImpact={showEventImpact}
                disableAgCharts={disableAgCharts}
              />
              <DriverChartBorder />
            </WrapItem>
          ) : (
            driverIds.map((driverId, idx) => (
              <WrapItem key={driverId} position="relative">
                <DriverChartCard
                  driverIds={driverIds}
                  chartIndex={idx}
                  isLast={idx === driverIds.length - 1}
                  isEditable={canEdit}
                  showEventImpact={showEventImpact}
                  disableAgCharts={disableAgCharts}
                />
                <DriverChartBorder />
              </WrapItem>
            ))
          )}
          {driverIds.length === 0 && canEdit && (
            <DriverChartsBlockEmptyState isAdding={isAdding} onOpenDriverSelect={setIsAdding.on} />
          )}
          {driverIds.length !== 0 && canEdit && !isMultiDriver && (
            <AddDriverChartCard isAdding={isAdding} onOpenDriverSelect={setIsAdding.on} />
          )}
        </Wrap>
        {/* Draw a line to cover the left borders */}
        <Box position="absolute" top={0} bottom={0} left={0} w="1px" bg="white" />
      </Box>
      <AddDriversToBlockPopover isAdding={isAdding} onClose={setIsAdding.off} />
    </AddDriversPopoverContextProvider>
  );
};

export default DriverCharts;
