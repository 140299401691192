import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, Flex, ListItem, Switch, Text, useListStyles } from '@chakra-ui/react';
import React, { useCallback } from 'react';

import { Tooltip } from 'chakra/tooltip';

interface CommonProps {
  left: React.ReactNode;
  right: React.ReactNode;
  isSelected?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  isDisabled?: boolean;
  tooltipText?: string;
  'data-testid'?: string;
}

const CommonListItem = React.forwardRef<HTMLLIElement, CommonProps>(
  (
    {
      left,
      right,
      onClick,
      isDisabled = false,
      isSelected = false,
      tooltipText,
      'data-testid': dataTestId,
    },
    ref,
  ) => {
    const listStyles = useListStyles();

    return (
      <Tooltip label={tooltipText} isDisabled={tooltipText == null}>
        <ListItem
          role="group"
          aria-selected={isSelected}
          onClick={isDisabled ? undefined : onClick}
          ref={ref}
          flex={1}
          aria-disabled={isDisabled}
          userSelect="none"
          _disabled={{
            color: 'gray.400',
            bg: 'white',
            cursor: 'default',
          }}
          data-testid={dataTestId}
        >
          <Flex w="full" alignItems="center" justifyContent="space-between">
            <Flex>{left}</Flex>
            <Flex
              alignItems="center"
              columnGap={1}
              sx={listStyles.submenuLabel}
              pl={6}
              maxWidth="12rem"
              aria-disabled={isDisabled}
              _disabled={{
                cursor: 'default',
                _groupHover: { bg: 'white', color: 'gray.400' },
              }}
            >
              {right}
            </Flex>
          </Flex>
        </ListItem>
      </Tooltip>
    );
  },
);

interface BooleanSubmenuListItemProps extends Omit<CommonProps, 'left' | 'right'> {
  name: string;
  isChecked: boolean;
  onChange?: (checked: boolean) => void;
}

export const BooleanSubmenuListItem = React.forwardRef<HTMLLIElement, BooleanSubmenuListItemProps>(
  ({ name, isChecked, onChange }, ref) => {
    const handleClick = useCallback(
      (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        onChange?.(!isChecked);
      },
      [isChecked, onChange],
    );

    return (
      <CommonListItem
        ref={ref}
        left={<Text>{name}</Text>}
        right={<Switch size="sm" isChecked={isChecked} onClick={handleClick} />}
        onClick={handleClick}
      />
    );
  },
);

interface SubmenuListItemProps extends Omit<CommonProps, 'left' | 'right'> {
  name: string;
  icon?: React.ReactNode;
  iconColor?: string;
  submenuLabel?: string;
  submenuArrow?: boolean;
  submenuLeft?: React.ReactNode;
  submenuRight?: React.ReactNode;
}

const SubmenuListItem = React.forwardRef<HTMLLIElement, SubmenuListItemProps>(
  (
    { name, icon, iconColor, submenuLabel, submenuArrow, submenuLeft, submenuRight, ...rest },
    ref,
  ) => {
    const listStyles = useListStyles();

    return (
      <CommonListItem
        ref={ref}
        {...rest}
        left={
          <Flex alignItems="center" gap={1}>
            {icon != null && (
              <Box
                sx={{
                  ...listStyles.submenuLabel,
                  color: iconColor ?? listStyles.submenuLabel.color,
                }}
              >
                {icon}
              </Box>
            )}
            <Text>{name}</Text>
          </Flex>
        }
        right={
          <>
            {submenuLeft}
            <Text isTruncated>{submenuLabel}</Text>
            {submenuRight}
            {submenuArrow || (submenuLabel != null && submenuArrow !== false) ? (
              <ArrowForwardIcon />
            ) : null}
          </>
        }
      />
    );
  },
);

SubmenuListItem.displayName = 'SubmenuListItem';

export default SubmenuListItem;
