import { createIcon, IconProps } from '@chakra-ui/react';
import React from 'react';

const Arrowhead = createIcon({
  displayName: 'ArrowheadIcon',
  defaultProps: { transition: 'transform 0.2s ease' },
  viewBox: '0 0 10 6',
  d: 'M4.61589 5.53907L0.68341 0.820092C0.412024 0.494429 0.643602 0 1.06752 0L8.93248 0C9.3564 0 9.58798 0.494428 9.31659 0.820091L5.38411 5.53907C5.18421 5.77894 4.81579 5.77894 4.61589 5.53907Z',
});

const ROTATION_BY_DIRECTION = {
  down: 0,
  left: 90,
  right: -90,
  up: 180,
} as const;

interface ArrowheadProps extends Omit<IconProps, 'css'> {
  direction: keyof typeof ROTATION_BY_DIRECTION;
}

const ArrowheadIcon = ({ direction, ...props }: ArrowheadProps) => {
  return <Arrowhead {...props} transform={`rotate(${ROTATION_BY_DIRECTION[direction]}deg)`} />;
};

export default React.memo(ArrowheadIcon);
