import { DeleteIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  FlexProps,
  IconButton,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  StyleProps,
  Text,
} from '@chakra-ui/react';
import keyBy from 'lodash/keyBy';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useDebounce } from 'react-use';

import { useBlockFilterContext } from 'components/BlockFilterContext/BlockFilterContext';
import EscapableInput from 'components/EscapableInput/EscapableInput';
import FormulaDropdownContext from 'components/FormulaInput/FormulaDropdownContext';
import MenuListWithSearch from 'components/MenuListWithSearch/MenuListWithSearch';
import MultiSelectSearchMenu from 'components/MultiSelectSearchMenu';
import TimePeriodSelectionMenu from 'components/TimePeriodSelectionMenu/TimePeriodSelectionMenu';
import { BlockFilterOperator, ValueType } from 'generated/graphql';
import { getAttributeValueString } from 'helpers/dimensionalDrivers';
import { extractEmoji } from 'helpers/emoji';
import { getFormulaDateRangeDisplay, isAtomicNumberData } from 'helpers/formula';
import { getNumber } from 'helpers/number';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import useControlledPopover from 'hooks/useControlledPopover';
import {
  businessObjectSpecNameSelector,
  businessObjectSpecNamesByIdSelector,
} from 'selectors/businessObjectSpecsSelector';
import { businessObjectNamesByIdForSpecSelector } from 'selectors/businessObjectsSelector';
import { dimensionsByIdSelector } from 'selectors/dimensionsSelector';
import { driverNamesByIdSelector } from 'selectors/driversSelector';
import {
  planTimelineEventGroupsByIdSelector,
  planTimelineObjectSpecsSelector,
} from 'selectors/planTimelineSelector';
import { submodelNamesByIdSelector } from 'selectors/submodelPageSelector';
import {
  EntityIdFilterItem,
  FILTER_OPERATOR_DISPLAY_TEXT,
  FilterItem,
  FilterValueTypes,
  ValueFilterItem,
  isEntityIdFilterItem,
  isEventGroupEntityFilter,
  isFormulaFilterItem,
  isModelEntityFilter,
  isObjectEntityFilter,
  isSpectEntityFilter,
  isValueFilterItem,
} from 'types/filtering';
import { ANY_ATTR, NO_ATTR } from 'types/formula';
import CalendarIcon from 'vectors/Calendar';
import DimensionIcon from 'vectors/Cube';
import DropdownIcon from 'vectors/Dropdown';
import NumberSignIcon from 'vectors/NumberSign';
import TextIcon from 'vectors/Text';

interface Props {
  filter: FilterItem;
  isFirst: boolean;
  updateFilter: (update: FilterItem) => void;
  deleteFilter: () => void;
}

const FilterMenuItem: React.FC<Props & Omit<FlexProps, 'filter'>> = ({
  filter,
  updateFilter,
  deleteFilter,
  isFirst,
  ...props
}) => {
  const { availableFilters } = useBlockFilterContext();

  const availableFiltersByKey = useMemo(
    () => keyBy(availableFilters, 'filterKey'),
    [availableFilters],
  );
  const isEntityTimePeriodFilter = filter.filterKey === 'entityTimePeriod';

  const filterOptions = useMemo(() => {
    const map = new Map<string, FilterOption>();
    if (isEntityTimePeriodFilter) {
      map.set(filter.filterKey, { value: filter.label, type: filter.valueType });
    } else {
      availableFilters.forEach((f) => {
        const filterOption = { value: f.label, type: f.valueType };

        map.set(f.filterKey, filterOption);
      });
    }

    return map;
  }, [filter, availableFilters, isEntityTimePeriodFilter]);

  const onChange = useCallback(
    (filterKey: string | string[]) => {
      // We don't expect this to return multiple options
      if (Array.isArray(filterKey)) {
        return;
      }

      const availableFilter = availableFiltersByKey[filterKey];
      if (availableFilter == null) {
        return;
      }

      const newFilter: FilterItem = { ...availableFilter, operator: BlockFilterOperator.Equals };
      updateFilter(newFilter);
    },
    [updateFilter, availableFiltersByKey],
  );

  const isDimensionDriverFilter =
    filter.valueType === ValueType.Attribute && filter?.filterKey === filter?.dimensionId;
  // dimensional driver filters can only be set to equals at this time
  const isDisabled = isEntityTimePeriodFilter || isDimensionDriverFilter;
  const showDeleteButton = !isEntityTimePeriodFilter;
  const showExpectedInput =
    filter.operator !== BlockFilterOperator.IsNull &&
    filter.operator !== BlockFilterOperator.IsNotNull;

  return (
    <Flex fontSize="xs" alignItems="center" px={4} columnGap={1} {...props}>
      <Text w="4rem" flexShrink={0} fontWeight="medium">
        {isFirst ? 'Where' : 'and'}
      </Text>
      <FilterSetting
        width="8rem"
        onChange={onChange}
        options={filterOptions}
        selected={filter.filterKey}
        isDisabled={isEntityTimePeriodFilter}
        isMultiSelect={false}
        searchable
      />
      <FilterMenuOperatorOptions
        filter={filter}
        updateFilter={updateFilter}
        isDisabled={isDisabled}
      />
      {showExpectedInput && <ExpectedInput filter={filter} updateFilter={updateFilter} />}
      {showDeleteButton && (
        <>
          <Spacer />
          <IconButton
            icon={<DeleteIcon boxSize={3} />}
            aria-label="Delete filter"
            size="xs"
            variant="text"
            padding={1}
            onClick={deleteFilter}
            borderRadius="md"
            border="1px solid"
            borderColor="gray.300"
            color="gray.500"
            _hover={{ bgColor: 'gray.200', color: 'gray.500' }}
            _active={{ bgColor: 'gray.200', color: 'gray.500' }}
          />
        </>
      )}
    </Flex>
  );
};

const FilterMenuOperatorOptions: React.FC<{
  filter: FilterItem;
  updateFilter: (update: FilterItem) => void;
  isDisabled?: boolean;
}> = ({ filter, updateFilter, isDisabled }) => {
  const options: Map<string, FilterOption> = useMemo(() => {
    const opts: BlockFilterOperator[] = [];

    const hasNullOptions = !filter.isNotNullable;
    const canUseNotEqual = !isEntityIdFilterItem(filter) || !filter.isNotNegatable;

    if (filter.valueType === ValueType.Attribute || isEntityIdFilterItem(filter)) {
      opts.push(BlockFilterOperator.Equals);
      if (canUseNotEqual) {
        opts.push(BlockFilterOperator.NotEquals);
      }
    } else if (isFormulaFilterItem(filter)) {
      // Currently no other filters other than null/not null check
    } else {
      opts.push(
        BlockFilterOperator.Equals,
        ...(canUseNotEqual ? [BlockFilterOperator.NotEquals] : []),
        BlockFilterOperator.GreaterThan,
        BlockFilterOperator.GreaterThanOrEqualTo,
        BlockFilterOperator.LessThan,
        BlockFilterOperator.LessThanOrEqualTo,
      );
    }

    if (hasNullOptions) {
      opts.push(BlockFilterOperator.IsNull, BlockFilterOperator.IsNotNull);
    }

    const displayTextMapping = FILTER_OPERATOR_DISPLAY_TEXT[filter.valueType] ?? {};
    return new Map(
      opts.map((o) => [
        o as string,
        safeObjGet(displayTextMapping[o]) != null
          ? ({ value: displayTextMapping[o] } as FilterOption)
          : { value: o },
      ]),
    );
  }, [filter]);

  const onChange = useCallback(
    (value: string | string[]) => {
      if (Array.isArray(value) && value.length === 0) {
        return;
      }
      const newFilter = { ...filter };
      const val = Array.isArray(value) ? value[0] : value;
      newFilter.operator = val as BlockFilterOperator;
      updateFilter(newFilter);
    },
    [updateFilter, filter],
  );

  return (
    <FilterSetting
      width="fit-content"
      onChange={onChange}
      options={options}
      selected={filter.operator}
      isMultiSelect={false}
      isDisabled={isDisabled}
    />
  );
};

export type FilterOption = {
  value: string;
  type?: ValueType | FilterValueTypes;
};
interface FilterSettingProps {
  options: Map<string, FilterOption>; // to respect insertion order
  selected?: string[] | string;
  onChange: (value: string | string[]) => void;
  width: string;
  height?: StyleProps['height'];
  isDisabled?: boolean;
  searchable?: boolean;
  isMultiSelect: boolean;
}

const AttributeIcon = ({
  attributeType,
}: {
  attributeType:
    | ValueType
    | FilterValueTypes
    | Array<ValueType | FilterValueTypes | undefined>
    | undefined;
}) => {
  switch (attributeType) {
    case ValueType.Attribute:
      return <DimensionIcon color="gray.500" />;
    case ValueType.Number:
      return <NumberSignIcon color="gray.500" />;
    case ValueType.Timestamp:
      return <CalendarIcon color="gray.500" />;
    case FilterValueTypes.ENTITY:
      return <TextIcon color="gray.500" />;
    default:
      return null;
  }
};
export const FilterSetting: React.FC<FilterSettingProps> = ({
  selected,
  options,
  onChange,
  width,
  height,
  searchable = false,
  isMultiSelect = false,
  isDisabled = false,
}) => {
  const { isOpen, onOpen, onClose, contentRef, triggerRef } = useControlledPopover<
    HTMLDivElement,
    HTMLButtonElement
  >();
  const display = useMemo(() => {
    if (selected == null) {
      return '';
    }

    const result = Array.isArray(selected)
      ? selected
          .map((id) => options.get(id)?.value)
          .filter(isNotNull)
          .join(', ')
      : options.get(selected)?.value;
    return result ?? ' ';
  }, [options, selected]);

  const displayIcon = useMemo(() => {
    if (selected == null) {
      return undefined;
    }
    const result = Array.isArray(selected)
      ? selected.map((id) => options.get(id)?.type)
      : options.get(selected)?.type;
    return result;
  }, [options, selected]);

  const menuItems = useMemo(() => {
    return Array.from(options.entries()).map(([id, { value }]) => ({
      id,
      value,
      isSecondary: id === NO_ATTR,
    }));
  }, [options]);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const onSelectedIdsChange = useCallback(
    (ids: Set<string>) => {
      onChange(Array.from(ids));
    },
    [onChange],
  );
  return (
    <Popover
      isLazy
      placement="bottom-end"
      initialFocusRef={searchInputRef}
      preventOverflow
      closeOnBlur
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <PopoverTrigger>
        <Button
          w={width}
          maxW={width}
          ref={triggerRef}
          variant="light"
          boxShadow="none"
          justifyContent="start"
          size="xs"
          height={height}
          isDisabled={isDisabled}
          data-testid="object-filter-button"
        >
          <Flex alignItems="center" justifyContent="center" flex={1} gap="4px" overflow="hidden">
            <AttributeIcon attributeType={displayIcon} />
            <Text overflow="hidden" flex={1} textAlign="left">
              {display}
            </Text>
          </Flex>
          <DropdownIcon
            color="inherit"
            flexShrink={0}
            ml={1}
            boxSize={2}
            display="inline"
            direction="down"
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent ref={contentRef} padding={0}>
        {!isMultiSelect ? (
          <MenuListWithSearch
            ref={searchInputRef}
            searchEnabled={searchable}
            selected={selected}
            onChange={onChange}
            onClose={onClose}
            options={options}
          />
        ) : (
          <MultiSelectSearchMenu
            ref={searchInputRef}
            selectedIds={new Set(selected)}
            items={menuItems}
            onSelectedIdsChange={onSelectedIdsChange}
          />
        )}
      </PopoverContent>
    </Popover>
  );
};

interface ExpectedValueProps {
  filter: FilterItem;
  updateFilter: (update: FilterItem) => void;
}

const EXPECTED_VALUE_WIDTH = '11.5rem';

const ExpectedInput: React.FC<ExpectedValueProps> = ({ filter, updateFilter }) => {
  if (isValueFilterItem(filter)) {
    switch (filter.valueType) {
      case ValueType.Number: {
        return <ExpectedNumberInput filter={filter} updateFilter={updateFilter} />;
      }
      case ValueType.Attribute: {
        return <ExpectedAttributeInput filter={filter} updateFilter={updateFilter} />;
      }
      case ValueType.Timestamp: {
        if (filter.filterKey === 'entityTimePeriod') {
          return <EntityTimestampInput />;
        }
        return <ExpectedTimestampInput filter={filter} updateFilter={updateFilter} />;
      }
      case FilterValueTypes.EXT_TABLE: {
        return <ExpectedExtTableColumnInput filter={filter} updateFilter={updateFilter} />;
      }
      default:
        return null;
    }
  } else if (isEntityIdFilterItem(filter)) {
    return <ExpectedIDInput filter={filter} updateFilter={updateFilter} />;
  }
  return null;
};

const ExpectedNumberInput: React.FC<{
  filter: ValueFilterItem & { valueType: ValueType.Number };
  updateFilter: (update: ValueFilterItem & { valueType: ValueType.Number }) => void;
}> = ({ filter, updateFilter }) => {
  const display = String(filter.expected ?? '');
  const [inputText, setInputText] = useState(display);

  useDebounce(
    () => {
      const num = getNumber(inputText.trim());
      updateFilter({ ...filter, expected: Number.isNaN(num) ? undefined : num });
    },
    250,
    [updateFilter, inputText],
  );

  return (
    <EscapableInput
      w={EXPECTED_VALUE_WIDTH}
      lineHeight={1}
      variant="white"
      size="xs"
      onChange={setInputText}
      isRequired
      name="filterExpectedValue"
      value={inputText}
      fontSize="xs"
      data-testid="object-filter-input"
    />
  );
};

const ExpectedExtTableColumnInput: React.FC<{
  filter: ValueFilterItem & { valueType: FilterValueTypes.EXT_TABLE };
  updateFilter: (update: ValueFilterItem & { valueType: FilterValueTypes.EXT_TABLE }) => void;
}> = ({ filter, updateFilter }) => {
  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const text = event.target.value;
      const newFilter = { ...filter, expected: text };
      updateFilter(newFilter);
    },
    [filter, updateFilter],
  );
  return (
    <Input
      size="xs"
      fontWeight="medium"
      borderRadius="md"
      borderColor="gray.300"
      borderWidth="1px"
      _hover={{
        borderColor: 'gray.300',
        borderWidth: '1px',
      }}
      color="gray.600"
      value={filter.expected ?? ''}
      onChange={onChange}
      data-testid="expected-ext-table-column-input"
    />
  );
};

const ExpectedAttributeInput: React.FC<{
  filter: ValueFilterItem & { valueType: ValueType.Attribute };
  updateFilter: (update: ValueFilterItem & { valueType: ValueType.Attribute }) => void;
}> = ({ filter, updateFilter }) => {
  const dimensionsById = useAppSelector(dimensionsByIdSelector);
  const dimension = filter?.dimensionId != null ? dimensionsById[filter.dimensionId] : undefined;

  const addNoAttrOption = useMemo(
    () => filter.dimensionId != null && filter.dimensionId === filter.filterKey,
    [filter],
  );

  const options = useMemo(() => {
    const allowedAttrs = (dimension?.attributes || [])?.filter(
      (a) => !a.deleted || filter.expected?.includes(a.id),
    );

    const formattedOptions: Array<[string, FilterOption]> = allowedAttrs.map((attr) => [
      attr.id,
      { value: getAttributeValueString(attr), type: undefined } as FilterOption,
    ]);

    const allOptions: Array<[string, FilterOption]> = [];

    if (addNoAttrOption) {
      allOptions.push([NO_ATTR, { value: `None`, type: undefined } as FilterOption]);
    }
    allOptions.push(...formattedOptions);

    return new Map(allOptions);
  }, [addNoAttrOption, dimension, filter.expected]);

  const selected = useMemo(() => {
    const expected = new Set(filter.expected);
    const res: string[] = [];
    if (dimension == null) {
      return res;
    }
    if (expected.has(NO_ATTR)) {
      res.push(NO_ATTR);
    }
    if (expected.has(ANY_ATTR)) {
      res.push(...dimension.attributes.map((a) => a.id));
    } else {
      res.push(...(filter.expected?.filter((id) => id !== NO_ATTR) ?? []));
    }
    return res;
  }, [dimension, filter.expected]);

  const onChange = useCallback(
    (value: string | string[]) => {
      const expectedIds = Array.isArray(value) ? value : [value];
      const newFilter = { ...filter, expected: expectedIds };
      updateFilter(newFilter);
    },
    [updateFilter, filter],
  );

  if (options == null) {
    return null;
  }

  return (
    <FilterSetting
      width={EXPECTED_VALUE_WIDTH}
      onChange={onChange}
      options={options}
      selected={selected}
      isDisabled={dimension?.deleted}
      searchable
      isMultiSelect
    />
  );
};

const EntityTimestampInput = () => {
  const { onSelectTimePeriod, activeEntity } = useContext(FormulaDropdownContext);

  if (activeEntity == null || isAtomicNumberData(activeEntity)) {
    return null;
  }

  return (
    <Popover isLazy>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Button variant="light" boxShadow="none" size="xs" flex="1">
              <Text
                textAlign="left"
                flex="1"
                maxW="13.5rem"
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
              >
                {activeEntity.data.dateRangeDisplay}
              </Text>
              <DropdownIcon
                color="inherit"
                flexShrink={0}
                ml={1}
                boxSize={2}
                display="inline"
                direction="down"
              />
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <TimePeriodSelectionMenu
              entityDateRange={activeEntity.data.dateRange}
              entityDriverId={activeEntity.data.id}
              onSelectTimePeriod={onSelectTimePeriod}
              onClose={onClose}
            />
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};
const ExpectedTimestampInput: React.FC<{
  filter: ValueFilterItem & { valueType: ValueType.Timestamp };
  updateFilter: (update: ValueFilterItem & { valueType: ValueType.Timestamp }) => void;
}> = ({ filter, updateFilter }) => {
  const driverNamesById = useAppSelector(driverNamesByIdSelector);
  const display = useMemo(
    () =>
      filter.expected ? getFormulaDateRangeDisplay(filter.expected, driverNamesById) : undefined,
    [driverNamesById, filter],
  );

  return (
    <Popover isLazy>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Button
              variant="light"
              boxShadow="none"
              size="xs"
              w={EXPECTED_VALUE_WIDTH}
              maxW={EXPECTED_VALUE_WIDTH}
              overflow="hidden"
              whiteSpace="nowrap"
            >
              <Text flex={1} textAlign="left" textOverflow="ellipsis" overflow="hidden">
                {display}
              </Text>
              <DropdownIcon
                color="inherit"
                flexShrink={0}
                ml={1}
                boxSize={2}
                display="inline"
                direction="down"
              />
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <TimePeriodSelectionMenu
              entityDateRange={filter.expected}
              onSelectTimePeriod={(dateRange) => {
                onClose();
                updateFilter({ ...filter, expected: dateRange });
              }}
            />
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

const ExpectedIDInput: React.FC<{
  filter: EntityIdFilterItem;
  updateFilter: (update: EntityIdFilterItem) => void;
}> = ({ filter, updateFilter }) => {
  if (isObjectEntityFilter(filter)) {
    return <ExpectedObjectIDInput filter={filter} updateFilter={updateFilter} />;
  } else if (isModelEntityFilter(filter)) {
    return <ExpectedModelIDInput filter={filter} updateFilter={updateFilter} />;
  } else if (isSpectEntityFilter(filter)) {
    return <ExpectedSpecIDInput filter={filter} updateFilter={updateFilter} />;
  } else if (isEventGroupEntityFilter(filter)) {
    return <ExpectedEventGroupIDInput filter={filter} updateFilter={updateFilter} />;
  }

  return null;
};

const ExpectedObjectIDInput: React.FC<{
  filter: EntityIdFilterItem & { entityType: 'object' };
  updateFilter: (update: EntityIdFilterItem) => void;
}> = ({ filter, updateFilter }) => {
  const objectNamesById = useAppSelector((state) =>
    businessObjectNamesByIdForSpecSelector(state, filter.specId),
  );
  const specName = useAppSelector((state) => {
    const name = businessObjectSpecNameSelector(state, filter.specId);
    if (name == null) {
      return 'database item';
    } else {
      return extractEmoji(name)[1];
    }
  });

  const options = useMemo(() => {
    const deletedOptions: Array<[string, FilterOption]> =
      filter.expected
        ?.filter((id) => objectNamesById[id] == null)
        .map((id) => [id, { value: `deleted ${specName}` }]) ?? [];
    const allOptions: Array<[string, FilterOption]> =
      Object.entries(objectNamesById).map((option) => [option[0], { value: option[1] }]) ?? [];
    return new Map<string, FilterOption>([...allOptions, ...deletedOptions]);
  }, [objectNamesById, specName, filter]);

  return (
    <ExpectedIDInputFilterSettings
      filter={filter}
      updateFilter={updateFilter}
      options={options}
      entityNamesById={objectNamesById}
    />
  );
};

const ExpectedModelIDInput: React.FC<{
  filter: EntityIdFilterItem & { entityType: 'model' };
  updateFilter: (update: EntityIdFilterItem) => void;
}> = ({ filter, updateFilter }) => {
  const submodelNamesById = useAppSelector(submodelNamesByIdSelector);
  const options = useMemo(
    () =>
      new Map<string, FilterOption>(
        Object.entries(submodelNamesById).map((option) => [option[0], { value: option[1] }]),
      ),
    [submodelNamesById],
  );

  return (
    <ExpectedIDInputFilterSettings
      filter={filter}
      updateFilter={updateFilter}
      options={options}
      entityNamesById={submodelNamesById}
    />
  );
};

const ExpectedSpecIDInput: React.FC<{
  filter: EntityIdFilterItem & { entityType: 'spec' };
  updateFilter: (update: EntityIdFilterItem) => void;
}> = ({ filter, updateFilter }) => {
  const { blockId } = useBlockContext();
  const planTimelineObjectSpecs = useAppSelector((state) =>
    planTimelineObjectSpecsSelector(state, blockId),
  );

  const specNamesById = useAppSelector(businessObjectSpecNamesByIdSelector);
  const options = useMemo(
    () =>
      new Map<string, FilterOption>(
        planTimelineObjectSpecs.map(({ id, name }) => [id, { value: name }]),
      ),
    [planTimelineObjectSpecs],
  );

  return (
    <ExpectedIDInputFilterSettings
      filter={filter}
      updateFilter={updateFilter}
      options={options}
      entityNamesById={specNamesById}
    />
  );
};

const ExpectedEventGroupIDInput: React.FC<{
  filter: EntityIdFilterItem & { entityType: 'eventGroup' };
  updateFilter: (update: EntityIdFilterItem) => void;
}> = ({ filter, updateFilter }) => {
  const { blockId } = useBlockContext();
  const timelineEventGroups = useAppSelector((state) =>
    planTimelineEventGroupsByIdSelector(state, blockId),
  );

  const options = useMemo(
    () =>
      new Map<string, FilterOption>(
        Object.entries(timelineEventGroups).map(([id, name]) => [id, { value: name }]),
      ),
    [timelineEventGroups],
  );
  return (
    <ExpectedIDInputFilterSettings
      filter={filter}
      updateFilter={updateFilter}
      options={options}
      entityNamesById={timelineEventGroups}
    />
  );
};

const ExpectedIDInputFilterSettings: React.FC<{
  filter: EntityIdFilterItem;
  options: Map<string, FilterOption>;
  updateFilter: (update: EntityIdFilterItem) => void;
  entityNamesById: NullableRecord<string, string>;
}> = ({ filter, options, updateFilter, entityNamesById }) => {
  const onChange = useCallback(
    (value: string | string[]) => {
      const newFilter = { ...filter };
      newFilter.expected = Array.isArray(value) ? value : [value];

      if (newFilter.expected.every((id) => entityNamesById[id] != null)) {
        newFilter.error = undefined;
      }

      updateFilter(newFilter);
    },
    [updateFilter, filter, entityNamesById],
  );

  return (
    <FilterSetting
      width={EXPECTED_VALUE_WIDTH}
      onChange={onChange}
      options={options}
      selected={filter.expected}
      searchable
      isMultiSelect
    />
  );
};

export default FilterMenuItem;
