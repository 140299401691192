import { Box, Button, Text } from '@chakra-ui/react';
import Tippy from '@tippyjs/react';
import { MouseEventHandler, useCallback, useRef } from 'react';

import { CellValueCalcErrTooltip } from 'config/cells';
import { CELL_TOOLTIP_TIPPY_DELAY } from 'config/tooltips';
import { CalculationErrorType } from 'generated/graphql';
import { stopEventPropagation } from 'helpers/browserEvent';
import { getNavigationOpts } from 'helpers/navigation';
import useAppDispatch from 'hooks/useAppDispatch';
import { navigateToDriverDetailPane } from 'reduxStore/actions/navigateTo';
import { DriverId } from 'reduxStore/models/drivers';
import { NOT_APPLICABLE_ERR_TYPE } from 'types/dataset';

const DETAIL_VIEW_HELP_COPY = 'Open details view to debug';
const DOCUMENTATION_COPY = 'learn more about the new error codes';
const CELL_CALC_ERR_TOOLTIP_CONTENT: Record<CalculationErrorType, string> = {
  [CalculationErrorType.MissingEntity]:
    'This driver or one of its inputs references an input that not longer exists.',
  [CalculationErrorType.CircularDependency]:
    'This driver, or one of its formula inputs, is attempting to reference itself in the same month.',
  [CalculationErrorType.DivByZero]:
    'This driver or one of its inputs is attempting to divide by 0.',
  [CalculationErrorType.Unexpected]: 'There was an unexpected error computing the value.',
  [CalculationErrorType.InvalidType]:
    'This driver, or one of its formula inputs, is performing an operation across incompatible types or resolves to a non numeric value.',
  [CalculationErrorType.Formula]:
    'This driver, or one of its formula inputs, has a malformed formula.',
};

interface hideable {
  hide(): void;
}

type CalcErrTooltipProps = Omit<CellValueCalcErrTooltip, 'type'> & { children: JSX.Element };

const CalcErrTooltip: React.FC<CalcErrTooltipProps> = ({ error, detailDriverId, children }) => {
  const tippyInstanceRef = useRef<hideable | null>(null);
  const closePopover = () => {
    if (tippyInstanceRef.current == null) {
      return;
    }
    tippyInstanceRef.current.hide();
  };

  return (
    <Tippy
      interactive
      delay={CELL_TOOLTIP_TIPPY_DELAY}
      placement="top"
      appendTo={document.body}
      onCreate={(instance) => {
        tippyInstanceRef.current = instance;
      }}
      content={
        <Box bg="slate.100" color="white" maxW="24rem" borderRadius="md" px={2} py={1}>
          <CalcErrTooltipContent
            error={error}
            detailDriverId={detailDriverId}
            onDone={closePopover}
          />
        </Box>
      }
    >
      <Box w="inherit" h="full">
        {children}
      </Box>
    </Tippy>
  );
};

type CalcErrTooltipContentProps = Omit<CellValueCalcErrTooltip, 'type'> & {
  onDone?: () => void;
};

export const CalcErrTooltipContent: React.FC<CalcErrTooltipContentProps> = ({
  error,
  detailDriverId,
  onDone,
}) => {
  if (error.error === NOT_APPLICABLE_ERR_TYPE) {
    return null;
  }
  const content = CELL_CALC_ERR_TOOLTIP_CONTENT[error.error];

  return (
    <Text
      w="full"
      onMouseDown={stopEventPropagation}
      fontSize="xxs"
      fontWeight="medium"
      wordBreak="break-word"
    >
      {content} <OpenDetailsButton driverId={detailDriverId} closePopover={onDone} />, or{' '}
      <LearnMoreButton closePopover={onDone} />
    </Text>
  );
};

const InlineTextButton: React.FC<{ text: string; onClick: MouseEventHandler }> = ({
  text,
  onClick,
}) => {
  const onClickCallback: MouseEventHandler = useCallback(
    (ev) => {
      stopEventPropagation(ev);
      onClick(ev);
    },
    [onClick],
  );

  return (
    <Button
      variant="link"
      color="inherit"
      fontSize="inherit"
      border="none"
      _active={{}}
      _hover={{}}
      textDecoration="underline"
      onClick={onClickCallback}
    >
      {text}
    </Button>
  );
};

const OpenDetailsButton: React.FC<{
  driverId: DriverId;
  closePopover?: () => void;
}> = ({ driverId, closePopover }) => {
  const dispatch = useAppDispatch();

  const onClickOpenDetails: MouseEventHandler = useCallback(
    (ev) => {
      stopEventPropagation(ev);
      closePopover?.();
      dispatch(navigateToDriverDetailPane({ driverId }, getNavigationOpts(ev)));
    },
    [dispatch, closePopover, driverId],
  );

  return <InlineTextButton text={DETAIL_VIEW_HELP_COPY} onClick={onClickOpenDetails} />;
};

const HELP_LINK = 'https://runway.olvy.co/improved-errors-transparency';
const openHelpLink = () => {
  window.open(HELP_LINK, '_blank');
};
const LearnMoreButton: React.FC<{ closePopover?: () => void }> = ({ closePopover }) => {
  const onClickCallback = useCallback(() => {
    openHelpLink();
    closePopover?.();
  }, [closePopover]);
  return <InlineTextButton text={DOCUMENTATION_COPY} onClick={onClickCallback} />;
};

export default CalcErrTooltip;
