import { createCachedSelector } from 're-reselect';

import { DependencyListenerEvaluator } from 'helpers/formulaEvaluation/DependenciesListenerEvaluator';
import {
  SelectorWithLayerParam,
  addLayerParams,
  getCacheKeyForLayerSelector,
} from 'helpers/layerSelectorFactory';
import { businessObjectFieldSpecByIdSelector } from 'selectors/businessObjectFieldSpecsSelector';
import {
  businessObjectsByFieldIdForLayerSelector,
  businessObjectsByIdForLayerSelector,
} from 'selectors/businessObjectsSelector';
import {
  attributesBySubDriverIdSelector,
  driversByIdForLayerSelector,
} from 'selectors/driversSelector';
import { submodelIdByBlockIdSelector } from 'selectors/submodelPageSelector';

export const dependenciesListenerEvaluatorSelector: SelectorWithLayerParam<DependencyListenerEvaluator> =
  createCachedSelector(
    addLayerParams(driversByIdForLayerSelector),
    addLayerParams(businessObjectFieldSpecByIdSelector),
    addLayerParams(businessObjectsByFieldIdForLayerSelector),
    addLayerParams(businessObjectsByIdForLayerSelector),
    addLayerParams(attributesBySubDriverIdSelector),
    submodelIdByBlockIdSelector,
    // eslint-disable-next-line max-params
    function dependenciesListenerArgsSelector(
      driversById,
      fieldSpecsById,
      objectsByFieldId,
      objectsById,
      attributesBySubDriverId,
      submodelIdByBlockId,
    ) {
      return new DependencyListenerEvaluator({
        driversById,
        fieldSpecsById,
        objectsByFieldId,
        objectsById,
        attributesBySubDriverId,
        submodelIdByBlockId,
      });
    },
  )(getCacheKeyForLayerSelector);
