import { Box, Flex, StyleProps, Text, TextProps, useMergeRefs } from '@chakra-ui/react';
import { isNumber } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Tooltip } from 'chakra/tooltip';
import BaseAttributeBadges, { COL_GAP } from 'components/BaseAttributeBadges/BaseAttributeBadges';
import { useDriverDescriptionContext } from 'components/DriverNameTableCell/DriverDescriptionContext';
import LightDriverAttributeBadge from 'components/LightDriverAttributeBadge/LightDriverAttributeBadge';
import { DriverRowContext } from 'config/driverRowContext';
import { toPxString } from 'helpers/styles';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import useHover from 'hooks/useHover';
import useIsKeyPressed from 'hooks/useIsKeyPressed';
import useIsTruncated from 'hooks/useIsTruncated';
import { useMeasureText } from 'hooks/useMeasureText';
import { useObserveSize } from 'hooks/useObserveSize';
import { Attribute } from 'reduxStore/models/dimensions';
import { isDetailPaneOpenSelector } from 'selectors/detailPaneSelectors';
import { blockBVADriverExplanations } from 'selectors/driverGridBlockSelector';
import {
  attributesForSubDriverIdSelector,
  driverDescriptionSelector,
  pseudoAttributeSelectorBySubDriverIdSelector,
} from 'selectors/driversSelector';
import { featureFlagsSelector } from 'selectors/featureFlagsSelector';
import { launchDarklySelector } from 'selectors/launchDarklySelector';
import { columnWidthSelector } from 'selectors/tableColumnsSelector';

interface Props {
  name: string;
  showDescription?: boolean;
  maxWidth?: number;
  attributes?: Attribute[];
  fontWeight?: StyleProps['fontWeight'];
  fontSize?: StyleProps['fontSize'];
  indents?: number;
  as?: TextProps['as'];
}

const INDENT_WIDTH = 20;
const HOR_PADDING = 16;

const DriverName: React.FC<Props> = ({
  name,
  maxWidth,
  attributes,
  showDescription = true,
  fontWeight = 'medium',
  fontSize = 'xs',
  indents = 0,
  as = undefined,
}) => {
  const { driverId } = useContext(DriverRowContext);
  const { blockId } = useBlockContext();
  const description = useAppSelector((state) => {
    const explanations = blockBVADriverExplanations(state, blockId);
    if (explanations != null) {
      const driverExplanation = explanations[driverId];
      if (driverExplanation != null) {
        return driverExplanation;
      }
    }
    return driverDescriptionSelector(state, { id: driverId });
  });

  const reduxAttributes = useAppSelector((state) =>
    attributes == null && driverId != null
      ? attributesForSubDriverIdSelector(state, driverId)
      : undefined,
  );

  const emptyAttributes = useAppSelector((state) =>
    pseudoAttributeSelectorBySubDriverIdSelector(state, driverId),
  );

  const resolvedAttributes = useMemo(() => {
    return (attributes ?? []).concat(reduxAttributes ?? []).concat(emptyAttributes ?? []);
  }, [attributes, reduxAttributes, emptyAttributes]);

  const width = useAppSelector((state) =>
    columnWidthSelector(state, { blockId, columnType: 'name' }),
  );

  const showDebugInfo = useAppSelector(featureFlagsSelector).showDebugInfoInUI;
  const debugInfoOnClick = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log(name, driverId);
  }, [name, driverId]);

  if (showDebugInfo) {
    return (
      <Flex justifyContent="flex-start">
        <DriverNameDisplay
          name={name}
          showDescription={showDescription}
          width={width}
          maxWidth={maxWidth}
          attributes={resolvedAttributes}
          fontWeight={fontWeight}
          fontSize={fontSize}
          indents={indents}
          description={description ?? ''}
          as={as}
        />
        <Text onClick={debugInfoOnClick}>{driverId}</Text>
      </Flex>
    );
  }
  return (
    <>
      <DriverNameDisplay
        name={name}
        showDescription={showDescription}
        width={width}
        maxWidth={maxWidth}
        attributes={resolvedAttributes}
        fontWeight={fontWeight}
        fontSize={fontSize}
        indents={indents}
        description={description ?? ''}
        as={as}
      />
      {showDebugInfo && driverId}
    </>
  );
};

interface DriverNameDisplayProps {
  name: string;
  showDescription?: boolean;
  width: number;
  maxWidth?: number;
  attributes?: Attribute[];
  fontWeight?: StyleProps['fontWeight'];
  fontSize?: StyleProps['fontSize'];
  indents?: number;
  description?: string;
  as?: TextProps['as'];
}

const EMPTY_ATTRIBUTES: Attribute[] = [];

/**
 * This component is driverId agnostic and does not rely on any contexts.
 * @param param0
 * @returns
 */
export const DriverNameDisplay: React.FC<DriverNameDisplayProps> = ({
  name,
  width,
  maxWidth,
  attributes = EMPTY_ATTRIBUTES,
  showDescription = true,
  fontWeight = 'medium',
  fontSize = 'xs',
  indents = 0,
  description,
  as = undefined,
}) => {
  const hasAttributes = attributes.length > 0;
  const detailPaneIsOpen = useAppSelector(isDetailPaneOpenSelector);
  const { enableAiExplanationsInFrontend } = useAppSelector(launchDarklySelector);

  const { ref: containerRef, width: containerWidth } = useObserveSize<HTMLDivElement>();
  const { ref: textRef, isTruncated, width: textWidth } = useIsTruncated();
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();

  const mergedRef = useMergeRefs(containerRef, hoverRef);

  // On first mount use the estimated text width to prevent flicker.
  const measure = useMeasureText({ font: 'Inter', size: '14px' });
  const measuredWidth = useMemo(() => measure(name)?.width ?? 0, [measure, name]);
  const availableWidth = Math.max(
    0,
    containerWidth === 0 || textWidth === 0
      ? width - HOR_PADDING - measuredWidth - COL_GAP
      : containerWidth - textWidth - COL_GAP,
  );

  const [allClipped, setAllClipped] = useState(false);
  const isAltKeyDown = useCallback((ev: KeyboardEvent) => ev.key === 'Alt', []);
  const isDescriptionKeyDown = useIsKeyPressed(isAltKeyDown);
  let canShowDescription = showDescription && !isEmpty(description) && !detailPaneIsOpen;
  if (enableAiExplanationsInFrontend) {
    canShowDescription = canShowDescription && isDescriptionKeyDown;
  }
  const showTooltip = (allClipped || isTruncated || canShowDescription) && !isDescriptionKeyDown;

  const [_, setAIDescription] = useDriverDescriptionContext();
  useEffect(() => {
    if (enableAiExplanationsInFrontend && canShowDescription && description != null && isHovered) {
      let nameWithAttributes: ReactNode = name;
      if (attributes.length > 0) {
        nameWithAttributes = (
          <Flex gap="2">
            <Text minWidth={4} fontSize="xs" fontWeight="bold">
              {name}
            </Text>
            <BaseAttributeBadges
              attributes={attributes}
              renderBadge={(props) => <LightDriverAttributeBadge {...props} />}
            />
          </Flex>
        );
      }

      setAIDescription({ name: nameWithAttributes, description });
    } else {
      setAIDescription({ name: undefined, description: undefined });
    }
  }, [
    canShowDescription,
    description,
    enableAiExplanationsInFrontend,
    isHovered,
    name,
    attributes,
    setAIDescription,
  ]);

  const driverNameContents = (
    <Box
      ref={mergedRef}
      flex={1}
      display={hasAttributes ? 'flex' : 'block'}
      maxWidth={isNumber(maxWidth) ? toPxString(maxWidth) : 'full'}
      overflow="hidden"
      alignItems="center"
      columnGap={1}
      data-testid="driver-name-cell"
    >
      <Text
        ref={textRef}
        overflow={allClipped || !hasAttributes ? 'hidden' : undefined}
        flexShrink={allClipped ? 1 : 0}
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        minWidth={4}
        textDecoration={canShowDescription ? 'underline dashed' : undefined}
        textUnderlineOffset="3px"
        textDecorationColor="gray.500"
        fontWeight={fontWeight}
        fontSize={fontSize}
        textIndent={toPxString(indents * INDENT_WIDTH)}
        as={as}
        data-testid="driver-name-text"
      >
        {name}
      </Text>
      {hasAttributes && (
        <BaseAttributeBadges
          attributes={attributes}
          availableWidth={availableWidth}
          onAllClippedUpdated={(value) => setAllClipped(value)}
          renderBadge={(props) => <LightDriverAttributeBadge {...props} />}
        />
      )}
    </Box>
  );

  const tooltipWrapper = (
    <Tooltip
      placement="top"
      isOpen={isHovered && showTooltip}
      openDelay={0}
      maxWidth={600}
      label={
        <Flex
          data-testid="driver-name-tooltip"
          flexDir="column"
          alignItems="start"
          textAlign="start"
          rowGap={1}
        >
          <Flex alignItems="start" fontWeight="semibold" columnGap={1}>
            <Text whiteSpace="nowrap" minWidth={4}>
              {name}
            </Text>
            <BaseAttributeBadges
              attributes={attributes}
              onAllClippedUpdated={(value) => setAllClipped(value)}
              renderBadge={(props) => <LightDriverAttributeBadge {...props} />}
            />
          </Flex>
          {canShowDescription && <Text whiteSpace="pre-wrap">{description}</Text>}
        </Flex>
      }
    >
      {driverNameContents}
    </Tooltip>
  );

  return tooltipWrapper;
};

export default React.memo(DriverName);
