import uniq from 'lodash/uniq';

import {
  BlockUpdateInput,
  DatasetMutationInput,
  DriverCreateInput,
  DriverIndentationInput,
  DriverType,
  DriverUpdateInput,
} from 'generated/graphql';
import {
  getAttrIdsByDimId,
  getAttrsByDimId,
  getMissingSubdrivers,
  getSubDriverBySubDriverId,
} from 'helpers/dimensionalDrivers';
import { attributeListToGqlAttrs, dimIdListToGqlDims } from 'helpers/dimensions';
import { transformFormulaForExpandedDimension } from 'helpers/driverFormulas';
import { mergeMutations } from 'helpers/mergeMutations';
import { bulkInsertSortIndexUpdates } from 'helpers/reorderList';
import { peekMutationStateChange } from 'helpers/sortIndex';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import {
  getDuplicateCreateInputFromExistingDriver,
  getSortIndexUpdateMutations,
} from 'reduxStore/actions/driverMutations';
import { getMutationThunkAction } from 'reduxStore/actions/submitDatasetMutation';
import { BlockId } from 'reduxStore/models/blocks';
import { Attribute, AttributeId, DimensionId } from 'reduxStore/models/dimensions';
import { DriverId } from 'reduxStore/models/drivers';
import { blockConfigSelector } from 'selectors/blocksSelector';
import { dimensionalPropertyEvaluatorSelector } from 'selectors/collectionSelector';
import { dimensionSelector } from 'selectors/dimensionsSelector';
import {
  basicDriverForLayerSelector,
  dimensionalDriversBySubDriverIdSelector,
  driversByIdForCurrentLayerSelector,
  driversByIdForLayerSelector,
  subDriversByDriverIdSelector,
} from 'selectors/driversSelector';
import { prevailingSelectedDriverIdsSelector } from 'selectors/prevailingCellSelectionSelector';
import { submodelTableGroupsSelector } from 'selectors/submodelTableGroupsSelector';

const mutationActions = {
  expandDriverSelectionByDimension: getMutationThunkAction<{
    dimensionId: DimensionId;
    blockId: BlockId;
  }>(({ dimensionId, blockId }, getState) => {
    const state = getState();
    const driversById = driversByIdForCurrentLayerSelector(state);
    const dimension = dimensionSelector(state, dimensionId);
    if (dimension == null) {
      return null;
    }
    const dimDriverBySubDriverId = dimensionalDriversBySubDriverIdSelector(state);
    const selectedDriverIds = prevailingSelectedDriverIdsSelector(state);
    const dimensionalPropertyEvaluator = dimensionalPropertyEvaluatorSelector(state);

    const driversToConvertToDim = selectedDriverIds.filter(
      (driverId) => dimDriverBySubDriverId[driverId] == null,
    );
    const driversToAddSubdriver = selectedDriverIds.filter(
      (driverId) => dimDriverBySubDriverId[driverId] != null,
    );

    const groups = submodelTableGroupsSelector(state);
    let indexUpdates: NullableRecord<string, number> = {};
    const updateIndexForGroup = (
      afterId: DriverId,
      addedItems: DriverCreateInput[],
      groupId?: string,
    ) => {
      const group = groups.find((g) => g.id === groupId || (g.id == null && groupId == null));
      const driverIdsInSameGroup: DriverId[] = group == null ? [] : group.driverIds;
      const orderedDriverSortIndices = driverIdsInSameGroup
        .map((driverId) => {
          const driver = driversById[driverId];
          if (driver == null) {
            return null;
          }
          return {
            id: driver.id,
            sortIndex: driver?.driverReferences?.find((ref) => ref.blockId === blockId)?.sortIndex,
          };
        })
        .filter(isNotNull);

      indexUpdates = bulkInsertSortIndexUpdates(
        orderedDriverSortIndices,
        addedItems,
        'after',
        afterId,
      );
    };
    const formulasToUpdateInfo: Array<{
      sourceDriverId: DriverId;
      attrs: Attribute[];
      create: DriverCreateInput;
    }> = [];

    const blockConfig = blockConfigSelector(state, blockId);
    const indents: DriverIndentationInput[] = [];
    const existingIndentDriverIds = new Set(
      blockConfig?.driverIndentations?.map((indent) => indent.driverId) ?? [],
    );
    const addIndentForDriver = (newDriverId: DriverId, sourceDriverId: DriverId) => {
      if (existingIndentDriverIds.has(newDriverId)) {
        return;
      }
      existingIndentDriverIds.add(newDriverId);
      const indentLevel = blockConfig?.driverIndentations?.find(
        (indentConfig) => indentConfig.driverId === sourceDriverId,
      )?.level;

      if (indentLevel != null) {
        indents.push({
          driverId: newDriverId,
          level: indentLevel,
        });
      }
    };

    const convertedDrivers: DriverCreateInput[] = driversToConvertToDim
      .flatMap((driverId) => {
        const driver = driversById[driverId];
        const expanded = getMissingSubdrivers(
          undefined,
          driverId,
          dimension,
          dimensionalPropertyEvaluator,
        );
        if (driver == null) {
          return null;
        }
        const groupId =
          driver.driverReferences?.find((ref) => ref.blockId === blockId)?.groupId ?? undefined;

        const dimDriver = {
          id: uuidv4(),
          name: driver.name,
          driverType: DriverType.Dimensional,
          format: driver.format,
          leverType: driver.leverType,
          dimensional: {
            dimensionIds: [dimension?.id],
            subDrivers: expanded.map((attrs, idx) => ({
              existingDriverId: idx === 0 ? driverId : uuidv4(),
              ...attributeListToGqlAttrs(Object.values(attrs)),
            })),
          },
          driverReferences: [{ blockId, groupId }],
        };
        const newSubDrivers: DriverCreateInput[] = dimDriver.dimensional.subDrivers
          .slice(1)
          .map((subDriver) =>
            getDuplicateCreateInputFromExistingDriver({
              name: driver.name,
              existingDriver: driver,
              newDriverId: subDriver.existingDriverId,
              blockId,
            }),
          );
        newSubDrivers.forEach((create, idx) => {
          formulasToUpdateInfo.push({
            sourceDriverId: driverId,
            attrs: Object.values(expanded[idx + 1]),
            create,
          });

          addIndentForDriver(create.id, driverId);
        });
        updateIndexForGroup(driver.id, newSubDrivers, groupId);
        return [...newSubDrivers, dimDriver];
      })
      .filter(isNotNull);

    const getSubdriverKey = (driverId: DriverId, newAttrs: AttributeId[]) =>
      `${driverId}:${newAttrs.sort().join(',')}`;
    const modifiedSubdrivers: string[] = [];
    const modifyDimDriver: Record<DriverId, DriverUpdateInput> = {};
    const newDrivers: DriverCreateInput[] = [];
    const subDriverTransformed: DriverId[] = [];
    driversToAddSubdriver.forEach((driverId) => {
      const driver = safeObjGet(driversById[driverId]);
      const dimDriver = safeObjGet(dimDriverBySubDriverId[driverId]);
      const expanded = getMissingSubdrivers(
        dimDriverBySubDriverId[driverId],
        driverId,
        dimension,
        dimensionalPropertyEvaluator,
      );

      if (driver == null || dimDriver == null) {
        return;
      }

      const groupId =
        driver.driverReferences?.find((ref) => ref.blockId === blockId)?.groupId ?? undefined;

      const subDriver = getSubDriverBySubDriverId(dimDriver, driverId);
      if (subDriver == null) {
        return;
      }

      const dimDriverUpdate = safeObjGet(modifyDimDriver[dimDriver.id]) ?? {
        id: dimDriver.id,
        dimensional: {
          ...dimIdListToGqlDims(uniq([...dimDriver.dimensions.map((d) => d.id), dimension.id])),
          updateSubDrivers: [],
        },
        newSubDrivers: [],
      };
      modifyDimDriver[dimDriver.id] = dimDriverUpdate;

      let shouldUpdateOriginal = driversToAddSubdriver.includes(subDriver.driverId);
      expanded.forEach((attrsByDimId) => {
        const allAttrs = Object.values(attrsByDimId);
        const modifiedKey = getSubdriverKey(
          dimDriver.id,
          allAttrs.map((a) => a.id),
        );
        if (modifiedSubdrivers.includes(modifiedKey)) {
          return;
        }
        modifiedSubdrivers.push(modifiedKey);

        if (shouldUpdateOriginal) {
          const { [dimensionId]: _, ...attributesOfOtherDimensions } = attrsByDimId;
          const existingSubDriverId = dimensionalPropertyEvaluator.getSubDriverIdForAttributeIds(
            dimDriver.id,
            Object.values(attributesOfOtherDimensions).map((attr) => attr.id),
          );
          const existingSubDriver =
            existingSubDriverId != null
              ? dimDriver.subdrivers.find((s) => s.driverId === existingSubDriverId)
              : null;

          if (existingSubDriver != null) {
            const newGqlAttrs = attributeListToGqlAttrs(allAttrs);
            dimDriverUpdate.dimensional?.updateSubDrivers?.push({
              newAttributeIds: newGqlAttrs.attributeIds,
              newBuiltInAttributes: newGqlAttrs.builtInAttributes,
              ...attributeListToGqlAttrs(existingSubDriver.attributes),
            });
            subDriverTransformed.push(existingSubDriver.driverId);
            shouldUpdateOriginal = false;

            return;
          }
        }

        const newDriverId = uuidv4();
        dimDriverUpdate.newSubDrivers?.push({
          existingDriverId: newDriverId,
          ...attributeListToGqlAttrs(allAttrs),
        });

        const newDriver = getDuplicateCreateInputFromExistingDriver({
          name: driver.name,
          existingDriver: driver,
          newDriverId,
          blockId,
        });
        newDrivers.push(newDriver);
        formulasToUpdateInfo.push({
          create: newDriver,
          sourceDriverId: driverId,
          attrs: allAttrs,
        });
        updateIndexForGroup(driver.id, [newDriver], groupId);
        addIndentForDriver(newDriverId, driverId);
      });
    });

    const driverCreateInputs: DriverCreateInput[] = [...newDrivers, ...convertedDrivers];

    const blockUpdate: BlockUpdateInput[] | null =
      indents.length > 0
        ? [
            {
              id: blockId,
              blockConfig: {
                ...blockConfig,
                driverIndentations: blockConfig?.driverIndentations?.concat(indents),
              },
            },
          ]
        : null;

    const mutations: DatasetMutationInput = {
      newDrivers: driverCreateInputs,
      updateDrivers: [
        ...Object.values(modifyDimDriver),
        ...getSortIndexUpdateMutations({
          state,
          blockId,
          sortIndexUpdates: indexUpdates,
          driverCreateInputs,
        }),
      ],
      updateBlocks: blockUpdate,
    };

    const peek = peekMutationStateChange(state, mutations);
    const peekSubdriverById = subDriversByDriverIdSelector(peek);
    const peekDriversById = driversByIdForLayerSelector(peek);
    const formulaUpdates: DriverUpdateInput[] = [];
    // Formulas need to be transformed using the new driver state which includes the newly created drivers
    // There are some bulk edit cases where the newly created driver will be referenced in a formula
    formulasToUpdateInfo.forEach(({ create, sourceDriverId, attrs }) => {
      const sourceDriver = basicDriverForLayerSelector(peek, { id: sourceDriverId });
      const sourceAttrIds = getAttrIdsByDimId(peekSubdriverById[sourceDriverId]?.attributes);
      const destAttrs = getAttrsByDimId(attrs);
      if (sourceDriver != null && create.basic != null) {
        const newForecastFormula = transformFormulaForExpandedDimension({
          state: peek,
          isForecast: true,
          sourceDriverId,
          sourceAttrIds,
          destDriverId: create.id,
          destAttrs,
          driversById: peekDriversById,
          expandedDimensionId: dimension.id,
        });

        if (newForecastFormula != null && newForecastFormula !== create.basic.forecast.formula) {
          formulaUpdates.push({
            id: create.id,
            forecast: {
              formula: newForecastFormula,
            },
          });
        }

        if (sourceDriver.actuals.formula != null && create.basic.actuals.formula != null) {
          const newActualsFormula = transformFormulaForExpandedDimension({
            state: peek,
            isForecast: false,
            sourceDriverId,
            sourceAttrIds,
            destDriverId: create.id,
            destAttrs,
            driversById: peekDriversById,
            expandedDimensionId: dimension.id,
          });
          if (newActualsFormula != null && newActualsFormula !== create.basic.actuals.formula) {
            formulaUpdates.push({
              id: create.id,
              actuals: {
                formula: newActualsFormula,
              },
            });
          }
        }
      }
    });
    return mergeMutations(mutations, { updateDrivers: formulaUpdates });
  }),
};
export const { expandDriverSelectionByDimension } = mutationActions;
