import { Text } from '@chakra-ui/react';
import pluralize from 'pluralize';

import Badge from 'components/Badge/Badge';
import { isBadgeTheme } from 'config/badge';
import { Dimension } from 'reduxStore/models/dimensions';
import { ANY_ATTR, NO_ATTR } from 'types/formula';
import LayersIcon from 'vectors/Layers';

interface Props {
  dimension: Dimension;
  type?: typeof ANY_ATTR | typeof NO_ATTR;
}

const FALLBACK_COLOR = 'gray';
const DimensionBadge: React.FC<Props> = ({ dimension, type = ANY_ATTR }) => {
  const color = dimension.color;
  const theme = isBadgeTheme(color) ? color : FALLBACK_COLOR;

  const content = (
    <Text as="span" display="inline-flex" alignItems="center" noOfLines={1} width="full">
      <Text noOfLines={1} fontStyle="italic" px={1}>
        {type === ANY_ATTR && (
          <>
            <LayersIcon color="gray.500" />
            &nbsp;All {pluralize(dimension.name)}
          </>
        )}
        {type === NO_ATTR && `None`}
      </Text>
    </Text>
  );

  return <Badge size="xs" text={content} theme={theme} />;
};

export default DimensionBadge;
