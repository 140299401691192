import {
  BusinessObjectFieldCellRef,
  BusinessObjectFieldMonthColumnKey,
  BusinessObjectFieldSpecColumnKey,
  BusinessObjectPropertyFieldCellRef,
  BusinessObjectTimeSeriesCellRef,
  CellColumnKey,
  CellRef,
  CellRowKey,
  CellType,
  DriverCellNameRef,
  DriverCellRef,
  DriverDataCellRef,
  DriverRowKey,
  EventGroupCellRef,
  ExtDriverCellRef,
  ImpactCellRef,
  MonthColumnKey,
  NameColumnKey,
  SqlCellRef,
  StickyColumnKey,
} from 'config/cells';
import { INITIAL_VALUE_COLUMN_TYPE, OPTIONS_COLUMN_TYPE } from 'config/modelView';
import { ComparisonColumn, RollupType } from 'generated/graphql';
import { ComparisonLayout } from 'helpers/blockComparisons';
import { isEqualIgnoringNullish } from 'helpers/object';
import { TimeSeriesComparisonSubColumn } from 'helpers/rollups';
import { OBJECT_TABLE_BLOCK_NAME_COLUMN_KEY } from 'reduxStore/models/blocks';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import { Coloring } from 'reduxStore/models/common';
import { DriverId } from 'reduxStore/models/drivers';
import { MonthKey } from 'types/datetime';

export function isImpactCellRef(cellRef?: CellRef | null): cellRef is ImpactCellRef {
  return cellRef?.type === CellType.Impact;
}

export function isEventGroupCellRef(cellRef?: CellRef | null): cellRef is EventGroupCellRef {
  return cellRef?.type === CellType.EventGroup;
}

export function isDriverCellRef(cellRef?: CellRef | null): cellRef is DriverCellRef {
  return cellRef?.type === CellType.Driver;
}

export function isExtDriverCellRef(cellRef?: CellRef | null): cellRef is ExtDriverCellRef {
  return cellRef?.type === CellType.ExtDriver;
}

export function isDriverDataCellRef(cellRef?: CellRef | null): cellRef is DriverDataCellRef {
  return isDriverCellRef(cellRef) && 'monthKey' in cellRef.columnKey;
}

export function cellRefSupportsCellPalette(cellRef?: CellRef | null): boolean {
  return isDriverDataCellRef(cellRef) || isBusinessObjectFieldCellRef(cellRef);
}

export function isDriverNameCellRef(cellRef?: CellRef | null): cellRef is DriverCellNameRef {
  return isDriverCellRef(cellRef) && isObjectFieldNameColumnKey(cellRef.columnKey);
}

export function isBusinessObjectFieldCellRef(
  cellRef?: CellRef | null,
): cellRef is BusinessObjectFieldCellRef | BusinessObjectTimeSeriesCellRef {
  return cellRef?.type === CellType.ObjectField || cellRef?.type === CellType.ObjectFieldTimeSeries;
}

export function isBusinessObjectPropertyFieldCellRef(
  cellRef?: CellRef | null,
): cellRef is BusinessObjectPropertyFieldCellRef {
  return isBusinessObjectFieldCellRef(cellRef) && 'objectFieldSpecId' in cellRef.columnKey;
}

export function isBusinessObjectNameFieldCellRef(
  cellRef?: CellRef | null,
): cellRef is BusinessObjectPropertyFieldCellRef {
  return isBusinessObjectFieldCellRef(cellRef) && isObjectFieldNameColumnKey(cellRef.columnKey);
}

export function isBusinessObjectFieldTimeSeriesCellRef(
  cellRef?: CellRef | null,
): cellRef is BusinessObjectTimeSeriesCellRef {
  return cellRef?.type === CellType.ObjectFieldTimeSeries;
}

type BusinessObjectFieldCellRefWithPopover = BusinessObjectFieldCellRef & {
  columnKey: BusinessObjectFieldMonthColumnKey;
};
type BusinessObjectTimeSeriesCellRefWithPopover = BusinessObjectTimeSeriesCellRef & {
  columnKey: MonthColumnKey;
};

export function isObjectFieldCellRefWithPopover(
  cellRef?: CellRef | null,
): cellRef is BusinessObjectFieldCellRefWithPopover | BusinessObjectTimeSeriesCellRefWithPopover {
  return isBusinessObjectFieldCellRef(cellRef) && isMonthColumnKey(cellRef?.columnKey);
}

export function getFieldSpecIdFromCellRef(
  cellRef:
    | BusinessObjectFieldCellRefWithPopover
    | BusinessObjectTimeSeriesCellRefWithPopover
    | BusinessObjectTimeSeriesCellRef
    | BusinessObjectPropertyFieldCellRef,
): BusinessObjectFieldSpecId | undefined {
  return cellRef.type === CellType.ObjectField
    ? cellRef?.columnKey.objectFieldSpecId
    : cellRef?.rowKey.fieldSpecId;
}

export function isDriverRowKey(rowKey: CellRowKey): rowKey is DriverCellRef['rowKey'] {
  return 'driverId' in rowKey;
}

export function isObjectRowKey(rowKey: CellRowKey): rowKey is BusinessObjectFieldCellRef['rowKey'] {
  return 'objectId' in rowKey;
}

export function isSqlCellRef(cellRef: CellRef): cellRef is SqlCellRef {
  return 'sqlColumn' in cellRef.columnKey;
}

export function isMonthColumnKey(columnKey: CellColumnKey): columnKey is MonthColumnKey {
  return 'monthKey' in columnKey;
}

export function isObjectFieldColumnKey(
  columnKey: CellColumnKey,
): columnKey is BusinessObjectFieldSpecColumnKey {
  return 'objectFieldSpecId' in columnKey;
}

export function isFormulaColumn(
  columnKey: CellColumnKey,
): columnKey is { columnType: 'formula' | 'actualsFormula' } {
  return (
    isStickyColumnKey(columnKey) &&
    (columnKey.columnType === 'formula' || columnKey.columnType === 'actualsFormula')
  );
}

export function isObjectFieldNameColumnKey(columnKey: CellColumnKey): columnKey is NameColumnKey {
  return 'columnType' in columnKey && columnKey.columnType === OBJECT_TABLE_BLOCK_NAME_COLUMN_KEY;
}

export function isObjectFieldNameOrOptionsColumnKey(
  columnKey: CellColumnKey,
): columnKey is NameColumnKey {
  return (
    'columnType' in columnKey &&
    (columnKey.columnType === OBJECT_TABLE_BLOCK_NAME_COLUMN_KEY ||
      columnKey.columnType === OPTIONS_COLUMN_TYPE)
  );
}

export function isBusinessObjectFieldSpecColumnKey(
  columnKey: CellColumnKey,
): columnKey is BusinessObjectFieldSpecColumnKey {
  return 'objectFieldSpecId' in columnKey && !isMonthColumnKey(columnKey);
}

export function isBusinessObjectTableColumnKey(
  columnKey: CellColumnKey,
): columnKey is BusinessObjectFieldCellRef['columnKey'] {
  return (
    isObjectFieldNameColumnKey(columnKey) ||
    isBusinessObjectFieldSpecColumnKey(columnKey) ||
    isMonthColumnKey(columnKey) ||
    ('columnType' in columnKey && columnKey.columnType === INITIAL_VALUE_COLUMN_TYPE)
  );
}

export function isStickyColumnKey(columnKey: CellColumnKey): columnKey is StickyColumnKey {
  return 'columnType' in columnKey;
}

export function stringifyMonthColumnKey(monthColumnKey: MonthColumnKey): string {
  return `${monthColumnKey.monthKey}-${monthColumnKey.rollupType}-${monthColumnKey.layerId}-${monthColumnKey.comparisonColumn}`;
}

export function stringifyCellColumnKey(monthColumnKey: MonthColumnKey & { label: string }): string {
  return `${monthColumnKey.monthKey}=${monthColumnKey.label}-${monthColumnKey.rollupType}-${monthColumnKey.layerId}-${monthColumnKey.comparisonColumn}`;
}

export function getMonthColumnKey(
  monthKey: MonthKey,
  rollupType?: RollupType,
  subColumn?: TimeSeriesComparisonSubColumn,
): MonthColumnKey {
  return {
    monthKey,
    rollupType: rollupType ?? RollupType.Month,
    layerId: subColumn?.layerId,
    comparisonColumn: subColumn?.column,
  };
}
export function getCellColumnKey(
  monthKey: MonthKey,
  label: string,
  rollupType?: RollupType,
  subColumn?: TimeSeriesComparisonSubColumn,
): MonthColumnKey & { label: string } {
  return {
    monthKey,
    rollupType: rollupType ?? RollupType.Month,
    layerId: subColumn?.layerId,
    comparisonColumn: subColumn?.column,
    label,
  };
}

export function hashCellRef(cellRef: CellRef): string {
  /**
   * We currently ignore the type of the CellRef, since the row/column should be enough to disambiguate.
   * If we ever have tables with mixed cell types, this assumption may need to be revisited.
   */
  return getCellRefHash(hashRowCellKey(cellRef.rowKey), hashColumnCellKey(cellRef.columnKey));
}

export function getCellRefHash(rowHash: string, colHash: string) {
  return `${rowHash},${colHash}`;
}

type AllKeys<T> = T extends any ? keyof T : never;
type RowKeys = AllKeys<CellRef['rowKey']>;
type ColumnKeys = AllKeys<CellRef['columnKey']>;

// N.B. this should represent the keys that are used in row and column keys
// the order is arbitrary; we just need to ensure that the stringify is consistent
const replacer: Array<RowKeys | ColumnKeys> = [
  'columnType',
  'comparisonColumn',
  'driverId',
  'type',
  'eventGroupId',
  'objectFieldId',
  'extDriverId',
  'fieldSpecId',
  'groupId',
  'groupKey',
  'layerId',
  'monthKey',
  'rollupType',
  'objectFieldSpecId',
  'objectId',
  'rowIndex',
  'specId',
  'sqlColumn',
  'comparisonType',
  'columnLayerId',
  'comparisonTimePeriod',
];

function hashCellKey(cellKey: CellRowKey | CellColumnKey): string {
  return cellKey == null ? '' : JSON.stringify(cellKey, replacer);
}

export function hashRowCellKey(cellKey: CellRowKey): string {
  return hashCellKey(cellKey);
}

export function hashColumnCellKey(cellKey: CellColumnKey): string {
  return hashCellKey(cellKey);
}

export function getColorFromCellRef(cellRef: CellRef, cellColor: Coloring | undefined) {
  if (cellColor == null) {
    return undefined;
  }

  if (isDriverDataCellRef(cellRef)) {
    const monthKey = cellRef.columnKey?.monthKey;
    if (monthKey != null) {
      if (cellColor.cells && cellColor.cells[monthKey] != null) {
        return `${cellColor.cells[monthKey].value}`;
      }

      return undefined;
    }
  }

  return cellColor.row;
}

export type BlockKeyHashes = {
  hashToIdx: NullableRecord<string, number>;
  orderedRowKeyHashes: string[];
  orderedColumnKeyHashes: string[];
};

export function mapCellKeysToIndex(cellKeys: {
  orderedRowKeys: CellRowKey[];
  orderedColumnKeys: CellColumnKey[];
}): BlockKeyHashes {
  const hashToIdx: NullableRecord<string, number> = {};
  const orderedRowKeyHashes = cellKeys.orderedRowKeys.map(hashRowCellKey);
  const orderedColumnKeyHashes = cellKeys.orderedColumnKeys.map(hashColumnCellKey);
  orderedRowKeyHashes.forEach((hash, idx) => {
    hashToIdx[hash] = idx;
  });
  orderedColumnKeyHashes.forEach((hash, idx) => {
    hashToIdx[hash] = idx;
  });

  return {
    hashToIdx,
    orderedRowKeyHashes,
    orderedColumnKeyHashes,
  };
}

export function isRowKeyEqual(rk1: CellRowKey, rk2: CellRowKey) {
  return isEqualIgnoringNullish(rk1, rk2);
}

export function isColumnKeyEqual(ck1: CellColumnKey, ck2: CellColumnKey) {
  return isEqualIgnoringNullish(ck1, ck2);
}

export function isCellRefEqual(cr1: CellRef, cr2: CellRef) {
  return (
    cr1.type === cr2.type &&
    isRowKeyEqual(cr1.rowKey, cr2.rowKey) &&
    isColumnKeyEqual(cr1.columnKey, cr2.columnKey)
  );
}

export function isEnhancedComparisonLayoutDriverHeaderRow(
  rowKey: DriverRowKey,
  comparisonLayout: ComparisonLayout,
): boolean {
  const isRowLayoutHeaderRow = comparisonLayout === 'row' && rowKey.layerId == null;
  const isColumnLayoutHeaderRow =
    comparisonLayout === 'column-default' && rowKey.comparisonType === ComparisonColumn.RowVersion;

  return isRowLayoutHeaderRow || isColumnLayoutHeaderRow;
}

export function getCellRefDriverId(cellRef: CellRef | null): DriverId | undefined {
  if (cellRef == null) {
    return undefined;
  }

  if (cellRef.type === CellType.Driver) {
    return cellRef.rowKey.subDriverId ?? cellRef.rowKey.driverId;
  }

  if (cellRef.type === CellType.ImpactDriver) {
    return cellRef.rowKey.driverId;
  }

  return undefined;
}

export function getCellRefMonthKey(cellRef: CellRef): DriverId | undefined {
  return isMonthColumnKey(cellRef.columnKey) ? cellRef.columnKey.monthKey : undefined;
}
