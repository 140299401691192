const baseColors = {
  white: '#FFFFFF',
  gray: {
    50: '#F9FAFB',
    100: '#F7F8FA',
    200: '#EFF1F5',
    300: '#DEE3EB',
    400: '#B9C0CD',
    500: '#949DAD',
    600: '#464A53',
  },
  orange: {
    500: '#EFAE6E',
  },
  slate: {
    100: '#1E2122',
  },
  blue: {
    100: '#F2F6FC',
    200: '#D6E4F8',
    300: '#B7D1F5',
    400: '#8CB0DE',
    500: '#4F7DBF',
    600: '#335177',
    runway: '#AAC4E5',
  },
  selection: {
    100: '#F5FBFF',
    200: '#DDF1FE',
    300: '#C2E6FE',
    400: '#90D2FE',
    500: '#18A0FB',
    600: '#097FCE',
    os: 'SelectedItem',
  },
  red: {
    100: '#FFF7F5',
    200: '#FDDAD3',
    300: '#FCBFB5',
    400: '#FBA393',
    500: '#F88672',
    600: '#CB5C4D',
  },
  purple: {
    100: '#F9F1F9',
    150: '#F6EAF5',
    200: '#F0DBEF',
    300: '#E0B8DF',
    400: '#D194CF',
    500: '#C270BF',
    600: '#9D4D9A',
  },
  yellow: {
    100: '#FFF7F0',
    200: '#FEEAD7',
    300: '#FDD8B4',
    400: '#FCC592',
    500: '#FBB26F',
    600: '#DD893C',
  },
  green: {
    400: '#A1DBC6',
    500: '#89C588',
  },
  failure: '#F75E4A',
  success: '#2ECA2B',
  runway: {
    black: '#192227',
    white: '#FDFCFC',
    blue: '#2A85B9',
    yellow: '#F9A600',
    red: '#F0624F',
    orange: '#F9A600',
  },
  highlight: {
    green1: '#DEFADF',
    green2: '#CBF2CF',
    green3: '#56C179',
  },
  watermelon: {
    100: '#FDE5E9',
    200: '#FBC3C7',
    300: '#F9A7A1',
    400: '#F78375',
    500: '#F0624F',
    600: '#D9503E',
  },
  seablue: {
    100: '#D6E8F8',
    200: '#B8D7F0',
    300: '#9AC2E8',
    400: '#63A1D2',
    500: '#2A85B9',
    600: '#2175A5',
  },
  sunburst: {
    100: '#FEF4DF',
    200: '#FDEEBF',
    300: '#FCDE80',
    400: '#FABF42',
    500: '#F9A600',
    600: '#E49300',
  },
  lilac: {
    100: '#F0E7FD',
    200: '#E7D9F9',
    300: '#D8C5F2',
    400: '#C5A6E9',
    500: '#B08CE1',
    600: '#9B75CC',
  },
  azuresky: {
    100: '#E9F9FF',
    200: '#CBF0FE',
    300: '#AEE4FD',
    400: '#7DD3FC',
    500: '#4FC3F7',
    600: '#3DA9E2',
  },
  fern: {
    100: '#D3EDD8',
    200: '#BCE2C0',
    300: '#98D8A6',
    400: '#91D49C',
    500: '#53B063',
    600: '#479D53',
  },
  daffodil: {
    100: '#F6E4CB',
    200: '#F1D9A1',
    300: '#E9CD6E',
    400: '#E0C33C',
    500: '#E5BA00',
    600: '#DAA21F',
  },
  tangerine: {
    100: '#FBDACD',
    200: '#F6D1B4',
    300: '#F0B58C',
    400: '#F2A779',
    500: '#E88D4A',
    600: '#D86C1A',
  },
  orchid: {
    100: '#F1DCF5',
    200: '#E6C7ED',
    300: '#D9AEE4',
    400: '#CA8BD8',
    500: '#BA68C8',
    600: '#A450B2',
  },
  aquamarine: {
    100: '#D9F7EF',
    200: '#B4F3E3',
    300: '#8AEBD6',
    400: '#70E0CB',
    500: '#00BFA5',
    600: '#009688',
  },
  rosemist: {
    100: '#FDE5EC',
    200: '#FACAD5',
    300: '#F6B0BE',
    400: '#F1909A',
    500: '#E57373',
    600: '#CF5651',
  },
  amethyst: {
    100: '#E9D9FA',
    200: '#D2BFF2',
    300: '#B8A4E7',
    400: '#9B81D8',
    500: '#7E57C2',
    600: '#6C4AB8',
  },
};

const colors = {
  ...baseColors,
  background: baseColors.gray[100],
  actuals: baseColors.gray[600],
  forecast: baseColors.blue[500],
  eventImpact: baseColors.blue[300],
  milestone: baseColors.purple[500],
};

export default colors;
