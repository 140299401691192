import { createSelector } from '@reduxjs/toolkit';
import { memoize } from 'lodash';
import { createCachedSelector } from 're-reselect';

import { DataArbiter } from 'components/AgGridComponents/helpers/gridDatasource/DataArbiter';
import { ImpactType } from 'generated/graphql';
import { getMonthsBetweenMonths } from 'helpers/dates';
import { augmentValueWithLiveEditImpact } from 'helpers/events';
import { getFormulaCacheKey } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaCache';
import {
  formulaEntitiesByIdForLayerSelector,
  getActualTimeSeriesData,
  getImpact,
  shouldUseActual,
} from 'helpers/formulaEvaluation/ForecastCalculator/FormulaEvaluatorHelpers';
import { FormulaEntity, FormulaEntityTypedId } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import { layerParamMemo } from 'helpers/layerSelectorFactory';
import { Event, EventId } from 'reduxStore/models/events';
import { LayerId } from 'reduxStore/models/layers';
import { ErrorTimeSeries, ValueTimeSeries } from 'reduxStore/models/timeSeries';
import { ObjectSpecEvaluation, Value } from 'reduxStore/models/value';
import { EvaluateBlockResult } from 'reduxStore/reducers/calculationsSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { fieldSelector } from 'selectors/constSelectors';
import { cursorMonthKeySelector } from 'selectors/cursorSelector';
import { impactingEventsByFormulaEntityIdForLayerSelector } from 'selectors/eventsAndGroupsSelector';
import { shouldDoSynchronousCalculationsSelector } from 'selectors/inspectorSelector';
import { lastActualsMonthKeyForLayerSelector } from 'selectors/lastActualsSelector';
import { getGivenOrCurrentLayerId } from 'selectors/layerSelector';
import { liveEditingEventImpactSelector } from 'selectors/liveEditSelector';
import { ValueOrCalculationError, isCalculationError } from 'types/dataset';
import { MonthKey } from 'types/datetime';
import { ParametricSelector } from 'types/redux';

type EntitySelectorProps = {
  id: FormulaEntityTypedId['id'];
  layerId?: LayerId;
};

type EntitySelectorPropsForMonth = EntitySelectorProps & {
  monthKey: MonthKey;
};

type EntitySelectorPropsByMonth = EntitySelectorProps & {
  monthKeys: MonthKey[];
};

const calculationsStateVersionSelector = (state: RootState) =>
  state.calculations.calculationsStateVersion;

const impactCalculationsSelector = (state: RootState) => state.calculations.impactCalculations;

export const evaluateBlockResultSelector = (
  state: RootState,
  blockId: string,
): EvaluateBlockResult | undefined => {
  return state.calculations.groupsByBlockId[blockId];
};

function extractHardcodedValue({
  id,
  monthKey,
  lastActualsMonthKey,
  formulaEntitiesById,
  eventsByEntityId,
}: {
  id: EntitySelectorProps['id'];
  monthKey: MonthKey;
  lastActualsMonthKey: string;
  formulaEntitiesById: NullableRecord<string, FormulaEntity>;
  eventsByEntityId: NullableRecord<string, Event[]>;
}) {
  const entity = formulaEntitiesById[id];
  if (entity == null) {
    return undefined;
  }
  if (shouldUseActual(lastActualsMonthKey, monthKey)) {
    return getActualTimeSeriesData(id, monthKey, formulaEntitiesById);
  }
  const impact = getImpact(id, monthKey, formulaEntitiesById, eventsByEntityId);
  if (impact?.type === ImpactType.Set && impact.impact.type === entity.valueType) {
    return impact.impact;
  }
  return undefined;
}
const hardcodedValueForLayerSelector: ParametricSelector<
  EntitySelectorPropsForMonth,
  Value | undefined
> = createSelector(
  (state: RootState, params: EntitySelectorPropsForMonth) =>
    formulaEntitiesByIdForLayerSelector(
      state,
      layerParamMemo(getGivenOrCurrentLayerId(state, params)),
    ),
  (state: RootState, params: EntitySelectorPropsForMonth) =>
    impactingEventsByFormulaEntityIdForLayerSelector(
      state,
      layerParamMemo(getGivenOrCurrentLayerId(state, params)),
    ),
  (state: RootState, params: EntitySelectorPropsForMonth) =>
    lastActualsMonthKeyForLayerSelector(
      state,
      layerParamMemo(getGivenOrCurrentLayerId(state, params)),
    ),
  fieldSelector<EntitySelectorPropsForMonth, 'id'>('id'),
  fieldSelector<EntitySelectorPropsForMonth, 'monthKey'>('monthKey'),
  function hardcodedValueForLayerSelector(
    formulaEntitiesById,
    eventsByEntityId,
    lastActualsMonthKey,
    id,
    monthKey,
  ) {
    return extractHardcodedValue({
      id,
      monthKey,
      lastActualsMonthKey,
      formulaEntitiesById,
      eventsByEntityId,
    });
  },
);

const getCacheKeyForEntityAndMonthKeysSelector = memoize(
  ({ id, monthKeys }: EntitySelectorPropsByMonth) => `${id}.${[...monthKeys].sort().join(',')}`,
);

const hardcodedValuesForLayerSelector: ParametricSelector<
  EntitySelectorPropsByMonth,
  Record<MonthKey, Value | undefined>
> = createCachedSelector(
  (state: RootState, params: EntitySelectorProps) =>
    formulaEntitiesByIdForLayerSelector(
      state,
      layerParamMemo(getGivenOrCurrentLayerId(state, params)),
    ),
  (state: RootState, params: EntitySelectorProps) =>
    impactingEventsByFormulaEntityIdForLayerSelector(
      state,
      layerParamMemo(getGivenOrCurrentLayerId(state, params)),
    ),
  (state: RootState, params: EntitySelectorProps) =>
    lastActualsMonthKeyForLayerSelector(
      state,
      layerParamMemo(getGivenOrCurrentLayerId(state, params)),
    ),
  fieldSelector<EntitySelectorPropsByMonth, 'id'>('id'),
  fieldSelector<EntitySelectorPropsByMonth, 'monthKeys'>('monthKeys'),
  function hardcodedValuesForLayerSelector(
    formulaEntitiesById,
    eventsByEntityId,
    lastActualsMonthKey,
    id,
    monthKeys,
  ) {
    return monthKeys.reduce((agg: Record<MonthKey, Value | undefined>, monthKey) => {
      agg[monthKey] = extractHardcodedValue({
        id,
        monthKey,
        lastActualsMonthKey,
        formulaEntitiesById,
        eventsByEntityId,
      });

      return agg;
    }, {});
  },
)((_state, props) => getCacheKeyForEntityAndMonthKeysSelector(props));

// TODO: Should we change this to return a CalculationValueOrError or have a separate selector?
export const entityMonthKeyValueForLayerSelector: ParametricSelector<
  EntitySelectorPropsForMonth,
  ValueOrCalculationError | undefined
> = createSelector(
  shouldDoSynchronousCalculationsSelector,
  calculationsStateVersionSelector,
  hardcodedValueForLayerSelector,
  fieldSelector<EntitySelectorPropsForMonth, 'id'>('id'),
  fieldSelector<EntitySelectorPropsForMonth, 'monthKey'>('monthKey'),
  getGivenOrCurrentLayerId,
  // eslint-disable-next-line max-params
  function entityMonthKeyValueForLayerSelector(
    shouldDoSynchronousCalculations,
    // This one is so that we rerun this selector when the calculations change
    _calculationsStateVersion,
    hardcodedValue,
    id,
    monthKey,
    layerId,
  ) {
    if (shouldDoSynchronousCalculations) {
      return undefined;
    }
    if (hardcodedValue != null) {
      return hardcodedValue;
    }

    return DataArbiter.get().getCachedValue({ id, monthKey, layerId });
  },
);

const entityObjectSpecEvaluationsForLayerSelector: ParametricSelector<
  EntitySelectorPropsForMonth,
  ObjectSpecEvaluation[] | undefined
> = createSelector(
  shouldDoSynchronousCalculationsSelector,
  calculationsStateVersionSelector,
  hardcodedValueForLayerSelector,
  fieldSelector<EntitySelectorPropsForMonth, 'id'>('id'),
  fieldSelector<EntitySelectorPropsForMonth, 'monthKey'>('monthKey'),
  getGivenOrCurrentLayerId,
  // eslint-disable-next-line max-params
  function entityObjectSpecEvaluationsForLayerSelector(
    shouldDoSynchronousCalculations,
    // This one is so that we rerun this selector when the calculations change
    _calculationsStateVersion,
    hardcodedValue,
    id,
    monthKey,
    layerId,
  ) {
    if (shouldDoSynchronousCalculations) {
      return undefined;
    }

    return DataArbiter.get().getCachedObjectSpecEvaluations({ id, monthKey, layerId });
  },
);

interface ImpactValueSelectorProps {
  id: FormulaEntityTypedId['id'];
  monthKey: MonthKey;
  ignoreEventIds: EventId[];
}
const impactMonthKeyValueSelector: ParametricSelector<ImpactValueSelectorProps, Value | undefined> =
  createSelector(
    shouldDoSynchronousCalculationsSelector,
    impactCalculationsSelector,
    fieldSelector<ImpactValueSelectorProps, 'id'>('id'),
    fieldSelector<ImpactValueSelectorProps, 'monthKey'>('monthKey'),
    fieldSelector<ImpactValueSelectorProps, 'ignoreEventIds'>('ignoreEventIds'),
    // eslint-disable-next-line max-params
    function impactMonthKeyValueSelector(
      shouldDoSynchronousCalculations,
      impactCalculations,
      id,
      monthKey,
      ignoreEventIds,
    ) {
      if (shouldDoSynchronousCalculations) {
        return undefined;
      }
      const cacheKey = getFormulaCacheKey(id, monthKey, ignoreEventIds);
      return impactCalculations.values[cacheKey];
    },
  );

interface EntityTimeSeriesProps {
  id: FormulaEntityTypedId['id'];
  start: MonthKey;
  end: MonthKey;
  layerId: LayerId;
}

type ValueOrErrorTimeSeries = Record<MonthKey, ValueOrCalculationError>;

// This isn't cached because we may have many different start/end combinations.
// Instead, the entityMonthKeyValueSelector is cached per month key and this
// simply defers to that selector.
function getEntityTimeSeries(state: RootState, props: EntityTimeSeriesProps) {
  const { id, start, end, layerId } = props;
  const monthKeys = getMonthsBetweenMonths(start, end);

  const ts: ValueOrErrorTimeSeries = {};
  monthKeys.forEach((mk) => {
    const value = entityMonthKeyValueForLayerSelector(state, { id, monthKey: mk, layerId });
    if (value != null) {
      ts[mk] = value;
    }
  });

  return ts;
}

// NOTE: This is separate from entityTimeSeriesSelector so that we can
// selectively choose to check for errors and display them if they exist. The
// fallback behavior is generally that an error value gets treated the same as
// an undefined value. It is probably best to push error values onto the normal
// time series but this ends up fanning out to a lot of places.
// This is only intended to work for backend calcaultions.
export const entityErrorTimeSeriesSelector: ParametricSelector<
  EntityTimeSeriesProps,
  ErrorTimeSeries
> = createSelector(getEntityTimeSeries, (ts) => {
  const errTs: ErrorTimeSeries = {};
  Object.keys(ts).forEach((mk) => {
    const value = ts[mk];
    if (isCalculationError(value)) {
      errTs[mk] = value;
    }
  });
  return errTs;
});

export const entityTimeSeriesSelector: ParametricSelector<EntityTimeSeriesProps, ValueTimeSeries> =
  createSelector(
    shouldDoSynchronousCalculationsSelector,
    cursorMonthKeySelector,
    liveEditingEventImpactSelector,
    getEntityTimeSeries,
    (shouldDoSynchronousCalculations, cursorMonthKey, impact, ts) => {
      const tsWithoutErrs: ValueTimeSeries = Object.entries(ts).reduce((agg, [mk, value]) => {
        if (!isCalculationError(value)) {
          agg[mk] = value;
        }
        return agg;
      }, {} as ValueTimeSeries);

      if (shouldDoSynchronousCalculations || cursorMonthKey == null || ts[cursorMonthKey] == null) {
        return tsWithoutErrs;
      }

      /**
       * In the case that we don't do synchronous calculations on the main thread and we are live-editing using the chart in the inspector,
       * we will need to add the impact of the live-edited event at the cursor month key to the known calculated value so the graph shows an update while the cursor is being dragged.
       */

      const valueAtCursor = tsWithoutErrs[cursorMonthKey];
      const newValue: Value = augmentValueWithLiveEditImpact(valueAtCursor, impact);
      return {
        ...tsWithoutErrs,
        [cursorMonthKey]: newValue,
      };
    },
  );

export const entityMonthlyObjectSpecEvaluationsSelector: ParametricSelector<
  EntityTimeSeriesProps,
  Map<MonthKey, ObjectSpecEvaluation[]>
> = (state: RootState, props: EntityTimeSeriesProps) => {
  const { id, start, end, layerId } = props;
  const monthKeys = getMonthsBetweenMonths(start, end);
  const map = new Map<MonthKey, ObjectSpecEvaluation[]>();

  monthKeys.forEach((mk) => {
    const evaluations = entityObjectSpecEvaluationsForLayerSelector(state, {
      id,
      monthKey: mk,
      layerId,
    });
    if (evaluations != null) {
      map.set(mk, evaluations);
    }
  });

  return map;
};

interface ImpactTimeSeriesProps {
  id: FormulaEntityTypedId['id'];
  start: MonthKey;
  end: MonthKey;
  ignoreEventIds: EventId[];
}

export const impactTimeSeriesSelector: ParametricSelector<
  ImpactTimeSeriesProps,
  ValueTimeSeries
> = (state: RootState, props: ImpactTimeSeriesProps) => {
  const { id, start, end, ignoreEventIds } = props;
  const monthKeys = getMonthsBetweenMonths(start, end);

  const ts: ValueTimeSeries = {};
  monthKeys.forEach((mk) => {
    const value = impactMonthKeyValueSelector(state, { id, monthKey: mk, ignoreEventIds });
    if (value != null) {
      ts[mk] = value;
    }
  });

  return ts;
};

/**
 * N.B there's a bit of a trick to getting this right. There are two edge cases to consider:
 *  - when the app initially loads and no calculations have been requested yet,
 *    i.e. calculations.valueLoading[cacheKey] == null, then loading should be true
 *    so that we start off with loading indicators instead of empty cells
 */
interface EntityLoadingSelectorProps {
  id: FormulaEntityTypedId['id'];
  monthKey: MonthKey;
  layerId?: LayerId;
}

function isEntityLoadingForMonthKey({
  shouldDoSynchronousCalculations,
  hardcodedValue,
  id,
  monthKey,
  layerId,
}: {
  shouldDoSynchronousCalculations: boolean;
  hardcodedValue: Value | undefined;
  id: FormulaEntityTypedId['id'];
  monthKey: MonthKey;
  layerId: LayerId;
}) {
  if (shouldDoSynchronousCalculations) {
    return false;
  }

  if (hardcodedValue != null) {
    return false;
  }

  const cacheKey = getFormulaCacheKey(id, monthKey, []);
  return DataArbiter.get().isCachedValueStale({ cacheKey, layerId });
}

export const entityLoadingForMonthKeySelector: ParametricSelector<
  EntityLoadingSelectorProps,
  boolean
> = createCachedSelector(
  shouldDoSynchronousCalculationsSelector,
  calculationsStateVersionSelector,
  hardcodedValueForLayerSelector,
  fieldSelector<EntityLoadingSelectorProps, 'id'>('id'),
  fieldSelector<EntityLoadingSelectorProps, 'monthKey'>('monthKey'),
  getGivenOrCurrentLayerId,
  // eslint-disable-next-line max-params
  function entityLoadingForMonthKeySelector(
    shouldDoSynchronousCalculations,
    // This one is so that we rerun this selector when the calculations change
    _calculationsStateVersion,
    hardcodedValue,
    id,
    monthKey,
    layerId,
  ) {
    return isEntityLoadingForMonthKey({
      shouldDoSynchronousCalculations,
      hardcodedValue,
      id,
      monthKey,
      layerId,
    });
  },
)((_state, { id, monthKey }) => `${id}.${monthKey}`);

// Don't cache the selectors below. Just proxy them to cached selector instead.
export const entityLoadingAnyMonthInRangeSelector: ParametricSelector<
  Omit<EntityLoadingSelectorProps, 'monthKey'> & { monthKeys: MonthKey[] },
  boolean
> = (state: RootState, { monthKeys, ...props }) => {
  return monthKeys.some((monthKey) =>
    entityLoadingForMonthKeySelector(state, { ...props, monthKey }),
  );
};

export const entityLoadingByMonthKeySelector: ParametricSelector<
  EntitySelectorPropsByMonth,
  Record<MonthKey, boolean>
> = createCachedSelector(
  calculationsStateVersionSelector,
  shouldDoSynchronousCalculationsSelector,
  hardcodedValuesForLayerSelector,
  fieldSelector<EntitySelectorPropsByMonth, 'id'>('id'),
  fieldSelector<EntitySelectorPropsByMonth, 'monthKeys'>('monthKeys'),
  getGivenOrCurrentLayerId,
  // eslint-disable-next-line max-params
  function entityLoadingByMonthKeySelector(
    // Rerun this selector when the calculations change
    _calculationsStateVersion,
    shouldDoSynchronousCalculations,
    hardcodedValues,
    id,
    monthKeys,
    layerId,
  ) {
    return monthKeys.reduce(
      (acc, monthKey) => ({
        ...acc,
        [monthKey]: isEntityLoadingForMonthKey({
          shouldDoSynchronousCalculations,
          hardcodedValue: hardcodedValues[monthKey],
          id,
          monthKey,
          layerId,
        }),
      }),
      {},
    );
  },
)((_state, props) => getCacheKeyForEntityAndMonthKeysSelector(props));

export interface ImpactLoadingSelectorProps {
  id: FormulaEntityTypedId['id'];
  monthKey: MonthKey;
  ignoreEventIds: EventId[];
}
export const impactLoadingForMonthKeySelector: ParametricSelector<
  ImpactLoadingSelectorProps,
  boolean
> = createSelector(
  shouldDoSynchronousCalculationsSelector,
  impactCalculationsSelector,
  fieldSelector<ImpactLoadingSelectorProps, 'id'>('id'),
  fieldSelector<ImpactLoadingSelectorProps, 'monthKey'>('monthKey'),
  fieldSelector<ImpactLoadingSelectorProps, 'ignoreEventIds'>('ignoreEventIds'),
  function impactLoadingForMonthKeySelector(
    shouldDoSynchronousCalculations,
    impactCalculations,
    id,
    monthKey,
    ignoreEventIds,
  ) {
    if (shouldDoSynchronousCalculations) {
      return false;
    }

    const cacheKey = getFormulaCacheKey(id, monthKey, ignoreEventIds);
    const loading = impactCalculations.valueLoading[cacheKey];
    if (loading == null) {
      return true;
    }

    return loading;
  },
);

export const impactLoadingAnyMonthInRangeSelector: ParametricSelector<
  Omit<ImpactLoadingSelectorProps, 'monthKey'> & { monthKeys: MonthKey[] },
  boolean
> = (state: RootState, { monthKeys, ...props }) => {
  return monthKeys.some((monthKey) =>
    impactLoadingForMonthKeySelector(state, { ...props, monthKey }),
  );
};
