import { useMemo } from 'react';

import { ContextMenuItem } from 'components/ContextMenuItems/ContextMenuItem';
import { assertUnreachable, isNotNull } from 'helpers/typescript';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import useOpenDetailViewContextMenu, {
  DetailViewInfo,
} from 'hooks/useOpenDetailViewContextMenuItem';
import { triggerCopilotRequest } from 'reduxStore/actions/copilot';
import { deleteSelected } from 'reduxStore/actions/deleteSelected';
import { duplicateGroup } from 'reduxStore/actions/eventMutations';
import { groupSelected } from 'reduxStore/actions/groupSelected';
import { toggleSelectedVisible } from 'reduxStore/actions/toggleSelectedVisible';
import { DEFAULT_EVENT_GROUP_ID, PlanTimelineItemRef } from 'reduxStore/models/events';
import { PaneType } from 'reduxStore/reducers/detailPaneSlice';
import { CopilotOperation } from 'reduxStore/reducers/pageSlice';
import { businessObjectForFieldIdForLayerSelector } from 'selectors/businessObjectsSelector';
import { parentIdsByEventAndGroupIdsSelector } from 'selectors/eventsAndGroupsSelector';
import {
  blockScopedSelectedEventIdsSelector,
  canAutoGroupEventSelection,
  selectedEventGroupIdsSelector,
} from 'selectors/selectedEventSelector';
import DuplicateIcon from 'vectors/Duplicate';
import EyeIcon from 'vectors/Eye';
import Group from 'vectors/Group';
import Sparkles from 'vectors/Sparkles';
import TrashIcon from 'vectors/Trash';

function useOpenDetailViewInfo(ref: PlanTimelineItemRef): DetailViewInfo {
  const { type, id } = ref;

  const parentIdById = useAppSelector(parentIdsByEventAndGroupIdsSelector);
  const businessObject = useAppSelector((state) =>
    ref.type === 'entity' && ref.entityType === 'objectField'
      ? businessObjectForFieldIdForLayerSelector(state, ref.entityId)
      : null,
  );

  if (type === 'entity') {
    switch (ref.entityType) {
      case 'driver':
        return {
          id: ref.entityId,
          type: PaneType.Driver,
        };
      case 'objectField':
        return {
          id: businessObject?.id ?? id,
          type: PaneType.Object,
        };
        break;
      default: {
        // Typescript error if we forget to check for a type
        assertUnreachable(ref);
        break;
      }
    }
  }
  return {
    id: parentIdById[id] ?? id,
    type: PaneType.Plan,
  };
}

export default function useTimelineContextMenuItems(ref: PlanTimelineItemRef): ContextMenuItem[] {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const numSelectedImpacts = useAppSelector(
    (state) => blockScopedSelectedEventIdsSelector(state, blockId).length,
  );
  const numSelectedPlans = useAppSelector(
    (state) => selectedEventGroupIdsSelector(state, blockId).length,
  );
  const numSelectedEntities = numSelectedPlans + numSelectedImpacts;
  const canAutoGroup = useAppSelector(canAutoGroupEventSelection);
  const isDefaultEventGroup = ref.id === DEFAULT_EVENT_GROUP_ID;

  const detailsViewInfo = useOpenDetailViewInfo(ref);
  const openDetailPaneOption = useOpenDetailViewContextMenu(detailsViewInfo);

  const { id } = ref;
  const items = useMemo<ContextMenuItem[]>(() => {
    return [
      openDetailPaneOption,
      {
        text: 'Duplicate',
        multiSelect: false,
        isHidden: numSelectedImpacts !== 0,
        icon: <DuplicateIcon />,
        onSelect: () => {
          dispatch(duplicateGroup(id));
        },
        shortcut: 'duplicate' as const,
        isDisabled: isDefaultEventGroup,
      },
      {
        text: 'Show/Hide',
        multiSelect: true,
        icon: <EyeIcon boxSize={4} isOpen color="inherit" />,
        onSelect: () => {
          dispatch(toggleSelectedVisible());
        },
        shortcut: 'hide' as const,
      },
      {
        text: 'Group',
        icon: <Group />,
        multiSelect: true,
        isHidden: numSelectedEntities < 2,
        onSelect: () => {
          dispatch(groupSelected());
        },
        shortcut: 'group' as const,
      },
      {
        text: 'Auto Group',
        icon: <Sparkles />,
        multiSelect: true,
        keepOpenOnSelect: true,
        isHidden: !canAutoGroup,
        onSelect: () => {
          dispatch(triggerCopilotRequest({ operation: CopilotOperation.GroupEvents }));
        },
      },
      {
        text: 'Delete',
        icon: <TrashIcon />,
        multiSelect: true,
        destructive: true,
        onSelect: () => {
          dispatch(deleteSelected());
        },
        shortcut: 'delete' as const,
        isDisabled: isDefaultEventGroup,
      },
    ]
      .filter(isNotNull)
      .filter((item) => numSelectedEntities < 2 || item.multiSelect);
  }, [
    openDetailPaneOption,
    numSelectedImpacts,
    numSelectedEntities,
    canAutoGroup,
    isDefaultEventGroup,
    dispatch,
    id,
  ]);

  return items;
}
