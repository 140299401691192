import { Box, EffectProps } from '@chakra-ui/react';
import React from 'react';

interface Props {
  children?: React.ReactNode;
  testId?: string;
  className?: string;
  boxShadow?: EffectProps['boxShadow'];
}

const SelectMenuContainer = React.forwardRef<HTMLDivElement, Props>(
  ({ children, testId, className, boxShadow = 'menu' }, ref) => {
    return (
      <Box
        className={className}
        ref={ref}
        data-testid={testId}
        role="menu"
        overflow="hidden"
        bgColor="white"
        borderRadius="base"
        boxShadow={boxShadow}
        tabIndex={0}
        zIndex="popover"
      >
        {children}
      </Box>
    );
  },
);

export default SelectMenuContainer;
