import { ColDef, ColGroupDef } from 'ag-grid-community';
import EventEmitter from 'events';
import { deepEqual } from 'fast-equals';
import { difference, isEqual } from 'lodash';

import { filterValidComparisonTypes } from 'components/AgGridComponents/helpers/deriveColumnDef';
import { DataArbiter } from 'components/AgGridComponents/helpers/gridDatasource/DataArbiter';
import {
  BaseDataSourceUpdateStateParams,
  BaseRequestParams,
  BlockResponse,
  CommonSourceUpdateStateParams,
  DataSourceEvent,
  DataSourceEventListener,
  DataSourceEventListenerDisposeFn,
  GroupRequestParams,
  ObjectRowsRequest,
  ObjectRowsUpdated,
  PendingRequestParams,
  RequestId,
} from 'components/AgGridComponents/helpers/gridDatasource/types';
import { EMPTY_ATTRIBUTE_ID, Group, GroupRowWithoutAddItem } from 'config/businessObjects';
import { DEFAULT_CURRENCY } from 'config/currency';
import {
  BlockComparisonLayout,
  BusinessObjectCreateInput,
  ComparisonColumn,
  DriverType,
  NegativeDisplay,
} from 'generated/graphql';
import { arraySameElements } from 'helpers/array';
import { getDriverDecimalPlaces } from 'helpers/drivers';
import { getDriverFormat } from 'helpers/formulaEvaluation/DriverFormatResolver/DriverFormatEvaluator';
import { isObjectTableGroupExpanded } from 'helpers/isObjectTableGroupExpanded';
import { isNotNull } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import {
  BusinessObject,
  BusinessObjectFieldId,
  BusinessObjectId,
} from 'reduxStore/models/businessObjects';
import { AttributeId } from 'reduxStore/models/dimensions';
import { DriverId } from 'reduxStore/models/drivers';
import { DEFAULT_LAYER_ID, LayerId } from 'reduxStore/models/layers';
import { MutationBatch } from 'reduxStore/models/mutations';
import { DisplayConfiguration } from 'reduxStore/models/value';
import { MonthKey } from 'types/datetime';

type GroupIndex = number;
export type GroupWithoutAddItem = Omit<Group, 'rows'> & {
  rows: GroupRowWithoutAddItem[];
};

/**
 * Abstract mixin for IBaseDataSource implementations.
 * Only applicable to object grid variants (not driver grids).
 * Includes some state management and helpers that are useful for reducing complexity.
 */
export abstract class ObjectDataSource {
  protected id: string;
  // Derived from Redux.
  protected blockId: string;
  protected blockConfig?: BaseDataSourceUpdateStateParams['blockConfig'];
  protected comparisonLayerIds: LayerId[];
  protected comparisonTypes: ComparisonColumn[];
  protected comparisonLayout: BlockComparisonLayout;
  protected viewAtMonthKey?: BaseDataSourceUpdateStateParams['viewAtMonthKey'];
  protected dateRange?: BaseDataSourceUpdateStateParams['dateRange'];
  protected currentLayerId: BaseDataSourceUpdateStateParams['currentLayerId'];
  protected calculationEngine?: BaseDataSourceUpdateStateParams['calculationEngine'];
  protected objectSpec?: CommonSourceUpdateStateParams['objectSpec'];
  protected objectsById?: CommonSourceUpdateStateParams['objectsById'];
  protected dimensionalPropertyEvaluator?: CommonSourceUpdateStateParams['dimensionalPropertyEvaluator'];
  protected driversByIdByLayerId?: CommonSourceUpdateStateParams['driversByIdByLayerId'];
  protected loggedInUser?: CommonSourceUpdateStateParams['loggedInUser'];
  protected enableBackendBlockEval: boolean;
  protected orgSettings?: CommonSourceUpdateStateParams['orgSettings'];

  // State for requests.
  protected pendingRequests: Map<RequestId, PendingRequestParams>;

  // Calculated state.
  protected groups?: GroupWithoutAddItem[];
  protected dimensionalDriverIds?: Set<DriverId>;
  protected suppressRefreshOnLayerChange: boolean;
  protected undoneMutation?: MutationBatch;

  // Other state.
  private initialized: boolean;
  private destroyed: boolean;
  private emitter: EventEmitter;

  constructor(blockId: string) {
    this.id = uuidv4();
    this.blockId = blockId;
    this.pendingRequests = new Map();
    this.destroyed = false;
    this.emitter = new EventEmitter();
    this.enableBackendBlockEval = false;
    this.suppressRefreshOnLayerChange = false;
    this.currentLayerId = DEFAULT_LAYER_ID;
    this.comparisonLayerIds = [];
    this.comparisonTypes = [];
    this.comparisonLayout = BlockComparisonLayout.Columns;
    this.initialized = false;
  }

  protected destroy(): void {
    this.emitter.removeAllListeners();
    this.destroyed = true;
  }

  protected initialize({
    blockConfig,
    dateRange,
    currentLayerId,
    viewAtMonthKey,
    calculationEngine,
    objectSpec,
    objectsById,
    dimensionalPropertyEvaluator,
    loggedInUser,
    enableBackendBlockEval,
    driversByIdByLayerId,
    orgSettings,
    enableBvAInDatabases,
  }: CommonSourceUpdateStateParams) {
    this.initialized = true;
    this.blockConfig = blockConfig;
    this.dateRange = dateRange;
    this.currentLayerId = currentLayerId;
    this.comparisonLayerIds = enableBvAInDatabases ? blockConfig?.comparisons?.layerIds ?? [] : [];
    this.comparisonTypes = filterValidComparisonTypes(
      enableBvAInDatabases ? blockConfig?.comparisons?.columns ?? [] : [],
    );
    this.comparisonLayout = blockConfig?.comparisons?.layout ?? BlockComparisonLayout.Columns;
    this.calculationEngine = calculationEngine;
    this.viewAtMonthKey = viewAtMonthKey;
    this.objectSpec = objectSpec;
    this.objectsById = objectsById;
    this.dimensionalPropertyEvaluator = dimensionalPropertyEvaluator;
    this.loggedInUser = loggedInUser;
    this.enableBackendBlockEval = enableBackendBlockEval;
    this.dimensionalDriverIds = new Set(
      objectSpec?.collection?.driverProperties.map((prop) => prop.driverId),
    );
    this.driversByIdByLayerId = driversByIdByLayerId;
    this.orgSettings = orgSettings;
  }

  protected reset() {
    this.destroyed = false;
  }

  public get instanceId(): string {
    return this.id;
  }

  public get isDestroyed(): boolean {
    return this.destroyed;
  }

  public on<T extends DataSourceEvent>(
    type: T['type'],
    listener: DataSourceEventListener<T>,
  ): DataSourceEventListenerDisposeFn {
    this.emitter.on(type, listener);
    return () => this.emitter.off(type, listener);
  }

  isGroupRowExpanded(groupByAttributeId: string): boolean {
    const matchingGroup = this.groups?.find(
      ({ groupInfo }) => groupInfo.attributeId === groupByAttributeId,
    );

    return matchingGroup?.isExpanded ?? false;
  }

  //
  // DataSource helpers.
  // Define protected helper functions here.
  // Please do NOT use method overriding.
  // If a concrete function name closes resembles a function in this file,
  // append "helper" to the end to make it clear that your concrete class
  // is not attempting to override the function defined in this class.
  //

  protected shouldParamsChangeForceAgGridToRefresh(params: CommonSourceUpdateStateParams): boolean {
    // don't refresh if it's initialization
    if (!this.initialized) {
      return false;
    }

    // layer has changed
    if (this.currentLayerId !== params.currentLayerId) {
      return true;
    }

    if (
      this.comparisonLayout !==
      (params.blockConfig?.comparisons?.layout ?? BlockComparisonLayout.Columns)
    ) {
      return true;
    }

    if (
      !isEqual(
        this.comparisonLayerIds.toSorted(),
        (params.blockConfig?.comparisons?.layerIds ?? []).toSorted(),
      )
    ) {
      return true;
    }

    if (
      !isEqual(
        this.comparisonTypes.toSorted(),
        (params.blockConfig?.comparisons?.columns ?? []).toSorted(),
      )
    ) {
      return true;
    }

    //  This relies on the fact that the backend will send updates preemptively
    //  when the block config changes when "backend-only" is on.
    //  TODO: If we're using the backend, we should still try to show a loading state.
    if (!this.enableBackendBlockEval) {
      // sort has changed
      const oldSort = this.blockConfig?.sortBy?.object?.ops;
      const newSort = params.blockConfig?.sortBy?.object?.ops;
      if (!deepEqual(oldSort, newSort)) {
        return true;
      }

      // filter has changed
      const oldFilters = this.blockConfig?.filterBy;
      const newFilters = params.blockConfig?.filterBy;
      if (!deepEqual(oldFilters, newFilters)) {
        return true;
      }

      // groupBy has changed
      const oldGroupBy = this.blockConfig?.groupBy?.objectField?.businessObjectFieldId;
      const newGroupBy = params.blockConfig?.groupBy?.objectField?.businessObjectFieldId;
      if (!deepEqual(oldGroupBy, newGroupBy)) {
        return true;
      }

      // viewAtTime only matters for filter - if filters is empty we don't need to check it
      if (newFilters != null && newFilters.length > 0) {
        const oldViewAtTime = this.blockConfig?.viewAtTime;
        const newViewAtTime = params.blockConfig?.viewAtTime;
        if (!deepEqual(oldViewAtTime, newViewAtTime)) {
          return true;
        }
      }
    }

    return false;
  }

  protected findNewFieldIdsByObjectId(
    oldObjectsById: NullableRecord<string, BusinessObject>,
    newObjectsById: NullableRecord<string, BusinessObject>,
  ): Record<BusinessObjectId, BusinessObjectFieldId[]> {
    const out: Record<BusinessObjectId, BusinessObjectFieldId[]> = {};

    for (const [objectId, object] of Object.entries(newObjectsById)) {
      if (object == null || object.specId !== this.objectSpec?.id) {
        continue;
      }

      const { fields } = object;

      const oldObject = oldObjectsById[objectId];
      if (oldObject == null) {
        // If the object is new, return all fields.
        out[objectId] = fields.map(({ id }) => id);
        continue;
      }

      const { fields: oldFields } = oldObject;
      if (fields.length === oldFields.length) {
        continue;
      }

      const fieldIds = difference(
        fields.map(({ id }) => id),
        oldFields.map(({ id }) => id),
      );

      for (const fieldId of fieldIds) {
        if (objectId in out) {
          out[objectId].push(fieldId);
        } else {
          out[objectId] = [fieldId];
        }
      }
    }

    return out;
  }

  protected haveColumnDefsChanged(
    oldColumnDefs: Array<ColDef | ColGroupDef> | undefined,
    newColumnDefs: Array<ColDef | ColGroupDef> | undefined,
  ): boolean {
    // Rebuild the table data when columns change.
    if (oldColumnDefs != null && newColumnDefs != null) {
      const currKey = this.getColumnIds(oldColumnDefs);
      const nextKey = this.getColumnIds(newColumnDefs);
      if (!arraySameElements(currKey, nextKey)) {
        return true;
      }
    }

    return false;
  }

  protected getColumnIds(defs: Array<ColDef | ColGroupDef>): string[] {
    return defs.flatMap((def) => {
      if ('children' in def) {
        return this.getColumnIds(def.children);
      }
      return def.colId ?? '';
    });
  }

  protected handleBlockResponseHelper(res: BlockResponse): void {
    const { blockConfig } = this;
    if (blockConfig == null) {
      return;
    }

    const collapsed =
      blockConfig.groupBy?.objectField?.collapsedAttributeIds ??
      blockConfig.groupBy?.driverProperty?.collapsedAttributeIds;
    this.groups = res.groups.map(({ groupInfo, rows }) => {
      const baseRows: GroupRowWithoutAddItem[] = rows.filter(
        (r): r is GroupRowWithoutAddItem => r.type === 'object',
      );
      const comparisonRows =
        this.comparisonLayerIds.length === 0
          ? baseRows
          : baseRows.flatMap((row) => {
              return [
                row,
                ...(this.comparisonLayout === BlockComparisonLayout.Columns
                  ? this.comparisonTypes.map((comparisonType) => ({
                      ...row,
                      comparisonType,
                    }))
                  : this.comparisonLayerIds.map((layerId) => ({
                      ...row,
                      layerId,
                    }))),
              ];
            });
      const group: GroupWithoutAddItem = {
        rows: comparisonRows,
        groupInfo,
        isExpanded: isObjectTableGroupExpanded(groupInfo, collapsed),
      };
      return group;
    });
  }

  protected requestBlockHelper(params: BaseRequestParams | GroupRequestParams) {
    const { dateRange, viewAtMonthKey } = this;
    if (dateRange == null || viewAtMonthKey == null) {
      return;
    }

    const calculator = !this.enableBackendBlockEval
      ? 'webworker'
      : this.calculationEngine ?? 'webworker';

    const requestId = DataArbiter.get().requestBlock({
      instanceId: this.id,
      calculator,
      data: {
        blockId: this.blockId,
        dateRange,
        viewAtMonthKey,
        layerId: this.currentLayerId,
      },
    });

    this.pendingRequests.set(requestId, { ...params, type: 'block' });
  }

  protected requestObjectsHelper(
    params: PendingRequestParams,
    objects: Array<{ driverIds: string[]; fieldIds: string[] }>,
  ): void {
    const { dateRange, calculationEngine } = this;
    if (dateRange == null) {
      return;
    }

    [this.currentLayerId, ...this.comparisonLayerIds].forEach((layerId) => {
      const request: ObjectRowsRequest = {
        instanceId: this.id,
        objects,
        layerId,
        dateRange,
        calculator: calculationEngine,
      };

      const requestId = DataArbiter.get().requestObjectRows(request);
      this.pendingRequests.set(requestId, params);
    });
  }

  // Abstract hook methods all data sources must implement.
  // These are hooks that the implementing data sources should use to
  // mutate its internal state in response to an incoming mutation.
  // These methods will be exclusively called by handleMutationCommon.
  protected abstract addObjectsMutationHook(
    objectIds: BusinessObjectId[],
    groupIndex: number,
  ): void;
  protected abstract removeObjectsMutationHook(objectIds: BusinessObjectId[]): void;
  protected abstract updateObjectsMutationHook(objectIds: BusinessObjectId[]): void;
  protected abstract updateSubDriversMutationHook(driverIds: DriverId[]): void;
  protected abstract addSubDriversMutationHook(driverIds: DriverId[]): void;
  protected abstract updateObjectSpecMutationHook(): void;

  protected handleMutationCommon(mutation: MutationBatch): void {
    const { driversByIdByLayerId } = this;
    if (driversByIdByLayerId == null) {
      return;
    }

    const { updateDrivers } = mutation.mutation;

    if (updateDrivers != null && updateDrivers.length > 0) {
      // When drivers are lazily created, we will need to recreate the rows.
      // Track the unmatched subdrivers so that a future state update can properly recreate the rows.
      const newDrivers = updateDrivers.reduce<{ objectIds: string[]; driverIds: string[] }>(
        (out, { id, newSubDrivers }) => {
          // All objects with a matching dimensional subdriver must be rebuilt.
          const objectId = this.dimensionalPropertyEvaluator?.getBusinessObjectIdBySubDriverId(id);
          if (objectId != null) {
            out.objectIds.push(objectId);
          }

          // If the top-level dimensional driver has not yet been propagated through Redux, skip checking subdrivers.
          if (!this.dimensionalDriverIds?.has(id) || !newSubDrivers) {
            return out;
          }

          for (const { driver } of newSubDrivers) {
            if (!driver) {
              continue;
            }
            out.driverIds.push(driver.id);
          }

          return out;
        },
        {
          objectIds: [],
          driverIds: [],
        },
      );
      this.updateObjectsMutationHook(newDrivers.objectIds);
      this.addSubDriversMutationHook(newDrivers.driverIds);

      const updatedDriverIds = updateDrivers.reduce<DriverId[]>((out, { id: driverId }) => {
        const driver = driversByIdByLayerId[this.currentLayerId]?.[driverId];
        if (driver == null) {
          return out;
        }
        // If the top-level dimensional driver has not yet been propagated through Redux, skip checking subdrivers.
        if (driver.type === DriverType.Dimensional && !this.dimensionalDriverIds?.has(driverId)) {
          return out;
        }

        out.push(driverId);
        return out;
      }, []);
      this.updateSubDriversMutationHook(updatedDriverIds);
    }

    // Driver mutations happen in the main layer.
    // Only check layer for object mutations.
    const { layerId } = mutation;
    const isUserMovingToNewDraftLayer =
      this.currentLayerId === DEFAULT_LAYER_ID &&
      mutation.mutation.newLayers?.some((layer) => {
        return layer.id === layerId && layer.isDraft && layer.userId === this.loggedInUser?.id;
      });
    if (!isUserMovingToNewDraftLayer && layerId != null && layerId !== this.currentLayerId) {
      return;
    }

    // build on top of datasource when user is on the default layer and makes a change that creates a new draft layer
    // this prevents the flicker & scroll to top that a full refresh would cause
    if (isUserMovingToNewDraftLayer) {
      this.suppressRefreshOnLayerChange = true;
    }

    const {
      deleteBusinessObjects,
      newBusinessObjects,
      updateBusinessObjects,
      updateBusinessObjectSpecs,
    } = mutation.mutation;

    // Handled deleted objects.
    if (deleteBusinessObjects != null && deleteBusinessObjects.length > 0) {
      this.removeObjectsMutationHook(deleteBusinessObjects.map((obj) => obj.id));
    }

    // Handled updated objects.
    if (updateBusinessObjects != null && updateBusinessObjects.length > 0) {
      updateBusinessObjects.forEach((object) => {
        this.updateObjectsMutationHook([object.id]);
      });
    }

    // Handle new objects.
    if (
      newBusinessObjects != null &&
      newBusinessObjects.length > 0 &&
      this.groups != null &&
      this.groups.length > 0
    ) {
      const relevantNewBusinessObjects = newBusinessObjects.filter(
        (obj) => obj.specId === this.objectSpec?.id,
      );
      if (relevantNewBusinessObjects.length === 0) {
        return;
      }

      if (this.blockConfig?.groupBy?.objectField != null) {
        // Apply the groupBy logic to newly added objects.
        const appliedGroupings = this.deriveNewObjectGroups(relevantNewBusinessObjects);
        for (const [groupIndex, objectIds] of appliedGroupings) {
          this.addObjectsMutationHook(objectIds, groupIndex);
        }
      } else {
        // Add object to first group.
        this.addObjectsMutationHook(
          relevantNewBusinessObjects.map((obj) => obj.id),
          0,
        );
      }
    }

    // Handle updated object specs.
    if (updateBusinessObjectSpecs != null) {
      const updatedSpecIds = new Set(updateBusinessObjectSpecs.map((spec) => spec.id));
      if (this.objectSpec != null && updatedSpecIds.has(this.objectSpec.id)) {
        this.updateObjectSpecMutationHook();
      }
    }
  }

  protected handleUndoneMutationCommon(mutation: MutationBatch): void {
    this.undoneMutation = structuredClone(mutation);
  }

  protected emit<T extends DataSourceEvent>(evt: T): void {
    this.emitter.emit(evt.type, evt);
  }

  protected extractMonthsByFieldId(
    values: ObjectRowsUpdated['values'],
  ): Map<BusinessObjectFieldId, Set<MonthKey>> {
    const result = new Map<BusinessObjectFieldId, Set<MonthKey>>();
    for (const { id, monthKey } of values) {
      if (!result.has(id)) {
        result.set(id, new Set());
      }
      result.get(id)?.add(monthKey);
    }
    return result;
  }

  public getGroupForAttribute(
    attributeId: AttributeId | undefined | null,
  ): GroupWithoutAddItem | undefined {
    if (attributeId == null) {
      return undefined;
    }
    return this.groups?.find(({ groupInfo }) => groupInfo.attributeId === attributeId);
  }

  protected getDriverDisplayConfiguration(driverId: DriverId): DisplayConfiguration {
    const driver = this.driversByIdByLayerId?.[this.currentLayerId]?.[driverId];
    const format = getDriverFormat(driver);
    return {
      format,
      currency:
        driver?.currencyISOCode ?? this.orgSettings?.defaultCurrencyISOCode ?? DEFAULT_CURRENCY,
      decimalPlaces:
        getDriverDecimalPlaces(driver, format) ?? this.orgSettings?.defaultDecimalPrecision,
      negativeDisplay: this.orgSettings?.negativeDisplay ?? NegativeDisplay.NegativeSign,
    };
  }

  /**
   * Used to derive the applicable object group when a mutation arrives.
   * Avoids needing to reevaluate the block.
   */
  private deriveNewObjectGroups(
    newObjects: BusinessObjectCreateInput[],
  ): Array<[GroupIndex, BusinessObjectId[]]> {
    const groupFieldId = this.blockConfig?.groupBy?.objectField?.businessObjectFieldId;
    if (groupFieldId == null || this.groups == null) {
      return [[0, newObjects.map(({ id }) => id)]];
    }

    const objectPredicate = (group: Group, object: BusinessObjectCreateInput) => {
      const field = object.fields.find(({ fieldSpecId }) => fieldSpecId === groupFieldId);
      const dimensionalProperty = object.collectionEntry?.attributeProperties?.find(
        ({ dimensionalPropertyId }) => dimensionalPropertyId === groupFieldId,
      );

      if (field != null) {
        return field.value.initialValue === group.groupInfo.attributeId;
      }
      if (dimensionalProperty != null) {
        // Can not use dimensionalPropertyEvaulator here because the Redux state change has not propagated yet.
        // This means that adding objects to group derived from mapped columns won't work.
        // TODO: T-21146
        return dimensionalProperty.attributeId === group.groupInfo.attributeId;
      }

      // In the case where there is no field/property we should add to the "none" group
      if (group.groupInfo.attributeId === EMPTY_ATTRIBUTE_ID) {
        return true;
      }

      return false;
    };

    return this.groups
      .map<[GroupIndex, BusinessObjectId[]] | null>((group, index) => {
        const passingObjects = newObjects.filter((obj) => objectPredicate(group, obj));
        if (passingObjects.length === 0) {
          return null;
        }

        return [index, passingObjects.map(({ id }) => id)];
      })
      .filter(isNotNull);
  }
}
