import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'LineChart',
  viewBox: '0 0 16 16',
  defaultProps: {
    fill: '#18A0FB',
    boxSize: '14px',
  },
  d: 'M4.33366 9.66634C4.59888 9.66634 4.85323 9.56098 5.04077 9.37345C5.2283 9.18591 5.33366 8.93156 5.33366 8.66634C5.33691 8.63309 5.33691 8.5996 5.33366 8.56634L7.19366 6.70634H7.34699H7.50033L8.57366 7.77967C8.57366 7.77967 8.57366 7.81301 8.57366 7.83301C8.57366 8.09822 8.67902 8.35258 8.86655 8.54011C9.05409 8.72765 9.30844 8.83301 9.57366 8.83301C9.83887 8.83301 10.0932 8.72765 10.2808 8.54011C10.4683 8.35258 10.5737 8.09822 10.5737 7.83301V7.77967L13.0003 5.33301C13.1981 5.33301 13.3914 5.27436 13.5559 5.16448C13.7203 5.0546 13.8485 4.89842 13.9242 4.71569C13.9999 4.53297 14.0197 4.3319 13.9811 4.13792C13.9425 3.94394 13.8473 3.76575 13.7074 3.6259C13.5676 3.48605 13.3894 3.39081 13.1954 3.35222C13.0014 3.31364 12.8004 3.33344 12.6176 3.40913C12.4349 3.48482 12.2787 3.61299 12.1689 3.77744C12.059 3.94189 12.0003 4.13523 12.0003 4.33301C11.9971 4.36626 11.9971 4.39975 12.0003 4.43301L9.59366 6.83967H9.48699L8.33366 5.66634C8.33366 5.40112 8.2283 5.14677 8.04077 4.95923C7.85323 4.7717 7.59887 4.66634 7.33366 4.66634C7.06844 4.66634 6.81409 4.7717 6.62655 4.95923C6.43902 5.14677 6.33366 5.40112 6.33366 5.66634L4.33366 7.66634C4.06844 7.66634 3.81409 7.7717 3.62655 7.95923C3.43902 8.14677 3.33366 8.40112 3.33366 8.66634C3.33366 8.93156 3.43902 9.18591 3.62655 9.37345C3.81409 9.56098 4.06844 9.66634 4.33366 9.66634V9.66634ZM13.3337 12.333H2.00033V0.999674C2.00033 0.822863 1.93009 0.653294 1.80506 0.52827C1.68004 0.403246 1.51047 0.333008 1.33366 0.333008C1.15685 0.333008 0.987279 0.403246 0.862254 0.52827C0.73723 0.653294 0.666992 0.822863 0.666992 0.999674V12.9997C0.666992 13.1765 0.73723 13.3461 0.862254 13.4711C0.987279 13.5961 1.15685 13.6663 1.33366 13.6663H13.3337C13.5105 13.6663 13.68 13.5961 13.8051 13.4711C13.9301 13.3461 14.0003 13.1765 14.0003 12.9997C14.0003 12.8229 13.9301 12.6533 13.8051 12.5283C13.68 12.4032 13.5105 12.333 13.3337 12.333Z',
});
