import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'LockIcon',
  viewBox: '0 0 16 16',
  defaultProps: {
    boxSize: 4,
  },
  path: (
    <>
      <rect
        x="4.4"
        y="7.4"
        width="7.2"
        height="5.2"
        rx="1.6"
        stroke="currentColor"
        strokeWidth="1.2"
        fill="none"
      />
      <path
        d="M6 7V6c0-1 .5-2 2-2s2 1 2 2v1"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </>
  ),
});
