import { useMemo } from 'react';

import { evaluateFormulaDisplay } from 'helpers/formulaEvaluation/ForecastCalculator/ForecastCalculator';
import { FormulaDisplay } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaDisplayListener';
import useAppSelector from 'hooks/useAppSelector';
import {
  DriverId,
  getDriverActualsFormulaForCalculation,
  getDriverForecastFormulaForCalculation,
} from 'reduxStore/models/drivers';
import { LayerId } from 'reduxStore/models/layers';
import {
  driverActualsFormulaSelector,
  driverForecastFormulaSelector,
  driverSelector,
  parentDimDriverForSubDriverSelector,
} from 'selectors/driversSelector';
import { formulaDisplayListenerSelector } from 'selectors/formulaDisplaySelector';

interface UseFormulaDisplayProps {
  isActuals: boolean;
  layerId?: LayerId;
  driverId: DriverId;
}

export const useFormulaDisplay = ({
  isActuals,
  driverId,
  layerId,
}: UseFormulaDisplayProps): {
  formulaDisplay: FormulaDisplay | null;
  rawFormula: string | null;
  calculationFormula: string;
  color: string | undefined;
  isFormulaSet: boolean;
  inheritableFormula: string;
  inheritableFormulaDisplay: FormulaDisplay | null;
  inheritableFormulaSourceDriverId: DriverId;
  inheritableFormulaSourceType: 'forecast' | 'defaultForecast' | 'actuals' | 'defaultActuals';
} => {
  const driver = useAppSelector((state) => driverSelector(state, { id: driverId, layerId }));
  const parentDimDriver = useAppSelector((state) =>
    parentDimDriverForSubDriverSelector(state, { id: driverId, layerId }),
  );
  const options = useMemo(() => {
    if (driver == null) {
      return {
        calculationFormula: '',
        color: undefined,
        isFormulaSet: false,
        inheritableFormula: '',
        inheritableFormulaDisplay: null,
        inheritableFormulaSourceDriverId: '',
        inheritableFormulaSourceType: 'forecast' as const,
      };
    }
    const { formula, isFormulaSet } = isActuals
      ? getDriverActualsFormulaForCalculation(driver, parentDimDriver)
      : getDriverForecastFormulaForCalculation(driver, parentDimDriver);

    const color = isFormulaSet ? undefined : 'gray.500';

    const {
      formula: inheritableFormula,
      sourceDriverId: inheritableFormulaSourceDriverId,
      sourceType: inheritableFormulaSourceType,
    } = isActuals
      ? getDriverActualsFormulaForCalculation(driver, parentDimDriver, true)
      : getDriverForecastFormulaForCalculation(driver, parentDimDriver, true);

    return {
      calculationFormula: formula,
      color,
      isFormulaSet,
      inheritableFormula,
      inheritableFormulaSourceDriverId,
      inheritableFormulaSourceType,
    };
  }, [driver, isActuals, parentDimDriver]);

  const calculationFormula = options?.calculationFormula;

  const formulaDisplayListener = useAppSelector((state) =>
    formulaDisplayListenerSelector(state, { type: 'driver', id: driverId, layerId }),
  );
  const formulaDisplay = useMemo(
    () =>
      calculationFormula !== ''
        ? evaluateFormulaDisplay(calculationFormula, formulaDisplayListener)
        : null,
    [calculationFormula, formulaDisplayListener],
  );

  const inheritableFormulaDisplay = useMemo(
    () =>
      options?.inheritableFormula !== ''
        ? evaluateFormulaDisplay(options.inheritableFormula, formulaDisplayListener)
        : null,
    [formulaDisplayListener, options.inheritableFormula],
  );

  const rawFormula = useAppSelector((state) =>
    options.isFormulaSet
      ? calculationFormula
      : isActuals
        ? driverActualsFormulaSelector(state, { id: driverId, layerId })
        : driverForecastFormulaSelector(state, { id: driverId, layerId }),
  );
  return useMemo(
    () => ({ ...options, formulaDisplay, rawFormula, inheritableFormulaDisplay }),
    [formulaDisplay, inheritableFormulaDisplay, options, rawFormula],
  );
};
