import { platform } from 'helpers/environment';
import { isNotNull } from 'helpers/typescript';

const CTRL = 'Ctrl';
export const MODIFIER_KEY = platform() === 'Mac' ? '⌘' : CTRL;
const SHIFT = '⇧';

export const getKeyboardShortcut = (
  key: string,
  { meta = false, shift = false }: { meta?: boolean; shift?: boolean } = {},
) => {
  return [meta ? MODIFIER_KEY : null, shift ? SHIFT : null, key].filter(isNotNull).join('');
};

export const KEYBOARD_SHORTCUTS = {
  delete: '⌫',
  shiftDelete: getKeyboardShortcut('⌫', { shift: true }),
  indent: getKeyboardShortcut(']', { meta: true }),
  outdent: getKeyboardShortcut('[', { meta: true }),
  enter: '⏎ Enter',
  escape: 'Esc',
  metaEnter: getKeyboardShortcut('Enter', { meta: true }),
  duplicate: getKeyboardShortcut('D', { meta: true }),
  search: getKeyboardShortcut('K', { meta: true }),
  merge: getKeyboardShortcut('M', { meta: true, shift: true }),
  details: getKeyboardShortcut('⏎', { meta: true, shift: true }),
  hide: getKeyboardShortcut('H', { shift: true, meta: true }),
  group: getKeyboardShortcut('G', { meta: true }),
  detailPageUp: `${SHIFT} ${CTRL} K`,
  detailPageDown: `${SHIFT} ${CTRL} J`,
  objectInspector: getKeyboardShortcut('T', { shift: true, meta: true }),
};

export type KeyboardShortcut = keyof typeof KEYBOARD_SHORTCUTS;
