import { Flex, Text } from '@chakra-ui/react';

import LastActualsMenu from 'components/LastActualsMenu/LastActualsMenu';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { comparisonLayerIdsForBlockSelector } from 'selectors/scenarioComparisonSelector';

export type TimeSeriesHeaderDataType = 'driver' | 'event' | 'extDriver';

interface Props {
  label: string;
  isActuals?: boolean;
  isLastActualsMonth?: boolean;
  dataType?: TimeSeriesHeaderDataType;
  onChangeLastClose?: () => void;
  lastActualsTopPositionProps?: { top: string; py: number };
}

const TimeSeriesHeaderLabel: React.FC<Props> = ({
  label,
  isActuals = false,
  isLastActualsMonth = false,
  dataType = 'driver',
  onChangeLastClose,
  lastActualsTopPositionProps,
}) => {
  const { blockId } = useBlockContext();
  const comparisonLayerIds = useAppSelector((state) =>
    comparisonLayerIdsForBlockSelector(state, blockId),
  );
  const isComparisonLayout = comparisonLayerIds.length > 0;
  return (
    <Flex
      direction="row"
      height="full"
      width="full"
      align="flex-end"
      justify="flex-end"
      pb="2px"
      data-testid="timeseries-header-label"
    >
      <Text
        fontSize={isComparisonLayout ? 'xxs' : 'xs'}
        fontWeight="medium"
        color={isActuals || dataType === 'extDriver' ? 'gray.500' : 'blue.500'}
      >
        {label}
      </Text>
      {isLastActualsMonth && (
        <LastActualsMenu
          dataType={dataType}
          onUpdated={onChangeLastClose}
          topPositionProps={lastActualsTopPositionProps}
        />
      )}
    </Flex>
  );
};

export default TimeSeriesHeaderLabel;
