import isEmpty from 'lodash/isEmpty';

import { ValueType } from 'generated/graphql';
import { EvaluatorDriver } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import { safeObjGet } from 'helpers/typescript';
import {
  BusinessObjectFieldSpec,
  BusinessObjectFieldSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { DriverFormat, DriverId, DriverType } from 'reduxStore/models/drivers';
import { DEFAULT_LAYER_ID } from 'reduxStore/models/layers';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { businessObjectFieldSpecByIdSelector } from 'selectors/businessObjectFieldSpecsSelector';
import {
  dimensionalDriversBySubDriverIdSelector,
  driversByIdForLayerSelector,
} from 'selectors/driversSelector';

type FormatEntity = {
  id: DriverId | BusinessObjectFieldSpecId;
  format: DriverFormat;
  forecastFormula: string | null;
  actualsFormula: string | null;
};

/**
 * The idea behind this cache is the same as the FormulaDependencyGraph. We have a single global instance of the cache
 * which we update inside of the FormulaCacheInvalidator whenever a driver/field spec formula or format is updated.
 * Then we use the formula to recursively determine the graph of other entities to invalidate.
 *
 * TODO: Right now, we only need one instance of the AutoFormatCacheSingleton rather than one per layer since
 * driver and field specs are only stored in the main layer. As we start to layerize drivers, we'll need to
 * maintain a separate AutoFormatCache per layer.
 */
class AutoFormatCache {
  private cache: Map<string, DriverFormat>;
  private formatEntities: Map<string, FormatEntity | undefined>;
  private initialized: boolean;

  constructor() {
    this.formatEntities = new Map();
    this.initialized = false;
    this.cache = new Map();
  }

  public initialize(state: RootState): void {
    this.initializeIfNotInitialized(state);
  }

  public getCachedFormat(id: DriverId | BusinessObjectFieldSpecId): DriverFormat | undefined {
    return this.cache.get(id);
  }

  public setCachedFormat(id: DriverId | BusinessObjectFieldSpecId, val: DriverFormat): void {
    this.cache.set(id, val);
  }

  public clearCachedFormat(id: DriverId | BusinessObjectFieldSpecId): void {
    this.cache.delete(id);
  }

  public getFormatEntity(id: DriverId | BusinessObjectFieldSpecId): FormatEntity | undefined {
    return this.formatEntities.get(id);
  }

  public updateEntityFormat({
    state,
    entityId,
  }: {
    state: RootState;
    entityId: EvaluatorDriver['id'] | BusinessObjectFieldSpec['id'];
  }): void {
    this.clearCachedFormat(entityId);

    const driversById = driversByIdForLayerSelector(state);
    const driver = safeObjGet(driversById[entityId]);

    if (driver != null) {
      this.updateDriverFormat(state, driver);
      return;
    }

    const fieldSpecsById = businessObjectFieldSpecByIdSelector(state);
    const fieldSpec = fieldSpecsById[entityId];

    if (fieldSpec != null) {
      this.updateBusinessObjectFieldSpecFormat(state, fieldSpec);
    }
  }

  private updateDriverFormat(state: RootState, driver: EvaluatorDriver) {
    this.initializeIfNotInitialized(state);

    const { id, format } = driver;

    const dimDriversBySubdriverId = dimensionalDriversBySubDriverIdSelector(state);
    const driversById = driversByIdForLayerSelector(state);

    if (driver.type === DriverType.Basic) {
      this.formatEntities.set(id, {
        id,
        format,
        forecastFormula: driver.forecast.formula,
        actualsFormula: driver.actuals.formula ?? null,
      });

      const dimDriver = dimDriversBySubdriverId[id];
      if (
        dimDriver != null &&
        dimDriver.format === DriverFormat.Auto &&
        dimDriver.subdrivers[0].driverId === id
      ) {
        this.formatEntities.set(dimDriver.id, {
          id: dimDriver.id,
          format,
          forecastFormula: driver.forecast.formula,
          actualsFormula: driver.actuals.formula ?? null,
        });
      }
    } else {
      // for dimensional drivers, use the format of the first subdriver
      const firstSubdriver = driver.subdrivers.find((sub) => driversById[sub.driverId] != null);
      const subdriver = firstSubdriver != null ? driversById[firstSubdriver.driverId] : null;

      if (
        driver.format === DriverFormat.Auto &&
        subdriver != null &&
        subdriver.type === DriverType.Basic
      ) {
        this.formatEntities.set(id, {
          id,
          format: subdriver.format,
          forecastFormula: subdriver.forecast.formula,
          actualsFormula: subdriver.actuals.formula ?? null,
        });
      } else {
        this.formatEntities.set(id, {
          id,
          format: driver.format,
          forecastFormula: null,
          actualsFormula: null,
        });
      }
    }
  }

  private updateBusinessObjectFieldSpecFormat(
    state: RootState,
    fieldSpec: BusinessObjectFieldSpec,
  ) {
    if (fieldSpec.type !== ValueType.Number) {
      return;
    }

    this.initializeIfNotInitialized(state);

    this.formatEntities.set(fieldSpec.id, {
      id: fieldSpec.id,
      format: fieldSpec.numericFormat ?? DriverFormat.Number,
      forecastFormula:
        fieldSpec.isFormula && !isEmpty(fieldSpec.defaultForecast.formula)
          ? fieldSpec.defaultForecast.formula
          : null,
      actualsFormula: null,
    });
  }

  public reset(state: RootState) {
    this.cache = new Map();
    this.formatEntities = new Map();
    this.initialized = false;

    this.initialize(state);
  }

  private initializeIfNotInitialized(state: RootState) {
    if (this.initialized) {
      return;
    }

    this.formatEntities = new Map();
    this.initialized = true;

    const driversById = driversByIdForLayerSelector(state);
    const fieldSpecsById = businessObjectFieldSpecByIdSelector(state, {
      layerId: DEFAULT_LAYER_ID,
    });
    Object.values(driversById).forEach((driver) => {
      if (driver != null) {
        this.updateDriverFormat(state, driver);
      }
    });

    Object.values(fieldSpecsById).forEach((fieldSpec) => {
      if (fieldSpec != null) {
        this.updateBusinessObjectFieldSpecFormat(state, fieldSpec);
      }
    });
  }
}

export const AutoFormatCacheSingleton = new AutoFormatCache();
