import { groupBy, isString } from 'lodash';
import lodashIntersection from 'lodash/intersection';
import memoize from 'lodash/memoize';
import orderBy from 'lodash/orderBy';
import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import { DateTime } from 'luxon';

import {
  AiEventInput,
  AiObjectFieldValueInput,
  EventUpdateInput,
  ImpactType,
  TimeSeriesPointInput,
  ValueType,
} from 'generated/graphql';
import {
  extractMonthKey,
  formatISOWithoutMs,
  getDateTimeFromMonthKey,
  getMonthKeysForRange,
  shortMonthFormat,
} from 'helpers/dates';
import { getAttributeValueString } from 'helpers/dimensionalDrivers';
import { formatDriverValue } from 'helpers/formatting';
import { convertTimeSeriesToGql, getNumericMonthValue } from 'helpers/gqlDataset';
import { isNotNull, isNull } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import {
  BusinessObjectFieldSpec,
  BusinessObjectFieldSpecId,
  BusinessObjectSpec,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { BusinessObject, BusinessObjectFieldId } from 'reduxStore/models/businessObjects';
import { Attribute, AttributeId } from 'reduxStore/models/dimensions';
import { DriverId } from 'reduxStore/models/drivers';
import {
  CurveType,
  DeltaDriverEvent,
  DeltaImpact,
  DriverEvent,
  Event,
  EventGroupId,
  EventId,
  ObjectFieldEvent,
  PopulatedEventGroup,
  isDeltaImpactEvent,
  isDriverEvent,
  isObjectFieldEvent,
  isSetImpactEvent,
} from 'reduxStore/models/events';
import {
  ValueTimeSeries,
  ValueTimeSeriesWithEmpty,
  getLastValueInTimeSeries,
  valueTimeSeriesToInput,
} from 'reduxStore/models/timeSeries';
import { DisplayConfiguration, NullableValue, Value, toNumberValue } from 'reduxStore/models/value';
import { EventLiveEdit, EventLiveUpdate, LiveEditImpact } from 'reduxStore/reducers/liveEditSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { businessObjectSpecsByIdForLayerSelector } from 'selectors/businessObjectSpecsSelector';
import { businessObjectsByFieldIdForLayerSelector } from 'selectors/businessObjectsSelector';
import { ISOTime, MonthKey } from 'types/datetime';

export type Range = { start: ISOTime; end: ISOTime };

export const getOriginalImpact = (liveEdit: EventLiveEdit, monthKey: string): number => {
  if (isDeltaImpactEvent(liveEdit.event)) {
    if (liveEdit.event.curveType === CurveType.Custom) {
      return getNumericMonthValue(liveEdit.originalCustomCurvePoints, monthKey);
    }
  }

  return liveEdit.originalTotalImpact ?? 0;
};

export function isEventSettingStartDate(state: RootState, event: Event): boolean {
  if (!isObjectFieldEvent(event)) {
    return false;
  }

  const { businessObjectFieldId } = event;
  const specs = businessObjectSpecsByIdForLayerSelector(state);
  const objectsByFieldId = businessObjectsByFieldIdForLayerSelector(state);
  const businessObject = objectsByFieldId[businessObjectFieldId];
  const field = businessObject.fields.find((f) => f.id === businessObjectFieldId);
  const spec = specs[businessObject.specId];
  const specField = spec.fields.find((f) => f.id === field?.fieldSpecId);

  if (specField == null || field == null) {
    return false;
  }

  return specField.id === field.fieldSpecId && specField.type === ValueType.Timestamp;
}

/**
 * Returns a displayable string from the formatted version of the input Value.
 *
 * @param impact The Value to be formatted
 * @param displaytConfig
 * @param opts Other optional configurations that aren't saved to an entity
 * @param attributesById All attributes in the dataset, required to map any AttributeValue into the attribute's name.
 * @param impactType The type of impact - this determines if the display string is shown as a delta.
 * @returns
 */
export function getDisplayStringForImpact(
  impact: NullableValue,
  displayConfig: DisplayConfiguration,
  {
    abbreviate = false,
    includeCents = false,
  }: { abbreviate?: boolean; includeCents?: boolean } = {},
  attributesById: Record<AttributeId, Attribute>,
  impactType: ImpactType,
): string {
  if (impact.value == null) {
    return '';
  }
  let impactDisplayValue;
  switch (impact.type) {
    case ValueType.Number: {
      const value = formatDriverValue(impact.value, displayConfig, {
        abbreviate,
        includeCents,
      });
      impactDisplayValue =
        impact.value >= 0 && impactType === ImpactType.Delta ? '+' + value : value;
      break;
    }
    case ValueType.Timestamp: {
      impactDisplayValue = shortMonthFormat(DateTime.fromISO(impact.value));
      break;
    }
    case ValueType.Attribute: {
      const attributeId = impact.value;
      const displayAttribute = attributeId != null ? attributesById[attributeId] : undefined;
      impactDisplayValue =
        displayAttribute != null ? getAttributeValueString(displayAttribute) : '';
      break;
    }
    default:
      impactDisplayValue = '';
  }
  return impactDisplayValue;
}

function getBeforeAfterDisplayStringForImpact(
  beforeValue: number,
  afterValue: number,
  displayConfig: DisplayConfiguration,
  {
    abbreviate = false,
    includeCents = false,
  }: { abbreviate?: boolean; includeCents?: boolean } = {},
) {
  const firstValue = formatDriverValue(beforeValue, displayConfig, {
    abbreviate,
    includeCents,
  });
  const secondValue = formatDriverValue(afterValue, displayConfig, {
    abbreviate,
    includeCents,
  });

  return `${firstValue} -> ${secondValue}`;
}

export function getTotalImpactDisplay(
  totalImpact: Value,
  displayConfig: DisplayConfiguration,
  attributesById: Record<AttributeId, Attribute>,
  impactType: ImpactType,
) {
  const fullDisplayString = getDisplayStringForImpact(
    totalImpact,
    displayConfig,
    { abbreviate: false },
    attributesById,
    impactType,
  );

  const abbreviatedDisplayString = getDisplayStringForImpact(
    totalImpact,
    displayConfig,
    { abbreviate: true },
    attributesById,
    impactType,
  );
  return { fullDisplayString, abbreviatedDisplayString };
}

export function getBeforeAfterImpactDisplay(
  beforeValue: number,
  afterValue: number,
  displayConfig: DisplayConfiguration,
) {
  const fullDisplayString = getBeforeAfterDisplayStringForImpact(
    beforeValue,
    afterValue,
    displayConfig,
    {
      abbreviate: false,
    },
  );

  const abbreviatedDisplayString = getBeforeAfterDisplayStringForImpact(
    beforeValue,
    afterValue,
    displayConfig,
    { abbreviate: true },
  );
  return { fullDisplayString, abbreviatedDisplayString };
}

/**
 * Returns the event impact for a given month or undefined if the given month
 * is not affected by this event.
 *
 * TODO (T-7159): merge this with #getDeltaMonthImpact(Event, MonthKey) once all usages are migrated.
 */
export function getMonthValueImpact(event: Event, monthKey: MonthKey): Value | undefined {
  if (isSetImpactEvent(event)) {
    if (event.customCurvePoints == null) {
      return undefined;
    }
    const monthImpact = event.customCurvePoints[monthKey];
    if (monthImpact != null) {
      return monthImpact;
    }
  } else if (isDeltaImpactEvent(event)) {
    const numImpact = getDeltaMonthImpact(event, monthKey);
    return numImpact == null ? numImpact : toNumberValue(numImpact);
  }
  return undefined;
}

/**
 * Returns the event impact for a given month or undefined if the given month
 * is not affected by this event.
 *
 * @deprecated Use #getMonthValueImpact(Event, MonthKey) instead
 */
export function getDeltaMonthImpact(
  event: DeltaDriverEvent,
  monthKey: MonthKey,
): number | undefined {
  const monthKeys = getMonthKeysForRange(
    DateTime.fromISO(event.start),
    DateTime.fromISO(event.end),
  );

  if (event.curveType === CurveType.Custom) {
    if (event.customCurvePoints == null) {
      return 0;
    }
    const monthImpact = event.customCurvePoints[monthKey];
    if (monthImpact != null) {
      return monthImpact.type === ValueType.Number ? monthImpact.value : 0;
    }
  } else if (event.totalImpact != null) {
    const monthIndex = monthKeys.indexOf(monthKey);
    // Month requested is not impacted by event
    if (monthIndex === -1) {
      return undefined;
    }
    switch (event.curveType) {
      case CurveType.Linear: {
        return event.totalImpact / monthKeys.length;
      }
      case CurveType.Logarithmic: {
        const base = solveForLogBase(event.totalImpact, monthKeys.length);
        return getLogStep(base, monthIndex);
      }
      case CurveType.Exponential: {
        const base = solveForExponentialBase(event.totalImpact, monthKeys.length);
        return getExponentialStep(base, monthIndex);
      }
      default:
        return undefined;
    }
  }
  return undefined;
}

export function getTotalDeltaImpact(event: DriverEvent & DeltaImpact) {
  if (event.curveType === CurveType.Custom && event.customCurvePoints) {
    return toNumberValue(
      sum(
        Object.keys(event.customCurvePoints).map((key) =>
          getNumericMonthValue(event.customCurvePoints, key),
        ),
      ),
    );
  }
  return toNumberValue(event.totalImpact ?? 0);
}

export function getTotalMagnitude(event: DriverEvent & DeltaImpact) {
  if (event.curveType === CurveType.Custom && event.customCurvePoints) {
    return sum(
      Object.values(event.customCurvePoints).map((point) =>
        point.type === ValueType.Number ? Math.abs(point.value) : 0,
      ),
    );
  }
  return event.totalImpact;
}

export function getShiftedStartEnd(
  event: { start: ISOTime; end: ISOTime },
  numMonthsMoved: number,
) {
  const startDateTime = DateTime.fromISO(event.start);
  const endDateTime = DateTime.fromISO(event.end);
  const newStart = formatISOWithoutMs(startDateTime.plus({ months: numMonthsMoved }));
  const newEnd = formatISOWithoutMs(endDateTime.plus({ months: numMonthsMoved }));
  return { newStart, newEnd };
}

export function getShiftedCustomCurve(event: Event, numMonthsMoved: number) {
  let shiftedCurveInput: TimeSeriesPointInput[] | undefined;
  const dateShiftFn: (dt: DateTime) => DateTime = (dt) => dt.plus({ months: numMonthsMoved });
  if (isDeltaImpactEvent(event)) {
    if (event.curveType !== CurveType.Custom || event.customCurvePoints == null) {
      return [];
    }
    shiftedCurveInput = convertTimeSeriesToGql(event.customCurvePoints, dateShiftFn);
  } else {
    shiftedCurveInput = convertTimeSeriesToGql(event.customCurvePoints, dateShiftFn);
  }

  return shiftedCurveInput;
}

export function getUpdateFromEvents(
  originalEvent: Event,
  updatedEvent: Event,
): { update: EventUpdateInput } | null {
  if (isDriverEvent(originalEvent) && isDriverEvent(updatedEvent)) {
    return getUpdateFromDriverEvents(originalEvent, updatedEvent);
  } else if (isObjectFieldEvent(originalEvent) && isObjectFieldEvent(updatedEvent)) {
    return getUpdateFromObjectFieldEvents(originalEvent, updatedEvent);
  }
  return null;
}

function getUpdateFromObjectFieldEvents(
  originalEvent: ObjectFieldEvent,
  updatedEvent: ObjectFieldEvent,
): { update: EventUpdateInput } | null {
  const update: EventUpdateInput = {
    id: originalEvent.id,
    businessObjectFieldId:
      originalEvent.businessObjectFieldId !== updatedEvent.businessObjectFieldId
        ? updatedEvent.businessObjectFieldId
        : undefined,
    name: originalEvent.name !== updatedEvent.name ? updatedEvent.name : undefined,
    start: originalEvent.start === updatedEvent.start ? undefined : updatedEvent.start,
    end: originalEvent.end === updatedEvent.end ? undefined : updatedEvent.end,
    hidden: originalEvent.hidden !== updatedEvent.hidden ? updatedEvent.hidden : undefined,
    ownerName:
      originalEvent.ownerName !== updatedEvent.ownerName ? updatedEvent.ownerName : undefined,
    setValueType:
      originalEvent.valueType !== updatedEvent.valueType ? updatedEvent.valueType : undefined,
    customCurvePoints: valueTimeSeriesToInput(updatedEvent.customCurvePoints),
  };
  update.impactType =
    (update.customCurvePoints ?? []).length > 0 ? updatedEvent.impactType : undefined;

  if (Object.values(update).every((v) => v == null)) {
    return null;
  }

  return { update };
}

function getUpdateFromDriverEvents(
  originalEvent: DriverEvent,
  updatedEvent: DriverEvent,
): { update: EventUpdateInput } | null {
  const update: EventUpdateInput = {
    id: originalEvent.id,
    driverId: originalEvent.driverId !== updatedEvent.driverId ? updatedEvent.driverId : undefined,
    name: originalEvent.name !== updatedEvent.name ? updatedEvent.name : undefined,
    start: originalEvent.start === updatedEvent.start ? undefined : updatedEvent.start,
    end: originalEvent.end === updatedEvent.end ? undefined : updatedEvent.end,
    customCurvePoints: valueTimeSeriesToInput(updatedEvent.customCurvePoints),
    hidden: originalEvent.hidden !== updatedEvent.hidden ? updatedEvent.hidden : undefined,
    ownerName:
      originalEvent.ownerName !== updatedEvent.ownerName ? updatedEvent.ownerName : undefined,
  };
  const changedValue =
    (update.customCurvePoints ?? []).length > 0 ||
    update.totalImpact != null ||
    update.curveType != null;
  update.impactType =
    originalEvent.impactType !== updatedEvent.impactType || changedValue
      ? updatedEvent.impactType
      : undefined;
  if (isDeltaImpactEvent(originalEvent)) {
    if (isDeltaImpactEvent(updatedEvent)) {
      update.curveType =
        originalEvent.curveType !== updatedEvent.curveType ? updatedEvent.curveType : undefined;
      update.totalImpact =
        originalEvent.totalImpact !== updatedEvent.totalImpact && updatedEvent.totalImpact != null
          ? String(updatedEvent.totalImpact)
          : undefined;
    }
  } else if (isDeltaImpactEvent(updatedEvent)) {
    update.curveType = updatedEvent.curveType;
    update.totalImpact =
      updatedEvent.totalImpact != null ? String(updatedEvent.totalImpact) : undefined;
  }

  if (Object.values(update).every((v) => v == null)) {
    return null;
  }

  return { update };
}

export function getCustomCurvePoints(event: DriverEvent): ValueTimeSeries {
  const eventStartDate = DateTime.fromISO(event.start);
  const eventEndDate = DateTime.fromISO(event.end);
  const customCurvePoints: ValueTimeSeries = {};
  const monthKeys = getMonthKeysForRange(eventStartDate, eventEndDate);
  monthKeys.forEach((mk) => {
    const monthImpact = getMonthValueImpact(event, mk);
    if (monthImpact != null) {
      customCurvePoints[mk] = monthImpact;
    }
  });

  return customCurvePoints;
}

export function getCustomCurvePointsTimeRange(customCurvePoints: ValueTimeSeriesWithEmpty) {
  const times = Object.keys(customCurvePoints).map((mk) => getDateTimeFromMonthKey(mk));
  return [
    DateTime.min(...times).toISO(),
    DateTime.max(...times)
      .endOf('month')
      .startOf('second')
      .toISO(),
  ];
}

export function updateEventTimesFromCustomCurve(event: Event) {
  if (event.customCurvePoints == null || Object.keys(event.customCurvePoints).length === 0) {
    return;
  }

  const [start, end] = getCustomCurvePointsTimeRange(event.customCurvePoints);
  event.start = start;
  event.end = end;
}

export function removeCustomCurvePointsOutsideEventTime(event: Event) {
  const monthKeys = Object.keys(event.customCurvePoints ?? {});
  const startMonthKey = extractMonthKey(event.start);
  const endMonthKey = extractMonthKey(event.end);

  monthKeys.forEach((mk) => {
    if (mk < startMonthKey || mk > endMonthKey) {
      delete event.customCurvePoints?.[mk];
    }
  });
}

export function updateEvent(event: Event, update: EventLiveUpdate) {
  event.version = uuidv4();
  if (update.name != null) {
    event.name = update.name;
  }

  if (isDeltaImpactEvent(event)) {
    if (update.driverId != null) {
      event.driverId = update.driverId;
    }

    if (update.curveType != null) {
      if (
        update.curveType === CurveType.Custom &&
        event.curveType !== CurveType.Custom &&
        update.customCurvePoints == null
      ) {
        event.customCurvePoints = getCustomCurvePoints(event);
      }
      if (
        update.curveType !== CurveType.Custom &&
        event.curveType === CurveType.Custom &&
        update.totalImpact == null &&
        event.customCurvePoints
      ) {
        event.totalImpact = getTotalDeltaImpact(event).value ?? 0;
      }
      event.curveType = update.curveType;
    }

    if (update.totalImpact != null) {
      if (event.curveType === CurveType.Custom && event.customCurvePoints) {
        const totalImpact = getTotalDeltaImpact(event).value ?? 0;
        const diff = update.totalImpact - totalImpact;
        // Distribute delta over the curve
        if (totalImpact !== 0 && diff !== 0) {
          const updatedPoints: ValueTimeSeries = {};
          Object.entries(event.customCurvePoints).forEach(([monthKey, monthValue]) => {
            if (monthValue.type === ValueType.Number) {
              const proportion = monthValue.value / totalImpact;
              updatedPoints[monthKey] = toNumberValue(monthValue.value + proportion * diff);
            }
          });
          event.customCurvePoints = updatedPoints;
        }
      } else {
        event.totalImpact = update.totalImpact;
      }
    }
  }

  if (update.customCurvePoints != null) {
    event.customCurvePoints = update.customCurvePoints;
    updateEventTimesFromCustomCurve(event);
  }

  if (update.start != null) {
    event.start = update.start;
  }

  if (update.end != null) {
    event.end = update.end;
  }

  // Cleanup
  if (isDeltaImpactEvent(event)) {
    if (event.curveType === CurveType.Custom) {
      event.totalImpact = undefined;
    } else {
      event.customCurvePoints = undefined;
    }
  }

  if (update.ownerName != null) {
    event.ownerName = update.ownerName;
  }
}

// Find the logarithmic curve which starts at (0,0) and ends at (numMonths, change)
const solveForLogBase = memoize(
  (change: number, months: number): number => {
    return Math.pow(months + 1, 1 / change);
  },
  (change: number, months: number) => `${change} over ${months}`,
);

// For logarithmic curve which starts at (0,0) and ends at (numMonths, change)
// finds delta in y between point at x and point at x-1
function getLogStep(base: number, x: number): number {
  const prevValue = x === 0 ? 0 : Math.log(x + 1) / Math.log(base);
  const currValue = Math.log(x + 2) / Math.log(base);
  return currValue - prevValue;
}

// Find the exponential curve which starts at (0,0) and ends at (numMonths, change)
const solveForExponentialBase = memoize(
  (change: number, months: number): number => Math.pow(1 + change, 1 / months),
  (change: number, months: number) => `${change} over ${months}`,
);

// For exponential curve which starts at (0,0) and ends at (numMonths, change)
// finds delta in y between point at x and point at x-1
function getExponentialStep(base: number, x: number): number {
  const prevValue = x === 0 ? 0 : Math.pow(base, x) - 1;
  const currValue = Math.pow(base, x + 1) - 1;
  return currValue - prevValue;
}

// Returns the common parent in the tree for all items given a list of parents
// If there is more than one common parent found, choose the lowest (farthest from root) parent
export function getCommonAncestor(
  parentIds: Array<EventGroupId | undefined>,
  populatedEventGroupsById: NullableRecord<EventGroupId, PopulatedEventGroup>,
  parentsToExclude: EventGroupId[],
): EventGroupId | undefined {
  // If any of the items are root items there is not a common parent
  if (parentIds.some(isNull)) {
    return undefined;
  }

  // Any group which is common to all items will appear in every path to root
  // Count the times each group is found in a path to root
  const parentsCounts: Record<EventGroupId, number> = {};
  const rootPathCache: Record<EventGroupId, EventGroupId[]> = {};
  parentIds.forEach((parentId) => {
    const path = getPathToRoot(parentId, populatedEventGroupsById, rootPathCache);
    path.forEach((groupId) => {
      const currCount = parentsCounts[groupId] ?? 0;
      parentsCounts[groupId] = currCount + 1;
    });
  });

  // Filter only those ids which appear in every path
  // If more than one common parent is found, choose the lowest.
  let lowestCommonAncestor: EventGroupId | undefined;
  const max = 0;
  Object.entries(parentsCounts)
    .filter(([id, count]) => count === parentIds.length && !parentsToExclude.includes(id))
    .forEach(([id, _count]) => {
      if (rootPathCache[id].length > max) {
        lowestCommonAncestor = id;
      }
    });
  return lowestCommonAncestor;
}

// Gets path to root for a group. Saves it to the cache
function getPathToRoot(
  groupId: EventGroupId | undefined,
  populatedEventGroupsById: NullableRecord<EventGroupId, PopulatedEventGroup>,
  rootPathCache?: Record<EventGroupId, EventGroupId[]>,
): EventGroupId[] {
  if (groupId == null) {
    return [];
  }
  if (rootPathCache?.[groupId] != null) {
    return rootPathCache[groupId];
  }

  const currGroup: PopulatedEventGroup | undefined = populatedEventGroupsById[groupId];
  let path: EventGroupId[] = [];
  if (currGroup == null || currGroup.parentId == null) {
    path = [groupId];
  } else {
    path = [...getPathToRoot(currGroup.parentId, populatedEventGroupsById, rootPathCache)];
    path.push(groupId);
  }

  if (rootPathCache) {
    rootPathCache[groupId] = path;
  }
  return path;
}

export function getEventImpactOnMonth(
  event: Event,
  monthKey: MonthKey,
  ignoreEventIds: Set<EventId> | undefined,
  isStartField: boolean,
): Value | undefined {
  const ignored = ignoreEventIds != null && ignoreEventIds.has(event.id);
  if (ignored) {
    return undefined;
  }

  // Start date event should impact values at all time
  if (isStartField && isSetImpactEvent(event)) {
    return getLastValueInTimeSeries(event.customCurvePoints);
  }

  if (monthKey >= extractMonthKey(event.start) && monthKey <= extractMonthKey(event.end)) {
    return getMonthValueImpact(event, monthKey);
  }
  return undefined;
}

export function getImpactTypeForDriver(
  eventsByDriverId: Record<DriverId, DriverEvent[]>,
  driverId: DriverId,
) {
  const impactTypes: ImpactType[] = eventsByDriverId[driverId]?.map((ev) => ev.impactType);
  const eventTypes = uniq(impactTypes);
  if (eventTypes.length !== 1) {
    return undefined;
  }
  return eventTypes[0];
}

// If an event group is associated with one of the selected month keys, it will appear at the top of the list
// and default group at the bottom. If no event group is associated, the default group will be at the top.
export function getSortedEventGroupsForEntityAtMonthKeys(
  eventsByEntityId: NullableRecord<string, Event[]>,
  entityIds: Array<DriverId | BusinessObjectFieldId>,
  selectedMonthKeys: MonthKey[],
  defaultEventGroupId?: EventGroupId,
): string[] {
  const eventsWithoutDefault = entityIds
    .flatMap((entityId) => eventsByEntityId[entityId] ?? [])
    .filter((event) => event.parentId !== defaultEventGroupId);

  // There can be multiple events for the same parent and entity, we want to consider all of them
  const eventsWithoutDefaultByParentId = groupBy(eventsWithoutDefault, (event) => event.parentId);

  let selectedMonthKeysHasCurvePoint = false;
  // Sort by which selected month key the events has a curve point for (e.g. if it has a curve point
  // for the first selected month key, it will be first in the list)
  const sortedEventsWithoutDefault = orderBy(
    Object.values(eventsWithoutDefaultByParentId),
    (events) => {
      const eventMonthKeys = events.flatMap((event) =>
        event.customCurvePoints != null ? Object.keys(event.customCurvePoints) : [],
      );
      const intersection = lodashIntersection(selectedMonthKeys, eventMonthKeys);
      if (intersection.length === 0) {
        return Number.MAX_SAFE_INTEGER;
      }

      selectedMonthKeysHasCurvePoint = true;
      return selectedMonthKeys.indexOf(intersection[0]);
    },
  );

  const sortedEventGroupIdsWithoutDefault = sortedEventsWithoutDefault
    .map((events) => {
      // All these events have the same parent event group, we just use the first
      return events[0]?.parentId ?? null;
    })
    .filter(isNotNull);

  if (defaultEventGroupId == null) {
    return sortedEventGroupIdsWithoutDefault;
  }

  if (selectedMonthKeysHasCurvePoint) {
    return [...sortedEventGroupIdsWithoutDefault, defaultEventGroupId];
  }

  return [defaultEventGroupId, ...sortedEventGroupIdsWithoutDefault];
}

export function eventToAiEventInput(
  event: Event,
  {
    resolvedDriverNamesById,
    attributesById,
    businessObjectsByFieldId,
    businessObjectFieldSpecById,
    businessObjectSpecsById,
  }: {
    resolvedDriverNamesById: Record<DriverId, string>;
    attributesById: Record<AttributeId, Attribute>;
    businessObjectsByFieldId: Record<BusinessObjectFieldId, BusinessObject>;
    businessObjectSpecsById: Record<BusinessObjectSpecId, BusinessObjectSpec | undefined>;
    businessObjectFieldSpecById: Record<
      BusinessObjectFieldSpecId,
      BusinessObjectFieldSpec | undefined
    >;
  },
): AiEventInput | null {
  const customCurvePoints = convertTimeSeriesToGql(event.customCurvePoints);
  if (customCurvePoints == null || customCurvePoints.length === 0) {
    return null;
  }

  let driver: AiEventInput['driver'];
  let object: AiEventInput['object'];
  if (isDriverEvent(event)) {
    driver = {
      id: event.driverId,
      name: resolvedDriverNamesById[event.driverId],
    };
  } else if (isObjectFieldEvent(event)) {
    const { businessObjectFieldId } = event;
    const monthKey = extractMonthKey(customCurvePoints[0].time);
    const businessObject = businessObjectsByFieldId[businessObjectFieldId];
    if (businessObject != null) {
      const field = businessObject.fields.find((f) => f.id === businessObjectFieldId);
      const fieldSpec = field != null ? businessObjectFieldSpecById[field.fieldSpecId] : null;
      const objectFields = businessObject.fields
        .map<AiObjectFieldValueInput | null>((f) => {
          const spec = businessObjectFieldSpecById[f.fieldSpecId];
          // only include attribute types that are not the current field as additional context
          if (
            spec == null ||
            spec.id === fieldSpec?.id ||
            spec.isFormula ||
            spec.type !== ValueType.Attribute
          ) {
            return null;
          }

          const actualsValue = f.value?.actuals.timeSeries?.[monthKey]?.value;
          const attributeId =
            actualsValue != null && isString(actualsValue) ? actualsValue : f.value?.initialValue;
          const attributeValue = attributeId != null ? attributesById[attributeId]?.value : null;
          if (!isString(attributeValue)) {
            return null;
          }

          return {
            fieldName: spec.name,
            fieldValue: attributeValue,
          };
        })
        .filter(isNotNull);

      if (fieldSpec != null) {
        object = {
          objectSpecName: businessObjectSpecsById[businessObject.specId]?.name ?? '',
          objectId: businessObject.id,
          objectName: businessObject.name,
          fieldSpecId: fieldSpec.id,
          fieldName: fieldSpec.name,
          objectFields,
        };
      }
    }
  }

  return {
    id: event.id,
    driver,
    object,
    impactType: event.impactType,
    customCurvePoints,
  };
}

export function getImpactedEntityId(event: Event) {
  return isDriverEvent(event) ? event.driverId : event.businessObjectFieldId;
}

export function augmentValueWithLiveEditImpact(
  value: Value,
  liveEditImpact: LiveEditImpact | undefined,
): Value {
  if (liveEditImpact == null) {
    return value;
  }

  if (liveEditImpact.type === ImpactType.Set) {
    return toNumberValue(liveEditImpact.value);
  }

  // Delta
  if (value.type === ValueType.Number) {
    return {
      type: ValueType.Number,
      value: value.value + liveEditImpact.value,
    };
  }

  return value;
}
