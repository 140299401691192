import { Draft } from '@reduxjs/toolkit';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';

import { getSubmodelIdFromInternalPageType } from 'config/internalPages/modelPage';
import {
  BuiltInAttributeInput,
  DatasetMutationInput,
  DimensionalSubDriverCreateInput,
  DimensionalSubDriverSourceInput,
  Driver,
  DriverCreateInput,
  DriverDeleteInput,
  DriverFormat,
  DriverPivot,
  DriverPivotInput,
  DriverReference,
  DriverType,
  DriverUpdateInput,
  EventDeleteInput,
  MilestoneDeleteInput,
  ValueType,
} from 'generated/graphql';
import { extractMonthKey, getISOTimeWithoutMsFromMonthKey } from 'helpers/dates';
import { builtInDimensionToName, getSubdriverKey } from 'helpers/dimensionalDrivers';
import { convertTimeSeries } from 'helpers/gqlDataset';
import { computeFreshSortIndexUpdates } from 'helpers/reorderList';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import { BlockId } from 'reduxStore/models/blocks';
import { Coloring } from 'reduxStore/models/common';
import { DatasetSnapshot } from 'reduxStore/models/dataset';
import {
  Attribute,
  AttributeId,
  Dimension,
  DimensionId,
  UserAddedDimensionType,
} from 'reduxStore/models/dimensions';
import {
  BasicDriver,
  CommonDriver,
  DimensionalDriver,
  DimensionalSubDriver,
  DimensionalSubDriverSource,
  DriverId,
  NormalizedDimensionalDriver,
  NormalizedDimensionalSubDriver,
} from 'reduxStore/models/drivers';
import { isDriverEvent } from 'reduxStore/models/events';
import { DEFAULT_LAYER_ID, DefaultLayer, LightLayer } from 'reduxStore/models/layers';
import { SubmodelId } from 'reduxStore/models/submodels';
import {
  NumericTimeSeriesWithEmpty,
  valueToNonNumericTimeSeries,
  valueToNumericTimeSeries,
} from 'reduxStore/models/timeSeries';
import { DatasetSliceState } from 'reduxStore/reducers/datasetSlice';
import {
  addAttributesToLayer,
  attributeCreateInputToAttribute,
  builtInAttributeToAttribute,
  handleCreateDimension,
} from 'reduxStore/reducers/helpers/dimensions';
import { handleDeleteEvents } from 'reduxStore/reducers/helpers/events';
import { handleDeleteMilestones } from 'reduxStore/reducers/helpers/milestones';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { driversByIdForLayerSelector } from 'selectors/driversSelector';
import { datasetLastActualsMonthKeySelector } from 'selectors/lastActualsSelector';

export function handleCreateDrivers(
  layer: Draft<LightLayer>,
  defaultLayer: Draft<DefaultLayer>,
  newDriverInputs: DriverCreateInput[],
) {
  if (newDriverInputs.length === 0) {
    return;
  }

  const isCreatingDimensionalDrivers = newDriverInputs.some(
    (input) => input.driverType === DriverType.Dimensional,
  );
  if (!isCreatingDimensionalDrivers) {
    newDriverInputs.forEach((newDriverInput) => handleCreateBasicDriverImpl(layer, newDriverInput));
  } else {
    const dimDriverBySubdriverId = computeDimDriverBySubdriverIdIndex(layer);
    newDriverInputs.forEach((newDriverInput) =>
      handleCreateDriver(layer, defaultLayer, newDriverInput, dimDriverBySubdriverId),
    );
  }
}

function handleCreateDriver(
  layer: Draft<LightLayer>,
  defaultLayer: Draft<DefaultLayer>,
  newDriverInput: DriverCreateInput,
  dimDriverBySubdriverId: Record<string, NormalizedDimensionalDriver | undefined>,
) {
  const { driverType } = newDriverInput;
  if (driverType === DriverType.Basic) {
    handleCreateBasicDriverImpl(layer, newDriverInput);
  } else if (driverType === DriverType.Dimensional) {
    handleCreateDimensionalDriverImpl(layer, defaultLayer, newDriverInput, dimDriverBySubdriverId);
  }
}

function handleCreateBasicDriverImpl(layer: Draft<LightLayer>, newDriverInput: DriverCreateInput) {
  const basicDriver = driverCreateInputToBasicDriver(newDriverInput);
  if (basicDriver == null) {
    return;
  }
  layer.drivers.byId[basicDriver.id] = basicDriver;
  layer.drivers.allIds.push(basicDriver.id);
}

function mapPivotByInputToPivotBy(pivotBy?: null | DriverPivotInput[]): DriverPivot[] {
  return (pivotBy ?? []).map(({ dimensionId, driverPropertyIds }) => ({
    dimensionId,
    driverPropertyIds: driverPropertyIds ?? [],
  }));
}

function driverCreateInputToBasicDriver(newDriverInput: DriverCreateInput) {
  const {
    id,
    name,
    format,
    currencyISOCode,
    coloring,
    decimalPlaces,
    driverType,
    // eslint-disable-next-line deprecation/deprecation
    submodelId,
    leverType,
    // eslint-disable-next-line deprecation/deprecation
    sortIndex,
    description,
    // eslint-disable-next-line deprecation/deprecation
    groupId,
    basic,
    valueTypeExtension,
    pivotBy,
    driverReferences,
  } = newDriverInput;
  if (driverType !== DriverType.Basic || basic == null || name.length === 0) {
    return null;
  }

  const { forecast, actuals, rollup } = basic;

  const actualsValueTimeSeries =
    actuals.timeSeries != null
      ? convertTimeSeries(actuals.timeSeries, valueTypeExtension?.type ?? ValueType.Number)
      : {};

  const actualsTimeSeries =
    valueTypeExtension?.type === ValueType.Attribute ||
    valueTypeExtension?.type === ValueType.Timestamp
      ? valueToNonNumericTimeSeries(actualsValueTimeSeries)
      : valueToNumericTimeSeries(actualsValueTimeSeries);

  const driver: BasicDriver = {
    id,
    name,
    format,
    submodelId: submodelId ?? undefined,
    leverType,
    sortIndex,
    description,
    groupId,
    currencyISOCode,
    coloring: {
      row: coloring?.row ?? undefined,
      cells: convertTimeSeries(coloring?.cells || [], ValueType.Timestamp),
    },
    decimalPlaces,
    type: DriverType.Basic,
    forecast: {
      formula: forecast.formula,
    },
    actuals: {
      formula: actuals.formula ?? undefined,
      timeSeries: actualsTimeSeries,
    },
    rollup:
      rollup?.reducer != null
        ? {
            reducer: rollup.reducer,
          }
        : undefined,
    valueTypeExtension,
    pivotBy: mapPivotByInputToPivotBy(pivotBy),
    driverReferences,
  };

  return driver;
}

function handleCreateDimensionalDriverImpl(
  layer: Draft<LightLayer>,
  defaultLayer: Draft<DefaultLayer>,
  newDriverInput: DriverCreateInput,
  dimDriverBySubdriverId: Record<string, NormalizedDimensionalDriver | undefined>,
) {
  const subDriversToUnlinkFromParent = new Set<DriverId>();
  newDriverInput?.dimensional?.subDrivers?.forEach((sd) => {
    if (sd.existingDriverId != null) {
      subDriversToUnlinkFromParent.add(sd.existingDriverId);
    }
  });
  unlinkSubDriversFromParent(
    layer,
    dimDriverBySubdriverId,
    Array.from(subDriversToUnlinkFromParent),
  );

  const dimensionalDriver = driverCreateInputToDimensionalDriver(defaultLayer, newDriverInput);
  if (dimensionalDriver == null) {
    return;
  }

  if (newDriverInput.driverType !== DriverType.Dimensional || newDriverInput.dimensional == null) {
    return;
  }
  // create each subdriver and it to the layer
  newDriverInput.dimensional.subDrivers?.map((s) => {
    if (s.driver == null) {
      return;
    }
    // only support basic subdriver
    handleCreateBasicDriverImpl(layer, s.driver);
  });

  // Ensure all subdrivers have the correct name
  dimensionalDriver.subdrivers.forEach(({ driverId }) => {
    const driver = layer.drivers.byId[driverId];
    if (driver == null) {
      return;
    }
    driver.name = newDriverInput.name;
  });

  layer.drivers.byId[dimensionalDriver.id] = dimensionalDriver;
  layer.drivers.allIds.push(dimensionalDriver.id);
}

function driverCreateInputToDimensionalDriver(
  defaultLayer: Draft<DefaultLayer>,
  newDriverInput: DriverCreateInput,
) {
  const {
    id,
    name,
    format,
    driverType,
    // eslint-disable-next-line deprecation/deprecation
    submodelId,
    coloring,
    leverType,
    description,
    // eslint-disable-next-line deprecation/deprecation
    sortIndex,
    // eslint-disable-next-line deprecation/deprecation
    groupId,
    dimensional,
    valueTypeExtension,
    pivotBy,
    driverReferences,
    driverMapping,
  } = newDriverInput;
  if (driverType !== DriverType.Dimensional || dimensional == null) {
    return null;
  }
  const { dimensionIds, builtInDimensions, subDrivers, defaultForecast, defaultActuals } =
    dimensional;
  const dimensionalSubDrivers: NormalizedDimensionalSubDriver[] =
    subDrivers
      ?.map((s) => {
        // only support basic subdrivers for now
        if (s.driver != null && s.driver.driverType !== DriverType.Basic) {
          return null;
        }

        const driverId = s.driver != null ? s.driver.id : s.existingDriverId;
        if (driverId == null) {
          return null;
        }

        // Built in dimensions added with type as id
        // Built in attributes added with value as id
        s.builtInAttributes?.forEach((attr) => {
          handleCreateBuiltInAttribute(defaultLayer, attr);
        });

        let source: DimensionalSubDriverSource | null = null;
        if (s.source != null) {
          source = convertDimSubdriverSource(s.source);
        }

        return {
          attributeIds: getIdsForGQLSubdriver(s),
          driverId,
          source,
        };
      })
      .filter(isNotNull) ?? [];
  const driver: NormalizedDimensionalDriver = {
    id,
    name,
    format,
    leverType,
    description,
    sortIndex,
    groupId,
    coloring: {
      row: coloring?.row ?? undefined,
      cells: convertTimeSeries(coloring?.cells || [], ValueType.Timestamp),
    },
    submodelId: submodelId ?? undefined,
    type: DriverType.Dimensional,
    dimensionIds: [...(builtInDimensions ?? []), ...(dimensionIds ?? [])],
    subdrivers: dimensionalSubDrivers,
    defaultForecast: defaultForecast ?? undefined,
    defaultActuals: defaultActuals ?? undefined,
    valueTypeExtension: {
      type: valueTypeExtension?.type ?? ValueType.Number,
      numberTypeExtension: valueTypeExtension?.numberTypeExtension ?? undefined,
      attributeTypeExtension: valueTypeExtension?.attributeTypeExtension ?? undefined,
    },
    pivotBy: mapPivotByInputToPivotBy(pivotBy),
    driverReferences,
    driverMapping,
  };
  return driver;
}

function handleCreateBuiltInAttribute(
  defaultLayer: Draft<DefaultLayer>,
  attr: BuiltInAttributeInput,
) {
  let existingDimension = defaultLayer.dimensions.byId[attr.type];
  if (existingDimension == null) {
    handleCreateDimension(
      defaultLayer,
      { id: attr.type, name: builtInDimensionToName[attr.type] },
      attr.type,
    );
    existingDimension = defaultLayer.dimensions.byId[attr.type];
  }
  const includesAttr = existingDimension.attributeIds.includes(attr.value);
  if (!includesAttr) {
    const newAttr = builtInAttributeToAttribute(attr);
    addAttributesToLayer(defaultLayer, [newAttr]);
    existingDimension.attributeIds.push(newAttr.id);
    existingDimension.attributeIds = existingDimension.attributeIds.sort();
  }
}

export function handleUpdateDrivers(
  layer: Draft<LightLayer>,
  defaultLayer: Draft<DefaultLayer>,
  updateDriverInputs: DriverUpdateInput[],
) {
  if (updateDriverInputs.length === 0) {
    return;
  }

  const dimDriverBySubdriverId = computeDimDriverBySubdriverIdIndex(layer);
  updateDriverInputs.forEach((updateDriverInput) =>
    handleUpdateDriver(layer, defaultLayer, updateDriverInput, dimDriverBySubdriverId),
  );
}

function handleUpdateDriver(
  layer: Draft<LightLayer>,
  defaultLayer: Draft<DefaultLayer>,
  updateDriverInput: DriverUpdateInput,
  dimDriverBySubdriverId: Record<string, NormalizedDimensionalDriver | undefined>,
) {
  const {
    id,
    name,
    description,
    format,
    // eslint-disable-next-line deprecation/deprecation
    submodelId,
    // eslint-disable-next-line deprecation/deprecation
    groupId,
    leverType,
    // eslint-disable-next-line deprecation/deprecation
    sortIndex,
    currencyISOCode,
    coloring,
    decimalPlaces,
    driverReferences,
    driverMapping,
    removeSubmodelId,
    removeDriverMapping,
    removeAllDriverReferences,
  } = updateDriverInput;

  const existingDriver = layer.drivers.byId[id];
  if (existingDriver == null) {
    return;
  }

  if (name != null) {
    existingDriver.name = name;
    if (existingDriver.type === DriverType.Dimensional) {
      existingDriver.subdrivers.forEach((sd) => {
        layer.drivers.byId[sd.driverId].name = name;
      });
    }
  }
  if (description != null) {
    existingDriver.description = description;
  }

  if (format != null) {
    existingDriver.format = format;
  }

  if (currencyISOCode != null) {
    existingDriver.currencyISOCode = currencyISOCode;
  }

  if (coloring != null) {
    const newColoring: Coloring = {};

    if (coloring.row != null) {
      newColoring.row = coloring.row;
    }

    if (coloring.row === '') {
      newColoring.row = undefined;
    }

    if (coloring.cells != null) {
      newColoring.cells = convertTimeSeries(coloring.cells, ValueType.Timestamp);
    }

    existingDriver.coloring = newColoring;
  }

  if (decimalPlaces != null) {
    existingDriver.decimalPlaces = decimalPlaces < 0 ? null : decimalPlaces;
  }

  if (submodelId != null) {
    // eslint-disable-next-line deprecation/deprecation
    existingDriver.submodelId = submodelId;
  }

  // TODO: should this modify driver references?
  if (groupId != null) {
    if (groupId === '') {
      // eslint-disable-next-line deprecation/deprecation
      existingDriver.groupId = null;
    } else {
      // eslint-disable-next-line deprecation/deprecation
      existingDriver.groupId = groupId;
    }
  }

  if (leverType != null) {
    existingDriver.leverType = leverType;
  }
  if (sortIndex != null) {
    // eslint-disable-next-line deprecation/deprecation
    existingDriver.sortIndex = sortIndex;
  }

  if (driverReferences != null) {
    existingDriver.driverReferences = driverReferences;
  }
  if (driverMapping != null) {
    existingDriver.driverMapping = driverMapping;
  }

  if (removeSubmodelId != null && removeSubmodelId) {
    // eslint-disable-next-line deprecation/deprecation
    delete existingDriver.submodelId;
    // eslint-disable-next-line deprecation/deprecation
    delete existingDriver.groupId;
    // eslint-disable-next-line deprecation/deprecation
    delete existingDriver.sortIndex;
  }
  if (
    removeDriverMapping != null &&
    typeof removeDriverMapping === 'boolean' &&
    removeDriverMapping
  ) {
    delete existingDriver.driverMapping;
  }

  if (removeAllDriverReferences != null && removeAllDriverReferences) {
    // eslint-disable-next-line deprecation/deprecation
    delete existingDriver.submodelId;
    // eslint-disable-next-line deprecation/deprecation
    delete existingDriver.groupId;
    // eslint-disable-next-line deprecation/deprecation
    delete existingDriver.sortIndex;
    existingDriver.driverReferences = [];
  }

  handleUpdateBasicDriverFields(layer, updateDriverInput);
  handleUpdateDimensionalDriverFields(
    layer,
    defaultLayer,
    updateDriverInput,
    dimDriverBySubdriverId,
  );
}

const computeDimDriverBySubdriverIdIndex = (
  layer: Draft<LightLayer>,
): Record<DriverId, NormalizedDimensionalDriver | undefined> => {
  return layer.drivers.allIds.reduce(
    (acc, id) => {
      const driver = layer.drivers.byId[id];
      if (driver.type === DriverType.Dimensional) {
        driver.subdrivers.forEach((subDriver) => {
          acc[subDriver.driverId] = driver;
        });
      }
      return acc;
    },
    {} as Record<DriverId, NormalizedDimensionalDriver | undefined>,
  );
};

const unlinkSubDriversFromParent = (
  layer: Draft<LightLayer>,
  dimDriverBySubdriverId: Record<string, NormalizedDimensionalDriver | undefined>,
  subDriverIds: DriverId[],
) => {
  const driversToDelete: DriverDeleteInput[] = [];

  subDriverIds.forEach((subDriverId) => {
    const parentDimDriver = safeObjGet(dimDriverBySubdriverId[subDriverId]);

    if (parentDimDriver == null || parentDimDriver.type !== DriverType.Dimensional) {
      return;
    }

    const subDriverIdx = parentDimDriver.subdrivers.findIndex((s) => s.driverId === subDriverId);
    if (subDriverIdx < 0) {
      return;
    }

    parentDimDriver.subdrivers.splice(subDriverIdx, 1);
    // If after unlinking the only remaining driver is non-attribute driver, then unlink that too.
    if (parentDimDriver.subdrivers.length === 1) {
      const remainingSubDriver = parentDimDriver.subdrivers[0];
      if (remainingSubDriver.attributeIds.length === 0) {
        parentDimDriver.subdrivers.splice(0, 1);
      }
    }

    if (parentDimDriver.subdrivers.length === 0) {
      driversToDelete.push({ id: parentDimDriver.id });
    }
  });

  handleDeleteDrivers(layer, driversToDelete);
};

function deleteRelatedDriverEntities(layer: Draft<LightLayer>, inputs: DriverDeleteInput[]) {
  const driversToDelete = new Set(inputs.map((input) => input.id));

  const milestones = Object.values(layer.milestones.byId);
  const events = Object.values(layer.events.byId);
  const milestonesToDelete: MilestoneDeleteInput[] = [];
  driversToDelete.forEach((driverId) => {
    milestones.forEach((milestone) => {
      if (milestone.driverId === driverId) {
        milestonesToDelete.push({ id: milestone.id });
      }
    });
  });
  const eventsToDelete: EventDeleteInput[] = [];
  driversToDelete.forEach((driverId) => {
    events.forEach((event) => {
      if (isDriverEvent(event) && event.driverId === driverId) {
        eventsToDelete.push({ id: event.id });
      }
    });
  });

  handleDeleteMilestones(layer, milestonesToDelete);
  handleDeleteEvents(layer, eventsToDelete);
}

export function handleDeleteDrivers(layer: Draft<LightLayer>, inputs: DriverDeleteInput[]) {
  const driverIdsToDelete = new Set(inputs.map((input) => input.id));
  layer.drivers.allIds = layer.drivers.allIds.filter((id) => !driverIdsToDelete.has(id));

  const subDriversToUnlinkFromParent = new Set<DriverId>();
  const additionalDriverIdsToDelete: DriverDeleteInput[] = [];

  const driversById = layer.drivers.byId;
  driverIdsToDelete.forEach((driverId) => {
    const driver = driversById[driverId];
    // This is expected to happen sometimes as you may delete something in the
    // default layer and modify it in another layer. In the future we shouldn't
    // just ignore all existence checks and be more intelligent about when we
    // throw.
    if (driver == null) {
      return;
    }

    delete driversById[driverId];

    if (driver.type === DriverType.Dimensional) {
      driver.subdrivers.forEach((subdriver) => {
        additionalDriverIdsToDelete.push({ id: subdriver.driverId });
      });
    } else {
      subDriversToUnlinkFromParent.add(driverId);
    }

    layer.deletedIdentifiers[driverId] = driver.name;
  });

  deleteRelatedDriverEntities(layer, inputs);

  if (subDriversToUnlinkFromParent.size > 0) {
    const dimDriverBySubdriverId = computeDimDriverBySubdriverIdIndex(layer);
    unlinkSubDriversFromParent(
      layer,
      dimDriverBySubdriverId,
      Array.from(subDriversToUnlinkFromParent),
    );
  }

  if (additionalDriverIdsToDelete.length > 0) {
    handleDeleteDrivers(layer, additionalDriverIdsToDelete);
  }
}

function handleUpdateBasicDriverFields(
  layer: Draft<LightLayer>,
  updateDriverInput: DriverUpdateInput,
) {
  const { id, actuals, forecast } = updateDriverInput;
  const existingDriver = layer.drivers.byId[id];
  if (existingDriver == null || existingDriver.type !== DriverType.Basic) {
    return;
  }

  if (actuals != null) {
    if (actuals.formula != null) {
      if (actuals.formula === '') {
        existingDriver.actuals.formula = undefined;
      } else {
        existingDriver.actuals.formula = actuals.formula;
      }

      if (existingDriver.trackParentLayerFields == null) {
        existingDriver.trackParentLayerFields = {};
      }
      existingDriver.trackParentLayerFields.actualsFormula = false;
    }

    const lastCloseMonthKey =
      layer.lastActualsTime != null ? extractMonthKey(layer.lastActualsTime) : null;

    actuals.timeSeries?.forEach((point) => {
      if (existingDriver.actuals.timeSeries == null) {
        existingDriver.actuals.timeSeries = {};
      }

      const monthKey = extractMonthKey(point.time);
      if (lastCloseMonthKey != null && monthKey > lastCloseMonthKey) {
        return;
      }
      if (point.value == null) {
        delete existingDriver.actuals.timeSeries[monthKey];
      } else if (
        existingDriver.valueTypeExtension?.type == null ||
        existingDriver.valueTypeExtension?.type === ValueType.Number
      ) {
        existingDriver.actuals.timeSeries[monthKey] = Number(point.value);
      } else {
        existingDriver.actuals.timeSeries[monthKey] = point.value;
      }
    });
  }

  if (forecast != null) {
    existingDriver.forecast.formula = forecast.formula;
    if (existingDriver.trackParentLayerFields == null) {
      existingDriver.trackParentLayerFields = {};
    }
    existingDriver.trackParentLayerFields.forecastFormula = false;
  }

  if ('rollup' in updateDriverInput) {
    const { rollup } = updateDriverInput;
    if (rollup?.reducer == null) {
      delete existingDriver.rollup;
    } else {
      existingDriver.rollup = { reducer: rollup.reducer };
    }
  }

  if ('pivotBy' in updateDriverInput) {
    existingDriver.pivotBy = mapPivotByInputToPivotBy(updateDriverInput.pivotBy);
  }
}

function getIdsForGQLSubdriver(
  input: Pick<DimensionalSubDriverCreateInput, 'attributeIds' | 'builtInAttributes'>,
) {
  return [
    ...(input.attributeIds ?? []),
    ...(input.builtInAttributes?.map((value) => value.value) ?? []),
  ];
}

function handleUpdateDimensionalDriverFields(
  layer: Draft<LightLayer>,
  defaultLayer: Draft<DefaultLayer>,
  updateDriverInput: DriverUpdateInput,
  dimDriverBySubdriverId: Record<DriverId, NormalizedDimensionalDriver | undefined>,
) {
  const { id, newSubDrivers, dimensional } = updateDriverInput;
  const existingDriver = layer.drivers.byId[id];
  if (existingDriver == null || existingDriver.type !== DriverType.Dimensional) {
    return;
  }

  const attrById = defaultLayer.attributes.byId;

  if (newSubDrivers != null) {
    // SubDrivers that already have this driver as their parent
    const existingSubDriverIds = new Set();
    const newDimSubDrivers: NormalizedDimensionalSubDriver[] = newSubDrivers
      .map((sub) => {
        let driverId: DriverId | undefined;

        if (sub.existingDriverId != null && layer.drivers.byId[sub.existingDriverId] != null) {
          driverId = sub.existingDriverId;
          const parentDimDriverId = dimDriverBySubdriverId[driverId]?.id;
          if (id === parentDimDriverId) {
            existingSubDriverIds.add(driverId);
          } else {
            unlinkSubDriversFromParent(layer, dimDriverBySubdriverId, [sub.existingDriverId]);
          }
        } else {
          if (sub.driver == null) {
            return null;
          }
          // create subdriver
          handleCreateBasicDriverImpl(layer, sub.driver);
          driverId = sub.driver.id;
        }

        if (driverId == null) {
          return null;
        }

        sub.builtInAttributes?.forEach((attr) => {
          handleCreateBuiltInAttribute(defaultLayer, attr);
        });

        return {
          attributeIds: getIdsForGQLSubdriver(sub),
          driverId,
        };
      })
      .filter(isNotNull);

    // Ensure that the new subdrivers have the right name
    newDimSubDrivers.forEach(({ driverId }) => {
      const driver = layer.drivers.byId[driverId];
      if (driver == null) {
        return;
      }
      driver.name = existingDriver.name;
    });

    existingDriver.subdrivers = [
      ...existingDriver.subdrivers.filter((sd) => !existingSubDriverIds.has(sd.driverId)),
      ...newDimSubDrivers,
    ];
  }

  if (dimensional != null) {
    // update default forecast
    if (dimensional.defaultForecast != null) {
      existingDriver.defaultForecast = dimensional.defaultForecast ?? undefined;
    }
    if (dimensional.defaultActuals != null) {
      existingDriver.defaultActuals = dimensional.defaultActuals ?? undefined;
    }

    // update subdrivers
    if (dimensional.updateSubDrivers != null) {
      // Recomputing keys is much faster than doing iterative array comparisons.
      // Duplicate subdrivers can exist; hence, code defensively.
      const subDriverIdsByAttributesKey = existingDriver.subdrivers.reduce<
        Record<string, DriverId[]>
      >((out, { driverId, attributeIds }) => {
        const key = getSubdriverKey(attributeIds);
        out[key] ??= [];
        out[key].push(driverId);
        return out;
      }, {});

      const subDriverIdsToUnlink: DriverId[] = [];

      for (const input of dimensional.updateSubDrivers) {
        const { newAttributeIds, newBuiltInAttributes, removeAllAttributes, source, unlink } =
          input;
        const key = getSubdriverKey(getIdsForGQLSubdriver(input));
        const subDriverIds = subDriverIdsByAttributesKey[key];

        for (const subDriverId of subDriverIds) {
          const existingSubDriver = existingDriver.subdrivers.find(
            ({ driverId }) => driverId === subDriverId,
          );
          if (existingSubDriver == null) {
            continue;
          }
          if (newAttributeIds != null || newBuiltInAttributes != null) {
            existingSubDriver.attributeIds = getIdsForGQLSubdriver({
              attributeIds: newAttributeIds,
              builtInAttributes: newBuiltInAttributes,
            });
          }
          if (removeAllAttributes != null && removeAllAttributes) {
            existingSubDriver.attributeIds = [];
          }
          if (source != null) {
            existingSubDriver.source = convertDimSubdriverSource(source);
          }
          if (
            unlink ||
            (existingSubDriver.attributeIds.length === 0 && existingDriver.subdrivers.length === 1)
          ) {
            subDriverIdsToUnlink.push(existingSubDriver.driverId);
          }
        }
      }

      if (subDriverIdsToUnlink.length > 0) {
        unlinkSubDriversFromParent(layer, dimDriverBySubdriverId, subDriverIdsToUnlink);
      }
    }

    if (dimensional.dimensionIds != null) {
      const removedDimensionIds = existingDriver.dimensionIds.filter(
        (dId) => !dimensional.dimensionIds?.includes(dId),
      );

      existingDriver.subdrivers.forEach((sub) => {
        sub.attributeIds = sub.attributeIds.filter((aId) => {
          const attr = attrById[aId];
          if (attr == null) {
            return true;
          }
          return !removedDimensionIds.includes(attr.dimensionId);
        });
      });
      existingDriver.dimensionIds = dimensional.dimensionIds;
    }

    // delete subdrivers
    if (dimensional.deleteSubDrivers != null) {
      const parentDimDriver = layer.drivers.byId[id];
      if (parentDimDriver.type !== DriverType.Dimensional) {
        return;
      }
      const subDriversToDelete: DriverDeleteInput[] = [];

      // Recomputing keys is much faster than doing iterative array comparisons.
      // Duplicate subdrivers can exist; hence, code defensively.
      const subDriverIdsByAttributesKey = existingDriver.subdrivers.reduce<
        Record<string, DriverId[]>
      >((out, { driverId, attributeIds }) => {
        const key = getSubdriverKey(attributeIds);
        out[key] ??= [];
        out[key].push(driverId);
        return out;
      }, {});

      const subDriverKeysToDelete = dimensional.deleteSubDrivers.map((input) =>
        getSubdriverKey(getIdsForGQLSubdriver(input)),
      );
      const matchingSubDrivers = subDriverKeysToDelete
        .flatMap((key) => subDriverIdsByAttributesKey[key])
        .filter(isNotNull);

      subDriversToDelete.push(...matchingSubDrivers.map((driverId) => ({ id: driverId })));

      handleDeleteDrivers(layer, subDriversToDelete);
      if (parentDimDriver.subdrivers.length === 0) {
        handleDeleteDrivers(layer, [{ id: parentDimDriver.id }]);
      }
    }
  }

  if (existingDriver.type === DriverType.Dimensional) {
    //  TODO: delete driver.dimensional.dimensionIds since we should infer from the subdrivers.
    const allAttrIds = uniq(existingDriver.subdrivers.flatMap((sub) => getIdsForGQLSubdriver(sub)));
    const allAttrs = allAttrIds.map((attrId) => attrById[attrId]).filter(isNotNull);
    const dimIds = uniq(allAttrs.map((a) => a.dimensionId));
    existingDriver.dimensionIds = dimIds;
  }
}

function convertDimSubdriverSource(
  input: DimensionalSubDriverSourceInput | null | undefined,
): DimensionalSubDriverSource | null {
  if (input == null) {
    return null;
  }
  const source: DimensionalSubDriverSource = { type: input.type };
  if (input.extQueryId != null) {
    source.extQueryId = input.extQueryId;
  }
  return source;
}

export function setDriversFromDatasetSnapshotLite(
  layer: Draft<LightLayer>,
  dataset: DatasetSnapshot,
) {
  if (dataset == null) {
    layer.drivers = { byId: {}, allIds: [] };
    return;
  }

  const subDriverIdToDimDriverName: Record<DriverId, string> = {};
  dataset.drivers.forEach((driver) => {
    if (driver.driverType === DriverType.Dimensional) {
      driver.dimensional?.subDrivers?.forEach((d) => {
        subDriverIdToDimDriverName[d.driver.id] = driver.name;
      });
    }
  });

  const drivers = dataset.drivers
    .map((gqlDriver) => {
      const {
        id,
        driverType,
        name,
        format,
        basic,
        dimensional,
        // eslint-disable-next-line deprecation/deprecation
        submodelId,
        // eslint-disable-next-line deprecation/deprecation
        sortIndex,
        // eslint-disable-next-line deprecation/deprecation
        groupId,
        driverMapping,
      } = gqlDriver;

      const commonDriver: CommonDriver = {
        id,
        name: subDriverIdToDimDriverName[id] ?? name,
        format,
        currencyISOCode: undefined,
        coloring: {},
        decimalPlaces: undefined,
        submodelId: submodelId ?? undefined,
        leverType: undefined,
        description: undefined,
        sortIndex,
        groupId,
        valueTypeExtension: undefined,
        pivotBy: [],
        driverMapping: driverMapping ?? undefined,
      };
      if (driverType === DriverType.Basic) {
        if (basic == null) {
          throw Error(`Basic driver "${gqlDriver.name}" must have field 'basic'.`);
        }

        const { forecast, actuals, rollup } = basic;
        const basicDriver: BasicDriver = {
          ...commonDriver,
          type: DriverType.Basic,
          forecast: { formula: forecast.formula },
          actuals: {
            formula: actuals.formula ?? undefined,
          },
          rollup: rollup?.reducer != null ? { reducer: rollup.reducer } : undefined,
        };

        return basicDriver;
      } else if (driverType === DriverType.Dimensional) {
        if (dimensional == null) {
          throw Error(`Dimensional driver "${gqlDriver.name}" must have field 'dimensional'.`);
        }

        const dimDriver: DimensionalDriver = {
          ...commonDriver,
          type: DriverType.Dimensional,
          dimensions: [],
          subdrivers: [],
          defaultForecast: undefined,
          defaultActuals: undefined,
        };
        return normalizeDimensionalDriver(dimDriver);
      }

      return null;
    })
    .filter(isNotNull);

  layer.drivers = {
    byId: keyBy(drivers, 'id'),
    allIds: drivers.map((e) => e.id),
  };
}

/**
 * If driverReferences is not set, returns the submodel reference and block references
 */
const resolveDriverReferences = ({
  blockConfigDriverReferencesByDriverId,
  blockIdBySubmodelId,
  gqlDriver,
  datasetBlockIds,
}: {
  blockConfigDriverReferencesByDriverId: NullableRecord<DriverId, DriverReference[]>;
  blockIdBySubmodelId: NullableRecord<SubmodelId, BlockId>;
  gqlDriver: Pick<Driver, 'id' | 'submodelId' | 'groupId' | 'sortIndex' | 'driverReferences'>;
  datasetBlockIds: Set<BlockId>;
}): DriverReference[] => {
  // eslint-disable-next-line deprecation/deprecation
  const { submodelId, groupId, sortIndex, driverReferences } = gqlDriver;
  if (driverReferences != null) {
    return driverReferences.filter((ref) => datasetBlockIds.has(ref.blockId));
  }

  const updatedDriverReferences: DriverReference[] = [...(driverReferences ?? [])];
  const encounteredBlockIds = new Set<string>();
  if (submodelId != null) {
    const submodelBlockId = blockIdBySubmodelId[submodelId];
    if (submodelBlockId != null) {
      updatedDriverReferences.push({
        blockId: submodelBlockId,
        groupId,
        sortIndex,
      });
      encounteredBlockIds.add(submodelBlockId);
    }
  }

  blockConfigDriverReferencesByDriverId[gqlDriver.id]?.forEach((blockIdAndSortIndex) => {
    if (!encounteredBlockIds.has(blockIdAndSortIndex.blockId)) {
      updatedDriverReferences.push(blockIdAndSortIndex);
    }
    encounteredBlockIds.add(blockIdAndSortIndex.blockId);
  });

  return updatedDriverReferences;
};

function getBlockConfigDriverReferencesByDriverId(
  state: DatasetSliceState,
): NullableRecord<DriverId, DriverReference[]> {
  const driverReferencesByDriverId: NullableRecord<DriverId, DriverReference[]> = {};

  Object.values(state.blocksPages.byId).forEach((page) => {
    page.blockIds.forEach((blockId) => {
      const block = state.blocks.byId[blockId];
      if (block == null) {
        return;
      }
      // Ignore database blocks, they only apply to business objects
      if (block.blockConfig.businessObjectSpecId != null) {
        return;
      }
      if (block.blockConfig.idFilter != null && block.blockConfig.idFilter.length > 0) {
        const sortIndices = computeFreshSortIndexUpdates(
          block.blockConfig.idFilter.map((id) => ({
            id,
          })),
        );

        block.blockConfig.idFilter?.forEach((driverId) => {
          const refsForDriver = driverReferencesByDriverId[driverId] ?? [];
          const existingRef = refsForDriver.find((ref) => ref.blockId === blockId);
          if (existingRef == null) {
            driverReferencesByDriverId[driverId] = [
              ...refsForDriver,
              { blockId, sortIndex: sortIndices[driverId] },
            ];
          }
        });
      }
    });
  });

  return driverReferencesByDriverId;
}

function getBlockIdBySubmodelId(state: DatasetSliceState): NullableRecord<SubmodelId, BlockId> {
  const blockIdBySubmodelId: NullableRecord<SubmodelId, BlockId> = {};

  Object.values(state.blocksPages.byId).forEach((page) => {
    if (page.internalPageType != null) {
      const submodelId = getSubmodelIdFromInternalPageType(page.internalPageType);
      const blockId = page.blockIds[0];
      if (blockId != null && submodelId !== '') {
        blockIdBySubmodelId[submodelId] = blockId;
      }
    }
  });

  return blockIdBySubmodelId;
}

export function setDriversFromDatasetSnapshot(
  state: DatasetSliceState,
  layer: Draft<LightLayer>,
  defaultLayer: Draft<DefaultLayer>,
  dataset: DatasetSnapshot,
  options?: {
    resolveDriverReferences?: boolean;
  },
) {
  if (dataset == null) {
    layer.drivers = { byId: {}, allIds: [] };
    return;
  }
  const blockConfigDriverReferencesByDriverId = getBlockConfigDriverReferencesByDriverId(state);
  const blockIdBySubmodelId = getBlockIdBySubmodelId(state);

  const subDriverIdToDimDriverName: Record<DriverId, string> = {};
  dataset.drivers.forEach((driver) => {
    if (driver.driverType === DriverType.Dimensional) {
      driver.dimensional?.subDrivers?.forEach((d) => {
        subDriverIdToDimDriverName[d.driver.id] = driver.name;
      });
    }
  });

  // Dimension and Attributes only exist on the default layer.
  const dimById = defaultLayer.dimensions.byId;
  const attrById = defaultLayer.attributes.byId;
  const datasetBlockIds = dataset.blocks.reduce((acc, block) => {
    acc.add(block.id);
    return acc;
  }, new Set<BlockId>());

  const drivers = dataset.drivers
    .map((gqlDriver) => {
      const {
        id,
        driverType,
        name,
        format,
        currencyISOCode,
        decimalPlaces,
        basic,
        dimensional,
        // eslint-disable-next-line deprecation/deprecation
        submodelId,
        leverType,
        description,
        // eslint-disable-next-line deprecation/deprecation
        sortIndex,
        // eslint-disable-next-line deprecation/deprecation
        groupId,
        coloring,
        valueTypeExtension,
        generatedExplanation,
        pivotBy,
        driverMapping,
      } = gqlDriver;

      const commonDriver: CommonDriver = {
        id,
        name: subDriverIdToDimDriverName[id] ?? name,
        format,
        currencyISOCode,
        coloring: {
          row: coloring?.row ?? undefined,
          cells: coloring?.cells ?? undefined,
        },
        decimalPlaces,
        submodelId: submodelId ?? undefined,
        leverType,
        description,
        sortIndex,
        groupId,
        generatedExplanation,
        valueTypeExtension,
        pivotBy,
        driverReferences:
          options?.resolveDriverReferences === false
            ? gqlDriver.driverReferences
            : resolveDriverReferences({
                blockConfigDriverReferencesByDriverId,
                blockIdBySubmodelId,
                gqlDriver,
                datasetBlockIds,
              }),
        driverMapping,
      };
      if (driverType === DriverType.Basic) {
        if (basic == null) {
          throw Error(`Basic driver "${gqlDriver.name}" must have field 'basic'.`);
        }

        const { forecast, actuals, rollup, trackParentLayerFields } = basic;
        const actualsTimeSeries =
          valueTypeExtension?.type == null || valueTypeExtension?.type === ValueType.Number
            ? valueToNumericTimeSeries(actuals.timeSeries ?? {})
            : valueToNonNumericTimeSeries(actuals.timeSeries ?? {});

        const basicDriver: BasicDriver = {
          ...commonDriver,
          type: DriverType.Basic,
          forecast: { formula: forecast.formula },
          actuals: {
            formula: actuals.formula ?? undefined,
            timeSeries: actualsTimeSeries,
          },
          rollup: rollup?.reducer != null ? { reducer: rollup.reducer } : undefined,
        };

        // Child layers should track their parent layer fields by default. Thus, we want this
        // field to be empty for child layers.
        if (layer.id === DEFAULT_LAYER_ID) {
          basicDriver.trackParentLayerFields = trackParentLayerFields;
        }

        return basicDriver;
      } else if (driverType === DriverType.Dimensional) {
        if (dimensional == null) {
          throw Error(`Dimensional driver "${gqlDriver.name}" must have field 'dimensional'.`);
        }

        const subDrivers = dimensional.subDrivers ?? [];

        // TODO: don't need to do much of this converting since it is normalized.
        const subdrivers = subDrivers.map((s) => ({
          attributes: [
            ...(s.attributes?.map((a) => attributeCreateInputToAttribute(a)) ?? []),
            ...(s.builtInAttributes?.map((a) => {
              handleCreateBuiltInAttribute(defaultLayer, a);
              return builtInAttributeToAttribute(a);
            }) ?? []),
          ],
          driverId: s.driver.id,
          ...(s.source != null && { source: convertDimSubdriverSource(s.source) }),
        }));

        //TODO: do not require keeping dimensions on the dimensional driver
        const uniqDims = uniq(
          subDrivers.flatMap((s) => s.attributes?.map((a) => a.dimensionId)),
        ).filter(isNotNull);
        const uniqBuiltInDims = uniq(
          subDrivers?.flatMap((s) => s.builtInAttributes?.map((a) => a.type)),
        ).filter(isNotNull);

        const dims: Dimension[] =
          uniqDims?.map((dimId) => {
            const d = dimById[dimId];

            return {
              id: d.id,
              name: d.name,
              deleted: d.deleted,
              type: UserAddedDimensionType,
              attributes: d.attributeIds.map((a) => attrById[a]),
            };
          }) ?? [];
        const builtInDims: Dimension[] =
          uniqBuiltInDims?.map((type) => {
            return {
              id: type,
              name: type,
              deleted: false,
              type,
              attributes: [],
            };
          }) ?? [];
        const dimDriver: DimensionalDriver = {
          ...commonDriver,
          type: DriverType.Dimensional,
          dimensions: [...dims, ...builtInDims],
          subdrivers,
          defaultForecast: dimensional.defaultForecast ?? undefined,
          defaultActuals: dimensional.defaultActuals ?? undefined,
        };
        return normalizeDimensionalDriver(dimDriver);
      }

      return null;
    })
    .filter(isNotNull);

  layer.drivers = {
    byId: keyBy(drivers, 'id'),
    allIds: drivers.map((e) => e.id),
  };

  dataset.deletedIdentifiers?.forEach(({ id, name }) => {
    layer.deletedIdentifiers[id] = name;
  });
}

function normalizeDimensionalSubDriver(
  subdriver: DimensionalSubDriver,
): NormalizedDimensionalSubDriver {
  return {
    attributeIds: subdriver.attributes.map((a) => a.id),
    driverId: subdriver.driverId,
    source: subdriver.source,
  };
}

function denormalizeDimensionalSubDriver(
  subdriver: NormalizedDimensionalSubDriver,
  attributesById: Record<AttributeId, Attribute>,
): DimensionalSubDriver {
  return {
    attributes: subdriver.attributeIds.map((attrId) => attributesById[attrId]).filter(isNotNull),
    driverId: subdriver.driverId,
  };
}

function normalizeDimensionalDriver(driver: DimensionalDriver): NormalizedDimensionalDriver {
  return {
    id: driver.id,
    name: driver.name,
    // eslint-disable-next-line deprecation/deprecation
    submodelId: driver.submodelId,
    type: driver.type,
    format: driver.format,
    currencyISOCode: driver.currencyISOCode,
    coloring: driver.coloring,
    decimalPlaces: driver.decimalPlaces,
    leverType: driver.leverType,
    description: driver.description,
    // eslint-disable-next-line deprecation/deprecation
    groupId: driver.groupId,
    // eslint-disable-next-line deprecation/deprecation
    sortIndex: driver.sortIndex,
    defaultForecast: driver.defaultForecast,
    defaultActuals: driver.defaultActuals,
    dimensionIds: driver.dimensions.map((dim) => dim.id),
    subdrivers: driver.subdrivers.map((sub) => normalizeDimensionalSubDriver(sub)),
    valueTypeExtension: driver.valueTypeExtension,
    pivotBy: driver.pivotBy,
    driverReferences: driver.driverReferences,
    driverMapping: driver.driverMapping,
  };
}

export function denormalizeDimensionalDriver(
  driver: NormalizedDimensionalDriver,
  dimensionsById: Record<DimensionId, Dimension>,
  attributesById: Record<AttributeId, Attribute>,
): DimensionalDriver {
  const sortedDimensions = sortBy(
    driver.dimensionIds.map((dimId) => dimensionsById[dimId]).filter(isNotNull),
    ['id'],
    ['desc'],
  );

  const sortedSubdrivers = sortBy(
    driver.subdrivers.map((sub) => denormalizeDimensionalSubDriver(sub, attributesById)),
    ['id'],
    ['desc'],
  );

  return {
    id: driver.id,
    name: driver.name,
    // eslint-disable-next-line deprecation/deprecation
    submodelId: driver.submodelId,
    type: driver.type,
    format: driver.format,
    currencyISOCode: driver.currencyISOCode,
    coloring: driver.coloring,
    decimalPlaces: driver.decimalPlaces,
    leverType: driver.leverType,
    description: driver.description,
    // eslint-disable-next-line deprecation/deprecation
    groupId: driver.groupId,
    // eslint-disable-next-line deprecation/deprecation
    sortIndex: driver.sortIndex,
    defaultForecast: driver.defaultForecast,
    defaultActuals: driver.defaultActuals,
    dimensions: sortedDimensions,
    subdrivers: sortedSubdrivers,
    valueTypeExtension: driver.valueTypeExtension,
    pivotBy: driver.pivotBy,
    driverReferences: driver.driverReferences,
    driverMapping: driver.driverMapping,
  };
}

export const driverActualsUpdateMutations = (
  state: RootState,
  inputs: Array<{
    id: DriverId;
    values: NumericTimeSeriesWithEmpty;
    newFormat: DriverFormat | undefined;
  }>,
): Partial<DatasetMutationInput> | null => {
  const lastActualsMonthKey = datasetLastActualsMonthKeySelector(state);

  const updates = inputs
    .map(({ id, values, newFormat }) => {
      const originalDriver = driversByIdForLayerSelector(state)[id];

      if (originalDriver == null || originalDriver.type !== DriverType.Basic) {
        return null;
      }
      const formatUpdate =
        originalDriver.format !== newFormat && newFormat != null ? { format: newFormat } : {};

      const timeSeries = Object.entries(values)
        .map(([monthKey, value]) => {
          if (monthKey > lastActualsMonthKey) {
            return null;
          }
          const originalPoint = originalDriver.actuals.timeSeries?.[monthKey];
          // Updating to existing value
          if (originalPoint === value) {
            return null;
          }
          return {
            time: getISOTimeWithoutMsFromMonthKey(monthKey),
            value: value != null ? String(value) : value,
          };
        })
        .filter(isNotNull);

      if (timeSeries.length === 0) {
        if (formatUpdate.format != null) {
          return {
            id,
            ...formatUpdate,
          };
        }
        return null;
      }

      return {
        id,
        actuals: {
          timeSeries,
        },
        ...formatUpdate,
      };
    })
    .filter(isNotNull);

  if (updates.length === 0) {
    return null;
  }

  return {
    updateDrivers: updates,
  };
};
