import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import xor from 'lodash/xor';

export const arraySameElements = <T extends string | number>(
  lhs: T[] | undefined,
  rhs: T[] | undefined,
) => {
  if (lhs == null && rhs == null) {
    return true;
  }
  if (lhs == null || rhs == null) {
    return false;
  }
  return isEmpty(xor(lhs, rhs));
};

export const hasDupes = <T extends string | number>(arr: T[]) => {
  return uniq(arr).length !== arr.length;
};

export const copyWithReplacedItem = <T>(arr: T[], idx: number, replacement: T) => {
  return arr.with(idx, replacement);
};

export const copyWithRemovedItem = <T>(arr: T[], idx: number) => {
  return [...arr.slice(0, idx), ...arr.slice(idx + 1)];
};

export const interleave = <T>(arr: T[], element: (idx: number) => T) => {
  return arr.flatMap((value, index, array) =>
    array.length - 1 !== index ? [value, element(index + 1)] : value,
  );
};

/**
 * Returns a new sorted array based on the sortOrder. If an element is not
 * found in the sortOrder, it is sorted at the end while preserving the
 * original order.
 *
 * This is useful when you need non-in-place sorting, or have large arrays that
 * require sub-quadratic complexity.
 * @param array
 * @param sortOrder
 */
export function toSortedArray(array: string[], sortOrder?: string[]): string[] {
  if (sortOrder == null) {
    return array;
  }
  const res: string[] = [];
  // Use Set.has() which has sublinear time compared to quadratic complexity of indexOf()
  const remainingSet = new Set(array);

  // Go through sort order and push any found elements from the array in order
  sortOrder.forEach((e) => {
    if (remainingSet.has(e)) {
      res.push(e);
    }
    remainingSet.delete(e);
  });

  // Add the remaining elements from the array at the end
  remainingSet.forEach((e) => {
    res.push(e);
  });
  return res;
}

/**
 * Brings the first element of an array that meets the condition specified in a callback function to the front of the array.
 * This mutates the given array in-place.
 * @return Returns true if an element was reordered, otherwise returns false.
 */
export const bringElementToFront = <T>(
  arr: T[],
  predicate: (value: T, index: number, obj: T[]) => unknown,
) => {
  const index = arr.findIndex(predicate);
  if (index >= 0) {
    arr.unshift(...arr.splice(index, 1));
    return true;
  }
  return false;
};

export const enqueue = <T>(queue: T[], newItem: T, maxLength?: number) => {
  if (maxLength === 0) {
    return [];
  }
  const newQueue = [...queue];
  if (maxLength != null) {
    while (newQueue.length >= maxLength) {
      newQueue.shift();
    }
  }
  newQueue.push(newItem);
  return newQueue;
};
