import { createSelector } from 'reselect';

import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import { BlockId } from 'reduxStore/models/blocks';
import { type Event, type EventId } from 'reduxStore/models/events';
import {
  BlockColumnResizeLiveEdit,
  EventGroupLiveUpdate,
  EventLiveEdit,
  LiveEdit,
  LiveEditImpact,
  LiveEditType,
} from 'reduxStore/reducers/liveEditSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { blockIdSelector } from 'selectors/constSelectors';
import { ParametricSelector, Selector } from 'types/redux';

export const liveEditSelector: Selector<LiveEdit> = (state) => state.liveEdit;

export const liveEditTypeSelector: Selector<LiveEditType | null> = createSelector(
  liveEditSelector,
  (liveEdit) => (liveEdit == null ? null : liveEdit.type),
);

export const liveEditingEventStateSelector: Selector<EventLiveEdit | undefined> = createSelector(
  liveEditSelector,
  (liveEdit) => {
    return liveEdit?.type === LiveEditType.Event ? liveEdit : undefined;
  },
);

export const liveEditingEventImpactSelector: Selector<LiveEditImpact | undefined> = createSelector(
  liveEditingEventStateSelector,
  (liveEdit) => {
    return liveEdit?.impact;
  },
);

export const liveEditingEventWarningSelector: Selector<string | undefined> = createSelector(
  liveEditSelector,
  (liveEdit) => {
    return liveEdit?.type === LiveEditType.MultiEvent || liveEdit?.type === LiveEditType.Event
      ? liveEdit.warning ?? undefined
      : undefined;
  },
);

export const liveEditingSingleEventSelector: Selector<Event | undefined> = createSelector(
  liveEditSelector,
  (liveEdit) => {
    return liveEdit?.type === LiveEditType.Event ? liveEdit.event : undefined;
  },
);

export const isDraggingEventSelector: Selector<boolean> = createSelector(
  liveEditSelector,
  (liveEdit) => liveEdit != null && liveEdit.type === LiveEditType.Event && liveEdit.isDragging,
);

export const liveEditingEventsSelector: Selector<Event[] | undefined> = createSelector(
  liveEditSelector,
  (liveEdit) => {
    if (liveEdit?.type === LiveEditType.Event) {
      return [liveEdit.event];
    } else if (liveEdit?.type === LiveEditType.MultiEvent) {
      return liveEdit.events;
    }
    return undefined;
  },
);

export const liveEditingEventIdsSelector: Selector<EventId[]> = createDeepEqualSelector(
  liveEditingEventsSelector,
  (events) => events?.map((ev) => ev.id) ?? [],
);

export const liveEditingEventGroupsSelector: Selector<EventGroupLiveUpdate[] | undefined> =
  createSelector(liveEditSelector, (liveEdit) => {
    if (liveEdit?.type !== LiveEditType.EventGroup) {
      return undefined;
    }
    return liveEdit.updates;
  });

export const liveEditBlockColumnResizeSelector: ParametricSelector<
  BlockId,
  BlockColumnResizeLiveEdit | null
> = createSelector(
  (state: RootState) => liveEditSelector(state),
  blockIdSelector,
  (liveEdit, blockId) =>
    liveEdit?.type === LiveEditType.BlockColumnResize && liveEdit.blockId === blockId
      ? liveEdit
      : null,
);
