import { useMergeRefs, useTheme } from '@chakra-ui/react';
import { getCSSVar } from '@chakra-ui/utils';
import React, { useContext, useEffect, useMemo, useRef } from 'react';

import {
  HARD_CODED_ACTUAL_TOOLTIP,
  IMPACTED_BY_PLAN_TOOLTIP,
} from 'components/AgGridComponents/CellRenderer/constants';
import CellBorder from 'components/CellBorder/CellBorder';
import { SetCellPopoverReferenceContext } from 'components/CellPopoverReferenceContextProvider/CellPopoverReferenceContextProvider';
import { CELL_HEIGHT_IN_PX, CellValueContext } from 'config/cells';
import { DriverFormat } from 'generated/graphql';
import { extDriverSourceToSlug } from 'helpers/integrations';
import { toPxString } from 'helpers/styles';
import { useBGColorForCell } from 'hooks/useBgColorForCell';
import { useCellRef } from 'hooks/useCellRefContext';
import { useCellSelectionStateContext } from 'hooks/useCellSelectionStateContext';
import { useResolvedBlockConfiguration } from 'hooks/useResolvedBlockConfiguration';
import { Value } from 'reduxStore/models/value';
import { CalculationError } from 'types/dataset';

import styles from './TimeSeriesCellWrapper.module.scss';

export interface TimeSeriesCellProps<V extends Value> {
  value: V['value'] | undefined;
  error?: CalculationError;
  showComparisonHighlight?: boolean;
  color: string;
  alert?: React.ReactNode;
  onMouseDown?: React.MouseEventHandler;
  onMouseEnter?: React.MouseEventHandler;
  onMouseLeave?: React.MouseEventHandler;
  onContextMenu?: React.MouseEventHandler;
  onSave?: (value: V | undefined, formatUpdate: DriverFormat | undefined) => void;
  width: number;
  inView?: boolean;
  onStartEditing?: () => void;
}

export interface ActiveCellProps<V extends Value> {
  value: V['value'] | undefined;
  error?: CalculationError;
  onSave?: (value: V | undefined, formatUpdate: DriverFormat | undefined) => void;
  onStartEditing?: () => void;
  disabled?: boolean;
}

interface Props {
  color: string;
  showComparisonHighlight?: boolean;
  children?: React.ReactNode;
  width: number;
  bgColor?: string;
  onContextMenu?: React.MouseEventHandler;
  onMouseDown?: React.MouseEventHandler;
  onMouseEnter?: React.MouseEventHandler;
  onMouseLeave?: React.MouseEventHandler;
}

const TimeSeriesCellWrapper = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      color: providedColor,
      width,
      showComparisonHighlight,
      children,
      onContextMenu,
      onMouseDown,
      onMouseEnter,
      onMouseLeave,
    },
    passedRef,
  ) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const cellState = useCellSelectionStateContext();
    const { cellRef, cellId } = useCellRef();
    const { layerHighlightColorByLayerId } = useResolvedBlockConfiguration();

    const { isPopoverReferenceCell } = cellState;
    const layerId = 'layerId' in cellRef.rowKey ? cellRef.rowKey.layerId : undefined;
    const layerHighlightColor =
      showComparisonHighlight && layerId != null
        ? layerHighlightColorByLayerId[layerId]
        : undefined;

    const bgColor = useBGColorForCell(layerHighlightColor);
    const { valueTooltip, clearJustEdited } = useContext(CellValueContext);
    const tooltipContent = valueTooltip?.content;

    const tooltipSimpleMsg = tooltipContent?.type === 'simple_msg' ? tooltipContent.msg : undefined;
    const isImpactedByPlan = tooltipSimpleMsg === IMPACTED_BY_PLAN_TOOLTIP;
    const isHardcodedActual = tooltipSimpleMsg === HARD_CODED_ACTUAL_TOOLTIP;

    const sourceSlug = extDriverSourceToSlug(
      tooltipContent?.type === 'ext_source' ? tooltipContent.extSource : undefined,
    );
    const setTimeSeriesPopoverRef = useContext(SetCellPopoverReferenceContext);

    // If this cell is the reference cell for the popover, set the ref so that
    // the popover can actually be positioned correctly
    useEffect(() => {
      if (isPopoverReferenceCell && wrapperRef.current != null) {
        setTimeSeriesPopoverRef(wrapperRef.current);
      }
    }, [isPopoverReferenceCell, cellRef, setTimeSeriesPopoverRef]);
    const mergedRef = useMergeRefs(wrapperRef, passedRef);
    const theme = useTheme();

    // If cell was impacted by plan, color should be of 'forecast'
    // regardless of what was passed in
    const color = isImpactedByPlan ? 'forecast' : providedColor;

    const inlineCss = useMemo(() => {
      const bgColorVar = getCSSVar(theme, 'colors', bgColor) as string;
      const colorVar = getCSSVar(theme, 'colors', color) as string;
      const height = toPxString(CELL_HEIGHT_IN_PX);
      const textDecorationColor = getCSSVar(
        theme,
        'colors',
        color !== 'forecast' ? 'gray.500' : 'blue.400',
      ) as string;
      const textDecorationLine =
        valueTooltip?.hasUnderline && sourceSlug == null ? 'underline' : undefined;
      const widthCssString = toPxString(width);

      return {
        backgroundColor: bgColorVar,
        color: colorVar,
        height,
        textDecorationColor,
        textDecorationLine,
        width: widthCssString,
      };
    }, [bgColor, color, sourceSlug, theme, valueTooltip, width]);

    return (
      <div
        data-testid="time-series-cell"
        data-cellid={cellId}
        ref={mergedRef}
        className={styles.timeSeriesCellWrapper}
        style={inlineCss}
        onContextMenu={onContextMenu}
        onMouseDown={onMouseDown}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseMove={clearJustEdited}
      >
        <div
          data-testid={
            isImpactedByPlan ? 'plan-impacted' : isHardcodedActual ? 'actual-hardcoded' : undefined
          }
          className={styles.timeSeriesCellWrapperOverflowHidden}
        >
          {children}
        </div>
        <CellBorder />
      </div>
    );
  },
);

TimeSeriesCellWrapper.displayName = 'TimeSeriesCellWrapper';

export default React.memo(TimeSeriesCellWrapper);
