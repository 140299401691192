import { Flex, usePopoverContext } from '@chakra-ui/react';
import React, { useCallback, useMemo } from 'react';

import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import SelectMenu, { SelectItem } from 'components/SelectMenu/SelectMenu';
import { ModelViewColumnType } from 'config/modelView';
import useAppDispatch from 'hooks/useAppDispatch';
import useBlockContext from 'hooks/useBlockContext';
import { toggleColumnVisibility } from 'reduxStore/actions/blockMutations';
import Eye from 'vectors/Eye';

type DriverColumnHeaderListItem = SelectItem & { onSelect: () => void };

interface Props {
  columnKey: ModelViewColumnType;
}

const DriverColumnHeaderPopoverContent: React.FC<Props> = ({ columnKey }) => {
  const { blockId } = useBlockContext();
  const dispatch = useAppDispatch();
  const { onClose } = usePopoverContext();

  const onSelect = useCallback(
    (item: DriverColumnHeaderListItem) => {
      item.onSelect();
      onClose();
    },
    [onClose],
  );

  const items = useMemo<DriverColumnHeaderListItem[]>(() => {
    return [
      {
        id: 'hide',
        name: 'Hide in block',
        icon: <Eye isOpen={false} />,
        onSelect: () => {
          dispatch(toggleColumnVisibility({ blockId, columnKey, visible: false }));
        },
      },
    ];
  }, [dispatch, blockId, columnKey]);

  return (
    <Flex flexDirection="column" w="18rem" overflow="hidden" bgColor="white" borderRadius="base">
      <SelectMenu
        items={items}
        onSelect={onSelect}
        startFocusIdx={-1}
        onClose={onClose}
        clearFocusOnMouseLeave
        width="unset"
      >
        {BaseSelectMenuItem}
      </SelectMenu>
    </Flex>
  );
};

export default DriverColumnHeaderPopoverContent;
