import { COLUMN_TYPE_TO_DEFAULT_WIDTH, INITIAL_VALUE_COLUMN_TYPE } from 'config/modelView';
import {
  BlockColumn,
  BlockCreateInput,
  BlockRow,
  BlockType,
  BlockViewAsType,
  ChartAxisType,
  ChartElementPosition,
  ChartGroupingType,
  ChartPlotByType,
  ChartRollupType,
  ChartSize,
} from 'generated/graphql';
import { deepClone } from 'helpers/clone';
import { uuidv4 } from 'helpers/uuidv4';
import {
  Block,
  BlockColumnWithOptionalId,
  BlockId,
  BlockRowWithOptionalId,
} from 'reduxStore/models/blocks';
import { BusinessObjectSpecId } from 'reduxStore/models/businessObjectSpecs';

export const getBlockCreateInput = (
  type: BlockType,
  { enableAgCharts }: { enableAgCharts: boolean },
): Omit<BlockCreateInput, 'pageId'> => {
  switch (type) {
    case BlockType.DriverGrid:
      return {
        id: uuidv4(),
        name: 'Key Metrics',
        type: BlockType.DriverGrid,
        blockConfig: {
          idFilter: [],
        },
      };
    case BlockType.DriverCharts: {
      if (!enableAgCharts) {
        return {
          id: uuidv4(),
          name: 'Charts',
          type: BlockType.DriverCharts,
          blockConfig: {
            idFilter: [],
            blockViewOptions: {
              viewAsType: BlockViewAsType.Chart,
            },
          },
        };
      }

      const groupId = uuidv4();
      const yAxisId = uuidv4();
      const xAxisId = uuidv4();

      return {
        id: uuidv4(),
        name: 'Charts',
        type: BlockType.DriverCharts,
        blockConfig: {
          idFilter: [],
          blockViewOptions: {
            viewAsType: BlockViewAsType.LineChart,
            chartGroupingType: ChartGroupingType.Single,
            chartSize: ChartSize.Medium,
            chartDisplay: {
              axes: [
                {
                  id: xAxisId,
                  type: ChartAxisType.Time,
                  position: ChartElementPosition.Bottom,
                  time: {
                    rollup: ChartRollupType.Monthly,
                    plotBy: ChartPlotByType.Time,
                  },
                },
                {
                  id: yAxisId,
                  type: ChartAxisType.Driver,
                  position: ChartElementPosition.Left,
                  driver: {
                    groupIds: [groupId],
                  },
                },
              ],
              series: [],
              groups: [
                {
                  id: groupId,
                  seriesIds: [],
                  isDefault: true,
                },
              ],
            },
          },
        },
      };
    }
    case BlockType.PlanTimeline:
      return {
        id: uuidv4(),
        name: 'Plan Timeline',
        type: BlockType.PlanTimeline,
        blockConfig: {},
      };
    case BlockType.Text:
      return {
        id: uuidv4(),
        name: 'Text',
        type: BlockType.Text,
        blockConfig: {
          textBlockBody: '',
        },
      };
    case BlockType.Video:
      return {
        id: uuidv4(),
        name: 'Video',
        type: BlockType.Video,
        blockConfig: {},
      };
    case BlockType.Image:
      return {
        id: uuidv4(),
        name: 'Image',
        type: BlockType.Image,
        blockConfig: {},
      };
    default:
      throw Error('invalid block type for this method');
  }
};

export const getBlockCreateInputForObjectTable = ({
  specId,
  name,
}: {
  specId: BusinessObjectSpecId;
  name: string;
}): Omit<BlockCreateInput, 'pageId'> => {
  return {
    id: uuidv4(),
    name,
    type: BlockType.ObjectTable,
    blockConfig: {
      businessObjectSpecId: specId,
      columns: [],
    },
  };
};

export const addDefaultBlockConfig = (block: Block): Block => {
  if (block == null) {
    return block;
  }
  if (block.type !== BlockType.DriverGrid) {
    return block;
  }

  const { blockConfig } = block;
  if (blockConfig.columns != null && blockConfig.columns.length > 0) {
    return block;
  }

  return {
    ...block,
    blockConfig: {
      ...blockConfig,
      columns: [
        { key: 'name', visible: true, width: COLUMN_TYPE_TO_DEFAULT_WIDTH.name },
        { key: 'data', visible: true, width: COLUMN_TYPE_TO_DEFAULT_WIDTH.data },
        {
          key: INITIAL_VALUE_COLUMN_TYPE,
          visible: false,
          width: COLUMN_TYPE_TO_DEFAULT_WIDTH.data,
        },
      ],
    },
  };
};

function getBlockColumnWithId(blockColumn: BlockColumnWithOptionalId): BlockColumn {
  return {
    ...blockColumn,
    id: blockColumn.id ?? uuidv4(),
  };
}

function getBlockRowWithId(blockRow: BlockRowWithOptionalId): BlockRow {
  return {
    ...blockRow,
    columns: blockRow.columns.map(getBlockColumnWithId),
    id: blockRow.id ?? uuidv4(),
  };
}

function getBlockRowsWithIds(layout: BlockRowWithOptionalId[]): BlockRow[] {
  return layout.map(getBlockRowWithId);
}

// Exported for testing
export function getBlockLocation(
  rows: BlockRowWithOptionalId[],
  blockId: BlockId,
): { rowIndex: number; columnIndex: number; blockIndex: number } | null {
  for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
    const row = rows[rowIndex];

    for (let columnIndex = 0; columnIndex < row.columns.length; columnIndex++) {
      const column = row.columns[columnIndex];
      const blockIndex = column.blockIds.indexOf(blockId);

      if (blockIndex !== -1) {
        return { rowIndex, columnIndex, blockIndex };
      }
    }
  }

  return null;
}

type BlockRelativePosition = 'above' | 'below' | 'left' | 'right' | 'replace';

export function getUpdatedBlocksPageLayout(
  blockRows: BlockRowWithOptionalId[],
  type: 'move-block' | 'insert-block',
  blockId: BlockId,
  targetBlockId: BlockId,
  relativePosition: BlockRelativePosition,
): BlockRow[] {
  const layout = deepClone(blockRows);

  const targetBlockIndices = getBlockLocation(layout, targetBlockId);
  if (targetBlockIndices == null) {
    return getBlockRowsWithIds(layout);
  }

  if (type === 'move-block') {
    const blockIndices = getBlockLocation(layout, blockId);

    if (blockIndices != null) {
      const { rowIndex, columnIndex, blockIndex } = blockIndices;

      const row = layout[rowIndex];
      const column = row.columns[columnIndex];

      column.blockIds.splice(blockIndex, 1);

      if (column.blockIds.length === 0) {
        row.columns.splice(columnIndex, 1);
      }
    }
  }

  const {
    rowIndex: targetRowIndex,
    columnIndex: targetColumnIndex,
    blockIndex: targetBlockIndex,
  } = targetBlockIndices;

  const targetRow = layout[targetRowIndex];

  if (relativePosition === 'above') {
    layout.splice(targetRowIndex, 0, {
      columns: [{ blockIds: [blockId] }],
    });
  } else if (relativePosition === 'below') {
    layout.splice(targetRowIndex + 1, 0, {
      columns: [{ blockIds: [blockId] }],
    });
  } else if (relativePosition === 'left') {
    targetRow.columns.splice(targetColumnIndex, 0, {
      blockIds: [blockId],
    });
  } else if (relativePosition === 'right') {
    targetRow.columns.splice(targetColumnIndex + 1, 0, {
      blockIds: [blockId],
    });
  } else if (relativePosition === 'replace') {
    targetRow.columns[targetColumnIndex].blockIds[targetBlockIndex] = blockId;
  }

  const filteredLayout = layout.filter((row) => row.columns.length > 0);

  return getBlockRowsWithIds(filteredLayout);
}

export function removeBlocksFromLayout(
  blockRows: BlockRowWithOptionalId[],
  blockIdsToRemove: BlockId[],
): BlockRow[] {
  const layout = deepClone(blockRows);

  blockIdsToRemove.forEach((blockIdToRemove) => {
    const blockLocation = getBlockLocation(layout, blockIdToRemove);

    if (blockLocation) {
      const { rowIndex, columnIndex, blockIndex } = blockLocation;
      const row = layout[rowIndex];
      const column = row.columns[columnIndex];

      // Remove the blockId from the column's blockIds
      column.blockIds.splice(blockIndex, 1);

      // If the column has no more blockIds, remove the column
      if (column.blockIds.length === 0) {
        row.columns.splice(columnIndex, 1);
      }

      // If the row has no more columns, remove the row
      if (row.columns.length === 0) {
        layout.splice(rowIndex, 1);
      }
    }
  });

  return getBlockRowsWithIds(layout);
}
