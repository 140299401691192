import { Box, Center, Flex, WrapItem } from '@chakra-ui/react';
import { useCallback, useEffect, useRef } from 'react';

import DriverChartBorder from 'components/DriverCharts/DriverChartBorder';
import { useAddDriversPopoverContext } from 'config/driverGridContext';
import { BLOCK_HOVER_VISIBLE_STYLES } from 'config/styles';
import { toPxString } from 'helpers/styles';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { driverChartCardDimensionsSelector } from 'selectors/driverChartCardDimensionsSelector';
import Plus from 'vectors/Plus';

interface Props {
  isAdding: boolean;
  onOpenDriverSelect: () => void;
}

const AddDriverChartCard: React.FC<Props> = ({ isAdding, onOpenDriverSelect }) => {
  const { blockId } = useBlockContext();
  const { width } = useAppSelector((state) => driverChartCardDimensionsSelector(state, blockId));
  const anchorRef = useRef<HTMLDivElement>(null);
  const [, setAddDriverPopoverReference] = useAddDriversPopoverContext();
  const onOpen = useCallback(() => {
    setAddDriverPopoverReference({
      reference: anchorRef.current,
    });
    onOpenDriverSelect();
  }, [onOpenDriverSelect, setAddDriverPopoverReference]);

  useEffect(() => {
    if (isAdding) {
      onOpen();
    }
  }, [isAdding, onOpen]);

  return (
    <WrapItem w={toPxString(width)} position="relative" role="group">
      <Box
        bgColor={isAdding ? 'gray.100' : 'white'}
        cursor="pointer"
        visibility={isAdding ? 'visible' : 'hidden'}
        transition="background-color 0.5 ease"
        w="full"
        h="full"
        onClick={onOpen}
        sx={BLOCK_HOVER_VISIBLE_STYLES}
        _groupHover={{ bgColor: 'gray.100' }}
      >
        <Center width="full" height="full">
          <Flex
            columnGap={2}
            padding={2}
            fontSize="xs"
            fontWeight="medium"
            alignItems="center"
            color="gray.500"
          >
            <Plus boxSize={3} />
            Add driver
          </Flex>
        </Center>
        <Box visibility={isAdding ? 'visible' : 'hidden'} sx={BLOCK_HOVER_VISIBLE_STYLES}>
          <DriverChartBorder />
        </Box>
        <Box ref={anchorRef} left="50%" top={0} position="absolute" />
      </Box>
    </WrapItem>
  );
};

export default AddDriverChartCard;
