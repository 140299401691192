import { BorderState } from 'components/CellBorder/types';
import { CellRef } from 'config/cells';
import { DriverPropertyColumn } from 'config/modelView';
import { useIsActuals } from 'hooks/useIsActuals';
import { useResolvedBlockConfiguration } from 'hooks/useResolvedBlockConfiguration';

const COLUMN_COMPACT = 'column-compact';

interface Props {
  borderState: BorderState;
  blockId: string | undefined;
  cellRef: CellRef;
  driverPropertyColumn: DriverPropertyColumn | null | undefined;
  isRowHeader?: boolean;
  lastStickyColumnType?: string | null;
  isLastStickyColumn: boolean;
}
export const useComparisonBorderState = ({
  borderState,
  blockId,
  cellRef,
  driverPropertyColumn,
  isRowHeader,
  lastStickyColumnType,
  isLastStickyColumn,
}: Props): BorderState | null => {
  const { comparisonLayout, comparisonLayerIds, displayedComparisonColumns } =
    useResolvedBlockConfiguration();

  const rowLayerId = 'layerId' in cellRef.rowKey ? cellRef.rowKey.layerId : null;
  const columnLayerId = 'layerId' in cellRef.columnKey ? cellRef.columnKey?.layerId : null;

  // Currently forecast cells have a different color border
  const monthKey = 'monthKey' in cellRef.columnKey ? cellRef.columnKey.monthKey : '';
  const layerId = columnLayerId ?? rowLayerId ?? '';
  const isActuals = useIsActuals(monthKey, layerId);
  if (
    comparisonLayout == null ||
    blockId == null ||
    comparisonLayerIds == null ||
    displayedComparisonColumns == null
  ) {
    return null;
  }

  const isDriverPropertyColumnHeaderSticky =
    driverPropertyColumn != null && driverPropertyColumn.type === lastStickyColumnType;

  const indexOfLastComparisonType = displayedComparisonColumns.length - 1;

  // BOTTOM BORDERS
  const rowComparisonType =
    'comparisonType' in cellRef.rowKey ? cellRef.rowKey.comparisonType : null;
  const isLastComparisonRow =
    rowComparisonType != null &&
    displayedComparisonColumns.indexOf(rowComparisonType) === indexOfLastComparisonType;

  const isLastLayerRow = comparisonLayerIds[comparisonLayerIds.length - 1] === rowLayerId;

  // RIGHT BORDERS
  const columnComparisonType =
    'comparisonColumn' in cellRef.columnKey ? cellRef.columnKey?.comparisonColumn : null;
  const isLastComparisonColumn =
    columnComparisonType != null &&
    displayedComparisonColumns.indexOf(columnComparisonType) === indexOfLastComparisonType;

  const isLastLayerColumn = comparisonLayerIds[comparisonLayerIds.length - 1] === columnLayerId;
  const isCompactColumnView = comparisonLayout === COLUMN_COMPACT;
  const isDriverPropertyColumn = driverPropertyColumn != null;

  if (isRowHeader) {
    borderState = {
      ...borderState,
      hasRightBorder: isDriverPropertyColumn ? !isDriverPropertyColumnHeaderSticky : true,
      hasSolidBottomBorder: true,
    };
  } else if (isCompactColumnView) {
    borderState = {
      ...borderState,
      hasRightBorder: true,
      hasSolidRightBorder: isDriverPropertyColumn ? isLastStickyColumn : isLastComparisonColumn,
      isForecastCell: !isActuals,
    };
  } else if (isDriverPropertyColumn) {
    borderState = {
      ...borderState,
      hasRightBorder: true,
      hasSolidBottomBorder: isLastLayerRow || isLastComparisonRow,
    };
  } else {
    borderState = {
      ...borderState,
      hasSolidRightBorder: isLastComparisonColumn || isLastLayerColumn,
      hasSolidBottomBorder: isLastLayerRow || isLastComparisonRow,
      isForecastCell: !isActuals,
    };
  }

  return borderState;
};
