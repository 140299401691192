import { createContext } from 'react';

import { CellType } from 'config/cells';
import { ComparisonColumn, ComparisonTimePeriod, RollupReducer } from 'generated/graphql';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import { DriverId } from 'reduxStore/models/drivers';
import { LayerId } from 'reduxStore/models/layers';

export type DriverRowContextValue = {
  driverId: DriverId;
  comparisonRowLayerId?: LayerId;
  groupId?: DriverGroupId;
  comparisonType?: ComparisonColumn;
  comparisonTimePeriod?: ComparisonTimePeriod;
  requiresBaselineLayer: boolean;
  requiresVariance: boolean;
  monthKeys: string[];
  rollupReducer: RollupReducer;
  rowType?: CellType.Driver | CellType.ImpactDriver;
};

export const DriverRowContext = createContext<DriverRowContextValue>({
  driverId: '',
  requiresBaselineLayer: false,
  requiresVariance: false,
  monthKeys: [],
  rollupReducer: RollupReducer.Sum,
});
