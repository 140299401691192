import { Center, Text } from '@chakra-ui/react';
import { useMemo } from 'react';

import { SelectItem } from 'components/SelectMenu/SelectMenu';
import { ColorLocation, formatColorForUsage } from 'helpers/color';

const COLORS: Array<[string, string]> = [
  ['white', 'Default'],
  ['gray', 'Gray'],
  ['red', 'Red'],
  ['yellow', 'Yellow'],
  ['green', 'Green'],
];

export const COLOR_SECTION_ID = 'background-color-options';

export const useColorSelectMenuItem = ({
  bgColor,
  showHashtag = false,
}: {
  bgColor: string | undefined;
  showHashtag?: boolean;
}): SelectItem[] => {
  const items = useMemo(
    () =>
      COLORS.map(([color, name]) => {
        const displayColor = formatColorForUsage(color, ColorLocation.Accessory);

        return {
          id: `${color}`,
          key: `${color}`,
          icon: (
            <Center
              bgColor={displayColor}
              rounded="3px"
              w="1rem"
              h="1rem"
              borderWidth={color === 'white' ? '1px' : '0px'}
              borderStyle="solid"
              borderColor="gray.300"
            >
              {showHashtag ? <Text color="gray.600">#</Text> : null}
            </Center>
          ),
          name: `${name}`,
          isChecked: bgColor != null ? bgColor === color : color === 'white',
          sectionId: COLOR_SECTION_ID,
        };
      }),
    [bgColor, showHashtag],
  );

  return items;
};
