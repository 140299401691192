// eslint-disable-next-line you-dont-need-lodash-underscore/clone-deep
import { cloneDeep, isEmpty } from 'lodash';

import {
  Attribute,
  BusinessObjectUpdateInput,
  CollectionEntryUpdateInput,
  DatasetMutationInput,
  DimensionalSubDriverCreateInput,
  DriverCreateInput,
  DriverFormat,
  DriverType,
  DriverUpdateInput,
} from 'generated/graphql';
import { arraySameElements } from 'helpers/array';
import { ATTRIBUTE_DELIMETER, getSubdriverKey } from 'helpers/dimensionalDrivers';
import { attributeListToGqlAttrs } from 'helpers/dimensions';
import {
  replaceBasicDriverId,
  replaceBasicDriverWithDimDriver,
  replaceDestAttrFilters,
} from 'helpers/driverFormulas';
import { isNotNull } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import { updateBusinessObject } from 'reduxStore/actions/businessObjectMutations';
import { getUpdateBusinessObjectSpecMutationInput } from 'reduxStore/actions/businessObjectSpecMutations';
import {
  getDriverFormulaCopyData,
  getFormulaFromFormulaCopyData,
} from 'reduxStore/actions/formulaCopyPaste';
import { submitAutoLayerizedMutations } from 'reduxStore/actions/submitDatasetMutation';
import { BusinessObjectSpecId } from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { DimensionalPropertyId, DriverPropertyId } from 'reduxStore/models/collections';
import { AttributeId, DimensionId } from 'reduxStore/models/dimensions';
import { DriverId } from 'reduxStore/models/drivers';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { AppThunk } from 'reduxStore/store';
import { businessObjectsBySpecIdForLayerSelector } from 'selectors/businessObjectsSelector';
import {
  collectionEntryForBusinessObjectIdSelector,
  dimensionalPropertyEvaluatorSelector,
  dimensionalPropertySelector,
  driverPropertiesForBusinessObjectSpecIdSelector,
  driverPropertiesForBusinessObjectSpecSelector,
  driverPropertySelector,
  keyDimensionalPropertiesForBusinessObjectSpecSelector,
  objectKeyAttributeCountsByDimDriverIdForObjectSpecSelector,
  subdriversByDimDriverIdForBusinessObjectSelector,
} from 'selectors/collectionSelector';
import { databaseBlockIdSelector } from 'selectors/databaseSelector';
import {
  dimensionalDriversByIdSelector,
  driversByIdForLayerSelector,
  evaluatorDriverSelector,
} from 'selectors/driversSelector';
import { currentLayerIdSelector } from 'selectors/layerSelector';

export const deleteDimensionalPropertyFromSpec =
  ({
    objectSpecId,
    dimensionalPropertyId,
  }: {
    objectSpecId: BusinessObjectSpecId;
    dimensionalPropertyId: DimensionalPropertyId;
  }): AppThunk<void> =>
  (dispatch, getState) => {
    const state = getState();

    const driverMutation = dimDriverUpdateForChangedDimensionalPropertyKeyMutation(state, {
      objectSpecId,
      dimensionalPropertyId,
      shouldDelete: true,
    });

    const updateObjectSpecMutation = getUpdateBusinessObjectSpecMutationInput(
      {
        id: objectSpecId,
        updateCollection: {
          removeDimensionalProperties:
            dimensionalPropertyId != null ? [dimensionalPropertyId] : undefined,
        },
      },
      getState,
    );

    dispatch(
      submitAutoLayerizedMutations('delete-business-object-spec-dimensional-property', [
        updateObjectSpecMutation,
        driverMutation,
      ]),
    );
  };

export const deleteDriverPropertyFromSpec =
  ({
    businessObjectSpecId,
    driverPropertyId,
  }: {
    businessObjectSpecId: BusinessObjectSpecId;
    driverPropertyId: DriverPropertyId;
  }): AppThunk<void> =>
  (dispatch, getState) => {
    const state = getState();
    // TODO: we should think about deleting the drivers associated with this property as well.
    // For now, we leave them as-is.

    const driverProperty = driverPropertySelector(state, driverPropertyId);
    const driverId = driverProperty?.driverId;
    const deleteDriverMutation =
      driverId != null
        ? {
            deleteDrivers: [{ id: driverId }],
          }
        : undefined;

    const updateSpecMutations = getUpdateBusinessObjectSpecMutationInput(
      {
        id: businessObjectSpecId,
        updateCollection: {
          removeDriverProperties: driverPropertyId != null ? [driverPropertyId] : undefined,
        },
      },
      getState,
    );

    dispatch(
      submitAutoLayerizedMutations('delete-business-object-spec-driver-property', [
        updateSpecMutations,
        deleteDriverMutation ?? {},
      ]),
    );
  };

export const updateDimensionalPropertyEntry =
  (input: {
    newAttributeId: AttributeId | null;
    dimensionalPropertyId: DimensionalPropertyId;
    objectId: BusinessObjectId;
    objectSpecId: BusinessObjectSpecId;
  }): AppThunk<void> =>
  (dispatch, getState) => {
    const state = getState();

    const updateObjectMutation = updateDimensionalPropertyEntryMutation(state, input);
    const updateDriversMutation = updateDriversFromDimensionalPropertyChangeMutation(state, {
      ...input,
      isNewDBKey: false,
      shouldSkipAttrValidation: false,
    });

    if (updateDriversMutation != null && updateDriversMutation.length > 0) {
      dispatch(
        submitAutoLayerizedMutations('update-dimensional-property-entry', [
          {
            updateDrivers: updateDriversMutation,
          },
        ]),
      );
    }
    dispatch(updateBusinessObject(updateObjectMutation));
  };

export const setDimensionalPropertyIsDatabaseKey =
  (
    objectSpecId: BusinessObjectSpecId,
    dimPropertyId: DimensionalPropertyId,
    isDatabaseKey: boolean,
  ): AppThunk<void> =>
  (dispatch, getState) => {
    const state = getState();

    const updateObjectSpecMutation = {
      updateBusinessObjectSpecs: [
        {
          id: objectSpecId,
          updateCollection: {
            updateDimensionalProperties: [
              {
                id: dimPropertyId,
                isDatabaseKey,
              },
            ],
          },
        },
      ],
    };

    const updateDriverMutation = dimDriverUpdateForChangedDimensionalPropertyKeyMutation(state, {
      objectSpecId,
      dimensionalPropertyId: dimPropertyId,
      shouldDelete: !isDatabaseKey,
    });

    dispatch(
      submitAutoLayerizedMutations('toggle-dimensional-property-is-database-key', [
        updateObjectSpecMutation,
        updateDriverMutation,
      ]),
    );
  };

// For mapped driver properties, we need to update the subdrivers formulas
// on key attribute changes
const updateDriverPropertySubdriversFormulaMutation = (
  state: RootState,
  {
    parentDimDriverId,
    currSubDriverId,
    dimensionId,
    newAttributeId,
    currAttrList,
    newAttrList,
  }: {
    parentDimDriverId: string;
    currSubDriverId: string;
    dimensionId: string;
    newAttributeId: AttributeId | null;
    currAttrList: Attribute[];
    newAttrList: string[];
  },
): DriverUpdateInput | null => {
  const parentDriver = evaluatorDriverSelector(state, parentDimDriverId);
  const currSubDriver = evaluatorDriverSelector(state, currSubDriverId);

  const parentDimDriverIsMappedDriver =
    parentDriver?.type === DriverType.Dimensional &&
    currSubDriver?.type === DriverType.Basic &&
    parentDriver?.driverMapping != null &&
    parentDriver?.driverMapping?.driverId != null;

  if (!parentDimDriverIsMappedDriver) {
    return null;
  }

  const mappedDriverId = parentDriver?.driverMapping?.driverId ?? '';
  const mappedDriver = evaluatorDriverSelector(state, mappedDriverId);
  const updatedFormulas: { forecast?: { formula: string }; actuals?: { formula: string } } = {};

  const invalidMappedDriver =
    mappedDriver == null ||
    currSubDriver?.type !== DriverType.Basic ||
    mappedDriver.type !== DriverType.Dimensional;

  if (invalidMappedDriver) {
    return null;
  }
  const expectedCurrentMappedDriverSubDriver = mappedDriver.subdrivers.find((sub) => {
    const sharesCommonAttrsWithCurrSubdriver = sub.attributes.every((attr) => {
      return currAttrList.some((currAttrId) => currAttrId.id === attr.id);
    });
    return sharesCommonAttrsWithCurrSubdriver;
  });
  const expectedUpdatedMappedDriverSubDriver = mappedDriver.subdrivers.find((sub) => {
    const sharesCommonAttrsWithCurrSubdriver = sub.attributes.every((attr) => {
      return newAttrList.some((newAttrId) => newAttrId === attr.id);
    });
    return sharesCommonAttrsWithCurrSubdriver;
  });
  const currForecastFormula = currSubDriver?.forecast?.formula;
  const currActualsFormula = currSubDriver?.actuals?.formula;

  let updatedForecastFormula = currForecastFormula;
  let updatedActualsFormula = currActualsFormula ?? '';

  // The current and updated attribute fitlers reference basic drivers
  // In this case we need to replace the basic driver reference

  const currentSubdriverReferencesBasicDriver = expectedCurrentMappedDriverSubDriver != null;
  const updatedSubdriverReferencesBasicDriver = expectedUpdatedMappedDriverSubDriver != null;

  if (currentSubdriverReferencesBasicDriver && updatedSubdriverReferencesBasicDriver) {
    updatedActualsFormula = replaceBasicDriverId(
      updatedActualsFormula,
      expectedCurrentMappedDriverSubDriver.driverId,
      expectedUpdatedMappedDriverSubDriver.driverId,
    );

    updatedForecastFormula = replaceBasicDriverId(
      updatedForecastFormula,
      expectedCurrentMappedDriverSubDriver.driverId,
      expectedUpdatedMappedDriverSubDriver.driverId,
    );
  } else if (currentSubdriverReferencesBasicDriver) {
    // In this case replace a basic driver with a dim driver filter when there is
    // no basic driver that matches the update filter attributes
    // want to filter for dimensions shared between the subdriver and the mapped driver
    const mappedDriverDimensionMap = new Set(mappedDriver.dimensions.map((dim) => dim.id));
    const attrList = currAttrList
      .filter((attr) => mappedDriverDimensionMap.has(attr.dimensionId))
      .map((attr) => {
        let attrId = attr.id;
        if (newAttributeId == null && attr.dimensionId === dimensionId) {
          attrId = 'ANY';
        }
        return `${attr.dimensionId}:${attrId}`;
      });

    const shouldAddNewDimensionFilter =
      mappedDriverDimensionMap.has(dimensionId) &&
      newAttributeId != null &&
      !currAttrList.some((attr) => attr.dimensionId === dimensionId);
    if (shouldAddNewDimensionFilter) {
      attrList.push(`${dimensionId}:${newAttributeId}`);
    }

    const updatedAttrFilters = attrList.join(' ').trimEnd();
    updatedActualsFormula = replaceBasicDriverWithDimDriver(
      updatedActualsFormula,
      expectedCurrentMappedDriverSubDriver.driverId,
      mappedDriverId,
      updatedAttrFilters,
    );
    updatedForecastFormula = replaceBasicDriverWithDimDriver(
      updatedForecastFormula,
      expectedCurrentMappedDriverSubDriver.driverId,
      mappedDriverId,
      updatedAttrFilters,
    );
  }

  // Update the dimension attribute filter
  updatedActualsFormula = replaceDestAttrFilters(
    updatedActualsFormula,
    dimensionId,
    newAttributeId ?? 'ANY',
  );

  updatedForecastFormula = replaceDestAttrFilters(
    updatedForecastFormula,
    dimensionId,
    newAttributeId ?? 'ANY',
  );

  const didForecastFormulaChange = currForecastFormula !== updatedForecastFormula;

  if (didForecastFormulaChange) {
    updatedFormulas.forecast = {
      formula: updatedForecastFormula,
    };
  }
  const didActualsFormulaChange = currActualsFormula !== updatedActualsFormula;
  if (didActualsFormulaChange) {
    updatedFormulas.actuals = {
      formula: updatedActualsFormula,
    };
  }

  if (!didActualsFormulaChange && !didForecastFormulaChange) {
    return null;
  }
  return {
    id: currSubDriverId,
    actuals: updatedFormulas.actuals,
    forecast: updatedFormulas.forecast,
  };
};

const transformFormulasForDatabaseRowKeyAttributeChange = (
  state: RootState,
  {
    currentSubDriverId,
    newSubDriverId,
    objectSpecId,
    newAttributes,
  }: {
    currentSubDriverId: string;
    newSubDriverId: string;
    objectSpecId: string;
    newAttributes: Record<DimensionId, AttributeId>;
  },
): { forecastFormula: string; actualsFormula: string } | null => {
  const driver = driversByIdForLayerSelector(state)[currentSubDriverId];
  if (driver == null || driver.type !== DriverType.Basic) {
    return null;
  }

  const forecastData = getDriverFormulaCopyData({
    state,
    formulaType: 'forecast',
    sourceDriverId: currentSubDriverId,
    formula: driver.forecast.formula,
  });
  if (forecastData == null) {
    return null;
  }
  const forecastFormula = getFormulaFromFormulaCopyData({
    state,
    blockId: databaseBlockIdSelector(state, objectSpecId)!,
    pastedData: forecastData,
    targetEntityId: { type: 'driver', id: newSubDriverId },
    targetOriginalFormula: null,
    driverIsBeingNewlyCreated: true,
    newAttributes,
  });

  if (isEmpty(driver.actuals.formula)) {
    return { forecastFormula, actualsFormula: '' };
  }

  const actualsData = getDriverFormulaCopyData({
    state,
    formulaType: 'actuals',
    sourceDriverId: currentSubDriverId,
    formula: driver.actuals.formula,
  });
  if (actualsData == null) {
    return { forecastFormula, actualsFormula: '' };
  }
  const actualsFormula = getFormulaFromFormulaCopyData({
    state,
    blockId: databaseBlockIdSelector(state, objectSpecId)!,
    pastedData: actualsData,
    targetEntityId: { type: 'driver', id: newSubDriverId },
    targetOriginalFormula: null,
    driverIsBeingNewlyCreated: true,
    newAttributes,
  });

  return { forecastFormula, actualsFormula };
};

/**
 * Given a list of changes applied to dimensional keys in a database table,
 * produce a mutation containing dimensional subdriver creations, updates, and deletions.
 *
 * Utilizes `objectKeyAttributeCountsByDimDriverIdForObjectSpecSelector` to get a mapping
 * of all dimensional key values from objects to subdrivers per dimensional driver.
 * This mapping is used to decided whether the bulk operation should either reuse, create,
 * or delete a subdriver.
 *
 * There are the rules:
 * 1. When an updated dimensional key pair matches one existing driver, no action needs to be taken.
 * 2. When an updated dimensional key pair matches no existing driver and any other object has that same key pair,
 *    create a new subdriver.
 * 3. When an updated dimensional key pair matches no exisitng driver and no other object has that same key pair,
 *    reuse/modify the existing subdriver.
 * 4. When these operations are completed, delete any subdriver that no longer has a matching key pair.
 */
export const bulkUpdateDriversFromDimensionalPropertyChangeMutation = (
  state: RootState,
  objectSpecId: BusinessObjectSpecId,
  changes: Array<{
    objectId: string;
    keyAttributes: Array<{
      dimensionalPropertyId: DimensionalPropertyId;
      attributeId: AttributeId;
    }>;
  }>,
): DatasetMutationInput => {
  const updateDriversByDriverId: Record<DriverId, DriverUpdateInput> = {};

  const driversById = driversByIdForLayerSelector(state);
  const dimensionalPropertyEvaluator = dimensionalPropertyEvaluatorSelector(state);
  const driverProperties = driverPropertiesForBusinessObjectSpecIdSelector(state, objectSpecId);
  const keyDimensionalProperties = keyDimensionalPropertiesForBusinessObjectSpecSelector(
    state,
    objectSpecId,
  );

  const keyAttributeCountsByDimDriverId =
    objectKeyAttributeCountsByDimDriverIdForObjectSpecSelector(state, objectSpecId);
  const updatedKeyAttributeCountsByDimDriverId = cloneDeep(keyAttributeCountsByDimDriverId);

  for (const { objectId, keyAttributes } of changes) {
    for (const { driverId: dimDriverId } of driverProperties) {
      const dimDriver = driversById[dimDriverId];
      if (dimDriver == null) {
        continue;
      }

      const currentKeyAttributeProperties =
        dimensionalPropertyEvaluator.getKeyAttributePropertiesForBusinessObject(objectId);

      const currentKeyAttributeIds = currentKeyAttributeProperties.map((attr) => attr.attribute.id);
      const updatedKeyAttributesByDimensionId = keyDimensionalProperties.reduce<
        Record<DimensionId, AttributeId>
      >((out, prop) => {
        const current = currentKeyAttributeProperties.find(
          ({ dimensionalPropertyId }) => prop.id === dimensionalPropertyId,
        );
        const change = keyAttributes.find(
          ({ dimensionalPropertyId }) => prop.id === dimensionalPropertyId,
        );

        // Every key attribute must be filled in as either:
        // 1. The changed attribute id
        // 2. The current attribute id
        // 3. undefined (the key attribute is not set)
        const attributeId = change?.attributeId ?? current?.attribute.id;
        if (attributeId != null) {
          out[prop.dimension.id] = attributeId;
        }

        return out;
      }, {});
      const updatedKeyAttributes = Object.values(updatedKeyAttributesByDimensionId);

      if (arraySameElements(currentKeyAttributeIds, updatedKeyAttributes)) {
        continue;
      }

      const currentKey = getSubdriverKey(currentKeyAttributeIds);
      const updatedKey = getSubdriverKey(updatedKeyAttributes);

      // If no object is pointing to the updated key.
      if ((updatedKeyAttributeCountsByDimDriverId[dimDriverId][updatedKey] ?? 0) === 0) {
        const existingSubDriverId = dimensionalPropertyEvaluator.getSubDriverIdForAttributeIds(
          dimDriverId,
          currentKeyAttributeIds,
        );

        // If one or fewer objects point to the current subdriver, attempt to reuse it.
        if (
          existingSubDriverId != null &&
          (updatedKeyAttributeCountsByDimDriverId[dimDriverId][currentKey] ?? 0) < 2
        ) {
          // Remapped subdrivers should be deleted from tracking to avoid accidentally trying to clean them up.
          delete updatedKeyAttributeCountsByDimDriverId[dimDriverId][currentKey];
          // Reset count.
          updatedKeyAttributeCountsByDimDriverId[dimDriverId][updatedKey] = 1;

          updateDriversByDriverId[dimDriverId] ??= { id: dimDriverId };
          updateDriversByDriverId[dimDriverId].dimensional ??= {};
          updateDriversByDriverId[dimDriverId].dimensional!.updateSubDrivers ??= [];

          // TODO: update formulas
          // LOL what?!
          // This is only useful if you're editing a driver formula that references another dimensional driver by the same dimensional keys.
          updateDriversByDriverId[dimDriverId].dimensional!.updateSubDrivers!.push({
            attributeIds: currentKeyAttributeIds,
            newAttributeIds: updatedKeyAttributes,
          });
        } else {
          const newSubDriverId = uuidv4();

          let actualsFormula = '';
          let forecastFormula = '';
          if (existingSubDriverId != null) {
            const subdriver = driversById[existingSubDriverId];
            if (subdriver) {
              const formulas = transformFormulasForDatabaseRowKeyAttributeChange(state, {
                currentSubDriverId: existingSubDriverId,
                newSubDriverId,
                objectSpecId,
                newAttributes: updatedKeyAttributesByDimensionId,
              });
              if (formulas) {
                if (!isEmpty(formulas.forecastFormula)) {
                  forecastFormula = formulas.forecastFormula;
                }
                if (!isEmpty(formulas.actualsFormula)) {
                  actualsFormula = formulas.actualsFormula;
                }
              }
            }
          }

          const newDriver: DriverCreateInput = {
            id: newSubDriverId,
            name: dimDriver.name,
            driverType: DriverType.Basic,
            format: DriverFormat.Number,
            // If you do not include `basic` the mutation will be ignored!
            basic: {
              actuals: {
                formula: actualsFormula,
              },
              forecast: {
                formula: forecastFormula,
              },
            },
          };

          --updatedKeyAttributeCountsByDimDriverId[dimDriverId][currentKey];
          // Set count.
          updatedKeyAttributeCountsByDimDriverId[dimDriverId][updatedKey] = 1;

          updateDriversByDriverId[dimDriverId] ??= { id: dimDriverId };
          updateDriversByDriverId[dimDriverId].newSubDrivers ??= [];
          // TODO: create formulas
          updateDriversByDriverId[dimDriverId].newSubDrivers!.push({
            attributeIds: updatedKeyAttributes,
            driver: newDriver,
          });
        }
      } else {
        // If another subdriver already exists for this object to point to do nothing but decrement count.
        --updatedKeyAttributeCountsByDimDriverId[dimDriverId][currentKey];
      }
    }
  }

  // Attempt to cleanup any dangling subdrivers.
  for (const [dimDriverId, countsByKey] of Object.entries(updatedKeyAttributeCountsByDimDriverId)) {
    const keysToCleanup = new Set<string>();
    const dimDriver = driversById[dimDriverId];
    if (dimDriver == null || dimDriver.type !== DriverType.Dimensional) {
      continue;
    }
    for (const [key, count] of Object.entries(countsByKey)) {
      if (count === 0) {
        keysToCleanup.add(key);
      }
    }
    // Avoid trying to cleanup a driver that does not exist.
    const filteredKeysToCleanup = Array.from(keysToCleanup)
      .map((key) => (key === 'none' ? [] : key.split(ATTRIBUTE_DELIMETER)))
      .filter(
        (attributes) =>
          dimensionalPropertyEvaluator.getSubDriverIdForAttributeIds(dimDriverId, attributes) !=
          null,
      );
    if (filteredKeysToCleanup.length > 0) {
      updateDriversByDriverId[dimDriverId] ??= { id: dimDriverId };
      updateDriversByDriverId[dimDriverId].dimensional ??= {};
      updateDriversByDriverId[dimDriverId].dimensional!.deleteSubDrivers =
        filteredKeysToCleanup.map((attributeIds) => ({ attributeIds }));
    }
  }

  return {
    updateDrivers: Object.values(updateDriversByDriverId),
  };
};

export const updateDriversFromDimensionalPropertyChangeMutation = (
  state: RootState,
  {
    newAttributeId,
    dimensionalPropertyId,
    objectId,
    objectSpecId,
    shouldSkipAttrValidation,
    isNewDBKey,
  }: {
    newAttributeId: AttributeId | null;
    dimensionalPropertyId: DimensionalPropertyId;
    objectId: BusinessObjectId;
    objectSpecId: BusinessObjectSpecId;
    // If true, don't validate subdriver attribute uniqueness. This is important for bulk updates performance when you set/unset a database key
    shouldSkipAttrValidation: boolean;
    isNewDBKey: boolean;
  },
): DriverUpdateInput[] | null => {
  // Need to get all subdrivers for object
  const subdriversByDimDriverId = subdriversByDimDriverIdForBusinessObjectSelector(state, objectId);
  // Then update all subdrivers with the new attribute
  if (Object.entries(subdriversByDimDriverId).length === 0) {
    return null;
  }
  const dimensionalPropertyEvaluator = dimensionalPropertyEvaluatorSelector(state);

  const dimensionProperty = dimensionalPropertySelector(state, dimensionalPropertyId);
  if (dimensionProperty == null || (!dimensionProperty.isDatabaseKey && !isNewDBKey)) {
    return null;
  }
  const dimensionId = dimensionProperty.dimension.id;

  const currentLayerId = currentLayerIdSelector(state);

  const businessObjects = businessObjectsBySpecIdForLayerSelector(state, {
    layerId: currentLayerId,
  })[objectSpecId];

  const subDriverAttrKeys = shouldSkipAttrValidation
    ? new Map<string, number>()
    : businessObjects.reduce((acc, curr) => {
        const dimProps = dimensionalPropertyEvaluator.getKeyAttributePropertiesForBusinessObject(
          curr.id,
        );
        const subDriverAttrKey = dimProps
          .map(({ attribute }) => attribute.id)
          .sort()
          .join('_');

        if (!acc.has(subDriverAttrKey)) {
          acc.set(subDriverAttrKey, 1);
        } else {
          acc.set(subDriverAttrKey, acc.get(subDriverAttrKey) + 1);
        }

        return acc;
      }, new Map<string, number>());

  const updateDrivers: DriverUpdateInput[] = [];

  for (const [dimDriverId, subdriver] of Object.entries(subdriversByDimDriverId)) {
    const updatedAttributes = subdriver?.attributes?.filter((attribute) => {
      return attribute.dimensionId !== dimensionId;
    });
    const newGqlAttrs = attributeListToGqlAttrs(updatedAttributes);
    if (newAttributeId != null) {
      newGqlAttrs.attributeIds.push(newAttributeId);
    }

    const currAttrList =
      subdriver?.attributes?.map(
        (attr) => ({ id: attr.id, dimensionId: attr.dimensionId }) as Attribute,
      ) ?? [];

    const currAttrIds = subdriver?.attributes
      .map((attr) => attr.id)
      .sort()
      .join('_');
    const newAttrIds = newGqlAttrs.attributeIds.sort().join('_');
    const anotherObjectHasSameCurrSubDriverAttrs =
      subDriverAttrKeys.has(currAttrIds) && subDriverAttrKeys.get(currAttrIds) > 1;
    const anotherObjectHasSameNewSubDriverAttrs = subDriverAttrKeys.has(newAttrIds);

    // When other objects share the same new updated attributes, don't update the subdriver
    // as this will overwrite the other object's subdriver
    if (anotherObjectHasSameNewSubDriverAttrs) {
      continue;
    }

    // On database attribute change, check if we should update the subdriver formulas for mapped drivers
    const subdriverFormulaUpdateMutation = updateDriverPropertySubdriversFormulaMutation(state, {
      parentDimDriverId: dimDriverId,
      currSubDriverId: subdriver.driverId,
      dimensionId,
      newAttributeId,
      currAttrList,
      newAttrList: newGqlAttrs.attributeIds,
    });

    // when another object has the same current attributes, create a new subdriver so
    // that we don't overwrite other objects' subdrivers
    if (anotherObjectHasSameCurrSubDriverAttrs) {
      // create new subdriver

      const currSubDriver = evaluatorDriverSelector(state, subdriver.driverId);
      if (currSubDriver == null || currSubDriver.type !== DriverType.Basic) {
        continue;
      }
      let updatedActualsFormula =
        subdriverFormulaUpdateMutation?.actuals?.formula ?? currSubDriver?.actuals.formula;
      let updatedForecastFormula =
        subdriverFormulaUpdateMutation?.forecast?.formula ?? currSubDriver?.forecast.formula;

      const newSubDriverId = uuidv4();
      if (
        subdriverFormulaUpdateMutation?.forecast?.formula == null &&
        !isEmpty(currSubDriver?.forecast.formula) &&
        newAttributeId != null
      ) {
        // Applies "magic paste" to formula when new subdrivers are begat from changing a key attribute.
        const formulas = transformFormulasForDatabaseRowKeyAttributeChange(state, {
          currentSubDriverId: currSubDriver.id,
          newAttributes: { [dimensionId]: newAttributeId },
          newSubDriverId,
          objectSpecId,
        });
        if (formulas) {
          const { forecastFormula, actualsFormula } = formulas;
          if (!isEmpty(forecastFormula)) {
            updatedForecastFormula = forecastFormula;
          }
          if (!isEmpty(actualsFormula)) {
            updatedActualsFormula = actualsFormula;
          }
        }
      }

      const newSubDriver: DimensionalSubDriverCreateInput = {
        attributeIds: newGqlAttrs.attributeIds,
        driver: {
          id: newSubDriverId,
          name: currSubDriver.name,
          driverType: DriverType.Basic,
          format: currSubDriver.format,
          basic: {
            actuals: {
              formula: updatedActualsFormula,
            },
            forecast: {
              formula: updatedForecastFormula,
            },
          },
        },
        existingDriverId: null,
      };

      const dimDriverUpdate: DriverUpdateInput = {
        id: dimDriverId,
        newSubDrivers: [newSubDriver],
      };
      updateDrivers.push(dimDriverUpdate);
    } else {
      // update existing subdriver
      if (subdriverFormulaUpdateMutation != null) {
        updateDrivers.push(subdriverFormulaUpdateMutation);
      }
      // if we are adding a database key, update the subdriver attributes
      // if we are removing a database key, don't update the subdriver attributes as this is
      // handled by the backend driver handler
      if (!isNewDBKey) {
        continue;
      }
      const dimDriverUpdate: DriverUpdateInput = {
        id: dimDriverId,

        dimensional: {
          updateSubDrivers: [
            {
              ...attributeListToGqlAttrs(subdriver?.attributes ?? []),
              newAttributeIds: newGqlAttrs.attributeIds,
              newBuiltInAttributes: newGqlAttrs.builtInAttributes,
            },
          ],
        },
      };
      updateDrivers.push(dimDriverUpdate);
    }
  }

  return updateDrivers;
};

export const updateDimensionalPropertyEntryMutation = (
  state: RootState,
  {
    newAttributeId,
    dimensionalPropertyId,
    objectId,
  }: {
    newAttributeId: AttributeId | null;
    dimensionalPropertyId: DimensionalPropertyId;
    objectId: BusinessObjectId;
  },
): BusinessObjectUpdateInput => {
  const collectionEntry = collectionEntryForBusinessObjectIdSelector(state, objectId);
  const exists =
    collectionEntry != null &&
    collectionEntry.attributeProperties.some(
      (entry) => entry.dimensionalPropertyId === dimensionalPropertyId,
    );

  let collectionEntryUpdate: CollectionEntryUpdateInput;
  if (newAttributeId == null) {
    collectionEntryUpdate = {
      removeAttributeProperties: [dimensionalPropertyId],
    };
  } else if (exists) {
    collectionEntryUpdate = {
      updateAttributeProperties: [{ dimensionalPropertyId, attributeId: newAttributeId }],
    };
  } else {
    collectionEntryUpdate = {
      addAttributeProperties: [{ dimensionalPropertyId, attributeId: newAttributeId }],
    };
  }

  return {
    id: objectId,
    updateCollectionEntry: collectionEntryUpdate,
  };
};

const databaseSubDriverFormulaUpdateOnKeyChangeHelper = (
  state: RootState,
  {
    objectSpecId,
    dimPropertyId,
    shouldDelete,
  }: {
    objectSpecId: BusinessObjectSpecId;
    dimPropertyId: DimensionalPropertyId;
    shouldDelete: boolean;
  },
): DatasetMutationInput['updateDrivers'] => {
  const businessObjects = businessObjectsBySpecIdForLayerSelector(state)[objectSpecId] ?? [];
  let updates: DriverUpdateInput[] = [];
  businessObjects.forEach((object) => {
    const attributeId = shouldDelete
      ? null
      : dimensionalPropertyEvaluatorSelector(state).getAttributeProperty({
          dimensionalPropertyId: dimPropertyId,
          objectId: object.id,
        })?.attribute.id ?? null;

    const update = updateDriversFromDimensionalPropertyChangeMutation(state, {
      newAttributeId: attributeId,
      dimensionalPropertyId: dimPropertyId,
      objectId: object.id,
      objectSpecId: object.specId,
      shouldSkipAttrValidation: false,
      isNewDBKey: !shouldDelete,
    })?.filter(isNotNull);
    if (update != null) {
      updates = updates.concat(...update);
    }
  });

  // should parse updates to find out what attributes are changing and the previous
  return updates;
};

const dimDriverUpdateForChangedDimensionalPropertyKeyMutation = (
  state: RootState,
  {
    objectSpecId,
    dimensionalPropertyId,
    shouldDelete,
  }: {
    objectSpecId: BusinessObjectSpecId;
    dimensionalPropertyId: DimensionalPropertyId;
    shouldDelete: boolean;
  },
): { updateDrivers?: DatasetMutationInput['updateDrivers'] } => {
  const dimensionalProperty = dimensionalPropertySelector(state, dimensionalPropertyId);
  const dimensionalDriversByid = dimensionalDriversByIdSelector(state);

  const dimensionId = dimensionalProperty?.dimension.id;

  if (!shouldDelete && dimensionId == null) {
    return {};
  }

  const driverProperties = driverPropertiesForBusinessObjectSpecSelector(state, objectSpecId);

  const dimDriverUpdates = driverProperties
    .map((p) => {
      const dimDriverId = p.driverId;
      const dimDriver = dimensionalDriversByid[dimDriverId];

      if (dimDriver?.type !== DriverType.Dimensional) {
        return null;
      }
      const existingDimIds = dimDriver.dimensions
        .filter((d) => d.id !== dimensionId)
        .map((d) => d.id);
      const updatedDimIds = shouldDelete
        ? existingDimIds
        : [...existingDimIds, dimensionId].filter(isNotNull);

      return {
        id: p.driverId,
        dimensional: {
          dimensionIds: updatedDimIds,
        },
      };
    })
    .filter(isNotNull);

  const subDriverUpdates = databaseSubDriverFormulaUpdateOnKeyChangeHelper(state, {
    objectSpecId,
    dimPropertyId: dimensionalPropertyId,
    shouldDelete,
  });

  if (subDriverUpdates == null) {
    return {
      updateDrivers: dimDriverUpdates,
    };
  }

  return {
    updateDrivers: [...dimDriverUpdates, ...subDriverUpdates],
  };
};
