import {
  DefaultFormula,
  DimensionalSubDriverSourceType,
  DriverType,
  Driver as GQLDriver,
  Maybe,
  RollupReducer,
  TrackParentLayerFields,
} from 'generated/graphql';
import { lastMonthSelfDriverRef } from 'helpers/formula';
import { Coloring } from 'reduxStore/models/common';
import { Attribute, AttributeId, Dimension, DimensionId } from 'reduxStore/models/dimensions';
import { NonNumericTimeSeries, NumericTimeSeries } from 'reduxStore/models/timeSeries';

export type DriverId = string;
export { DriverFormat, DriverType } from 'generated/graphql';

export type CommonDriver = Pick<
  GQLDriver,
  | 'name'
  | 'format'
  | 'leverType'
  | 'sortIndex'
  | 'description'
  | 'generatedExplanation'
  | 'groupId'
  | 'currencyISOCode'
  | 'decimalPlaces'
  | 'valueTypeExtension'
  | 'pivotBy'
  | 'driverReferences'
  | 'driverMapping'
> & {
  id: DriverId;
  /** @deprecated check GQLDriver['submodelId'] */
  submodelId?: NonNullable<GQLDriver['submodelId']>;
  coloring: Coloring;
};

export type BasicDriver = CommonDriver & {
  type: DriverType.Basic;
  actuals: {
    formula?: string;
    timeSeries?: NumericTimeSeries | NonNumericTimeSeries;
  };
  forecast: {
    formula: string;
  };
  rollup?: {
    reducer: RollupReducer;
  };
  trackParentLayerFields?: Maybe<TrackParentLayerFields>;
};

export type DimensionalSubDriver = {
  attributes: Attribute[];
  driverId: DriverId;
  source?: DimensionalSubDriverSource | null;
};

export type DimensionalSubDriverSource = {
  type: DimensionalSubDriverSourceType;
  extQueryId?: string;
};

export type DimensionalDriver = CommonDriver & {
  type: DriverType.Dimensional;
  dimensions: Dimension[];
  subdrivers: DimensionalSubDriver[];
  defaultForecast?: DefaultFormula;
  defaultActuals?: DefaultFormula;
};

export type Driver = BasicDriver | DimensionalDriver;

export type NormalizedDimensionalSubDriver = Omit<DimensionalSubDriver, 'attributes'> & {
  attributeIds: AttributeId[];
};

export type NormalizedDimensionalDriver = Omit<DimensionalDriver, 'dimensions' | 'subdrivers'> & {
  dimensionIds: DimensionId[];
  subdrivers: NormalizedDimensionalSubDriver[];
};

export type NormalizedDriver = BasicDriver | NormalizedDimensionalDriver;

export type FormulaSourceType = 'forecast' | 'actuals' | 'defaultForecast' | 'defaultActuals';

/**
 * This is the resolved formula used during calculations
 */
export function getDriverForecastFormulaForCalculation(
  driver: Driver | NormalizedDriver,
  parentDimDriver?: Driver | NormalizedDriver | undefined | null,
  useEmptySetFormula = false,
): {
  formula: string;
  sourceDriverId: DriverId;
  isFormulaSet: boolean;
  sourceType: FormulaSourceType;
} {
  if (driver.type !== DriverType.Basic) {
    const defaultForecastFormula = useEmptySetFormula ? '' : driver.defaultForecast?.formula ?? '';
    return {
      formula: defaultForecastFormula,
      sourceDriverId: driver.id,
      isFormulaSet: true,
      sourceType: 'defaultForecast',
    };
  }
  const forecastFormula = useEmptySetFormula ? '' : driver.forecast.formula;
  if (forecastFormula != null && forecastFormula !== '') {
    return {
      formula: forecastFormula,
      sourceDriverId: driver.id,
      isFormulaSet: true,
      sourceType: 'forecast',
    };
  }
  if (parentDimDriver != null) {
    const parentFormula = getDriverForecastFormulaForCalculation(parentDimDriver).formula;
    if (parentFormula !== '') {
      return {
        formula: parentFormula,
        sourceDriverId: parentDimDriver.id,
        isFormulaSet: false,
        sourceType: 'defaultForecast',
      };
    }
  }
  return {
    formula: lastMonthSelfDriverRef(driver.id),
    sourceDriverId: driver.id,
    isFormulaSet: true,
    sourceType: 'forecast',
  };
}

/**
 * This is the resolved formula used during calculations
 */
export function getDriverActualsFormulaForCalculation(
  driver: Driver | NormalizedDriver,
  parentDimDriver?: Driver | NormalizedDriver | undefined | null,
  useEmptySetFormula = false,
): {
  formula: string;
  sourceDriverId: DriverId;
  isFormulaSet: boolean;
  sourceType: FormulaSourceType;
} {
  if (driver.type !== DriverType.Basic) {
    const defaultActualsFormula = useEmptySetFormula ? '' : driver.defaultActuals?.formula ?? '';
    return {
      formula: defaultActualsFormula,
      sourceDriverId: driver.id,
      isFormulaSet: true,
      sourceType: 'defaultActuals',
    };
  }
  const actualsFormula = useEmptySetFormula ? '' : driver.actuals.formula;
  if (actualsFormula != null && actualsFormula !== '') {
    return {
      formula: actualsFormula,
      sourceDriverId: driver.id,
      isFormulaSet: true,
      sourceType: 'actuals',
    };
  }
  if (parentDimDriver != null) {
    const parentFormula = getDriverActualsFormulaForCalculation(parentDimDriver).formula;
    if (parentFormula !== '') {
      return {
        formula: parentFormula,
        sourceDriverId: parentDimDriver.id,
        isFormulaSet: false,
        sourceType: 'defaultActuals',
      };
    }
  }
  return {
    ...getDriverForecastFormulaForCalculation(driver, parentDimDriver),
    isFormulaSet: false,
  };
}
