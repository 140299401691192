import React from 'react';

import DataSourceLogo from 'components/DataSourceLogo/DataSourceLogo';
import DriverAttributeBadge from 'components/DriverAttributeBadges/DriverAttributeBadge';
import DimensionBadge from 'components/Formula/DimensionBadge';
import FormulaEntityChunk from 'components/Formula/FormulaEntityChunk';
import { isAttribute } from 'helpers/dimensionalDrivers';
import { ExtQueryFormulaDisplayChunk } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaDisplayListener';
import useAppSelector from 'hooks/useAppSelector';
import { dimensionsByIdSelector } from 'selectors/dimensionsSelector';

interface Props {
  chunk: ExtQueryFormulaDisplayChunk;
}

const ExtQueryChunk: React.FC<Props> = ({ chunk }) => {
  const { timeRange, timeRangeDisplay, displayName, attributeFilters } = chunk;
  const dimById = useAppSelector(dimensionsByIdSelector);
  return (
    <FormulaEntityChunk
      timeRange={timeRange}
      icon={chunk.source != null ? <DataSourceLogo source={chunk.source} size="xs" /> : undefined}
      timeRangeDisplay={timeRangeDisplay}
      label={displayName}
      attributes={
        attributeFilters != null && (
          <>
            {Object.entries(attributeFilters).flatMap(([dimId, attrs]) =>
              attrs.map((a) =>
                isAttribute(a) ? (
                  <DriverAttributeBadge key={a.id} attribute={a} size="xs" />
                ) : (
                  <DimensionBadge key={dimId} dimension={dimById[dimId]} type={a} />
                ),
              ),
            )}
          </>
        )
      }
    />
  );
};

export default ExtQueryChunk;
