import { Flex, StyleProps } from '@chakra-ui/react';
import React, { useCallback, useContext, useMemo } from 'react';

import BusinessObjectFieldInitialValueCell from 'components/BusinessObjectFieldInitialValueCell/BusinessObjectFieldInitialValueCell';
import { DriverPropertyAsTimeSeriesRow } from 'components/BusinessObjectTable/ObjectFieldAsTimeSeriesRow';
import EmptyObjectFieldTimeSeriesCell from 'components/EmptyObjectFieldTimeSeriesCell/EmptyObjectFieldTimeSeriesCell';
import ObjectContextMenu from 'components/ObjectContextMenu/ObjectContextMenu';
import ObjectPropertyCell from 'components/ObjectPropertyCell/ObjectPropertyCell';
import { eventIdsByMonthKeys } from 'components/SelectionInspectorContents/helpers';
import { StickyContext } from 'components/StickyHeader/StickyContext';
import RowContainer from 'components/Table/RowContainer';
import ObjectFieldInspectorTimeSeriesRow from 'components/TimelineRow/BusinessObjectFieldInspectorTimeSeriesRow';
import FixedSizeVirtualizedVerticalList from 'components/VirtualizedList/FixedSizeVirtualizedVerticalList';
import { RenderedItemsInfo } from 'components/VirtualizedList/types';
import { CELL_HEIGHT_IN_PX } from 'config/cells';
import { ObjectFieldTimeSeriesRowContext } from 'config/objectFieldTimeSeriesRowContext';
import { isNotNull } from 'helpers/typescript';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { useRequestObjectFieldSpecDataRequester } from 'hooks/useRequestObjectFieldSpecDataRequester';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { LayerId } from 'reduxStore/models/layers';
import { businessObjectSelector } from 'selectors/businessObjectsSelector';
import { driverPropertySelector } from 'selectors/collectionSelector';
import { eventsForBusinessObjectSelector } from 'selectors/eventsAndGroupsSelector';
import { currentLayerIdSelector } from 'selectors/layerSelector';
import {
  isShowingComparisonDriverHeaderRowSelector,
  timeSeriesColumnsForBlockSelector,
} from 'selectors/rollupSelector';
import { comparisonLayerIdsForBlockSelector } from 'selectors/scenarioComparisonSelector';

const ObjectFieldTimeSeriesStickyCells: React.FC<{
  isLastRow: boolean;
  isStartField?: boolean;
  pl?: StyleProps['pl'];
  showInitialValue?: boolean;
}> = React.memo(({ isLastRow, isStartField, pl, showInitialValue = true }) => {
  return (
    <Flex
      flexShrink={0}
      position="sticky"
      left={0}
      zIndex="docked"
      role="group"
      pl={pl}
      background="white"
    >
      <ObjectPropertyCell isLast={isLastRow} />
      {showInitialValue && <BusinessObjectFieldInitialValueCell isStartField={isStartField} />}
    </Flex>
  );
});

const ObjectFieldComparisonHeaderRow: React.FC<{
  isFirstRow: boolean;
  businessObjectId: BusinessObjectId;
  fieldSpecId: BusinessObjectFieldSpecId;
  isLastRow: boolean;
  pl?: StyleProps['pl'];
}> = React.memo(({ isFirstRow, businessObjectId, fieldSpecId, isLastRow, pl }) => {
  const { blockId } = useBlockContext();
  const ctx = useMemo(() => {
    return {
      businessObjectId,
      fieldSpecId,
      layerId: undefined,
    };
  }, [businessObjectId, fieldSpecId]);

  const columns = useAppSelector((state) => timeSeriesColumnsForBlockSelector(state, blockId));
  return (
    <ObjectFieldTimeSeriesRowContext.Provider value={ctx}>
      <RowContainer testId="field-comparison-header-row" isFirstRow={isFirstRow}>
        <ObjectFieldTimeSeriesStickyCells isLastRow={isLastRow} pl={pl} />
        {columns.map(({ mks, subLabel }) => {
          const label = subLabel?.column;
          const key = `${label}/${mks[0]}`;
          return (
            <EmptyObjectFieldTimeSeriesCell
              key={key}
              monthKey={mks[0]}
              layerId={undefined}
              subLabel={subLabel}
            />
          );
        })}
      </RowContainer>
    </ObjectFieldTimeSeriesRowContext.Provider>
  );
});

interface ObjectFieldTimeSeriesRowProps extends Props {
  layerId: LayerId | undefined;
  isStartField?: boolean;
  showInitialValue?: boolean;
}

const ObjectFieldTimeSeriesRow: React.FC<ObjectFieldTimeSeriesRowProps> = React.memo(
  ({
    layerId,
    businessObjectId,
    fieldSpecId,
    isFirstRow,
    isLastRow,
    isStartField,
    pl,
    showInitialValue,
  }) => {
    const ctx = useMemo(() => {
      return {
        businessObjectId,
        fieldSpecId,
        layerId,
      };
    }, [businessObjectId, fieldSpecId, layerId]);

    const dimDriverId = useAppSelector((state) => {
      return driverPropertySelector(state, fieldSpecId)?.driverId;
    });

    const events = useAppSelector((state) =>
      eventsForBusinessObjectSelector(state, businessObjectId),
    );
    const object = useAppSelector((state) => businessObjectSelector(state, businessObjectId));
    const field = object?.fields.find((f) => f.fieldSpecId === fieldSpecId);
    const eventsImpactingField = events.filter((e) => e.businessObjectFieldId === field?.id);

    return (
      <ObjectFieldTimeSeriesRowContext.Provider value={ctx}>
        <ObjectContextMenu>
          <RowContainer
            key={`${businessObjectId}/${fieldSpecId}/${layerId}`}
            isFirstRow={isFirstRow}
            testId="object-property-row"
          >
            <ObjectFieldTimeSeriesStickyCells
              isStartField={isStartField}
              isLastRow={isLastRow}
              pl={pl}
              showInitialValue={showInitialValue}
            />
            {dimDriverId != null ? (
              <DriverPropertyAsTimeSeriesRow
                driverId={dimDriverId}
                businessObjectId={businessObjectId}
                showFormula={false}
              />
            ) : (
              <ObjectFieldInspectorTimeSeriesRow
                isEmpty={isStartField}
                eventIdsByMonthKey={eventIdsByMonthKeys(eventsImpactingField)}
              />
            )}
          </RowContainer>
        </ObjectContextMenu>
      </ObjectFieldTimeSeriesRowContext.Provider>
    );
  },
);

interface Props {
  businessObjectId: BusinessObjectId;
  fieldSpecId: BusinessObjectFieldSpecId;
  isStartField?: boolean;
  isFirstRow: boolean;
  isLastRow: boolean;
  pl?: StyleProps['pl'];
  showInitialValue?: boolean;
}

const getItemHeight = () => CELL_HEIGHT_IN_PX;

const ObjectFieldTimeSeriesRows: React.FC<Props> = ({
  businessObjectId,
  isStartField,
  fieldSpecId,
  isFirstRow,
  isLastRow,
  pl,
  showInitialValue = true,
}) => {
  const { blockId } = useBlockContext();
  const isComparisonLayout = useAppSelector((state) =>
    isShowingComparisonDriverHeaderRowSelector(state, blockId),
  );
  let layerComparison: Array<string | undefined> = useAppSelector((state) =>
    comparisonLayerIdsForBlockSelector(state, blockId),
  );

  const hasNoVersions = layerComparison.length === 0;
  if (hasNoVersions) {
    layerComparison = [undefined];
  }
  const currentLayerId = useAppSelector(currentLayerIdSelector);
  const { verticalScrollingTargetRef } = useContext(StickyContext);
  const objectFieldSpecDataRequester = useRequestObjectFieldSpecDataRequester(blockId);

  const onItemsRendered = useCallback(
    (rowsInfo: RenderedItemsInfo) => {
      const { startIndex, stopIndex } = rowsInfo;
      const visibleLayerComparisons = layerComparison
        .slice(startIndex, stopIndex + 1)
        .filter(isNotNull);
      const requests = visibleLayerComparisons.map((layerId) => ({
        fieldSpecId,
        layerId,
        objectId: businessObjectId,
      }));
      //we always request values for the current layer
      requests.push({
        fieldSpecId,
        layerId: currentLayerId,
        objectId: businessObjectId,
      });
      objectFieldSpecDataRequester.requestDataForFieldSpec(requests);
    },
    [businessObjectId, currentLayerId, fieldSpecId, layerComparison, objectFieldSpecDataRequester],
  );

  return (
    <>
      {isComparisonLayout && (
        <ObjectFieldComparisonHeaderRow
          businessObjectId={businessObjectId}
          fieldSpecId={fieldSpecId}
          isFirstRow={isFirstRow}
          isLastRow={isLastRow}
          pl={pl}
        />
      )}
      <FixedSizeVirtualizedVerticalList
        verticalScrollTargetRef={verticalScrollingTargetRef}
        getItemHeight={getItemHeight}
        onItemsRendered={onItemsRendered}
      >
        {layerComparison.map((layerId) => (
          <ObjectFieldTimeSeriesRow
            key={`${fieldSpecId}/${layerId}`}
            businessObjectId={businessObjectId}
            isStartField={isStartField}
            fieldSpecId={fieldSpecId}
            layerId={layerId}
            isFirstRow={isFirstRow && !isComparisonLayout}
            isLastRow={isLastRow}
            pl={pl}
            showInitialValue={showInitialValue}
          />
        ))}
      </FixedSizeVirtualizedVerticalList>
    </>
  );
};

export default React.memo(ObjectFieldTimeSeriesRows);
