import { deepEqual } from 'fast-equals';
import { isEmpty, noop } from 'lodash';
import difference from 'lodash/difference';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import uniq from 'lodash/uniq';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import {
  ComparisonColumnInfo,
  DriverComparisonColumnInfo,
} from 'components/CompareScenariosModalContent/diffTypes';
import { OBJECT_NAME_FIELD_NAME } from 'config/businessObjects';
import { SCENARIO_COMPARISON_PAGE_TYPE } from 'config/internalPages/scenarioComparisonPage';
import { ComparisonTimePeriod, ValueType } from 'generated/graphql';
import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import { isNotNull, nullSafeEqual, safeObjGet } from 'helpers/typescript';
import { Block, BlockId } from 'reduxStore/models/blocks';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpec,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import {
  BusinessObject,
  BusinessObjectField,
  BusinessObjectFieldId,
  BusinessObjectId,
} from 'reduxStore/models/businessObjects';
import { Collection } from 'reduxStore/models/collections';
import { DriverId, DriverType, NormalizedDriver } from 'reduxStore/models/drivers';
import { isDriverEvent, isObjectFieldEvent } from 'reduxStore/models/events';
import { DEFAULT_LAYER_ID, Layer, LayerId } from 'reduxStore/models/layers';
import { MutationBatch } from 'reduxStore/models/mutations';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { baselineLayerIdForBlockSelector } from 'selectors/baselineLayerSelector';
import { blocksPageForBlockIdSelector } from 'selectors/blocksPagesSelector';
import { blocksPageByInternalPageTypeSelector } from 'selectors/blocksPagesTableSelector';
import { blockConfigSelector, blocksByIdSelector } from 'selectors/blocksSelector';
import { collectionsByObjectSpecIdSelector } from 'selectors/collectionSelector';
import { blockIdSelector, fieldSelector, paramSelector } from 'selectors/constSelectors';
import { DriverForLayerProps, cacheKeyForDriverForLayerSelector } from 'selectors/driversSelector';
import { enableNewMergeScreenSelector } from 'selectors/launchDarklySelector';
import {
  currentLayerIdSelector,
  layersSelector,
  nonDeletedLayerIdsSelector,
} from 'selectors/layerSelector';
import { globalScenariosSelector, nonDraftScenariosSelector } from 'selectors/scenariosSelector';
import { ParametricSelector, Selector } from 'types/redux';

export const scenarioComparisonPageDriverBlockIdSelector: Selector<BlockId | null> = createSelector(
  blocksPageByInternalPageTypeSelector,
  (pagesByInternalPageType) => {
    const page = pagesByInternalPageType[SCENARIO_COMPARISON_PAGE_TYPE];
    if (page == null || page.blockIds.length === 0) {
      return null;
    }

    return page.blockIds[0];
  },
);

export const scenarioComparisonPageDatabaseBlockIdSelector: Selector<BlockId | null> =
  createSelector(blocksPageByInternalPageTypeSelector, (pagesByInternalPageType) => {
    const page = pagesByInternalPageType[SCENARIO_COMPARISON_PAGE_TYPE];
    if (page == null || page.blockIds.length === 0) {
      return null;
    }

    return page.blockIds[1];
  });

export const comparisonLayerIdsForBlockSelector = createCachedSelector(
  paramSelector<BlockId>(),
  blockConfigSelector,
  layersSelector,
  baselineLayerIdForBlockSelector,
  (_blockId, blockConfig, layers, baselineLayerId) => {
    const comparisonLayers =
      blockConfig?.comparisons?.layerIds.filter(
        (layerId) => layers[layerId] != null && !layers[layerId].isDeleted,
      ) ?? [];

    const otherComparisonLayers = comparisonLayers.filter((layerId) => layerId !== baselineLayerId);
    return otherComparisonLayers.length > 0 ? [baselineLayerId, ...otherComparisonLayers] : [];
  },
)({
  keySelector: blockIdSelector,
  selectorCreator: createDeepEqualSelector,
});

export const comparisonTimePeriodsForBlockSelector: ParametricSelector<
  BlockId,
  ComparisonTimePeriod[]
> = createSelector(paramSelector<BlockId>(), blockConfigSelector, (_blockId, blockConfig) => {
  return blockConfig?.comparisons?.timePeriods ?? [];
});

export const scenarioComparisonPageBlockSelector: Selector<Block | null> = createSelector(
  scenarioComparisonPageDriverBlockIdSelector,
  blocksByIdSelector,
  (scenarioComparisonBlockId, blocksById) => {
    if (scenarioComparisonBlockId == null) {
      return null;
    }

    return blocksById[scenarioComparisonBlockId] ?? null;
  },
);

export const isScenarioComparisonDriverBlockSelector: ParametricSelector<BlockId, boolean> =
  createCachedSelector(
    scenarioComparisonPageDriverBlockIdSelector,
    blockIdSelector,
    (scenarioComparisonBlockId, blockId) => {
      return scenarioComparisonBlockId === blockId;
    },
  )(blockIdSelector);

export const isScenarioComparisonDatabaseBlockSelector: ParametricSelector<BlockId, boolean> =
  createCachedSelector(
    scenarioComparisonPageDatabaseBlockIdSelector,
    blockIdSelector,
    (scenarioComparisonBlockId, blockId) => {
      return scenarioComparisonBlockId === blockId;
    },
  )(blockIdSelector);

export const scenarioComparisonBaselineLayerIdSelector: Selector<LayerId> = createSelector(
  scenarioComparisonPageBlockSelector,
  (scenarioComparisonBlock) => {
    return scenarioComparisonBlock?.blockConfig.comparisons?.baselineLayerId ?? DEFAULT_LAYER_ID;
  },
);

export const isBaselineLayerSelector: ParametricSelector<LayerId, boolean> = createCachedSelector(
  (state: RootState) => scenarioComparisonBaselineLayerIdSelector(state),
  paramSelector<LayerId>(),
  (baselineLayerId, layerId) => {
    return baselineLayerId === layerId;
  },
)((_state, layerId) => layerId);

export const fixedLayerIdsToCompareSelector: Selector<LayerId[]> = createDeepEqualSelector(
  scenarioComparisonBaselineLayerIdSelector,
  currentLayerIdSelector,
  (baselineLayerId, currentLayerId) => [
    baselineLayerId,
    ...(currentLayerId !== baselineLayerId ? [currentLayerId] : []),
  ],
);

export const additionalLayerIdsToCompareSelector: Selector<LayerId[]> = createDeepEqualSelector(
  scenarioComparisonPageBlockSelector,
  fixedLayerIdsToCompareSelector,
  nonDeletedLayerIdsSelector,
  (block, fixedLayerIds, allLayerIds) => {
    const compareLayerIds = block?.blockConfig.comparisons?.layerIds ?? [];
    return compareLayerIds
      .slice(fixedLayerIds.length)
      .filter((layerId) => allLayerIds.includes(layerId));
  },
);

export const additionalLayerIdsToCompareOptionsSelector: Selector<LayerId[]> =
  createDeepEqualSelector(
    nonDraftScenariosSelector,
    currentLayerIdSelector,
    globalScenariosSelector,
    (nonDraftLayers, currentLayerId, layersById) => {
      const allLayerIds = Object.values(nonDraftLayers).map((layer) => layer.id);
      const layerIdOptions = difference(allLayerIds, [currentLayerId, DEFAULT_LAYER_ID]);
      return layerIdOptions.filter((layerId) => layersById[layerId] != null);
    },
  );

export const nextAdditionalLayerIdToCompareOptionSelector: Selector<LayerId | undefined> =
  createSelector(
    additionalLayerIdsToCompareOptionsSelector,
    additionalLayerIdsToCompareSelector,
    (allLayerIdOptions, layerIdsToCompare) => {
      const layerIdOptions = difference(allLayerIdOptions, layerIdsToCompare);
      return layerIdOptions.length > 0 ? layerIdOptions[0] : undefined;
    },
  );

export const uniqueAdditionalLayerIdsToCompareSelector: Selector<LayerId[]> =
  createDeepEqualSelector(additionalLayerIdsToCompareSelector, (additionalLayerIds) =>
    uniq(additionalLayerIds),
  );

export const allLayerIdsToCompareSelector: Selector<LayerId[]> = createDeepEqualSelector(
  uniqueAdditionalLayerIdsToCompareSelector,
  (additionalLayerIds) => [DEFAULT_LAYER_ID, ...additionalLayerIds],
);

export const nonBaselineLayerIdsToCompareSelector: Selector<LayerId[]> = createDeepEqualSelector(
  fixedLayerIdsToCompareSelector,
  uniqueAdditionalLayerIdsToCompareSelector,
  scenarioComparisonBaselineLayerIdSelector,
  (fixedLayerIds, additionalLayerIds, baselineLayerId) => [
    ...fixedLayerIds.filter((layerId) => layerId !== baselineLayerId),
    ...additionalLayerIds,
  ],
);

export const layerIdToMergeSelector: Selector<LayerId | undefined> = createDeepEqualSelector(
  nonBaselineLayerIdsToCompareSelector,
  (nonBaselineLayerIds) => (nonBaselineLayerIds.length > 0 ? nonBaselineLayerIds[0] : undefined),
);

type BaseObjectDiff = {
  objectId: BusinessObjectId;
  objectSpecId: BusinessObjectSpecId;
  objectName: string;
  objectSpecName: string;
};

export type ObjectFieldDiff = BaseObjectDiff & {
  fieldId: BusinessObjectFieldSpecId;
  fieldSpecId: BusinessObjectFieldSpecId;
  isStartField: boolean;
};

export type ObjectAddOrDeleteDiff = BaseObjectDiff & {
  diffType: 'add' | 'delete';
};

export type ObjectRenameDiff = BaseObjectDiff & {
  diffType: 'rename';
  newName: string;
};

export type ObjectDiff = ObjectFieldDiff | ObjectAddOrDeleteDiff | ObjectRenameDiff;

const MAX_DRIVER_DIFFS = 250;
const MAX_OBJECT_DIFFS = 250;

const maxDriverDiffsReached = ({ driverIds }: { driverIds: Set<DriverId> }) => {
  return driverIds.size > MAX_DRIVER_DIFFS;
};

const maxObjectDiffsReached = ({ objectDiffKeys }: { objectDiffKeys: Set<string> }) => {
  return objectDiffKeys.size > MAX_OBJECT_DIFFS;
};

const maxDiffsReached = ({
  driverIds,
  objectDiffKeys,
}: {
  driverIds: Set<DriverId>;
  objectDiffKeys: Set<string>;
}) => {
  return maxDriverDiffsReached({ driverIds }) || maxObjectDiffsReached({ objectDiffKeys });
};

const scenarioComparisonDiffsSelector = createSelector(
  enableNewMergeScreenSelector,
  scenarioComparisonBaselineLayerIdSelector,
  nonBaselineLayerIdsToCompareSelector,
  layersSelector,
  collectionsByObjectSpecIdSelector,
  (usingNewMergeScreen, baselineLayerId, layerIds, layers, collectionsByObjectSpecId) => {
    if (usingNewMergeScreen) {
      return {
        driverIds: new Set<DriverId>(),
        objectDiffs: [],
        maxObjectDiffsReached: false,
        maxDriverDiffsReached: false,
        drivers: {},
        objectSpecs: {},
      };
    }
    const eventsByLayerId = mapValues(layers, (layer) => layer.events.byId);
    const objectsByLayerId = mapValues(layers, (layer) => layer.businessObjects.byId);
    const objectSpecsByLayerId = mapValues(layers, (layer) => layer.businessObjectSpecs.byId);
    const driversByLayerId = mapValues(layers, (layer) => layer.drivers.byId);

    const driverIds = new Set<DriverId>();
    const deletedObjectIds = new Set<DriverId>();
    const objectDiffKeys = new Set<string>();
    const objectDiffs: ObjectDiff[] = [];
    const baselineLayerEvents = eventsByLayerId[baselineLayerId] ?? {};

    const layeredDriverDiff: LayeredDriverDiffs = {};
    const objectSpecDiffsByLayer: ObjectSpecDiffsByLayer = {};

    const addObjectDiff = (objectDiff: ObjectDiff) => {
      const key =
        'fieldSpecId' in objectDiff
          ? `${objectDiff.objectId}-${objectDiff.fieldSpecId}`
          : objectDiff.objectId;
      if (!objectDiffKeys.has(key)) {
        objectDiffKeys.add(key);
        objectDiffs.push(objectDiff);
      }
    };

    const getBaseObjectDiff = (object: BusinessObject, objectSpec: BusinessObjectSpec) => {
      return {
        objectId: object.id,
        objectName: object.name,
        objectSpecId: objectSpec.id,
        objectSpecName: objectSpec.name,
      };
    };

    const getComparisonObjectsForField = (
      field: BusinessObjectField,
      object: BusinessObject,
      objectSpec: BusinessObjectSpec,
    ): ObjectDiff => {
      return {
        ...getBaseObjectDiff(object, objectSpec),
        fieldId: field.id,
        fieldSpecId: field.fieldSpecId,
        isStartField: objectSpec?.startFieldId === field.fieldSpecId,
      };
    };

    const getAddOrDeleteDiffForObject = (
      object: BusinessObject,
      objectSpec: BusinessObjectSpec,
      diffType: 'add' | 'delete',
    ) => {
      return {
        ...getBaseObjectDiff(object, objectSpec),
        diffType,
      };
    };

    const getRenameDiffForObject = (
      oldObject: BusinessObject,
      newObject: BusinessObject,
      objectSpec: BusinessObjectSpec,
    ) => {
      return {
        ...getBaseObjectDiff(oldObject, objectSpec),
        diffType: 'rename' as const,
        newName: newObject.name,
      };
    };

    // This function is expensive - we should avoid calling it on large lists of objects when we can.
    const getObjectsByFieldId = (objects: BusinessObject[], layerId: LayerId) =>
      keyBy(
        objects.flatMap((o) => {
          const objectSpec = objectSpecsByLayerId[layerId][o.specId];
          if (objectSpec == null) {
            return [];
          }

          return o.fields.map((f) => getComparisonObjectsForField(f, o, objectSpec));
        }),
        'fieldId',
      );

    const baselineLayerObjectsById = objectsByLayerId[baselineLayerId];
    const baselineLayerObjectsByFieldId = getObjectsByFieldId(
      Object.values(baselineLayerObjectsById),
      baselineLayerId,
    );
    const driversInBaselineLayerById = driversByLayerId[baselineLayerId];

    for (const layerId of layerIds) {
      const comparisonLayer = layers[layerId];
      const comparisonLayerObjectsById = objectsByLayerId[layerId];
      const {
        objects: objectDiffsFromMutations,
        events: eventDiffsFromMutations,
        drivers: driverDiffsFromMutations,
        objectSpecs: specDiffsFromMutations,
      } = getDiffsFromMutations(comparisonLayer.mutationBatches, {
        driversInBaselineLayerById,
        collectionsByObjectSpecId,
      });

      const addedObjects: BusinessObject[] = [];

      Object.entries(objectDiffsFromMutations).forEach(([objectId, diff]) => {
        if (maxDiffsReached({ driverIds, objectDiffKeys })) {
          return;
        }

        if (diff == null) {
          return;
        }

        const baselineObject = safeObjGet(baselineLayerObjectsById[objectId]);
        const comparisonObject = safeObjGet(comparisonLayerObjectsById[objectId]);

        if (diff.type === 'added' && comparisonObject != null) {
          addedObjects.push(comparisonObject);
          const objectSpec = objectSpecsByLayerId[layerId][comparisonObject.specId];
          addObjectDiff(getAddOrDeleteDiffForObject(comparisonObject, objectSpec, 'add'));
          comparisonObject.fields.map((f) => {
            const anyNonEmptyActuals = Object.values(f.value?.actuals.timeSeries ?? {}).some(
              isNotNull,
            );
            const nonEmptyInitialValue =
              f.value?.initialValue != null && f.value.initialValue !== '';

            if (nonEmptyInitialValue || anyNonEmptyActuals) {
              addObjectDiff(getComparisonObjectsForField(f, comparisonObject, objectSpec));
            }
          });
          return;
        }

        if (diff.type === 'deleted' && baselineObject != null) {
          const objectSpec =
            objectSpecsByLayerId[layerId][baselineObject.specId] ??
            objectSpecsByLayerId[baselineLayerId][baselineObject.specId]; // it is possible that the object spec was deleted in the comparison layer. use baseline layer's object spec in that case
          addObjectDiff(getAddOrDeleteDiffForObject(baselineObject, objectSpec, 'delete'));
          deletedObjectIds.add(objectId);
          return;
        }

        if (diff.type === 'updated' && baselineObject != null && comparisonObject != null) {
          const objectSpec = objectSpecsByLayerId[layerId][baselineObject.specId];

          if (baselineObject.name !== comparisonObject.name) {
            addObjectDiff(getRenameDiffForObject(baselineObject, comparisonObject, objectSpec));
          }

          const baselineFieldsById = keyBy(baselineObject.fields, 'id');
          const comparisonFieldsById = keyBy(comparisonObject.fields, 'id');
          const allFieldIds = new Set([
            ...Object.keys(baselineFieldsById),
            ...Object.keys(comparisonFieldsById),
          ]);

          allFieldIds.forEach((fieldId) => {
            const baselineField = safeObjGet(baselineFieldsById[fieldId]);
            const comparisonField = safeObjGet(comparisonFieldsById[fieldId]);

            if (baselineField == null && comparisonField != null) {
              addObjectDiff(
                getComparisonObjectsForField(comparisonField, baselineObject, objectSpec),
              );
            }
            if (baselineField != null && comparisonField == null) {
              addObjectDiff(
                getComparisonObjectsForField(baselineField, baselineObject, objectSpec),
              );
            }
            if (baselineField != null && comparisonField != null) {
              if (
                !nullSafeEqual(
                  baselineField.value?.initialValue,
                  comparisonField.value?.initialValue,
                ) ||
                !deepEqual(baselineField.value?.actuals, comparisonField.value?.actuals)
              ) {
                addObjectDiff(
                  getComparisonObjectsForField(baselineField, baselineObject, objectSpec),
                );
              }
            }
          });
        }
      });

      const comparisonLayerEvents = eventsByLayerId[layerId] ?? {};
      // We only need to index new objects by fieldId, because for existing objects we can just
      // use the baseline layer's index. This is much more efficient than re-indexing all objects
      // on the comparison layer.
      const comparisonLayerObjectsByFieldId = getObjectsByFieldId(addedObjects, layerId);
      Object.entries(eventDiffsFromMutations).forEach(([eventId, eventDiff]) => {
        if (maxDiffsReached({ driverIds, objectDiffKeys })) {
          return;
        }

        if (eventDiff == null) {
          return;
        }

        if (eventDiff.type === 'updated' || eventDiff.type === 'deleted') {
          const baselineEvent = baselineLayerEvents[eventId];

          if (baselineEvent == null) {
            // It's possible that the event was deleted in the baseline layer after the
            // update mutation in comparison layer.
            return;
          }

          if (isDriverEvent(baselineEvent)) {
            driverIds.add(baselineEvent.driverId);
          } else if (isObjectFieldEvent(baselineEvent)) {
            const baselineObject =
              baselineLayerObjectsByFieldId[baselineEvent.businessObjectFieldId];
            if (baselineObject != null && !deletedObjectIds.has(baselineObject.objectId)) {
              addObjectDiff(baselineObject);
            }
          }
          return;
        }

        if (eventDiff.type === 'added') {
          const comparisonEvent = comparisonLayerEvents[eventId];
          if (comparisonEvent == null) {
            // It's possible that we marked this eventDiff as "added" but it doesn't exist in
            // the layer anymore. Side effects can cause this, for example: if we have a newEvent
            // mutation to create an event, it can get deleted from a deleteFields mutation if
            // the field is the one the event exists on.
            return;
          }
          if (isDriverEvent(comparisonEvent)) {
            driverIds.add(comparisonEvent.driverId);
          } else if (isObjectFieldEvent(comparisonEvent)) {
            const comparisonObject =
              comparisonLayerObjectsByFieldId[comparisonEvent.businessObjectFieldId] ??
              baselineLayerObjectsByFieldId[comparisonEvent.businessObjectFieldId];
            if (comparisonObject != null) {
              addObjectDiff(comparisonObject);
            }
          }
        }
      });

      const driverDiffs = getDriverDiff({
        layers,
        baselineLayerId,
        comparisonLayerId: layerId,
        driverDiffsFromMutations,
      });

      if (!isDriverDiffEmpty(driverDiffs)) {
        layeredDriverDiff[layerId] = driverDiffs;
      }

      const columnDiffs = getColumnDiff({
        layers,
        baselineLayerId,
        comparisonLayerId: layerId,
        specDiffsFromMutations,
      });
      if (!isEmpty(columnDiffs)) {
        objectSpecDiffsByLayer[layerId] = columnDiffs;
      }
    }

    return {
      // These don't need to be layerized, because we just show the row.
      // We don't actually care which layer things were changed on.
      // We'll need to do this eventually though
      driverIds,
      objectDiffs,
      maxObjectDiffsReached: maxObjectDiffsReached({ objectDiffKeys }),
      maxDriverDiffsReached: maxDriverDiffsReached({ driverIds }),
      drivers: layeredDriverDiff,
      objectSpecs: objectSpecDiffsByLayer,
    };
  },
);

export const scenarioComparisonDriverIdsSelector: Selector<DriverId[]> = createDeepEqualSelector(
  scenarioComparisonDiffsSelector,
  ({ driverIds, drivers }) => {
    const driverIdsSet = new Set(driverIds); // contains driverIds that are affected by impacts

    Object.values(drivers).forEach((driverDiff) => {
      if (driverDiff == null) {
        return;
      }
      // We only care about drivers that have either been added, deleted, have updated actuals, have updated formulas or impacting events.
      Object.entries(driverDiff.byId).forEach(([driverId, diffs]) => {
        if (
          diffs != null &&
          (diffs.has('updated_actuals_timeseries') ||
            diffs?.has('updated_formula') ||
            diffs.has('added') ||
            diffs.has('deleted'))
        ) {
          driverIdsSet.add(driverId);
        }
      });
    });

    return Array.from(driverIdsSet);
  },
);

export const scenarioComparisonMaxDriverDiffsReachedSelector: Selector<boolean> = createSelector(
  scenarioComparisonDiffsSelector,
  (diffs) => diffs.maxDriverDiffsReached,
);

export const scenarioComparisonMaxObjectDiffsReachedSelector: Selector<boolean> = createSelector(
  scenarioComparisonDiffsSelector,
  (diffs) => diffs.maxObjectDiffsReached,
);

const scenarioComparisonObjectDiffsSelector: Selector<ObjectDiff[]> = createDeepEqualSelector(
  scenarioComparisonDiffsSelector,
  ({ objectDiffs }) => objectDiffs,
);

export const scenarioComparisonFieldsByObjectIdByObjectSpecIdSelector: Selector<
  Record<BusinessObjectSpecId, Record<BusinessObjectId, ObjectDiff[]>>
> = createCachedSelector(scenarioComparisonObjectDiffsSelector, (objectDiffs) => {
  const groupedFields = groupBy(objectDiffs, 'objectSpecId');
  return mapValues(groupedFields, (grouped) => groupBy(grouped, 'objectId'));
})(currentLayerIdSelector);

const scenarioComparisonObjectSpecIds: Selector<BusinessObjectSpecId[]> = createSelector(
  scenarioComparisonFieldsByObjectIdByObjectSpecIdSelector,
  (comparisons) => Object.keys(comparisons),
);

export const identicalComparedDriversSelector: Selector<boolean> = createSelector(
  scenarioComparisonDiffsSelector,
  ({ driverIds, drivers }) => {
    return driverIds.size === 0 && Object.values(drivers).every(isDriverDiffEmpty);
  },
);

export const identicalComparedDatabasesSelector: Selector<boolean> = createSelector(
  scenarioComparisonDiffsSelector,
  ({ objectDiffs, objectSpecs }) => {
    return objectDiffs.length === 0 && isEmpty(objectSpecs);
  },
);

export const identicalComparedScenariosSelector: Selector<boolean> = createSelector(
  identicalComparedDatabasesSelector,
  identicalComparedDriversSelector,
  (areDatabasesIdentical, areDriversIdentical) => {
    return areDatabasesIdentical && areDriversIdentical;
  },
);

// Diffing layerized drivers
// We'll need to generalize diffing in the future, but for now just do this naively.
export type DriverDiffType =
  | 'added'
  | 'deleted'
  | 'updated_formula'
  | 'updated_name'
  | 'updated_actuals_timeseries'
  | 'updated_format'
  | 'updated_currency'
  | 'updated_decimal_places'
  | 'updated_coloring'
  | 'updated_references'
  | 'updated_lever_type';

type DriverDiffsById = NullableRecord<DriverId, Set<DriverDiffType>>;
type DriverDiffsByType = Partial<NullableRecord<DriverDiffType, Set<DriverId>>>;
type TransformedDriverDiffs = { byId: DriverDiffsById; byType: DriverDiffsByType };
type LayeredDriverDiffs = NullableRecord<LayerId, TransformedDriverDiffs>;

// Diffing layerized object specs
export type ColumnDiffType =
  | 'formula'
  | 'currency'
  | 'nameAnonymization'
  | 'decimalPlaces'
  | 'name'
  | 'numericFormat'
  | 'fieldAnonymization'
  | 'valueType'
  | 'dimension'
  | 'defaultValues'
  | 'column'
  | 'newColumn'
  | 'deletedColumn'
  | 'databaseKey';

export type ColumnType = 'fieldSpec' | 'dimensionalProperty' | 'driverProperty';

type ColumnDiffs = NullableRecord<string, { columnType: ColumnType; diffs: Set<ColumnDiffType> }>;
type ObjectSpecDiffsTransformed = NullableRecord<string, ColumnDiffs>;
type ObjectSpecDiffsByLayer = NullableRecord<LayerId, ObjectSpecDiffsTransformed>;

type DiffType<UpdateFields = object> =
  | { type: 'added' }
  | { type: 'deleted' }
  | ({ type: 'updated' } & UpdateFields);
type Diff<UpdateFields = object> = NullableRecord<string, DiffType<UpdateFields>>;

type ObjectDiffs = Diff<{ fields: Set<BusinessObjectFieldId> }>;
type EventDiffs = Diff;
type ObjectSpecDiffs = Diff<{
  fields: NullableRecord<string, Set<ColumnDiffType>>;
  driverProperties: NullableRecord<string, Set<ColumnDiffType>>;
  dimensionalProperties: NullableRecord<string, Set<ColumnDiffType>>;
}>;
type DriverDiffs = Diff<{
  fields: Set<
    | 'formula'
    | 'name'
    | 'actualsTimeseries'
    | 'currencyISOCode'
    | 'format'
    | 'decimalPlaces'
    | 'coloring'
    | 'driverReferences'
    | 'leverType'
  >;
}>;

function isDriverDiffEmpty(driverDiff: TransformedDriverDiffs | undefined) {
  return driverDiff == null || (isEmpty(driverDiff.byId) && isEmpty(driverDiff.byType));
}

const scenarioComparisonLayeredDriverDiffsSelector: Selector<LayeredDriverDiffs> = createSelector(
  scenarioComparisonDiffsSelector,
  (diffs) => diffs.drivers,
);

const scenarioComparisonLayeredObjectSpecDiffsSelector: Selector<ObjectSpecDiffsByLayer> =
  createSelector(scenarioComparisonDiffsSelector, (diffs) => diffs.objectSpecs);

export const scenarioComparisonDriverDiffsForLayerSelector: ParametricSelector<
  Required<DriverForLayerProps>,
  Set<DriverDiffType> | undefined
> = createCachedSelector(
  (state: RootState, _: Required<DriverForLayerProps>) =>
    scenarioComparisonLayeredDriverDiffsSelector(state),
  fieldSelector('id'),
  fieldSelector('layerId'),
  (layeredDiffs, driverId, layerId) => layeredDiffs[layerId]?.byId?.[driverId],
)(cacheKeyForDriverForLayerSelector);

// todo: Ideally we treat the merge page the same as any other comparison table/page,
// but for the POC we only want to touch the merge page.
export const scenarioComparisonShouldShowDriverDiffs: ParametricSelector<BlockId, boolean> =
  createSelector(
    blocksPageForBlockIdSelector,
    (blocksPage) => blocksPage?.internalPageType === SCENARIO_COMPARISON_PAGE_TYPE,
  );

function getDriverDiff({
  layers,
  baselineLayerId,
  comparisonLayerId,
  driverDiffsFromMutations,
}: {
  layers: Record<string, Layer>;
  baselineLayerId: LayerId;
  comparisonLayerId: LayerId;
  driverDiffsFromMutations: DriverDiffs;
}): TransformedDriverDiffs {
  const driversByLayerId = mapValues(layers, (layer) => layer.drivers.byId);
  const baselineDrivers = driversByLayerId[baselineLayerId];

  const driverDiffs: TransformedDriverDiffs = { byId: {}, byType: {} };

  const insertDriverDiff = (driverId: DriverId, diffType: DriverDiffType) => {
    const existingById = driverDiffs.byId[driverId] ?? new Set();
    existingById.add(diffType);
    driverDiffs.byId[driverId] = existingById;

    const existingByType = driverDiffs.byType[diffType] ?? new Set();
    existingByType.add(driverId);
    driverDiffs.byType[diffType] = existingByType;
  };

  const comparisonDrivers = driversByLayerId[comparisonLayerId];

  Object.entries(driverDiffsFromMutations).forEach(([driverId, diff]) => {
    if (diff == null) {
      return;
    }

    const baselineDriver = baselineDrivers[driverId];
    const comparisonDriver = comparisonDrivers[driverId];
    if (
      (comparisonDriver != null && comparisonDriver.type !== DriverType.Basic) ||
      (baselineDriver != null && baselineDriver.type !== DriverType.Basic)
    ) {
      return;
    }

    if (diff.type === 'added') {
      if (comparisonDriver == null) {
        return;
      }
      insertDriverDiff(comparisonDriver.id, 'added');
      return;
    }

    if (diff.type === 'deleted') {
      if (baselineDriver == null) {
        // It's possible this driver was deleted in baseline layer after comparison layer mutations.
        return;
      }
      insertDriverDiff(baselineDriver.id, 'deleted');
      return;
    }

    if (diff.type === 'updated') {
      if (baselineDriver == null || comparisonDriver == null) {
        // It's possible this driver was deleted in baseline layer after comparison layer mutations.
        return;
      }
      diff.fields.forEach((field) => {
        if (field === 'formula') {
          const isBasicDriver =
            baselineDriver.type === DriverType.Basic && comparisonDriver.type === DriverType.Basic;
          if (
            isBasicDriver &&
            // Verify that the driver formula wasn't updated back to its initial value before inserting the diff
            (baselineDriver.forecast.formula !== comparisonDriver.forecast.formula ||
              baselineDriver.actuals.formula !== comparisonDriver.actuals.formula)
          ) {
            insertDriverDiff(baselineDriver.id, 'updated_formula');
          }
        }
        if (field === 'name' && baselineDriver.name !== comparisonDriver.name) {
          insertDriverDiff(baselineDriver.id, 'updated_name');
        }
        if (field === 'actualsTimeseries') {
          insertDriverDiff(baselineDriver.id, 'updated_actuals_timeseries');
        }
        if (field === 'format' && baselineDriver.format !== comparisonDriver.format) {
          insertDriverDiff(baselineDriver.id, 'updated_format');
        }
        if (
          field === 'decimalPlaces' &&
          baselineDriver.decimalPlaces !== comparisonDriver.decimalPlaces
        ) {
          insertDriverDiff(baselineDriver.id, 'updated_decimal_places');
        }
        if (
          field === 'currencyISOCode' &&
          baselineDriver.currencyISOCode !== comparisonDriver.currencyISOCode
        ) {
          insertDriverDiff(baselineDriver.id, 'updated_currency');
        }
        if (field === 'leverType' && baselineDriver.leverType !== comparisonDriver.leverType) {
          insertDriverDiff(baselineDriver.id, 'updated_lever_type');
        }
        if (
          field === 'coloring' &&
          !deepEqual(baselineDriver.coloring, comparisonDriver.coloring)
        ) {
          insertDriverDiff(baselineDriver.id, 'updated_coloring');
        }

        const baselineDriverReferences = new Set(
          (baselineDriver.driverReferences ?? []).map((ref) => `${ref.blockId}-${ref.groupId}`),
        );
        const comparisonDriverReferences = new Set(
          (comparisonDriver.driverReferences ?? []).map((ref) => `${ref.blockId}-${ref.groupId}`),
        );
        if (
          field === 'driverReferences' &&
          !deepEqual(baselineDriverReferences, comparisonDriverReferences)
        ) {
          insertDriverDiff(baselineDriver.id, 'updated_references');
        }
      });
    }
  });

  return driverDiffs;
}

function getColumnDiff({
  layers,
  baselineLayerId,
  comparisonLayerId,
  specDiffsFromMutations,
}: {
  layers: Record<string, Layer>;
  baselineLayerId: LayerId;
  comparisonLayerId: LayerId;
  specDiffsFromMutations: ObjectSpecDiffs;
}): ObjectSpecDiffsTransformed {
  const objectSpecDiffs: ObjectSpecDiffsTransformed = {};
  const insertObjectSpecDiff = ({
    objectSpecId,
    columnId,
    columnType,
    diffType,
  }: {
    objectSpecId: BusinessObjectSpecId;
    columnId: string;
    columnType: ColumnType;
    diffType: ColumnDiffType;
  }) => {
    const existingByFieldSpecId: ColumnDiffs = objectSpecDiffs[objectSpecId] ?? {};
    const existingColumnDiff: { columnType: ColumnType; diffs: Set<ColumnDiffType> } =
      existingByFieldSpecId[columnId] ?? { columnType, diffs: new Set() };
    existingColumnDiff.diffs.add(diffType);
    existingByFieldSpecId[columnId] = existingColumnDiff;
    objectSpecDiffs[objectSpecId] = existingByFieldSpecId;
  };

  const baselineObjectSpecs = layers[baselineLayerId].businessObjectSpecs;
  const comparisonObjectSpecs = layers[comparisonLayerId].businessObjectSpecs;

  Object.entries(specDiffsFromMutations).forEach(([objectSpecId, objectSpecDiff]) => {
    // We currently only support updating field specs across layers, not adding or deleting them
    if (objectSpecDiff?.type !== 'updated') {
      return;
    }

    const baselineObjectSpec = safeObjGet(baselineObjectSpecs.byId[objectSpecId]);
    const comparisonObjectSpec = safeObjGet(comparisonObjectSpecs.byId[objectSpecId]);

    Object.entries(objectSpecDiff.fields).forEach(([fieldSpecId, fieldSpecDiffs]) => {
      // The number of fields on an object spec is very small, so we can just iterate over them
      const baselineField = baselineObjectSpec?.fields.find(
        (fieldSpec) => fieldSpec.id === fieldSpecId,
      );
      const comparisonField = comparisonObjectSpec?.fields.find(
        (fieldSpec) => fieldSpec.id === fieldSpecId,
      );

      fieldSpecDiffs?.forEach((diffType) => {
        if (
          diffType === 'nameAnonymization' &&
          (baselineObjectSpec?.isRestricted ?? false) !==
            (comparisonObjectSpec?.isRestricted ?? false)
        ) {
          insertObjectSpecDiff({
            objectSpecId,
            columnId: fieldSpecId,
            columnType: 'fieldSpec',
            diffType,
          });
        }
        if (
          diffType === 'name' &&
          comparisonField != null &&
          baselineField?.name !== comparisonField.name
        ) {
          insertObjectSpecDiff({
            objectSpecId,
            columnId: fieldSpecId,
            columnType: 'fieldSpec',
            diffType,
          });
        }
        if (
          diffType === 'formula' &&
          comparisonField != null &&
          baselineField?.defaultForecast.formula !== comparisonField.defaultForecast.formula
        ) {
          insertObjectSpecDiff({
            objectSpecId,
            columnId: fieldSpecId,
            columnType: 'fieldSpec',
            diffType,
          });
        }
        if (
          diffType === 'currency' &&
          comparisonField != null &&
          comparisonField?.type === ValueType.Number &&
          (baselineField?.type === ValueType.Number
            ? baselineField?.currencyISOCode
            : undefined) !== comparisonField?.currencyISOCode
        ) {
          insertObjectSpecDiff({
            objectSpecId,
            columnId: fieldSpecId,
            columnType: 'fieldSpec',
            diffType,
          });
        }
        if (
          diffType === 'decimalPlaces' &&
          comparisonField != null &&
          comparisonField.type === ValueType.Number &&
          (baselineField?.type === ValueType.Number ? baselineField?.decimalPlaces : undefined) !==
            comparisonField?.decimalPlaces
        ) {
          insertObjectSpecDiff({
            objectSpecId,
            columnId: fieldSpecId,
            columnType: 'fieldSpec',
            diffType,
          });
        }
        if (
          diffType === 'numericFormat' &&
          comparisonField != null &&
          comparisonField.type === ValueType.Number &&
          (baselineField?.type === ValueType.Number ? baselineField?.numericFormat : undefined) !==
            comparisonField?.numericFormat
        ) {
          insertObjectSpecDiff({
            objectSpecId,
            columnId: fieldSpecId,
            columnType: 'fieldSpec',
            diffType,
          });
        }
        if (
          diffType === 'fieldAnonymization' &&
          comparisonField != null &&
          baselineField?.isRestricted !== comparisonField.isRestricted
        ) {
          insertObjectSpecDiff({
            objectSpecId,
            columnId: fieldSpecId,
            columnType: 'fieldSpec',
            diffType,
          });
        }
        if (
          diffType === 'dimension' &&
          comparisonField != null &&
          baselineField?.dimensionId !== comparisonField.dimensionId
        ) {
          insertObjectSpecDiff({
            objectSpecId,
            columnId: fieldSpecId,
            columnType: 'fieldSpec',
            diffType,
          });
        }
        if (
          diffType === 'valueType' &&
          comparisonField != null &&
          baselineField?.type !== comparisonField.type
        ) {
          insertObjectSpecDiff({
            objectSpecId,
            columnId: fieldSpecId,
            columnType: 'fieldSpec',
            diffType,
          });
        }
        if (diffType === 'column' && comparisonField != null && baselineField == null) {
          insertObjectSpecDiff({
            objectSpecId,
            columnId: fieldSpecId,
            columnType: 'fieldSpec',
            diffType: 'newColumn',
          });
        }
        if (diffType === 'column' && comparisonField == null && baselineField != null) {
          insertObjectSpecDiff({
            objectSpecId,
            columnId: fieldSpecId,
            columnType: 'fieldSpec',
            diffType: 'deletedColumn',
          });
        }
        if (diffType === 'defaultValues') {
          insertObjectSpecDiff({
            objectSpecId,
            columnId: fieldSpecId,
            columnType: 'fieldSpec',
            diffType,
          });
        }
      });
    });

    Object.entries(objectSpecDiff.dimensionalProperties).forEach(([propertyId, propertyDiffs]) => {
      propertyDiffs?.forEach((diffType) => {
        const baselineProperty = baselineObjectSpec?.collection?.dimensionalProperties.find(
          (property) => property.id === propertyId,
        );
        const comparisonProperty = comparisonObjectSpec?.collection?.dimensionalProperties.find(
          (property) => property.id === propertyId,
        );
        if (diffType === 'name' && baselineProperty?.name !== comparisonProperty?.name) {
          insertObjectSpecDiff({
            objectSpecId,
            columnId: propertyId,
            columnType: 'dimensionalProperty',
            diffType,
          });
        }
        if (diffType === 'column' && comparisonProperty != null && baselineProperty == null) {
          insertObjectSpecDiff({
            objectSpecId,
            columnId: propertyId,
            columnType: 'dimensionalProperty',
            diffType: 'newColumn',
          });
        }
        if (diffType === 'column' && comparisonProperty == null && baselineProperty != null) {
          insertObjectSpecDiff({
            objectSpecId,
            columnId: propertyId,
            columnType: 'dimensionalProperty',
            diffType: 'deletedColumn',
          });
        }
        if (
          diffType === 'databaseKey' &&
          (baselineProperty?.isDatabaseKey ?? false) !==
            (comparisonProperty?.isDatabaseKey ?? false)
        ) {
          insertObjectSpecDiff({
            objectSpecId,
            columnId: propertyId,
            columnType: 'dimensionalProperty',
            diffType: 'databaseKey',
          });
        }
      });
    });
    Object.entries(objectSpecDiff.driverProperties).forEach(([propertyId, propertyDiffs]) => {
      propertyDiffs?.forEach((diffType) => {
        const baselineProperty = baselineObjectSpec?.collection?.driverProperties.find(
          (property) => property.id === propertyId,
        );
        const comparisonProperty = comparisonObjectSpec?.collection?.driverProperties.find(
          (property) => property.id === propertyId,
        );
        if (diffType === 'column' && comparisonProperty != null && baselineProperty == null) {
          insertObjectSpecDiff({
            objectSpecId,
            columnId: propertyId,
            columnType: 'driverProperty',
            diffType: 'newColumn',
          });
        }
        if (diffType === 'column' && comparisonProperty == null && baselineProperty != null) {
          insertObjectSpecDiff({
            objectSpecId,
            columnId: propertyId,
            columnType: 'driverProperty',
            diffType: 'deletedColumn',
          });
        }
        if (diffType === 'name' && baselineProperty != null && comparisonProperty != null) {
          const baselineDriver = safeObjGet(
            layers[baselineLayerId].drivers.byId[baselineProperty.driverId],
          );
          const comparisonDriver = safeObjGet(
            layers[comparisonLayerId].drivers.byId[comparisonProperty.driverId],
          );
          if (baselineDriver?.name !== comparisonDriver?.name) {
            insertObjectSpecDiff({
              objectSpecId,
              columnId: propertyId,
              columnType: 'driverProperty',
              diffType,
            });
          }
        }
      });
    });
  });

  return objectSpecDiffs;
}

export const objectSpecIdsToCompareSelector: Selector<string[]> = createSelector(
  scenarioComparisonLayeredObjectSpecDiffsSelector,
  scenarioComparisonObjectSpecIds,
  (objectSpecDiffsByLayer, objectSpecIdsFromObjectDiffs) => {
    const specSet = new Set<string>(objectSpecIdsFromObjectDiffs);
    Object.values(objectSpecDiffsByLayer).forEach((objectSpecDiffs) => {
      if (objectSpecDiffs == null) {
        return;
      }
      Object.keys(objectSpecDiffs).forEach((objectSpecId) => {
        specSet.add(objectSpecId);
      });
    });
    return Array.from(specSet);
  },
);

interface ColumnsForObjectSpec {
  objectSpecId: string;
}
export const comparisonColumnsForObjectSpecSelector: ParametricSelector<
  ColumnsForObjectSpec,
  ComparisonColumnInfo[]
> = createCachedSelector(
  (state: RootState, _params: ColumnsForObjectSpec) =>
    scenarioComparisonLayeredObjectSpecDiffsSelector(state),
  fieldSelector('objectSpecId'),
  (objectSpecDiffsByLayer, objectSpecId) => {
    const columnByFieldSpecId: Map<string, ComparisonColumnInfo> = new Map();
    Object.values(objectSpecDiffsByLayer).forEach((objectSpecDiffs) => {
      if (objectSpecDiffs == null) {
        return;
      }
      const fieldSpecDiffs = objectSpecDiffs[objectSpecId];
      if (fieldSpecDiffs == null) {
        return;
      }
      Object.entries(fieldSpecDiffs).forEach(([fieldSpecId, diffInfo]) => {
        if (diffInfo == null) {
          return;
        }
        const key = `${fieldSpecId}-${diffInfo.columnType}`;
        const existing = columnByFieldSpecId.get(key) ?? {
          id: fieldSpecId,
          type: diffInfo.columnType,
          diffTypes: [],
        };
        const diffSet = new Set([...existing.diffTypes, ...diffInfo.diffs]);
        if (diffSet.has('newColumn')) {
          diffSet.add('name');
        }
        if (diffSet.size > 0) {
          columnByFieldSpecId.set(key, {
            type: diffInfo.columnType,
            id: fieldSpecId,
            diffTypes: Array.from(diffSet),
          });
        }
      });
    });
    return Array.from(columnByFieldSpecId.values());
  },
)((_state, { objectSpecId }) => objectSpecId);

const EXCLUDED_DIFF_TYPES: Set<DriverDiffType> = new Set([
  'updated_actuals_timeseries',
  'updated_formula',
  'added',
  'deleted',
]);
export const driverPropertiesComparisonColumnsSelector: Selector<DriverComparisonColumnInfo[]> =
  createSelector(scenarioComparisonLayeredDriverDiffsSelector, (driverDiffsByLayer) => {
    const columnByDriverId: Map<DriverId, DriverComparisonColumnInfo> = new Map();
    Object.values(driverDiffsByLayer).forEach((transformedDriverDiffs) => {
      if (transformedDriverDiffs == null) {
        return;
      }
      Object.entries(transformedDriverDiffs.byId).forEach(([driverId, diffs]) => {
        if (diffs == null) {
          return;
        }
        const existing = columnByDriverId.get(driverId) ?? { driverId, diffTypes: [] };
        const diffSet = new Set([...existing.diffTypes, ...diffs]);
        const diffTypes = Array.from(diffSet).filter(
          (diffType) => !EXCLUDED_DIFF_TYPES.has(diffType),
        );
        if (diffTypes.length > 0) {
          columnByDriverId.set(driverId, {
            driverId,
            diffTypes,
          });
        }
      });
    });
    return Array.from(columnByDriverId.values());
  });

export const shouldDisableDriverEditingSelector: ParametricSelector<BlockId, boolean> =
  createCachedSelector(
    comparisonLayerIdsForBlockSelector,
    // Note: Usually we do not allow editing formulas if you're comparing layers, but a special
    // case is if you're only comparing against one layer. Because of the UI, users expect to
    // be able to edit the formulas in this case.
    (layerIds) => layerIds.length > 1,
  )(blockIdSelector);

export type Diffs = {
  objects: ObjectDiffs;
  events: EventDiffs;
  drivers: DriverDiffs;
  objectSpecs: ObjectSpecDiffs;
};
type DiffKey = keyof Diffs;
type UpdateDiff<T extends DiffKey> = Diffs[T][string] & { type: 'updated' };

type Opts = {
  driversInBaselineLayerById?: Record<string, NormalizedDriver>;
  collectionsByObjectSpecId?: Record<string, Collection>;
};
// Exported for testing
export function getDiffsFromMutations(mutations: MutationBatch[], options: Opts = {}): Diffs {
  const diffs: Diffs = {
    objects: {},
    events: {},
    drivers: {},
    objectSpecs: {},
  };

  function insertAddDiff<T extends DiffKey>(type: T, id: string) {
    diffs[type][id] = { type: 'added' };
  }

  function insertDeleteDiff<T extends DiffKey>(type: T, id: string) {
    const existingDiff = diffs[type][id];

    if (existingDiff == null || existingDiff.type === 'updated') {
      // If there is no existing diff or the existing one is "updated",
      // this means the entity already existed before this set of mutations.
      // Thus, we mark it as deleted.
      diffs[type][id] = { type: 'deleted' };
    } else if (existingDiff.type === 'added') {
      // This means the entity was added in this set of mutations, so we
      // just remove it from the diffs.
      delete diffs[type][id];
    }
  }

  function insertUpdateDiff<T extends DiffKey>({
    type,
    id,
    newDiffGenerator,
    updateExistingDiff,
  }: {
    type: T;
    id: string;
    newDiffGenerator: () => UpdateDiff<T>;
    updateExistingDiff: (diff: UpdateDiff<T>) => void;
  }) {
    const existingDiff = diffs[type][id];

    if (existingDiff == null) {
      // If the entity existed before this set of mutations, mark it as updated
      diffs[type][id] = newDiffGenerator();
      return;
    }

    // If entity was added or deleted earlier in this layer, no need for an "updated" diff.
    // An update after delete should never happen in practice, but account for it just in case
    if (existingDiff.type === 'added' || existingDiff.type === 'deleted') {
      return;
    }

    // Update the existing entity
    updateExistingDiff(existingDiff as UpdateDiff<T>);
  }

  const insertColumnDiff = (
    objectSpecId: string,
    columnId: string, // columnId to denote either fieldSpecId or propertyId
    type: ColumnDiffType,
    columnType: ColumnType,
  ) => {
    insertUpdateDiff({
      type: 'objectSpecs',
      id: objectSpecId,
      newDiffGenerator: () => ({
        type: 'updated',
        fields: columnType === 'fieldSpec' ? { [columnId]: new Set([type]) } : {},
        driverProperties: columnType === 'driverProperty' ? { [columnId]: new Set([type]) } : {},
        dimensionalProperties:
          columnType === 'dimensionalProperty' ? { [columnId]: new Set([type]) } : {},
      }),
      updateExistingDiff: (existingDiff) => {
        if (columnType === 'fieldSpec') {
          existingDiff.fields[columnId] = existingDiff.fields[columnId] ?? new Set();
          existingDiff.fields[columnId]?.add(type);
        } else if (columnType === 'driverProperty') {
          existingDiff.driverProperties[columnId] =
            existingDiff.driverProperties[columnId] ?? new Set();
          existingDiff.driverProperties[columnId]?.add(type);
        } else if (columnType === 'dimensionalProperty') {
          existingDiff.dimensionalProperties[columnId] =
            existingDiff.dimensionalProperties[columnId] ?? new Set();
          existingDiff.dimensionalProperties[columnId]?.add(type);
        }
      },
    });
  };

  mutations.forEach(({ mutation }) => {
    const {
      newBusinessObjects,
      updateBusinessObjects,
      deleteBusinessObjects,

      newEvents,
      updateEvents,
      deleteEvents,

      newDrivers,
      updateDrivers,
      deleteDrivers,

      updateBusinessObjectSpecs,

      deleteSubmodels,
    } = mutation;

    // ----------------
    // Business Objects
    // ----------------
    newBusinessObjects?.forEach(({ id }) => {
      insertAddDiff('objects', id);
    });

    deleteBusinessObjects?.forEach(({ id }) => {
      insertDeleteDiff('objects', id);
    });

    updateBusinessObjects?.forEach(({ id, fields }) => {
      insertUpdateDiff({
        type: 'objects',
        id,
        newDiffGenerator: () => ({
          type: 'updated',
          fields: new Set(fields?.map((field) => field.id)),
        }),
        updateExistingDiff: (existingDiff) => {
          fields?.forEach((field) => existingDiff.fields.add(field.id));
        },
      });
    });

    // ----------------
    // Events
    // ----------------
    newEvents?.forEach(({ id }) => {
      // Note that these can be deleted from the materialized dataset, even if
      // it isn't matched by a deleteEvent. One way this can happen is through side
      // effects of other mutations, for example a deleteField on an updateBusinessObject
      // mutation will result in the event being deleted from the materialized dataset.
      // Thus, it is important not to assume that the event exists in dataset, just because
      // there is an "added" diff for it.
      insertAddDiff('events', id);
    });

    deleteEvents?.forEach(({ id }) => {
      insertDeleteDiff('events', id);
    });

    updateEvents?.forEach(({ id }) => {
      insertUpdateDiff({
        type: 'events',
        id,
        newDiffGenerator: () => ({ type: 'updated' }),
        updateExistingDiff: noop,
      });
    });

    // ----------------
    // Drivers
    // ----------------
    newDrivers?.forEach((driver) => {
      if (driver.driverType === DriverType.Basic) {
        insertAddDiff('drivers', driver.id);
      } else if (driver.driverType === DriverType.Dimensional && driver.dimensional != null) {
        driver.dimensional.subDrivers?.forEach((subDriver) => {
          if (subDriver.driver != null) {
            insertAddDiff('drivers', subDriver.driver.id);
          }
        });
      }
    });

    deleteDrivers?.forEach(({ id }) => {
      insertDeleteDiff('drivers', id);
    });

    updateDrivers?.forEach(
      ({
        id,
        forecast,
        actuals,
        name,
        format,
        decimalPlaces,
        driverReferences,
        coloring,
        currencyISOCode,
        leverType,
      }) => {
        if (forecast?.formula != null || actuals?.formula != null) {
          insertUpdateDiff({
            type: 'drivers',
            id,
            newDiffGenerator: () => ({ type: 'updated', fields: new Set(['formula' as const]) }),
            updateExistingDiff: (existingDiff) => {
              existingDiff.fields.add('formula' as const);
            },
          });
        }

        if (actuals?.timeSeries != null) {
          insertUpdateDiff({
            type: 'drivers',
            id,
            newDiffGenerator: () => ({
              type: 'updated',
              fields: new Set(['actualsTimeseries' as const]),
            }),
            updateExistingDiff: (existingDiff) => {
              existingDiff.fields.add('actualsTimeseries' as const);
            },
          });
        }

        if (name != null) {
          insertUpdateDiff({
            type: 'drivers',
            id,
            newDiffGenerator: () => ({ type: 'updated', fields: new Set(['name' as const]) }),
            updateExistingDiff: (existingDiff) => {
              existingDiff.fields.add('name' as const);
            },
          });
          Object.entries(options.collectionsByObjectSpecId ?? {}).forEach(
            ([objectSpecId, collection]) => {
              collection.driverProperties.forEach((property) => {
                if (property.driverId === id) {
                  insertColumnDiff(objectSpecId, property.id, 'name', 'driverProperty');
                }
              });
            },
          );
        }
        if (format != null) {
          insertUpdateDiff({
            type: 'drivers',
            id,
            newDiffGenerator: () => ({ type: 'updated', fields: new Set(['format' as const]) }),
            updateExistingDiff: (existingDiff) => {
              existingDiff.fields.add('format' as const);
            },
          });
        }
        if (decimalPlaces != null) {
          insertUpdateDiff({
            type: 'drivers',
            id,
            newDiffGenerator: () => ({
              type: 'updated',
              fields: new Set(['decimalPlaces' as const]),
            }),
            updateExistingDiff: (existingDiff) => {
              existingDiff.fields.add('decimalPlaces' as const);
            },
          });
        }
        if (currencyISOCode != null) {
          insertUpdateDiff({
            type: 'drivers',
            id,
            newDiffGenerator: () => ({
              type: 'updated',
              fields: new Set(['currencyISOCode' as const]),
            }),
            updateExistingDiff: (existingDiff) => {
              existingDiff.fields.add('currencyISOCode' as const);
            },
          });
        }
        if (coloring != null) {
          insertUpdateDiff({
            type: 'drivers',
            id,
            newDiffGenerator: () => ({
              type: 'updated',
              fields: new Set(['coloring' as const]),
            }),
            updateExistingDiff: (existingDiff) => {
              existingDiff.fields.add('coloring' as const);
            },
          });
        }
        if (driverReferences != null) {
          insertUpdateDiff({
            type: 'drivers',
            id,
            newDiffGenerator: () => ({
              type: 'updated',
              fields: new Set(['driverReferences' as const]),
            }),
            updateExistingDiff: (existingDiff) => {
              existingDiff.fields.add('driverReferences' as const);
            },
          });
        }
        if (leverType != null) {
          insertUpdateDiff({
            type: 'drivers',
            id,
            newDiffGenerator: () => ({
              type: 'updated',
              fields: new Set(['leverType' as const]),
            }),
            updateExistingDiff: (existingDiff) => {
              existingDiff.fields.add('leverType' as const);
            },
          });
        }
      },
    );

    // ----------------
    // Object Specs
    // ----------------
    updateBusinessObjectSpecs?.forEach(
      ({
        id: objectSpecId,
        updateFields,
        updateCollection,
        addFields,
        deleteFields,
        isRestricted,
      }) => {
        if (isRestricted != null) {
          insertColumnDiff(objectSpecId, OBJECT_NAME_FIELD_NAME, 'nameAnonymization', 'fieldSpec');
        }
        updateFields?.forEach((updateInput) => {
          const { id: fieldSpecId } = updateInput;
          if (updateInput.defaultForecast?.formula != null) {
            insertColumnDiff(objectSpecId, fieldSpecId, 'formula', 'fieldSpec');
          }
          if (updateInput.currencyISOCode != null) {
            insertColumnDiff(objectSpecId, fieldSpecId, 'currency', 'fieldSpec');
          }
          if (updateInput.decimalPlaces != null) {
            insertColumnDiff(objectSpecId, fieldSpecId, 'decimalPlaces', 'fieldSpec');
          }
          if (updateInput.name != null) {
            insertColumnDiff(objectSpecId, fieldSpecId, 'name', 'fieldSpec');
          }
          if (updateInput.numericFormat != null) {
            insertColumnDiff(objectSpecId, fieldSpecId, 'numericFormat', 'fieldSpec');
          }
          if (updateInput.isRestricted != null) {
            insertColumnDiff(objectSpecId, fieldSpecId, 'fieldAnonymization', 'fieldSpec');
          }
          if (updateInput.dimensionId != null) {
            insertColumnDiff(objectSpecId, fieldSpecId, 'dimension', 'fieldSpec');
          }
          if (updateInput.setDefaultValues != null) {
            insertColumnDiff(objectSpecId, fieldSpecId, 'defaultValues', 'fieldSpec');
          }
          if (updateInput.removeDefaultValues != null) {
            insertColumnDiff(objectSpecId, fieldSpecId, 'defaultValues', 'fieldSpec');
          }
          if (updateInput.type != null) {
            insertColumnDiff(objectSpecId, fieldSpecId, 'valueType', 'fieldSpec');
          }
        });
        if (addFields != null) {
          addFields.forEach(({ id }) => {
            insertColumnDiff(objectSpecId, id, 'column', 'fieldSpec');
          });
        }
        if (deleteFields != null) {
          deleteFields.forEach((fieldSpecId) => {
            insertColumnDiff(objectSpecId, fieldSpecId, 'column', 'fieldSpec');
          });
        }
        if (updateCollection != null) {
          const {
            addDimensionalProperties,
            addDriverProperties,
            removeDimensionalProperties,
            removeDriverProperties,
            updateDimensionalProperties,
          } = updateCollection;
          if (addDimensionalProperties != null) {
            addDimensionalProperties.forEach(({ id }) => {
              insertColumnDiff(objectSpecId, id, 'column', 'dimensionalProperty');
            });
          }
          if (addDriverProperties != null) {
            addDriverProperties.forEach(({ id }) => {
              insertColumnDiff(objectSpecId, id, 'column', 'driverProperty');
            });
          }
          if (removeDimensionalProperties != null) {
            removeDimensionalProperties.forEach((propertyId) => {
              insertColumnDiff(objectSpecId, propertyId, 'column', 'dimensionalProperty');
            });
          }
          if (removeDriverProperties != null) {
            removeDriverProperties.forEach((propertyId) => {
              insertColumnDiff(objectSpecId, propertyId, 'column', 'driverProperty');
            });
          }
          if (updateDimensionalProperties != null) {
            updateDimensionalProperties.forEach(({ id, isDatabaseKey, name }) => {
              if (isDatabaseKey != null) {
                insertColumnDiff(objectSpecId, id, 'databaseKey', 'dimensionalProperty');
              }
              if (name != null) {
                insertColumnDiff(objectSpecId, id, 'name', 'dimensionalProperty');
              }
            });
          }
        }
      },
    );

    // ----------------
    // Submodels
    // ----------------
    const driversInBaselineLayerById = options.driversInBaselineLayerById ?? {};
    deleteSubmodels?.forEach((deleteInput) => {
      // TODO: remove after deleteDrivers no longer needs to be supported for old mutations.
      // eslint-disable-next-line deprecation/deprecation
      if (deleteInput.deleteDrivers) {
        Object.values(driversInBaselineLayerById).forEach((driver) => {
          // eslint-disable-next-line deprecation/deprecation
          if (driver.submodelId === deleteInput.id) {
            insertDeleteDiff('drivers', driver.id);
          }
        });
      }
    });
  });

  return diffs;
}
