import * as Sentry from '@sentry/nextjs';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { DateTime } from 'luxon';
import { useEffect, useRef } from 'react';
import { useEffectOnce } from 'react-use';

import { isUserBrowser } from 'helpers/environment';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { setIsLaunchDarklyEnabled, syncFlags } from 'reduxStore/reducers/datasetSlice';
import { isLaunchDarklyEnabledSelector } from 'selectors/launchDarklySelector';
import { authenticatedUserSelector } from 'selectors/loginSelector';
import { loadedSelectedOrgSelector } from 'selectors/selectedOrgSelector';

const LaunchDarklySyncer = () => {
  const flags = useFlags();
  const dispatch = useAppDispatch();
  const ldClient = useLDClient();
  const selectedOrg = useAppSelector(loadedSelectedOrgSelector);
  const user = useAppSelector(authenticatedUserSelector);
  const isLaunchDarklyInitializedRef = useRef(false);
  const isLaunchDarklyEnabled = useAppSelector(isLaunchDarklyEnabledSelector);

  useEffectOnce(() => {
    dispatch(setIsLaunchDarklyEnabled(isUserBrowser()));
  });

  useEffect(() => {
    if (!isLaunchDarklyEnabled || isLaunchDarklyInitializedRef.current) {
      return;
    }

    if (ldClient == null || selectedOrg == null || user == null) {
      return;
    }

    ldClient
      .identify({
        kind: 'multi',
        user: {
          kind: 'user',
          key: user.id,
          name: user.name,
          email: user.email,
        },
        org: {
          kind: 'org',
          key: selectedOrg.id,
          name: selectedOrg.name,
          slug: selectedOrg.slug,
          createdAt: DateTime.fromISO(selectedOrg.createdAt).toMillis(),
        },
      })
      .then((receivedFlags) => {
        dispatch(syncFlags({ ...receivedFlags }));
      })
      .catch((error) => {
        Sentry.captureException(
          new Error('Failed to retrieve flags from LaunchDarkly', {
            cause: error,
          }),
        );
        // N.B. fall back to default flags and unblock loading if LD errors
        dispatch(syncFlags({ ...ldClient.allFlags() }));
      })
      .finally(() => {
        isLaunchDarklyInitializedRef.current = true;
      });
  }, [dispatch, isLaunchDarklyEnabled, ldClient, selectedOrg, user]);

  useEffect(() => {
    if (isLaunchDarklyInitializedRef.current) {
      dispatch(syncFlags({ ...flags }));
    }
  }, [dispatch, flags, ldClient]);

  return null;
};

export default LaunchDarklySyncer;
