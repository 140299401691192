import { InfoIcon, SearchIcon } from '@chakra-ui/icons';
import { Box, Divider, Flex, Portal, Text } from '@chakra-ui/react';
import Tippy from '@tippyjs/react';
import groupBy from 'lodash/groupBy';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';

import DimensionSubMenu from 'components/BusinessObjectTable/SettingsPopoverContents/DimensionalDatabaseNewProperty//DimensionSubMenu';
import DriverSubMenu from 'components/BusinessObjectTable/SettingsPopoverContents/DimensionalDatabaseNewProperty/DriverSubMenu';
import LookupSubMenu from 'components/BusinessObjectTable/SettingsPopoverContents/DimensionalDatabaseNewProperty/LookupSubMenu';
import CustomizeBlockList from 'components/CustomizeBlock/CustomizeBlockList';
import SearchInput from 'components/SearchInput/SearchInput';
import { SelectItem } from 'components/SelectMenu/SelectMenu';
import SubmenuListItem from 'components/SubmenuListItem/SubmenuListItem';
import { DatabaseTableContext } from 'config/databaseTableContext';
import { DriverPropertyCreateData, ValueType } from 'generated/graphql';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { toggleShowColumnAsTimeSeries } from 'reduxStore/actions/blockMutations';
import {
  addExistingDriverToBusinessObjectSpec,
  createNewBusinessObjectDriverProperty,
  createNewBusinessObjectSpecField,
} from 'reduxStore/actions/businessObjectSpecMutations';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { setAutoFocus } from 'reduxStore/reducers/pageSlice';
import { dimensionalPropertiesForBusinessObjectSpecIdSelector } from 'selectors/collectionSelector';
import { enableDatabaseDriverAggregationsSelector } from 'selectors/launchDarklySelector';
import CalendarIcon from 'vectors/Calendar';
import DimensionIcon from 'vectors/Cube';
import NumberSignIcon from 'vectors/NumberSign';
import PlusIcon from 'vectors/Plus';

type MenuState = {
  page: 'root' | 'dimension' | 'lookup' | 'driver';
  isLegacyField?: boolean;
};

interface Props {
  onClose: () => void;
  isOpen?: boolean;
  groupKey?: string;
  objectId?: BusinessObjectId;
  page?: MenuState['page'];
}

type MenuItemFooter = {
  icon: JSX.Element;
  label: string;
  content: string;
};

type LegacyFieldItem = SelectItem & {
  type: ValueType;
  name: string;
  footerInfo: MenuItemFooter;
  'data-testid'?: string;
};

const LEGACY_ITEMS: LegacyFieldItem[] = [
  {
    id: 'number',
    name: 'Number field',
    type: ValueType.Number,
    icon: <NumberSignIcon />,
    footerInfo: {
      icon: <NumberSignIcon />,
      label: 'Number field',
      content:
        'A numeric value that can change over time. Scoped to this database, but can be referenced using formulas in your model.',
    },
  },
  {
    id: 'dimension',
    name: 'Dimension field',
    type: ValueType.Attribute,
    icon: <DimensionIcon />,
    footerInfo: {
      icon: <DimensionIcon />,
      label: 'Dimension field',
      content:
        'A collection of text options whose values that can vary over time. (ex: an Employee’s Role)\nField can be referenced using formulas in your model.',
    },
  },
  {
    'data-testid': 'add-new-legacy-date-field',
    id: 'date',
    name: 'Date field',
    type: ValueType.Timestamp,
    icon: <CalendarIcon />,
    footerInfo: {
      icon: <CalendarIcon />,
      label: 'Date field',
      content:
        'A date value that can change over time. (ex: Next Payment Date)\nScoped to this database but can be referenced using formulas in your model.',
    },
  },
];

type BlockOptionItem = {
  id: string;
  name: string;
  item: JSX.Element;
  sectionId: string;
  footer?: MenuItemFooter;
};

const SECTION_ID_LABEL_MAP: Record<string, string> = {
  legacyFields: 'Legacy fields',
};
const DimensionalDatabaseNewProperty = React.forwardRef<HTMLDivElement, Props>(
  ({ onClose, isOpen, objectId, groupKey, page }, ref) => {
    const { blockId } = useBlockContext();
    const { objectSpecId } = useContext(DatabaseTableContext);
    const dispatch = useAppDispatch();
    const [activeItemId, setActiveItemId] = useState<string>('');
    const [query, setQuery] = useState<string>('');
    const [currentMenuState, setCurrentMenuState] = useState<MenuState>({
      page: page ?? 'root',
      isLegacyField: false,
    });

    const enableDatabaseDriverAggregations = useAppSelector(
      enableDatabaseDriverAggregationsSelector,
    );

    const goToRootMenu = useCallback(() => setCurrentMenuState({ page: 'root' }), []);
    const goToDimensionMenu = useCallback(
      () => setCurrentMenuState({ page: 'dimension', isLegacyField: false }),
      [],
    );
    const gotToLegacyDimensionMenu = useCallback(
      () => setCurrentMenuState({ page: 'dimension', isLegacyField: true }),
      [],
    );
    const goToLookupMenu = useCallback(() => setCurrentMenuState({ page: 'lookup' }), []);

    const goToDriverMenu = useCallback(() => setCurrentMenuState({ page: 'driver' }), []);

    const onSelectLegacyItem = useCallback(
      (item: LegacyFieldItem) => {
        const type = item.type;
        if (item.id === 'dimension') {
          gotToLegacyDimensionMenu();
          return;
        }
        dispatch(createNewBusinessObjectSpecField({ objectSpecId, blockId, type }));
        onClose();
      },
      [dispatch, objectSpecId, blockId, onClose, gotToLegacyDimensionMenu],
    );

    const dimensionalProperties = useAppSelector((state) =>
      dimensionalPropertiesForBusinessObjectSpecIdSelector(state, objectSpecId),
    );
    const numKeyProps = useMemo(
      () => dimensionalProperties.filter((p) => p.isDatabaseKey).length,
      [dimensionalProperties],
    );

    const onAddDriver = useCallback(() => {
      if (numKeyProps === 0) {
        dispatch(setAutoFocus({ type: 'segmentByMenu' }));
      }
    }, [dispatch, numKeyProps]);

    const onAddExistingDriver = useCallback(
      (driverId: string) => {
        const onSuccess = (driverPropertyData: DriverPropertyCreateData) => {
          dispatch(toggleShowColumnAsTimeSeries({ blockId, columnKey: driverPropertyData.id }));
        };
        dispatch(
          addExistingDriverToBusinessObjectSpec({
            objectSpecId,
            driverId,
            type: ValueType.Number,
            onSuccess,
          }),
        );
        onAddDriver?.();
        onClose();
      },
      [blockId, dispatch, objectSpecId, onAddDriver, onClose],
    );
    const onCreateNewDriver = useCallback(
      (driverName?: string) => {
        const onSuccess = (driverPropertyData: DriverPropertyCreateData) => {
          dispatch(
            setAutoFocus({
              type: 'objectField',
              blockId,
              fieldSpecId: driverPropertyData.id,
              groupKey,
              objectId,
            }),
          );
        };
        // TODO: Add support for other driver types when we pick up deprecating fields
        // Currently only number drivers are supported
        const type = ValueType.Number;
        dispatch(
          createNewBusinessObjectDriverProperty({
            objectSpecId,
            propertyName: driverName,
            type,
            onSuccess,
          }),
        );
        onAddDriver?.();
        onClose();
      },
      [blockId, dispatch, groupKey, objectId, objectSpecId, onAddDriver, onClose],
    );

    const options: BlockOptionItem[] = useMemo(
      () => [
        {
          id: 'driver',
          name: 'driver',
          sectionId: 'addMappedColumn',
          item: enableDatabaseDriverAggregations ? (
            <SubmenuListItem
              name="Add driver"
              icon={<PlusIcon />}
              iconColor="gray.500"
              submenuArrow
              onClick={goToDriverMenu}
              data-testid="add-new-driver-property-column"
            />
          ) : (
            <SubmenuListItem
              name="Add driver"
              icon={<NumberSignIcon />}
              iconColor="gray.500"
              onClick={() => onCreateNewDriver()}
              data-testid="add-new-driver-property-column"
            />
          ),
          footer: {
            icon: <NumberSignIcon />,
            label: 'Add driver',
            content:
              'A numeric value that can change over time.\nDrivers created in databases can be readily used in your model.',
          },
        },
        {
          id: 'dimension',
          name: 'dimension',
          sectionId: 'addMappedColumn',
          item: (
            <SubmenuListItem
              name="Add dimension"
              data-testid="add-new-dimension-property-column"
              icon={<DimensionIcon />}
              iconColor="gray.500"
              submenuArrow
              onClick={goToDimensionMenu}
            />
          ),
          footer: {
            icon: <DimensionIcon />,
            label: 'Add dimension',
            content:
              'A collection of text values that don’t change over time.\nOften used to segment or as a reference for looking up data from other databases.',
          },
        },
        {
          id: 'lookup',
          name: 'lookup',
          sectionId: 'lookup',
          item: (
            <SubmenuListItem
              name="Add lookup"
              onClick={goToLookupMenu}
              icon={<SearchIcon boxSize={3} mr={1} />}
              iconColor="gray.500"
            />
          ),
          footer: {
            icon: <SearchIcon boxSize={3} mr={1} />,
            label: 'Add lookup',
            content: 'Reference a property from another database using a matching dimension.',
          },
        },
        ...LEGACY_ITEMS.map((item) => ({
          id: `${item.id}-field`,
          sectionId: 'legacyFields',
          name: item.name,
          item: (
            <SubmenuListItem
              data-testid={item['data-testid']}
              key={item.id}
              name={item.name}
              icon={item.icon}
              iconColor="gray.500"
              submenuArrow={item.id === 'dimension'}
              onClick={() => onSelectLegacyItem(item)}
            />
          ),
          footer: item.footerInfo,
        })),
      ],
      [
        enableDatabaseDriverAggregations,
        goToDriverMenu,
        onCreateNewDriver,
        goToDimensionMenu,
        goToLookupMenu,
        onSelectLegacyItem,
      ],
    );

    const filteredOptions = useMemo(() => {
      const lowerQuery = query.toLowerCase();
      return options.filter((option) => option.name.toLowerCase().includes(lowerQuery));
    }, [query, options]);

    const onItemHover = useCallback((itemId: string) => {
      setActiveItemId(itemId);
    }, []);

    const onItemMouseLeave = useCallback(() => {
      setActiveItemId('');
    }, []);

    const footerRef = useRef<HTMLDivElement>(null);
    if (!isOpen) {
      return null;
    }
    return (
      <Flex
        flexDirection="column"
        height="fit-content"
        bgColor="white"
        borderRadius="base"
        boxShadow="menu"
        ref={ref}
      >
        <CustomizeBlockList overflow="hidden">
          {currentMenuState.page === 'root' && (
            <Flex flexDirection="column" py={2}>
              <SearchInput placeholder="Find a column type" query={query} setQuery={setQuery} />
              <Box pt={2}>
                {Object.entries(groupBy(filteredOptions, 'sectionId')).map(
                  ([sectionId, sectionResults], idx, arr) => {
                    const res = sectionResults.map((option) => (
                      <Box
                        key={option.id}
                        role="group"
                        onMouseEnter={() => onItemHover(option.id)}
                        onMouseLeave={onItemMouseLeave}
                      >
                        {option.item}
                        {option.footer != null && activeItemId === option.id && (
                          <Portal containerRef={footerRef}>
                            <Flex
                              padding={3}
                              gap={2}
                              flexDir="column"
                              borderTopWidth="px"
                              borderTopColor="gray.300"
                              bgColor="gray.100"
                              borderBottomRadius="md"
                              overflow="hidden"
                              width="100%"
                            >
                              <Flex alignItems="center" gap={1} fontSize="xxs">
                                <Box color="gray.500">{option.footer.icon}</Box>
                                <Text fontWeight="600">{option.footer.label}</Text>
                              </Flex>
                              <Text maxW="200px" fontSize="xxs" color="gray.500" fontWeight={500}>
                                {option.footer.content}
                              </Text>
                            </Flex>
                          </Portal>
                        )}
                      </Box>
                    ));
                    const sectionLabel = SECTION_ID_LABEL_MAP[sectionId];
                    const isLastMenuItem = idx === arr.length - 1;
                    return (
                      <React.Fragment key={`${sectionLabel ?? ''}.${sectionResults[0]?.id ?? ''}`}>
                        {sectionLabel != null && (
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            flexDirection="row"
                            pr={2}
                          >
                            <Text
                              color="gray.600"
                              fontSize="xxxs"
                              fontWeight={700}
                              textTransform="uppercase"
                              ml={2}
                              my={2}
                            >
                              {sectionLabel}
                            </Text>
                            {sectionId === 'legacyFields' && (
                              <Tippy
                                interactive
                                content={
                                  <Box
                                    background="black"
                                    color="white"
                                    borderRadius="3px"
                                    px={3}
                                    py={2}
                                    fontSize="xxs"
                                    fontWeight="medium"
                                  >
                                    We&apos;re improving our columns and will deprecate legacy
                                    fields later this year. Your CX rep will share updates in
                                    advance of any changes.{' '}
                                    <a
                                      href="mailto:success@runway.com"
                                      style={{
                                        textDecoration: 'underline',
                                        textUnderlineOffset: '4px',
                                      }}
                                    >
                                      Talk to our team
                                    </a>{' '}
                                    to learn more.
                                  </Box>
                                }
                              >
                                <InfoIcon color="gray.500" boxSize={3} />
                              </Tippy>
                            )}
                          </Flex>
                        )}
                        {res}
                        {!isLastMenuItem && <Divider my={2} />}
                      </React.Fragment>
                    );
                  },
                )}
                {filteredOptions.length === 0 && (
                  <Flex alignItems="center" justifyContent="center" flexDirection="column" py={4}>
                    <Text color="gray.600" textAlign="center" fontWeight="medium">
                      No results found
                    </Text>
                  </Flex>
                )}
              </Box>
            </Flex>
          )}
          {currentMenuState.page === 'lookup' && (
            <LookupSubMenu onBack={goToRootMenu} onClose={onClose} />
          )}
          {currentMenuState.page === 'dimension' && (
            <DimensionSubMenu
              onBack={goToRootMenu}
              onClose={onClose}
              groupKey={groupKey}
              isLegacyField={currentMenuState.isLegacyField}
            />
          )}
          {currentMenuState.page === 'driver' && enableDatabaseDriverAggregations && (
            <DriverSubMenu
              onBack={goToRootMenu}
              onClose={onClose}
              onAddExistingDriver={onAddExistingDriver}
              onCreateNewDriver={onCreateNewDriver}
            />
          )}
        </CustomizeBlockList>
        <Box ref={footerRef} />
      </Flex>
    );
  },
);

DimensionalDatabaseNewProperty.displayName = 'DimensionalDatabaseNewProperty';
export default React.memo(DimensionalDatabaseNewProperty);
