import { BoxProps } from '@chakra-ui/react';
import React from 'react';

import Badge from 'components/Badge/Badge';
import DriverAttributeBadge from 'components/DriverAttributeBadges/DriverAttributeBadge';
import DimensionBadge from 'components/Formula/DimensionBadge';
import FormulaEntityChunk from 'components/Formula/FormulaEntityChunk';
import { isAttribute } from 'helpers/dimensionalDrivers';
import { DriverFormulaDisplayChunk } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaDisplayListener';
import useAppSelector from 'hooks/useAppSelector';
import { useEmptyAttributesFromFilters } from 'hooks/useEmptyAttributesFromFilters';
import { dimensionsByIdSelector } from 'selectors/dimensionsSelector';
import { driverIsKpiSelector } from 'selectors/driversSelector';
import Star from 'vectors/Star';

interface Props {
  chunk: DriverFormulaDisplayChunk;
  textColor?: BoxProps['color'];
}

const DriverChunk: React.FC<Props> = ({ chunk, textColor }) => {
  const dimById = useAppSelector(dimensionsByIdSelector);
  const { driverId, attributeFilters, placeholder, timeRange, timeRangeDisplay, displayName } =
    chunk;
  const isKpi = useAppSelector((state) => driverIsKpiSelector(state, driverId));

  const emptyAttributes = useEmptyAttributesFromFilters(attributeFilters);

  return (
    <FormulaEntityChunk
      label={displayName}
      timeRange={timeRange}
      timeRangeDisplay={timeRangeDisplay}
      icon={
        isKpi ? (
          <div data-testid="star-icon">
            <Star />
          </div>
        ) : undefined
      }
      attributes={
        attributeFilters != null && (
          <>
            {Object.entries(attributeFilters).flatMap(([dimId, attrs]) =>
              attrs.map((a) =>
                isAttribute(a) ? (
                  <DriverAttributeBadge key={a.id} attribute={a} size="xs" textColor={textColor} />
                ) : (
                  <DimensionBadge key={dimId} dimension={dimById[dimId]} type={a} />
                ),
              ),
            )}
            {emptyAttributes.map((attr) => (
              <DriverAttributeBadge key={attr.id} attribute={attr} size="xs" />
            ))}
          </>
        )
      }
      placeholderBadge={
        placeholder != null ? <Badge text={placeholder} theme="gray" size="xs" /> : null
      }
    />
  );
};

export default DriverChunk;
