import { Box, Button, Flex, Input, Text } from '@chakra-ui/react';
import React, { useCallback, useMemo, useState } from 'react';

import { WithEventGroup } from '@features/Plans';
import { CELL_PALETTE_POPOVER_CLASS_NAME } from 'components/CellPalette/CellPaletteWithPlanPicker';
import PlanMoreMenu from 'components/PlanPicker/PlanMoreMenu';
import { SelectItem } from 'components/SelectMenu/SelectMenu';
import { uuidv4 } from 'helpers/uuidv4';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { setMultiPlanPickerSelectedEventGroups } from 'reduxStore/reducers/pageSlice';
import { multiPlanPickerSelectedEventGroupsSelector } from 'selectors/cellPaletteSelector';
import { sortedEventGroupsForCellSelectionSelector } from 'selectors/eventsAndGroupsSelector';
import { PlansIcon } from 'vectors';

import PlanPickerBase from './PlanPickerBase';

// This is mock data for testing the component, we need to actually use data from the
// backend/Redux
const MOCK_STACKED_IMPACTS = [
  {
    impactType: 'Delta',
    value: { type: 'Number', value: 100 },
    withEventGroup: null,
  },
  {
    impactType: 'Delta',
    value: { type: 'Number', value: -10 },
    withEventGroup: null,
  },
];

const EventGroupTag: React.FC<{ eventGroupName: string | null; onClick: () => void }> = ({
  eventGroupName,
  onClick,
}) => {
  const { label, color } =
    eventGroupName != null
      ? { label: eventGroupName, color: 'gray.600' }
      : { label: 'Tag plan', color: 'gray.500' };

  return (
    <Button
      className={CELL_PALETTE_POPOVER_CLASS_NAME}
      size="sm"
      variant="iconText"
      leftIcon={<PlansIcon />}
      onClick={onClick}
      _hover={{
        bgColor: 'gray.300',
      }}
      maxW="240px"
    >
      <Text color={color} isTruncated lineHeight="16px">
        {label}
      </Text>
    </Button>
  );
};

const MultiImpactPlanPicker = React.forwardRef<HTMLDivElement>((_, ref) => {
  const dispatch = useAppDispatch();
  const sortedEventGroups = useAppSelector(sortedEventGroupsForCellSelectionSelector);
  const selectedEventGroups = useAppSelector(multiPlanPickerSelectedEventGroupsSelector);
  const [indexSelectingPlan, setIndexSelectingPlan] = useState<number | null>(null);

  const onSelect = useCallback(
    ({ id: eventGroupId }: SelectItem) => {
      if (indexSelectingPlan == null) {
        return;
      }

      const newSelectedEventGroups: Array<WithEventGroup | null> =
        selectedEventGroups != null
          ? [...selectedEventGroups]
          : Array.from({ length: MOCK_STACKED_IMPACTS.length }, () => null);
      newSelectedEventGroups[indexSelectingPlan] = {
        type: 'existing',
        eventGroupId,
      };
      dispatch(setMultiPlanPickerSelectedEventGroups(newSelectedEventGroups));

      setIndexSelectingPlan(null);
    },
    [dispatch, indexSelectingPlan, selectedEventGroups],
  );

  const onSelectCreatePlan = useCallback(
    (newEventGroupName: string) => {
      if (indexSelectingPlan == null) {
        return;
      }

      const newEventGroup = {
        id: uuidv4(),
        name: newEventGroupName,
      };

      const newSelectedEventGroups: Array<WithEventGroup | null> =
        selectedEventGroups != null
          ? [...selectedEventGroups]
          : Array.from({ length: MOCK_STACKED_IMPACTS.length }, () => null);
      newSelectedEventGroups[indexSelectingPlan] = {
        type: 'new',
        newEventGroup,
      };
      dispatch(setMultiPlanPickerSelectedEventGroups(newSelectedEventGroups));

      setIndexSelectingPlan(null);
    },
    [dispatch, indexSelectingPlan, selectedEventGroups],
  );

  const items: SelectItem[] = useMemo(() => {
    return sortedEventGroups.map(({ id, name, isNew }) => ({
      id,
      name,
      sectionId: 'main',
      checkedStyle: 'check',
      isChecked: false,
      icon: <PlansIcon />,
      submenu: () => <PlanMoreMenu eventGroupId={id} />,
      submenuType: 'options',
      isDisabled: isNew,
      disabledTooltipLabel: isNew ? 'Plan will be created once you save' : undefined,
    }));
  }, [sortedEventGroups]);

  if (indexSelectingPlan != null) {
    return (
      <PlanPickerBase
        ref={ref}
        onSelect={onSelect}
        onSelectCreatePlan={onSelectCreatePlan}
        onClose={() => setIndexSelectingPlan(null)}
        items={items}
      />
    );
  }

  return (
    <Box
      ref={ref}
      bgColor="white"
      borderRadius="6px"
      boxShadow="menu"
      zIndex="popover"
      className={CELL_PALETTE_POPOVER_CLASS_NAME}
    >
      <Flex direction="column" px="6px" py="4px" className={CELL_PALETTE_POPOVER_CLASS_NAME}>
        {MOCK_STACKED_IMPACTS.map(({ withEventGroup, value }, index) => {
          const selectedEventGroup =
            selectedEventGroups != null && selectedEventGroups.length > index
              ? selectedEventGroups[index]
              : withEventGroup;

          return (
            <Flex
              className={CELL_PALETTE_POPOVER_CLASS_NAME}
              direction="row"
              justify="space-between"
              gap="8px"
              key={index}
              height="32px"
              py="2px"
              onMouseDown={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <Flex>
                <EventGroupTag
                  eventGroupName={
                    selectedEventGroup?.type === 'existing' ? selectedEventGroup.eventGroupId : null
                  }
                  onClick={() => {
                    setIndexSelectingPlan(index);
                  }}
                />
              </Flex>
              <Flex>
                <Input
                  disabled
                  value={`${value.value > 0 ? '+' : ''}${value.value}`}
                  _hover={{}}
                  width="60px"
                  size="xs"
                  height="28px"
                  borderRadius="6px"
                />
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </Box>
  );
});

export default React.memo(MultiImpactPlanPicker);
