import {
  CellValueChangedEvent as AGCellValueChangedEvent,
  ColDef,
  ColGroupDef,
  IRowNode,
} from 'ag-grid-community';
import { DateTime } from 'luxon';

import { CellColumnKey, DriverRowKey, ObjectFieldRowKey } from 'config/cells';
import { ModelViewColumnType } from 'config/modelView';
import { ComparisonColumn, ValueType } from 'generated/graphql';
import { ComputedAttributeProperty } from 'helpers/formulaEvaluation/DimensionalPropertyEvaluator';
import { BlockId } from 'reduxStore/models/blocks';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { DimensionalPropertyId, DriverPropertyId } from 'reduxStore/models/collections';
import { AttributeId, DimensionId } from 'reduxStore/models/dimensions';
import { DriverId } from 'reduxStore/models/drivers';
import { CurvePointTyped } from 'reduxStore/models/events';
import { LayerId } from 'reduxStore/models/layers';
import { DisplayConfiguration } from 'reduxStore/models/value';
import { CalculationError } from 'types/dataset';
import { MonthKey } from 'types/datetime';

export type ColumnValueType =
  | 'options'
  | 'name'
  | 'formula'
  | 'addColumn'
  | ModelViewColumnType
  | ValueType;

export type BackingType = 'objectField' | 'dimension' | 'driver';
export type FormulaCellRendererValueType = {
  subDriverId: DriverId;
  rawFormula: string | null;
  keyAttributes: ComputedAttributeProperty[];
};

type ColumnId =
  | BusinessObjectFieldSpecId
  | DimensionalPropertyId
  | `${BusinessObjectFieldSpecId | DriverPropertyId}.${MonthKey}`;

type SubDriverMeta = {
  driverPropertyId: DriverPropertyId;
  // Subdrivers are lazily created; will be undefined until created.
  subDriverId?: DriverId;
  subDriverDisplayConfiguration?: DisplayConfiguration;
  actualsFormula?: string;
};

export const isColValueCalculationError = (v: Row['data'][string]): v is CalculationError =>
  v != null && typeof v === 'object' && 'error' in v;

export type CellValue = number | string | null | undefined | CalculationError;

// Currently you can't have a transition value that goes to/from an error in
// the UI. That should simply render as an error.
type TransitionCellValue = [string | number, string | number];

export type Row = {
  type: 'objectRow';
  id: string;
  objectId: BusinessObjectId;
  objectSpecId: BusinessObjectSpecId;
  isIntegration: boolean;
  groupAttributeId: AttributeId;
  appId?: string;
  name: string;
  subDriverMetaByColId: NullableRecord<ColumnId, SubDriverMeta>;
  data: {
    [key: ColumnId]: CellValue | FormulaCellRendererValueType | TransitionCellValue;
  };
  hardCodedActuals: Set<MonthKey>;
  curvePointsByMonthKey: NullableRecord<MonthKey, CurvePointTyped>;
  rowKey: {
    [colId: string]: DriverRowKey | ObjectFieldRowKey;
  };
  // Computing the fieldId for a given row/fieldSpecId combination is expensive, so we cache it here.
  fieldIdByFieldSpecId: Record<ColumnId, string>;
  loading: boolean;
  isObjectWithDuplicateKeys: boolean;
  comparisonType?: ComparisonColumn;
  layerId?: LayerId;
};

// NOTE: add any custom columndef keys to selectors/gridSelectors.ts
export interface ColumnDef extends ColDef<Row, any> {
  colId: ColumnId;
  attributeRefData?: NullableRecord<string, { name: string; deleted: boolean }>;
  columnKey: CellColumnKey;
  blockId: BlockId;
  fieldSpec: {
    objectFieldSpecId: BusinessObjectFieldSpecId;
    objectSpecId: BusinessObjectSpecId;
    monthKey?: MonthKey;
    layerId?: LayerId;
    comparisonType?: ComparisonColumn;
    afterLastActuals?: boolean;
    valueType: ColumnValueType;
    backingType?: BackingType;
    dimensionName?: string;
    dimensionColor?: string;
    isDatabaseKey: boolean;
    displayConfiguration?: DisplayConfiguration;
    canShowAsTimeSeries: boolean;
    displayAs?: 'value' | 'timeseries';
    dimensionId?: DimensionId;
    isRestricted?: boolean;
    isIntegration?: boolean;
    isMapped?: boolean;
    dimensionalDriverId?: DriverId;
    cellAlignment?: 'left' | 'right';
    isNewDimensionalTable?: boolean;
    isFormula?: boolean;
    blockDateRangeDateTime: [DateTime, DateTime];
  };
}

export type DatabaseCellValueChangedEvent = Omit<
  AGCellValueChangedEvent<Row, number | string | string[] | FormulaCellRendererValueType>,
  'context'
> & {
  colDef: ColumnDef;
  context: AgGridDatabaseContext;
};

export type AgGridDatabaseContext = {
  pasteAction: {
    cellValueChangedEvents: DatabaseCellValueChangedEvent[];
  };
  selectedRowNodes: Array<IRowNode<any>>;
  // sometimes we want to retain the previously selected row nodes for context when the user
  // technically changes the selected node. e.g. click the "options" column of a row, to drag
  // the currently selected rows
  staleSelectedRowNodes: boolean;
  singleCellRangeSelection: IRowNode<any> | undefined;
  dragAction?: {
    insertAtRowId: string | undefined;
    movingRowNodes: Array<IRowNode<any>>;
    route: string[] | undefined;
  };
  type: 'database';

  // This is the cell that the cell palette will show up on, if isShowingCellPalette is true
  cellPaletteAnchorCell?: { rowNode: IRowNode<any>; colId: ColumnId };
  isShowingCellPalette?: boolean;
};

export interface ColumnGroupDef extends ColGroupDef<Row> {
  colId: ColumnId;
  headerGroupType: 'timeseries' | 'comparison';
}

// for copy/paste cumulative values cell
export type PasteCumulativeCellValues = {
  cumulativeCellValues: string[];
};

export function isPasteCumulativeCellValues(value: unknown): value is PasteCumulativeCellValues {
  return (
    typeof value === 'object' &&
    value != null &&
    'cumulativeCellValues' in value &&
    Array.isArray(value.cumulativeCellValues) &&
    value.cumulativeCellValues.every((v) => typeof v === 'string')
  );
}

export function isCumulativeTimeseriesCell(colDef: ColumnDef) {
  const { displayAs, canShowAsTimeSeries } = colDef.fieldSpec;
  return displayAs === 'value' && canShowAsTimeSeries;
}
