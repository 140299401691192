import { SystemStyleObject } from '@chakra-ui/react';

function hoverVisibleStyle(role: string): SystemStyleObject {
  return {
    // N.B. we use the .hover class for Storybook testing
    [`[role=${role}]:hover &, [role=${role}].hover &, [role=${role}]:focus-within &`]: {
      visibility: 'visible',
      opacity: 1,
    },
  };
}

export const NUMERIC_FONT_SETTINGS: SystemStyleObject = {
  fontFeatureSettings: "'tnum' on, 'lnum' on",
};

export const ROW_HOVER_VISIBLE_STYLES = hoverVisibleStyle('row');
export const BLOCK_HOVER_VISIBLE_STYLES = hoverVisibleStyle('block');
export const NAV_HOVER_VISIBLE_STYLES = hoverVisibleStyle('nav');
export const BLOCK_CONTAINER_HOVER_VISIBLE_STYLES = hoverVisibleStyle('block-container');
