import * as Sentry from '@sentry/nextjs';
import humanizeDuration from 'humanize-duration';
import { max, min, sortBy } from 'lodash';
import memoize from 'lodash/memoize';
import round from 'lodash/round';
import { DateTime, Duration, DurationUnit, Settings } from 'luxon';

import { Scalars } from 'generated/graphql';
// eslint-disable-next-line no-restricted-imports
import { TODAY } from 'helpers/dateMovingConstants';
import { ISOTime, MonthKey, MonthKeyOrMonthKeyRange } from 'types/datetime';

Settings.defaultZone = 'utc';
const MONTH_KEY_FORMAT = 'yyyy-MM';
const MONTH_KEY_REGEX = /^\d\d\d\d-\d\d$/;
const JANUARY_MONTH = 1;
const DECEMBER_MONTH = 12;

// We call this a lot and the results rarely change. Doing any sort of date
// math is really slow and this becomes a problem with the amount that we do it
// for rollup formula evaluation.
export const getMonthKeysForRange = memoize(
  (startMonth: DateTime, endMonth: DateTime): MonthKey[] => {
    return getMonthsBetweenDates(startMonth, endMonth).map(getMonthKey);
  },
  (startMonth: DateTime, endMonth: DateTime) => `${startMonth.toMillis()}-${endMonth.toMillis()}`,
);

export const getMonthKeysForYearTo = (fiscalYearStartMonth: number, now: DateTime): MonthKey[] => {
  let yearStart = now.set({ month: fiscalYearStartMonth });

  if (now < yearStart) {
    yearStart = yearStart.minus({ years: 1 });
  }

  return getMonthKeysForRange(yearStart, now);
};

export const getMonthKeysForQuarterTo = (
  fiscalYearStartMonth: number,
  now: DateTime,
): MonthKey[] => {
  const quarterStartMonths = getQuarterStarts(fiscalYearStartMonth).sort((a, b) => a - b);
  const currentMonth = now.month;
  const quarterStartMonth =
    quarterStartMonths.findLast((m) => currentMonth >= m) ??
    quarterStartMonths[quarterStartMonths.length - 1];
  const months = (currentMonth - quarterStartMonth + 12) % 12;
  const startOfQuarter = now.minus({ months });
  return getMonthKeysForRange(startOfQuarter, now);
};

export const getMonthKeysForQTD = memoize((fiscalYearStartMonth: number): MonthKey[] => {
  return getMonthKeysForQuarterTo(fiscalYearStartMonth, TODAY);
});

/** QLC: Quarter to Last Close */
export const getMonthKeysForQLC = memoize(
  (fiscalYearStartMonth: number, lastActualsTime: string): MonthKey[] => {
    const lastActuals = DateTime.fromISO(lastActualsTime);
    return getMonthKeysForQuarterTo(fiscalYearStartMonth, lastActuals);
  },
);

/** HYTD: Half Year To Date */
export const getMonthKeysForHYTD = memoize(
  (fiscalYearStartMonth: number, now: DateTime): MonthKey[] => {
    const midYearMonth =
      fiscalYearStartMonth + 6 > 12 ? fiscalYearStartMonth + 6 - 12 : fiscalYearStartMonth + 6;
    const halfYearStart = DateTime.local(now.year, midYearMonth, 1);

    let fiscalYearStart;
    const currentMonth = now.month;
    if (currentMonth < fiscalYearStartMonth) {
      fiscalYearStart = DateTime.local(now.year - 1, fiscalYearStartMonth, 1);
    } else {
      fiscalYearStart = DateTime.local(now.year, fiscalYearStartMonth, 1);
    }

    const monthKeysFromFiscal = getMonthKeysForRange(fiscalYearStart, now);
    const monthKeysFromHalfYear = getMonthKeysForRange(halfYearStart, now);

    if (
      monthKeysFromFiscal.length < monthKeysFromHalfYear.length ||
      monthKeysFromHalfYear.length === 0
    ) {
      return monthKeysFromFiscal;
    }
    return monthKeysFromHalfYear;
  },
);

export const isEndOfQuarter = (dt: DateTime, fiscalYearStartMonth: number) => {
  return getQuarterEnds(fiscalYearStartMonth).includes(dt.month);
};

export const isEndOfYear = (dt: DateTime, fiscalYearStartMonth: number) => {
  const fyEndMonth =
    fiscalYearStartMonth === JANUARY_MONTH ? DECEMBER_MONTH : fiscalYearStartMonth - 1;
  return dt.month === fyEndMonth;
};

export const extractMonthKey = (time: string): MonthKey => {
  return time.slice(0, 7);
};

export const getCurrentMonthKey = (): MonthKey => extractMonthKey(new Date().toISOString());

// Parsing datetimes is actually fairly expensive and these functions are
// easily memoized. There is a small set of inputs and they don't often change
// but are used a lot.
export const getMonthKey = memoize(
  (month: DateTime): MonthKey => {
    return month.toFormat(MONTH_KEY_FORMAT);
  },
  (month: DateTime) => month.toMillis().toString(),
);

/**
 * Returns the DateTime object for the given month key
 * @param monthKey
 * @example
 * getDateTimeFromMonthKey('2020-01')
 */
export const getDateTimeFromMonthKey = memoize((monthKey: MonthKey): DateTime => {
  return DateTime.fromFormat(monthKey, MONTH_KEY_FORMAT);
});

export function monthKeyRange(from: MonthKey, to: MonthKey): MonthKey[] {
  const [first, last] = from < to ? [from, to] : [to, from];

  let curr = first;
  const res: MonthKey[] = [curr];
  while (curr !== last) {
    curr = nextMonthKey(curr);
    res.push(curr);
  }
  return res;
}

// Given a list of month keys, reduces it to a list of month keys or month key ranges, where consecutive
// month keys are combined into ranges.
export function toMonthKeyOrRangeList(monthKeys: MonthKey[]): MonthKeyOrMonthKeyRange[] {
  if (monthKeys.length === 0) {
    return [];
  }

  const monthKeyDateTimes = monthKeys.map(getDateTimeFromMonthKey);
  const sortedMonthKeyDateTimes = sortBy(monthKeyDateTimes);
  const sortedMonthKeys = sortedMonthKeyDateTimes.map(getMonthKey);

  const result: MonthKeyOrMonthKeyRange[] = [];
  let rangeStart = sortedMonthKeys[0];
  let rangeEnd = sortedMonthKeys[0];

  for (let i = 1; i < sortedMonthKeys.length; i++) {
    const currentMonthKey = sortedMonthKeys[i];

    if (areMonthKeysConsecutive(rangeEnd, currentMonthKey)) {
      rangeEnd = currentMonthKey;
    } else {
      if (rangeStart === rangeEnd) {
        result.push({ type: 'month', monthKey: rangeStart });
      } else {
        result.push({ type: 'range', from: rangeStart, to: rangeEnd });
      }

      rangeStart = currentMonthKey;
      rangeEnd = currentMonthKey;
    }
  }

  if (rangeStart === rangeEnd) {
    result.push({ type: 'month', monthKey: rangeStart });
  } else {
    result.push({ type: 'range', from: rangeStart, to: rangeEnd });
  }

  return result;
}

export function formatMonthKeyOrRange(monthKeyOrRange: MonthKeyOrMonthKeyRange): string {
  if (monthKeyOrRange.type === 'month') {
    return shortMonthFormat(getDateTimeFromMonthKey(monthKeyOrRange.monthKey));
  }

  const fromMonth = shortMonthFormat(getDateTimeFromMonthKey(monthKeyOrRange.from));
  const toMonth = shortMonthFormat(getDateTimeFromMonthKey(monthKeyOrRange.to));

  return `${fromMonth} - ${toMonth}`;
}

export const getNumMonthsInclusive = (start: MonthKey, end: MonthKey): number => {
  const startTime = getDateTimeFromMonthKey(start);
  const endTime = getDateTimeFromMonthKey(end);
  return endTime.diff(startTime, 'months').months + 1;
};

const SHORT_MONTH_WITH_DAY_FORMAT = 'MMM d %yy';
const SHORT_MONTH_FORMAT = 'MMM %yy';
const SHORT_QUARTER_FORMAT = 'Qq %yy';
const SHORT_YEAR_FORMAT = 'yy';

export const shortMonthWithDayFormat = memoize((date: DateTime): string => {
  return date.toFormat(SHORT_MONTH_WITH_DAY_FORMAT).replace('%', `'`);
});

export const shortMonthFormat = memoize((date: DateTime): string => {
  return date.toFormat(SHORT_MONTH_FORMAT).replace('%', `'`);
});

export const shortQuarterFormat = (date: DateTime): string => {
  return date.toFormat(SHORT_QUARTER_FORMAT).replace('%', `'`);
};

function getQuarterStarts(fiscalYearStartMonth: number) {
  const quarterStart = fiscalYearStartMonth;
  return [
    quarterStart,
    (quarterStart + 3) % 12,
    (quarterStart + 6) % 12,
    (quarterStart + 9) % 12,
  ].map((m) => (m === 0 ? 12 : m));
}

function getQuarterEnds(fiscalYearStartMonth: number) {
  const lastQuarterEnd =
    fiscalYearStartMonth === JANUARY_MONTH ? DECEMBER_MONTH : fiscalYearStartMonth - 1;
  return [
    (lastQuarterEnd + 3) % 12,
    (lastQuarterEnd + 6) % 12,
    (lastQuarterEnd + 9) % 12,
    lastQuarterEnd,
  ].map((m) => (m === 0 ? 12 : m));
}

export const shortFiscalQuarterFormat = (date: DateTime, fiscalYearStartMonth: number): string => {
  const quarterStartMonths = getQuarterStarts(fiscalYearStartMonth);
  const sortedQuarterStarts = quarterStartMonths.toSorted((a, b) => a - b);
  const quarterStartMonth =
    sortedQuarterStarts.findLast((m) => date.month >= m) ??
    sortedQuarterStarts[sortedQuarterStarts.length - 1];
  const quarterStartIndex = quarterStartMonths.indexOf(quarterStartMonth);
  let fyFormatDate;
  if (date.month >= fiscalYearStartMonth && fiscalYearStartMonth >= 3) {
    fyFormatDate = `FY '${date.plus({ years: 1 }).toFormat(SHORT_YEAR_FORMAT)}`;
  } else if (fiscalYearStartMonth > 1) {
    fyFormatDate = `FY '${date.toFormat(SHORT_YEAR_FORMAT)}`;
  } else {
    fyFormatDate = `'${date.toFormat(SHORT_YEAR_FORMAT)}`;
  }

  return `Q${quarterStartIndex + 1} ${fyFormatDate}`;
};

export const shortYearFormat = (date: DateTime): string => {
  return `FY ${date.year}`;
};

const getFromShortMonthWithDay = memoize((dateStr: string): DateTime => {
  return DateTime.fromFormat(dateStr.replace(`'`, '%'), SHORT_MONTH_WITH_DAY_FORMAT);
});

const getFromShortMonth = memoize((dateStr: string): DateTime => {
  return DateTime.fromFormat(dateStr.replace(`'`, '%'), SHORT_MONTH_FORMAT);
});

const getFromShortQuarter = memoize((dateStr: string): DateTime => {
  return DateTime.fromFormat(dateStr.replace(`'`, '%'), SHORT_QUARTER_FORMAT);
});

export const getMonthsBetweenDates = (start: DateTime, end: DateTime): DateTime[] => {
  const res: DateTime[] = [];

  let curr = start;
  while (curr <= end) {
    res.push(curr);
    curr = curr.plus({ months: 1 });
  }
  return res;
};

export const getMonthsBetweenMonths = (start: MonthKey, end: MonthKey): MonthKey[] => {
  const res: MonthKey[] = [];
  let curr = start;
  while (curr <= end) {
    res.push(curr);
    curr = nextMonthKey(curr);
  }
  return res;
};

export const numMonthsBetweenDates = (start: DateTime, end: DateTime): number => {
  return round(end.diff(start, 'months').months);
};

export const nextMonthKey = memoize((mk: MonthKey): MonthKey => {
  const [year, month] = mk.split('-');
  if (Number(month) === 12) {
    return `${String(Number(year) + 1).padStart(4, '0')}-01`;
  }

  return `${year}-${String(Number(month) + 1).padStart(2, '0')}`;
});

export const nextYearMonthKey = (mk: MonthKey): MonthKey => {
  const [year, month] = mk.split('-');

  return `${String(Number(year) + 1)}-${month}`;
};

const areMonthKeysConsecutive = (monthKeyA: MonthKey, monthKeyB: MonthKey): boolean => {
  return nextMonthKey(monthKeyA) === monthKeyB;
};

export const previousMonthKey = memoize((mk: MonthKey): MonthKey => {
  const [year, month] = mk.split('-');
  if (Number(month) === 1) {
    return `${String(Number(year) - 1).padStart(4, '0')}-12`;
  }

  return `${year}-${String(Number(month) - 1).padStart(2, '0')}`;
});

export const offsetMonthKey = (offset: number, mk: MonthKey): MonthKey => {
  let remaining = Math.abs(offset);
  let curr = mk;
  while (remaining > 0) {
    curr = offset > 0 ? nextMonthKey(curr) : previousMonthKey(curr);
    remaining -= 1;
  }
  return curr;
};

const isDateEqualOrBefore = (date: DateTime, otherDate: DateTime): boolean => {
  return date <= otherDate;
};

export const isDateBetween = (date: MonthKey, start: MonthKey, end: MonthKey): boolean => {
  const parsedStart = getDateTimeFromMonthKey(start);
  const parsedEnd = getDateTimeFromMonthKey(end);
  const parsedDate = getDateTimeFromMonthKey(date);

  return isDateEqualOrBefore(parsedStart, parsedDate) && isDateEqualOrBefore(parsedDate, parsedEnd);
};

export const getStartAndEndMonthKeys = (
  monthKeys: MonthKey[],
): { startMonthKey: MonthKey | undefined; endMonthKey: MonthKey | undefined } => {
  const startMonthKey = min(monthKeys);
  const endMonthKey = max(monthKeys);

  return { startMonthKey, endMonthKey };
};

export const getMonthKeyRange = memoize(
  (startMonthKey: MonthKey, endMonthKey: MonthKey): MonthKey[] => {
    if (startMonthKey > endMonthKey) {
      const err = Error(`startMonthKey must be before endMonthKey`);
      Sentry.withScope((scope) => {
        scope.setExtras({
          startMonthKey,
          endMonthKey,
        });
        Sentry.captureException(err);
      });
      return [];
    }

    const keys: MonthKey[] = [startMonthKey];
    let mk = startMonthKey;
    while (mk !== endMonthKey) {
      mk = nextMonthKey(mk);
      keys.push(mk);
    }

    return keys;
  },
  (startMonthKey: MonthKey, endMonthKey: MonthKey) => `${startMonthKey},${endMonthKey}`,
);

/**
 * Converts an ISO string with ms to one without, for communication with the API
 * @param isoString an ISO string that may contain ms
 * @returns an ISO string without ms
 */
export const getISOTimeWithoutMs = (isoString: ISOTime): Scalars['Time'] => {
  return DateTime.fromISO(isoString).startOf('second').toISO({ suppressMilliseconds: true });
};

/**
 * Formats a DateTime to an ISO time without ms, for communication with the API
 * @param dateTime a DateTime object
 * @returns an ISO string without ms
 */
export const formatISOWithoutMs = (dateTime: DateTime): Scalars['Time'] =>
  dateTime.startOf('second').toISO({ suppressMilliseconds: true });

/**
 * Formats a MonthKey to an ISO time without ms, for communication with the API
 * @param monthKey a MonthKey string
 * @returns an ISO string without ms
 */

export const getISOTimeWithoutMsFromMonthKey = memoize(
  (monthKey: MonthKey): Scalars['Time'] => formatISOWithoutMs(getDateTimeFromMonthKey(monthKey)),
  (monthKey: MonthKey) => monthKey,
);

export function isValidMonthKey(str: string) {
  return MONTH_KEY_REGEX.test(str);
}

function getQuarter(date: DateTime) {
  return Math.floor((date.month - 1) / 3 + 1);
}

function getQuarterStartMonth(quarter: number, year?: number) {
  if (year == null) {
    year = DateTime.now().year;
  }
  return DateTime.fromObject({ year, month: 3 * (quarter - 1) + 1 });
}

function getQuarterEndMonth(quarter: number, year?: number) {
  if (year == null) {
    year = DateTime.now().year;
  }
  return DateTime.fromObject({ year, month: 3 * quarter }).endOf('month');
}

export { TODAY };
export const START_OF_MONTH = TODAY.startOf('month');
export const CURRENT_MONTH_KEY = getMonthKey(TODAY);

const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: 'shortEn',
  languages: {
    shortEn: {
      y: () => 'y',
      mo: () => 'mo',
      w: () => 'w',
      d: () => 'd',
      h: () => 'h',
      m: () => 'm',
      s: () => 's',
      ms: () => 'ms',
    },
  },
});

export const humanizeDurationShort = (duration: Duration): string => {
  return shortEnglishHumanizer(duration.toMillis(), {
    largest: 1,
    round: true,
    spacer: '',
  });
};

export const prettyPrintTimestamp = (dateString: string): string => {
  return DateTime.fromISO(dateString)
    .setZone('local')
    .toFormat("LLL dd `yy 'at' hh:mm a")
    .replace('`', "'");
};

export const getDiffFromNow = (isoString: string): Duration => {
  return DateTime.fromISO(isoString).diffNow().rescale();
};

// Add more as necessary
const DATE_PARSE_ATTEMPT_FORMATS = [
  'M/yy',
  'MM/yy',
  'MMM/yy',
  'MMMM/yy',

  'yy/M',
  'yy/MM',
  'yy/MMM',
  'yy/MMMM',

  'M-yy',
  'MM-yy',
  'MMM-yy',
  'MMMM-yy',

  'yy-M',
  'yy-MM',
  'yy-MMM',
  'yy-MMMM',

  'MMM yy',
  'MMMM yy',
  'MMM %yy',
  'MMMM %yy',
  'MMM yyyy',
  'MMMM yyyy',

  'MMM d yy',
  'MMMM d yy',
  'MMM d %yy',
  'MMMM d %yy',
  'MMM d yyyy',
  'MMMM d yyyy',

  'MMM dd yy',
  'MMMM dd yy',
  'MMM dd %yy',
  'MMMM dd %yy',
  'MMM dd yyyy',
  'MMMM dd yyyy',

  'M/d/yy',
  'M/dd/yy',
  'MM/d/yy',
  'MM/dd/yy',
  'M-d-yy',
  'M-dd-yy',
  'MM-d-yy',
  'MM-dd-yy',

  'd/M/yy',
  'dd/M/yy',
  'd/MM/yy',
  'dd/MM/yy',
  'd-M-yy',
  'dd-M-yy',
  'd-MM-yy',
  'dd-MM-yy',

  'D',
  'DD',
  'DDD',

  'Qq yy',
  'Qq %yy',
  'Qq yyyy',
];

// Try Runway specific formats first and then fall back to some common formats
// and finally fall back to fromISO.
const DT_PARSE_ATTEMPTS = [
  (raw: string) => (isValidMonthKey(raw) ? getDateTimeFromMonthKey(raw) : null),
  getFromShortMonthWithDay,
  getFromShortMonth,
  getFromShortQuarter,
  ...DATE_PARSE_ATTEMPT_FORMATS.map(
    (f) => (raw: string) => DateTime.fromFormat(raw.replace(`'`, '%'), f),
  ),
  (raw: string) => DateTime.fromISO(raw),
];

// TODO: I have tried a couple libraries that can parse many different date
// formats but they haven't had good (or any) built in types and don't work
// well in some common cases. If this solution proves to be too difficult to
// maintain or make flexible, we could consider trying to build something that
// does more intelligent pattern matching.
export const inferDate = (raw: string): DateTime | undefined => {
  let dt: DateTime | undefined;

  DT_PARSE_ATTEMPTS.some((fn) => {
    const attempted = fn(raw);
    if (attempted?.isValid && attempted.toUnixInteger() > 0) {
      dt = attempted;
      return true;
    }
    return false;
  });

  return dt;
};

export const MONTHS = [
  '', // placeholder so that months indexes line up nicely
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const ALL_DATE_DIFF_UNITS = ['d', 'm', 'q', 'y'] as const;
type DateDiffUnit = (typeof ALL_DATE_DIFF_UNITS)[number];

function isDateDiffUnit(unit: string): unit is DateDiffUnit {
  return ALL_DATE_DIFF_UNITS.includes(unit as DateDiffUnit);
}

export const DATE_DIFF_UNIT_ARG_IDX = 2;

export const DATE_DIFF_UNIT_TO_DURATION_UNIT: Record<DateDiffUnit, DurationUnit> = {
  d: 'days',
  m: 'months',
  q: 'quarters',
  y: 'years',
};

export function getLuxonUnitFromText(text: string): DurationUnit | null {
  const lower = text.toLowerCase();
  if (!isDateDiffUnit(lower)) {
    return null;
  }
  return DATE_DIFF_UNIT_TO_DURATION_UNIT[lower];
}

const DATE_LITERAL_FORMAT = 'yyyy-MM-dd';
export function parseLiteralDateString(literal: string): DateTime | null {
  const d = DateTime.fromFormat(literal, DATE_LITERAL_FORMAT);
  return d.isValid ? d : null;
}

// starts on Monday, ends on Sunday
export const DEFAULT_WEEKEND_MASK = [false, false, false, false, false, true, true];

export function weekendMaskStrInvalidReason(weekendMaskStr: string): string | null {
  if (weekendMaskStr.length === 0) {
    return null;
  }
  if (weekendMaskStr.length !== 7) {
    return 'Weekend string must have a lengh of 7';
  }

  if (weekendMaskStr.match(/[^01]/)) {
    return 'Weekend string contains non 0 or 1 characters';
  }

  return null;
}

export function workDaysBetweenDates(
  start: DateTime,
  end: DateTime,
  weekendMask: boolean[],
  holidays: DateTime[],
) {
  let workdays = 0;
  let curr = start;

  while (curr < end) {
    // Check if it's a work day
    if (!weekendMask[curr.weekday - 1]) {
      // If so, we need to also check if it's a holiday
      if (!holidays.find((holiday) => curr.hasSame(holiday, 'day'))) {
        workdays++;
      }
    }

    curr = curr.plus({ days: 1 });
  }

  return workdays;
}

export const getFinancialQuarter = memoize(
  (offset: number, monthKey: MonthKey, isEnd?: boolean): string => {
    const thisMonthDateTime = getDateTimeFromMonthKey(monthKey);
    const quarter = getQuarter(thisMonthDateTime);
    const quarterBoundMonthFn = isEnd ? getQuarterEndMonth : getQuarterStartMonth;
    return getMonthKey(
      quarterBoundMonthFn(quarter, thisMonthDateTime.year).plus({ month: 3 * offset }),
    );
  },
  (offset: number, monthKey: MonthKey, isEnd?: boolean) => [offset, monthKey, isEnd].join('.'), // delimiter is not in month keys
);

export const getFinancialYear = memoize(
  (offset: number, monthKey: MonthKey, isEnd?: boolean): string => {
    const baseTime = getDateTimeFromMonthKey(monthKey);
    const endOrStartAdjusted = isEnd ? baseTime.endOf('year') : baseTime.startOf('year');
    const result = endOrStartAdjusted.plus({ year: offset });
    return getMonthKey(result);
  },
  (offset: number, monthKey: MonthKey, isEnd?: boolean) => [offset, monthKey, isEnd].join('.'),
);

export function combineDateRanges(
  dateRanges: Array<[MonthKey, MonthKey]>,
): Array<[MonthKey, MonthKey]> {
  const combinedDateRanges: Array<[MonthKey, MonthKey]> = [];

  // Sort by start
  const sortedDateRanges = dateRanges.sort((a, b) => a[0].localeCompare(b[0]));

  let currentRange: [MonthKey, MonthKey] | null = null;
  sortedDateRanges.forEach((dateRange) => {
    if (currentRange == null) {
      currentRange = dateRange;
      return;
    }

    // if `dateRange` overlaps with `currentRange` at all, combine them
    // and continue to the next range. If they are disjoint, push
    // `currentRange`, make `dateRange` the new `currentRange` and
    // continue.
    if (dateRange[0] <= currentRange[1]) {
      currentRange = [
        currentRange[0],
        dateRange[1].localeCompare(currentRange[1]) < 0 ? currentRange[1] : dateRange[1],
      ];
    } else {
      combinedDateRanges.push(currentRange);
      currentRange = dateRange;
    }
  });

  if (currentRange != null) {
    combinedDateRanges.push(currentRange);
  }

  return combinedDateRanges;
}

/** Returns the number of years between two dates, rounded to the nearest year. */
export const getYearDifference = (start: DateTime, end: DateTime): number => {
  const yearsDelta = start.diff(end, 'years').years;
  return Math.abs(Math.round(yearsDelta));
};

export const formatIsoStringToLocalTime = (isoString: string | undefined, format: string) => {
  const dateTime =
    isoString != null
      ? DateTime.fromISO(isoString).minus({ minutes: new Date().getTimezoneOffset() })
      : undefined;
  return dateTime != null ? dateTime.toFormat(format) : undefined;
};
