import { CurveType, ImpactType, ModifierType, ValueType } from 'generated/graphql';
import { BusinessObjectFieldId } from 'reduxStore/models/businessObjects';
import { DriverId } from 'reduxStore/models/drivers';
import { ValueTimeSeries } from 'reduxStore/models/timeSeries';
import { Value } from 'reduxStore/models/value';
import { PlanTimelineEvent, PlanTimelineRow } from 'selectors/planTimelineSelector';
import { ISOTime, MonthKey } from 'types/datetime';

export const DEFAULT_EVENT_GROUP_ID = 'default_event_group';

export type EventId = string;
export type EventGroupId = string;

type EventRef = { type: 'event'; id: EventId };
type EventGroupRef = { type: 'group'; id: EventGroupId };
type DriverEntityRef = {
  type: 'entity';
  entityType: 'driver';
  id: `${EventGroupId}:${DriverId}`;
  parentId: EventGroupId | undefined;
  entityId: DriverId;
  // A single entity ref can be associated with multiple Events
  refs: EventRef[];
};
type ObjectEntityRef = {
  type: 'entity';
  entityType: 'objectField';
  id: `${EventGroupId}:${BusinessObjectFieldId}`;
  parentId: EventGroupId | undefined;
  entityId: BusinessObjectFieldId;
  // A single entity ref can be associated with multiple Events
  refs: EventRef[];
};
// This is a layer of abstraction on top of PlanTimelineItem,
// which is used for a lot of UI interactions on the Plan Timeline.
export type PlanTimelineItemRef = EventRef | EventGroupRef | DriverEntityRef | ObjectEntityRef;

export function refFromPlanTimelineItem(
  item: PlanTimelineRow | PlanTimelineEvent,
): PlanTimelineItemRef {
  if (item.type === 'driver' || item.type === 'objectField') {
    return {
      type: 'entity',
      entityType: item.type,
      id: item.id,
      parentId: item.parentId,
      entityId: item.entityId,
      refs: item.events.map((i) => ({ type: 'event', id: i.id })),
    };
  }

  const type = item.type === 'group' ? 'group' : 'event';

  return { type, id: item.id };
}

export function isPlanTimelineItemRefEqual(
  a: PlanTimelineItemRef | undefined | null,
  b: PlanTimelineItemRef | undefined | null,
): boolean {
  if (a == null || b == null) {
    // Even if they're both null, they're not equal
    return false;
  }

  const baseEquality = a.type === b.type && a.id === b.id;

  if (a.type !== 'entity' || b.type !== 'entity') {
    return baseEquality;
  }

  return baseEquality && a.entityId === b.entityId && a.parentId === b.parentId;
}

export function isEventType(
  ref: PlanTimelineItemRef,
): ref is PlanTimelineItemRef & { type: 'event' } {
  return ref.type === 'event';
}

export function isEntityType(
  ref: PlanTimelineItemRef,
): ref is PlanTimelineItemRef & { type: 'entity' } {
  return ref.type === 'entity';
}

export { CurveType, ModifierType } from 'generated/graphql';

export type EventGroup = {
  id: EventGroupId;
  name: string;
  description?: string;
  parentId?: EventGroupId;
  hidden: boolean;
  eventIds: EventId[];
  eventGroupIds: EventGroupId[];
  ownerName: string;
  ownerId: string;
  defaultStart?: ISOTime;
  defaultEnd?: ISOTime;
  sortIndex?: number;
};

export type PopulatedEventGroup = Omit<EventGroup, 'eventIds' | 'eventGroupIds'> & {
  events: Event[];
  eventGroups: PopulatedEventGroup[];
};

export type EventCommon = {
  id: string;
  name?: string;
  description?: string;
  parentId?: EventGroupId;
  start: ISOTime;
  end: ISOTime;
  hidden: boolean;
  ownerName: string;
  version: string;
  sortIndex?: number;
  customCurvePoints?: ValueTimeSeries;
  monthKey?: MonthKey;
  value?: Value;
};

type SetImpact = {
  impactType: ImpactType.Set;
  valueType: ValueType;
  setValue?: string;
};

export type DeltaImpact = {
  impactType: ImpactType.Delta;
  modifierType: ModifierType;
  curveType: CurveType;
  totalImpact?: number;
};

export type CurvePointTyped = Value & { impactType: ImpactType.Set | ImpactType.Delta };

export type Event = DriverEvent | ObjectFieldEvent;

export type DeltaDriverEvent = EventCommon & DeltaImpact & { driverId: DriverId };
export type SetDriverEvent = EventCommon & SetImpact & { driverId: DriverId };

export type DriverEvent = DeltaDriverEvent | SetDriverEvent;

export type ObjectFieldEvent = EventCommon &
  SetImpact & {
    businessObjectFieldId: BusinessObjectFieldId;
  };

export function isDriverEvent(data: Event): data is DriverEvent {
  return 'driverId' in data;
}

export function isObjectFieldEvent(data: Event): data is ObjectFieldEvent {
  return 'businessObjectFieldId' in data;
}

export function isEvent(data: Event | EventGroup): data is Event {
  return 'driverId' in data || 'businessObjectFieldId' in data;
}

export function isEventGroup(data: Event | EventGroup): data is EventGroup {
  return 'eventIds' in data;
}

// Using setValue and modifierType as proxies. Eventually we may want an
// explicit "type" string if there's overlap in field names.

export function isSetImpactEvent(data: Event): data is Event & SetImpact {
  return data.impactType === ImpactType.Set;
}

export function isDeltaImpactEvent(data: Event): data is Event & DeltaImpact {
  return data.impactType === ImpactType.Delta;
}

export function getFlattenedEventIdsForGroup(
  id: EventGroupId,
  eventGroupsById: Record<EventGroupId, EventGroup>,
): EventId[] {
  const eventIds: EventId[] = [];
  const groupIds: EventGroupId[] = [id];
  while (groupIds.length > 0) {
    const group = eventGroupsById[groupIds.pop() ?? ''];
    if (group != null) {
      eventIds.push(...group.eventIds);
      groupIds.push(...group.eventGroupIds);
    }
  }
  return eventIds;
}
