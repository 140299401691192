import { createCachedSelector } from 're-reselect';

import { CELL_DATA_COLUMN_WIDTH_IN_PX } from 'config/cells';
import {
  COLUMN_TYPE_TO_DEFAULT_WIDTH,
  ColumnOpts,
  ColumnOrMonthKey,
  DriverPropertyColumn,
  MODEL_VIEW_DATA_COLUMN_TYPE,
  ModelViewColumnType,
  NAME_COLUMN_TYPE,
} from 'config/modelView';
import { ComparisonColumn } from 'generated/graphql';
import { BlockId } from 'reduxStore/models/blocks';
import { isModelViewColumnType } from 'reduxStore/reducers/helpers/submodels';
import { configurableColumnsSelector } from 'selectors/configurableColumnsSelector';
import { blockIdSelector } from 'selectors/constSelectors';
import { isDataVisibleSelector } from 'selectors/isDataVisibleSelector';
import { currentLayerIdSelector } from 'selectors/layerSelector';
import { liveEditBlockColumnResizeSelector } from 'selectors/liveEditSelector';
import { businessObjectSpecForBlockSelector } from 'selectors/orderedFieldSpecIdsSelector';
import { blockMonthKeysSelector } from 'selectors/pageDateRangeSelector';
import {
  comparisonColumnsForBlockSelector,
  comparisonLayoutSelector,
} from 'selectors/rollupSelector';
import { comparisonLayerIdsForBlockSelector } from 'selectors/scenarioComparisonSelector';
import { ParametricSelector } from 'types/redux';

export const visibleColumnsSelector: ParametricSelector<BlockId, ColumnOpts[]> =
  createCachedSelector(
    configurableColumnsSelector,
    isDataVisibleSelector,
    blockMonthKeysSelector,
    liveEditBlockColumnResizeSelector,
    (columns, isDataVisible, monthKeys, liveEdit) => {
      const visibleColumns = columns
        .filter((col) => col.visible && col.key !== MODEL_VIEW_DATA_COLUMN_TYPE)
        .map((col) => {
          const liveWidth = liveEdit?.columnKey === col.key ? liveEdit.width : null;
          return {
            ...col,
            width:
              liveWidth ??
              col.width ??
              COLUMN_TYPE_TO_DEFAULT_WIDTH[col.key as ModelViewColumnType] ??
              CELL_DATA_COLUMN_WIDTH_IN_PX,
          };
        });

      if (!isDataVisible) {
        return visibleColumns;
      }

      return [
        ...visibleColumns,
        ...monthKeys.map((key) => ({
          key,
          visible: true,
          // N.B. we currently disallow resizing data columns to avoid issues
          // with scroll syncing.
          width: CELL_DATA_COLUMN_WIDTH_IN_PX,
        })),
      ];
    },
  )(blockIdSelector);

export const visibleStickyColumnsSelector: ParametricSelector<BlockId, ColumnOpts[]> =
  createCachedSelector(
    visibleColumnsSelector,
    businessObjectSpecForBlockSelector,
    blockMonthKeysSelector,
    liveEditBlockColumnResizeSelector,
    (columns, spec) => {
      const isDatabaseKey = (key: string) =>
        spec?.collection?.dimensionalProperties.find(
          (dimProp) => dimProp.id === key && dimProp.isDatabaseKey,
        );
      return columns.filter((column) =>
        spec != null ? isDatabaseKey(column.key) || column.key === NAME_COLUMN_TYPE : true,
      );
    },
  )(blockIdSelector);

export const visibleColumnTypesSelector: ParametricSelector<BlockId, ColumnOrMonthKey[]> =
  createCachedSelector(visibleColumnsSelector, (cols) => cols.map((col) => col.key))(
    blockIdSelector,
  );
export const visibleModelViewColumnTypesSelector: ParametricSelector<
  BlockId,
  ModelViewColumnType[]
> = createCachedSelector(visibleColumnTypesSelector, (cols) =>
  cols.filter((c: ColumnOrMonthKey): c is ModelViewColumnType => isModelViewColumnType(c)),
)(blockIdSelector);

export const driverPropertyColumnsSelector: ParametricSelector<BlockId, DriverPropertyColumn[]> =
  createCachedSelector(
    visibleModelViewColumnTypesSelector,
    comparisonLayoutSelector,
    comparisonLayerIdsForBlockSelector,
    currentLayerIdSelector,
    comparisonColumnsForBlockSelector,
    (columnTypes, comparisonLayout, comparisonLayerIds, currentLayerId, comparisonTypes) => {
      const isOldCompactLayout = comparisonLayout == null && comparisonLayerIds.length === 1;
      const isNewColumnLayout =
        comparisonLayout === 'column-default' || comparisonLayout === 'column-compact';

      return columnTypes.map((type) => {
        return {
          type,
          layerIds:
            isNewColumnLayout && type !== NAME_COLUMN_TYPE
              ? comparisonLayerIds.filter(
                  (lId) =>
                    // Only show the currentLayerId as a subcolumn if "Baseline Version" is selected
                    // by the user. This allows the user to toggle off the current layer id, for example
                    // if they only want to see the snapshot data. In the future, we'll support a less
                    // hacky way for them to do this.
                    lId !== currentLayerId ||
                    comparisonTypes.includes(ComparisonColumn.BaselineVersion) ||
                    comparisonTypes.includes(ComparisonColumn.LatestVersion),
                )
              : // The old compact comparison layout is special because we want to show the properties of the
                // current layer ID.
                isOldCompactLayout
                ? [currentLayerId]
                : [undefined],
        };
      });
    },
  )(blockIdSelector);
