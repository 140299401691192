import { useBoolean } from '@chakra-ui/react';
import { scaleLinear, scaleOrdinal, scaleUtc } from '@visx/scale';
import { isNumber, uniq } from 'lodash';
import React, { useCallback, useMemo } from 'react';

import { DEFAULT_DRIVER_CHART_SIZE } from 'config/block';
import { DriverChartContext, DriverChartContextData, DriverChartDatum } from 'config/driverChart';
import theme from 'config/theme';
import { getChartDimensions, getMinMaxVals, timeSeriesToChartData } from 'helpers/chart';
import { getMonthKey, getMonthKeysForRange } from 'helpers/dates';
import { isFiniteNumber } from 'helpers/typescript';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import useChartForecastMonthKeys from 'hooks/useChartForecastMonthKeys';
import { DriverId } from 'reduxStore/models/drivers';
import { LayerId } from 'reduxStore/models/layers';
import { isAggregatedValueChart } from 'reduxStore/reducers/helpers/viewOptions';
import { baselineLayerIdForBlockSelector } from 'selectors/baselineLayerSelector';
import { blockConfigViewOptionsSelector } from 'selectors/blocksSelector';
import { driverTimeSeriesByLayerIdForBlockSelector } from 'selectors/driverTimeSeriesByLayerIdForBlockSelector';
import { firstMilestoneForDriverSelector } from 'selectors/milestonesSelector';
import { blockDateRangeDateTimeSelector } from 'selectors/pageDateRangeSelector';
import { resolvedGeneralFormatFromBlockIdSelector } from 'selectors/resolvedEntityFormatSelector';
import { comparisonLayerIdsForBlockSelector } from 'selectors/scenarioComparisonSelector';

const COLORS = theme.colors;

interface Props {
  driverIds: DriverId[];
  children: React.ReactNode;
}

const LINE_COLORS = [
  COLORS.watermelon,
  COLORS.seablue,
  COLORS.sunburst,
  COLORS.lilac,
  COLORS.azuresky,
  COLORS.fern,
  COLORS.daffodil,
  COLORS.tangerine,
  COLORS.orchid,
  COLORS.aquamarine,
  COLORS.rosemist,
  COLORS.amethyst,
];

interface AggregatedChartData {
  date: Date;
  monthKey: string;
  driverValues: Record<string, number | null>;
}

export interface FullAggregateChartData {
  positiveValues: AggregatedChartData[];
  negativeValues: AggregatedChartData[];
}

const mapDriverDataToTimeseries = (
  timeSeriesByDriverIds: Record<string, DriverChartDatum[]>,
): FullAggregateChartData => {
  const driverTimeseriesPositiveData: AggregatedChartData[] = [];
  const driverTimeseriesNegativeData: AggregatedChartData[] = [];

  Object.keys(timeSeriesByDriverIds).map((driverId) => {
    const driverData = timeSeriesByDriverIds[driverId];

    driverData.forEach((element, idx) => {
      if (driverTimeseriesPositiveData[idx] == null) {
        driverTimeseriesPositiveData[idx] = {
          date: element.x,
          monthKey: element.monthKey,
          driverValues: {},
        };

        driverTimeseriesNegativeData[idx] = {
          date: element.x,
          monthKey: element.monthKey,
          driverValues: {},
        };
      }

      const isNull = element.y == null || !isFiniteNumber(element.y);
      let positiveValue, negativeValue;

      if (isNull) {
        positiveValue = null;
        negativeValue = null;
      } else if (element.y === 0) {
        positiveValue = element.y;
        negativeValue = element.y;
      } else if (element.y > 0) {
        positiveValue = element.y;
        negativeValue = null;
      } else {
        positiveValue = null;
        negativeValue = element.y;
      }

      driverTimeseriesPositiveData[idx] = {
        ...driverTimeseriesPositiveData[idx],
        driverValues: {
          ...driverTimeseriesPositiveData[idx].driverValues,
          [driverId]: positiveValue,
        },
      };

      driverTimeseriesNegativeData[idx] = {
        ...driverTimeseriesNegativeData[idx],
        driverValues: {
          ...driverTimeseriesNegativeData[idx].driverValues,
          [driverId]: negativeValue,
        },
      };
    });
  });

  return {
    positiveValues: driverTimeseriesPositiveData,
    negativeValues: driverTimeseriesNegativeData,
  };
};

const DriverChartContextProvider: React.FC<Props> = ({ children, driverIds }) => {
  const { blockId } = useBlockContext();
  const [hiddenDrivers, setToggledDrivers] = React.useState<DriverId[]>([]);

  const [isScenariosLegendVisible, setIsScenariosLegendVisible] = useBoolean();
  const toggleIsScenariosLegendVisible = useCallback(() => {
    setIsScenariosLegendVisible.toggle();
  }, [setIsScenariosLegendVisible]);

  let filteredDriverIds = driverIds.filter((driverId) => !hiddenDrivers.includes(driverId));

  if (filteredDriverIds.length === 0) {
    setToggledDrivers([]);
    filteredDriverIds = driverIds;
  }

  const allMilestones = useAppSelector((state) => {
    return filteredDriverIds.map((driverId) => firstMilestoneForDriverSelector(state, driverId));
  });
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));
  const aggregateValues = isAggregatedValueChart(viewOptions ?? {});

  const comparisonLayerIds = useAppSelector((state) =>
    comparisonLayerIdsForBlockSelector(state, blockId),
  );
  const isComparingLayers = comparisonLayerIds.length > 0;

  const baselineLayerId = useAppSelector((state) =>
    baselineLayerIdForBlockSelector(state, blockId),
  );

  const format = useAppSelector((state) =>
    resolvedGeneralFormatFromBlockIdSelector(state, blockId),
  );

  const { xMax, yMax } = getChartDimensions(viewOptions);

  const [blockStartDateTime, endDateTime] = useAppSelector((state) =>
    blockDateRangeDateTimeSelector(state, blockId),
  );

  const toggleDriver = useCallback(
    (driverId: DriverId) => {
      if (hiddenDrivers.includes(driverId)) {
        setToggledDrivers((prev) => prev.filter((id) => id !== driverId));
      } else {
        setToggledDrivers((prev) => [...prev, driverId]);
      }
    },
    [hiddenDrivers],
  );

  // We want this chart to be edge to edge but if we don't start the time
  // scale at the end of the first month, we'll have a bunch of empty space.
  const startDateTime = useMemo(() => blockStartDateTime.endOf('month'), [blockStartDateTime]);

  const timeScale = useMemo(
    () =>
      scaleUtc({
        range: [0, xMax],
        domain: [startDateTime.toJSDate(), endDateTime.toJSDate()],
      })
        // Clamp time scale to ensure charts always stay within x bounds
        .clamp(true),
    [xMax, startDateTime, endDateTime],
  );

  const monthKeysWithActualsData = getMonthKeysForRange(startDateTime, endDateTime);

  // We should refactor this to a selector that takes driver ids and block id and return a min, max values
  const driverTimeSeriesByLayerId: Record<
    LayerId,
    Record<DriverId, DriverChartDatum[]>
  > = useAppSelector((state) => {
    const results: Record<DriverId, Record<LayerId, DriverChartDatum[]>> = {};
    filteredDriverIds.forEach((driverId) => {
      const layerIdsToTimeseries = driverTimeSeriesByLayerIdForBlockSelector(state, {
        driverId,
        blockId,
      });

      Object.keys(layerIdsToTimeseries).forEach((layerId) => {
        if (results[layerId] == null) {
          results[layerId] = {};
        }

        const driverTimeseriesData = layerIdsToTimeseries[layerId];

        const formatted = timeSeriesToChartData(
          driverTimeseriesData,
          monthKeysWithActualsData,
          format,
          true,
        );

        results[layerId] = {
          ...results[layerId],
          [driverId]: formatted,
        };
      });
    });

    return results;
  });

  const baseLayerData = driverTimeSeriesByLayerId[baselineLayerId];

  const fullAggregateChartData = mapDriverDataToTimeseries(baseLayerData);

  const { positiveValues, negativeValues } = fullAggregateChartData;

  const { minValue, maxValue } = useMemo(() => {
    if (aggregateValues) {
      let min = 0;
      let max = Number.MIN_VALUE;

      const findMinMaxValue = (interval: AggregatedChartData) => {
        const values = Object.values(interval.driverValues).filter(isFiniteNumber);
        const sum = values.reduce((partialSum, a) => partialSum + a, 0);

        min = Math.min(min, sum, ...values);
        max = Math.max(max, sum, ...values);
      };

      positiveValues.forEach(findMinMaxValue);

      negativeValues.forEach(findMinMaxValue);

      return { minValue: min, maxValue: max };
    } else {
      let layerIds = [baselineLayerId];

      if (isComparingLayers) {
        layerIds = uniq([...comparisonLayerIds, ...layerIds]);
      }

      const allValues = Object.keys(driverTimeSeriesByLayerId)
        .map((layerId) => {
          if (!layerIds.includes(layerId)) {
            return [];
          }

          const timeSeriesByDriverId = driverTimeSeriesByLayerId[layerId];
          let timeSeriesChartData: DriverChartDatum[] = [];

          Object.keys(timeSeriesByDriverId).forEach((driverId) => {
            const dataForDriverId = timeSeriesByDriverId[driverId];

            timeSeriesChartData = timeSeriesChartData.concat(dataForDriverId);
          });

          return [...timeSeriesChartData.map((d) => d.y)].filter(isFinite);
        })
        .concat(
          allMilestones
            .map((milestone) => (milestone != null ? milestone.value : null))
            .filter(isNumber),
        )
        .flat();

      return getMinMaxVals(allValues, format, {
        addHeadroom: false,
      });
    }
  }, [
    aggregateValues,
    positiveValues,
    negativeValues,
    baselineLayerId,
    isComparingLayers,
    driverTimeSeriesByLayerId,
    allMilestones,
    format,
    comparisonLayerIds,
  ]);

  const valueScale = useMemo(
    () =>
      scaleLinear({
        range: [yMax, 0],
        domain: [minValue, maxValue],
        nice: true,
      }),
    [yMax, minValue, maxValue],
  );

  const monthKeysWithForecastData = useChartForecastMonthKeys(startDateTime, endDateTime);
  const size = viewOptions?.chartSize ?? DEFAULT_DRIVER_CHART_SIZE;

  const driverIdsToColors = useMemo(() => {
    const colors: Record<DriverId, string> = {};

    if (driverIds.length === 1) {
      return {};
    }

    driverIds.forEach((driverId, index) => {
      const lineColor = LINE_COLORS[index % LINE_COLORS.length];
      colors[driverId] = lineColor != null ? lineColor[500] : 'black';
    });

    return colors;
  }, [driverIds]);

  const colorScale = scaleOrdinal({
    domain: driverIds,
    range: LINE_COLORS,
  });

  const chartContext: DriverChartContextData = useMemo(
    () => ({
      height: yMax,
      width: xMax,
      timeScale,
      valueScale,
      driverIds: filteredDriverIds,
      allDriverIds: driverIds,
      format,
      baselineLayerId,
      monthKeysWithForecastData,
      startDateTime: getMonthKey(startDateTime),
      endDateTime: getMonthKey(endDateTime),
      size,
      driverIdsToColors,
      toggleDriver,
      fullAggregateChartData,
      colorScale,
      isScenariosLegendVisible,
      toggleIsScenariosLegendVisible,
    }),
    [
      yMax,
      xMax,
      timeScale,
      valueScale,
      filteredDriverIds,
      driverIds,
      format,
      baselineLayerId,
      monthKeysWithForecastData,
      startDateTime,
      endDateTime,
      size,
      driverIdsToColors,
      toggleDriver,
      fullAggregateChartData,
      colorScale,
      isScenariosLegendVisible,
      toggleIsScenariosLegendVisible,
    ],
  );

  return <DriverChartContext.Provider value={chartContext}>{children}</DriverChartContext.Provider>;
};

export default DriverChartContextProvider;
