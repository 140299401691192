import { createSelector } from '@reduxjs/toolkit';
import pluralize from 'pluralize';
import React, { useCallback, useContext, useMemo } from 'react';
import { useKey } from 'react-use';

import DataSourceLogo from 'components/DataSourceLogo/DataSourceLogo';
import DriverSearchResult from 'components/DriverSearchResult/DriverSearchResult';
import EmojiIcon from 'components/EmojiWidget/EmojiIcon';
import FormulaDropdownContext from 'components/FormulaInput/FormulaDropdownContext';
import FormulaSelectionContext from 'components/FormulaInput/FormulaSelectionContext';
import FunctionsSelectMenu from 'components/FormulaReferenceMenu/FunctionsSelectMenu';
import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import CustomCreateOption from 'components/SelectMenu/CustomCreateOption';
import DriverSelectMenuFooter from 'components/SelectMenu/DriverSelectMenuFooter';
import ExtDriverSelectMenuFooter from 'components/SelectMenu/ExtDriverSelectMenuFooter';
import SelectMenu, { CustomOption, Section, SelectItem } from 'components/SelectMenu/SelectMenu';
import SelectMenuFooter from 'components/SelectMenu/SelectMenuFooter';
import SelectMenuItem from 'components/SelectMenu/SelectMenuItem';
import {
  MATH_OPERATOR_SELECT_ITEMS,
  SPECIAL_MATH_SELECT_ITEMS,
  matchesMathOperator,
} from 'config/formula';
import { preventEventDefault } from 'helpers/browserEvent';
import { DATE_DIFF_UNIT_TO_DURATION_UNIT } from 'helpers/dates';
import {
  CursorPositionFuncMetadata,
  getFuncArgDisplayName,
  queryIsNumeric,
  queryIsObjectQuery,
} from 'helpers/draftEditor';
import { getSearchName } from 'helpers/drivers';
import { extractEmoji } from 'helpers/emoji';
import { invalidNameError } from 'helpers/errorMessages';
import { extDriverDisplayName } from 'helpers/extDrivers';
import { checkNameIsUniqueCaseInsensitive } from 'helpers/naming';
import { isNotNull, nullSafeEqual, safeObjGet } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import { useAccessCapabilities } from 'hooks/useAccessCapabilities';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { createNewDriversInContext } from 'reduxStore/actions/driverMutations';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { Attribute } from 'reduxStore/models/dimensions';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import { BasicDriver, DimensionalDriver } from 'reduxStore/models/drivers';
import { ExtDriver } from 'reduxStore/models/extDrivers';
import { SubmodelId } from 'reduxStore/models/submodels';
import { FormulaEntity } from 'reduxStore/reducers/formulaInputSlice';
import { accessResourcesByIdSelector } from 'selectors/accessResourcesByIdSelector';
import {
  accessibleDimDriversByIdSelector,
  numericDimDriversForLayerSelector,
  numericDriversForLayerSelector,
} from 'selectors/accessibleDriversSelector';
import { blockConfigShowRestrictedSelector } from 'selectors/blocksSelector';
import {
  businessObjectSpecsByFieldSpecIdForLayerSelector,
  businessObjectSpecsForLayerSelector,
} from 'selectors/businessObjectSpecsSelector';
import { driverGroupsByIdSelector } from 'selectors/driverGroupSelector';
import {
  allDriverNamesSelector,
  dimensionalDriversBySubDriverIdSelector,
} from 'selectors/driversSelector';
import { extDriversSelector } from 'selectors/extDriversSelector';
import { recentFormulaEntitiesSelector } from 'selectors/formulaInputSelector';
import { enableDriverThisSegmentSelector } from 'selectors/launchDarklySelector';
import { accessibleDatabasesSelector } from 'selectors/navigationSelector';
import { submodelNamesByIdSelector } from 'selectors/submodelPageSelector';
import { sortedAccessibleSubmodelIdsSelector } from 'selectors/submodelSelector';
import { submodelsByIdSelector } from 'selectors/submodelsByIdSelector';
import { AttributeFilters, EntityType, MathOperator } from 'types/formula';
import FormulaIcon from 'vectors/Formula';
import NumberSignIcon from 'vectors/NumberSign';
import PlusIcon from 'vectors/Plus';
import TextSignIcon from 'vectors/TextSign';

interface BaseFormulaReferenceSelectItem extends SelectItem {
  name: string;
}

export type FormulaReferenceSelectItem = BaseFormulaReferenceSelectItem &
  (
    | {
        type: 'driver';
        driver: BasicDriver | DimensionalDriver;
        attributes: Attribute[] | undefined;
      }
    | {
        type: 'driverFilter';
        driver: DimensionalDriver;
        filters: AttributeFilters;
      }
    | {
        type: 'driverGroup';
        submodelId: SubmodelId;
        driverGroupId: DriverGroupId;
        icon: JSX.Element;
      }
    | { type: 'math'; icon: JSX.Element; operator: MathOperator }
    | { type: 'number'; icon: JSX.Element }
    | {
        type: 'extDriver';
        extDriver: ExtDriver;
      }
    | {
        type: 'databases';
        icon: JSX.Element;
        specId: BusinessObjectSpecId;
        fieldId?: BusinessObjectFieldSpecId;
        driver?: BasicDriver | DimensionalDriver;
        isThisRef?: boolean;
      }
    | { type: 'functions' }
    | { type: 'variables' }
    | {
        type: 'string';
        icon: JSX.Element;
      }
  );

function isRecentlyUsedEntity(item: FormulaReferenceSelectItem, entity: FormulaEntity): boolean {
  switch (item.type) {
    case 'driver': {
      return entity.type === EntityType.Driver && item.id === entity.data.id;
    }
    case 'driverGroup': {
      return entity.type === EntityType.Submodel && item.id === entity.data.id;
    }
    case 'extDriver': {
      return entity.type === EntityType.ExtDriver && item.id === entity.data.id;
    }
    case 'databases': {
      return (
        entity.type === EntityType.ObjectSpec &&
        item.specId === entity.data.id &&
        nullSafeEqual(item.fieldId, entity.data.fieldId)
      );
    }
    default: {
      return false;
    }
  }
}

function funcParamStringLiteralOptions(
  cursorFuncQueryMetadata: CursorPositionFuncMetadata | null,
): Array<FormulaReferenceSelectItem & { type: 'string' }> {
  if (cursorFuncQueryMetadata == null) {
    return [];
  }

  const { func, argSlotIdx } = cursorFuncQueryMetadata;

  if (matchesMathOperator(func.name, MathOperator.DateDiff) && argSlotIdx === 2) {
    return Object.entries(DATE_DIFF_UNIT_TO_DURATION_UNIT).map(([unit, longUnit]) => ({
      type: 'string',
      id: unit,
      icon: <TextSignIcon />,
      footer: (
        <SelectMenuFooter
          icon={<TextSignIcon />}
          title="Time Unit"
          subtitle={`Calculate date difference in ${longUnit}`}
        />
      ),
      name: `'${unit}'`,
      sectionId: 'arg',
    }));
  }

  return [];
}

const SEARCH_KEYS = ['searchableName', 'name'];
// Order is important
const SECTION_IDS = [
  'thisDatabase',
  'recent',
  'number',
  'arg',
  'math',
  'specialMath',
  'variables',
  'driver',
  'driverGroup',
  'databases',
  'extDriver',
] as const;
type SectionId = (typeof SECTION_IDS)[number];

const BASE_SECTIONS_BY_ID: Record<SectionId, Section> = {
  thisDatabase: {
    id: 'thisDatabase',
    name: 'This Database',
    maxResults: 5,
    showMore: true,
  },
  recent: {
    id: 'recent',
    name: 'Recent',
    maxResults: 5,
    showMore: false,
  },
  number: {
    id: 'number',
    name: 'Number',
  },
  arg: {
    id: 'arg',
  },
  math: {
    id: 'math',
    name: 'Functions',
  },
  specialMath: {
    id: 'specialMath',
    name: 'Values',
  },
  extDriver: {
    id: 'extDriver',
    name: 'External Drivers',
    maxResults: 5,
    showMore: true,
  },
  driver: {
    id: 'driver',
    name: 'Drivers',
    maxResults: 5,
    showMore: true,
  },
  driverGroup: {
    id: 'driverGroup',
    name: 'Driver Groups',
    maxResults: 3,
    showMore: true,
  },
  databases: {
    id: 'databases',
    name: 'Databases',
    maxResults: 5,
    showMore: true,
  },
  variables: {
    id: 'variables',
    name: 'Variables',
  },
};

const FUNCTION_ITEMS: Array<FormulaReferenceSelectItem & { type: 'math' }> =
  MATH_OPERATOR_SELECT_ITEMS.map(({ operator, name, help }) => ({
    type: 'math',
    id: operator,
    name,
    icon: <FormulaIcon />,
    footer: <SelectMenuFooter icon={<FormulaIcon />} title={help.title} subtitle={help.subtitle} />,
    operator,
    sectionId: 'math',
  }));

const SPECIAL_MATH_ITEMS: Array<FormulaReferenceSelectItem & { type: 'math' }> =
  SPECIAL_MATH_SELECT_ITEMS.map(({ operator, name, help }) => ({
    type: 'math',
    id: operator,
    name,
    icon: <NumberSignIcon />,
    footer: <SelectMenuFooter icon={<FormulaIcon />} title={help.title} subtitle={help.subtitle} />,
    operator,
    sectionId: 'specialMath',
  }));

const VARIABLES_ITEMS: Array<FormulaReferenceSelectItem & { type: 'variables' }> = [
  {
    type: 'variables',
    id: 'thisSegment',
    name: 'This Segment',
    icon: <NumberSignIcon />,
    sectionId: 'variables',
  },
];

export interface FormulaReferenceMenuProps {
  query: string;
  cursorFuncQueryMetadata: CursorPositionFuncMetadata | null;
}

const searchableExtDriversSelector = createSelector(extDriversSelector, (extDrivers) => {
  return extDrivers.map((extDriver) => ({
    type: 'extDriver' as const,
    id: extDriver.id,
    name: extDriverDisplayName(extDriver),
    extDriver,
    footer: <ExtDriverSelectMenuFooter extDriverId={extDriver.id} />,
    sectionId: 'extDriver',
  }));
});

const searchableDriverItemsSelector = createSelector(
  [numericDriversForLayerSelector, numericDimDriversForLayerSelector],
  (numericDrivers, numericDimDrivers) => [
    ...numericDrivers.map((driver) => {
      const { id, name, attributes } = driver;
      return {
        type: 'driver' as const,
        id,
        key: id,
        driver,
        name,
        attributes,
        footer: <DriverSelectMenuFooter driverId={id} />,
        sectionId: 'driver',
        searchableName: getSearchName({ name: driver.name, attributes }),
      };
    }),
    ...numericDimDrivers.map((driver) => {
      const { id, name } = driver;
      return {
        type: 'driver' as const,
        id,
        key: id,
        driver,
        name,
        attributes: undefined,
        footer: <DriverSelectMenuFooter driverId={id} />,
        sectionId: 'driver',
      };
    }),
  ],
);

const FormulaReferenceMenu: React.FC<FormulaReferenceMenuProps> = ({
  query,
  cursorFuncQueryMetadata,
}) => {
  const accessCapabilities = useAccessCapabilities();
  const { blockId } = useBlockContext();
  const { formulaEntityId, isActuals } = useContext(FormulaSelectionContext);
  const {
    insertFormulaEntity,
    onSelectMathOperator,
    insertDriverGroup,
    insertExtDriver,
    insertObjectSpec,
    insertThisSegment,
    onSelectRawFormulaQuery,
    onSelectStringLiteral,
    lastCreatedDriverId,
    setLastCreatedDriverId,
    canCreateDriver,
  } = useContext(FormulaDropdownContext);
  const allDriverNames = useAppSelector(allDriverNamesSelector);
  // TODO: temporarily limiting driver references to numeric drivers only while we
  // figure out how to support non-numeric drivers in formulas
  const dimDriversById = useAppSelector(accessibleDimDriversByIdSelector);
  const recentlyUsedEntities = useAppSelector(recentFormulaEntitiesSelector);
  const blockConfigShowRestricted = useAppSelector((state) =>
    blockConfigShowRestrictedSelector(state, blockId),
  );

  // "This Segment"
  const enableDriverThisSegment = useAppSelector(enableDriverThisSegmentSelector);
  const dimDriver = useAppSelector((state) =>
    formulaEntityId?.type === 'driver'
      ? dimDriversById[formulaEntityId.id] ??
        dimensionalDriversBySubDriverIdSelector(state)[formulaEntityId.id]
      : null,
  );
  const segmentByDimDriverId = dimDriver?.id;
  const showThisSegmentOptions = enableDriverThisSegment && dimDriver != null;

  const driverItems = useAppSelector(searchableDriverItemsSelector);
  const accessibleSubmodelIds = useAppSelector(sortedAccessibleSubmodelIdsSelector);
  const submodelsById = useAppSelector(submodelsByIdSelector);
  const allSubmodels = accessibleSubmodelIds.map((id) => submodelsById[id]).filter(isNotNull);
  const submodelNamesBySubmodelId = useAppSelector(submodelNamesByIdSelector);

  const driverGroupsById = useAppSelector(driverGroupsByIdSelector);
  const driverGroupItems = useMemo<
    Array<FormulaReferenceSelectItem & { type: 'driverGroup' }>
  >(() => {
    return allSubmodels.flatMap((s) => {
      return (s.sortedDriverGroupIds ?? [])
        .map((id) => (id != null ? driverGroupsById[id] : null))
        .filter(isNotNull)
        .map((group) => {
          const submodelNameWithEmoji = safeObjGet(submodelNamesBySubmodelId[s.id]) ?? '';
          const [submodelEmoji, submodelName] = extractEmoji(submodelNameWithEmoji);
          const icon = <EmojiIcon size="sm" emoji={submodelEmoji} />;
          const name = `${submodelName} / ${group.name}`;
          return {
            type: 'driverGroup' as const,
            id: `${s.id}.${group.id}`,
            name,
            icon,
            submodelId: s.id,
            driverGroupId: group.id,
            sectionId: 'driverGroup',
            footer: (
              <SelectMenuFooter
                icon={icon}
                title={name}
                subtitle={`A reference to all the drivers in the ${group.name} group of the ${submodelName} model.`}
              />
            ),
          };
        });
    });
  }, [allSubmodels, driverGroupsById, submodelNamesBySubmodelId]);

  const objectSpecs = useAppSelector(businessObjectSpecsForLayerSelector);
  const accessResourcesById = useAppSelector(accessResourcesByIdSelector);
  const { canReadIntegrations } = useAccessCapabilities();
  const searchableExtDrivers = useAppSelector(searchableExtDriversSelector);

  const extDriverItems = useMemo<Array<FormulaReferenceSelectItem & { type: 'extDriver' }>>(() => {
    if (!isActuals || !canReadIntegrations) {
      return [];
    }
    return searchableExtDrivers;
  }, [searchableExtDrivers, isActuals, canReadIntegrations]);

  const specsByFieldSpecId = useAppSelector(businessObjectSpecsByFieldSpecIdForLayerSelector);
  const formulaEntitySpecId =
    formulaEntityId?.type === 'objectFieldSpec'
      ? specsByFieldSpecId[formulaEntityId.id]?.id
      : undefined;

  const accessibleDatabases = useAppSelector(accessibleDatabasesSelector);
  const databaseItems = useMemo<Array<FormulaReferenceSelectItem & { type: 'databases' }>>(() => {
    return objectSpecs
      .filter(({ id }) => accessibleDatabases.includes(id))
      .flatMap((objectSpec) => {
        const specId = objectSpec.id;
        const [emoji, name] = extractEmoji(objectSpec.name);
        const icon = <EmojiIcon size="sm" emoji={emoji} />;
        // If editing an object field formula for this object spec
        const showThisOptions = specId === formulaEntitySpecId;

        const objectSpecFieldItems: Array<FormulaReferenceSelectItem & { type: 'databases' }> =
          objectSpec.fields.flatMap((fieldSpec) => {
            if (
              fieldSpec.isRestricted &&
              !blockConfigShowRestricted &&
              !accessCapabilities.canWritePermissions
            ) {
              return [];
            }

            const common = {
              type: 'databases' as const,
              id: fieldSpec.id,
              icon: <EmojiIcon size="sm" emoji={emoji} />,
              specId,
              fieldId: fieldSpec.id,
            };

            const getFooter = (title: string, text: string): JSX.Element => (
              <SelectMenuFooter icon={icon} title={title} subtitle={text} />
            );

            return [
              {
                ...common,
                key: `${specId},${fieldSpec.id}`,
                name: `${name} ${fieldSpec.name}`,
                footer: getFooter(
                  `${name} ${fieldSpec.name}`,
                  `The ${fieldSpec.name} property of the ${name} database.`,
                ),
                sectionId: 'databases',
              },
              ...(showThisOptions
                ? [
                    {
                      ...common,
                      key: `this,${specId},${fieldSpec.id}`,
                      name: `This ${pluralize.singular(name)} ${fieldSpec.name}`,
                      footer: getFooter(
                        `This ${pluralize.singular(name)} ${fieldSpec.name}`,
                        `The ${fieldSpec.name} property of a specific instance of the ${name} database.`,
                      ),
                      isThisRef: true,
                      sectionId: 'thisDatabase',
                    },
                  ]
                : []),
            ];
          });

        const objectSpecDriverItems: Array<FormulaReferenceSelectItem & { type: 'databases' }> = (
          objectSpec.collection?.driverProperties ?? []
        ).flatMap((driverProperty) => {
          const driver = dimDriversById[driverProperty.driverId];
          if (driver == null) {
            return [];
          }

          const common = {
            type: 'databases' as const,
            id: driverProperty.id,
            icon,
            specId,
            fieldId: driverProperty.id,
            driver,
          };

          const getFooter = (title: string, text: string): JSX.Element => (
            <SelectMenuFooter icon={icon} title={title} subtitle={text} />
          );

          return [
            {
              ...common,
              key: `${specId},${driver.id}`,
              name: `${name} ${driver.name}`,
              footer: getFooter(
                `${name} ${driver.name}`,
                `The ${driver.name} property of the ${name} database.`,
              ),
              sectionId: 'databases',
            },
            ...(showThisOptions
              ? [
                  {
                    ...common,
                    key: `this,${specId},${driverProperty.id}`,
                    name: `This ${pluralize.singular(name)} ${driver.name}`,
                    footer: getFooter(
                      `This ${pluralize.singular(name)} ${driver.name}`,
                      `The ${driver.name} property of a specific instance of the ${name} database.`,
                    ),
                    isThisRef: true,
                    sectionId: 'thisDatabase',
                  },
                ]
              : []),
          ];
        });

        const items = [
          {
            type: 'databases' as const,
            id: specId,
            key: specId,
            icon,
            specId,
            name,
            footer: (
              <SelectMenuFooter
                icon={icon}
                title={name}
                subtitle={`A reference to all records of the ${name} database.`}
              />
            ),
            sectionId: 'databases',
          },
          ...objectSpecFieldItems,
          ...objectSpecDriverItems,
        ];

        return items;
      });
  }, [
    objectSpecs,
    accessibleDatabases,
    formulaEntitySpecId,
    blockConfigShowRestricted,
    accessCapabilities.canWritePermissions,
    dimDriversById,
  ]);

  const onSelect = useCallback(
    (item: FormulaReferenceSelectItem) => {
      switch (item.type) {
        case 'number': {
          onSelectRawFormulaQuery(item.name);
          break;
        }
        case 'string': {
          onSelectStringLiteral(item.id);
          break;
        }
        case 'math': {
          onSelectMathOperator(item.operator);
          break;
        }
        case 'databases': {
          insertObjectSpec(item.specId, item.fieldId, item.isThisRef ?? false);
          break;
        }
        case 'extDriver': {
          insertExtDriver(item.extDriver.id);
          break;
        }
        case 'driver': {
          insertFormulaEntity(item.driver.id);
          break;
        }
        case 'driverFilter': {
          insertFormulaEntity(item.driver.id, { attributeFilters: item.filters });
          break;
        }
        case 'driverGroup': {
          insertDriverGroup(item.submodelId, item.driverGroupId);
          break;
        }
        case 'variables': {
          if (item.id === 'thisSegment' && segmentByDimDriverId != null) {
            insertThisSegment(segmentByDimDriverId);
          }
          break;
        }
        default: {
          break;
        }
      }
    },
    [
      onSelectRawFormulaQuery,
      onSelectStringLiteral,
      onSelectMathOperator,
      insertObjectSpec,
      insertExtDriver,
      insertFormulaEntity,
      insertDriverGroup,
      insertThisSegment,
      segmentByDimDriverId,
    ],
  );

  const paramLiteralOptions = funcParamStringLiteralOptions(cursorFuncQueryMetadata ?? null);
  const argSectionDisplayName = getFuncArgDisplayName(cursorFuncQueryMetadata ?? null);

  const sectionsById = useMemo(() => {
    if (argSectionDisplayName == null) {
      return BASE_SECTIONS_BY_ID;
    }
    const copy = structuredClone(BASE_SECTIONS_BY_ID);
    copy.arg.name = argSectionDisplayName;
    return copy;
  }, [argSectionDisplayName]);

  const results = useMemo<{
    filteredItems: FormulaReferenceSelectItem[];
    sortedSections: Section[];
  }>(() => {
    const isQueryEmpty = query.length === 0;
    let sortedSections;
    let filteredItems;

    if (isQueryEmpty) {
      const functionsItem = {
        id: 'functions',
        type: 'functions',
        name: 'Functions',
        submenu: () => <FunctionsSelectMenu onSelect={onSelect} />,
      } as const;

      if (recentlyUsedEntities.length === 0) {
        filteredItems = [
          ...paramLiteralOptions,
          ...driverItems,
          ...driverGroupItems,
          ...databaseItems,
          functionsItem,
        ];
        sortedSections = [
          ...(paramLiteralOptions.length > 0 ? [sectionsById.arg] : []),
          ...(formulaEntityId?.type === 'objectFieldSpec'
            ? [{ ...sectionsById.thisDatabase, showMore: false, maxResults: 3 }]
            : []),
          { ...sectionsById.driver, showMore: false, maxResults: 3 },
          { ...sectionsById.driverGroup, showMore: false, maxResults: 3 },
          { ...sectionsById.databases, showMore: false, maxResults: 3 },
        ];
      } else {
        const entityItems = [
          ...paramLiteralOptions,
          ...driverItems,
          ...driverGroupItems,
          ...extDriverItems,
          ...databaseItems,
        ]
          .filter((item) => {
            return accessCapabilities.shouldDenyRoleAccessByAccessEntity(
              accessResourcesById[item.id],
            );
          })
          .map((item) => ({
            ...item,
            sectionId: 'recent',
          }));
        const recentItems = recentlyUsedEntities
          .map((entity) => entityItems.find((item) => isRecentlyUsedEntity(item, entity)))
          .filter(isNotNull);

        filteredItems = [...paramLiteralOptions, ...recentItems, functionsItem];
        sortedSections = [sectionsById.arg, sectionsById.recent];
      }
    } else {
      const isNumericQuery = queryIsNumeric(query);
      const numericOption: (FormulaReferenceSelectItem & { type: 'number' }) | null = isNumericQuery
        ? {
            type: 'number' as const,
            id: query,
            icon: <NumberSignIcon />,
            footer: (
              <SelectMenuFooter
                icon={<NumberSignIcon />}
                title="Number"
                subtitle="Inserts a number."
              />
            ),
            name: query,
            sectionId: 'number',
          }
        : null;

      sortedSections = SECTION_IDS.map((id) => sectionsById[id]);

      filteredItems = [
        numericOption,
        ...paramLiteralOptions,
        ...FUNCTION_ITEMS,
        ...SPECIAL_MATH_ITEMS,
        ...(showThisSegmentOptions ? VARIABLES_ITEMS : []),
        ...driverItems,
        ...driverGroupItems,
        ...extDriverItems,
        ...databaseItems,
      ].filter(isNotNull);
      if (isNumericQuery) {
        // if query is a number, search for number only at the start of the driver name
        filteredItems = filteredItems.filter((item) => item.name.startsWith(query));
      } else if (queryIsObjectQuery(query)) {
        // if query looks like an object query (Something.Field), show objects before other results.
        sortedSections = [
          sectionsById.databases,
          ...SECTION_IDS.filter((id) => id !== 'databases').map((id) => sectionsById[id]),
        ];
      }
    }

    return {
      sortedSections,
      filteredItems: filteredItems.map((i) => ({ ...i, shortcut: 'enter' })),
    };
  }, [
    query,
    recentlyUsedEntities,
    onSelect,
    paramLiteralOptions,
    driverItems,
    driverGroupItems,
    databaseItems,
    sectionsById,
    formulaEntityId?.type,
    extDriverItems,
    accessCapabilities,
    accessResourcesById,
    showThisSegmentOptions,
  ]);

  const dispatch = useAppDispatch();
  const customOptions = useMemo<CustomOption[]>(() => {
    return [
      {
        id: 'customOption',
        icon: <PlusIcon />,
        alwaysShowHint: true,
        shortcutHint: 'metaEnter',
        render: (q: string) => <CustomCreateOption label="Create driver" helperText={q} />,
        errorMessage: (q: string) =>
          checkNameIsUniqueCaseInsensitive(q, allDriverNames) ? null : invalidNameError('driver'),
        footer: (
          <SelectMenuFooter
            icon={<PlusIcon />}
            title="New driver"
            subtitle="Creates a new driver directly below the currently selected driver."
          />
        ),
        onSelect: (newDriverName: string) => {
          if (
            !checkNameIsUniqueCaseInsensitive(newDriverName, allDriverNames) ||
            newDriverName.length === 0
          ) {
            return;
          }

          const id = uuidv4();
          dispatch(
            createNewDriversInContext({
              newDrivers: [
                {
                  id,
                  name: newDriverName,
                },
              ],
              context: {
                belowDriverId: lastCreatedDriverId ?? formulaEntityId?.id,
                blockId,
              },
              select: false,
            }),
          );
          insertFormulaEntity(id, { name: newDriverName });
          setLastCreatedDriverId(id);
        },
      },
    ];
  }, [
    allDriverNames,
    blockId,
    dispatch,
    formulaEntityId,
    insertFormulaEntity,
    lastCreatedDriverId,
    setLastCreatedDriverId,
  ]);

  useKey(
    'Enter',
    (ev) => {
      if (ev.metaKey || ev.ctrlKey || ev.shiftKey) {
        preventEventDefault(ev);
        customOptions[0].onSelect(query);
      }
    },
    // required to prevent conflicts with the formula input
    { options: { capture: true } },
    [customOptions, query],
  );

  const emptyQuery = query.length === 0;

  return (
    <SelectMenu
      items={results.filteredItems}
      onSelect={onSelect}
      query={query}
      searchKeys={SEARCH_KEYS}
      customOptions={canCreateDriver == null || canCreateDriver ? customOptions : undefined}
      clearFocusOnMouseLeave={!emptyQuery}
      sections={results.sortedSections}
      startFocusIdx={emptyQuery || results.filteredItems[0].type === 'number' ? -1 : 0}
      tabToSelect
      width="40rem"
    >
      {({ item, idx, isFocused }) => {
        switch (item.type) {
          case 'number':
          case 'string':
          case 'driverGroup':
          case 'databases':
          case 'math':
          case 'variables': {
            return (
              <BaseSelectMenuItem
                key={`${item.type}-${item.id}`}
                item={item}
                idx={idx}
                isFocused={isFocused}
              />
            );
          }
          case 'functions': {
            return (
              <SelectMenuItem
                name="Functions"
                icon={<FormulaIcon />}
                hasNextMenu
                idx={idx}
                isFocused={isFocused}
              />
            );
          }
          case 'extDriver': {
            return (
              <SelectMenuItem
                key={item.id}
                name={item.name}
                icon={<DataSourceLogo source={item.extDriver.source} size="sm" />}
                idx={idx}
                isFocused={isFocused}
              />
            );
          }
          case 'driverFilter':
            return (
              <DriverSearchResult
                key={item.id}
                driver={item.driver}
                idx={idx}
                isFocused={isFocused}
                filters={item.filters}
              />
            );
          case 'driver': {
            return (
              <DriverSearchResult
                key={item.id}
                driver={item.driver}
                idx={idx}
                isFocused={isFocused}
              />
            );
          }
          default: {
            return null;
          }
        }
      }}
    </SelectMenu>
  );
};

export default FormulaReferenceMenu;
