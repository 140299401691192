import { Box } from '@chakra-ui/react';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';

import { BadgeTheme, ThemeColors, THEMES_TO_COLORS } from 'config/badge';

import styles from './ObjectAttributeBadge.module.scss';

const ObjectAttributeBadge = ({
  label,
  badgeTheme = 'gray',
}: {
  label: string;
  badgeTheme?: BadgeTheme | ThemeColors;
}) => {
  const themeProps = typeof badgeTheme === 'string' ? THEMES_TO_COLORS[badgeTheme] : badgeTheme;

  const shownLabel = formatLabel(label);
  return (
    <Box
      data-sentry-mask
      as="p"
      className={classNames(styles.objectAttributeBadge)}
      {...themeProps}
    >
      {shownLabel}
    </Box>
  );
};

const onlyYear = /^\s*\d+\s*$/;
function formatLabel(label: string): string {
  if (onlyYear.test(label)) {
    return label;
  }

  const date = DateTime.fromISO(label).toJSDate();
  if (isNaN(date.getTime())) {
    return label;
  }

  if (date.getUTCHours() === 0 && date.getMinutes() === 0) {
    return date.toLocaleDateString(window.navigator.language, { dateStyle: 'medium' });
  }

  return date.toLocaleString(window.navigator.language, {
    dateStyle: 'medium',
    timeStyle: 'short',
  });
}

export default React.memo(ObjectAttributeBadge);
