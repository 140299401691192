import * as Sentry from '@sentry/nextjs';
import { uniqWith } from 'lodash';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';

import { ComparisonColumn, DriverFormat } from 'generated/graphql';
import { getValueForColumn } from 'helpers/blockComparisons';
import {
  getMonthColumnKey,
  isColumnKeyEqual,
  isDriverRowKey,
  isMonthColumnKey,
  isRowKeyEqual,
} from 'helpers/cells';
import { getContiguousKeys } from 'helpers/gridKeys';
import { isEqualIgnoringNullish } from 'helpers/object';
import { applyRollupReducer } from 'helpers/rollups';
import { isNotNull } from 'helpers/typescript';
import { TAB } from 'reduxStore/actions/cellCopyPaste';
import { formatValueToString, toNumberValueOrUndefined } from 'reduxStore/models/value';
import { setCopiedCells } from 'reduxStore/reducers/pageSlice';
import { AppThunk } from 'reduxStore/store';
import { selectedBlockCellKeysSelector } from 'selectors/activeCellSelectionSelector';
import { baselineLayerIdForBlockSelector } from 'selectors/baselineLayerSelector';
import { driverTimeSeriesForLayerSelector } from 'selectors/driverTimeSeriesSelector';
import { driverDisplayConfigurationSelector } from 'selectors/entityDisplayConfigurationSelector';
import { driverDataCellSelectionSelector } from 'selectors/prevailingCellSelectionSelector';
import {
  driverRollupReducerSelector,
  timeSeriesColumnsForBlockSelector,
} from 'selectors/rollupSelector';
import { prevailingSelectionBlockIdSelector } from 'selectors/selectionSelector';

export const modelCopyCell =
  ({ setClipboardData }: { setClipboardData: (data: string) => void }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const cellSelection = driverDataCellSelectionSelector(state);
    if (cellSelection == null) {
      return;
    }

    const { selectedCells, activeCell } = cellSelection;
    const activeCellRowKey = activeCell.rowKey;
    const { orderedRowKeys, orderedColumnKeys } = selectedBlockCellKeysSelector(state);

    const selectedRowKeys = uniqBy(
      selectedCells.map((c) => c.rowKey),
      (rk) => rk.driverId,
    );
    const contiguousRows = getContiguousKeys(
      selectedRowKeys.map((key) => ({
        key,
        position: orderedRowKeys
          .filter(isDriverRowKey)
          .findIndex((rk) => rk.driverId === key.driverId),
      })),
      activeCellRowKey,
    );

    const selectedMonthColumnKeys = uniqWith(
      selectedCells.map((c) => c.columnKey),
      isEqualIgnoringNullish,
    );
    const activeCellColumnKey = activeCell.columnKey;
    const orderedMonthColumnKeys = orderedColumnKeys.filter(isMonthColumnKey);
    const contiguousMonthKeys = getContiguousKeys(
      selectedMonthColumnKeys.map((key) => ({
        key,
        position: orderedMonthColumnKeys.findIndex((ck) => isColumnKeyEqual(ck, key)),
      })),
      activeCellColumnKey,
    );

    const isNonContiguousRangeSelected =
      selectedCells.length !== contiguousRows.length * contiguousMonthKeys.length;

    const copiedCells = isNonContiguousRangeSelected ? [activeCell] : selectedCells;
    const orderedSelectedRowKeys = orderBy(
      uniqBy(
        copiedCells.map((c) => c.rowKey),
        (rk) => rk.driverId,
      ),
      (key) => orderedRowKeys.findIndex((rk) => isRowKeyEqual(rk, key)),
    );

    // N.B. this entire piece of logic is a total mess that needs to be refactored
    // our row keys & column keys are not sufficient to infer the actual data that's
    // rendered on screen; ideally both the component and this block would read from the
    // same source, so that there's less duplicated code here
    const blockId = prevailingSelectionBlockIdSelector(state);
    if (blockId == null) {
      Sentry.withScope((scope: Sentry.Scope) => {
        scope.setLevel('warning');
        Sentry.captureMessage('Attempted copy without blockId');
      });
      return;
    }

    const columns = timeSeriesColumnsForBlockSelector(state, blockId);
    const clipboardData = orderedSelectedRowKeys
      .map((rowKey) => {
        const { driverId, layerId: rowLayerId, comparisonType: rowComparisonType } = rowKey;
        if (driverId == null) {
          return undefined;
        }

        const cellsInRow = orderBy(
          copiedCells.filter((ref) => isRowKeyEqual(ref.rowKey, rowKey)),
          (ref) => orderedMonthColumnKeys.findIndex((ck) => isColumnKeyEqual(ck, ref.columnKey)),
        );
        const baselineLayerId = baselineLayerIdForBlockSelector(state, blockId);
        const rollupReducer = driverRollupReducerSelector(state, { id: driverId });
        const baselineVersionTimeSeries = driverTimeSeriesForLayerSelector(state, {
          id: driverId,
          layerId: baselineLayerId,
        });

        const displayConfiguration = driverDisplayConfigurationSelector(state, driverId);

        return cellsInRow
          .map((ref) => {
            const column = columns.find((c) =>
              c.mks.some((mk) =>
                isColumnKeyEqual(getMonthColumnKey(mk, c.rollupType, c.subLabel), ref.columnKey),
              ),
            );
            if (column == null) {
              return undefined;
            }

            const { mks, subLabel } = column;

            const layerId = subLabel?.layerId ?? rowLayerId;
            const comparisonType = subLabel?.column ?? rowComparisonType;

            const timeSeries = driverTimeSeriesForLayerSelector(state, { id: driverId, layerId });
            const reducedRow = applyRollupReducer({
              monthKeys: mks,
              values: timeSeries,
              reducer: rollupReducer,
            });

            const reducedBaseline = applyRollupReducer({
              monthKeys: mks,
              values: baselineVersionTimeSeries,
              reducer: rollupReducer,
            });

            const value = getValueForColumn({
              rowValue: reducedRow.value,
              baselineValue: reducedBaseline.value,
              column: comparisonType,
            });

            const numberValue = toNumberValueOrUndefined(value);
            if (numberValue == null) {
              return undefined;
            }

            const format =
              comparisonType === ComparisonColumn.VariancePercentage
                ? DriverFormat.Percentage
                : displayConfiguration.format;

            return formatValueToString(
              numberValue,
              { ...displayConfiguration, format },
              {
                abbreviate: false,
                includeCents: true,
              },
            );
          })
          .map((value) => value ?? '')
          .join(TAB);
      })
      .filter(isNotNull)
      .join('\n');

    setClipboardData(clipboardData);

    dispatch(setCopiedCells({ blockId: cellSelection.blockId, selectedCells: copiedCells }));
  };
