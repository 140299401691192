import { useCallback } from 'react';

import {
  HARD_CODED_ACTUAL_TOOLTIP,
  IMPACTED_BY_PLAN_TOOLTIP,
} from 'components/AgGridComponents/CellRenderer/constants';
import { cellValueStyle } from 'components/AgGridComponents/CellRenderer/helpers';
import { CellValueStyle } from 'components/AgGridComponents/CellRenderer/types';
import { CellValueTooltipData } from 'config/cells';
import useAppSelector from 'hooks/useAppSelector';
import { useIsActuals } from 'hooks/useIsActuals';
import { BlockId } from 'reduxStore/models/blocks';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { DriverId } from 'reduxStore/models/drivers';
import { LayerId } from 'reduxStore/models/layers';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { entityMonthKeyValueForLayerSelector } from 'selectors/calculationsSelector';
import { driverMonthKeysWithEventImpactSelector } from 'selectors/driverMonthKeysWithEventImpactSelector';
import {
  driverActualsFormulaSelector,
  driverActualsOverrideMonthKeysSelector,
} from 'selectors/driversSelector';
import { objectFieldValueTooltipSelector } from 'selectors/objectFieldValueTooltipSelector';
import { isCalculationError } from 'types/dataset';
import { MonthKey } from 'types/datetime';

const DEFAULT_ERROR_TOOLTIP = 'There was an error computing the value';

const CELL_VALUE_STYLE_LABEL: Record<CellValueStyle, string | undefined> = {
  'actuals-hardcoded': HARD_CODED_ACTUAL_TOOLTIP,
  'actuals-formula': undefined,
  'forecast-impact': IMPACTED_BY_PLAN_TOOLTIP,
  'forecast-formula': undefined,
  error: DEFAULT_ERROR_TOOLTIP,
};

export function useDriverValueTooltip({
  driverId,
  monthKey,
  layerId,
}: {
  driverId: DriverId;
  monthKey: MonthKey;
  layerId: LayerId | undefined;
}): CellValueTooltipData | undefined {
  const isActuals = useIsActuals(monthKey, layerId);
  const hasHardcodedActual = useAppSelector((state) =>
    driverActualsOverrideMonthKeysSelector(state, { id: driverId, layerId }).has(monthKey),
  );
  const driverActualsFormula = useAppSelector((state) =>
    driverActualsFormulaSelector(state, {
      id: driverId,
      layerId,
    }),
  );
  const hasImpact = useAppSelector((state) =>
    driverMonthKeysWithEventImpactSelector(state, { id: driverId, layerId }).has(monthKey),
  );

  // If there is an error with a specific message that we want to display, we
  // should return that here. Otherwise, fall back to the fixed tooltips per
  // value style.

  const monthKeyValue = useAppSelector((state) =>
    entityMonthKeyValueForLayerSelector(state, { id: driverId, layerId, monthKey }),
  );

  if (isCalculationError(monthKeyValue)) {
    return {
      hasUnderline: false,
      content: {
        type: 'driver_calc_error',
        error: monthKeyValue,
        detailDriverId: driverId,
      },
    };
  }

  const valueStyle = cellValueStyle({
    backingType: 'driver',
    isActuals,
    hasHardcodedActual,
    driverHasActualsFormula: driverActualsFormula != null,
    fieldHasIntegrationActuals: false,
    hasImpact,
  });

  const label = CELL_VALUE_STYLE_LABEL[valueStyle];
  if (label == null) {
    return undefined;
  }

  return { hasUnderline: true, content: { type: 'simple_msg', msg: label } };
}

export function useObjectFieldValueTooltip({
  objectId,
  fieldSpecId,
  monthKey,
  blockId,
  layerId,
}: {
  objectId: BusinessObjectId;
  fieldSpecId: BusinessObjectFieldSpecId;
  monthKey: MonthKey;
  blockId: BlockId;
  layerId: LayerId;
}): CellValueTooltipData | undefined {
  const tooltipSelector = useCallback(
    (state: RootState) => {
      return objectFieldValueTooltipSelector(state, {
        objectId,
        fieldSpecId,
        monthKey,
        blockId,
        layerId,
      });
    },
    [objectId, fieldSpecId, monthKey, blockId, layerId],
  );
  return useAppSelector(tooltipSelector);
}
