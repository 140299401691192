import { Code, Flex, IconButton } from '@chakra-ui/react';
import React, { useCallback } from 'react';

import {
  BEIGE,
  DARK_BEIGE,
  DARK_GRAY,
  LIGHT_GRAY,
} from 'components/RunwayApp/GlobalNavigationMenu';
import { MODIFIER_KEY } from 'config/shortcuts';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useHover from 'hooks/useHover';
import { toggleSearchModalOpen } from 'reduxStore/reducers/pageSlice';
import { currentLayerIsDraftSelector } from 'selectors/layerSelector';
import SearchIcon from 'vectors/Search';
interface Props {
  isDisabled?: boolean;
}

const SearchSidebarItem: React.FC<Props> = ({ isDisabled }) => {
  const dispatch = useAppDispatch();
  const [hoverRef, isHovered] = useHover();

  const currentLayerIsDraft = useAppSelector(currentLayerIsDraftSelector);
  const onClick = useCallback(() => {
    dispatch(toggleSearchModalOpen());
  }, [dispatch]);

  return (
    <IconButton
      ref={hoverRef as React.RefObject<HTMLButtonElement>}
      aria-label="Search"
      justifyContent="flex-end"
      borderRadius="4px"
      borderWidth="1px"
      bgColor={currentLayerIsDraft ? BEIGE : LIGHT_GRAY}
      borderColor={currentLayerIsDraft ? DARK_BEIGE : LIGHT_GRAY}
      _hover={
        !isDisabled
          ? {
              borderColor: DARK_GRAY,
            }
          : undefined
      }
      cursor="pointer"
      data-testid="nav-search"
      onClick={!isDisabled ? onClick : undefined}
      px="4px"
      role="group"
      width="150px"
      height="24px"
    >
      <Flex justifyContent="space-between" width="full" alignItems="center">
        <SearchIcon color="gray.600" boxSize="12px" marginX="2px" />
        {isHovered && (
          <Flex gap="1px" marginTop="1px">
            <Code
              noOfLines={1}
              height="14px"
              wordBreak="break-all"
              display="flex"
              p={0}
              alignItems="center"
              marginTop="1px"
              bgColor="inherit"
              color={currentLayerIsDraft ? 'rgb(192, 165, 113)' : DARK_GRAY}
            >
              {MODIFIER_KEY}
            </Code>
            <Code
              noOfLines={1}
              fontSize="xxs"
              height="14px"
              wordBreak="break-all"
              p={0}
              display="flex"
              alignItems="center"
              bgColor="inherit"
              color={currentLayerIsDraft ? 'rgb(192, 165, 113)' : DARK_GRAY}
            >
              K
            </Code>
          </Flex>
        )}
      </Flex>
    </IconButton>
  );
};

export default SearchSidebarItem;
