import React from 'react';

import MultiImpactPlanPicker from 'components/PlanPicker/MultiImpactPlanPicker';
import SingleImpactPlanPicker from 'components/PlanPicker/SingleImpactPlanPicker';
import useAppSelector from 'hooks/useAppSelector';
import { enableStackedImpactsSelector } from 'selectors/launchDarklySelector';

type Props = {
  isEditingActiveCell: boolean;
  className?: string;
};

const PlanPicker = React.forwardRef<HTMLDivElement, Props>(
  ({ isEditingActiveCell, className }, ref) => {
    const enableStackedImpacts = useAppSelector(enableStackedImpactsSelector);

    // TODO: Soon, this also needs to check if the cell has multiple delta impacts
    if (enableStackedImpacts && isEditingActiveCell) {
      return <MultiImpactPlanPicker ref={ref} />;
    }

    return (
      <SingleImpactPlanPicker
        isEditingActiveCell={isEditingActiveCell}
        ref={ref}
        className={className}
      />
    );
  },
);

export default React.memo(PlanPicker);
