import { DEFAULT_DRIVER_FORMAT } from 'config/drivers';
import { ValueType } from 'generated/graphql';
import { AutoFormatCacheSingleton } from 'helpers/formulaEvaluation/DriverFormatResolver/AutoFormatCache';
import { DriverFormatListener } from 'helpers/formulaEvaluation/DriverFormatResolver/DriverFormatListener';
import evaluate from 'helpers/formulaEvaluation/ForecastCalculator/ForecastCalculator';
import {
  BusinessObjectFieldSpec,
  BusinessObjectFieldSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { Driver, DriverFormat, DriverId } from 'reduxStore/models/drivers';
import { RawFormula } from 'types/formula';

class DriverFormatEvaluator {
  getResolvedFormatById(
    id: DriverId | BusinessObjectFieldSpecId,
    // Note: `useActuals` should be technically be part of the cache key, but it doesn't end up mattering
    // because `useActuals` is only true for self-referencial drivers, so whenever the value of `useActuals`
    // changes, it is always accompanied by a formula change, which will cause the cache to be cleared.
    { useActuals = false }: { useActuals?: boolean } = {},
  ): DriverFormat {
    const formatEntity = AutoFormatCacheSingleton.getFormatEntity(id);
    if (formatEntity == null) {
      return DEFAULT_DRIVER_FORMAT;
    }

    let format = formatEntity.format;
    if (format === DriverFormat.Auto) {
      const cachedVal = AutoFormatCacheSingleton.getCachedFormat(id);
      if (cachedVal != null) {
        return cachedVal;
      }

      const formula = useActuals ? formatEntity.actualsFormula : formatEntity.forecastFormula;
      format =
        formula !== '' && formula != null ? resolveFormat(formula, id) : DEFAULT_DRIVER_FORMAT;

      AutoFormatCacheSingleton.setCachedFormat(id, format);
    } else {
      AutoFormatCacheSingleton.clearCachedFormat(id);
    }

    return format;
  }
}

export const DriverFormatEvaluatorSingleton = new DriverFormatEvaluator();

// This should only be used inside of the DriverFormatEvaluator.getResolvedFormatById.
// It is exported for testing purposes.
export function resolveFormat(
  formula: RawFormula,
  entityId: DriverId | BusinessObjectFieldSpecId,
): DriverFormat {
  const listener = new DriverFormatListener({
    entityId,
  });

  try {
    return evaluate(formula, listener);
  } catch {
    return DEFAULT_DRIVER_FORMAT;
  }
}

export const getDriverFormat = (driver: Driver | null | undefined) => {
  if (driver == null) {
    return DEFAULT_DRIVER_FORMAT;
  }

  if (driver.format !== DriverFormat.Auto) {
    return driver.format;
  }

  const { format, id } = driver;

  if (format === DriverFormat.Auto) {
    return DriverFormatEvaluatorSingleton.getResolvedFormatById(id);
  }

  return format;
};

export const getObjectSpecFormat = (fieldSpec: BusinessObjectFieldSpec | undefined) => {
  if (fieldSpec == null || fieldSpec.type !== ValueType.Number) {
    return DEFAULT_DRIVER_FORMAT;
  }

  const { numericFormat, id } = fieldSpec;
  if (numericFormat === DriverFormat.Auto) {
    if (fieldSpec.isFormula) {
      return DriverFormatEvaluatorSingleton.getResolvedFormatById(id);
    }

    return DEFAULT_DRIVER_FORMAT;
  }

  return numericFormat;
};
