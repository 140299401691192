import { createSelector } from 'reselect';

import { CellSelection, ImpactCellRef, ImpactDriverCellRef } from 'config/cells';
import { isImpactCellRef } from 'helpers/cells';
import { Event } from 'reduxStore/models/events';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import {
  eventImpactingDriverAndMonthKeySelector,
  eventImpactingEventEntityAndMonthKeySelector,
} from 'selectors/eventsAndGroupsSelector';
import { pageSelectionSelector } from 'selectors/selectionSelector';
import { MonthKey } from 'types/datetime';

// TODO: the cell selection type narrowing should really live on `pageSlice`
// Also, this doesn't work for field time series cells.
export const selectedTimelineCellSelector = createSelector(
  pageSelectionSelector,
  (selection) =>
    (selection?.type === 'eventsAndGroups' ? selection.cellSelection ?? null : null) as
      | CellSelection<ImpactCellRef>
      | CellSelection<ImpactDriverCellRef>
      | null,
);

export const getSelectedTimelineCellEventsByMonthKey = (
  state: RootState,
): Record<MonthKey, Event> => {
  const cellSelection = selectedTimelineCellSelector(state);

  if (cellSelection == null) {
    return {};
  }

  const eventIdsByMonthKey: Record<MonthKey, Event> = {};

  cellSelection.selectedCells.forEach((cell) => {
    const { columnKey } = cell;
    const { monthKey } = columnKey;
    const event = isImpactCellRef(cell)
      ? eventImpactingEventEntityAndMonthKeySelector(state, { ...cell.rowKey, monthKey })
      : eventImpactingDriverAndMonthKeySelector(state, {
          driverId: cell.rowKey.driverId,
          monthKey,
        });

    if (event == null) {
      return;
    }

    eventIdsByMonthKey[monthKey] = event;
  });

  return eventIdsByMonthKey;
};
