import React, { useCallback } from 'react';

import { EventEntityContextProvider } from '@features/Plans/EventEntity';
import BusinessObjectFieldCell from 'components/BusinessObjectFieldCell/BusinessObjectFieldCell';
import { shouldShowComparisonHighlight } from 'helpers/blockComparisons';
import { nullSafeEqual } from 'helpers/typescript';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { useCellRef } from 'hooks/useCellRefContext';
import { useIsActuals } from 'hooks/useIsActuals';
import { updateBusinessObjectFieldActuals } from 'reduxStore/actions/businessObjectMutations';
import {
  updateEventFromCell,
  updateObjectEventImpactDiffFromCell,
} from 'reduxStore/actions/eventMutations';
import { BlockId } from 'reduxStore/models/blocks';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { EventId } from 'reduxStore/models/events';
import { LayerId } from 'reduxStore/models/layers';
import { Value } from 'reduxStore/models/value';
import { baselineLayerIdForBlockSelector } from 'selectors/baselineLayerSelector';
import { businessObjectFieldForecastTimeSeriesSelector } from 'selectors/businessObjectTimeSeriesSelector';
import { fieldIdForFieldSpecIdAndObjectIdSelector } from 'selectors/businessObjectsSelector';
import { currentLayerIdSelector } from 'selectors/layerSelector';
import { MonthKey } from 'types/datetime';

interface Props {
  businessObjectId: BusinessObjectId;
  fieldSpecId: BusinessObjectFieldSpecId;
  monthKey: MonthKey;
  eventIdToUpdate?: EventId;
  blockId: BlockId;
  onClick?: React.MouseEventHandler;
}

const BusinessObjectFieldTimeSeriesCell: React.FC<Props> = ({
  businessObjectId,
  fieldSpecId,
  monthKey,
  eventIdToUpdate,
  blockId,
}) => {
  const dispatch = useAppDispatch();
  const { cellRef } = useCellRef();
  const baselineLayerId = useAppSelector((state) =>
    baselineLayerIdForBlockSelector(state, blockId),
  );
  const currentLayerId = useAppSelector(currentLayerIdSelector);
  const layerId =
    ('layerId' in cellRef.rowKey ? cellRef.rowKey.layerId : undefined) ?? currentLayerId;

  const rowValue = useAppSelector((state) => {
    return (
      businessObjectFieldForecastTimeSeriesSelector(state, {
        businessObjectId,
        businessObjectFieldSpecId: fieldSpecId,
        blockId,
        layerId,
      })[monthKey] ?? null
    );
  });

  const isActuals = useIsActuals(monthKey);
  const onSave = useCallback(
    (value: Value | undefined) => {
      if (nullSafeEqual(value?.value, rowValue?.value)) {
        return;
      }

      if (isActuals) {
        dispatch(
          updateBusinessObjectFieldActuals([
            {
              fieldSpecId,
              objectId: businessObjectId,
              values: { [monthKey]: value },
            },
          ]),
        );
      } else if (eventIdToUpdate != null) {
        dispatch(updateEventFromCell(eventIdToUpdate, monthKey, value));
      } else if (value != null) {
        dispatch(
          updateObjectEventImpactDiffFromCell({
            businessObjectId,
            businessObjectFieldSpecId: fieldSpecId,
            monthKey,
            value,
          }),
        );
      }
    },
    [businessObjectId, dispatch, eventIdToUpdate, fieldSpecId, isActuals, monthKey, rowValue],
  );

  const objectFieldId = useAppSelector((state) =>
    fieldIdForFieldSpecIdAndObjectIdSelector(state, {
      businessObjectId,
      businessObjectFieldSpecId: fieldSpecId,
    }),
  );

  if (layerId === baselineLayerId) {
    return (
      <EventEntityContextProvider
        type="objectField"
        objectId={businessObjectId}
        fieldSpecId={fieldSpecId}
        objectFieldId={objectFieldId}
      >
        <BusinessObjectFieldCell
          fieldSpecId={fieldSpecId}
          value={rowValue}
          onSave={onSave}
          isActuals={isActuals}
          showComparisonHighlight={false}
        />
      </EventEntityContextProvider>
    );
  }

  return (
    <BusinessObjectFieldTimeSeriesCellWithComparison
      baselineLayerId={baselineLayerId}
      fieldSpecId={fieldSpecId}
      value={rowValue}
      onSave={onSave}
      isActuals={isActuals}
      layerId={layerId}
      rowValue={rowValue}
      monthKey={monthKey}
      businessObjectId={businessObjectId}
    />
  );
};

interface BusinessObjectFieldTimeSeriesCellWithComparisonProps
  extends Omit<React.ComponentProps<typeof BusinessObjectFieldCell>, 'showComparisonHighlight'> {
  baselineLayerId: LayerId;
  layerId: LayerId;
  businessObjectId: BusinessObjectId;
  fieldSpecId: BusinessObjectFieldSpecId;
  monthKey: MonthKey;
  rowValue: Value | undefined;
}

const BusinessObjectFieldTimeSeriesCellWithComparison: React.FC<
  BusinessObjectFieldTimeSeriesCellWithComparisonProps
> = ({
  businessObjectId,
  baselineLayerId,
  rowValue,
  monthKey,
  layerId,
  fieldSpecId,
  onSave,
  isActuals,
}) => {
  const { blockId } = useBlockContext();
  const baselineValue = useAppSelector((state) => {
    return (
      businessObjectFieldForecastTimeSeriesSelector(state, {
        businessObjectId,
        businessObjectFieldSpecId: fieldSpecId,
        blockId,
        layerId: baselineLayerId,
      })[monthKey] ?? null
    );
  });

  const showComparisonHighlight = shouldShowComparisonHighlight({
    rowValue: rowValue?.value,
    baselineValue: baselineValue?.value,
    layerId,
    baselineLayerId,
  });

  return (
    <BusinessObjectFieldCell
      fieldSpecId={fieldSpecId}
      value={rowValue}
      onSave={onSave}
      isActuals={isActuals}
      showComparisonHighlight={showComparisonHighlight}
    />
  );
};

BusinessObjectFieldTimeSeriesCell.displayName = 'BusinessObjectFieldTimeSeriesCell';

export default React.memo(BusinessObjectFieldTimeSeriesCell);
