import { Draft } from '@reduxjs/toolkit';
import keyBy from 'lodash/keyBy';

import {
  MilestoneCreateInput,
  MilestoneDeleteInput,
  MilestoneType,
  MilestoneUpdateInput,
  ThresholdDirection,
} from 'generated/graphql';
import { DatasetSnapshot } from 'reduxStore/models/dataset';
import { LightLayer } from 'reduxStore/models/layers';
import { Milestone } from 'reduxStore/models/milestones';

export function handleCreateMilestone(
  layer: Draft<LightLayer>,
  newMilestoneInput: MilestoneCreateInput,
) {
  const { id, type, driverId, name, value, time, thresholdDirection } = newMilestoneInput;

  const driver = layer.drivers.byId[driverId];

  // This is expected to happen sometimes as you may delete something in the
  // default layer and modify it in another layer. In the future we shouldn't
  // just ignore all existence checks and be more intelligent about when we
  // throw.
  if (driver == null) {
    return;
  }

  const milestone: Milestone = {
    id,
    type: type ?? MilestoneType.ValueWithTime,
    driverId,
    name: name ?? undefined,
    value: Number(value),
    date: time ?? undefined,
    thresholdDirection: thresholdDirection ?? ThresholdDirection.AboveOrEqual,
  };

  layer.milestones.byId[id] = milestone;
  layer.milestones.allIds.push(id);
}

export function handleUpdateMilestone(
  layer: Draft<LightLayer>,
  updateMilestoneInput: MilestoneUpdateInput,
) {
  const { id, type, value, time, thresholdDirection } = updateMilestoneInput;
  const milestone = layer.milestones.byId[id];
  if (milestone == null) {
    return;
  }

  if (type != null) {
    milestone.type = type;
    if (type === MilestoneType.Value) {
      milestone.date = undefined;
    }
  }

  if (value != null) {
    milestone.value = Number(value);
  }

  if (time != null) {
    milestone.date = time;
  }

  if (thresholdDirection != null) {
    milestone.thresholdDirection = thresholdDirection;
  }
}

export function handleDeleteMilestones(layer: Draft<LightLayer>, inputs: MilestoneDeleteInput[]) {
  const idsToDelete = new Set(inputs.map((input) => input.id));
  idsToDelete.forEach((id) => {
    const milestone = layer.milestones.byId[id];
    if (milestone == null) {
      return;
    }

    delete layer.milestones.byId[id];
  });
  layer.milestones.allIds = layer.milestones.allIds.filter((id) => !idsToDelete.has(id));
}

export function setMilestonesFromDatasetSnapshot(
  layer: Draft<LightLayer>,
  dataset: DatasetSnapshot,
) {
  if (dataset == null) {
    layer.milestones = { byId: {}, allIds: [] };
    return;
  }

  const milestones = dataset.milestones.map(
    ({ id, type, name, time, value, driver, thresholdDirection }) => {
      return {
        id,
        type,
        name: name ?? undefined,
        driverId: driver.id,
        value: Number(value),
        date: time != null && type === MilestoneType.ValueWithTime ? time : undefined,
        thresholdDirection,
      };
    },
  );

  layer.milestones = {
    byId: keyBy(milestones, 'id'),
    allIds: milestones.map((e) => e.id),
  };
}
