import { EvaluatorDriver } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import { BlockId } from 'reduxStore/models/blocks';
import {
  BusinessObjectFieldSpec,
  BusinessObjectFieldSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import {
  BusinessObject,
  BusinessObjectFieldId,
  BusinessObjectId,
} from 'reduxStore/models/businessObjects';
import { Attribute } from 'reduxStore/models/dimensions';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import { Driver, DriverId } from 'reduxStore/models/drivers';
import { SubmodelId } from 'reduxStore/models/submodels';

// Wrap all of the necessary getters in a class so that we can return and cache
// this from a redux selector and mock it for testing when necessary.

interface DependencyListenerEvaluatorArgs {
  driversById: Record<DriverId, Driver | undefined>;
  fieldSpecsById: Record<BusinessObjectFieldSpecId, BusinessObjectFieldSpec | undefined>;
  attributesBySubDriverId: Record<DriverId, Attribute[] | undefined>;
  objectsByFieldId: Record<BusinessObjectFieldId, BusinessObject>;
  objectsById: Record<BusinessObjectId, BusinessObject>;
  submodelIdByBlockId: NullableRecord<BlockId, SubmodelId>;
}

export class DependencyListenerEvaluator {
  private driversById: Record<DriverId, Driver | undefined>;
  private driversByGroupId: Record<DriverGroupId, Driver[]>;
  private driversBySubmodelId: Record<SubmodelId, Driver[]>;
  private fieldSpecsById: Record<BusinessObjectFieldSpecId, BusinessObjectFieldSpec | undefined>;
  private attributesBySubDriverId: Record<DriverId, Attribute[] | undefined>;
  private objectsByFieldId: Record<BusinessObjectFieldId, BusinessObject>;
  private objectsById: Record<BusinessObjectId, BusinessObject>;
  private submodelIdByBlockId: NullableRecord<BlockId, SubmodelId>;

  constructor({
    driversById,
    fieldSpecsById,
    attributesBySubDriverId,
    objectsByFieldId,
    objectsById,
    submodelIdByBlockId,
  }: DependencyListenerEvaluatorArgs) {
    this.driversById = driversById ?? {};
    this.fieldSpecsById = fieldSpecsById ?? {};
    this.attributesBySubDriverId = attributesBySubDriverId;
    this.objectsByFieldId = objectsByFieldId ?? {};
    this.objectsById = objectsById ?? {};
    this.submodelIdByBlockId = submodelIdByBlockId ?? {};

    // the number of drivers can be large so just use a plain for loop here to
    // reduce extra overhead of something like wrapping `Object.values` with a
    // `groupBy`
    this.driversByGroupId = {};
    this.driversBySubmodelId = {};
    const addToRecord = (
      record: Record<string, Driver[]>,
      key: string | null | undefined,
      driver: Driver,
    ) => {
      if (key == null) {
        return;
      }
      const drivers = record[key] ?? [];
      drivers.push(driver);
      record[key] = drivers;
    };
    for (const driver of Object.values(driversById)) {
      if (driver == null) {
        continue;
      }
      driver.driverReferences?.forEach((ref) => {
        addToRecord(this.driversByGroupId, ref.groupId, driver);
        addToRecord(this.driversBySubmodelId, this.submodelIdByBlockId[ref.blockId], driver);
      });
    }
  }

  getDriverById(id: DriverId): EvaluatorDriver | undefined {
    return this.driversById[id];
  }

  getDriversByGroupId(id: DriverGroupId): EvaluatorDriver[] {
    return this.driversByGroupId[id] ?? [];
  }

  getDriversBySubmodelId(id: SubmodelId): EvaluatorDriver[] {
    return this.driversBySubmodelId[id] ?? [];
  }

  getFieldSpecById(id: BusinessObjectFieldSpecId): BusinessObjectFieldSpec | undefined {
    return this.fieldSpecsById[id];
  }

  getAttributesByDriverId(id: DriverId): Attribute[] {
    return this.attributesBySubDriverId[id] ?? [];
  }

  getObjectById(id: BusinessObjectId): BusinessObject | undefined {
    return this.objectsById[id];
  }

  getObjectByFieldId(id: BusinessObjectFieldId): BusinessObject | undefined {
    return this.objectsByFieldId[id];
  }
}
