import { DatabaseConfig } from 'generated/graphql';
import { BusinessObjectSpec } from 'reduxStore/models/businessObjectSpecs';
import { BusinessObject } from 'reduxStore/models/businessObjects';
import { EntityTable, ExtEntityTable } from 'reduxStore/models/common';
import { Attribute, NormalizedDimension } from 'reduxStore/models/dimensions';
import { DriverGroup } from 'reduxStore/models/driverGroup';
import { NormalizedDriver } from 'reduxStore/models/drivers';
import { Event, EventGroup } from 'reduxStore/models/events';
import { ExtDriver } from 'reduxStore/models/extDrivers';
import { ExtObjectSpec } from 'reduxStore/models/extObjectSpecs';
import { ExtObject } from 'reduxStore/models/extObjects';
import { ExtQuery } from 'reduxStore/models/extQueries';
import { ExtTable } from 'reduxStore/models/extTables';
import { IntegrationQuery } from 'reduxStore/models/integrationQueries';
import { Milestone } from 'reduxStore/models/milestones';
import { MutationBatch, MutationId } from 'reduxStore/models/mutations';
import { PageData } from 'reduxStore/models/pageData';
import { Submodel } from 'reduxStore/models/submodels';
import { UserId } from 'reduxStore/models/user';
import { ISOTime } from 'types/datetime';

export type LayerId = string;

// TODO: Convert the rest of the object collections in here to the following format:
// https://redux.js.org/usage/structuring-reducers/normalizing-state-shape
export type Layer = {
  id: LayerId;
  name: string;
  description?: string;
  isDraft: boolean;
  lockedMutationId?: MutationId;
  createdByUserId?: UserId;
  createdAt: ISOTime;
  parentLayerId?: LayerId;
  events: EntityTable<Event>;
  eventGroups: EntityTable<EventGroup>;
  drivers: EntityTable<NormalizedDriver>;
  dimensions: EntityTable<NormalizedDimension>;
  attributes: EntityTable<Attribute>;
  milestones: EntityTable<Milestone>;
  businessObjectSpecs: EntityTable<BusinessObjectSpec>;
  businessObjects: EntityTable<BusinessObject>;
  extDrivers: EntityTable<ExtDriver>;
  extObjects: ExtEntityTable<ExtObject>;
  extObjectSpecs: ExtEntityTable<ExtObjectSpec>;
  integrationQueries: EntityTable<IntegrationQuery>;
  extTables: ExtEntityTable<ExtTable>;
  databaseConfigs: EntityTable<DatabaseConfig>;
  extQueries: EntityTable<ExtQuery>;
  submodels: EntityTable<Submodel>;
  driverGroups: EntityTable<DriverGroup>;
  // For the main layer, this has the mutations since page load.
  // For all other layers, this has all mutations ever created on this layer.
  mutationBatches: MutationBatch[];
  pageData: EntityTable<PageData>;
  // To handle commits/deletions of layers and also support reloading layers,
  // we can't actually remove committed/deleted layers. They will not reappear
  // after a page refresh though.
  isDeleted: boolean;
  // Need to identifier of deleted drivers to display in formula errors
  deletedIdentifiers: NullableRecord<string, string>;
  lastActualsTime?: string;

  // internal usage
  eventsByEntityId: NullableRecord<string, Event[]>;
};

export type LightLayer = Omit<
  Layer,
  'extDrivers' | 'extObjects' | 'extObjectSpecs' | 'attributes' | 'dimensions' | 'extTables'
>;

export type DefaultLayer = Layer & { id: typeof DEFAULT_LAYER_ID };

export type LayerMetadata = Pick<
  Layer,
  'id' | 'name' | 'isDraft' | 'isDeleted' | 'lastActualsTime' | 'lockedMutationId'
>;

export const DEFAULT_LAYER_ID = 'default_layer';
export const DEFAULT_LAYER_NAME = 'Main Scenario';
