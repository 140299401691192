import { difference, isEqual, uniq } from 'lodash';

import {
  BlockConfig,
  BlockUpdateInput,
  DatasetMutationInput,
  DriverGroupCreateInput,
  DriverReference,
  DriverUpdateInput,
  SubmodelUpdateInput,
} from 'generated/graphql';
import { computeFreshSortIndexUpdates } from 'helpers/reorderList';
import { isNotNull, nullSafeEqual, safeObjGet } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import { updateBlockConfigMutation } from 'reduxStore/actions/blockMutations';
import {
  DEFAULT_MUTATION_ROUTING,
  MutationAction,
  submitAutoLayerizedMutations,
} from 'reduxStore/actions/submitDatasetMutation';
import { BlockId } from 'reduxStore/models/blocks';
import { DriverGroupId, DriverGroupIdOrNull } from 'reduxStore/models/driverGroup';
import { Driver, DriverId } from 'reduxStore/models/drivers';
import { DEFAULT_LAYER_ID } from 'reduxStore/models/layers';
import { SubmodelId } from 'reduxStore/models/submodels';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { AppDispatch, AppThunk } from 'reduxStore/store';
import { blockDriverIdsSelector, blocksByIdSelector } from 'selectors/blocksSelector';
import { driverGroupsByIdSelector } from 'selectors/driverGroupSelector';
import { driversByIdForLayerSelector } from 'selectors/driversSelector';
import {
  driversForNavSubmodelSelector,
  navSubmodelIdSelector,
} from 'selectors/navSubmodelSelector';
import {
  prevailingCellSelectionBlockIdSelector,
  prevailingSelectedDriverIdsSelector,
} from 'selectors/prevailingCellSelectionSelector';
import {
  blockIdBySubmodelIdSelector,
  submodelIdByBlockIdSelector,
  submodelPageFromSubmodelIdSelector,
} from 'selectors/submodelPageSelector';
import { submodelIdsByDriverIdSelector } from 'selectors/submodelSelector';
import {
  submodelGroupsSelector,
  submodelTableGroupsSelector,
} from 'selectors/submodelTableGroupsSelector';

function areDriverRefsEqual(ref1: DriverReference | undefined, ref2: DriverReference | undefined) {
  return isEqual(
    {
      blockId: ref1?.blockId ?? undefined,
      groupId: ref1?.groupId ?? undefined,
      sortIndex: ref1?.sortIndex ?? undefined,
    },
    {
      blockId: ref2?.blockId ?? undefined,
      groupId: ref2?.groupId ?? undefined,
      sortIndex: ref2?.sortIndex ?? undefined,
    },
  );
}

type UpdateDriverReferencesInput = {
  movingDriverIds: DriverId[];
  sourceBlockId: BlockId | undefined;
  targetBlockId: BlockId | undefined;
  groupId?: DriverGroupId;
  relativeToDriverId?: DriverId;
  position?: 'start' | 'end' | 'before' | 'after';
};
const updateDriverReferencesMutations = (
  state: RootState,
  {
    movingDriverIds,
    sourceBlockId,
    targetBlockId,
    groupId,
    relativeToDriverId,
    position = 'before',
  }: UpdateDriverReferencesInput,
): DriverUpdateInput[] => {
  if (movingDriverIds.length === 0) {
    return [];
  }
  const driversById = driversByIdForLayerSelector(state);

  const targetBlockDriverIds =
    targetBlockId == null ? [] : blockDriverIdsSelector(state, targetBlockId);
  const submodelIdByBlockId = submodelIdByBlockIdSelector(state);
  const unmovingDriverIds = difference(targetBlockDriverIds, movingDriverIds);
  const movingDriverIdsSet = new Set(movingDriverIds);

  // determine sortIndex
  let sortIdxs: NullableRecord<string, number> = {};
  if (movingDriverIds.length > 0) {
    const unmovingDrivers = unmovingDriverIds.map((id) => driversById[id]).filter(isNotNull);
    const movingDrivers = movingDriverIds.map((id) => driversById[id]).filter(isNotNull);

    const insertIndex =
      position === 'start'
        ? 0
        : position === 'end' || relativeToDriverId == null
          ? unmovingDrivers.length
          : unmovingDrivers.findIndex((driver) => driver.id === relativeToDriverId) +
            (position === 'after' ? 1 : 0);
    const orderedDrivers = [
      ...unmovingDrivers.slice(0, insertIndex),
      ...movingDrivers,
      ...unmovingDrivers.slice(insertIndex),
    ].map((d) => ({
      ...d,
      sortIndex: d.driverReferences?.find((ref) => ref.blockId === targetBlockId)?.sortIndex,
    }));

    sortIdxs = computeFreshSortIndexUpdates(orderedDrivers);
  }

  const relativeToDriver = relativeToDriverId != null ? driversById[relativeToDriverId] : undefined;
  const relativeToDriverGroupId = relativeToDriver?.driverReferences?.find(
    (ref) => ref.blockId === targetBlockId,
  )?.groupId;

  // Add driverReferences to the drivers being added/moved
  const updateDrivers: DriverUpdateInput[] = uniq([...unmovingDriverIds, ...movingDriverIds])
    .map((id) => {
      const driver = driversById[id];
      if (driver == null) {
        return null;
      }

      const otherDriverReferences = driver.driverReferences?.filter(
        (ref) => ![sourceBlockId, targetBlockId].includes(ref.blockId),
      );
      let updatedDriverReferences = otherDriverReferences;

      let shouldUpdateDriverReferences = false;
      let shouldUpdateSubmodelId = false;

      // Driver is being removed from source block
      if (sourceBlockId != null && sourceBlockId !== targetBlockId && movingDriverIdsSet.has(id)) {
        const existingSourceRef = driver.driverReferences?.find(
          (ref) => ref.blockId === sourceBlockId,
        );
        if (existingSourceRef != null) {
          shouldUpdateDriverReferences = true;
        }

        // if the driver.submodelId is the submodel we are removing from, assign it to a different value
        const sourceSubmodelId = submodelIdByBlockId[sourceBlockId];
        // eslint-disable-next-line deprecation/deprecation
        if (driver.submodelId != null && driver.submodelId === sourceSubmodelId) {
          shouldUpdateSubmodelId = true;
        }
      }
      if (targetBlockId != null) {
        const existingTargetRef = driver.driverReferences?.find(
          (ref) => ref.blockId === targetBlockId,
        );

        const updatedTargetRef = {
          blockId: targetBlockId,
          sortIndex: sortIdxs[id] ?? existingTargetRef?.sortIndex,
          groupId: movingDriverIdsSet.has(id)
            ? groupId ?? relativeToDriverGroupId
            : existingTargetRef?.groupId,
        };

        updatedDriverReferences = [...(updatedDriverReferences ?? []), updatedTargetRef];

        if (!areDriverRefsEqual(existingTargetRef, updatedTargetRef)) {
          shouldUpdateDriverReferences = true;
        }
      }
      if (!shouldUpdateDriverReferences && !shouldUpdateSubmodelId) {
        return null;
      }

      let newSubmodelId: SubmodelId | undefined;
      let removeSubmodelId = false;
      if (shouldUpdateSubmodelId) {
        for (const { blockId } of updatedDriverReferences ?? []) {
          if (blockId != null) {
            newSubmodelId = submodelIdByBlockId[blockId];
          }
        }
        if (newSubmodelId == null) {
          removeSubmodelId = true;
        }
      }

      return {
        id,
        submodelId: newSubmodelId,
        removeSubmodelId,
        driverReferences: updatedDriverReferences,
        removeAllDriverReferences: (updatedDriverReferences ?? []).length === 0,
      };
    })
    .filter(isNotNull);

  return updateDrivers;
};

const updateDriverReferencesAndBlockConfigMutations = (
  state: RootState,
  {
    movingDriverIds,
    sourceBlockId,
    targetBlockId,
    groupId,
    relativeToDriverId,
    position = 'before',
  }: UpdateDriverReferencesInput,
): { updateDrivers: DriverUpdateInput[]; updateBlocks: BlockUpdateInput[] } => {
  if (movingDriverIds.length === 0) {
    return { updateDrivers: [], updateBlocks: [] };
  }

  const movingDriverIdsSet = new Set(movingDriverIds);

  // Inherit the indentation from the submodel list as the initial indentations
  // Note: the user can change the indentations afterwards and the submodel list and
  // the page driver block can diverge.
  // + clear up any driverIndentations that are associated with the drivers being removed
  const blocksById = blocksByIdSelector(state);
  const submodelIdsByDriverId = submodelIdsByDriverIdSelector(state);

  const updateBlockConfigFunction = (blockId: BlockId) => (bc: BlockConfig) => {
    if (blockId === sourceBlockId && sourceBlockId !== targetBlockId) {
      bc.driverIndentations = [
        ...(bc.driverIndentations ?? []).filter((dI) => !movingDriverIdsSet.has(dI.driverId)),
      ];
    } else {
      const newDriverIndentations = movingDriverIds
        .map((driverId) => {
          const submodelId = submodelIdsByDriverId[driverId]?.find(isNotNull);
          if (submodelId == null) {
            return null;
          }
          const submodelBlockId = submodelPageFromSubmodelIdSelector(state, submodelId)
            ?.blockIds[0];
          if (submodelBlockId == null) {
            return null;
          }
          const indentation = blocksById[submodelBlockId]?.blockConfig?.driverIndentations?.find(
            (driverIndentation) => driverIndentation.driverId === driverId,
          )?.level;
          if (indentation == null) {
            return null;
          }

          return { driverId, level: indentation };
        })
        .filter(isNotNull);

      bc.driverIndentations = [...(bc.driverIndentations ?? []), ...newDriverIndentations];
    }

    // TODO: Uncomment this once the driverReference change is stable
    // We don't want to uncomment this yet in case the changes are reverted, since this is a destructive change
    // bc.idFilter = [];
  };

  const updateBlocks: BlockUpdateInput[] = [];

  if (sourceBlockId != null && sourceBlockId !== targetBlockId) {
    updateBlocks.push(
      ...(updateBlockConfigMutation(state, {
        blockId: sourceBlockId,
        fn: updateBlockConfigFunction(sourceBlockId),
      })?.updateBlocks ?? []),
    );
  }
  if (targetBlockId != null) {
    updateBlocks.push(
      ...(updateBlockConfigMutation(state, {
        blockId: targetBlockId,
        fn: updateBlockConfigFunction(targetBlockId),
      })?.updateBlocks ?? []),
    );
  }

  const updateDrivers = updateDriverReferencesMutations(state, {
    movingDriverIds,
    sourceBlockId,
    targetBlockId,
    groupId,
    relativeToDriverId,
    position,
  });

  return {
    updateDrivers,
    updateBlocks,
  };
};

const updateDriverSubmodelHelper = (
  state: RootState,
  {
    inputDrivers,
    targetSubmodelId,
    sourceBlockId,
  }: {
    inputDrivers: Driver[];
    targetSubmodelId: SubmodelId;
    sourceBlockId: BlockId;
  },
): {
  currentLayerMutations: {
    updateDrivers: DriverUpdateInput[];
    updateSubmodels: SubmodelUpdateInput[];
    newDriverGroups: DriverGroupCreateInput[];
  };
  defaultLayerMutations: {
    updateBlocks: BlockUpdateInput[];
  };
} => {
  const currentLayerMutations: {
    updateSubmodels: SubmodelUpdateInput[];
    updateDrivers: DriverUpdateInput[];
    newDriverGroups: DriverGroupCreateInput[];
  } = {
    updateSubmodels: [],
    updateDrivers: [],
    newDriverGroups: [],
  };
  const defaultLayerMutations: {
    updateBlocks: BlockUpdateInput[];
  } = {
    updateBlocks: [],
  };

  if (inputDrivers.length === 0) {
    return { currentLayerMutations, defaultLayerMutations };
  }
  const blockIdBySubmodelId = blockIdBySubmodelIdSelector(state);
  const targetBlockId = blockIdBySubmodelId[targetSubmodelId];

  if (targetBlockId == null) {
    return { currentLayerMutations, defaultLayerMutations };
  }

  const { groupedDrivers, ungroupedDrivers } = groupDriversByGroupId(inputDrivers, sourceBlockId);

  const targetSubmodelGroups = submodelGroupsSelector(state, { submodelId: targetSubmodelId });

  // For each group of drivers, determine target group and mutations
  [[null, ungroupedDrivers] as const, ...Object.entries(groupedDrivers)].forEach(
    ([sourceGroupId, drivers]) => {
      // We re-use the source group if none of the below conditions are met
      let targetGroupId = sourceGroupId;

      if (sourceGroupId != null) {
        const sourceGroup = driverGroupsByIdSelector(state)[sourceGroupId];
        const matchingTargetGroup = targetSubmodelGroups.find((g) => g.name === sourceGroup.name);

        if (matchingTargetGroup == null) {
          // Transfer driver to new group with same name
          targetGroupId = uuidv4();
          currentLayerMutations.newDriverGroups.push({ id: targetGroupId, name: sourceGroup.name });
        } else if (matchingTargetGroup.id != null && matchingTargetGroup.id !== sourceGroupId) {
          // Transfer driver to existing group in target model
          targetGroupId = matchingTargetGroup.id;
        }
      }
      const driverIds = drivers.map((d) => d.id);
      const blockMutations = updateDriverReferencesAndBlockConfigMutations(state, {
        movingDriverIds: driverIds,
        targetBlockId,
        sourceBlockId,
        groupId: targetGroupId ?? undefined,
      });
      currentLayerMutations.updateDrivers.push(...blockMutations.updateDrivers);
      defaultLayerMutations.updateBlocks.push(...blockMutations.updateBlocks);
    },
  );

  if (currentLayerMutations.newDriverGroups.length > 0) {
    const existingDriverGroupIds = targetSubmodelGroups.map((g) => g.id ?? null);
    const newDriverGroupIds = currentLayerMutations.newDriverGroups.map((g) => g.id);
    currentLayerMutations.updateSubmodels.push({
      id: targetSubmodelId,
      sortedDriverGroupIds: [...existingDriverGroupIds, ...newDriverGroupIds],
    });
  }

  return { currentLayerMutations, defaultLayerMutations };
};

const groupDriversByGroupId = (drivers: Driver[], blockId: BlockId) => {
  const groupedDrivers: Record<DriverGroupId, Driver[]> = {};
  const ungroupedDrivers = [];

  for (const driver of drivers) {
    if (driver == null) {
      continue;
    }
    const groupId = driver.driverReferences?.find((ref) => ref.blockId === blockId)?.groupId;
    if (groupId == null) {
      ungroupedDrivers.push(driver);
      continue;
    }

    if (groupedDrivers[groupId] == null) {
      groupedDrivers[groupId] = [];
    }
    groupedDrivers[groupId].push(driver);
  }

  return {
    groupedDrivers,
    ungroupedDrivers,
  };
};

const submitMutationHelper = (
  action: MutationAction,
  {
    defaultLayerMutations,
    currentLayerMutations,
  }: {
    defaultLayerMutations: DatasetMutationInput;
    currentLayerMutations: DatasetMutationInput;
  },
  dispatch: AppDispatch,
) => {
  dispatch(submitAutoLayerizedMutations(action, [defaultLayerMutations, currentLayerMutations]));
};

export const addDriverReferencesToBlock = ({
  driverIds,
  blockId,
  groupId,
}: {
  driverIds: DriverId[];
  blockId: BlockId;
  groupId?: DriverGroupId;
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const mutations = updateDriverReferencesAndBlockConfigMutations(state, {
      movingDriverIds: driverIds,
      targetBlockId: blockId,
      sourceBlockId: undefined,
      groupId,
    });

    dispatch(submitAutoLayerizedMutations('add-driver-references-to-block', [mutations]));
  };
};

export const removeDriverReferenceFromBlock = ({
  driverId,
  blockId,
}: {
  driverId: DriverId;
  blockId: BlockId;
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const mutations = updateDriverReferencesAndBlockConfigMutations(state, {
      movingDriverIds: [driverId],
      sourceBlockId: blockId,
      targetBlockId: undefined,
    });
    dispatch(submitAutoLayerizedMutations('remove-driver-reference-from-block', [mutations]));
  };
};

export const removeSelectedDriversFromBlock = (): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const driverIds = prevailingSelectedDriverIdsSelector(state);
    const blockId = prevailingCellSelectionBlockIdSelector(state);

    if (blockId == null) {
      return;
    }
    const mutations = updateDriverReferencesAndBlockConfigMutations(state, {
      movingDriverIds: driverIds,
      sourceBlockId: blockId,
      targetBlockId: undefined,
    });

    dispatch(submitAutoLayerizedMutations('remove-drivers-from-block', [mutations]));
  };
};

export const removeDriversFromBlock = ({
  driverIds,
  blockId,
}: {
  driverIds: DriverId[];
  blockId: BlockId;
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const mutations = updateDriverReferencesAndBlockConfigMutations(state, {
      movingDriverIds: driverIds,
      sourceBlockId: blockId,
      targetBlockId: undefined,
    });

    dispatch(submitAutoLayerizedMutations('remove-drivers-from-block', [mutations]));
  };
};

export const clearDriversFromBlock = (blockId: BlockId): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const blockDriverIds = blockDriverIdsSelector(state, blockId);

    const mutations = updateDriverReferencesAndBlockConfigMutations(state, {
      movingDriverIds: blockDriverIds,
      sourceBlockId: blockId,
      targetBlockId: undefined,
    });

    dispatch(submitAutoLayerizedMutations('clear-drivers-from-block', [mutations]));
  };
};

export const updateDriverPositionInBlock = ({
  blockId,
  driverIds,
  groupId,
  relativeToDriverId,
  position,
}: {
  blockId: BlockId;
  driverIds: DriverId[];
  groupId?: DriverGroupId;
  relativeToDriverId?: DriverId;
  position: 'start' | 'end' | 'before' | 'after';
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const driverAndBlockConfigMutations = updateDriverReferencesAndBlockConfigMutations(state, {
      movingDriverIds: driverIds,
      sourceBlockId: blockId,
      targetBlockId: blockId,
      groupId,
      relativeToDriverId,
      position,
    });
    dispatch(
      submitAutoLayerizedMutations('update-driver-position-in-block', [
        driverAndBlockConfigMutations,
      ]),
    );
  };
};

export const updateSelectedDriverPositionInBlock = ({
  blockId,
  relativeToDriverId,
  position,
}: {
  blockId: BlockId;
  relativeToDriverId?: DriverId;
  position: 'before' | 'after';
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const driverIds = prevailingSelectedDriverIdsSelector(state);

    dispatch(
      updateDriverPositionInBlock({
        blockId,
        relativeToDriverId,
        position,
        driverIds,
      }),
    );
  };
};

export const updateDriverSubmodel = ({
  id,
  submodelId,
  sourceBlockId: givenSourceBlockId,
  sourceSubmodelId,
}: {
  id: DriverId;
  submodelId: SubmodelId;
  sourceBlockId?: BlockId;
  sourceSubmodelId?: SubmodelId;
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const driver = driversByIdForLayerSelector(state)[id];
    if (driver == null) {
      return;
    }

    const blockIdBySubmodelId = blockIdBySubmodelIdSelector(state);
    const sourceBlockId = givenSourceBlockId ?? blockIdBySubmodelId[sourceSubmodelId ?? ''];

    if (sourceBlockId == null) {
      return;
    }

    const mutations = updateDriverSubmodelHelper(state, {
      inputDrivers: [driver],
      targetSubmodelId: submodelId,
      sourceBlockId,
    });

    submitMutationHelper('update-driver-submodel', mutations, dispatch);
  };
};

export const addDriverSubmodel =
  ({ id, submodelId }: { id: DriverId; submodelId: SubmodelId }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const driver = driversByIdForLayerSelector(state)[id];
    if (driver == null) {
      return;
    }

    const blockIdBySubmodelId = blockIdBySubmodelIdSelector(state);
    const targetBlockId = blockIdBySubmodelId[submodelId];

    if (targetBlockId == null) {
      return;
    }

    const mutations = updateDriverReferencesAndBlockConfigMutations(state, {
      movingDriverIds: [id],
      sourceBlockId: undefined,
      targetBlockId,
    });

    dispatch(submitAutoLayerizedMutations('add-driver-submodel', [mutations]));
  };

export const removeDriverSubmodel =
  ({ driverIds, submodelId }: { driverIds: DriverId[]; submodelId: SubmodelId }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const driversById = driversByIdForLayerSelector(state);
    const validDriverIds = driverIds.map((id) => driversById[id]?.id).filter(isNotNull);
    if (validDriverIds.length === 0) {
      return;
    }

    const blockIdBySubmodelId = blockIdBySubmodelIdSelector(state);
    const sourceBlockId = blockIdBySubmodelId[submodelId];

    if (sourceBlockId == null) {
      return;
    }

    const mutations = updateDriverReferencesAndBlockConfigMutations(state, {
      movingDriverIds: validDriverIds,
      sourceBlockId,
      targetBlockId: undefined,
    });

    dispatch(submitAutoLayerizedMutations('remove-driver-submodel', [mutations]));
  };

export const updateSubmodelForGroup = ({
  submodelId,
  groupId,
  sourceBlockId,
}: {
  submodelId: SubmodelId;
  groupId: DriverGroupIdOrNull;
  sourceBlockId: BlockId;
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();

    const navSubmodelId = navSubmodelIdSelector(state);
    if (navSubmodelId == null) {
      return;
    }
    const driversInNavSubmodel = driversForNavSubmodelSelector(state);
    const driversInGroup = driversInNavSubmodel.filter((driver) => {
      const driverGroupId = driver.driverReferences?.find(
        (ref) => ref.blockId === sourceBlockId,
      )?.groupId;
      return nullSafeEqual(driverGroupId, groupId);
    });

    const newSubmodelGroupIds = submodelTableGroupsSelector(state)
      .map((group) => group.id ?? null)
      .filter((id) => !nullSafeEqual(id, groupId));

    const mutations = updateDriverSubmodelHelper(state, {
      inputDrivers: driversInGroup,
      targetSubmodelId: submodelId,
      sourceBlockId,
    });

    mutations.currentLayerMutations.updateSubmodels.push({
      id: navSubmodelId,
      sortedDriverGroupIds: newSubmodelGroupIds,
    });

    dispatch(
      submitAutoLayerizedMutations('update-submodel-for-group', [
        mutations.defaultLayerMutations,
        mutations.currentLayerMutations,
      ]),
    );
  };
};

export const updateSelectedDriversSubmodel = ({
  submodelId,
  sourceBlockId,
}: {
  submodelId: SubmodelId;
  sourceBlockId: BlockId;
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const driverIds = prevailingSelectedDriverIdsSelector(state);
    const driversById = driversByIdForLayerSelector(state);
    const driversToUpdate = driverIds
      .map((id) => driversById[id])
      .filter((driver): driver is Driver => {
        return driver != null;
      });

    if (driversToUpdate.length === 0) {
      return;
    }

    const mutations = updateDriverSubmodelHelper(state, {
      inputDrivers: driversToUpdate,
      targetSubmodelId: submodelId,
      sourceBlockId,
    });

    submitMutationHelper('update-selected-drivers-submodel', mutations, dispatch);
  };
};

export const removeSelectedDriversSubmodel = ({
  sourceBlockId,
}: {
  sourceBlockId: BlockId;
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const driverIds = prevailingSelectedDriverIdsSelector(state);
    const driversById = driversByIdForLayerSelector(state);
    const driversToUpdate = driverIds
      .map((id) => driversById[id])
      .filter((driver): driver is Driver => {
        return driver != null;
      });

    if (driversToUpdate.length === 0) {
      return;
    }
    const submodelIdByBlockId = submodelIdByBlockIdSelector(state);
    const submodelId = submodelIdByBlockId[sourceBlockId];

    if (submodelId == null) {
      return;
    }

    dispatch(
      removeDriverSubmodel({
        driverIds,
        submodelId,
      }),
    );
  };
};

export const blockDuplicateDriverReferenceMutations = (
  state: RootState,
  // blockId => new blockId for the duplicated block
  blockIdMap: Record<BlockId, BlockId>,
): DriverUpdateInput[] => {
  const driversById = driversByIdForLayerSelector(state);
  const driverUpdates: Record<DriverId, DriverUpdateInput> = {};

  Object.entries(blockIdMap).forEach(([blockId, newBlockId]) => {
    const blockDriverIds = blockDriverIdsSelector(state, blockId);
    blockDriverIds.forEach((id) => {
      const driver = driversById[id];
      const existingDriverReference = driver?.driverReferences?.find((r) => r.blockId === blockId);
      if (driver == null || driver.driverReferences == null || existingDriverReference == null) {
        return;
      }
      //
      driverUpdates[id] = {
        id: driver.id,
        driverReferences: [
          ...(safeObjGet(driverUpdates[id])?.driverReferences ?? driver.driverReferences),
          { ...existingDriverReference, blockId: newBlockId },
        ],
      };
    });
  });

  return Object.values(driverUpdates);
};

export const blockDeletionDriverReferenceMutations = (
  state: RootState,
  blockIds: BlockId[],
): { updateDrivers: DriverUpdateInput[] } => {
  if (blockIds.length === 0) {
    return { updateDrivers: [] };
  }
  /**
   *  Block deletion is typically scoped to the default layer and there is code to sync the layer used for deleteBlocks and updateDrivers mutation types. See deleteBlock thunk in blockMutations.ts.
   *
   *  However, driversByIdForLayerSelector without layerId might pick up drivers that don't exist in the default layer causing an update to a driver that doesn't exist in the default layer.
   *  This happens when you are deleting a block in a draft layer.
   *
   *  With the logic below, it is possible for drivers in non-default layers to have dangling references to deleted blocks.
   */
  const scopeToDefaultLayer = DEFAULT_MUTATION_ROUTING.deleteBlocks;
  const layerId = scopeToDefaultLayer ? DEFAULT_LAYER_ID : undefined;
  const driversById = driversByIdForLayerSelector(state, { layerId });
  const blockIdsSet = new Set(blockIds);
  return {
    updateDrivers: Object.entries(driversById)
      .filter(([_, driver]) => {
        return driver?.driverReferences?.some((ref) => blockIdsSet.has(ref.blockId));
      })
      .map(([id, driver]) => {
        return {
          id,
          driverReferences: driver?.driverReferences?.filter(
            (ref) => !blockIdsSet.has(ref.blockId),
          ),
        };
      }),
  };
};
