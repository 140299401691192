import { Box, Flex, useBoolean } from '@chakra-ui/react';
import Tippy from '@tippyjs/react';
import classNames from 'classnames';
import {
  DraftHandleValue,
  DraftStyleMap,
  Editor,
  EditorState,
  KeyBindingUtil,
  Modifier,
  convertFromRaw,
  getDefaultKeyBinding,
} from 'draft-js';
import { handleDraftEditorPastedText, registerCopySource } from 'draftjs-conductor';
import { isEmpty, isString } from 'lodash';
import last from 'lodash/last';
import React, {
  SyntheticEvent,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isSafari } from 'react-device-detect';
import { useHotkeys } from 'react-hotkeys-hook';
import { useEffectOnce } from 'react-use';

import { shouldOpenPlanPicker } from '@features/Plans';
import { Tooltip } from 'chakra/tooltip';
import FormulaDropdown from 'components/FormulaDropdown/FormulaDropdown';
import FormulaDropdownContext from 'components/FormulaInput/FormulaDropdownContext';
import { InheritedFormulaPill } from 'components/FormulaInput/FormulaInputInheritedtFormulaPill';
import FormulaSelectionContext from 'components/FormulaInput/FormulaSelectionContext';
import FormulaTooltipContents from 'components/FormulaInput/FormulaTooltipContents';
import { FORMULA_EDITOR_CLASS, MATH_OPERATOR_TO_LABEL } from 'config/formula';
import { NUMERIC_FONT_SETTINGS } from 'config/styles';
import theme from 'config/theme';
import colors from 'config/theme/foundations/colors';
import 'draft-js/dist/Draft.css';
import { isInputKeyboardEvent } from 'helpers/browserEvent';
import { FormulaCopyData, parseSingleFormulaFromClipboardJSON } from 'helpers/clipboard';
import { attributeFilterForSubDriver, getSubDriverBySubDriverId } from 'helpers/dimensionalDrivers';
import {
  addErrorHighlightingToContent,
  addWhitespace,
  compositeDecorator,
  deleteWordRange,
  displayToDraftJS,
  draftContentStateToRawFormula,
  findDifferenceIndex,
  findReferenceEntity,
  getCursorFuncQueryMetadata,
  getEntityAtIndex,
  getEntityKeysInSelection,
  getEntityRange,
  getObjectRefLabel,
  getQuery,
  getSelectionState,
  parseFunctions,
  preventQueryDropdown,
  removeHighlightingFromQuery,
  showEmptyQueryDropdown,
  updateErrorHighlighting,
} from 'helpers/draftEditor';
import {
  THIS_MONTH_DATE_RANGE,
  defaultDateRange,
  getFormulaDateRangeDisplay,
} from 'helpers/formula';
import { FormulaDisplay } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaDisplayListener';
import { FormulaEntityTypedId } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import { isValidJSON } from 'helpers/json';
import { isPrintableChar } from 'helpers/string';
import { safeObjGet } from 'helpers/typescript';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useAppStore from 'hooks/useAppStore';
import useBlockContext from 'hooks/useBlockContext';
import { handleCommandEnter } from 'reduxStore/actions/cellNavigation';
import {
  getDriverFormulaCopyData,
  getFieldFormulaCopyData,
  getFormulaFromFormulaCopyData,
} from 'reduxStore/actions/formulaCopyPaste';
import { BlockId } from 'reduxStore/models/blocks';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import { DriverId, DriverType, FormulaSourceType } from 'reduxStore/models/drivers';
import { ExtDriverId } from 'reduxStore/models/extDrivers';
import { SubmodelId } from 'reduxStore/models/submodels';
import {
  addRecentEntities,
  clearFormulaEntityReference,
  setCanInsertFormulaEntityReference,
} from 'reduxStore/reducers/formulaInputSlice';
import { businessObjectFieldSpecByIdSelector } from 'selectors/businessObjectFieldSpecsSelector';
import { businessObjectSpecsByIdForLayerSelector } from 'selectors/businessObjectSpecsSelector';
import { businessObjectsByFieldIdForLayerSelector } from 'selectors/businessObjectsSelector';
import { databaseFormulaPropertiesByIdSelector } from 'selectors/collectionSelector';
import { driverGroupsByIdSelector } from 'selectors/driverGroupSelector';
import {
  attributesBySubDriverIdSelector,
  dimensionalDriversBySubDriverIdSelector,
  driverFormulaSelector,
  driverNamesByIdSelector,
  driversByIdForLayerSelector,
} from 'selectors/driversSelector';
import { extDriversByIdSelector } from 'selectors/extDriversSelector';
import { getFormulaDisplayForFormula } from 'selectors/formulaDisplaySelector';
import { formulaEvaluatorForLayerSelector } from 'selectors/formulaEvaluatorSelector';
import { insertedFormulaEntityReferenceSelector } from 'selectors/formulaInputSelector';
import {
  submodelIdByBlockIdSelector,
  submodelNamesByIdSelector,
} from 'selectors/submodelPageSelector';
import { FilterItem, filterIsComplete } from 'types/filtering';
import {
  ANY_ATTR,
  AtomicNumberMetadata,
  AttributeFilters,
  DriverRefMetadata,
  EntityData,
  EntityDataWithKey,
  EntityType,
  ExtDriverRefMetadata,
  ExtQueryRefMetadata,
  FormulaTimeRange,
  MathOperator,
  ObjectSpecRefMetadata,
  RawFormula,
  SubmodelRefMetadata,
  ThisSegmentMetadata,
} from 'types/formula';

const FORMULA_DROPDOWN_OFFSET: [number, number] = [0, 0];

const draftCustomStyleMap: DraftStyleMap = {
  RED: {
    color: colors.red[600],
  },
};

enum FormulaCommands {
  Submit = 'Submit',
  Cancel = 'Cancel',
  NextEntity = 'NextEntity',
  PreviousEntity = 'PreviousEntity',
  DeleteWord = 'DeleteWord',
}

export type OnChangeFormulaArgs = {
  isActuals: boolean;
  newFormula: RawFormula | null;
  matchesOrig: boolean;
  lastKeyboardEvent: React.KeyboardEvent | null;
};

export interface FormulaInputProps {
  // There isn't always going to be an associated entity to the FormulaInput.
  // For example, we use the FormulaInput to enter Plans for timeseries cells.
  formulaEntityId?: FormulaEntityTypedId;
  isActuals: boolean;
  formulaDisplay: FormulaDisplay | null;
  formulaError?: string;
  isFormulaSet?: boolean;
  inheritableFormulaDisplay?: FormulaDisplay | null;
  inheritableFormulaSourceDriverId?: DriverId;
  inheritableFormulaSourceType?: FormulaSourceType;
  onCancel: () => void;
  onChange?: (props: OnChangeFormulaArgs) => void;
  onSave: (props: OnChangeFormulaArgs) => void;
  onBlur?: (event: SyntheticEvent, props: OnChangeFormulaArgs) => void;
  isActive?: boolean;
  onActive?: () => void;
  canCreateDriver?: boolean;
  showBorder?: boolean;
  justifyContent?: string;
  minWidth?: string;
  width?: string;
  disableDropdown?: boolean;
}

export type EditorRef = {
  focus: () => void;
  save: () => void;
};

const FormulaInput = forwardRef<EditorRef | null, FormulaInputProps>(
  (
    {
      formulaEntityId,
      isActuals,
      formulaDisplay: passedInFormulaDisplay,
      isFormulaSet = true,
      inheritableFormulaDisplay,
      inheritableFormulaSourceDriverId,
      inheritableFormulaSourceType,
      formulaError,
      onChange,
      onSave,
      onCancel,
      onBlur: onBlurGiven,
      isActive = true,
      onActive,
      canCreateDriver,
      showBorder,
      justifyContent,
      minWidth = '30rem',
      width = 'max-content',
      disableDropdown = false,
    },
    ref,
  ) => {
    const dispatch = useAppDispatch();
    const editorRef = useRef<Editor | null>(null);

    const evaluator = useAppSelector(formulaEvaluatorForLayerSelector);
    const driversById = useAppSelector(driversByIdForLayerSelector);
    const extDriversById = useAppSelector(extDriversByIdSelector);
    const objectSpecsById = useAppSelector(businessObjectSpecsByIdForLayerSelector);
    const objectFieldSpecById = useAppSelector(businessObjectFieldSpecByIdSelector);
    const databaseFormulaPropertiesById = useAppSelector(databaseFormulaPropertiesByIdSelector);
    const attributesBySubDriverId = useAppSelector(attributesBySubDriverIdSelector);
    const objectsByFieldId = useAppSelector(businessObjectsByFieldIdForLayerSelector);
    const objectsById = useAppSelector(businessObjectsByFieldIdForLayerSelector);
    const submodelIdByBlockId = useAppSelector(submodelIdByBlockIdSelector);
    const dimensionalDriversBySubdriverId = useAppSelector(dimensionalDriversBySubDriverIdSelector);
    const driverNamesById = useAppSelector(driverNamesByIdSelector);

    const originalComputedFormula = useRef<string | null>(null);

    // no querying until the user makes an explicit change to the formula input
    const [isQueryCleared, setIsQueryCleared] = useBoolean(true);

    const formulaDisplay = isFormulaSet ? passedInFormulaDisplay : null;
    const [editorState, setEditorState] = useState<EditorState>(() => {
      if (formulaDisplay == null) {
        return EditorState.createEmpty(compositeDecorator);
      }

      const initState = EditorState.createWithContent(
        convertFromRaw(displayToDraftJS(databaseFormulaPropertiesById, formulaDisplay)),
        compositeDecorator,
      );

      return initState;
    });

    const editorContent = editorState.getCurrentContent();
    const editorBlock = editorContent.getFirstBlock();
    const selection = editorState.getSelection();
    const currentContentPlainText = editorContent.getPlainText();
    const showInheritableFormula =
      inheritableFormulaDisplay != null && currentContentPlainText === '';
    const rawFormula = draftContentStateToRawFormula(editorContent, driversById);

    const [activeEntity, setActiveEntity] = useState<EntityDataWithKey | null>(null);
    // This ref is used in callbacks which do not need to respond to state changes.
    const activeEntityRef = useRef<EntityDataWithKey | null>(activeEntity);
    activeEntityRef.current = activeEntity;
    const [defaultSubmenu, setDefaultSubmenu] = useState<string | null>(null);
    const activeEntityKey = activeEntity?.key;

    // When there's an entity at the beginning or end of a formula, we add a corresponding leading/trailing
    // space. Things like focusing the input and commands like 'move-selection-to-start-of-block' +
    // 'move-selection-to-end-of-block' for some reason do not work without them. We then hide these
    // spaces when they exist and there exists an entity before/after the space. For example:
    // ` [entity] ` -> `[entity]` while `1 + [entity] + 1` -> `1 + [entity] + 1`
    const collapseLeadingSpace = rawFormula?.startsWith(' ') && editorBlock.getEntityAt(1) != null;
    const collapseTrailingSpace =
      rawFormula?.endsWith(' ') && editorBlock.getEntityAt(editorBlock.getLength() - 2) != null;

    // If the cursor is in a function, we may not want to include certain things
    // in a query. For instance, the `unit` string literal at the end of
    // `dateDiff` shouldn't prompt creating a driver and should suggest
    // particular string literals.
    const parsedFuncs = useMemo(
      () => parseFunctions(currentContentPlainText),
      [currentContentPlainText],
    );

    const resetSelectionState = useCallback(
      (position: 'start' | 'end' = 'end') => {
        const currentActiveEntity = activeEntityRef.current;
        if (currentActiveEntity?.key == null) {
          return;
        }

        const [entityStart, entityEnd] = getEntityRange(editorBlock, currentActiveEntity.key);
        const cursorPosition = position === 'start' ? entityStart : entityEnd;
        const selectionState = getSelectionState(editorBlock.getKey(), cursorPosition);
        setActiveEntity(null);
        setDefaultSubmenu(null);
        // wait for the component to no longer be readOnly, then update focus. This is needed when we close the menu
        window.requestAnimationFrame(() => {
          setEditorState((e) => EditorState.forceSelection(e, selectionState));
        });
      },
      [editorBlock],
    );

    const hasError = formulaError != null;
    const [editorQuery, queryIndex] = getQuery(editorState, parsedFuncs);
    const query = isQueryCleared ? '' : editorQuery;
    const hasSelectedEntity = activeEntity != null;

    const onSelectInheritedFormula = useCallback(
      (event?: React.MouseEvent) => {
        if (inheritableFormulaDisplay == null) {
          return;
        }

        event?.stopPropagation();

        const updatedState = EditorState.createWithContent(
          convertFromRaw(
            displayToDraftJS(databaseFormulaPropertiesById, inheritableFormulaDisplay),
          ),
          compositeDecorator,
        );
        setEditorState(updatedState);
        const updatedContent = updatedState.getCurrentContent();
        const length = updatedContent.getPlainText().length;
        const block = updatedContent.getFirstBlock();
        const selectionState = getSelectionState(block.getKey(), length);

        window.requestAnimationFrame(() => {
          setEditorState((e) => EditorState.forceSelection(e, selectionState));
        });
      },
      [databaseFormulaPropertiesById, inheritableFormulaDisplay],
    );

    // Manages keyboard input while you have an entity selected.
    useEffect(() => {
      if (activeEntityKey == null) {
        return () => {};
      }

      const activeEntityKeydownHandler = (ev: KeyboardEvent) => {
        if (isInputKeyboardEvent(ev) || ev.defaultPrevented) {
          return;
        }

        ev.preventDefault();
        ev.stopPropagation();
        const { key } = ev;
        switch (key) {
          // navigation
          case 'ArrowLeft':
          case 'ArrowRight':
          case 'Escape': {
            resetSelectionState(key === 'ArrowLeft' ? 'start' : 'end');
            break;
          }
          // deletion, or typing over an active entity
          default: {
            const newKey = isPrintableChar(ev.key) ? ev.key : null;
            if (newKey == null && ev.key !== 'Delete' && ev.key !== 'Backspace') {
              return;
            }

            setActiveEntity(null);
            setDefaultSubmenu(null);
            setEditorState((e) => {
              const block = e.getCurrentContent().getFirstBlock();
              const [entityStart, entityEnd] = getEntityRange(block, activeEntityKey);
              const blockKey = block.getKey();
              const entitySelection = getSelectionState(blockKey, entityStart, entityEnd);
              const newContentState =
                newKey != null
                  ? Modifier.replaceText(editorContent, entitySelection, newKey)
                  : Modifier.removeRange(editorContent, entitySelection, 'backward');
              const newEditorState = EditorState.push(
                e,
                newKey == null && formulaEntityId != null
                  ? addErrorHighlightingToContent({
                      content: newContentState,
                      driversById,
                      fieldSpecsById: objectFieldSpecById,
                      attributesBySubDriverId,
                      objectsByFieldId,
                      objectsById,
                      submodelIdByBlockId,
                      errorEvaluatorProps: {
                        formulaEntityId,
                        isActualsFormula: isActuals,
                        evaluator,
                      },
                    })
                  : newContentState,
                'remove-range',
              );

              const anchor = newKey != null ? entityStart + newKey.length : entityStart;
              return EditorState.forceSelection(
                newEditorState,
                getSelectionState(blockKey, anchor),
              );
            });
            break;
          }
        }
      };

      window.addEventListener('keydown', activeEntityKeydownHandler);
      return () => {
        window.removeEventListener('keydown', activeEntityKeydownHandler);
      };
    }, [
      activeEntityKey,
      driversById,
      objectFieldSpecById,
      attributesBySubDriverId,
      objectsByFieldId,
      objectsById,
      setActiveEntity,
      editorBlock,
      editorContent,
      resetSelectionState,
      formulaEntityId,
      isActuals,
      evaluator,
      submodelIdByBlockId,
    ]);

    useHotkeys(
      'Escape',
      () => {
        if (isActive && !hasSelectedEntity) {
          onCancel();
        }
      },
      undefined,
      [onCancel, hasSelectedEntity, isActive],
    );

    const lastKeyboardEvent = useRef<React.KeyboardEvent | null>(null);

    // N.B. this & handleKeyCommand only get used when the editor is not readOnly
    const keyBindingFn = useCallback(
      (ev: React.KeyboardEvent) => {
        lastKeyboardEvent.current = ev;
        // Anytime the user types, allow them to insert a formula entity reference.
        // Doesn't need to be valid, we can just check at the end.
        dispatch(setCanInsertFormulaEntityReference(true));

        const { key } = ev;

        if (KeyBindingUtil.isCtrlKeyCommand(ev)) {
          if (key === 'a') {
            return 'move-selection-to-start-of-block';
          } else if (key === 'e') {
            return 'move-selection-to-end-of-block';
          }
        }

        const hasMetaModifier =
          KeyBindingUtil.hasCommandModifier(ev) || KeyBindingUtil.isCtrlKeyCommand(ev);

        switch (key) {
          case 'Tab':
          case 'Enter': {
            // DraftJS intercepts global hot keys, so we need to manually handle them here
            if (key === 'Tab' && showInheritableFormula) {
              ev.preventDefault();
              ev.stopPropagation();
              return 'select-inherited-formula';
            }
            if (shouldOpenPlanPicker(ev.nativeEvent)) {
              return 'cmd-enter';
            }
            ev.preventDefault();
            ev.stopPropagation();
            setIsQueryCleared.off();
            return FormulaCommands.Submit;
          }
          case 'Escape': {
            ev.stopPropagation();
            return FormulaCommands.Cancel;
          }
          case 'ArrowRight':
          case 'ArrowLeft': {
            if (key === 'ArrowRight' && showInheritableFormula) {
              ev.preventDefault();
              ev.stopPropagation();
              return 'select-inherited-formula';
            }
            if (
              !hasMetaModifier &&
              !KeyBindingUtil.isOptionKeyCommand(ev) &&
              selection.isCollapsed()
            ) {
              const lookForward = key === 'ArrowRight';
              const index = lookForward
                ? selection.getStartOffset()
                : selection.getStartOffset() - 1;

              // Since we sometimes add an invisible leading/trailing whitespace,
              // we need to manually prevent the user from keying into that space.
              const cannotNavigateLeft = index <= 0 && collapseLeadingSpace;
              const cannotNavigateRight =
                index >= editorBlock.getLength() - 1 && collapseTrailingSpace;
              if (
                (key === 'ArrowRight' && cannotNavigateRight) ||
                (key === 'ArrowLeft' && cannotNavigateLeft)
              ) {
                ev.preventDefault();
                ev.stopPropagation();
                return null;
              }

              const refEntity = getEntityAtIndex(editorContent, index, true);
              if (refEntity) {
                ev.stopPropagation();
                return lookForward ? FormulaCommands.NextEntity : FormulaCommands.PreviousEntity;
              }
            }
            return getDefaultKeyBinding(ev);
          }
          case 'Backspace': {
            if (!ev.altKey) {
              return getDefaultKeyBinding(ev);
            }
            return FormulaCommands.DeleteWord;
          }
          default: {
            return getDefaultKeyBinding(ev);
          }
        }
      },
      [
        dispatch,
        setIsQueryCleared,
        showInheritableFormula,
        selection,
        collapseLeadingSpace,
        editorBlock,
        collapseTrailingSpace,
        editorContent,
      ],
    );

    const getOnChangePayload = useCallback(
      () => ({
        isActuals,
        newFormula: rawFormula,
        matchesOrig: rawFormula === originalComputedFormula.current,
        lastKeyboardEvent: lastKeyboardEvent.current,
      }),
      [isActuals, rawFormula],
    );

    const handleSave = useCallback(() => {
      onSave(getOnChangePayload());
    }, [getOnChangePayload, onSave]);

    /**
     * We're not always guaranteed to be highlighting an error while the user is editing a formula.
     * This function can be used to force the error highlighting to be up to date; this is useful
     * when showing an error to the user.
     */
    const forceUpdateErrorHighlighting = useCallback(() => {
      if (formulaEntityId == null) {
        return;
      }

      setEditorState((e) => {
        return updateErrorHighlighting({
          editorState: e,
          driversById,
          fieldSpecsById: objectFieldSpecById,
          attributesBySubDriverId,
          objectsByFieldId,
          objectsById,
          submodelIdByBlockId,
          errorEvaluatorProps: {
            formulaEntityId,
            isActualsFormula: isActuals,
            evaluator,
          },
        });
      });
    }, [
      driversById,
      objectFieldSpecById,
      attributesBySubDriverId,
      objectsByFieldId,
      objectsById,
      submodelIdByBlockId,
      formulaEntityId,
      isActuals,
      evaluator,
    ]);

    const handleKeyCommand = useCallback(
      (command: string): DraftHandleValue => {
        switch (command) {
          case 'cmd-enter': {
            dispatch(handleCommandEnter({}));
            return 'handled';
          }
          case 'select-inherited-formula': {
            onSelectInheritedFormula();
            return 'handled';
          }
          case FormulaCommands.Submit: {
            handleSave();
            return 'handled';
          }
          case FormulaCommands.Cancel: {
            onCancel();
            return 'handled';
          }
          case FormulaCommands.NextEntity: {
            const entity = findReferenceEntity(
              editorContent,
              selection.getStartOffset(),
              'forward',
            );
            if (entity) {
              setActiveEntity(entity);
            }
            return 'handled';
          }
          case FormulaCommands.PreviousEntity: {
            const entity = findReferenceEntity(
              editorContent,
              selection.getStartOffset(),
              'backward',
            );
            if (entity) {
              setActiveEntity(entity);
            }
            return 'handled';
          }
          case FormulaCommands.DeleteWord: {
            const [start, end] = deleteWordRange(editorContent, selection.getStartOffset());
            const currentBlock = editorContent.getFirstBlock();
            const blockKey = currentBlock.getKey();
            const newContent = Modifier.replaceText(
              editorContent,
              getSelectionState(blockKey, start, end),
              '',
              undefined,
              undefined,
            );
            setEditorState((e) => {
              const newState = EditorState.push(e, newContent, 'delete-character');
              return EditorState.forceSelection(
                newState,
                getSelectionState(blockKey, Math.min(newContent.getPlainText().length, start)),
              );
            });
            return 'handled';
          }
          default:
            return 'not-handled';
        }
      },
      [dispatch, handleSave, onCancel, editorContent, selection, onSelectInheritedFormula],
    );

    const onSelectMathOperator = useCallback(
      (operator: MathOperator) => {
        setEditorState((e) => {
          const queryArray = getQuery(e, parsedFuncs);
          const queryLength = queryArray[0].length;
          const offset = queryArray[1];
          const content = e.getCurrentContent();
          const currentBlock = content.getFirstBlock();
          const blockKey = currentBlock.getKey();

          const isParenAfterQuery = content.getPlainText().charAt(offset + queryLength) === '(';
          const [operatorLabel, operatorLabelSuffix] = MATH_OPERATOR_TO_LABEL[operator];

          const querySelection = getSelectionState(blockKey, offset, offset + queryLength);
          const newContent = Modifier.replaceText(
            content,
            querySelection,
            isParenAfterQuery ? operator : operatorLabel + operatorLabelSuffix,
            undefined,
            undefined,
          );

          const afterParenSelection = getSelectionState(blockKey, offset + operatorLabel.length);

          const newEditorState = EditorState.push(e, newContent, 'insert-characters');
          return EditorState.forceSelection(newEditorState, afterParenSelection);
        });
      },
      [parsedFuncs],
    );

    const onSelectStringLiteral = useCallback(
      (literal: string) => {
        setEditorState((e) => {
          const wrappedLiteral = `'${literal}'`;

          const queryArray = getQuery(e, parsedFuncs);
          const queryLength = queryArray[0].length;
          const offset = queryArray[1];
          const content = e.getCurrentContent();
          const currentBlock = content.getFirstBlock();
          const blockKey = currentBlock.getKey();

          const querySelection = getSelectionState(blockKey, offset, offset + queryLength);
          const newContent = Modifier.replaceText(
            content,
            querySelection,
            wrappedLiteral,
            undefined,
            undefined,
          );

          const newSelection = getSelectionState(blockKey, offset + wrappedLiteral.length);

          const newEditorState = EditorState.push(e, newContent, 'insert-characters');
          return EditorState.forceSelection(newEditorState, newSelection);
        });
      },
      [parsedFuncs],
    );

    const insertEntity = useCallback(
      (
        entityData: EntityData,
        {
          select,
          replaceEntityKey,
          insertAtEnd = false,
        }: { select: boolean; replaceEntityKey?: string; insertAtEnd?: boolean },
      ) => {
        if (formulaEntityId == null) {
          return;
        }
        const { type, data } = entityData;
        const queryArray = getQuery(editorState, parsedFuncs);
        const queryLength = queryArray[0].length;
        let offset = queryArray[1];
        const content = editorState.getCurrentContent();

        // Normalize for the added space at the end. It won't be present if
        // this is the first thing being added.
        const isAtEndOfLine = editorState.getSelection().getEndOffset() === offset + queryLength;

        let newContent = content.createEntity(type, 'IMMUTABLE', data);
        const entityKey = newContent.getLastCreatedEntityKey();
        // we always want to ensure there is a space in front of an entity
        if (offset === 0) {
          newContent = Modifier.insertText(
            newContent,
            getSelectionState(newContent.getFirstBlock().getKey(), 0),
            ' ',
          );
          offset = 1;
        }

        let newOffset = offset;
        const currentBlock = newContent.getFirstBlock();
        const blockKey = currentBlock.getKey();
        if (replaceEntityKey != null) {
          let entityStart = 0;
          let entityEnd = 0;
          currentBlock.findEntityRanges(
            (value) => value.getEntity() === replaceEntityKey,
            (start, end) => {
              entityStart = start;
              entityEnd = end;
            },
          );
          newContent = Modifier.replaceText(
            newContent,
            getSelectionState(blockKey, entityStart, entityEnd),
            type,
            undefined,
            entityKey,
          );
          newOffset = entityStart + type.length;
        } else {
          const label = data.label;
          if (!insertAtEnd) {
            // replace the query with the label from the selected item
            const querySelection = getSelectionState(blockKey, offset, offset + queryLength);
            newContent = Modifier.replaceText(
              newContent,
              querySelection,
              label,
              undefined,
              entityKey,
            );
            newOffset = offset + label.length;
          } else {
            newContent = Modifier.insertText(
              newContent,
              getSelectionState(blockKey, offset + queryLength),
              label,
              undefined,
              entityKey,
            );
            newOffset = offset + queryLength + label.length;
          }

          // add space preceding new entity if there is an entity right behind it
          if (currentBlock.getEntityAt(offset - 1) != null) {
            newContent = Modifier.insertText(newContent, getSelectionState(blockKey, offset), ' ');
            newOffset += 1;
          }

          // add space following new entity if there is an entity right in front of it
          if (currentBlock.getEntityAt(newOffset + 1) != null) {
            newContent = Modifier.insertText(
              newContent,
              getSelectionState(blockKey, newOffset),
              ' ',
            );
            newOffset += 1;
          }

          // add space after the end of entity if we're adding to the end of the line
          if (isAtEndOfLine) {
            const endOfEntitySelection = getSelectionState(
              blockKey,
              newContent.getFirstBlock().getLength(),
            );
            newContent = Modifier.insertText(newContent, endOfEntitySelection, ' ');
            newOffset += 1;
          }
        }

        const newEditorState = EditorState.forceSelection(
          EditorState.push(
            editorState,
            addErrorHighlightingToContent({
              content: newContent,
              driversById,
              fieldSpecsById: objectFieldSpecById,
              attributesBySubDriverId,
              objectsByFieldId,
              objectsById,
              submodelIdByBlockId,
              errorEvaluatorProps: {
                formulaEntityId,
                isActualsFormula: isActuals,
                evaluator,
              },
            }),
            'insert-characters',
          ),
          getSelectionState(blockKey, newOffset),
        );

        setEditorState(newEditorState);
        if (select) {
          setActiveEntity({
            ...entityData,
            key: entityKey,
          });
        }

        switch (type) {
          case EntityType.Driver: {
            dispatch(addRecentEntities([{ type, data: { id: data.id } }]));
            break;
          }
          case EntityType.ExtDriver: {
            dispatch(addRecentEntities([{ type, data: { id: data.id, source: data.source } }]));
            break;
          }
          case EntityType.ObjectSpec: {
            dispatch(addRecentEntities([{ type, data: { id: data.id, fieldId: data.fieldId } }]));
            break;
          }
          case EntityType.Submodel: {
            dispatch(addRecentEntities([{ type, data: { id: data.id } }]));
            break;
          }
          default: {
            break;
          }
        }
      },
      [
        editorState,
        parsedFuncs,
        driversById,
        objectFieldSpecById,
        attributesBySubDriverId,
        objectsByFieldId,
        objectsById,
        submodelIdByBlockId,
        formulaEntityId,
        isActuals,
        evaluator,
        dispatch,
      ],
    );

    const submodelNamesBySubmodelId = useAppSelector(submodelNamesByIdSelector);
    const allDriverGroupsById = useAppSelector(driverGroupsByIdSelector);
    const insertDriverGroup = useCallback(
      (submodelId: SubmodelId, driverGroupId: DriverGroupId) => {
        const { name } = allDriverGroupsById[driverGroupId];
        const submodelName = submodelNamesBySubmodelId[submodelId];
        insertEntity(
          {
            type: EntityType.Submodel,
            data: {
              id: submodelId,
              label: `${submodelName} / ${name}`,
              driverGroupFilter: driverGroupId,
              ...THIS_MONTH_DATE_RANGE,
            },
          },
          { select: false },
        );
      },
      [allDriverGroupsById, insertEntity, submodelNamesBySubmodelId],
    );

    const allDriversById = useAppSelector(driversByIdForLayerSelector);
    const insertExtDriver = useCallback(
      (id: ExtDriverId) => {
        insertEntity(
          {
            type: EntityType.ExtDriver,
            data: {
              id,
              source: extDriversById[id].source,
              label: last(extDriversById[id].path) ?? '',
              ...THIS_MONTH_DATE_RANGE,
            },
          },
          { select: false },
        );
      },
      [extDriversById, insertEntity],
    );

    const insertObjectSpec = useCallback(
      (
        id: BusinessObjectSpecId,
        fieldId: BusinessObjectFieldSpecId | undefined,
        isThisRef: boolean,
      ) => {
        const objectSpec = objectSpecsById[id];
        let fieldName: string | undefined;
        if (fieldId != null) {
          const driverProperty = objectSpec?.collection?.driverProperties.find(
            (d) => d.id === fieldId,
          );
          if (driverProperty != null) {
            fieldName = driversById[driverProperty.driverId]?.name;
          } else {
            fieldName = objectFieldSpecById[fieldId]?.name;
          }
        }
        insertEntity(
          {
            type: EntityType.ObjectSpec,
            data: {
              id,
              fieldId,
              isThisRef,
              label: getObjectRefLabel(objectSpec?.name ?? '', fieldName, isThisRef),
              ...THIS_MONTH_DATE_RANGE,
            },
          },
          { select: false },
        );
      },
      [objectSpecsById, insertEntity, driversById, objectFieldSpecById],
    );

    const insertExtQuery = useCallback(
      (id: string) => {
        // TODO: Fix label
        const label = `extQuery(${id})`;
        insertEntity(
          {
            type: EntityType.ExtQuery,
            data: {
              id,
              label,
            },
          },
          { select: false },
        );
      },
      [insertEntity],
    );

    const generateThisSegmentData = useCallback(
      (dimDriverId: DriverId, existingData?: ThisSegmentMetadata): ThisSegmentMetadata | null => {
        const driver = safeObjGet(driversById[dimDriverId]);
        if (driver == null || driver.type !== DriverType.Dimensional) {
          return existingData ?? null;
        }

        const defaultDateRangeValues = defaultDateRange(dimDriverId, []);
        const { dateRange, dateRangeDisplay } = {
          dateRange: existingData?.dateRange ?? defaultDateRangeValues.dateRange,
          dateRangeDisplay:
            existingData?.dateRangeDisplay ?? defaultDateRangeValues.dateRangeDisplay,
        };
        const data: ThisSegmentMetadata = {
          id: dimDriverId,
          label: `This Segment.${driver.name}`,
          thisEntityType: EntityType.Driver,
          segmentedBy: driver.dimensions.map((d) => d.id),
          dateRange,
          dateRangeDisplay,
        };

        return data;
      },
      [driversById],
    );

    const insertThisSegment = useCallback(
      (dimDriverId: DriverId) => {
        const data = generateThisSegmentData(dimDriverId);
        if (data == null) {
          return;
        }
        insertEntity(
          {
            type: EntityType.ThisSegment,
            data,
          },
          { select: false },
        );
      },
      [generateThisSegmentData, insertEntity],
    );

    const insertFormulaEntity = useCallback(
      (
        id: DriverId,
        {
          name,
          select = false,
          insertAtEnd = false,
          attributeFilters,
        }: {
          name?: string;
          select?: boolean;
          insertAtEnd?: boolean;
          attributeFilters?: AttributeFilters;
        } = {},
      ) => {
        if (formulaEntityId == null) {
          return;
        }

        const driver = allDriversById[id];

        let label = name ?? driver?.name;
        if (label == null) {
          throw new Error(`Unknown driver reference added to formula ${id}`);
        }
        const dimensionalDriver = dimensionalDriversBySubdriverId[id];
        const referencedDriverIds = [id];
        if (dimensionalDriver != null) {
          const subdriver = getSubDriverBySubDriverId(dimensionalDriver, id);
          if (subdriver != null) {
            attributeFilters = attributeFilterForSubDriver(dimensionalDriver, subdriver);
            label = dimensionalDriver.name;
            id = dimensionalDriver.id;
          }
        } else if (driver?.type === DriverType.Dimensional) {
          const baseAttributes = attributeFilters ?? {};
          const updatedAttributeFilters = driver.dimensions.reduce((acc, dim) => {
            acc[dim.id] = [ANY_ATTR];
            return acc;
          }, baseAttributes);

          attributeFilters = updatedAttributeFilters;
          referencedDriverIds.push(...driver.subdrivers.map((sd) => sd.driverId));
        }

        insertEntity(
          {
            type: EntityType.Driver,
            data: {
              id,
              label,
              attributeFilters,
              ...defaultDateRange(formulaEntityId.id, referencedDriverIds),
            },
          },
          {
            select,
            replaceEntityKey: activeEntityRef.current?.key,
            insertAtEnd,
          },
        );
      },
      [allDriversById, dimensionalDriversBySubdriverId, formulaEntityId, insertEntity],
    );

    const updateActiveEntity = useCallback(
      (
        updateEntity:
          | { type: EntityType.Driver; fields: Partial<DriverRefMetadata> }
          | { type: EntityType.Submodel; fields: Partial<SubmodelRefMetadata> }
          | { type: EntityType.ExtDriver; fields: Partial<ExtDriverRefMetadata> }
          | { type: EntityType.ObjectSpec; fields: Partial<ObjectSpecRefMetadata> }
          | { type: EntityType.ExtQuery; fields: Partial<ExtQueryRefMetadata> }
          | { type: EntityType.AtomicNumber; fields: Partial<AtomicNumberMetadata> }
          | { type: EntityType.ThisSegment; fields: Partial<ThisSegmentMetadata> },
        { closeMenu = false }: { closeMenu?: boolean } = {},
      ) => {
        const currentActiveEntity = activeEntityRef.current;
        if (currentActiveEntity?.type !== updateEntity.type || currentActiveEntity.key == null) {
          return;
        }

        setEditorState((e) => {
          const content = e.getCurrentContent();
          const entity = content.getEntity(currentActiveEntity.key);
          const entityData = entity.getData() as NullableRecord<string, string>;
          const [start, end] = getEntityRange(content.getFirstBlock(), currentActiveEntity.key);
          const newContent = content.createEntity(entity.getType(), 'IMMUTABLE', {
            ...entityData,
            ...updateEntity.fields,
          });
          const newEntityKey = newContent.getLastCreatedEntityKey();
          const anchorKey = e.getSelection().getAnchorKey();

          setActiveEntity({
            ...currentActiveEntity,
            key: newEntityKey,
            data: { ...currentActiveEntity.data, ...updateEntity.fields },
          } as EntityDataWithKey);
          return EditorState.push(
            e,
            Modifier.applyEntity(
              newContent,
              getSelectionState(anchorKey, start, end),
              newEntityKey,
            ),
            'apply-entity',
            // @ts-expect-error false param does not force selection to the entity
            false,
          );
        });

        if (closeMenu) {
          const [, entityEnd] = getEntityRange(editorBlock, currentActiveEntity.key);
          const selectionState = getSelectionState(editorBlock.getKey(), entityEnd);

          setActiveEntity(null);
          setDefaultSubmenu(null);

          // wait for the component to no longer be readOnly, then update focus
          window.requestAnimationFrame(() => {
            setEditorState((e) => EditorState.forceSelection(e, selectionState));
          });
        }
      },
      [editorBlock],
    );

    const onCloseMenu = useCallback(() => {
      setActiveEntity(null);
      setDefaultSubmenu(null);
      onCancel();
    }, [onCancel]);
    const onSelectTimePeriod = useCallback(
      (dateRange: FormulaTimeRange, { closeMenu = true }: { closeMenu?: boolean } = {}) => {
        const currentActiveEntity = activeEntityRef.current;
        if (currentActiveEntity == null || currentActiveEntity.key == null) {
          return;
        }

        const dateRangeDisplay = getFormulaDateRangeDisplay(dateRange, driverNamesById);

        updateActiveEntity(
          {
            type: currentActiveEntity.type,
            fields: {
              dateRange,
              dateRangeDisplay,
            },
          },
          { closeMenu },
        );
      },
      [driverNamesById, updateActiveEntity],
    );

    const onSelectSubDriverAttributes = useCallback(
      (attributeFilters: AttributeFilters) => {
        updateActiveEntity({ type: EntityType.Driver, fields: { attributeFilters } });
      },
      [updateActiveEntity],
    );

    const onSelectObjectField = useCallback(
      (field: { id: BusinessObjectFieldSpecId; name: string } | null) => {
        const currentActiveEntity = activeEntityRef.current;
        if (currentActiveEntity?.type !== EntityType.ObjectSpec) {
          return;
        }

        const specId = currentActiveEntity.data.id;
        const spec = safeObjGet(objectSpecsById[specId]);
        if (spec == null) {
          return;
        }

        updateActiveEntity({
          type: EntityType.ObjectSpec,
          fields: {
            fieldId: field?.id ?? undefined,
            label: getObjectRefLabel(
              spec.name,
              field?.name,
              currentActiveEntity.data.isThisRef ?? false,
            ),
          },
        });
      },
      [objectSpecsById, updateActiveEntity],
    );

    const onSelectThisSegmentEntity = useCallback(
      ({ dimDriverId }: { dimDriverId: DriverId }) => {
        const currentActiveEntity = activeEntityRef.current;
        if (currentActiveEntity?.type !== EntityType.ThisSegment) {
          return;
        }
        const data = generateThisSegmentData(dimDriverId, currentActiveEntity.data);
        if (data == null) {
          return;
        }

        updateActiveEntity({
          type: EntityType.ThisSegment,
          fields: data,
        });
      },
      [generateThisSegmentData, updateActiveEntity],
    );

    const onSelectObjectFilters = useCallback(
      (filters: FilterItem[]) => {
        const completedFilters = filters.filter(filterIsComplete);
        updateActiveEntity({ type: EntityType.ObjectSpec, fields: { filters: completedFilters } });
      },
      [updateActiveEntity],
    );

    const handleEditorMouseDown = useCallback(
      (ev: React.MouseEvent) => {
        ev.stopPropagation();

        setActiveEntity(null);
        setDefaultSubmenu(null);
      },
      [setActiveEntity],
    );

    const onEditorChange = useCallback(
      (edState: EditorState) => {
        if (
          rawFormula === draftContentStateToRawFormula(edState.getCurrentContent(), driversById)
        ) {
          setEditorState(edState);
          return;
        }
        setIsQueryCleared.off();

        let newState = addWhitespace(edState);
        const current = newState.getCurrentContent().getPlainText();
        const currentSelection = edState.getSelection();
        const lastChange = edState.getLastChangeType();
        if (lastChange === 'undo' || lastChange === 'redo') {
          // always check for errors on undo/redo, and protect against
          // inadvertently highlighting a selection range
          setEditorState(
            EditorState.forceSelection(
              newState,
              getSelectionState(
                currentSelection.getAnchorKey(),
                Math.min(current.length, currentSelection.getEndOffset()),
              ),
            ),
          );
          return;
        }

        let shouldUpdateErrorHighlighting = false;
        const currentLength = Array.from(current).length;
        const savedLength = Array.from(currentContentPlainText).length;
        const [newQuery, newQueryOffset] = getQuery(newState, parsedFuncs);
        if (newQuery === query && query === '') {
          let change = ' ';
          let diffIndex = -1;
          let isEditingEnd = false;
          if (lastChange === 'insert-characters') {
            diffIndex = findDifferenceIndex(current, currentContentPlainText);
            change = current[diffIndex];
            isEditingEnd = diffIndex === currentLength;
          } else {
            diffIndex = findDifferenceIndex(currentContentPlainText, current);
            change = currentContentPlainText[diffIndex];
            isEditingEnd = diffIndex === savedLength;
          }

          if (!isEditingEnd && change !== ' ') {
            shouldUpdateErrorHighlighting = true;
          }
        }

        if (shouldUpdateErrorHighlighting && formulaEntityId != null) {
          newState = updateErrorHighlighting({
            editorState: newState,
            driversById,
            fieldSpecsById: objectFieldSpecById,
            attributesBySubDriverId,
            objectsByFieldId,
            objectsById,
            submodelIdByBlockId,
            errorEvaluatorProps: {
              formulaEntityId,
              isActualsFormula: isActuals,
              evaluator,
            },
          });
        } else if (newQuery !== '') {
          newState = removeHighlightingFromQuery(newState, newQuery, newQueryOffset);
        }

        setEditorState(newState);
      },
      [
        rawFormula,
        driversById,
        setIsQueryCleared,
        parsedFuncs,
        query,
        currentContentPlainText,
        objectFieldSpecById,
        attributesBySubDriverId,
        objectsByFieldId,
        objectsById,
        submodelIdByBlockId,
        formulaEntityId,
        isActuals,
        evaluator,
      ],
    );

    useEffect(() => {
      onChange?.(getOnChangePayload());
    }, [getOnChangePayload, onChange]);

    useEffectOnce(() => {
      // This magical code handles the copy/paste within DraftJS itself.
      // It leverages draftjs-conductor (https://github.com/thibaudcolas/draftjs-conductor)
      let unregisterCopySource: ReturnType<typeof registerCopySource> | undefined;
      if (editorRef.current) {
        unregisterCopySource = registerCopySource(editorRef.current);
      }

      // Initialize the state of the formula so if we click outside, we don't end
      // up changing the formula.
      const getSavedContent = () => {
        if (formulaDisplay == null) {
          return EditorState.createEmpty(compositeDecorator);
        }

        return EditorState.createWithContent(
          convertFromRaw(displayToDraftJS(databaseFormulaPropertiesById, formulaDisplay)),
          compositeDecorator,
        );
      };
      const savedRawFormula = draftContentStateToRawFormula(
        getSavedContent().getCurrentContent(),
        driversById,
      );
      originalComputedFormula.current = savedRawFormula;
      if (isEmpty(savedRawFormula)) {
        dispatch(setCanInsertFormulaEntityReference(true));
      }

      return () => {
        dispatch(setCanInsertFormulaEntityReference(false));
        unregisterCopySource?.unregister();
      };
    });

    const [lastCreatedDriverId, setLastCreatedDriverId] = useState<DriverId>();
    const formulaDropdownContext = useMemo(
      () => ({
        activeEntity,
        insertFormulaEntity,
        onSelectMathOperator,
        onSelectStringLiteral,
        onSelectTimePeriod,
        insertDriverGroup,
        insertExtDriver,
        insertObjectSpec,
        insertExtQuery,
        insertThisSegment,
        onSelectSubDriverAttributes,
        onSelectObjectFilters,
        onSelectRawFormulaQuery: setIsQueryCleared.on,
        onSelectObjectField,
        onSelectThisSegmentEntity,
        lastCreatedDriverId,
        setLastCreatedDriverId,
        canCreateDriver,
        onCloseMenu,
      }),
      [
        activeEntity,
        insertFormulaEntity,
        onSelectMathOperator,
        onSelectStringLiteral,
        onSelectTimePeriod,
        insertDriverGroup,
        insertExtDriver,
        insertObjectSpec,
        insertExtQuery,
        insertThisSegment,
        onSelectSubDriverAttributes,
        onSelectObjectFilters,
        onSelectThisSegmentEntity,
        setIsQueryCleared.on,
        onSelectObjectField,
        lastCreatedDriverId,
        canCreateDriver,
        onCloseMenu,
      ],
    );

    const formulaSelectionContext = useMemo(() => {
      const keysInSelection = getEntityKeysInSelection(editorBlock, selection);
      return {
        formulaEntityId,
        isActuals,
        hasError,
        activeEntity,
        setActiveEntity,
        defaultSubmenu,
        setDefaultSubmenu,
        anchorOffset: selection.getAnchorOffset(),
        focusOffset: selection.getFocusOffset(),
        resetSelectionState,
        closeFormulaInput: handleSave,
        keysInSelection,
      };
    }, [
      editorBlock,
      selection,
      formulaEntityId,
      isActuals,
      hasError,
      activeEntity,
      defaultSubmenu,
      resetSelectionState,
      handleSave,
    ]);

    const { blockId } = useBlockContext();
    const handleFormulaJSONPaste = useHandleFormulaJSONPaste({
      isActuals,
      formulaEntityId,
      editorState,
      blockId,
    });
    const handlePastedText = useCallback(
      (text: string, html: string | undefined, currState: EditorState): DraftHandleValue => {
        if (isValidJSON(text)) {
          const newContent = handleFormulaJSONPaste(text);
          if (newContent != null) {
            setEditorState((e) => {
              return EditorState.push(e, newContent, 'insert-characters');
            });
          }
          return 'handled';
        }
        const newState = handleDraftEditorPastedText(html, currState);

        if (newState === false) {
          return 'not-handled';
        }

        setEditorState(newState);
        return 'handled';
      },
      [handleFormulaJSONPaste],
    );

    const cursorFuncQueryMetadata = getCursorFuncQueryMetadata(editorState, parsedFuncs);

    const showFormulaError = formulaError != null;
    const showErrorTooltip = showFormulaError && isActive;

    useEffect(() => {
      if (showErrorTooltip) {
        forceUpdateErrorHighlighting();
      }
    }, [forceUpdateErrorHighlighting, showErrorTooltip]);

    const hasQuery =
      isActive &&
      !isQueryCleared &&
      !preventQueryDropdown(editorState, queryIndex - 1, cursorFuncQueryMetadata) &&
      (query.length > 0 ||
        showEmptyQueryDropdown(editorState, queryIndex - 1, cursorFuncQueryMetadata));
    const showDropdown = (hasQuery || hasSelectedEntity) && isActive && !disableDropdown;

    // set the focus to the end of the input
    const focusEditorAndMoveCursorToEndOfInput = useCallback(() => {
      setEditorState((s) => {
        const content = s.getCurrentContent();
        const endsInSpace = content.getPlainText().endsWith(' ');
        const block = content.getFirstBlock();
        const focus = endsInSpace ? block.getLength() - 1 : block.getLength();
        const endOfInputSelection = getSelectionState(block.getKey(), focus, focus);
        return EditorState.forceSelection(s, endOfInputSelection);
      });
    }, []);

    // This is needed to focus the editor when the user clicks on the wrapper
    const focusEditorInputElement = useCallback(() => {
      editorRef.current?.focus();
    }, []);

    const driverReferenceToInsert = useAppSelector(insertedFormulaEntityReferenceSelector);
    useEffect(() => {
      if (isActive && driverReferenceToInsert != null) {
        window.setTimeout(() => {
          // N.B. hard-won knowledge; if you don't focus the editor first, this won't work
          focusEditorAndMoveCursorToEndOfInput();
          if (driverReferenceToInsert.type === 'driver') {
            insertFormulaEntity(driverReferenceToInsert.id, {
              select: hasSelectedEntity,
              insertAtEnd: true,
            });
          } else if (driverReferenceToInsert.type === 'extDriver') {
            insertExtDriver(driverReferenceToInsert.id);
          }
          dispatch(clearFormulaEntityReference());
        }, 50);
      }
    }, [
      dispatch,
      isActive,
      driverReferenceToInsert,
      insertFormulaEntity,
      hasSelectedEntity,
      focusEditorAndMoveCursorToEndOfInput,
      insertExtDriver,
    ]);

    // refocus the input if you blur it with an error
    // otherwise, calls onBlur callback
    const onBlur = useCallback(
      (e: SyntheticEvent) => {
        window.setTimeout(() => {
          if (isActive && showFormulaError) {
            focusEditorAndMoveCursorToEndOfInput();
            forceUpdateErrorHighlighting();
          } else {
            onBlurGiven?.(e, getOnChangePayload());
          }
        }, 100);
      },
      [
        isActive,
        showFormulaError,
        focusEditorAndMoveCursorToEndOfInput,
        forceUpdateErrorHighlighting,
        onBlurGiven,
        getOnChangePayload,
      ],
    );

    // when an input becomes active, focus it
    useEffect(() => {
      if (isActive) {
        window.setTimeout(() => {
          focusEditorAndMoveCursorToEndOfInput();
          if (onActive) {
            onActive();
          }
        }, 100);
      }
    }, [isActive, focusEditorAndMoveCursorToEndOfInput, onActive]);

    const handleCopy = useHandleCopy({
      editorState,
      isActuals,
      formulaEntityId,
    });

    // Expose some functionality to the parent component through the ref,
    // for example to focus the editor.
    useImperativeHandle(
      ref,
      () => {
        return {
          focus: focusEditorAndMoveCursorToEndOfInput,
          save: handleSave,
        };
      },
      [focusEditorAndMoveCursorToEndOfInput, handleSave],
    );

    return (
      <FormulaSelectionContext.Provider value={formulaSelectionContext}>
        <Tooltip
          placement="left-start"
          isOpen={showErrorTooltip}
          bgColor="white"
          color="gray.600"
          label={<FormulaTooltipContents error={formulaError} />}
        >
          <Tippy
            offset={FORMULA_DROPDOWN_OFFSET}
            placement="bottom-start"
            visible
            interactive
            appendTo={document.body}
            zIndex={theme.zIndices.popover}
            render={() => {
              return (
                // Only mount the formula dropdown component when it's open. We want its key handlers to take
                // precedence over the formula editor when it's open, and for those key handlers to not run at
                // all when it's closed
                showDropdown && (
                  <FormulaDropdownContext.Provider value={formulaDropdownContext}>
                    <FormulaDropdown
                      query={query}
                      cursorFuncQueryMetadata={cursorFuncQueryMetadata}
                    />
                  </FormulaDropdownContext.Provider>
                )
              );
            }}
          >
            <Box width="100%" onClick={focusEditorInputElement}>
              <Flex
                data-testid="formula-input"
                // N.B. add CSS class so that we can easily detect this component in `hasFormulaInputOpen`
                className={classNames(FORMULA_EDITOR_CLASS, {
                  error: showFormulaError,
                  collapseLeadingSpace,
                  collapseTrailingSpace,
                  empty: rawFormula == null,
                  isSafari,
                  noBorder: !showBorder,
                })}
                fontSize="xs"
                fontWeight="medium"
                lineHeight={7}
                maxWidth="45rem"
                maxHeight="30rem"
                minHeight="2.625rem"
                minWidth={minWidth}
                onMouseDown={handleEditorMouseDown}
                overflowX="hidden"
                overflowY="auto"
                position="relative"
                width={width}
                justifyContent={justifyContent}
                sx={NUMERIC_FONT_SETTINGS}
                _before={{
                  content: '"="',
                  color: 'gray.500',
                  position: 'absolute',
                  fontWeight: 'medium',
                  left: 2,
                  top: '8px',
                }}
              >
                <Editor
                  ref={editorRef}
                  stripPastedStyles
                  spellCheck={false}
                  editorState={editorState}
                  onChange={onEditorChange}
                  keyBindingFn={keyBindingFn}
                  onBlur={onBlur}
                  onCopy={handleCopy}
                  handleKeyCommand={handleKeyCommand}
                  readOnly={hasSelectedEntity}
                  customStyleMap={draftCustomStyleMap}
                  handlePastedText={handlePastedText}
                />
                {currentContentPlainText === '' &&
                  inheritableFormulaDisplay != null &&
                  inheritableFormulaSourceType != null &&
                  inheritableFormulaSourceDriverId != null && (
                    <InheritedFormulaPill
                      formulaDisplay={inheritableFormulaDisplay}
                      onClick={onSelectInheritedFormula}
                      formulaSourceType={inheritableFormulaSourceType}
                      formulaSourceDriverId={inheritableFormulaSourceDriverId}
                    />
                  )}
              </Flex>
            </Box>
          </Tippy>
        </Tooltip>
      </FormulaSelectionContext.Provider>
    );
  },
);

/**
 * The draft.js Editor only uses the first `onCopy` reference passed to it, so we need to make sure we only ever send down one version of the handler. This means that the dependency array of the useCallback needs to be empty.
 */

const useHandleCopy = ({
  isActuals,
  formulaEntityId,
  editorState,
}: {
  isActuals: boolean;
  formulaEntityId: FormulaEntityTypedId | undefined;
  editorState: EditorState;
}) => {
  const { getState } = useAppStore();

  const driversById = useAppSelector(driversByIdForLayerSelector);

  const args = {
    driversById,
    isActuals,
    getState,
    formulaEntityId,
    editorState,
  };
  const argsRef = useRef(args);
  argsRef.current = args;
  const handleCopy = useCallback(
    (_editor: Editor, ev: React.ClipboardEvent) => {
      const editorContent = argsRef.current.editorState.getCurrentContent();
      const editorSelection = argsRef.current.editorState.getSelection();
      const rawTextSize = editorContent.getPlainText().length;
      const allSelected =
        editorSelection.getStartOffset() === 0 && editorSelection.getEndOffset() === rawTextSize;
      if (!(editorSelection.isCollapsed() || allSelected)) {
        return;
      }

      ev.nativeEvent.preventDefault();
      ev.nativeEvent.stopPropagation();

      const formula = draftContentStateToRawFormula(editorContent, argsRef.current.driversById);
      if (
        formula == null ||
        formula.trim().length === 0 ||
        argsRef.current.formulaEntityId == null
      ) {
        return;
      }

      const entityId = argsRef.current.formulaEntityId.id;
      const state = argsRef.current.getState();
      let formulaCopyData: FormulaCopyData | null = null;

      if (argsRef.current.formulaEntityId.type === 'objectFieldSpec') {
        formulaCopyData = getFieldFormulaCopyData({
          state,
          sourceFieldSpecId: entityId,
          formula,
        });
      } else {
        formulaCopyData = getDriverFormulaCopyData({
          state,
          sourceDriverId: entityId,
          formula,
          formulaType: argsRef.current.isActuals ? 'actuals' : 'forecast',
        });
      }

      if (formulaCopyData == null) {
        return;
      }

      ev.nativeEvent.clipboardData?.setData('text/plain', JSON.stringify(formulaCopyData));
    },
    // This absolutely must be an empty array, see useHandleCopy doc string above
    [],
  );

  return handleCopy;
};

const useHandleFormulaJSONPaste = ({
  isActuals,
  formulaEntityId,
  editorState,
  blockId,
}: {
  isActuals: boolean;
  formulaEntityId: FormulaEntityTypedId | undefined;
  editorState: EditorState;
  blockId: BlockId;
}) => {
  const { getState } = useAppStore();
  const dispatch = useAppDispatch();

  const driversById = useAppSelector(driversByIdForLayerSelector);

  const args = {
    driversById,
    isActuals,
    getState,
    formulaEntityId,
    editorState,
    blockId,
    dispatch,
  };
  const argsRef = useRef(args);
  argsRef.current = args;
  const handleFormulaJSONPaste = useCallback(
    (text: string) => {
      const state = argsRef.current.getState();
      const incomingValue = parseSingleFormulaFromClipboardJSON(text);
      if (
        incomingValue == null ||
        isString(incomingValue) ||
        argsRef.current.formulaEntityId == null
      ) {
        return undefined;
      }

      const driverId = argsRef.current.formulaEntityId.id;
      const targetOriginalFormula = driverFormulaSelector(state, {
        id: driverId,
        type: argsRef.current.isActuals ? 'actuals' : 'forecast',
      });

      const formula = getFormulaFromFormulaCopyData({
        state,
        targetEntityId: argsRef.current.formulaEntityId,
        pastedData: incomingValue,
        targetOriginalFormula,
        blockId: argsRef.current.blockId,
      });
      const formulaDisplay = getFormulaDisplayForFormula(
        state,
        formula,
        argsRef.current.formulaEntityId,
      );

      if (formulaDisplay == null) {
        return undefined;
      }

      const databaseFormulaPropertiesById = databaseFormulaPropertiesByIdSelector(state);

      const editorContent = argsRef.current.editorState.getCurrentContent();
      const editorSelection = argsRef.current.editorState.getSelection();

      return Modifier.replaceWithFragment(
        editorContent,
        editorSelection,
        convertFromRaw(
          displayToDraftJS(databaseFormulaPropertiesById, formulaDisplay),
        ).getBlockMap(),
      );
    },
    // This absolutely must be an empty array, see useHandleCopy doc string above
    [],
  );

  return handleFormulaJSONPaste;
};

export default FormulaInput;
