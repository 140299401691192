import {
  AgPieSeriesTooltipRendererParams,
  AgPolarChartOptions,
  AgPolarSeriesOptions,
} from 'ag-charts-community';
import React, { useMemo } from 'react';

import AgChart from 'components/AgGridComponents/AgChart/AgChart';
import { ChartDisplay, ChartGroupingType, ChartSize } from 'generated/graphql';
import { safeObjGet } from 'helpers/typescript';
import useBlockContext from 'hooks/useBlockContext';
import { DriverId } from 'reduxStore/models/drivers';

import { renderTooltip } from './agChartTooltips';
import { CHART_TOOLTIP_CLASSNAME } from './agCharts';
import { useChartDriverAggregatedData, useChartDriverConfig } from './chartDriverHooks';

interface AgPieChartProps {
  driverIds: DriverId[];
  chartDisplay: ChartDisplay;
  size?: ChartSize;
  groupingType?: ChartGroupingType;
  stacked?: boolean;
  chartIndex?: number;
}

const OTHER_ID = 'other';
const OTHER_LABEL = 'Other drivers in block';
const OTHER_COLOR = '#d9d9d9';

const AgPieChart: React.FC<AgPieChartProps> = ({ chartIndex, driverIds, chartDisplay }) => {
  const { blockId } = useBlockContext();
  const {
    chartConfig,
    dateRange,
    driverDisplayConfigurationsById,
    width,
    height,
    colorByDriverId,
    driverNamesById,
  } = useChartDriverConfig(blockId, driverIds, chartDisplay);

  const {
    isAnyDriverLoading,
    attributesBySubDriverId,
    data: aggregatedData,
  } = useChartDriverAggregatedData(driverIds, dateRange, chartDisplay);

  const data = useMemo(() => {
    if (!aggregatedData) {
      return null;
    }

    const result = aggregatedData.map(({ id, value }) => {
      return {
        id,
        value,
        label: driverNamesById[id],
        color: colorByDriverId[id],
      };
    });

    return result;
  }, [driverNamesById, colorByDriverId, aggregatedData]);

  const filteredData = useMemo(() => {
    if (data == null || typeof chartIndex !== 'number') {
      return data;
    }

    const dataToDisplay = data.filter((_, i) => i === chartIndex);
    const dataToAggregate = data.filter((_, i) => i !== chartIndex);
    const aggregatedValue = dataToAggregate.reduce(
      (acc, { value }) => (typeof value === 'number' ? acc + value : acc),
      0,
    );

    return [
      {
        value: aggregatedValue,
        id: OTHER_ID,
        label: OTHER_LABEL,
        color: OTHER_COLOR,
      },
      ...dataToDisplay,
    ];
  }, [data, chartIndex]);

  const series = useMemo((): AgPolarSeriesOptions[] => {
    if (!chartConfig.chartDisplay) {
      return [];
    }

    const config: AgPolarSeriesOptions = {
      type: 'pie',
      angleKey: 'value',
      calloutLabelKey: 'label',
      fills: filteredData?.map(({ color }) => color) ?? [],
      highlightStyle: {
        series: {
          enabled: true,
          dimOpacity: 0.5,
        },
      },
      sectorLabel: {
        color: 'white',
        fontWeight: 'bold',
      },
      tooltip: {
        enabled: true,
        showArrow: false,
        renderer: ({
          angleKey,
          title,
          datum,
        }: AgPieSeriesTooltipRendererParams<Record<string, number>>) => {
          const id = String(datum.id);

          if (id === OTHER_ID) {
            return '';
          }

          const key = String(angleKey);
          const value = Number(datum[key]);
          const displayConfiguration = driverDisplayConfigurationsById[datum.id];
          const attributes = safeObjGet(attributesBySubDriverId[datum.key]);

          return renderTooltip(title, { id, key, value }, displayConfiguration, attributes);
        },
      },
    };

    return [config];
  }, [attributesBySubDriverId, driverDisplayConfigurationsById, chartConfig, filteredData]);

  const options: AgPolarChartOptions = useMemo(() => {
    if (filteredData == null || driverDisplayConfigurationsById == null) {
      return {
        width,
        height,
      };
    }

    return {
      data: isAnyDriverLoading ? [] : filteredData,
      series,
      // TODO: figure out how to sync tooltips more effectively.
      tooltip: {
        class: CHART_TOOLTIP_CLASSNAME,
        position: {
          type: 'pointer',
        },
      },
      legend: {
        enabled: typeof chartIndex === 'number' ? false : driverIds.length > 1,
        reverseOrder: true,
        // TODO: make this part of display configuration
        position: 'right',
        preventHidingAll: true,
        item: {
          maxWidth: 200,
          label: {
            formatter: ({ itemId, value }) => {
              const driverId = String(itemId);
              const attributes = safeObjGet(attributesBySubDriverId[driverId]);

              if (attributes != null && attributes.length > 0) {
                let base = value;
                for (const attr of attributes) {
                  base += ` (${attr.value})`;
                }
                return base;
              }

              return value;
            },
          },
        },
      },
      width,
      height,
    };
  }, [
    attributesBySubDriverId,
    filteredData,
    driverDisplayConfigurationsById,
    driverIds.length,
    isAnyDriverLoading,
    series,
    width,
    height,
    chartIndex,
  ]);

  return <AgChart isLoading={isAnyDriverLoading} options={options} />;
};

export default React.memo(AgPieChart);
