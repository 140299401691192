import { ComponentSingleStyleConfig } from '@chakra-ui/react';

export default {
  baseStyle: {
    borderColor: 'gray.300',
    background: 'white',
    borderSize: '0.0625rem',
    rounded: 'sm',
  },
} as ComponentSingleStyleConfig;
