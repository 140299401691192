import { ComponentSingleStyleConfig } from '@chakra-ui/react';

const tooltip: ComponentSingleStyleConfig = {
  baseStyle: {
    '--popper-arrow-bg': '#232529',
    boxShadow: 'tooltip',
    bg: '#232529',
    color: 'white',
    fontSize: 'xxs',
    fontWeight: 'medium',
    lineHeight: '1.05rem',
    maxWidth: '31.25rem',
    px: 2,
    py: 1,
    rounded: 6,
    textAlign: 'center',
  },
  defaultProps: {
    hasArrow: false,
    placement: 'top',
  },
};

export default tooltip;
