// Generated from ../antlr/ImpactParser.g4 by ANTLR 4.9.0-SNAPSHOT


import { ATN } from "antlr4ts/atn/ATN";
import { ATNDeserializer } from "antlr4ts/atn/ATNDeserializer";
import { FailedPredicateException } from "antlr4ts/FailedPredicateException";
import { NotNull } from "antlr4ts/Decorators";
import { NoViableAltException } from "antlr4ts/NoViableAltException";
import { Override } from "antlr4ts/Decorators";
import { Parser } from "antlr4ts/Parser";
import { ParserRuleContext } from "antlr4ts/ParserRuleContext";
import { ParserATNSimulator } from "antlr4ts/atn/ParserATNSimulator";
import { ParseTreeListener } from "antlr4ts/tree/ParseTreeListener";
import { ParseTreeVisitor } from "antlr4ts/tree/ParseTreeVisitor";
import { RecognitionException } from "antlr4ts/RecognitionException";
import { RuleContext } from "antlr4ts/RuleContext";
//import { RuleVersion } from "antlr4ts/RuleVersion";
import { TerminalNode } from "antlr4ts/tree/TerminalNode";
import { Token } from "antlr4ts/Token";
import { TokenStream } from "antlr4ts/TokenStream";
import { Vocabulary } from "antlr4ts/Vocabulary";
import { VocabularyImpl } from "antlr4ts/VocabularyImpl";

import * as Utils from "antlr4ts/misc/Utils";

import { ImpactParserListener } from "./ImpactParserListener";

export class ImpactParserParser extends Parser {
	public static readonly T__0 = 1;
	public static readonly T__1 = 2;
	public static readonly T__2 = 3;
	public static readonly T__3 = 4;
	public static readonly T__4 = 5;
	public static readonly T__5 = 6;
	public static readonly T__6 = 7;
	public static readonly T__7 = 8;
	public static readonly T__8 = 9;
	public static readonly T__9 = 10;
	public static readonly T__10 = 11;
	public static readonly T__11 = 12;
	public static readonly T__12 = 13;
	public static readonly T__13 = 14;
	public static readonly T__14 = 15;
	public static readonly T__15 = 16;
	public static readonly T__16 = 17;
	public static readonly T__17 = 18;
	public static readonly T__18 = 19;
	public static readonly T__19 = 20;
	public static readonly T__20 = 21;
	public static readonly T__21 = 22;
	public static readonly T__22 = 23;
	public static readonly T__23 = 24;
	public static readonly T__24 = 25;
	public static readonly T__25 = 26;
	public static readonly T__26 = 27;
	public static readonly T__27 = 28;
	public static readonly T__28 = 29;
	public static readonly T__29 = 30;
	public static readonly T__30 = 31;
	public static readonly T__31 = 32;
	public static readonly T__32 = 33;
	public static readonly T__33 = 34;
	public static readonly T__34 = 35;
	public static readonly T__35 = 36;
	public static readonly T__36 = 37;
	public static readonly T__37 = 38;
	public static readonly T__38 = 39;
	public static readonly T__39 = 40;
	public static readonly T__40 = 41;
	public static readonly T__41 = 42;
	public static readonly T__42 = 43;
	public static readonly T__43 = 44;
	public static readonly T__44 = 45;
	public static readonly T__45 = 46;
	public static readonly T__46 = 47;
	public static readonly T__47 = 48;
	public static readonly DATE = 49;
	public static readonly UUID = 50;
	public static readonly NUMBER = 51;
	public static readonly FLOAT = 52;
	public static readonly NUMBER_SUFFIX = 53;
	public static readonly STRING = 54;
	public static readonly LPAREN = 55;
	public static readonly RPAREN = 56;
	public static readonly ADD = 57;
	public static readonly SUB = 58;
	public static readonly MUL = 59;
	public static readonly DIV = 60;
	public static readonly POW = 61;
	public static readonly NULL = 62;
	public static readonly ANY = 63;
	public static readonly CALENDAR = 64;
	public static readonly RELATIVE = 65;
	public static readonly COHORT_MONTH = 66;
	public static readonly ROUND = 67;
	public static readonly ROUNDDOWN = 68;
	public static readonly WS = 69;
	public static readonly BOOLEAN_OPERATOR = 70;
	public static readonly THIS = 71;
	public static readonly LINKED = 72;
	public static readonly INVALID_WORD_CHARS = 73;
	public static readonly INVALID_WORD = 74;
	public static readonly RULE_atomicNumber = 0;
	public static readonly RULE_impactExpression = 1;
	public static readonly RULE_calculator = 2;
	public static readonly RULE_blockFilter = 3;
	public static readonly RULE_timestampCalculator = 4;
	public static readonly RULE_timestampExpression = 5;
	public static readonly RULE_stringCalculator = 6;
	public static readonly RULE_stringExpression = 7;
	public static readonly RULE_timestampValue = 8;
	public static readonly RULE_durationValue = 9;
	public static readonly RULE_durationUnit = 10;
	public static readonly RULE_booleanExpression = 11;
	public static readonly RULE_expression = 12;
	public static readonly RULE_dateHelpers = 13;
	public static readonly RULE_arrayExpression = 14;
	public static readonly RULE_sumProduct = 15;
	public static readonly RULE_baseIf = 16;
	public static readonly RULE_stringIf = 17;
	public static readonly RULE_timestampIf = 18;
	public static readonly RULE_ifErrorRef = 19;
	public static readonly RULE_coalesceRef = 20;
	public static readonly RULE_driverRef = 21;
	public static readonly RULE_dimDriverRef = 22;
	public static readonly RULE_submodelRef = 23;
	public static readonly RULE_objectRef = 24;
	public static readonly RULE_extObjectRef = 25;
	public static readonly RULE_extObjectFieldRef = 26;
	public static readonly RULE_objectSpecRef = 27;
	public static readonly RULE_matchObjectSpecRef = 28;
	public static readonly RULE_objectFieldRef = 29;
	public static readonly RULE_extDriverRef = 30;
	public static readonly RULE_extQueryRef = 31;
	public static readonly RULE_extQueryFilterView = 32;
	public static readonly RULE_objectFilterView = 33;
	public static readonly RULE_objectFilter = 34;
	public static readonly RULE_userAttributeFilter = 35;
	public static readonly RULE_builtInAttributeFilter = 36;
	public static readonly RULE_attributeFilter = 37;
	public static readonly RULE_driverGroupFilter = 38;
	public static readonly RULE_submodelFilter = 39;
	public static readonly RULE_driverFilterView = 40;
	public static readonly RULE_matchFilterView = 41;
	public static readonly RULE_attributeList = 42;
	public static readonly RULE_allContextAttributes = 43;
	public static readonly RULE_attribute = 44;
	public static readonly RULE_contextAttribute = 45;
	public static readonly RULE_relative = 46;
	public static readonly RULE_dimDriverView = 47;
	public static readonly RULE_submodelView = 48;
	public static readonly RULE_reduceExpressionsOrViews = 49;
	public static readonly RULE_expressionOrView = 50;
	public static readonly RULE_reducerFn = 51;
	public static readonly RULE_reduceableViews = 52;
	public static readonly RULE_timeRange = 53;
	public static readonly RULE_dateRef = 54;
	public static readonly RULE_stringGroup = 55;
	public static readonly RULE_uuidGroup = 56;
	// tslint:disable:no-trailing-whitespace
	public static readonly ruleNames: string[] = [
		"atomicNumber", "impactExpression", "calculator", "blockFilter", "timestampCalculator", 
		"timestampExpression", "stringCalculator", "stringExpression", "timestampValue", 
		"durationValue", "durationUnit", "booleanExpression", "expression", "dateHelpers", 
		"arrayExpression", "sumProduct", "baseIf", "stringIf", "timestampIf", 
		"ifErrorRef", "coalesceRef", "driverRef", "dimDriverRef", "submodelRef", 
		"objectRef", "extObjectRef", "extObjectFieldRef", "objectSpecRef", "matchObjectSpecRef", 
		"objectFieldRef", "extDriverRef", "extQueryRef", "extQueryFilterView", 
		"objectFilterView", "objectFilter", "userAttributeFilter", "builtInAttributeFilter", 
		"attributeFilter", "driverGroupFilter", "submodelFilter", "driverFilterView", 
		"matchFilterView", "attributeList", "allContextAttributes", "attribute", 
		"contextAttribute", "relative", "dimDriverView", "submodelView", "reduceExpressionsOrViews", 
		"expressionOrView", "reducerFn", "reduceableViews", "timeRange", "dateRef", 
		"stringGroup", "uuidGroup",
	];

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined, "'atomicNumber('", "'='", "'objectSpec('", "','", "'endofmonth('", 
		"'startofmonth('", "'asdays'", "'asweeks'", "'asmonths'", "'asyears'", 
		"'&&'", "'||'", "'daysinmonth'", "'datediff'", "'networkdays'", "'sumproduct('", 
		"'if('", "'iferror('", "'coalesce('", "'driver('", "'dimDriver('", "'submodel('", 
		"'object('", "'extObject('", "'extField('", "'field('", "'extDriver('", 
		"'extQuery('", "'filter('", "':'", "'DRIVER_GROUP:'", "'match('", "'attributeList('", 
		"'ALL_CONTEXT_ATTRIBUTES'", "'contextAttribute('", "'sum'", "'count'", 
		"'avg'", "'min'", "'max'", "'relative('", "'range('", "'cohort('", "'relMonths('", 
		"'relQuarters('", "'relYears('", "'\"'", "'|'", undefined, undefined, 
		undefined, undefined, undefined, undefined, "'('", "')'", "'+'", "'-'", 
		"'*'", "'/'", "'^'", undefined, "'ANY'", "'CALENDAR'", "'RELATIVE'", "'COHORTMONTH'", 
		undefined, undefined, undefined, undefined, "'this'", "'linked'",
	];
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		"DATE", "UUID", "NUMBER", "FLOAT", "NUMBER_SUFFIX", "STRING", "LPAREN", 
		"RPAREN", "ADD", "SUB", "MUL", "DIV", "POW", "NULL", "ANY", "CALENDAR", 
		"RELATIVE", "COHORT_MONTH", "ROUND", "ROUNDDOWN", "WS", "BOOLEAN_OPERATOR", 
		"THIS", "LINKED", "INVALID_WORD_CHARS", "INVALID_WORD",
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(ImpactParserParser._LITERAL_NAMES, ImpactParserParser._SYMBOLIC_NAMES, []);

	// @Override
	// @NotNull
	public get vocabulary(): Vocabulary {
		return ImpactParserParser.VOCABULARY;
	}
	// tslint:enable:no-trailing-whitespace

	// @Override
	public get grammarFileName(): string { return "ImpactParser.g4"; }

	// @Override
	public get ruleNames(): string[] { return ImpactParserParser.ruleNames; }

	// @Override
	public get serializedATN(): string { return ImpactParserParser._serializedATN; }

	protected createFailedPredicateException(predicate?: string, message?: string): FailedPredicateException {
		return new FailedPredicateException(this, predicate, message);
	}

	constructor(input: TokenStream) {
		super(input);
		this._interp = new ParserATNSimulator(ImpactParserParser._ATN, this);
	}
	// @RuleVersion(0)
	public atomicNumber(): AtomicNumberContext {
		let _localctx: AtomicNumberContext = new AtomicNumberContext(this._ctx, this.state);
		this.enterRule(_localctx, 0, ImpactParserParser.RULE_atomicNumber);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 114;
			this.match(ImpactParserParser.T__0);
			this.state = 118;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case ImpactParserParser.NUMBER:
				{
				this.state = 115;
				this.match(ImpactParserParser.NUMBER);
				}
				break;
			case ImpactParserParser.SUB:
				{
				this.state = 116;
				this.match(ImpactParserParser.SUB);
				this.state = 117;
				this.match(ImpactParserParser.NUMBER);
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
			this.state = 120;
			this.match(ImpactParserParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public impactExpression(): ImpactExpressionContext {
		let _localctx: ImpactExpressionContext = new ImpactExpressionContext(this._ctx, this.state);
		this.enterRule(_localctx, 2, ImpactParserParser.RULE_impactExpression);
		let _la: number;
		try {
			this.state = 128;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 1, this._ctx) ) {
			case 1:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 122;
				this.atomicNumber();
				}
				break;

			case 2:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 123;
				this.expression(0);
				}
				break;

			case 3:
				this.enterOuterAlt(_localctx, 3);
				{
				this.state = 124;
				this.atomicNumber();
				this.state = 125;
				_la = this._input.LA(1);
				if (!(_la === ImpactParserParser.ADD || _la === ImpactParserParser.SUB)) {
				this._errHandler.recoverInline(this);
				} else {
					if (this._input.LA(1) === Token.EOF) {
						this.matchedEOF = true;
					}

					this._errHandler.reportMatch(this);
					this.consume();
				}
				this.state = 126;
				this.expression(0);
				}
				break;
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public calculator(): CalculatorContext {
		let _localctx: CalculatorContext = new CalculatorContext(this._ctx, this.state);
		this.enterRule(_localctx, 4, ImpactParserParser.RULE_calculator);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 131;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			if (_la === ImpactParserParser.T__1) {
				{
				this.state = 130;
				this.match(ImpactParserParser.T__1);
				}
			}

			this.state = 133;
			this.impactExpression();
			this.state = 134;
			this.match(ImpactParserParser.EOF);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public blockFilter(): BlockFilterContext {
		let _localctx: BlockFilterContext = new BlockFilterContext(this._ctx, this.state);
		this.enterRule(_localctx, 6, ImpactParserParser.RULE_blockFilter);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 136;
			this.match(ImpactParserParser.T__2);
			this.state = 137;
			this.match(ImpactParserParser.UUID);
			this.state = 140;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			if (_la === ImpactParserParser.T__3) {
				{
				this.state = 138;
				this.match(ImpactParserParser.T__3);
				this.state = 139;
				this.objectFilterView();
				}
			}

			this.state = 142;
			this.match(ImpactParserParser.RPAREN);
			this.state = 143;
			this.match(ImpactParserParser.EOF);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public timestampCalculator(): TimestampCalculatorContext {
		let _localctx: TimestampCalculatorContext = new TimestampCalculatorContext(this._ctx, this.state);
		this.enterRule(_localctx, 8, ImpactParserParser.RULE_timestampCalculator);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 146;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			if (_la === ImpactParserParser.T__1) {
				{
				this.state = 145;
				this.match(ImpactParserParser.T__1);
				}
			}

			this.state = 148;
			this.timestampExpression(0);
			this.state = 149;
			this.match(ImpactParserParser.EOF);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}

	public timestampExpression(): TimestampExpressionContext;
	public timestampExpression(_p: number): TimestampExpressionContext;
	// @RuleVersion(0)
	public timestampExpression(_p?: number): TimestampExpressionContext {
		if (_p === undefined) {
			_p = 0;
		}

		let _parentctx: ParserRuleContext = this._ctx;
		let _parentState: number = this.state;
		let _localctx: TimestampExpressionContext = new TimestampExpressionContext(this._ctx, _parentState);
		let _prevctx: TimestampExpressionContext = _localctx;
		let _startState: number = 10;
		this.enterRecursionRule(_localctx, 10, ImpactParserParser.RULE_timestampExpression, _p);
		let _la: number;
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 162;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case ImpactParserParser.T__22:
			case ImpactParserParser.T__23:
			case ImpactParserParser.T__40:
			case ImpactParserParser.T__41:
			case ImpactParserParser.T__42:
			case ImpactParserParser.STRING:
				{
				_localctx = new SingleTimestampContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;

				this.state = 152;
				this.timestampValue();
				}
				break;
			case ImpactParserParser.T__4:
				{
				_localctx = new EndOfMonthContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 153;
				this.match(ImpactParserParser.T__4);
				this.state = 154;
				this.timestampExpression(0);
				this.state = 155;
				this.match(ImpactParserParser.RPAREN);
				}
				break;
			case ImpactParserParser.T__5:
				{
				_localctx = new StartOfMonthContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 157;
				this.match(ImpactParserParser.T__5);
				this.state = 158;
				this.timestampExpression(0);
				this.state = 159;
				this.match(ImpactParserParser.RPAREN);
				}
				break;
			case ImpactParserParser.T__16:
				{
				_localctx = new TimestampConditionalContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 161;
				this.timestampIf();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
			this._ctx._stop = this._input.tryLT(-1);
			this.state = 172;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 7, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					if (this._parseListeners != null) {
						this.triggerExitRuleEvent();
					}
					_prevctx = _localctx;
					{
					this.state = 170;
					this._errHandler.sync(this);
					switch ( this.interpreter.adaptivePredict(this._input, 6, this._ctx) ) {
					case 1:
						{
						_localctx = new AddSubTimestampContext(new TimestampExpressionContext(_parentctx, _parentState));
						this.pushNewRecursionContext(_localctx, _startState, ImpactParserParser.RULE_timestampExpression);
						this.state = 164;
						if (!(this.precpred(this._ctx, 5))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 5)");
						}
						this.state = 165;
						_la = this._input.LA(1);
						if (!(_la === ImpactParserParser.ADD || _la === ImpactParserParser.SUB)) {
						this._errHandler.recoverInline(this);
						} else {
							if (this._input.LA(1) === Token.EOF) {
								this.matchedEOF = true;
							}

							this._errHandler.reportMatch(this);
							this.consume();
						}
						this.state = 166;
						this.expression(0);
						}
						break;

					case 2:
						{
						_localctx = new AddSubDurationContext(new TimestampExpressionContext(_parentctx, _parentState));
						this.pushNewRecursionContext(_localctx, _startState, ImpactParserParser.RULE_timestampExpression);
						this.state = 167;
						if (!(this.precpred(this._ctx, 4))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 4)");
						}
						this.state = 168;
						_la = this._input.LA(1);
						if (!(_la === ImpactParserParser.ADD || _la === ImpactParserParser.SUB)) {
						this._errHandler.recoverInline(this);
						} else {
							if (this._input.LA(1) === Token.EOF) {
								this.matchedEOF = true;
							}

							this._errHandler.reportMatch(this);
							this.consume();
						}
						this.state = 169;
						this.durationValue();
						}
						break;
					}
					}
				}
				this.state = 174;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 7, this._ctx);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.unrollRecursionContexts(_parentctx);
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public stringCalculator(): StringCalculatorContext {
		let _localctx: StringCalculatorContext = new StringCalculatorContext(this._ctx, this.state);
		this.enterRule(_localctx, 12, ImpactParserParser.RULE_stringCalculator);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 176;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			if (_la === ImpactParserParser.T__1) {
				{
				this.state = 175;
				this.match(ImpactParserParser.T__1);
				}
			}

			this.state = 178;
			this.stringExpression();
			this.state = 179;
			this.match(ImpactParserParser.EOF);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public stringExpression(): StringExpressionContext {
		let _localctx: StringExpressionContext = new StringExpressionContext(this._ctx, this.state);
		this.enterRule(_localctx, 14, ImpactParserParser.RULE_stringExpression);
		try {
			this.state = 185;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case ImpactParserParser.T__22:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 181;
				this.objectRef();
				}
				break;
			case ImpactParserParser.T__23:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 182;
				this.extObjectRef();
				}
				break;
			case ImpactParserParser.STRING:
				this.enterOuterAlt(_localctx, 3);
				{
				this.state = 183;
				this.match(ImpactParserParser.STRING);
				}
				break;
			case ImpactParserParser.T__16:
				this.enterOuterAlt(_localctx, 4);
				{
				this.state = 184;
				this.stringIf();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public timestampValue(): TimestampValueContext {
		let _localctx: TimestampValueContext = new TimestampValueContext(this._ctx, this.state);
		this.enterRule(_localctx, 16, ImpactParserParser.RULE_timestampValue);
		try {
			this.state = 191;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case ImpactParserParser.T__22:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 187;
				this.objectRef();
				}
				break;
			case ImpactParserParser.T__23:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 188;
				this.extObjectRef();
				}
				break;
			case ImpactParserParser.T__40:
			case ImpactParserParser.T__41:
			case ImpactParserParser.T__42:
				this.enterOuterAlt(_localctx, 3);
				{
				this.state = 189;
				this.timeRange();
				}
				break;
			case ImpactParserParser.STRING:
				this.enterOuterAlt(_localctx, 4);
				{
				this.state = 190;
				this.match(ImpactParserParser.STRING);
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public durationValue(): DurationValueContext {
		let _localctx: DurationValueContext = new DurationValueContext(this._ctx, this.state);
		this.enterRule(_localctx, 18, ImpactParserParser.RULE_durationValue);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 193;
			this.durationUnit();
			this.state = 194;
			this.match(ImpactParserParser.LPAREN);
			this.state = 195;
			this.expression(0);
			this.state = 196;
			this.match(ImpactParserParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public durationUnit(): DurationUnitContext {
		let _localctx: DurationUnitContext = new DurationUnitContext(this._ctx, this.state);
		this.enterRule(_localctx, 20, ImpactParserParser.RULE_durationUnit);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 198;
			_la = this._input.LA(1);
			if (!((((_la) & ~0x1F) === 0 && ((1 << _la) & ((1 << ImpactParserParser.T__6) | (1 << ImpactParserParser.T__7) | (1 << ImpactParserParser.T__8) | (1 << ImpactParserParser.T__9))) !== 0))) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}

	public booleanExpression(): BooleanExpressionContext;
	public booleanExpression(_p: number): BooleanExpressionContext;
	// @RuleVersion(0)
	public booleanExpression(_p?: number): BooleanExpressionContext {
		if (_p === undefined) {
			_p = 0;
		}

		let _parentctx: ParserRuleContext = this._ctx;
		let _parentState: number = this.state;
		let _localctx: BooleanExpressionContext = new BooleanExpressionContext(this._ctx, _parentState);
		let _prevctx: BooleanExpressionContext = _localctx;
		let _startState: number = 22;
		this.enterRecursionRule(_localctx, 22, ImpactParserParser.RULE_booleanExpression, _p);
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 213;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 11, this._ctx) ) {
			case 1:
				{
				_localctx = new SimpleExpressionContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;

				this.state = 201;
				this.expression(0);
				this.state = 202;
				this.match(ImpactParserParser.BOOLEAN_OPERATOR);
				this.state = 203;
				this.expression(0);
				}
				break;

			case 2:
				{
				_localctx = new TimestampBooleanExpressionContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 205;
				this.timestampExpression(0);
				this.state = 206;
				this.match(ImpactParserParser.BOOLEAN_OPERATOR);
				this.state = 207;
				this.timestampExpression(0);
				}
				break;

			case 3:
				{
				_localctx = new StringBooleanExpressionContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 209;
				this.stringExpression();
				this.state = 210;
				this.match(ImpactParserParser.BOOLEAN_OPERATOR);
				this.state = 211;
				this.stringExpression();
				}
				break;
			}
			this._ctx._stop = this._input.tryLT(-1);
			this.state = 223;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 13, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					if (this._parseListeners != null) {
						this.triggerExitRuleEvent();
					}
					_prevctx = _localctx;
					{
					this.state = 221;
					this._errHandler.sync(this);
					switch ( this.interpreter.adaptivePredict(this._input, 12, this._ctx) ) {
					case 1:
						{
						_localctx = new AndExpressionContext(new BooleanExpressionContext(_parentctx, _parentState));
						this.pushNewRecursionContext(_localctx, _startState, ImpactParserParser.RULE_booleanExpression);
						this.state = 215;
						if (!(this.precpred(this._ctx, 4))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 4)");
						}
						this.state = 216;
						this.match(ImpactParserParser.T__10);
						this.state = 217;
						this.booleanExpression(5);
						}
						break;

					case 2:
						{
						_localctx = new OrExpressionContext(new BooleanExpressionContext(_parentctx, _parentState));
						this.pushNewRecursionContext(_localctx, _startState, ImpactParserParser.RULE_booleanExpression);
						this.state = 218;
						if (!(this.precpred(this._ctx, 3))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 3)");
						}
						this.state = 219;
						this.match(ImpactParserParser.T__11);
						this.state = 220;
						this.booleanExpression(4);
						}
						break;
					}
					}
				}
				this.state = 225;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 13, this._ctx);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.unrollRecursionContexts(_parentctx);
		}
		return _localctx;
	}

	public expression(): ExpressionContext;
	public expression(_p: number): ExpressionContext;
	// @RuleVersion(0)
	public expression(_p?: number): ExpressionContext {
		if (_p === undefined) {
			_p = 0;
		}

		let _parentctx: ParserRuleContext = this._ctx;
		let _parentState: number = this.state;
		let _localctx: ExpressionContext = new ExpressionContext(this._ctx, _parentState);
		let _prevctx: ExpressionContext = _localctx;
		let _startState: number = 24;
		this.enterRecursionRule(_localctx, 24, ImpactParserParser.RULE_expression, _p);
		let _la: number;
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 269;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 15, this._ctx) ) {
			case 1:
				{
				_localctx = new NumberContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;

				this.state = 227;
				this.match(ImpactParserParser.NUMBER);
				}
				break;

			case 2:
				{
				_localctx = new NullContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 228;
				this.match(ImpactParserParser.NULL);
				}
				break;

			case 3:
				{
				_localctx = new DriverContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 229;
				this.driverRef();
				}
				break;

			case 4:
				{
				_localctx = new ExtDriverContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 230;
				this.extDriverRef();
				}
				break;

			case 5:
				{
				_localctx = new ReducerContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 231;
				this.reduceExpressionsOrViews();
				}
				break;

			case 6:
				{
				_localctx = new AddProductContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 232;
				this.sumProduct();
				}
				break;

			case 7:
				{
				_localctx = new MinusContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 233;
				this.match(ImpactParserParser.SUB);
				this.state = 234;
				this.expression(17);
				}
				break;

			case 8:
				{
				_localctx = new ParenthesisContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 235;
				this.match(ImpactParserParser.LPAREN);
				this.state = 236;
				this.expression(0);
				this.state = 237;
				this.match(ImpactParserParser.RPAREN);
				}
				break;

			case 9:
				{
				_localctx = new RoundContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 239;
				this.match(ImpactParserParser.ROUND);
				this.state = 240;
				this.match(ImpactParserParser.LPAREN);
				this.state = 241;
				this.expression(0);
				this.state = 242;
				this.match(ImpactParserParser.RPAREN);
				}
				break;

			case 10:
				{
				_localctx = new RoundDownContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 244;
				this.match(ImpactParserParser.ROUNDDOWN);
				this.state = 245;
				this.match(ImpactParserParser.LPAREN);
				this.state = 246;
				this.expression(0);
				this.state = 247;
				this.match(ImpactParserParser.RPAREN);
				}
				break;

			case 11:
				{
				_localctx = new RoundPlacesContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 249;
				this.match(ImpactParserParser.ROUND);
				this.state = 250;
				this.match(ImpactParserParser.LPAREN);
				this.state = 251;
				this.expression(0);
				this.state = 252;
				this.match(ImpactParserParser.T__3);
				this.state = 253;
				this.expression(0);
				this.state = 254;
				this.match(ImpactParserParser.RPAREN);
				}
				break;

			case 12:
				{
				_localctx = new ConditionalContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 256;
				this.baseIf();
				}
				break;

			case 13:
				{
				_localctx = new IfErrorContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 257;
				this.ifErrorRef();
				}
				break;

			case 14:
				{
				_localctx = new CoalesceContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 258;
				this.coalesceRef();
				}
				break;

			case 15:
				{
				_localctx = new ObjectContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 259;
				this.objectRef();
				}
				break;

			case 16:
				{
				_localctx = new ExtObjectContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 260;
				this.extObjectRef();
				}
				break;

			case 17:
				{
				_localctx = new DimDriverFilteredContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 261;
				this.driverFilterView();
				}
				break;

			case 18:
				{
				_localctx = new MatchFilterContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 262;
				this.matchFilterView();
				}
				break;

			case 19:
				{
				_localctx = new DateExpressionContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 263;
				this.dateHelpers();
				}
				break;

			case 20:
				{
				_localctx = new InvalidExpressionContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 265;
				this._errHandler.sync(this);
				_alt = 1;
				do {
					switch (_alt) {
					case 1:
						{
						{
						this.state = 264;
						this.match(ImpactParserParser.INVALID_WORD);
						}
						}
						break;
					default:
						throw new NoViableAltException(this);
					}
					this.state = 267;
					this._errHandler.sync(this);
					_alt = this.interpreter.adaptivePredict(this._input, 14, this._ctx);
				} while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER);
				}
				break;
			}
			this._ctx._stop = this._input.tryLT(-1);
			this.state = 282;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 17, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					if (this._parseListeners != null) {
						this.triggerExitRuleEvent();
					}
					_prevctx = _localctx;
					{
					this.state = 280;
					this._errHandler.sync(this);
					switch ( this.interpreter.adaptivePredict(this._input, 16, this._ctx) ) {
					case 1:
						{
						_localctx = new PowContext(new ExpressionContext(_parentctx, _parentState));
						this.pushNewRecursionContext(_localctx, _startState, ImpactParserParser.RULE_expression);
						this.state = 271;
						if (!(this.precpred(this._ctx, 15))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 15)");
						}
						this.state = 272;
						this.match(ImpactParserParser.POW);
						this.state = 273;
						this.expression(16);
						}
						break;

					case 2:
						{
						_localctx = new MulDivContext(new ExpressionContext(_parentctx, _parentState));
						this.pushNewRecursionContext(_localctx, _startState, ImpactParserParser.RULE_expression);
						this.state = 274;
						if (!(this.precpred(this._ctx, 14))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 14)");
						}
						this.state = 275;
						_la = this._input.LA(1);
						if (!(_la === ImpactParserParser.MUL || _la === ImpactParserParser.DIV)) {
						this._errHandler.recoverInline(this);
						} else {
							if (this._input.LA(1) === Token.EOF) {
								this.matchedEOF = true;
							}

							this._errHandler.reportMatch(this);
							this.consume();
						}
						this.state = 276;
						this.expression(15);
						}
						break;

					case 3:
						{
						_localctx = new AddSubContext(new ExpressionContext(_parentctx, _parentState));
						this.pushNewRecursionContext(_localctx, _startState, ImpactParserParser.RULE_expression);
						this.state = 277;
						if (!(this.precpred(this._ctx, 13))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 13)");
						}
						this.state = 278;
						_la = this._input.LA(1);
						if (!(_la === ImpactParserParser.ADD || _la === ImpactParserParser.SUB)) {
						this._errHandler.recoverInline(this);
						} else {
							if (this._input.LA(1) === Token.EOF) {
								this.matchedEOF = true;
							}

							this._errHandler.reportMatch(this);
							this.consume();
						}
						this.state = 279;
						this.expression(14);
						}
						break;
					}
					}
				}
				this.state = 284;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 17, this._ctx);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.unrollRecursionContexts(_parentctx);
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public dateHelpers(): DateHelpersContext {
		let _localctx: DateHelpersContext = new DateHelpersContext(this._ctx, this.state);
		this.enterRule(_localctx, 26, ImpactParserParser.RULE_dateHelpers);
		let _la: number;
		try {
			this.state = 312;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case ImpactParserParser.T__12:
				_localctx = new DaysInMonthContext(_localctx);
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 285;
				this.match(ImpactParserParser.T__12);
				this.state = 286;
				this.match(ImpactParserParser.LPAREN);
				this.state = 287;
				this.match(ImpactParserParser.RPAREN);
				}
				break;
			case ImpactParserParser.T__13:
				_localctx = new DateDiffContext(_localctx);
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 288;
				this.match(ImpactParserParser.T__13);
				this.state = 289;
				this.match(ImpactParserParser.LPAREN);
				this.state = 290;
				this.timestampExpression(0);
				this.state = 291;
				this.match(ImpactParserParser.T__3);
				this.state = 292;
				this.timestampExpression(0);
				this.state = 293;
				this.match(ImpactParserParser.T__3);
				this.state = 294;
				this.match(ImpactParserParser.STRING);
				this.state = 295;
				this.match(ImpactParserParser.RPAREN);
				}
				break;
			case ImpactParserParser.T__14:
				_localctx = new NetWorkDaysContext(_localctx);
				this.enterOuterAlt(_localctx, 3);
				{
				this.state = 297;
				this.match(ImpactParserParser.T__14);
				this.state = 298;
				this.match(ImpactParserParser.LPAREN);
				this.state = 299;
				this.timestampExpression(0);
				this.state = 300;
				this.match(ImpactParserParser.T__3);
				this.state = 301;
				this.timestampExpression(0);
				this.state = 304;
				this._errHandler.sync(this);
				switch ( this.interpreter.adaptivePredict(this._input, 18, this._ctx) ) {
				case 1:
					{
					this.state = 302;
					this.match(ImpactParserParser.T__3);
					this.state = 303;
					this.match(ImpactParserParser.STRING);
					}
					break;
				}
				this.state = 308;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la === ImpactParserParser.T__3) {
					{
					this.state = 306;
					this.match(ImpactParserParser.T__3);
					this.state = 307;
					this.match(ImpactParserParser.STRING);
					}
				}

				this.state = 310;
				this.match(ImpactParserParser.RPAREN);
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}

	public arrayExpression(): ArrayExpressionContext;
	public arrayExpression(_p: number): ArrayExpressionContext;
	// @RuleVersion(0)
	public arrayExpression(_p?: number): ArrayExpressionContext {
		if (_p === undefined) {
			_p = 0;
		}

		let _parentctx: ParserRuleContext = this._ctx;
		let _parentState: number = this.state;
		let _localctx: ArrayExpressionContext = new ArrayExpressionContext(this._ctx, _parentState);
		let _prevctx: ArrayExpressionContext = _localctx;
		let _startState: number = 28;
		this.enterRecursionRule(_localctx, 28, ImpactParserParser.RULE_arrayExpression, _p);
		let _la: number;
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 319;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case ImpactParserParser.T__2:
				{
				this.state = 315;
				this.objectSpecRef();
				}
				break;
			case ImpactParserParser.NUMBER:
				{
				this.state = 316;
				this.match(ImpactParserParser.NUMBER);
				this.state = 317;
				_la = this._input.LA(1);
				if (!(_la === ImpactParserParser.ADD || _la === ImpactParserParser.SUB)) {
				this._errHandler.recoverInline(this);
				} else {
					if (this._input.LA(1) === Token.EOF) {
						this.matchedEOF = true;
					}

					this._errHandler.reportMatch(this);
					this.consume();
				}
				this.state = 318;
				this.arrayExpression(1);
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
			this._ctx._stop = this._input.tryLT(-1);
			this.state = 326;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 22, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					if (this._parseListeners != null) {
						this.triggerExitRuleEvent();
					}
					_prevctx = _localctx;
					{
					{
					_localctx = new ArrayExpressionContext(_parentctx, _parentState);
					this.pushNewRecursionContext(_localctx, _startState, ImpactParserParser.RULE_arrayExpression);
					this.state = 321;
					if (!(this.precpred(this._ctx, 2))) {
						throw this.createFailedPredicateException("this.precpred(this._ctx, 2)");
					}
					this.state = 322;
					_la = this._input.LA(1);
					if (!(_la === ImpactParserParser.ADD || _la === ImpactParserParser.SUB)) {
					this._errHandler.recoverInline(this);
					} else {
						if (this._input.LA(1) === Token.EOF) {
							this.matchedEOF = true;
						}

						this._errHandler.reportMatch(this);
						this.consume();
					}
					this.state = 323;
					this.match(ImpactParserParser.NUMBER);
					}
					}
				}
				this.state = 328;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 22, this._ctx);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.unrollRecursionContexts(_parentctx);
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public sumProduct(): SumProductContext {
		let _localctx: SumProductContext = new SumProductContext(this._ctx, this.state);
		this.enterRule(_localctx, 30, ImpactParserParser.RULE_sumProduct);
		try {
			this.state = 359;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 23, this._ctx) ) {
			case 1:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 329;
				this.match(ImpactParserParser.T__15);
				this.state = 330;
				this.driverRef();
				this.state = 331;
				this.match(ImpactParserParser.T__3);
				this.state = 332;
				this.driverRef();
				this.state = 333;
				this.match(ImpactParserParser.RPAREN);
				}
				break;

			case 2:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 335;
				this.match(ImpactParserParser.T__15);
				this.state = 336;
				this.dimDriverRef();
				this.state = 337;
				this.match(ImpactParserParser.T__3);
				this.state = 338;
				this.dimDriverRef();
				this.state = 339;
				this.match(ImpactParserParser.RPAREN);
				}
				break;

			case 3:
				this.enterOuterAlt(_localctx, 3);
				{
				this.state = 341;
				this.match(ImpactParserParser.T__15);
				this.state = 342;
				this.dimDriverRef();
				this.state = 343;
				this.match(ImpactParserParser.T__3);
				this.state = 344;
				this.driverRef();
				this.state = 345;
				this.match(ImpactParserParser.RPAREN);
				}
				break;

			case 4:
				this.enterOuterAlt(_localctx, 4);
				{
				this.state = 347;
				this.match(ImpactParserParser.T__15);
				this.state = 348;
				this.driverRef();
				this.state = 349;
				this.match(ImpactParserParser.T__3);
				this.state = 350;
				this.dimDriverRef();
				this.state = 351;
				this.match(ImpactParserParser.RPAREN);
				}
				break;

			case 5:
				this.enterOuterAlt(_localctx, 5);
				{
				this.state = 353;
				this.match(ImpactParserParser.T__15);
				this.state = 354;
				this.arrayExpression(0);
				this.state = 355;
				this.match(ImpactParserParser.T__3);
				this.state = 356;
				this.arrayExpression(0);
				this.state = 357;
				this.match(ImpactParserParser.RPAREN);
				}
				break;
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public baseIf(): BaseIfContext {
		let _localctx: BaseIfContext = new BaseIfContext(this._ctx, this.state);
		this.enterRule(_localctx, 32, ImpactParserParser.RULE_baseIf);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 361;
			this.match(ImpactParserParser.T__16);
			this.state = 362;
			this.booleanExpression(0);
			this.state = 363;
			this.match(ImpactParserParser.T__3);
			this.state = 364;
			this.expression(0);
			this.state = 365;
			this.match(ImpactParserParser.T__3);
			this.state = 366;
			this.expression(0);
			this.state = 367;
			this.match(ImpactParserParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public stringIf(): StringIfContext {
		let _localctx: StringIfContext = new StringIfContext(this._ctx, this.state);
		this.enterRule(_localctx, 34, ImpactParserParser.RULE_stringIf);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 369;
			this.match(ImpactParserParser.T__16);
			this.state = 370;
			this.booleanExpression(0);
			this.state = 371;
			this.match(ImpactParserParser.T__3);
			this.state = 372;
			this.stringExpression();
			this.state = 373;
			this.match(ImpactParserParser.T__3);
			this.state = 374;
			this.stringExpression();
			this.state = 375;
			this.match(ImpactParserParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public timestampIf(): TimestampIfContext {
		let _localctx: TimestampIfContext = new TimestampIfContext(this._ctx, this.state);
		this.enterRule(_localctx, 36, ImpactParserParser.RULE_timestampIf);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 377;
			this.match(ImpactParserParser.T__16);
			this.state = 378;
			this.booleanExpression(0);
			this.state = 379;
			this.match(ImpactParserParser.T__3);
			this.state = 380;
			this.timestampExpression(0);
			this.state = 381;
			this.match(ImpactParserParser.T__3);
			this.state = 382;
			this.timestampExpression(0);
			this.state = 383;
			this.match(ImpactParserParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public ifErrorRef(): IfErrorRefContext {
		let _localctx: IfErrorRefContext = new IfErrorRefContext(this._ctx, this.state);
		this.enterRule(_localctx, 38, ImpactParserParser.RULE_ifErrorRef);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 385;
			this.match(ImpactParserParser.T__17);
			this.state = 386;
			this.expression(0);
			this.state = 387;
			this.match(ImpactParserParser.T__3);
			this.state = 388;
			this.expression(0);
			this.state = 389;
			this.match(ImpactParserParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public coalesceRef(): CoalesceRefContext {
		let _localctx: CoalesceRefContext = new CoalesceRefContext(this._ctx, this.state);
		this.enterRule(_localctx, 40, ImpactParserParser.RULE_coalesceRef);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 391;
			this.match(ImpactParserParser.T__18);
			this.state = 392;
			this.expression(0);
			this.state = 397;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la === ImpactParserParser.T__3) {
				{
				{
				this.state = 393;
				this.match(ImpactParserParser.T__3);
				this.state = 394;
				this.expression(0);
				}
				}
				this.state = 399;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			this.state = 400;
			this.match(ImpactParserParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public driverRef(): DriverRefContext {
		let _localctx: DriverRefContext = new DriverRefContext(this._ctx, this.state);
		this.enterRule(_localctx, 42, ImpactParserParser.RULE_driverRef);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 402;
			this.match(ImpactParserParser.T__19);
			this.state = 403;
			this.match(ImpactParserParser.UUID);
			this.state = 404;
			this.match(ImpactParserParser.T__3);
			this.state = 405;
			this.timeRange();
			this.state = 406;
			this.match(ImpactParserParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public dimDriverRef(): DimDriverRefContext {
		let _localctx: DimDriverRefContext = new DimDriverRefContext(this._ctx, this.state);
		this.enterRule(_localctx, 44, ImpactParserParser.RULE_dimDriverRef);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 408;
			this.match(ImpactParserParser.T__20);
			this.state = 409;
			this.match(ImpactParserParser.UUID);
			this.state = 410;
			this.match(ImpactParserParser.T__3);
			this.state = 411;
			this.timeRange();
			this.state = 412;
			this.match(ImpactParserParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public submodelRef(): SubmodelRefContext {
		let _localctx: SubmodelRefContext = new SubmodelRefContext(this._ctx, this.state);
		this.enterRule(_localctx, 46, ImpactParserParser.RULE_submodelRef);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 414;
			this.match(ImpactParserParser.T__21);
			this.state = 415;
			this.match(ImpactParserParser.UUID);
			this.state = 418;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			if (_la === ImpactParserParser.T__3) {
				{
				this.state = 416;
				this.match(ImpactParserParser.T__3);
				this.state = 417;
				this.timeRange();
				}
			}

			this.state = 420;
			this.match(ImpactParserParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public objectRef(): ObjectRefContext {
		let _localctx: ObjectRefContext = new ObjectRefContext(this._ctx, this.state);
		this.enterRule(_localctx, 48, ImpactParserParser.RULE_objectRef);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 422;
			this.match(ImpactParserParser.T__22);
			this.state = 423;
			_la = this._input.LA(1);
			if (!(_la === ImpactParserParser.UUID || _la === ImpactParserParser.THIS)) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			this.state = 424;
			this.match(ImpactParserParser.T__3);
			this.state = 425;
			this.objectFieldRef();
			this.state = 428;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			if (_la === ImpactParserParser.T__3) {
				{
				this.state = 426;
				this.match(ImpactParserParser.T__3);
				this.state = 427;
				this.objectFilterView();
				}
			}

			this.state = 430;
			this.match(ImpactParserParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public extObjectRef(): ExtObjectRefContext {
		let _localctx: ExtObjectRefContext = new ExtObjectRefContext(this._ctx, this.state);
		this.enterRule(_localctx, 50, ImpactParserParser.RULE_extObjectRef);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 432;
			this.match(ImpactParserParser.T__23);
			{
			this.state = 433;
			this.match(ImpactParserParser.LINKED);
			}
			this.state = 434;
			this.match(ImpactParserParser.T__3);
			this.state = 435;
			this.extObjectFieldRef();
			this.state = 436;
			this.match(ImpactParserParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public extObjectFieldRef(): ExtObjectFieldRefContext {
		let _localctx: ExtObjectFieldRefContext = new ExtObjectFieldRefContext(this._ctx, this.state);
		this.enterRule(_localctx, 52, ImpactParserParser.RULE_extObjectFieldRef);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 438;
			this.match(ImpactParserParser.T__24);
			{
			this.state = 439;
			this.match(ImpactParserParser.LINKED);
			}
			this.state = 440;
			this.match(ImpactParserParser.T__3);
			this.state = 441;
			this.timeRange();
			this.state = 442;
			this.match(ImpactParserParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public objectSpecRef(): ObjectSpecRefContext {
		let _localctx: ObjectSpecRefContext = new ObjectSpecRefContext(this._ctx, this.state);
		this.enterRule(_localctx, 54, ImpactParserParser.RULE_objectSpecRef);
		let _la: number;
		try {
			this.state = 464;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 29, this._ctx) ) {
			case 1:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 444;
				this.match(ImpactParserParser.T__2);
				this.state = 445;
				this.match(ImpactParserParser.UUID);
				this.state = 446;
				this.match(ImpactParserParser.T__3);
				this.state = 447;
				this.objectFieldRef();
				this.state = 450;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la === ImpactParserParser.T__3) {
					{
					this.state = 448;
					this.match(ImpactParserParser.T__3);
					this.state = 449;
					this.objectFilterView();
					}
				}

				this.state = 452;
				this.match(ImpactParserParser.RPAREN);
				}
				break;

			case 2:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 454;
				this.match(ImpactParserParser.T__2);
				this.state = 455;
				this.match(ImpactParserParser.UUID);
				this.state = 456;
				this.match(ImpactParserParser.T__3);
				this.state = 457;
				this.timeRange();
				this.state = 460;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la === ImpactParserParser.T__3) {
					{
					this.state = 458;
					this.match(ImpactParserParser.T__3);
					this.state = 459;
					this.objectFilterView();
					}
				}

				this.state = 462;
				this.match(ImpactParserParser.RPAREN);
				}
				break;
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public matchObjectSpecRef(): MatchObjectSpecRefContext {
		let _localctx: MatchObjectSpecRefContext = new MatchObjectSpecRefContext(this._ctx, this.state);
		this.enterRule(_localctx, 56, ImpactParserParser.RULE_matchObjectSpecRef);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 466;
			this.match(ImpactParserParser.T__2);
			this.state = 467;
			this.match(ImpactParserParser.UUID);
			this.state = 468;
			this.match(ImpactParserParser.T__3);
			this.state = 469;
			this.objectFieldRef();
			this.state = 470;
			this.match(ImpactParserParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public objectFieldRef(): ObjectFieldRefContext {
		let _localctx: ObjectFieldRefContext = new ObjectFieldRefContext(this._ctx, this.state);
		this.enterRule(_localctx, 58, ImpactParserParser.RULE_objectFieldRef);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 472;
			this.match(ImpactParserParser.T__25);
			this.state = 473;
			this.match(ImpactParserParser.UUID);
			this.state = 474;
			this.match(ImpactParserParser.T__3);
			this.state = 475;
			this.timeRange();
			this.state = 476;
			this.match(ImpactParserParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public extDriverRef(): ExtDriverRefContext {
		let _localctx: ExtDriverRefContext = new ExtDriverRefContext(this._ctx, this.state);
		this.enterRule(_localctx, 60, ImpactParserParser.RULE_extDriverRef);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 478;
			this.match(ImpactParserParser.T__26);
			this.state = 479;
			this.match(ImpactParserParser.UUID);
			this.state = 480;
			this.match(ImpactParserParser.T__3);
			this.state = 481;
			this.timeRange();
			this.state = 482;
			this.match(ImpactParserParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public extQueryRef(): ExtQueryRefContext {
		let _localctx: ExtQueryRefContext = new ExtQueryRefContext(this._ctx, this.state);
		this.enterRule(_localctx, 62, ImpactParserParser.RULE_extQueryRef);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 484;
			this.match(ImpactParserParser.T__27);
			this.state = 485;
			this.match(ImpactParserParser.UUID);
			this.state = 486;
			this.match(ImpactParserParser.T__3);
			this.state = 487;
			this.timeRange();
			this.state = 490;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			if (_la === ImpactParserParser.T__3) {
				{
				this.state = 488;
				this.match(ImpactParserParser.T__3);
				this.state = 489;
				this.extQueryFilterView();
				}
			}

			this.state = 492;
			this.match(ImpactParserParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public extQueryFilterView(): ExtQueryFilterViewContext {
		let _localctx: ExtQueryFilterViewContext = new ExtQueryFilterViewContext(this._ctx, this.state);
		this.enterRule(_localctx, 64, ImpactParserParser.RULE_extQueryFilterView);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 494;
			this.match(ImpactParserParser.T__28);
			this.state = 496;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			do {
				{
				{
				this.state = 495;
				this.attributeFilter();
				}
				}
				this.state = 498;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			} while (((((_la - 50)) & ~0x1F) === 0 && ((1 << (_la - 50)) & ((1 << (ImpactParserParser.UUID - 50)) | (1 << (ImpactParserParser.CALENDAR - 50)) | (1 << (ImpactParserParser.RELATIVE - 50)))) !== 0));
			this.state = 500;
			this.match(ImpactParserParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public objectFilterView(): ObjectFilterViewContext {
		let _localctx: ObjectFilterViewContext = new ObjectFilterViewContext(this._ctx, this.state);
		this.enterRule(_localctx, 66, ImpactParserParser.RULE_objectFilterView);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 502;
			this.match(ImpactParserParser.T__28);
			this.state = 503;
			this.objectFilter();
			this.state = 508;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la === ImpactParserParser.T__10) {
				{
				{
				this.state = 504;
				this.match(ImpactParserParser.T__10);
				this.state = 505;
				this.objectFilter();
				}
				}
				this.state = 510;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			this.state = 511;
			this.match(ImpactParserParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public objectFilter(): ObjectFilterContext {
		let _localctx: ObjectFilterContext = new ObjectFilterContext(this._ctx, this.state);
		this.enterRule(_localctx, 68, ImpactParserParser.RULE_objectFilter);
		let _la: number;
		try {
			this.state = 530;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case ImpactParserParser.UUID:
				_localctx = new ObjectUUIDFilterContext(_localctx);
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 513;
				this.match(ImpactParserParser.UUID);
				this.state = 518;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				while (_la === ImpactParserParser.T__3) {
					{
					{
					this.state = 514;
					this.match(ImpactParserParser.T__3);
					this.state = 515;
					this.match(ImpactParserParser.UUID);
					}
					}
					this.state = 520;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
				}
				}
				break;
			case ImpactParserParser.T__25:
				_localctx = new ObjectFieldFilterContext(_localctx);
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 521;
				this.objectFieldRef();
				this.state = 522;
				this.match(ImpactParserParser.BOOLEAN_OPERATOR);
				this.state = 528;
				this._errHandler.sync(this);
				switch ( this.interpreter.adaptivePredict(this._input, 34, this._ctx) ) {
				case 1:
					{
					this.state = 523;
					this.stringGroup();
					}
					break;

				case 2:
					{
					this.state = 524;
					this.uuidGroup();
					}
					break;

				case 3:
					{
					this.state = 525;
					this.timeRange();
					}
					break;

				case 4:
					{
					this.state = 526;
					this.match(ImpactParserParser.UUID);
					}
					break;

				case 5:
					{
					this.state = 527;
					this.match(ImpactParserParser.NULL);
					}
					break;
				}
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public userAttributeFilter(): UserAttributeFilterContext {
		let _localctx: UserAttributeFilterContext = new UserAttributeFilterContext(this._ctx, this.state);
		this.enterRule(_localctx, 70, ImpactParserParser.RULE_userAttributeFilter);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 532;
			this.match(ImpactParserParser.UUID);
			this.state = 533;
			this.match(ImpactParserParser.T__29);
			this.state = 534;
			_la = this._input.LA(1);
			if (!(((((_la - 50)) & ~0x1F) === 0 && ((1 << (_la - 50)) & ((1 << (ImpactParserParser.UUID - 50)) | (1 << (ImpactParserParser.NULL - 50)) | (1 << (ImpactParserParser.ANY - 50)))) !== 0))) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public builtInAttributeFilter(): BuiltInAttributeFilterContext {
		let _localctx: BuiltInAttributeFilterContext = new BuiltInAttributeFilterContext(this._ctx, this.state);
		this.enterRule(_localctx, 72, ImpactParserParser.RULE_builtInAttributeFilter);
		let _la: number;
		try {
			this.state = 542;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case ImpactParserParser.CALENDAR:
				_localctx = new CalendarFilterContext(_localctx);
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 536;
				this.match(ImpactParserParser.CALENDAR);
				this.state = 537;
				this.match(ImpactParserParser.T__29);
				this.state = 538;
				_la = this._input.LA(1);
				if (!(((((_la - 49)) & ~0x1F) === 0 && ((1 << (_la - 49)) & ((1 << (ImpactParserParser.DATE - 49)) | (1 << (ImpactParserParser.NULL - 49)) | (1 << (ImpactParserParser.ANY - 49)))) !== 0))) {
				this._errHandler.recoverInline(this);
				} else {
					if (this._input.LA(1) === Token.EOF) {
						this.matchedEOF = true;
					}

					this._errHandler.reportMatch(this);
					this.consume();
				}
				}
				break;
			case ImpactParserParser.RELATIVE:
				_localctx = new RelativeFilterContext(_localctx);
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 539;
				this.match(ImpactParserParser.RELATIVE);
				this.state = 540;
				this.match(ImpactParserParser.T__29);
				this.state = 541;
				_la = this._input.LA(1);
				if (!(((((_la - 51)) & ~0x1F) === 0 && ((1 << (_la - 51)) & ((1 << (ImpactParserParser.NUMBER - 51)) | (1 << (ImpactParserParser.NULL - 51)) | (1 << (ImpactParserParser.ANY - 51)) | (1 << (ImpactParserParser.COHORT_MONTH - 51)))) !== 0))) {
				this._errHandler.recoverInline(this);
				} else {
					if (this._input.LA(1) === Token.EOF) {
						this.matchedEOF = true;
					}

					this._errHandler.reportMatch(this);
					this.consume();
				}
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public attributeFilter(): AttributeFilterContext {
		let _localctx: AttributeFilterContext = new AttributeFilterContext(this._ctx, this.state);
		this.enterRule(_localctx, 74, ImpactParserParser.RULE_attributeFilter);
		try {
			this.state = 546;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case ImpactParserParser.UUID:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 544;
				this.userAttributeFilter();
				}
				break;
			case ImpactParserParser.CALENDAR:
			case ImpactParserParser.RELATIVE:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 545;
				this.builtInAttributeFilter();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public driverGroupFilter(): DriverGroupFilterContext {
		let _localctx: DriverGroupFilterContext = new DriverGroupFilterContext(this._ctx, this.state);
		this.enterRule(_localctx, 76, ImpactParserParser.RULE_driverGroupFilter);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 548;
			this.match(ImpactParserParser.T__30);
			this.state = 549;
			this.match(ImpactParserParser.UUID);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public submodelFilter(): SubmodelFilterContext {
		let _localctx: SubmodelFilterContext = new SubmodelFilterContext(this._ctx, this.state);
		this.enterRule(_localctx, 78, ImpactParserParser.RULE_submodelFilter);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 551;
			this.driverGroupFilter();
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public driverFilterView(): DriverFilterViewContext {
		let _localctx: DriverFilterViewContext = new DriverFilterViewContext(this._ctx, this.state);
		this.enterRule(_localctx, 80, ImpactParserParser.RULE_driverFilterView);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 553;
			this.match(ImpactParserParser.T__28);
			this.state = 554;
			this.dimDriverView();
			this.state = 555;
			this.match(ImpactParserParser.T__3);
			{
			this.state = 557;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			do {
				{
				{
				this.state = 556;
				this.attributeFilter();
				}
				}
				this.state = 559;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			} while (((((_la - 50)) & ~0x1F) === 0 && ((1 << (_la - 50)) & ((1 << (ImpactParserParser.UUID - 50)) | (1 << (ImpactParserParser.CALENDAR - 50)) | (1 << (ImpactParserParser.RELATIVE - 50)))) !== 0));
			}
			this.state = 561;
			this.match(ImpactParserParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public matchFilterView(): MatchFilterViewContext {
		let _localctx: MatchFilterViewContext = new MatchFilterViewContext(this._ctx, this.state);
		this.enterRule(_localctx, 82, ImpactParserParser.RULE_matchFilterView);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 563;
			this.match(ImpactParserParser.T__31);
			this.state = 566;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case ImpactParserParser.T__20:
				{
				this.state = 564;
				this.dimDriverRef();
				}
				break;
			case ImpactParserParser.T__2:
				{
				this.state = 565;
				this.matchObjectSpecRef();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
			this.state = 568;
			this.match(ImpactParserParser.T__3);
			this.state = 569;
			this.attributeList();
			this.state = 570;
			this.match(ImpactParserParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public attributeList(): AttributeListContext {
		let _localctx: AttributeListContext = new AttributeListContext(this._ctx, this.state);
		this.enterRule(_localctx, 84, ImpactParserParser.RULE_attributeList);
		let _la: number;
		try {
			let _alt: number;
			this.state = 591;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 42, this._ctx) ) {
			case 1:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 572;
				this.match(ImpactParserParser.T__32);
				this.state = 573;
				this.attribute();
				this.state = 578;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 40, this._ctx);
				while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
					if (_alt === 1) {
						{
						{
						this.state = 574;
						this.match(ImpactParserParser.T__3);
						this.state = 575;
						this.attribute();
						}
						}
					}
					this.state = 580;
					this._errHandler.sync(this);
					_alt = this.interpreter.adaptivePredict(this._input, 40, this._ctx);
				}
				this.state = 583;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la === ImpactParserParser.T__3) {
					{
					this.state = 581;
					this.match(ImpactParserParser.T__3);
					this.state = 582;
					this.allContextAttributes();
					}
				}

				this.state = 585;
				this.match(ImpactParserParser.RPAREN);
				}
				break;

			case 2:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 587;
				this.match(ImpactParserParser.T__32);
				this.state = 588;
				this.allContextAttributes();
				this.state = 589;
				this.match(ImpactParserParser.RPAREN);
				}
				break;
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public allContextAttributes(): AllContextAttributesContext {
		let _localctx: AllContextAttributesContext = new AllContextAttributesContext(this._ctx, this.state);
		this.enterRule(_localctx, 86, ImpactParserParser.RULE_allContextAttributes);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 593;
			this.match(ImpactParserParser.T__33);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public attribute(): AttributeContext {
		let _localctx: AttributeContext = new AttributeContext(this._ctx, this.state);
		this.enterRule(_localctx, 88, ImpactParserParser.RULE_attribute);
		try {
			this.state = 597;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case ImpactParserParser.T__34:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 595;
				this.contextAttribute();
				}
				break;
			case ImpactParserParser.UUID:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 596;
				this.match(ImpactParserParser.UUID);
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public contextAttribute(): ContextAttributeContext {
		let _localctx: ContextAttributeContext = new ContextAttributeContext(this._ctx, this.state);
		this.enterRule(_localctx, 90, ImpactParserParser.RULE_contextAttribute);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 599;
			this.match(ImpactParserParser.T__34);
			this.state = 600;
			this.match(ImpactParserParser.UUID);
			this.state = 601;
			this.match(ImpactParserParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public relative(): RelativeContext {
		let _localctx: RelativeContext = new RelativeContext(this._ctx, this.state);
		this.enterRule(_localctx, 92, ImpactParserParser.RULE_relative);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 603;
			this.match(ImpactParserParser.T__28);
			this.state = 604;
			this.dimDriverView();
			this.state = 605;
			this.match(ImpactParserParser.T__3);
			this.state = 607;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			do {
				{
				{
				this.state = 606;
				this.attributeFilter();
				}
				}
				this.state = 609;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			} while (((((_la - 50)) & ~0x1F) === 0 && ((1 << (_la - 50)) & ((1 << (ImpactParserParser.UUID - 50)) | (1 << (ImpactParserParser.CALENDAR - 50)) | (1 << (ImpactParserParser.RELATIVE - 50)))) !== 0));
			this.state = 611;
			this.match(ImpactParserParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public dimDriverView(): DimDriverViewContext {
		let _localctx: DimDriverViewContext = new DimDriverViewContext(this._ctx, this.state);
		this.enterRule(_localctx, 94, ImpactParserParser.RULE_dimDriverView);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 613;
			this.dimDriverRef();
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public submodelView(): SubmodelViewContext {
		let _localctx: SubmodelViewContext = new SubmodelViewContext(this._ctx, this.state);
		this.enterRule(_localctx, 96, ImpactParserParser.RULE_submodelView);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 615;
			this.match(ImpactParserParser.T__28);
			this.state = 616;
			this.submodelRef();
			this.state = 617;
			this.match(ImpactParserParser.T__3);
			this.state = 618;
			this.submodelFilter();
			this.state = 619;
			this.match(ImpactParserParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public reduceExpressionsOrViews(): ReduceExpressionsOrViewsContext {
		let _localctx: ReduceExpressionsOrViewsContext = new ReduceExpressionsOrViewsContext(this._ctx, this.state);
		this.enterRule(_localctx, 98, ImpactParserParser.RULE_reduceExpressionsOrViews);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 621;
			this.reducerFn();
			this.state = 622;
			this.match(ImpactParserParser.LPAREN);
			this.state = 623;
			this.expressionOrView();
			this.state = 628;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la === ImpactParserParser.T__3) {
				{
				{
				this.state = 624;
				this.match(ImpactParserParser.T__3);
				this.state = 625;
				this.expressionOrView();
				}
				}
				this.state = 630;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			this.state = 631;
			this.match(ImpactParserParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public expressionOrView(): ExpressionOrViewContext {
		let _localctx: ExpressionOrViewContext = new ExpressionOrViewContext(this._ctx, this.state);
		this.enterRule(_localctx, 100, ImpactParserParser.RULE_expressionOrView);
		try {
			this.state = 635;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 46, this._ctx) ) {
			case 1:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 633;
				this.expression(0);
				}
				break;

			case 2:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 634;
				this.reduceableViews();
				}
				break;
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public reducerFn(): ReducerFnContext {
		let _localctx: ReducerFnContext = new ReducerFnContext(this._ctx, this.state);
		this.enterRule(_localctx, 102, ImpactParserParser.RULE_reducerFn);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 637;
			_la = this._input.LA(1);
			if (!(((((_la - 36)) & ~0x1F) === 0 && ((1 << (_la - 36)) & ((1 << (ImpactParserParser.T__35 - 36)) | (1 << (ImpactParserParser.T__36 - 36)) | (1 << (ImpactParserParser.T__37 - 36)) | (1 << (ImpactParserParser.T__38 - 36)) | (1 << (ImpactParserParser.T__39 - 36)))) !== 0))) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public reduceableViews(): ReduceableViewsContext {
		let _localctx: ReduceableViewsContext = new ReduceableViewsContext(this._ctx, this.state);
		this.enterRule(_localctx, 104, ImpactParserParser.RULE_reduceableViews);
		try {
			this.state = 644;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 47, this._ctx) ) {
			case 1:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 639;
				this.dimDriverView();
				}
				break;

			case 2:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 640;
				this.driverFilterView();
				}
				break;

			case 3:
				this.enterOuterAlt(_localctx, 3);
				{
				this.state = 641;
				this.objectSpecRef();
				}
				break;

			case 4:
				this.enterOuterAlt(_localctx, 4);
				{
				this.state = 642;
				this.submodelView();
				}
				break;

			case 5:
				this.enterOuterAlt(_localctx, 5);
				{
				this.state = 643;
				this.extQueryRef();
				}
				break;
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public timeRange(): TimeRangeContext {
		let _localctx: TimeRangeContext = new TimeRangeContext(this._ctx, this.state);
		this.enterRule(_localctx, 106, ImpactParserParser.RULE_timeRange);
		try {
			this.state = 664;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case ImpactParserParser.T__40:
				_localctx = new VariableRelativeTimeContext(_localctx);
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 646;
				this.match(ImpactParserParser.T__40);
				this.state = 647;
				this.expression(0);
				this.state = 648;
				this.match(ImpactParserParser.T__3);
				this.state = 649;
				this.expression(0);
				this.state = 650;
				this.match(ImpactParserParser.RPAREN);
				}
				break;
			case ImpactParserParser.T__41:
				_localctx = new DateRangeContext(_localctx);
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 652;
				this.match(ImpactParserParser.T__41);
				this.state = 653;
				this.dateRef();
				this.state = 654;
				this.match(ImpactParserParser.T__3);
				this.state = 655;
				this.dateRef();
				this.state = 656;
				this.match(ImpactParserParser.RPAREN);
				}
				break;
			case ImpactParserParser.T__42:
				_localctx = new CohortRelativeTimeContext(_localctx);
				this.enterOuterAlt(_localctx, 3);
				{
				this.state = 658;
				this.match(ImpactParserParser.T__42);
				this.state = 659;
				this.expression(0);
				this.state = 660;
				this.match(ImpactParserParser.T__3);
				this.state = 661;
				this.expression(0);
				this.state = 662;
				this.match(ImpactParserParser.RPAREN);
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public dateRef(): DateRefContext {
		let _localctx: DateRefContext = new DateRefContext(this._ctx, this.state);
		this.enterRule(_localctx, 108, ImpactParserParser.RULE_dateRef);
		try {
			this.state = 688;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case ImpactParserParser.DATE:
				_localctx = new DateContext(_localctx);
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 666;
				this.match(ImpactParserParser.DATE);
				}
				break;
			case ImpactParserParser.T__43:
				_localctx = new DateRelativeMonthsContext(_localctx);
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 667;
				this.match(ImpactParserParser.T__43);
				this.state = 671;
				this._errHandler.sync(this);
				switch (this._input.LA(1)) {
				case ImpactParserParser.NUMBER:
					{
					this.state = 668;
					this.match(ImpactParserParser.NUMBER);
					}
					break;
				case ImpactParserParser.SUB:
					{
					this.state = 669;
					this.match(ImpactParserParser.SUB);
					this.state = 670;
					this.match(ImpactParserParser.NUMBER);
					}
					break;
				default:
					throw new NoViableAltException(this);
				}
				this.state = 673;
				this.match(ImpactParserParser.RPAREN);
				}
				break;
			case ImpactParserParser.T__44:
				_localctx = new DateRelativeQuartersContext(_localctx);
				this.enterOuterAlt(_localctx, 3);
				{
				this.state = 674;
				this.match(ImpactParserParser.T__44);
				this.state = 678;
				this._errHandler.sync(this);
				switch (this._input.LA(1)) {
				case ImpactParserParser.NUMBER:
					{
					this.state = 675;
					this.match(ImpactParserParser.NUMBER);
					}
					break;
				case ImpactParserParser.SUB:
					{
					this.state = 676;
					this.match(ImpactParserParser.SUB);
					this.state = 677;
					this.match(ImpactParserParser.NUMBER);
					}
					break;
				default:
					throw new NoViableAltException(this);
				}
				this.state = 680;
				this.match(ImpactParserParser.RPAREN);
				}
				break;
			case ImpactParserParser.T__45:
				_localctx = new DateRelativeYearsContext(_localctx);
				this.enterOuterAlt(_localctx, 4);
				{
				this.state = 681;
				this.match(ImpactParserParser.T__45);
				this.state = 685;
				this._errHandler.sync(this);
				switch (this._input.LA(1)) {
				case ImpactParserParser.NUMBER:
					{
					this.state = 682;
					this.match(ImpactParserParser.NUMBER);
					}
					break;
				case ImpactParserParser.SUB:
					{
					this.state = 683;
					this.match(ImpactParserParser.SUB);
					this.state = 684;
					this.match(ImpactParserParser.NUMBER);
					}
					break;
				default:
					throw new NoViableAltException(this);
				}
				this.state = 687;
				this.match(ImpactParserParser.RPAREN);
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public stringGroup(): StringGroupContext {
		let _localctx: StringGroupContext = new StringGroupContext(this._ctx, this.state);
		this.enterRule(_localctx, 110, ImpactParserParser.RULE_stringGroup);
		let _la: number;
		try {
			this.state = 701;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case ImpactParserParser.T__46:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 690;
				this.match(ImpactParserParser.T__46);
				this.state = 691;
				this.match(ImpactParserParser.STRING);
				this.state = 696;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				while (_la === ImpactParserParser.T__47) {
					{
					{
					this.state = 692;
					this.match(ImpactParserParser.T__47);
					this.state = 693;
					this.match(ImpactParserParser.STRING);
					}
					}
					this.state = 698;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
				}
				this.state = 699;
				this.match(ImpactParserParser.T__46);
				}
				break;
			case ImpactParserParser.STRING:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 700;
				this.match(ImpactParserParser.STRING);
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public uuidGroup(): UuidGroupContext {
		let _localctx: UuidGroupContext = new UuidGroupContext(this._ctx, this.state);
		this.enterRule(_localctx, 112, ImpactParserParser.RULE_uuidGroup);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 703;
			this.match(ImpactParserParser.UUID);
			this.state = 708;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la === ImpactParserParser.T__47) {
				{
				{
				this.state = 704;
				this.match(ImpactParserParser.T__47);
				this.state = 705;
				this.match(ImpactParserParser.UUID);
				}
				}
				this.state = 710;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}

	public sempred(_localctx: RuleContext, ruleIndex: number, predIndex: number): boolean {
		switch (ruleIndex) {
		case 5:
			return this.timestampExpression_sempred(_localctx as TimestampExpressionContext, predIndex);

		case 11:
			return this.booleanExpression_sempred(_localctx as BooleanExpressionContext, predIndex);

		case 12:
			return this.expression_sempred(_localctx as ExpressionContext, predIndex);

		case 14:
			return this.arrayExpression_sempred(_localctx as ArrayExpressionContext, predIndex);
		}
		return true;
	}
	private timestampExpression_sempred(_localctx: TimestampExpressionContext, predIndex: number): boolean {
		switch (predIndex) {
		case 0:
			return this.precpred(this._ctx, 5);

		case 1:
			return this.precpred(this._ctx, 4);
		}
		return true;
	}
	private booleanExpression_sempred(_localctx: BooleanExpressionContext, predIndex: number): boolean {
		switch (predIndex) {
		case 2:
			return this.precpred(this._ctx, 4);

		case 3:
			return this.precpred(this._ctx, 3);
		}
		return true;
	}
	private expression_sempred(_localctx: ExpressionContext, predIndex: number): boolean {
		switch (predIndex) {
		case 4:
			return this.precpred(this._ctx, 15);

		case 5:
			return this.precpred(this._ctx, 14);

		case 6:
			return this.precpred(this._ctx, 13);
		}
		return true;
	}
	private arrayExpression_sempred(_localctx: ArrayExpressionContext, predIndex: number): boolean {
		switch (predIndex) {
		case 7:
			return this.precpred(this._ctx, 2);
		}
		return true;
	}

	private static readonly _serializedATNSegments: number = 2;
	private static readonly _serializedATNSegment0: string =
		"\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x03L\u02CA\x04\x02" +
		"\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06\x04\x07" +
		"\t\x07\x04\b\t\b\x04\t\t\t\x04\n\t\n\x04\v\t\v\x04\f\t\f\x04\r\t\r\x04" +
		"\x0E\t\x0E\x04\x0F\t\x0F\x04\x10\t\x10\x04\x11\t\x11\x04\x12\t\x12\x04" +
		"\x13\t\x13\x04\x14\t\x14\x04\x15\t\x15\x04\x16\t\x16\x04\x17\t\x17\x04" +
		"\x18\t\x18\x04\x19\t\x19\x04\x1A\t\x1A\x04\x1B\t\x1B\x04\x1C\t\x1C\x04" +
		"\x1D\t\x1D\x04\x1E\t\x1E\x04\x1F\t\x1F\x04 \t \x04!\t!\x04\"\t\"\x04#" +
		"\t#\x04$\t$\x04%\t%\x04&\t&\x04\'\t\'\x04(\t(\x04)\t)\x04*\t*\x04+\t+" +
		"\x04,\t,\x04-\t-\x04.\t.\x04/\t/\x040\t0\x041\t1\x042\t2\x043\t3\x044" +
		"\t4\x045\t5\x046\t6\x047\t7\x048\t8\x049\t9\x04:\t:\x03\x02\x03\x02\x03" +
		"\x02\x03\x02\x05\x02y\n\x02\x03\x02\x03\x02\x03\x03\x03\x03\x03\x03\x03" +
		"\x03\x03\x03\x03\x03\x05\x03\x83\n\x03\x03\x04\x05\x04\x86\n\x04\x03\x04" +
		"\x03\x04\x03\x04\x03\x05\x03\x05\x03\x05\x03\x05\x05\x05\x8F\n\x05\x03" +
		"\x05\x03\x05\x03\x05\x03\x06\x05\x06\x95\n\x06\x03\x06\x03\x06\x03\x06" +
		"\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07" +
		"\x03\x07\x03\x07\x05\x07\xA5\n\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03" +
		"\x07\x03\x07\x07\x07\xAD\n\x07\f\x07\x0E\x07\xB0\v\x07\x03\b\x05\b\xB3" +
		"\n\b\x03\b\x03\b\x03\b\x03\t\x03\t\x03\t\x03\t\x05\t\xBC\n\t\x03\n\x03" +
		"\n\x03\n\x03\n\x05\n\xC2\n\n\x03\v\x03\v\x03\v\x03\v\x03\v\x03\f\x03\f" +
		"\x03\r\x03\r\x03\r\x03\r\x03\r\x03\r\x03\r\x03\r\x03\r\x03\r\x03\r\x03" +
		"\r\x03\r\x05\r\xD8\n\r\x03\r\x03\r\x03\r\x03\r\x03\r\x03\r\x07\r\xE0\n" +
		"\r\f\r\x0E\r\xE3\v\r\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x03" +
		"\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x03" +
		"\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x03" +
		"\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x03" +
		"\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x06\x0E\u010C\n\x0E\r\x0E" +
		"\x0E\x0E\u010D\x05\x0E\u0110\n\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x03" +
		"\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x07\x0E\u011B\n\x0E\f\x0E\x0E\x0E" +
		"\u011E\v\x0E\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03" +
		"\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03" +
		"\x0F\x03\x0F\x03\x0F\x05\x0F\u0133\n\x0F\x03\x0F\x03\x0F\x05\x0F\u0137" +
		"\n\x0F\x03\x0F\x03\x0F\x05\x0F\u013B\n\x0F\x03\x10\x03\x10\x03\x10\x03" +
		"\x10\x03\x10\x05\x10\u0142\n\x10\x03\x10\x03\x10\x03\x10\x07\x10\u0147" +
		"\n\x10\f\x10\x0E\x10\u014A\v\x10\x03\x11\x03\x11\x03\x11\x03\x11\x03\x11" +
		"\x03\x11\x03\x11\x03\x11\x03\x11\x03\x11\x03\x11\x03\x11\x03\x11\x03\x11" +
		"\x03\x11\x03\x11\x03\x11\x03\x11\x03\x11\x03\x11\x03\x11\x03\x11\x03\x11" +
		"\x03\x11\x03\x11\x03\x11\x03\x11\x03\x11\x03\x11\x03\x11\x05\x11\u016A" +
		"\n\x11\x03\x12\x03\x12\x03\x12\x03\x12\x03\x12\x03\x12\x03\x12\x03\x12" +
		"\x03\x13\x03\x13\x03\x13\x03\x13\x03\x13\x03\x13\x03\x13\x03\x13\x03\x14" +
		"\x03\x14\x03\x14\x03\x14\x03\x14\x03\x14\x03\x14\x03\x14\x03\x15\x03\x15" +
		"\x03\x15\x03\x15\x03\x15\x03\x15\x03\x16\x03\x16\x03\x16\x03\x16\x07\x16" +
		"\u018E\n\x16\f\x16\x0E\x16\u0191\v\x16\x03\x16\x03\x16\x03\x17\x03\x17" +
		"\x03\x17\x03\x17\x03\x17\x03\x17\x03\x18\x03\x18\x03\x18\x03\x18\x03\x18" +
		"\x03\x18\x03\x19\x03\x19\x03\x19\x03\x19\x05\x19\u01A5\n\x19\x03\x19\x03" +
		"\x19\x03\x1A\x03\x1A\x03\x1A\x03\x1A\x03\x1A\x03\x1A\x05\x1A\u01AF\n\x1A" +
		"\x03\x1A\x03\x1A\x03\x1B\x03\x1B\x03\x1B\x03\x1B\x03\x1B\x03\x1B\x03\x1C" +
		"\x03\x1C\x03\x1C\x03\x1C\x03\x1C\x03\x1C\x03\x1D\x03\x1D\x03\x1D\x03\x1D" +
		"\x03\x1D\x03\x1D\x05\x1D\u01C5\n\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03" +
		"\x1D\x03\x1D\x03\x1D\x03\x1D\x05\x1D\u01CF\n\x1D\x03\x1D\x03\x1D\x05\x1D" +
		"\u01D3\n\x1D\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1F\x03" +
		"\x1F\x03\x1F\x03\x1F\x03\x1F\x03\x1F\x03 \x03 \x03 \x03 \x03 \x03 \x03" +
		"!\x03!\x03!\x03!\x03!\x03!\x05!\u01ED\n!\x03!\x03!\x03\"\x03\"\x06\"\u01F3" +
		"\n\"\r\"\x0E\"\u01F4\x03\"\x03\"\x03#\x03#\x03#\x03#\x07#\u01FD\n#\f#" +
		"\x0E#\u0200\v#\x03#\x03#\x03$\x03$\x03$\x07$\u0207\n$\f$\x0E$\u020A\v" +
		"$\x03$\x03$\x03$\x03$\x03$\x03$\x03$\x05$\u0213\n$\x05$\u0215\n$\x03%" +
		"\x03%\x03%\x03%\x03&\x03&\x03&\x03&\x03&\x03&\x05&\u0221\n&\x03\'\x03" +
		"\'\x05\'\u0225\n\'\x03(\x03(\x03(\x03)\x03)\x03*\x03*\x03*\x03*\x06*\u0230" +
		"\n*\r*\x0E*\u0231\x03*\x03*\x03+\x03+\x03+\x05+\u0239\n+\x03+\x03+\x03" +
		"+\x03+\x03,\x03,\x03,\x03,\x07,\u0243\n,\f,\x0E,\u0246\v,\x03,\x03,\x05" +
		",\u024A\n,\x03,\x03,\x03,\x03,\x03,\x03,\x05,\u0252\n,\x03-\x03-\x03." +
		"\x03.\x05.\u0258\n.\x03/\x03/\x03/\x03/\x030\x030\x030\x030\x060\u0262" +
		"\n0\r0\x0E0\u0263\x030\x030\x031\x031\x032\x032\x032\x032\x032\x032\x03" +
		"3\x033\x033\x033\x033\x073\u0275\n3\f3\x0E3\u0278\v3\x033\x033\x034\x03" +
		"4\x054\u027E\n4\x035\x035\x036\x036\x036\x036\x036\x056\u0287\n6\x037" +
		"\x037\x037\x037\x037\x037\x037\x037\x037\x037\x037\x037\x037\x037\x03" +
		"7\x037\x037\x037\x057\u029B\n7\x038\x038\x038\x038\x038\x058\u02A2\n8" +
		"\x038\x038\x038\x038\x038\x058\u02A9\n8\x038\x038\x038\x038\x038\x058" +
		"\u02B0\n8\x038\x058\u02B3\n8\x039\x039\x039\x039\x079\u02B9\n9\f9\x0E" +
		"9\u02BC\v9\x039\x039\x059\u02C0\n9\x03:\x03:\x03:\x07:\u02C5\n:\f:\x0E" +
		":\u02C8\v:\x03:\x02\x02\x06\f\x18\x1A\x1E;\x02\x02\x04\x02\x06\x02\b\x02" +
		"\n\x02\f\x02\x0E\x02\x10\x02\x12\x02\x14\x02\x16\x02\x18\x02\x1A\x02\x1C" +
		"\x02\x1E\x02 \x02\"\x02$\x02&\x02(\x02*\x02,\x02.\x020\x022\x024\x026" +
		"\x028\x02:\x02<\x02>\x02@\x02B\x02D\x02F\x02H\x02J\x02L\x02N\x02P\x02" +
		"R\x02T\x02V\x02X\x02Z\x02\\\x02^\x02`\x02b\x02d\x02f\x02h\x02j\x02l\x02" +
		"n\x02p\x02r\x02\x02\n\x03\x02;<\x03\x02\t\f\x03\x02=>\x04\x0244II\x04" +
		"\x0244@A\x04\x0233@A\x05\x0255@ADD\x03\x02&*\x02\u02F0\x02t\x03\x02\x02" +
		"\x02\x04\x82\x03\x02\x02\x02\x06\x85\x03\x02\x02\x02\b\x8A\x03\x02\x02" +
		"\x02\n\x94\x03\x02\x02\x02\f\xA4\x03\x02\x02\x02\x0E\xB2\x03\x02\x02\x02" +
		"\x10\xBB\x03\x02\x02\x02\x12\xC1\x03\x02\x02\x02\x14\xC3\x03\x02\x02\x02" +
		"\x16\xC8\x03\x02\x02\x02\x18\xD7\x03\x02\x02\x02\x1A\u010F\x03\x02\x02" +
		"\x02\x1C\u013A\x03\x02\x02\x02\x1E\u0141\x03\x02\x02\x02 \u0169\x03\x02" +
		"\x02\x02\"\u016B\x03\x02\x02\x02$\u0173\x03\x02\x02\x02&\u017B\x03\x02" +
		"\x02\x02(\u0183\x03\x02\x02\x02*\u0189\x03\x02\x02\x02,\u0194\x03\x02" +
		"\x02\x02.\u019A\x03\x02\x02\x020\u01A0\x03\x02\x02\x022\u01A8\x03\x02" +
		"\x02\x024\u01B2\x03\x02\x02\x026\u01B8\x03\x02\x02\x028\u01D2\x03\x02" +
		"\x02\x02:\u01D4\x03\x02\x02\x02<\u01DA\x03\x02\x02\x02>\u01E0\x03\x02" +
		"\x02\x02@\u01E6\x03\x02\x02\x02B\u01F0\x03\x02\x02\x02D\u01F8\x03\x02" +
		"\x02\x02F\u0214\x03\x02\x02\x02H\u0216\x03\x02\x02\x02J\u0220\x03\x02" +
		"\x02\x02L\u0224\x03\x02\x02\x02N\u0226\x03\x02\x02\x02P\u0229\x03\x02" +
		"\x02\x02R\u022B\x03\x02\x02\x02T\u0235\x03\x02\x02\x02V\u0251\x03\x02" +
		"\x02\x02X\u0253\x03\x02\x02\x02Z\u0257\x03\x02\x02\x02\\\u0259\x03\x02" +
		"\x02\x02^\u025D\x03\x02\x02\x02`\u0267\x03\x02\x02\x02b\u0269\x03\x02" +
		"\x02\x02d\u026F\x03\x02\x02\x02f\u027D\x03\x02\x02\x02h\u027F\x03\x02" +
		"\x02\x02j\u0286\x03\x02\x02\x02l\u029A\x03\x02\x02\x02n\u02B2\x03\x02" +
		"\x02\x02p\u02BF\x03\x02\x02\x02r\u02C1\x03\x02\x02\x02tx\x07\x03\x02\x02" +
		"uy\x075\x02\x02vw\x07<\x02\x02wy\x075\x02\x02xu\x03\x02\x02\x02xv\x03" +
		"\x02\x02\x02yz\x03\x02\x02\x02z{\x07:\x02\x02{\x03\x03\x02\x02\x02|\x83" +
		"\x05\x02\x02\x02}\x83\x05\x1A\x0E\x02~\x7F\x05\x02\x02\x02\x7F\x80\t\x02" +
		"\x02\x02\x80\x81\x05\x1A\x0E\x02\x81\x83\x03\x02\x02\x02\x82|\x03\x02" +
		"\x02\x02\x82}\x03\x02\x02\x02\x82~\x03\x02\x02\x02\x83\x05\x03\x02\x02" +
		"\x02\x84\x86\x07\x04\x02\x02\x85\x84\x03\x02\x02\x02\x85\x86\x03\x02\x02" +
		"\x02\x86\x87\x03\x02\x02\x02\x87\x88\x05\x04\x03\x02\x88\x89\x07\x02\x02" +
		"\x03\x89\x07\x03\x02\x02\x02\x8A\x8B\x07\x05\x02\x02\x8B\x8E\x074\x02" +
		"\x02\x8C\x8D\x07\x06\x02\x02\x8D\x8F\x05D#\x02\x8E\x8C\x03\x02\x02\x02" +
		"\x8E\x8F\x03\x02\x02\x02\x8F\x90\x03\x02\x02\x02\x90\x91\x07:\x02\x02" +
		"\x91\x92\x07\x02\x02\x03\x92\t\x03\x02\x02\x02\x93\x95\x07\x04\x02\x02" +
		"\x94\x93\x03\x02\x02\x02\x94\x95\x03\x02\x02\x02\x95\x96\x03\x02\x02\x02" +
		"\x96\x97\x05\f\x07\x02\x97\x98\x07\x02\x02\x03\x98\v\x03\x02\x02\x02\x99" +
		"\x9A\b\x07\x01\x02\x9A\xA5\x05\x12\n\x02\x9B\x9C\x07\x07\x02\x02\x9C\x9D" +
		"\x05\f\x07\x02\x9D\x9E\x07:\x02\x02\x9E\xA5\x03\x02\x02\x02\x9F\xA0\x07" +
		"\b\x02\x02\xA0\xA1\x05\f\x07\x02\xA1\xA2\x07:\x02\x02\xA2\xA5\x03\x02" +
		"\x02\x02\xA3\xA5\x05&\x14\x02\xA4\x99\x03\x02\x02\x02\xA4\x9B\x03\x02" +
		"\x02\x02\xA4\x9F\x03\x02\x02\x02\xA4\xA3\x03\x02\x02\x02\xA5\xAE\x03\x02" +
		"\x02\x02\xA6\xA7\f\x07\x02\x02\xA7\xA8\t\x02\x02\x02\xA8\xAD\x05\x1A\x0E" +
		"\x02\xA9\xAA\f\x06\x02\x02\xAA\xAB\t\x02\x02\x02\xAB\xAD\x05\x14\v\x02" +
		"\xAC\xA6\x03\x02\x02\x02\xAC\xA9\x03\x02\x02\x02\xAD\xB0\x03\x02\x02\x02" +
		"\xAE\xAC\x03\x02\x02\x02\xAE\xAF\x03\x02\x02\x02\xAF\r\x03\x02\x02\x02" +
		"\xB0\xAE\x03\x02\x02\x02\xB1\xB3\x07\x04\x02\x02\xB2\xB1\x03\x02\x02\x02" +
		"\xB2\xB3\x03\x02\x02\x02\xB3\xB4\x03\x02\x02\x02\xB4\xB5\x05\x10\t\x02" +
		"\xB5\xB6\x07\x02\x02\x03\xB6\x0F\x03\x02\x02\x02\xB7\xBC\x052\x1A\x02" +
		"\xB8\xBC\x054\x1B\x02\xB9\xBC\x078\x02\x02\xBA\xBC\x05$\x13\x02\xBB\xB7" +
		"\x03\x02\x02\x02\xBB\xB8\x03\x02\x02\x02\xBB\xB9\x03\x02\x02\x02\xBB\xBA" +
		"\x03\x02\x02\x02\xBC\x11\x03\x02\x02\x02\xBD\xC2\x052\x1A\x02\xBE\xC2" +
		"\x054\x1B\x02\xBF\xC2\x05l7\x02\xC0\xC2\x078\x02\x02\xC1\xBD\x03\x02\x02" +
		"\x02\xC1\xBE\x03\x02\x02\x02\xC1\xBF\x03\x02\x02\x02\xC1\xC0\x03\x02\x02" +
		"\x02\xC2\x13\x03\x02\x02\x02\xC3\xC4\x05\x16\f\x02\xC4\xC5\x079\x02\x02" +
		"\xC5\xC6\x05\x1A\x0E\x02\xC6\xC7\x07:\x02\x02\xC7\x15\x03\x02\x02\x02" +
		"\xC8\xC9\t\x03\x02\x02\xC9\x17\x03\x02\x02\x02\xCA\xCB\b\r\x01\x02\xCB" +
		"\xCC\x05\x1A\x0E\x02\xCC\xCD\x07H\x02\x02\xCD\xCE\x05\x1A\x0E\x02\xCE" +
		"\xD8\x03\x02\x02\x02\xCF\xD0\x05\f\x07\x02\xD0\xD1\x07H\x02\x02\xD1\xD2" +
		"\x05\f\x07\x02\xD2\xD8\x03\x02\x02\x02\xD3\xD4\x05\x10\t\x02\xD4\xD5\x07" +
		"H\x02\x02\xD5\xD6\x05\x10\t\x02\xD6\xD8\x03\x02\x02\x02\xD7\xCA\x03\x02" +
		"\x02\x02\xD7\xCF\x03\x02\x02\x02\xD7\xD3\x03\x02\x02\x02\xD8\xE1\x03\x02" +
		"\x02\x02\xD9\xDA\f\x06\x02\x02\xDA\xDB\x07\r\x02\x02\xDB\xE0\x05\x18\r" +
		"\x07\xDC\xDD\f\x05\x02\x02\xDD\xDE\x07\x0E\x02\x02\xDE\xE0\x05\x18\r\x06" +
		"\xDF\xD9\x03\x02\x02\x02\xDF\xDC\x03\x02\x02\x02\xE0\xE3\x03\x02\x02\x02" +
		"\xE1\xDF\x03\x02\x02\x02\xE1\xE2\x03\x02\x02\x02\xE2\x19\x03\x02\x02\x02" +
		"\xE3\xE1\x03\x02\x02\x02\xE4\xE5\b\x0E\x01\x02\xE5\u0110\x075\x02\x02" +
		"\xE6\u0110\x07@\x02\x02\xE7\u0110\x05,\x17\x02\xE8\u0110\x05> \x02\xE9" +
		"\u0110\x05d3\x02\xEA\u0110\x05 \x11\x02\xEB\xEC\x07<\x02\x02\xEC\u0110" +
		"\x05\x1A\x0E\x13\xED\xEE\x079\x02\x02\xEE\xEF\x05\x1A\x0E\x02\xEF\xF0" +
		"\x07:\x02\x02\xF0\u0110\x03\x02\x02\x02\xF1\xF2\x07E\x02\x02\xF2\xF3\x07" +
		"9\x02\x02\xF3\xF4\x05\x1A\x0E\x02\xF4\xF5\x07:\x02\x02\xF5\u0110\x03\x02" +
		"\x02\x02\xF6\xF7\x07F\x02\x02\xF7\xF8\x079\x02\x02\xF8\xF9\x05\x1A\x0E" +
		"\x02\xF9\xFA\x07:\x02\x02\xFA\u0110\x03\x02\x02\x02\xFB\xFC\x07E\x02\x02" +
		"\xFC\xFD\x079\x02\x02\xFD\xFE\x05\x1A\x0E\x02\xFE\xFF\x07\x06\x02\x02" +
		"\xFF\u0100\x05\x1A\x0E\x02\u0100\u0101\x07:\x02\x02\u0101\u0110\x03\x02" +
		"\x02\x02\u0102\u0110\x05\"\x12\x02\u0103\u0110\x05(\x15\x02\u0104\u0110" +
		"\x05*\x16\x02\u0105\u0110\x052\x1A\x02\u0106\u0110\x054\x1B\x02\u0107" +
		"\u0110\x05R*\x02\u0108\u0110\x05T+\x02\u0109\u0110\x05\x1C\x0F\x02\u010A" +
		"\u010C\x07L\x02\x02\u010B\u010A\x03\x02\x02\x02\u010C\u010D\x03\x02\x02" +
		"\x02\u010D\u010B\x03\x02\x02\x02\u010D\u010E\x03\x02\x02\x02\u010E\u0110" +
		"\x03\x02\x02\x02\u010F\xE4\x03\x02\x02\x02\u010F\xE6\x03\x02\x02\x02\u010F" +
		"\xE7\x03\x02\x02\x02\u010F\xE8\x03\x02\x02\x02\u010F\xE9\x03\x02\x02\x02" +
		"\u010F\xEA\x03\x02\x02\x02\u010F\xEB\x03\x02\x02\x02\u010F\xED\x03\x02" +
		"\x02\x02\u010F\xF1\x03\x02\x02\x02\u010F\xF6\x03\x02\x02\x02\u010F\xFB" +
		"\x03\x02\x02\x02\u010F\u0102\x03\x02\x02\x02\u010F\u0103\x03\x02\x02\x02" +
		"\u010F\u0104\x03\x02\x02\x02\u010F\u0105\x03\x02\x02\x02\u010F\u0106\x03" +
		"\x02\x02\x02\u010F\u0107\x03\x02\x02\x02\u010F\u0108\x03\x02\x02\x02\u010F" +
		"\u0109\x03\x02\x02\x02\u010F\u010B\x03\x02\x02\x02\u0110\u011C\x03\x02" +
		"\x02\x02\u0111\u0112\f\x11\x02\x02\u0112\u0113\x07?\x02\x02\u0113\u011B" +
		"\x05\x1A\x0E\x12\u0114\u0115\f\x10\x02\x02\u0115\u0116\t\x04\x02\x02\u0116" +
		"\u011B\x05\x1A\x0E\x11\u0117\u0118\f\x0F\x02\x02\u0118\u0119\t\x02\x02" +
		"\x02\u0119\u011B\x05\x1A\x0E\x10\u011A\u0111\x03\x02\x02\x02\u011A\u0114" +
		"\x03\x02\x02\x02\u011A\u0117\x03\x02\x02\x02\u011B\u011E\x03\x02\x02\x02" +
		"\u011C\u011A\x03\x02\x02\x02\u011C\u011D\x03\x02\x02\x02\u011D\x1B\x03" +
		"\x02\x02\x02\u011E\u011C\x03\x02\x02\x02\u011F\u0120\x07\x0F\x02\x02\u0120" +
		"\u0121\x079\x02\x02\u0121\u013B\x07:\x02\x02\u0122\u0123\x07\x10\x02\x02" +
		"\u0123\u0124\x079\x02\x02\u0124\u0125\x05\f\x07\x02\u0125\u0126\x07\x06" +
		"\x02\x02\u0126\u0127\x05\f\x07\x02\u0127\u0128\x07\x06\x02\x02\u0128\u0129" +
		"\x078\x02\x02\u0129\u012A\x07:\x02\x02\u012A\u013B\x03\x02\x02\x02\u012B" +
		"\u012C\x07\x11\x02\x02\u012C\u012D\x079\x02\x02\u012D\u012E\x05\f\x07" +
		"\x02\u012E\u012F\x07\x06\x02\x02\u012F\u0132\x05\f\x07\x02\u0130\u0131" +
		"\x07\x06\x02\x02\u0131\u0133\x078\x02\x02\u0132\u0130\x03\x02\x02\x02" +
		"\u0132\u0133\x03\x02\x02\x02\u0133\u0136\x03\x02\x02\x02\u0134\u0135\x07" +
		"\x06\x02\x02\u0135\u0137\x078\x02\x02\u0136\u0134\x03\x02\x02\x02\u0136" +
		"\u0137\x03\x02\x02\x02\u0137\u0138\x03\x02\x02\x02\u0138\u0139\x07:\x02" +
		"\x02\u0139\u013B\x03\x02\x02\x02\u013A\u011F\x03\x02\x02\x02\u013A\u0122" +
		"\x03\x02\x02\x02\u013A\u012B\x03\x02\x02\x02\u013B\x1D\x03\x02\x02\x02" +
		"\u013C\u013D\b\x10\x01\x02\u013D\u0142\x058\x1D\x02\u013E\u013F\x075\x02" +
		"\x02\u013F\u0140\t\x02\x02\x02\u0140\u0142\x05\x1E\x10\x03\u0141\u013C" +
		"\x03\x02\x02\x02\u0141\u013E\x03\x02\x02\x02\u0142\u0148\x03\x02\x02\x02" +
		"\u0143\u0144\f\x04\x02\x02\u0144\u0145\t\x02\x02\x02\u0145\u0147\x075" +
		"\x02\x02\u0146\u0143\x03\x02\x02\x02\u0147\u014A\x03\x02\x02\x02\u0148" +
		"\u0146\x03\x02\x02\x02\u0148\u0149\x03\x02\x02\x02\u0149\x1F\x03\x02\x02" +
		"\x02\u014A\u0148\x03\x02\x02\x02\u014B\u014C\x07\x12\x02\x02\u014C\u014D" +
		"\x05,\x17\x02\u014D\u014E\x07\x06\x02\x02\u014E\u014F\x05,\x17\x02\u014F" +
		"\u0150\x07:\x02\x02\u0150\u016A\x03\x02\x02\x02\u0151\u0152\x07\x12\x02" +
		"\x02\u0152\u0153\x05.\x18\x02\u0153\u0154\x07\x06\x02\x02\u0154\u0155" +
		"\x05.\x18\x02\u0155\u0156\x07:\x02\x02\u0156\u016A\x03\x02\x02\x02\u0157" +
		"\u0158\x07\x12\x02\x02\u0158\u0159\x05.\x18\x02\u0159\u015A\x07\x06\x02" +
		"\x02\u015A\u015B\x05,\x17\x02\u015B\u015C\x07:\x02\x02\u015C\u016A\x03" +
		"\x02\x02\x02\u015D\u015E\x07\x12\x02\x02\u015E\u015F\x05,\x17\x02\u015F" +
		"\u0160\x07\x06\x02\x02\u0160\u0161\x05.\x18\x02\u0161\u0162\x07:\x02\x02" +
		"\u0162\u016A\x03\x02\x02\x02\u0163\u0164\x07\x12\x02\x02\u0164\u0165\x05" +
		"\x1E\x10\x02\u0165\u0166\x07\x06\x02\x02\u0166\u0167\x05\x1E\x10\x02\u0167" +
		"\u0168\x07:\x02\x02\u0168\u016A\x03\x02\x02\x02\u0169\u014B\x03\x02\x02" +
		"\x02\u0169\u0151\x03\x02\x02\x02\u0169\u0157\x03\x02\x02\x02\u0169\u015D" +
		"\x03\x02\x02\x02\u0169\u0163\x03\x02\x02\x02\u016A!\x03\x02\x02\x02\u016B" +
		"\u016C\x07\x13\x02\x02\u016C\u016D\x05\x18\r\x02\u016D\u016E\x07\x06\x02" +
		"\x02\u016E\u016F\x05\x1A\x0E\x02\u016F\u0170\x07\x06\x02\x02\u0170\u0171" +
		"\x05\x1A\x0E\x02\u0171\u0172\x07:\x02\x02\u0172#\x03\x02\x02\x02\u0173" +
		"\u0174\x07\x13\x02\x02\u0174\u0175\x05\x18\r\x02\u0175\u0176\x07\x06\x02" +
		"\x02\u0176\u0177\x05\x10\t\x02\u0177\u0178\x07\x06\x02\x02\u0178\u0179" +
		"\x05\x10\t\x02\u0179\u017A\x07:\x02\x02\u017A%\x03\x02\x02\x02\u017B\u017C" +
		"\x07\x13\x02\x02\u017C\u017D\x05\x18\r\x02\u017D\u017E\x07\x06\x02\x02" +
		"\u017E\u017F\x05\f\x07\x02\u017F\u0180\x07\x06\x02\x02\u0180\u0181\x05" +
		"\f\x07\x02\u0181\u0182\x07:\x02\x02\u0182\'\x03\x02\x02\x02\u0183\u0184" +
		"\x07\x14\x02\x02\u0184\u0185\x05\x1A\x0E\x02\u0185\u0186\x07\x06\x02\x02" +
		"\u0186\u0187\x05\x1A\x0E\x02\u0187\u0188\x07:\x02\x02\u0188)\x03\x02\x02" +
		"\x02\u0189\u018A\x07\x15\x02\x02\u018A\u018F\x05\x1A\x0E\x02\u018B\u018C" +
		"\x07\x06\x02\x02\u018C\u018E\x05\x1A\x0E\x02\u018D\u018B\x03\x02\x02\x02" +
		"\u018E\u0191\x03\x02\x02\x02\u018F\u018D\x03\x02\x02\x02\u018F\u0190\x03" +
		"\x02\x02\x02\u0190\u0192\x03\x02\x02\x02\u0191\u018F\x03\x02\x02\x02\u0192" +
		"\u0193\x07:\x02\x02\u0193+\x03\x02\x02\x02\u0194\u0195\x07\x16\x02\x02" +
		"\u0195\u0196\x074\x02\x02\u0196\u0197\x07\x06\x02\x02\u0197\u0198\x05" +
		"l7\x02\u0198\u0199\x07:\x02\x02\u0199-\x03\x02\x02\x02\u019A\u019B\x07" +
		"\x17\x02\x02\u019B\u019C\x074\x02\x02\u019C\u019D\x07\x06\x02\x02\u019D" +
		"\u019E\x05l7\x02\u019E\u019F\x07:\x02\x02\u019F/\x03\x02\x02\x02\u01A0" +
		"\u01A1\x07\x18\x02\x02\u01A1\u01A4\x074\x02\x02\u01A2\u01A3\x07\x06\x02" +
		"\x02\u01A3\u01A5\x05l7\x02\u01A4\u01A2\x03\x02\x02\x02\u01A4\u01A5\x03" +
		"\x02\x02\x02\u01A5\u01A6\x03\x02\x02\x02\u01A6\u01A7\x07:\x02\x02\u01A7" +
		"1\x03\x02\x02\x02\u01A8\u01A9\x07\x19\x02\x02\u01A9\u01AA\t\x05\x02\x02" +
		"\u01AA\u01AB\x07\x06\x02\x02\u01AB\u01AE\x05<\x1F\x02\u01AC\u01AD\x07" +
		"\x06\x02\x02\u01AD\u01AF\x05D#\x02\u01AE\u01AC\x03\x02\x02\x02\u01AE\u01AF" +
		"\x03\x02\x02\x02\u01AF\u01B0\x03\x02\x02\x02\u01B0\u01B1\x07:\x02\x02" +
		"\u01B13\x03\x02\x02\x02\u01B2\u01B3\x07\x1A\x02\x02\u01B3\u01B4\x07J\x02" +
		"\x02\u01B4\u01B5\x07\x06\x02\x02\u01B5\u01B6\x056\x1C\x02\u01B6\u01B7" +
		"\x07:\x02\x02\u01B75\x03\x02\x02\x02\u01B8\u01B9\x07\x1B\x02\x02\u01B9" +
		"\u01BA\x07J\x02\x02\u01BA\u01BB\x07\x06\x02\x02\u01BB\u01BC\x05l7\x02" +
		"\u01BC\u01BD\x07:\x02\x02\u01BD7\x03\x02\x02\x02\u01BE\u01BF\x07\x05\x02" +
		"\x02\u01BF\u01C0\x074\x02\x02\u01C0\u01C1\x07\x06\x02\x02\u01C1\u01C4" +
		"\x05<\x1F\x02\u01C2\u01C3\x07\x06\x02\x02\u01C3\u01C5\x05D#\x02\u01C4" +
		"\u01C2\x03\x02\x02\x02\u01C4\u01C5\x03\x02\x02\x02\u01C5\u01C6\x03\x02" +
		"\x02\x02\u01C6\u01C7\x07:\x02\x02\u01C7\u01D3\x03\x02\x02\x02\u01C8\u01C9" +
		"\x07\x05\x02\x02\u01C9\u01CA\x074\x02\x02\u01CA\u01CB\x07\x06\x02\x02" +
		"\u01CB\u01CE\x05l7\x02\u01CC\u01CD\x07\x06\x02\x02\u01CD\u01CF\x05D#\x02" +
		"\u01CE\u01CC\x03\x02\x02\x02\u01CE\u01CF\x03\x02\x02\x02\u01CF\u01D0\x03" +
		"\x02\x02\x02\u01D0\u01D1\x07:\x02\x02\u01D1\u01D3\x03\x02\x02\x02\u01D2" +
		"\u01BE\x03\x02\x02\x02\u01D2\u01C8\x03\x02\x02\x02\u01D39\x03\x02\x02" +
		"\x02\u01D4\u01D5\x07\x05\x02\x02\u01D5\u01D6\x074\x02\x02\u01D6\u01D7" +
		"\x07\x06\x02\x02\u01D7\u01D8\x05<\x1F\x02\u01D8\u01D9\x07:\x02\x02\u01D9" +
		";\x03\x02\x02\x02\u01DA\u01DB\x07\x1C\x02\x02\u01DB\u01DC\x074\x02\x02" +
		"\u01DC\u01DD\x07\x06\x02\x02\u01DD\u01DE\x05l7\x02\u01DE\u01DF\x07:\x02" +
		"\x02\u01DF=\x03\x02\x02\x02\u01E0\u01E1\x07\x1D\x02\x02\u01E1\u01E2\x07" +
		"4\x02\x02\u01E2\u01E3\x07\x06\x02\x02\u01E3\u01E4\x05l7\x02\u01E4\u01E5" +
		"\x07:\x02\x02\u01E5?\x03\x02\x02\x02\u01E6\u01E7\x07\x1E\x02\x02\u01E7" +
		"\u01E8\x074\x02\x02\u01E8\u01E9\x07\x06\x02\x02\u01E9\u01EC\x05l7\x02" +
		"\u01EA\u01EB\x07\x06\x02\x02\u01EB\u01ED\x05B\"\x02\u01EC\u01EA\x03\x02" +
		"\x02\x02\u01EC\u01ED\x03\x02\x02\x02\u01ED\u01EE\x03\x02\x02\x02\u01EE" +
		"\u01EF\x07:\x02\x02\u01EFA\x03\x02\x02\x02\u01F0\u01F2\x07\x1F\x02\x02" +
		"\u01F1\u01F3\x05L\'\x02\u01F2\u01F1\x03\x02\x02\x02\u01F3\u01F4\x03\x02" +
		"\x02\x02\u01F4\u01F2\x03\x02\x02\x02\u01F4\u01F5\x03\x02\x02\x02\u01F5" +
		"\u01F6\x03\x02\x02\x02\u01F6\u01F7\x07:\x02\x02\u01F7C\x03\x02\x02\x02" +
		"\u01F8\u01F9\x07\x1F\x02\x02\u01F9\u01FE\x05F$\x02\u01FA\u01FB\x07\r\x02" +
		"\x02\u01FB\u01FD\x05F$\x02\u01FC\u01FA\x03\x02\x02\x02\u01FD\u0200\x03" +
		"\x02\x02\x02\u01FE\u01FC\x03\x02\x02\x02\u01FE\u01FF\x03\x02\x02\x02\u01FF" +
		"\u0201\x03\x02\x02\x02\u0200\u01FE\x03\x02\x02\x02\u0201\u0202\x07:\x02" +
		"\x02\u0202E\x03\x02\x02\x02\u0203\u0208\x074\x02\x02\u0204\u0205\x07\x06" +
		"\x02\x02\u0205\u0207\x074\x02\x02\u0206\u0204\x03\x02\x02\x02\u0207\u020A" +
		"\x03\x02\x02\x02\u0208\u0206\x03\x02\x02\x02\u0208\u0209\x03\x02\x02\x02" +
		"\u0209\u0215\x03\x02\x02\x02\u020A\u0208\x03\x02\x02\x02\u020B\u020C\x05" +
		"<\x1F\x02\u020C\u0212\x07H\x02\x02\u020D\u0213\x05p9\x02\u020E\u0213\x05" +
		"r:\x02\u020F\u0213\x05l7\x02\u0210\u0213\x074\x02\x02\u0211\u0213\x07" +
		"@\x02\x02\u0212\u020D\x03\x02\x02\x02\u0212\u020E\x03\x02\x02\x02\u0212" +
		"\u020F\x03\x02\x02\x02\u0212\u0210\x03\x02\x02\x02\u0212\u0211\x03\x02" +
		"\x02\x02\u0213\u0215\x03\x02\x02\x02\u0214\u0203\x03\x02\x02\x02\u0214" +
		"\u020B\x03\x02\x02\x02\u0215G\x03\x02\x02\x02\u0216\u0217\x074\x02\x02" +
		"\u0217\u0218\x07 \x02\x02\u0218\u0219\t\x06\x02\x02\u0219I\x03\x02\x02" +
		"\x02\u021A\u021B\x07B\x02\x02\u021B\u021C\x07 \x02\x02\u021C\u0221\t\x07" +
		"\x02\x02\u021D\u021E\x07C\x02\x02\u021E\u021F\x07 \x02\x02\u021F\u0221" +
		"\t\b";
	private static readonly _serializedATNSegment1: string =
		"\x02\x02\u0220\u021A\x03\x02\x02\x02\u0220\u021D\x03\x02\x02\x02\u0221" +
		"K\x03\x02\x02\x02\u0222\u0225\x05H%\x02\u0223\u0225\x05J&\x02\u0224\u0222" +
		"\x03\x02\x02\x02\u0224\u0223\x03\x02\x02\x02\u0225M\x03\x02\x02\x02\u0226" +
		"\u0227\x07!\x02\x02\u0227\u0228\x074\x02\x02\u0228O\x03\x02\x02\x02\u0229" +
		"\u022A\x05N(\x02\u022AQ\x03\x02\x02\x02\u022B\u022C\x07\x1F\x02\x02\u022C" +
		"\u022D\x05`1\x02\u022D\u022F\x07\x06\x02\x02\u022E\u0230\x05L\'\x02\u022F" +
		"\u022E\x03\x02\x02\x02\u0230\u0231\x03\x02\x02\x02\u0231\u022F\x03\x02" +
		"\x02\x02\u0231\u0232\x03\x02\x02\x02\u0232\u0233\x03\x02\x02\x02\u0233" +
		"\u0234\x07:\x02\x02\u0234S\x03\x02\x02\x02\u0235\u0238\x07\"\x02\x02\u0236" +
		"\u0239\x05.\x18\x02\u0237\u0239\x05:\x1E\x02\u0238\u0236\x03\x02\x02\x02" +
		"\u0238\u0237\x03\x02\x02\x02\u0239\u023A\x03\x02\x02\x02\u023A\u023B\x07" +
		"\x06\x02\x02\u023B\u023C\x05V,\x02\u023C\u023D\x07:\x02\x02\u023DU\x03" +
		"\x02\x02\x02\u023E\u023F\x07#\x02\x02\u023F\u0244\x05Z.\x02\u0240\u0241" +
		"\x07\x06\x02\x02\u0241\u0243\x05Z.\x02\u0242\u0240\x03\x02\x02\x02\u0243" +
		"\u0246\x03\x02\x02\x02\u0244\u0242\x03\x02\x02\x02\u0244\u0245\x03\x02" +
		"\x02\x02\u0245\u0249\x03\x02\x02\x02\u0246\u0244\x03\x02\x02\x02\u0247" +
		"\u0248\x07\x06\x02\x02\u0248\u024A\x05X-\x02\u0249\u0247\x03\x02\x02\x02" +
		"\u0249\u024A\x03\x02\x02\x02\u024A\u024B\x03\x02\x02\x02\u024B\u024C\x07" +
		":\x02\x02\u024C\u0252\x03\x02\x02\x02\u024D\u024E\x07#\x02\x02\u024E\u024F" +
		"\x05X-\x02\u024F\u0250\x07:\x02\x02\u0250\u0252\x03\x02\x02\x02\u0251" +
		"\u023E\x03\x02\x02\x02\u0251\u024D\x03\x02\x02\x02\u0252W\x03\x02\x02" +
		"\x02\u0253\u0254\x07$\x02\x02\u0254Y\x03\x02\x02\x02\u0255\u0258\x05\\" +
		"/\x02\u0256\u0258\x074\x02\x02\u0257\u0255\x03\x02\x02\x02\u0257\u0256" +
		"\x03\x02\x02\x02\u0258[\x03\x02\x02\x02\u0259\u025A\x07%\x02\x02\u025A" +
		"\u025B\x074\x02\x02\u025B\u025C\x07:\x02\x02\u025C]\x03\x02\x02\x02\u025D" +
		"\u025E\x07\x1F\x02\x02\u025E\u025F\x05`1\x02\u025F\u0261\x07\x06\x02\x02" +
		"\u0260\u0262\x05L\'\x02\u0261\u0260\x03\x02\x02\x02\u0262\u0263\x03\x02" +
		"\x02\x02\u0263\u0261\x03\x02\x02\x02\u0263\u0264\x03\x02\x02\x02\u0264" +
		"\u0265\x03\x02\x02\x02\u0265\u0266\x07:\x02\x02\u0266_\x03\x02\x02\x02" +
		"\u0267\u0268\x05.\x18\x02\u0268a\x03\x02\x02\x02\u0269\u026A\x07\x1F\x02" +
		"\x02\u026A\u026B\x050\x19\x02\u026B\u026C\x07\x06\x02\x02\u026C\u026D" +
		"\x05P)\x02\u026D\u026E\x07:\x02\x02\u026Ec\x03\x02\x02\x02\u026F\u0270" +
		"\x05h5\x02\u0270\u0271\x079\x02\x02\u0271\u0276\x05f4\x02\u0272\u0273" +
		"\x07\x06\x02\x02\u0273\u0275\x05f4\x02\u0274\u0272\x03\x02\x02\x02\u0275" +
		"\u0278\x03\x02\x02\x02\u0276\u0274\x03\x02\x02\x02\u0276\u0277\x03\x02" +
		"\x02\x02\u0277\u0279\x03\x02\x02\x02\u0278\u0276\x03\x02\x02\x02\u0279" +
		"\u027A\x07:\x02\x02\u027Ae\x03\x02\x02\x02\u027B\u027E\x05\x1A\x0E\x02" +
		"\u027C\u027E\x05j6\x02\u027D\u027B\x03\x02\x02\x02\u027D\u027C\x03\x02" +
		"\x02\x02\u027Eg\x03\x02\x02\x02\u027F\u0280\t\t\x02\x02\u0280i\x03\x02" +
		"\x02\x02\u0281\u0287\x05`1\x02\u0282\u0287\x05R*\x02\u0283\u0287\x058" +
		"\x1D\x02\u0284\u0287\x05b2\x02\u0285\u0287\x05@!\x02\u0286\u0281\x03\x02" +
		"\x02\x02\u0286\u0282\x03\x02\x02\x02\u0286\u0283\x03\x02\x02\x02\u0286" +
		"\u0284\x03\x02\x02\x02\u0286\u0285\x03\x02\x02\x02\u0287k\x03\x02\x02" +
		"\x02\u0288\u0289\x07+\x02\x02\u0289\u028A\x05\x1A\x0E\x02\u028A\u028B" +
		"\x07\x06\x02\x02\u028B\u028C\x05\x1A\x0E\x02\u028C\u028D\x07:\x02\x02" +
		"\u028D\u029B\x03\x02\x02\x02\u028E\u028F\x07,\x02\x02\u028F\u0290\x05" +
		"n8\x02\u0290\u0291\x07\x06\x02\x02\u0291\u0292\x05n8\x02\u0292\u0293\x07" +
		":\x02\x02\u0293\u029B\x03\x02\x02\x02\u0294\u0295\x07-\x02\x02\u0295\u0296" +
		"\x05\x1A\x0E\x02\u0296\u0297\x07\x06\x02\x02\u0297\u0298\x05\x1A\x0E\x02" +
		"\u0298\u0299\x07:\x02\x02\u0299\u029B\x03\x02\x02\x02\u029A\u0288\x03" +
		"\x02\x02\x02\u029A\u028E\x03\x02\x02\x02\u029A\u0294\x03\x02\x02\x02\u029B" +
		"m\x03\x02\x02\x02\u029C\u02B3\x073\x02\x02\u029D\u02A1\x07.\x02\x02\u029E" +
		"\u02A2\x075\x02\x02\u029F\u02A0\x07<\x02\x02\u02A0\u02A2\x075\x02\x02" +
		"\u02A1\u029E\x03\x02\x02\x02\u02A1\u029F\x03\x02\x02\x02\u02A2\u02A3\x03" +
		"\x02\x02\x02\u02A3\u02B3\x07:\x02\x02\u02A4\u02A8\x07/\x02\x02\u02A5\u02A9" +
		"\x075\x02\x02\u02A6\u02A7\x07<\x02\x02\u02A7\u02A9\x075\x02\x02\u02A8" +
		"\u02A5\x03\x02\x02\x02\u02A8\u02A6\x03\x02\x02\x02\u02A9\u02AA\x03\x02" +
		"\x02\x02\u02AA\u02B3\x07:\x02\x02\u02AB\u02AF\x070\x02\x02\u02AC\u02B0" +
		"\x075\x02\x02\u02AD\u02AE\x07<\x02\x02\u02AE\u02B0\x075\x02\x02\u02AF" +
		"\u02AC\x03\x02\x02\x02\u02AF\u02AD\x03\x02\x02\x02\u02B0\u02B1\x03\x02" +
		"\x02\x02\u02B1\u02B3\x07:\x02\x02\u02B2\u029C\x03\x02\x02\x02\u02B2\u029D" +
		"\x03\x02\x02\x02\u02B2\u02A4\x03\x02\x02\x02\u02B2\u02AB\x03\x02\x02\x02" +
		"\u02B3o\x03\x02\x02\x02\u02B4\u02B5\x071\x02\x02\u02B5\u02BA\x078\x02" +
		"\x02\u02B6\u02B7\x072\x02\x02\u02B7\u02B9\x078\x02\x02\u02B8\u02B6\x03" +
		"\x02\x02\x02\u02B9\u02BC\x03\x02\x02\x02\u02BA\u02B8\x03\x02\x02\x02\u02BA" +
		"\u02BB\x03\x02\x02\x02\u02BB\u02BD\x03\x02\x02\x02\u02BC\u02BA\x03\x02" +
		"\x02\x02\u02BD\u02C0\x071\x02\x02\u02BE\u02C0\x078\x02\x02\u02BF\u02B4" +
		"\x03\x02\x02\x02\u02BF\u02BE\x03\x02\x02\x02\u02C0q\x03\x02\x02\x02\u02C1" +
		"\u02C6\x074\x02\x02\u02C2\u02C3\x072\x02\x02\u02C3\u02C5\x074\x02\x02" +
		"\u02C4\u02C2\x03\x02\x02\x02\u02C5\u02C8\x03\x02\x02\x02\u02C6\u02C4\x03" +
		"\x02\x02\x02\u02C6\u02C7\x03\x02\x02\x02\u02C7s\x03\x02\x02\x02\u02C8" +
		"\u02C6\x03\x02\x02\x02:x\x82\x85\x8E\x94\xA4\xAC\xAE\xB2\xBB\xC1\xD7\xDF" +
		"\xE1\u010D\u010F\u011A\u011C\u0132\u0136\u013A\u0141\u0148\u0169\u018F" +
		"\u01A4\u01AE\u01C4\u01CE\u01D2\u01EC\u01F4\u01FE\u0208\u0212\u0214\u0220" +
		"\u0224\u0231\u0238\u0244\u0249\u0251\u0257\u0263\u0276\u027D\u0286\u029A" +
		"\u02A1\u02A8\u02AF\u02B2\u02BA\u02BF\u02C6";
	public static readonly _serializedATN: string = Utils.join(
		[
			ImpactParserParser._serializedATNSegment0,
			ImpactParserParser._serializedATNSegment1,
		],
		"",
	);
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!ImpactParserParser.__ATN) {
			ImpactParserParser.__ATN = new ATNDeserializer().deserialize(Utils.toCharArray(ImpactParserParser._serializedATN));
		}

		return ImpactParserParser.__ATN;
	}

}

export class AtomicNumberContext extends ParserRuleContext {
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	public NUMBER(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.NUMBER, 0); }
	public SUB(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.SUB, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_atomicNumber; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterAtomicNumber) {
			listener.enterAtomicNumber(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitAtomicNumber) {
			listener.exitAtomicNumber(this);
		}
	}
}


export class ImpactExpressionContext extends ParserRuleContext {
	public atomicNumber(): AtomicNumberContext | undefined {
		return this.tryGetRuleContext(0, AtomicNumberContext);
	}
	public expression(): ExpressionContext | undefined {
		return this.tryGetRuleContext(0, ExpressionContext);
	}
	public ADD(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.ADD, 0); }
	public SUB(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.SUB, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_impactExpression; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterImpactExpression) {
			listener.enterImpactExpression(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitImpactExpression) {
			listener.exitImpactExpression(this);
		}
	}
}


export class CalculatorContext extends ParserRuleContext {
	public impactExpression(): ImpactExpressionContext {
		return this.getRuleContext(0, ImpactExpressionContext);
	}
	public EOF(): TerminalNode { return this.getToken(ImpactParserParser.EOF, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_calculator; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterCalculator) {
			listener.enterCalculator(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitCalculator) {
			listener.exitCalculator(this);
		}
	}
}


export class BlockFilterContext extends ParserRuleContext {
	public UUID(): TerminalNode { return this.getToken(ImpactParserParser.UUID, 0); }
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	public EOF(): TerminalNode { return this.getToken(ImpactParserParser.EOF, 0); }
	public objectFilterView(): ObjectFilterViewContext | undefined {
		return this.tryGetRuleContext(0, ObjectFilterViewContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_blockFilter; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterBlockFilter) {
			listener.enterBlockFilter(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitBlockFilter) {
			listener.exitBlockFilter(this);
		}
	}
}


export class TimestampCalculatorContext extends ParserRuleContext {
	public timestampExpression(): TimestampExpressionContext {
		return this.getRuleContext(0, TimestampExpressionContext);
	}
	public EOF(): TerminalNode { return this.getToken(ImpactParserParser.EOF, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_timestampCalculator; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterTimestampCalculator) {
			listener.enterTimestampCalculator(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitTimestampCalculator) {
			listener.exitTimestampCalculator(this);
		}
	}
}


export class TimestampExpressionContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_timestampExpression; }
	public copyFrom(ctx: TimestampExpressionContext): void {
		super.copyFrom(ctx);
	}
}
export class SingleTimestampContext extends TimestampExpressionContext {
	public timestampValue(): TimestampValueContext {
		return this.getRuleContext(0, TimestampValueContext);
	}
	constructor(ctx: TimestampExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterSingleTimestamp) {
			listener.enterSingleTimestamp(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitSingleTimestamp) {
			listener.exitSingleTimestamp(this);
		}
	}
}
export class AddSubTimestampContext extends TimestampExpressionContext {
	public timestampExpression(): TimestampExpressionContext {
		return this.getRuleContext(0, TimestampExpressionContext);
	}
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	public ADD(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.ADD, 0); }
	public SUB(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.SUB, 0); }
	constructor(ctx: TimestampExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterAddSubTimestamp) {
			listener.enterAddSubTimestamp(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitAddSubTimestamp) {
			listener.exitAddSubTimestamp(this);
		}
	}
}
export class AddSubDurationContext extends TimestampExpressionContext {
	public timestampExpression(): TimestampExpressionContext {
		return this.getRuleContext(0, TimestampExpressionContext);
	}
	public durationValue(): DurationValueContext {
		return this.getRuleContext(0, DurationValueContext);
	}
	public ADD(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.ADD, 0); }
	public SUB(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.SUB, 0); }
	constructor(ctx: TimestampExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterAddSubDuration) {
			listener.enterAddSubDuration(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitAddSubDuration) {
			listener.exitAddSubDuration(this);
		}
	}
}
export class EndOfMonthContext extends TimestampExpressionContext {
	public timestampExpression(): TimestampExpressionContext {
		return this.getRuleContext(0, TimestampExpressionContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	constructor(ctx: TimestampExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterEndOfMonth) {
			listener.enterEndOfMonth(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitEndOfMonth) {
			listener.exitEndOfMonth(this);
		}
	}
}
export class StartOfMonthContext extends TimestampExpressionContext {
	public timestampExpression(): TimestampExpressionContext {
		return this.getRuleContext(0, TimestampExpressionContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	constructor(ctx: TimestampExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterStartOfMonth) {
			listener.enterStartOfMonth(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitStartOfMonth) {
			listener.exitStartOfMonth(this);
		}
	}
}
export class TimestampConditionalContext extends TimestampExpressionContext {
	public timestampIf(): TimestampIfContext {
		return this.getRuleContext(0, TimestampIfContext);
	}
	constructor(ctx: TimestampExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterTimestampConditional) {
			listener.enterTimestampConditional(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitTimestampConditional) {
			listener.exitTimestampConditional(this);
		}
	}
}


export class StringCalculatorContext extends ParserRuleContext {
	public stringExpression(): StringExpressionContext {
		return this.getRuleContext(0, StringExpressionContext);
	}
	public EOF(): TerminalNode { return this.getToken(ImpactParserParser.EOF, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_stringCalculator; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterStringCalculator) {
			listener.enterStringCalculator(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitStringCalculator) {
			listener.exitStringCalculator(this);
		}
	}
}


export class StringExpressionContext extends ParserRuleContext {
	public objectRef(): ObjectRefContext | undefined {
		return this.tryGetRuleContext(0, ObjectRefContext);
	}
	public extObjectRef(): ExtObjectRefContext | undefined {
		return this.tryGetRuleContext(0, ExtObjectRefContext);
	}
	public STRING(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.STRING, 0); }
	public stringIf(): StringIfContext | undefined {
		return this.tryGetRuleContext(0, StringIfContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_stringExpression; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterStringExpression) {
			listener.enterStringExpression(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitStringExpression) {
			listener.exitStringExpression(this);
		}
	}
}


export class TimestampValueContext extends ParserRuleContext {
	public objectRef(): ObjectRefContext | undefined {
		return this.tryGetRuleContext(0, ObjectRefContext);
	}
	public extObjectRef(): ExtObjectRefContext | undefined {
		return this.tryGetRuleContext(0, ExtObjectRefContext);
	}
	public timeRange(): TimeRangeContext | undefined {
		return this.tryGetRuleContext(0, TimeRangeContext);
	}
	public STRING(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.STRING, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_timestampValue; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterTimestampValue) {
			listener.enterTimestampValue(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitTimestampValue) {
			listener.exitTimestampValue(this);
		}
	}
}


export class DurationValueContext extends ParserRuleContext {
	public durationUnit(): DurationUnitContext {
		return this.getRuleContext(0, DurationUnitContext);
	}
	public LPAREN(): TerminalNode { return this.getToken(ImpactParserParser.LPAREN, 0); }
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_durationValue; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterDurationValue) {
			listener.enterDurationValue(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitDurationValue) {
			listener.exitDurationValue(this);
		}
	}
}


export class DurationUnitContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_durationUnit; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterDurationUnit) {
			listener.enterDurationUnit(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitDurationUnit) {
			listener.exitDurationUnit(this);
		}
	}
}


export class BooleanExpressionContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_booleanExpression; }
	public copyFrom(ctx: BooleanExpressionContext): void {
		super.copyFrom(ctx);
	}
}
export class SimpleExpressionContext extends BooleanExpressionContext {
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public BOOLEAN_OPERATOR(): TerminalNode { return this.getToken(ImpactParserParser.BOOLEAN_OPERATOR, 0); }
	constructor(ctx: BooleanExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterSimpleExpression) {
			listener.enterSimpleExpression(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitSimpleExpression) {
			listener.exitSimpleExpression(this);
		}
	}
}
export class AndExpressionContext extends BooleanExpressionContext {
	public booleanExpression(): BooleanExpressionContext[];
	public booleanExpression(i: number): BooleanExpressionContext;
	public booleanExpression(i?: number): BooleanExpressionContext | BooleanExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(BooleanExpressionContext);
		} else {
			return this.getRuleContext(i, BooleanExpressionContext);
		}
	}
	constructor(ctx: BooleanExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterAndExpression) {
			listener.enterAndExpression(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitAndExpression) {
			listener.exitAndExpression(this);
		}
	}
}
export class OrExpressionContext extends BooleanExpressionContext {
	public booleanExpression(): BooleanExpressionContext[];
	public booleanExpression(i: number): BooleanExpressionContext;
	public booleanExpression(i?: number): BooleanExpressionContext | BooleanExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(BooleanExpressionContext);
		} else {
			return this.getRuleContext(i, BooleanExpressionContext);
		}
	}
	constructor(ctx: BooleanExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterOrExpression) {
			listener.enterOrExpression(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitOrExpression) {
			listener.exitOrExpression(this);
		}
	}
}
export class TimestampBooleanExpressionContext extends BooleanExpressionContext {
	public timestampExpression(): TimestampExpressionContext[];
	public timestampExpression(i: number): TimestampExpressionContext;
	public timestampExpression(i?: number): TimestampExpressionContext | TimestampExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(TimestampExpressionContext);
		} else {
			return this.getRuleContext(i, TimestampExpressionContext);
		}
	}
	public BOOLEAN_OPERATOR(): TerminalNode { return this.getToken(ImpactParserParser.BOOLEAN_OPERATOR, 0); }
	constructor(ctx: BooleanExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterTimestampBooleanExpression) {
			listener.enterTimestampBooleanExpression(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitTimestampBooleanExpression) {
			listener.exitTimestampBooleanExpression(this);
		}
	}
}
export class StringBooleanExpressionContext extends BooleanExpressionContext {
	public stringExpression(): StringExpressionContext[];
	public stringExpression(i: number): StringExpressionContext;
	public stringExpression(i?: number): StringExpressionContext | StringExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(StringExpressionContext);
		} else {
			return this.getRuleContext(i, StringExpressionContext);
		}
	}
	public BOOLEAN_OPERATOR(): TerminalNode { return this.getToken(ImpactParserParser.BOOLEAN_OPERATOR, 0); }
	constructor(ctx: BooleanExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterStringBooleanExpression) {
			listener.enterStringBooleanExpression(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitStringBooleanExpression) {
			listener.exitStringBooleanExpression(this);
		}
	}
}


export class ExpressionContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_expression; }
	public copyFrom(ctx: ExpressionContext): void {
		super.copyFrom(ctx);
	}
}
export class NumberContext extends ExpressionContext {
	public NUMBER(): TerminalNode { return this.getToken(ImpactParserParser.NUMBER, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterNumber) {
			listener.enterNumber(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitNumber) {
			listener.exitNumber(this);
		}
	}
}
export class NullContext extends ExpressionContext {
	public NULL(): TerminalNode { return this.getToken(ImpactParserParser.NULL, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterNull) {
			listener.enterNull(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitNull) {
			listener.exitNull(this);
		}
	}
}
export class DriverContext extends ExpressionContext {
	public driverRef(): DriverRefContext {
		return this.getRuleContext(0, DriverRefContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterDriver) {
			listener.enterDriver(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitDriver) {
			listener.exitDriver(this);
		}
	}
}
export class ExtDriverContext extends ExpressionContext {
	public extDriverRef(): ExtDriverRefContext {
		return this.getRuleContext(0, ExtDriverRefContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterExtDriver) {
			listener.enterExtDriver(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitExtDriver) {
			listener.exitExtDriver(this);
		}
	}
}
export class ReducerContext extends ExpressionContext {
	public reduceExpressionsOrViews(): ReduceExpressionsOrViewsContext {
		return this.getRuleContext(0, ReduceExpressionsOrViewsContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterReducer) {
			listener.enterReducer(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitReducer) {
			listener.exitReducer(this);
		}
	}
}
export class AddProductContext extends ExpressionContext {
	public sumProduct(): SumProductContext {
		return this.getRuleContext(0, SumProductContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterAddProduct) {
			listener.enterAddProduct(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitAddProduct) {
			listener.exitAddProduct(this);
		}
	}
}
export class MinusContext extends ExpressionContext {
	public SUB(): TerminalNode { return this.getToken(ImpactParserParser.SUB, 0); }
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterMinus) {
			listener.enterMinus(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitMinus) {
			listener.exitMinus(this);
		}
	}
}
export class ParenthesisContext extends ExpressionContext {
	public LPAREN(): TerminalNode { return this.getToken(ImpactParserParser.LPAREN, 0); }
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterParenthesis) {
			listener.enterParenthesis(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitParenthesis) {
			listener.exitParenthesis(this);
		}
	}
}
export class PowContext extends ExpressionContext {
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public POW(): TerminalNode { return this.getToken(ImpactParserParser.POW, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterPow) {
			listener.enterPow(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitPow) {
			listener.exitPow(this);
		}
	}
}
export class MulDivContext extends ExpressionContext {
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public MUL(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.MUL, 0); }
	public DIV(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.DIV, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterMulDiv) {
			listener.enterMulDiv(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitMulDiv) {
			listener.exitMulDiv(this);
		}
	}
}
export class AddSubContext extends ExpressionContext {
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public ADD(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.ADD, 0); }
	public SUB(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.SUB, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterAddSub) {
			listener.enterAddSub(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitAddSub) {
			listener.exitAddSub(this);
		}
	}
}
export class RoundContext extends ExpressionContext {
	public ROUND(): TerminalNode { return this.getToken(ImpactParserParser.ROUND, 0); }
	public LPAREN(): TerminalNode { return this.getToken(ImpactParserParser.LPAREN, 0); }
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterRound) {
			listener.enterRound(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitRound) {
			listener.exitRound(this);
		}
	}
}
export class RoundDownContext extends ExpressionContext {
	public ROUNDDOWN(): TerminalNode { return this.getToken(ImpactParserParser.ROUNDDOWN, 0); }
	public LPAREN(): TerminalNode { return this.getToken(ImpactParserParser.LPAREN, 0); }
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterRoundDown) {
			listener.enterRoundDown(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitRoundDown) {
			listener.exitRoundDown(this);
		}
	}
}
export class RoundPlacesContext extends ExpressionContext {
	public ROUND(): TerminalNode { return this.getToken(ImpactParserParser.ROUND, 0); }
	public LPAREN(): TerminalNode { return this.getToken(ImpactParserParser.LPAREN, 0); }
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterRoundPlaces) {
			listener.enterRoundPlaces(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitRoundPlaces) {
			listener.exitRoundPlaces(this);
		}
	}
}
export class ConditionalContext extends ExpressionContext {
	public baseIf(): BaseIfContext {
		return this.getRuleContext(0, BaseIfContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterConditional) {
			listener.enterConditional(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitConditional) {
			listener.exitConditional(this);
		}
	}
}
export class IfErrorContext extends ExpressionContext {
	public ifErrorRef(): IfErrorRefContext {
		return this.getRuleContext(0, IfErrorRefContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterIfError) {
			listener.enterIfError(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitIfError) {
			listener.exitIfError(this);
		}
	}
}
export class CoalesceContext extends ExpressionContext {
	public coalesceRef(): CoalesceRefContext {
		return this.getRuleContext(0, CoalesceRefContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterCoalesce) {
			listener.enterCoalesce(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitCoalesce) {
			listener.exitCoalesce(this);
		}
	}
}
export class ObjectContext extends ExpressionContext {
	public objectRef(): ObjectRefContext {
		return this.getRuleContext(0, ObjectRefContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterObject) {
			listener.enterObject(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitObject) {
			listener.exitObject(this);
		}
	}
}
export class ExtObjectContext extends ExpressionContext {
	public extObjectRef(): ExtObjectRefContext {
		return this.getRuleContext(0, ExtObjectRefContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterExtObject) {
			listener.enterExtObject(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitExtObject) {
			listener.exitExtObject(this);
		}
	}
}
export class DimDriverFilteredContext extends ExpressionContext {
	public driverFilterView(): DriverFilterViewContext {
		return this.getRuleContext(0, DriverFilterViewContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterDimDriverFiltered) {
			listener.enterDimDriverFiltered(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitDimDriverFiltered) {
			listener.exitDimDriverFiltered(this);
		}
	}
}
export class MatchFilterContext extends ExpressionContext {
	public matchFilterView(): MatchFilterViewContext {
		return this.getRuleContext(0, MatchFilterViewContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterMatchFilter) {
			listener.enterMatchFilter(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitMatchFilter) {
			listener.exitMatchFilter(this);
		}
	}
}
export class DateExpressionContext extends ExpressionContext {
	public dateHelpers(): DateHelpersContext {
		return this.getRuleContext(0, DateHelpersContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterDateExpression) {
			listener.enterDateExpression(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitDateExpression) {
			listener.exitDateExpression(this);
		}
	}
}
export class InvalidExpressionContext extends ExpressionContext {
	public INVALID_WORD(): TerminalNode[];
	public INVALID_WORD(i: number): TerminalNode;
	public INVALID_WORD(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(ImpactParserParser.INVALID_WORD);
		} else {
			return this.getToken(ImpactParserParser.INVALID_WORD, i);
		}
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterInvalidExpression) {
			listener.enterInvalidExpression(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitInvalidExpression) {
			listener.exitInvalidExpression(this);
		}
	}
}


export class DateHelpersContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_dateHelpers; }
	public copyFrom(ctx: DateHelpersContext): void {
		super.copyFrom(ctx);
	}
}
export class DaysInMonthContext extends DateHelpersContext {
	public LPAREN(): TerminalNode { return this.getToken(ImpactParserParser.LPAREN, 0); }
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	constructor(ctx: DateHelpersContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterDaysInMonth) {
			listener.enterDaysInMonth(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitDaysInMonth) {
			listener.exitDaysInMonth(this);
		}
	}
}
export class DateDiffContext extends DateHelpersContext {
	public LPAREN(): TerminalNode { return this.getToken(ImpactParserParser.LPAREN, 0); }
	public timestampExpression(): TimestampExpressionContext[];
	public timestampExpression(i: number): TimestampExpressionContext;
	public timestampExpression(i?: number): TimestampExpressionContext | TimestampExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(TimestampExpressionContext);
		} else {
			return this.getRuleContext(i, TimestampExpressionContext);
		}
	}
	public STRING(): TerminalNode { return this.getToken(ImpactParserParser.STRING, 0); }
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	constructor(ctx: DateHelpersContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterDateDiff) {
			listener.enterDateDiff(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitDateDiff) {
			listener.exitDateDiff(this);
		}
	}
}
export class NetWorkDaysContext extends DateHelpersContext {
	public LPAREN(): TerminalNode { return this.getToken(ImpactParserParser.LPAREN, 0); }
	public timestampExpression(): TimestampExpressionContext[];
	public timestampExpression(i: number): TimestampExpressionContext;
	public timestampExpression(i?: number): TimestampExpressionContext | TimestampExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(TimestampExpressionContext);
		} else {
			return this.getRuleContext(i, TimestampExpressionContext);
		}
	}
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	public STRING(): TerminalNode[];
	public STRING(i: number): TerminalNode;
	public STRING(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(ImpactParserParser.STRING);
		} else {
			return this.getToken(ImpactParserParser.STRING, i);
		}
	}
	constructor(ctx: DateHelpersContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterNetWorkDays) {
			listener.enterNetWorkDays(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitNetWorkDays) {
			listener.exitNetWorkDays(this);
		}
	}
}


export class ArrayExpressionContext extends ParserRuleContext {
	public objectSpecRef(): ObjectSpecRefContext | undefined {
		return this.tryGetRuleContext(0, ObjectSpecRefContext);
	}
	public arrayExpression(): ArrayExpressionContext | undefined {
		return this.tryGetRuleContext(0, ArrayExpressionContext);
	}
	public NUMBER(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.NUMBER, 0); }
	public ADD(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.ADD, 0); }
	public SUB(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.SUB, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_arrayExpression; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterArrayExpression) {
			listener.enterArrayExpression(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitArrayExpression) {
			listener.exitArrayExpression(this);
		}
	}
}


export class SumProductContext extends ParserRuleContext {
	public driverRef(): DriverRefContext[];
	public driverRef(i: number): DriverRefContext;
	public driverRef(i?: number): DriverRefContext | DriverRefContext[] {
		if (i === undefined) {
			return this.getRuleContexts(DriverRefContext);
		} else {
			return this.getRuleContext(i, DriverRefContext);
		}
	}
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	public dimDriverRef(): DimDriverRefContext[];
	public dimDriverRef(i: number): DimDriverRefContext;
	public dimDriverRef(i?: number): DimDriverRefContext | DimDriverRefContext[] {
		if (i === undefined) {
			return this.getRuleContexts(DimDriverRefContext);
		} else {
			return this.getRuleContext(i, DimDriverRefContext);
		}
	}
	public arrayExpression(): ArrayExpressionContext[];
	public arrayExpression(i: number): ArrayExpressionContext;
	public arrayExpression(i?: number): ArrayExpressionContext | ArrayExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ArrayExpressionContext);
		} else {
			return this.getRuleContext(i, ArrayExpressionContext);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_sumProduct; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterSumProduct) {
			listener.enterSumProduct(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitSumProduct) {
			listener.exitSumProduct(this);
		}
	}
}


export class BaseIfContext extends ParserRuleContext {
	public booleanExpression(): BooleanExpressionContext {
		return this.getRuleContext(0, BooleanExpressionContext);
	}
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_baseIf; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterBaseIf) {
			listener.enterBaseIf(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitBaseIf) {
			listener.exitBaseIf(this);
		}
	}
}


export class StringIfContext extends ParserRuleContext {
	public booleanExpression(): BooleanExpressionContext {
		return this.getRuleContext(0, BooleanExpressionContext);
	}
	public stringExpression(): StringExpressionContext[];
	public stringExpression(i: number): StringExpressionContext;
	public stringExpression(i?: number): StringExpressionContext | StringExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(StringExpressionContext);
		} else {
			return this.getRuleContext(i, StringExpressionContext);
		}
	}
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_stringIf; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterStringIf) {
			listener.enterStringIf(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitStringIf) {
			listener.exitStringIf(this);
		}
	}
}


export class TimestampIfContext extends ParserRuleContext {
	public booleanExpression(): BooleanExpressionContext {
		return this.getRuleContext(0, BooleanExpressionContext);
	}
	public timestampExpression(): TimestampExpressionContext[];
	public timestampExpression(i: number): TimestampExpressionContext;
	public timestampExpression(i?: number): TimestampExpressionContext | TimestampExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(TimestampExpressionContext);
		} else {
			return this.getRuleContext(i, TimestampExpressionContext);
		}
	}
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_timestampIf; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterTimestampIf) {
			listener.enterTimestampIf(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitTimestampIf) {
			listener.exitTimestampIf(this);
		}
	}
}


export class IfErrorRefContext extends ParserRuleContext {
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_ifErrorRef; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterIfErrorRef) {
			listener.enterIfErrorRef(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitIfErrorRef) {
			listener.exitIfErrorRef(this);
		}
	}
}


export class CoalesceRefContext extends ParserRuleContext {
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_coalesceRef; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterCoalesceRef) {
			listener.enterCoalesceRef(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitCoalesceRef) {
			listener.exitCoalesceRef(this);
		}
	}
}


export class DriverRefContext extends ParserRuleContext {
	public UUID(): TerminalNode { return this.getToken(ImpactParserParser.UUID, 0); }
	public timeRange(): TimeRangeContext {
		return this.getRuleContext(0, TimeRangeContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_driverRef; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterDriverRef) {
			listener.enterDriverRef(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitDriverRef) {
			listener.exitDriverRef(this);
		}
	}
}


export class DimDriverRefContext extends ParserRuleContext {
	public UUID(): TerminalNode { return this.getToken(ImpactParserParser.UUID, 0); }
	public timeRange(): TimeRangeContext {
		return this.getRuleContext(0, TimeRangeContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_dimDriverRef; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterDimDriverRef) {
			listener.enterDimDriverRef(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitDimDriverRef) {
			listener.exitDimDriverRef(this);
		}
	}
}


export class SubmodelRefContext extends ParserRuleContext {
	public UUID(): TerminalNode { return this.getToken(ImpactParserParser.UUID, 0); }
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	public timeRange(): TimeRangeContext | undefined {
		return this.tryGetRuleContext(0, TimeRangeContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_submodelRef; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterSubmodelRef) {
			listener.enterSubmodelRef(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitSubmodelRef) {
			listener.exitSubmodelRef(this);
		}
	}
}


export class ObjectRefContext extends ParserRuleContext {
	public objectFieldRef(): ObjectFieldRefContext {
		return this.getRuleContext(0, ObjectFieldRefContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	public UUID(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.UUID, 0); }
	public THIS(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.THIS, 0); }
	public objectFilterView(): ObjectFilterViewContext | undefined {
		return this.tryGetRuleContext(0, ObjectFilterViewContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_objectRef; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterObjectRef) {
			listener.enterObjectRef(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitObjectRef) {
			listener.exitObjectRef(this);
		}
	}
}


export class ExtObjectRefContext extends ParserRuleContext {
	public extObjectFieldRef(): ExtObjectFieldRefContext {
		return this.getRuleContext(0, ExtObjectFieldRefContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	public LINKED(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.LINKED, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_extObjectRef; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterExtObjectRef) {
			listener.enterExtObjectRef(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitExtObjectRef) {
			listener.exitExtObjectRef(this);
		}
	}
}


export class ExtObjectFieldRefContext extends ParserRuleContext {
	public timeRange(): TimeRangeContext {
		return this.getRuleContext(0, TimeRangeContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	public LINKED(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.LINKED, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_extObjectFieldRef; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterExtObjectFieldRef) {
			listener.enterExtObjectFieldRef(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitExtObjectFieldRef) {
			listener.exitExtObjectFieldRef(this);
		}
	}
}


export class ObjectSpecRefContext extends ParserRuleContext {
	public UUID(): TerminalNode { return this.getToken(ImpactParserParser.UUID, 0); }
	public objectFieldRef(): ObjectFieldRefContext | undefined {
		return this.tryGetRuleContext(0, ObjectFieldRefContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	public objectFilterView(): ObjectFilterViewContext | undefined {
		return this.tryGetRuleContext(0, ObjectFilterViewContext);
	}
	public timeRange(): TimeRangeContext | undefined {
		return this.tryGetRuleContext(0, TimeRangeContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_objectSpecRef; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterObjectSpecRef) {
			listener.enterObjectSpecRef(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitObjectSpecRef) {
			listener.exitObjectSpecRef(this);
		}
	}
}


export class MatchObjectSpecRefContext extends ParserRuleContext {
	public UUID(): TerminalNode { return this.getToken(ImpactParserParser.UUID, 0); }
	public objectFieldRef(): ObjectFieldRefContext {
		return this.getRuleContext(0, ObjectFieldRefContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_matchObjectSpecRef; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterMatchObjectSpecRef) {
			listener.enterMatchObjectSpecRef(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitMatchObjectSpecRef) {
			listener.exitMatchObjectSpecRef(this);
		}
	}
}


export class ObjectFieldRefContext extends ParserRuleContext {
	public UUID(): TerminalNode { return this.getToken(ImpactParserParser.UUID, 0); }
	public timeRange(): TimeRangeContext {
		return this.getRuleContext(0, TimeRangeContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_objectFieldRef; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterObjectFieldRef) {
			listener.enterObjectFieldRef(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitObjectFieldRef) {
			listener.exitObjectFieldRef(this);
		}
	}
}


export class ExtDriverRefContext extends ParserRuleContext {
	public UUID(): TerminalNode { return this.getToken(ImpactParserParser.UUID, 0); }
	public timeRange(): TimeRangeContext {
		return this.getRuleContext(0, TimeRangeContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_extDriverRef; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterExtDriverRef) {
			listener.enterExtDriverRef(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitExtDriverRef) {
			listener.exitExtDriverRef(this);
		}
	}
}


export class ExtQueryRefContext extends ParserRuleContext {
	public UUID(): TerminalNode { return this.getToken(ImpactParserParser.UUID, 0); }
	public timeRange(): TimeRangeContext {
		return this.getRuleContext(0, TimeRangeContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	public extQueryFilterView(): ExtQueryFilterViewContext | undefined {
		return this.tryGetRuleContext(0, ExtQueryFilterViewContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_extQueryRef; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterExtQueryRef) {
			listener.enterExtQueryRef(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitExtQueryRef) {
			listener.exitExtQueryRef(this);
		}
	}
}


export class ExtQueryFilterViewContext extends ParserRuleContext {
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	public attributeFilter(): AttributeFilterContext[];
	public attributeFilter(i: number): AttributeFilterContext;
	public attributeFilter(i?: number): AttributeFilterContext | AttributeFilterContext[] {
		if (i === undefined) {
			return this.getRuleContexts(AttributeFilterContext);
		} else {
			return this.getRuleContext(i, AttributeFilterContext);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_extQueryFilterView; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterExtQueryFilterView) {
			listener.enterExtQueryFilterView(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitExtQueryFilterView) {
			listener.exitExtQueryFilterView(this);
		}
	}
}


export class ObjectFilterViewContext extends ParserRuleContext {
	public objectFilter(): ObjectFilterContext[];
	public objectFilter(i: number): ObjectFilterContext;
	public objectFilter(i?: number): ObjectFilterContext | ObjectFilterContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ObjectFilterContext);
		} else {
			return this.getRuleContext(i, ObjectFilterContext);
		}
	}
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_objectFilterView; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterObjectFilterView) {
			listener.enterObjectFilterView(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitObjectFilterView) {
			listener.exitObjectFilterView(this);
		}
	}
}


export class ObjectFilterContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_objectFilter; }
	public copyFrom(ctx: ObjectFilterContext): void {
		super.copyFrom(ctx);
	}
}
export class ObjectUUIDFilterContext extends ObjectFilterContext {
	public UUID(): TerminalNode[];
	public UUID(i: number): TerminalNode;
	public UUID(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(ImpactParserParser.UUID);
		} else {
			return this.getToken(ImpactParserParser.UUID, i);
		}
	}
	constructor(ctx: ObjectFilterContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterObjectUUIDFilter) {
			listener.enterObjectUUIDFilter(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitObjectUUIDFilter) {
			listener.exitObjectUUIDFilter(this);
		}
	}
}
export class ObjectFieldFilterContext extends ObjectFilterContext {
	public objectFieldRef(): ObjectFieldRefContext {
		return this.getRuleContext(0, ObjectFieldRefContext);
	}
	public BOOLEAN_OPERATOR(): TerminalNode { return this.getToken(ImpactParserParser.BOOLEAN_OPERATOR, 0); }
	public stringGroup(): StringGroupContext | undefined {
		return this.tryGetRuleContext(0, StringGroupContext);
	}
	public uuidGroup(): UuidGroupContext | undefined {
		return this.tryGetRuleContext(0, UuidGroupContext);
	}
	public timeRange(): TimeRangeContext | undefined {
		return this.tryGetRuleContext(0, TimeRangeContext);
	}
	public UUID(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.UUID, 0); }
	public NULL(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.NULL, 0); }
	constructor(ctx: ObjectFilterContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterObjectFieldFilter) {
			listener.enterObjectFieldFilter(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitObjectFieldFilter) {
			listener.exitObjectFieldFilter(this);
		}
	}
}


export class UserAttributeFilterContext extends ParserRuleContext {
	public UUID(): TerminalNode[];
	public UUID(i: number): TerminalNode;
	public UUID(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(ImpactParserParser.UUID);
		} else {
			return this.getToken(ImpactParserParser.UUID, i);
		}
	}
	public ANY(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.ANY, 0); }
	public NULL(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.NULL, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_userAttributeFilter; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterUserAttributeFilter) {
			listener.enterUserAttributeFilter(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitUserAttributeFilter) {
			listener.exitUserAttributeFilter(this);
		}
	}
}


export class BuiltInAttributeFilterContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_builtInAttributeFilter; }
	public copyFrom(ctx: BuiltInAttributeFilterContext): void {
		super.copyFrom(ctx);
	}
}
export class CalendarFilterContext extends BuiltInAttributeFilterContext {
	public CALENDAR(): TerminalNode { return this.getToken(ImpactParserParser.CALENDAR, 0); }
	public DATE(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.DATE, 0); }
	public ANY(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.ANY, 0); }
	public NULL(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.NULL, 0); }
	constructor(ctx: BuiltInAttributeFilterContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterCalendarFilter) {
			listener.enterCalendarFilter(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitCalendarFilter) {
			listener.exitCalendarFilter(this);
		}
	}
}
export class RelativeFilterContext extends BuiltInAttributeFilterContext {
	public RELATIVE(): TerminalNode { return this.getToken(ImpactParserParser.RELATIVE, 0); }
	public NUMBER(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.NUMBER, 0); }
	public ANY(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.ANY, 0); }
	public NULL(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.NULL, 0); }
	public COHORT_MONTH(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.COHORT_MONTH, 0); }
	constructor(ctx: BuiltInAttributeFilterContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterRelativeFilter) {
			listener.enterRelativeFilter(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitRelativeFilter) {
			listener.exitRelativeFilter(this);
		}
	}
}


export class AttributeFilterContext extends ParserRuleContext {
	public userAttributeFilter(): UserAttributeFilterContext | undefined {
		return this.tryGetRuleContext(0, UserAttributeFilterContext);
	}
	public builtInAttributeFilter(): BuiltInAttributeFilterContext | undefined {
		return this.tryGetRuleContext(0, BuiltInAttributeFilterContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_attributeFilter; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterAttributeFilter) {
			listener.enterAttributeFilter(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitAttributeFilter) {
			listener.exitAttributeFilter(this);
		}
	}
}


export class DriverGroupFilterContext extends ParserRuleContext {
	public UUID(): TerminalNode { return this.getToken(ImpactParserParser.UUID, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_driverGroupFilter; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterDriverGroupFilter) {
			listener.enterDriverGroupFilter(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitDriverGroupFilter) {
			listener.exitDriverGroupFilter(this);
		}
	}
}


export class SubmodelFilterContext extends ParserRuleContext {
	public driverGroupFilter(): DriverGroupFilterContext {
		return this.getRuleContext(0, DriverGroupFilterContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_submodelFilter; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterSubmodelFilter) {
			listener.enterSubmodelFilter(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitSubmodelFilter) {
			listener.exitSubmodelFilter(this);
		}
	}
}


export class DriverFilterViewContext extends ParserRuleContext {
	public dimDriverView(): DimDriverViewContext {
		return this.getRuleContext(0, DimDriverViewContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	public attributeFilter(): AttributeFilterContext[];
	public attributeFilter(i: number): AttributeFilterContext;
	public attributeFilter(i?: number): AttributeFilterContext | AttributeFilterContext[] {
		if (i === undefined) {
			return this.getRuleContexts(AttributeFilterContext);
		} else {
			return this.getRuleContext(i, AttributeFilterContext);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_driverFilterView; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterDriverFilterView) {
			listener.enterDriverFilterView(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitDriverFilterView) {
			listener.exitDriverFilterView(this);
		}
	}
}


export class MatchFilterViewContext extends ParserRuleContext {
	public attributeList(): AttributeListContext {
		return this.getRuleContext(0, AttributeListContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	public dimDriverRef(): DimDriverRefContext | undefined {
		return this.tryGetRuleContext(0, DimDriverRefContext);
	}
	public matchObjectSpecRef(): MatchObjectSpecRefContext | undefined {
		return this.tryGetRuleContext(0, MatchObjectSpecRefContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_matchFilterView; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterMatchFilterView) {
			listener.enterMatchFilterView(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitMatchFilterView) {
			listener.exitMatchFilterView(this);
		}
	}
}


export class AttributeListContext extends ParserRuleContext {
	public attribute(): AttributeContext[];
	public attribute(i: number): AttributeContext;
	public attribute(i?: number): AttributeContext | AttributeContext[] {
		if (i === undefined) {
			return this.getRuleContexts(AttributeContext);
		} else {
			return this.getRuleContext(i, AttributeContext);
		}
	}
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	public allContextAttributes(): AllContextAttributesContext | undefined {
		return this.tryGetRuleContext(0, AllContextAttributesContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_attributeList; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterAttributeList) {
			listener.enterAttributeList(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitAttributeList) {
			listener.exitAttributeList(this);
		}
	}
}


export class AllContextAttributesContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_allContextAttributes; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterAllContextAttributes) {
			listener.enterAllContextAttributes(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitAllContextAttributes) {
			listener.exitAllContextAttributes(this);
		}
	}
}


export class AttributeContext extends ParserRuleContext {
	public contextAttribute(): ContextAttributeContext | undefined {
		return this.tryGetRuleContext(0, ContextAttributeContext);
	}
	public UUID(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.UUID, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_attribute; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterAttribute) {
			listener.enterAttribute(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitAttribute) {
			listener.exitAttribute(this);
		}
	}
}


export class ContextAttributeContext extends ParserRuleContext {
	public UUID(): TerminalNode { return this.getToken(ImpactParserParser.UUID, 0); }
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_contextAttribute; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterContextAttribute) {
			listener.enterContextAttribute(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitContextAttribute) {
			listener.exitContextAttribute(this);
		}
	}
}


export class RelativeContext extends ParserRuleContext {
	public dimDriverView(): DimDriverViewContext {
		return this.getRuleContext(0, DimDriverViewContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	public attributeFilter(): AttributeFilterContext[];
	public attributeFilter(i: number): AttributeFilterContext;
	public attributeFilter(i?: number): AttributeFilterContext | AttributeFilterContext[] {
		if (i === undefined) {
			return this.getRuleContexts(AttributeFilterContext);
		} else {
			return this.getRuleContext(i, AttributeFilterContext);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_relative; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterRelative) {
			listener.enterRelative(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitRelative) {
			listener.exitRelative(this);
		}
	}
}


export class DimDriverViewContext extends ParserRuleContext {
	public dimDriverRef(): DimDriverRefContext {
		return this.getRuleContext(0, DimDriverRefContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_dimDriverView; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterDimDriverView) {
			listener.enterDimDriverView(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitDimDriverView) {
			listener.exitDimDriverView(this);
		}
	}
}


export class SubmodelViewContext extends ParserRuleContext {
	public submodelRef(): SubmodelRefContext {
		return this.getRuleContext(0, SubmodelRefContext);
	}
	public submodelFilter(): SubmodelFilterContext {
		return this.getRuleContext(0, SubmodelFilterContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_submodelView; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterSubmodelView) {
			listener.enterSubmodelView(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitSubmodelView) {
			listener.exitSubmodelView(this);
		}
	}
}


export class ReduceExpressionsOrViewsContext extends ParserRuleContext {
	public reducerFn(): ReducerFnContext {
		return this.getRuleContext(0, ReducerFnContext);
	}
	public LPAREN(): TerminalNode { return this.getToken(ImpactParserParser.LPAREN, 0); }
	public expressionOrView(): ExpressionOrViewContext[];
	public expressionOrView(i: number): ExpressionOrViewContext;
	public expressionOrView(i?: number): ExpressionOrViewContext | ExpressionOrViewContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionOrViewContext);
		} else {
			return this.getRuleContext(i, ExpressionOrViewContext);
		}
	}
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_reduceExpressionsOrViews; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterReduceExpressionsOrViews) {
			listener.enterReduceExpressionsOrViews(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitReduceExpressionsOrViews) {
			listener.exitReduceExpressionsOrViews(this);
		}
	}
}


export class ExpressionOrViewContext extends ParserRuleContext {
	public expression(): ExpressionContext | undefined {
		return this.tryGetRuleContext(0, ExpressionContext);
	}
	public reduceableViews(): ReduceableViewsContext | undefined {
		return this.tryGetRuleContext(0, ReduceableViewsContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_expressionOrView; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterExpressionOrView) {
			listener.enterExpressionOrView(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitExpressionOrView) {
			listener.exitExpressionOrView(this);
		}
	}
}


export class ReducerFnContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_reducerFn; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterReducerFn) {
			listener.enterReducerFn(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitReducerFn) {
			listener.exitReducerFn(this);
		}
	}
}


export class ReduceableViewsContext extends ParserRuleContext {
	public dimDriverView(): DimDriverViewContext | undefined {
		return this.tryGetRuleContext(0, DimDriverViewContext);
	}
	public driverFilterView(): DriverFilterViewContext | undefined {
		return this.tryGetRuleContext(0, DriverFilterViewContext);
	}
	public objectSpecRef(): ObjectSpecRefContext | undefined {
		return this.tryGetRuleContext(0, ObjectSpecRefContext);
	}
	public submodelView(): SubmodelViewContext | undefined {
		return this.tryGetRuleContext(0, SubmodelViewContext);
	}
	public extQueryRef(): ExtQueryRefContext | undefined {
		return this.tryGetRuleContext(0, ExtQueryRefContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_reduceableViews; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterReduceableViews) {
			listener.enterReduceableViews(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitReduceableViews) {
			listener.exitReduceableViews(this);
		}
	}
}


export class TimeRangeContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_timeRange; }
	public copyFrom(ctx: TimeRangeContext): void {
		super.copyFrom(ctx);
	}
}
export class VariableRelativeTimeContext extends TimeRangeContext {
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	constructor(ctx: TimeRangeContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterVariableRelativeTime) {
			listener.enterVariableRelativeTime(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitVariableRelativeTime) {
			listener.exitVariableRelativeTime(this);
		}
	}
}
export class DateRangeContext extends TimeRangeContext {
	public dateRef(): DateRefContext[];
	public dateRef(i: number): DateRefContext;
	public dateRef(i?: number): DateRefContext | DateRefContext[] {
		if (i === undefined) {
			return this.getRuleContexts(DateRefContext);
		} else {
			return this.getRuleContext(i, DateRefContext);
		}
	}
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	constructor(ctx: TimeRangeContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterDateRange) {
			listener.enterDateRange(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitDateRange) {
			listener.exitDateRange(this);
		}
	}
}
export class CohortRelativeTimeContext extends TimeRangeContext {
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	constructor(ctx: TimeRangeContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterCohortRelativeTime) {
			listener.enterCohortRelativeTime(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitCohortRelativeTime) {
			listener.exitCohortRelativeTime(this);
		}
	}
}


export class DateRefContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_dateRef; }
	public copyFrom(ctx: DateRefContext): void {
		super.copyFrom(ctx);
	}
}
export class DateContext extends DateRefContext {
	public DATE(): TerminalNode { return this.getToken(ImpactParserParser.DATE, 0); }
	constructor(ctx: DateRefContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterDate) {
			listener.enterDate(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitDate) {
			listener.exitDate(this);
		}
	}
}
export class DateRelativeMonthsContext extends DateRefContext {
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	public NUMBER(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.NUMBER, 0); }
	public SUB(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.SUB, 0); }
	constructor(ctx: DateRefContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterDateRelativeMonths) {
			listener.enterDateRelativeMonths(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitDateRelativeMonths) {
			listener.exitDateRelativeMonths(this);
		}
	}
}
export class DateRelativeQuartersContext extends DateRefContext {
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	public NUMBER(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.NUMBER, 0); }
	public SUB(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.SUB, 0); }
	constructor(ctx: DateRefContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterDateRelativeQuarters) {
			listener.enterDateRelativeQuarters(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitDateRelativeQuarters) {
			listener.exitDateRelativeQuarters(this);
		}
	}
}
export class DateRelativeYearsContext extends DateRefContext {
	public RPAREN(): TerminalNode { return this.getToken(ImpactParserParser.RPAREN, 0); }
	public NUMBER(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.NUMBER, 0); }
	public SUB(): TerminalNode | undefined { return this.tryGetToken(ImpactParserParser.SUB, 0); }
	constructor(ctx: DateRefContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterDateRelativeYears) {
			listener.enterDateRelativeYears(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitDateRelativeYears) {
			listener.exitDateRelativeYears(this);
		}
	}
}


export class StringGroupContext extends ParserRuleContext {
	public STRING(): TerminalNode[];
	public STRING(i: number): TerminalNode;
	public STRING(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(ImpactParserParser.STRING);
		} else {
			return this.getToken(ImpactParserParser.STRING, i);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_stringGroup; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterStringGroup) {
			listener.enterStringGroup(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitStringGroup) {
			listener.exitStringGroup(this);
		}
	}
}


export class UuidGroupContext extends ParserRuleContext {
	public UUID(): TerminalNode[];
	public UUID(i: number): TerminalNode;
	public UUID(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(ImpactParserParser.UUID);
		} else {
			return this.getToken(ImpactParserParser.UUID, i);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ImpactParserParser.RULE_uuidGroup; }
	// @Override
	public enterRule(listener: ImpactParserListener): void {
		if (listener.enterUuidGroup) {
			listener.enterUuidGroup(this);
		}
	}
	// @Override
	public exitRule(listener: ImpactParserListener): void {
		if (listener.exitUuidGroup) {
			listener.exitUuidGroup(this);
		}
	}
}


