import { StarIcon } from '@chakra-ui/icons';
import { Box, Flex, IconButton, Text } from '@chakra-ui/react';
import React, { useCallback, useMemo } from 'react';

import { Tooltip } from 'chakra/tooltip';
import WithShowMore from 'components/WithShowMore/WithShowMore';
import { formatIsoStringToLocalTime } from 'helpers/dates';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { createNamedVersionFromMutationBatch } from 'reduxStore/actions/layerMutations';
import {
  MutationAffectedEntityType,
  MutationBatch,
  getMutationBatchDisplay,
} from 'reduxStore/models/mutations';
import { deletedIdentifiersSelector } from 'selectors/deletedIdentifierSelector';
import { namedDatasetVersionsByIdSelector } from 'selectors/layerSelector';
import { isRunwayEmployeeSelector } from 'selectors/loginSelector';
import { mutationAffectedEntityNameSelector } from 'selectors/mutationAffectedEntitySelector';

export type MutationBatchWithUserId = MutationBatch & { userId: string };

interface Props {
  mutation: MutationBatchWithUserId;
  openNewLayerId: (layerId: string) => void;
}

const MutationBatchCard: React.FC<Props> = ({ mutation, openNewLayerId }) => {
  const isRunwayEmployee = useAppSelector(isRunwayEmployeeSelector);
  const deletedIdentifiers = useAppSelector(deletedIdentifiersSelector);
  const timeText = useMemo(
    () => formatIsoStringToLocalTime(mutation.createdAt, 'h:mm a'),
    [mutation.createdAt],
  );
  const mutationDisplay = useMemo(
    () =>
      getMutationBatchDisplay({
        mutationBatch: mutation,
        deletedIdentifiers,
      }),
    [deletedIdentifiers, mutation],
  );

  const dispatch = useAppDispatch();
  const namedVersionByIds = useAppSelector(namedDatasetVersionsByIdSelector);
  const save = useCallback(
    (batch: MutationBatch) => {
      if (namedVersionByIds[batch.id] == null) {
        dispatch(createNamedVersionFromMutationBatch(batch));
      }
      openNewLayerId(batch.id);
    },
    [dispatch, namedVersionByIds, openNewLayerId],
  );

  return (
    <Flex w="full" justifyContent="space-between" alignContent="flex-start" py={2}>
      <Flex flexGrow={1}>
        <WithShowMore items={mutationDisplay.actions}>
          {({ itemsToShow }) => (
            <Flex columnGap={2}>
              <Flex h="full" pr={2}>
                {timeText != null && (
                  <Text fontSize="xxs" w="3.5rem">
                    {timeText}
                  </Text>
                )}
              </Flex>
              <Flex w="full" flexDir="column" rowGap={2}>
                {itemsToShow?.map((action, idx) => (
                  <Action
                    key={idx}
                    entityIds={action.entityIds}
                    entityType={action.entityType}
                    message={action.message}
                  />
                ))}
              </Flex>
            </Flex>
          )}
        </WithShowMore>
      </Flex>
      <Tooltip
        label={`${namedVersionByIds[mutation.id]?.name ?? ''}${
          isRunwayEmployee ? ` (${mutation.id})` : ''
        }`}
      >
        <IconButton
          variant="text"
          aria-label="Save version"
          data-testid="save-version"
          w={4}
          h={4}
          color="gray.300"
          flex={0}
          onClick={() => save(mutation)}
          _hover={{ bgColor: 'transparent', color: 'selection.500' }}
          icon={
            <StarIcon
              color={namedVersionByIds[mutation.id] != null ? 'selection.500' : 'inherit'}
              boxSize={3}
            />
          }
        />
      </Tooltip>
    </Flex>
  );
};

const Action: React.FC<{
  entityIds: string[] | undefined;
  entityType: MutationAffectedEntityType | undefined;
  message: string;
}> = React.memo(({ entityIds, entityType, message }) => {
  return (
    <Flex w="full" flexDir="column" rowGap={1}>
      <Text fontSize="xxs" fontWeight="medium" color="gray.600">
        {message}
      </Text>
      <WithShowMore items={entityIds}>
        {({ itemsToShow }) =>
          itemsToShow?.map((entityId, eIdx) => (
            <ActionEntity type={entityType} id={entityId} key={eIdx} />
          ))
        }
      </WithShowMore>
    </Flex>
  );
});

const ActionEntity: React.FC<{ type: MutationAffectedEntityType | undefined; id: string }> =
  React.memo(({ type, id }) => {
    const name = useAppSelector((state) =>
      type != null ? mutationAffectedEntityNameSelector(state, { type, id }) : undefined,
    );
    if (name == null) {
      return null;
    }

    return (
      <Box bgColor="selection.200" p={1} borderRadius="md" w="fit-content">
        <Text fontSize="xxs" fontWeight="medium">
          {name === '' ? '(Untitled)' : name}
        </Text>
      </Box>
    );
  });

export default React.memo(MutationBatchCard);
