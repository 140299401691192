import React from 'react';

import ReferenceMetadataPill from 'components/FormulaInput/ReferenceMetadataPill';
import { BlockFilterOperator, ValueType } from 'generated/graphql';
import { getAttributeValueString } from 'helpers/dimensionalDrivers';
import { getFormulaDateRangeDisplay } from 'helpers/formula';
import { isNotNull } from 'helpers/typescript';
import useAppSelector from 'hooks/useAppSelector';
import { BusinessObjectSpecId } from 'reduxStore/models/businessObjectSpecs';
import { businessObjectNamesByIdForSpecSelector } from 'selectors/businessObjectsSelector';
import { attributesByIdSelector } from 'selectors/dimensionsSelector';
import { driverNamesByIdSelector } from 'selectors/driversSelector';
import { FilterItem, isEntityIdFilterItem } from 'types/filtering';
import FilterIcon from 'vectors/Filter';

// Inline styles for the operators to save space
const FILTER_OPERATOR_TO_DISPLAY: Record<BlockFilterOperator, string> = {
  [BlockFilterOperator.Equals]: '=',
  [BlockFilterOperator.NotEquals]: '≠',
  [BlockFilterOperator.GreaterThan]: '>',
  [BlockFilterOperator.LessThan]: '<',
  [BlockFilterOperator.GreaterThanOrEqualTo]: '≥',
  [BlockFilterOperator.LessThanOrEqualTo]: '≤',
  [BlockFilterOperator.IsNull]: 'is none',
  [BlockFilterOperator.IsNotNull]: 'is not none',
};

interface Props {
  isSelected: boolean;
  onMouseDown: React.MouseEventHandler;
  objectSpecId: BusinessObjectSpecId;
  filters: FilterItem[];
}

const ObjectSpecFilterPill: React.FC<Props> = ({
  isSelected,
  onMouseDown,
  objectSpecId,
  filters,
}) => {
  const attributesById = useAppSelector(attributesByIdSelector);
  const driverNamesById = useAppSelector(driverNamesByIdSelector);
  const objectNamesById = useAppSelector((state) =>
    businessObjectNamesByIdForSpecSelector(state, objectSpecId),
  );

  const filterLabels = filters.map((filter) => {
    const { label, expected, valueType } = filter;
    const displayOperator =
      filter.operator != null ? FILTER_OPERATOR_TO_DISPLAY[filter.operator] : null;
    if (displayOperator == null) {
      return null;
    }

    // The null comparison cases
    if (
      filter.operator === BlockFilterOperator.IsNull ||
      filter.operator === BlockFilterOperator.IsNotNull
    ) {
      return `${label} ${displayOperator}`;
    }

    if (expected == null) {
      return null;
    }

    let displayValue: string | undefined;
    if (valueType === ValueType.Attribute) {
      const attributes = expected.map((attrId) => attributesById[attrId]).filter(isNotNull);
      const attrNames = attributes.map(getAttributeValueString);
      displayValue = attrNames.join(', ');
    } else if (valueType === ValueType.Timestamp) {
      displayValue = getFormulaDateRangeDisplay(expected, driverNamesById);
    } else if (valueType === ValueType.Number) {
      displayValue = String(expected);
    } else if (
      isEntityIdFilterItem(filter) &&
      filter.entityType === 'object' &&
      Array.isArray(expected)
    ) {
      const names = expected.map((id) => objectNamesById[id]).filter(isNotNull);
      displayValue = names.join(', ');
    }
    return displayValue != null ? `${label} ${displayOperator} ${displayValue}` : null;
  });

  return (
    <ReferenceMetadataPill
      label={filterLabels.join(' and ')}
      icon={<FilterIcon color="gray.500" boxSize={4} />}
      isSelected={isSelected}
      onMouseDown={onMouseDown}
      isTruncated
      zIndex={3}
    />
  );
};

export default React.memo(ObjectSpecFilterPill);
