import {
  AgCartesianChartOptions,
  AgCategoryAxisOptions,
  AgNumberAxisOptions,
  AgRangeAreaSeriesOptions,
} from 'ag-charts-community';
import { useMemo } from 'react';

import AgChart from 'components/AgGridComponents/AgChart/AgChart';
import {
  CURRENCY_FORMAT,
  getHeight,
  getWidth,
  NUMBER_FORMAT,
  PERCENT_FORMAT,
} from 'components/AgGridComponents/AgChart/agCharts';
import theme from 'config/theme';
import colors from 'config/theme/foundations/colors';
import { ChartDisplay, ChartSize, DriverFormat } from 'generated/graphql';
import {
  getDateTimeFromMonthKey,
  getMonthKey,
  getMonthKeysForRange,
  shortMonthFormat,
} from 'helpers/dates';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { useRequestCellValue } from 'hooks/useRequestCellValue';
import { DriverId } from 'reduxStore/models/drivers';
import { impactLoadingForPageDateRangeSelector } from 'selectors/calculationsForPageDateRangeSelector';
import { entityLoadingAnyMonthInRangeSelector } from 'selectors/calculationsSelector';
import {
  driverTimeSeriesForImpactShadingSelector,
  driverTimeSeriesForLayerSelector,
} from 'selectors/driverTimeSeriesSelector';
import { driverDisplayConfigurationSelector } from 'selectors/entityDisplayConfigurationSelector';
import {
  blockDateRangeDateTimeSelector,
  pageDateRangeDateTimeSelector,
} from 'selectors/pageDateRangeSelector';
import { flattenedSelectedEventIdsSelector } from 'selectors/selectedEventSelector';

const { fonts } = theme;

const enableAxisLabels = (chartSize: ChartSize | undefined | null) => {
  return chartSize !== ChartSize.Medium;
};

interface Props {
  driverId: DriverId;
  chartDisplay: ChartDisplay;
  size?: ChartSize;
}

const AgEventImpactChart: React.FC<Props> = ({
  driverId,
  chartDisplay,
  size = ChartSize.Medium,
}) => {
  const { blockId } = useBlockContext();
  const pageDateRange = useAppSelector((state) => pageDateRangeDateTimeSelector(state));
  const blockDateRange = useAppSelector((state) => blockDateRangeDateTimeSelector(state, blockId));
  const displayConfiguration = useAppSelector((state) =>
    driverDisplayConfigurationSelector(state, driverId),
  );

  const ignoreEventIds = useAppSelector(flattenedSelectedEventIdsSelector);

  const [blockStart, blockEnd] = blockDateRange;
  const monthKeys = useMemo(
    () => getMonthKeysForRange(blockStart, blockEnd),
    [blockStart, blockEnd],
  );
  const isDriverLoading = useAppSelector((state) =>
    entityLoadingAnyMonthInRangeSelector(state, {
      id: driverId,
      monthKeys,
    }),
  );
  const isEventImpactLoading = useAppSelector((state) =>
    impactLoadingForPageDateRangeSelector(state, { id: driverId, ignoreEventIds }),
  );

  const driverTimeSeries = useAppSelector((state) =>
    driverTimeSeriesForLayerSelector(state, {
      id: driverId,
      start: getMonthKey(blockStart),
      end: getMonthKey(blockEnd),
    }),
  );
  const eventImpactTimeSeries = useAppSelector((state) =>
    driverTimeSeriesForImpactShadingSelector(state, {
      driverId,
      blockId,
      includeEventsInSameRow: false,
    }),
  );

  useRequestCellValue({
    id: driverId,
    type: 'driver',
    dateRange: blockDateRange,
    requireBackendOnly: true,
  });
  useRequestCellValue({
    id: driverId,
    type: 'driver',
    dateRange: pageDateRange,
    ignoreEventIds,
    requireBackendOnly: true,
  });

  const data = useMemo(() => {
    const seriesId = chartDisplay.series.find((s) => s.driverId === driverId)?.id;

    if (seriesId == null || isDriverLoading || isEventImpactLoading) {
      return [];
    }

    const seriesData: Array<Record<string, any>> = [];
    for (const monthKey of monthKeys) {
      if (isNaN(driverTimeSeries[monthKey]) || isNaN(eventImpactTimeSeries[monthKey])) {
        continue;
      }
      const point = {
        monthKey,
        [seriesId]: driverTimeSeries[monthKey],
        eventImpact: eventImpactTimeSeries[monthKey],
      };
      seriesData.push(point);
    }

    return seriesData;
  }, [
    chartDisplay.series,
    driverId,
    driverTimeSeries,
    eventImpactTimeSeries,
    isDriverLoading,
    isEventImpactLoading,
    monthKeys,
  ]);

  const axes = useMemo<AgCartesianChartOptions['axes']>(() => {
    const seriesId = chartDisplay.series.find((s) => s.driverId === driverId)?.id;
    if (seriesId == null) {
      return [];
    }

    const yAxis: AgNumberAxisOptions = {
      type: 'number',
      position: 'left',
      crosshair: {
        enabled: true,
        label: {
          enabled: false,
        },
      },
      gridLine: {
        enabled: false,
      },
      label: {
        color: colors.gray[500],
        fontSize: 10,
        fontFamily: fonts.body,
        format:
          displayConfiguration?.format === DriverFormat.Currency
            ? CURRENCY_FORMAT
            : displayConfiguration?.format === DriverFormat.Percentage
              ? PERCENT_FORMAT
              : NUMBER_FORMAT,
      },
    };
    const xAxis: AgCategoryAxisOptions = {
      type: 'category',
      position: 'bottom',
      line: { enabled: false },
      crosshair: {
        enabled: true,
      },
      gridLine: {
        enabled: false,
      },
      label: {
        color: colors.gray[500],
        fontSize: 10,
        fontFamily: fonts.body,
        formatter: (params) => {
          return shortMonthFormat(getDateTimeFromMonthKey(String(params.value)));
        },
      },
      interval: {
        values: enableAxisLabels(size)
          ? undefined
          : [monthKeys[0], monthKeys[monthKeys.length - 1]],
      },
    };

    return [yAxis, xAxis];
  }, [chartDisplay.series, displayConfiguration?.format, driverId, monthKeys, size]);

  // Just ignore scenario comparisons, they do nothing useful when combined with impact shading.
  const series = useMemo<AgRangeAreaSeriesOptions[]>(() => {
    const seriesItem = chartDisplay.series.find((s) => s.driverId === driverId);
    if (seriesItem == null) {
      return [];
    }

    return [
      {
        type: 'range-area',
        xKey: 'monthKey',
        yHighKey: seriesItem.id,
        yLowKey: 'eventImpact',
        stroke: seriesItem.color ?? '',
        strokeWidth: 1,
        marker: { fill: seriesItem.color ?? '', fillOpacity: 0, size: 1, strokeOpacity: 0 },
        fill: seriesItem.color ?? '',
        fillOpacity: 0.1,
        tooltip: {
          enabled: true,
          interaction: {
            enabled: true,
          },
        },
      },
    ];
  }, [chartDisplay.series, driverId]);

  const width = getWidth(size);
  const height = getHeight(size);

  const options = useMemo<AgCartesianChartOptions>(
    () => ({
      axes,
      series,
      data,
      width,
      height,
    }),
    [axes, data, height, series, width],
  );

  return <AgChart isLoading={isDriverLoading || isEventImpactLoading} options={options} />;
};

export default AgEventImpactChart;
