import { useTheme } from '@chakra-ui/react';
import { getCSSVar } from '@chakra-ui/utils';
import React, { useContext, useMemo } from 'react';

import CellBorder from 'components/CellBorder/CellBorder';
import { CELL_HEIGHT_IN_PX, CellStyleContext } from 'config/cells';
import { toPxString } from 'helpers/styles';
import { useCellRef } from 'hooks/useCellRefContext';
import { Value } from 'reduxStore/models/value';

import styles from './TimeSeriesCellWrapper.module.scss';

export interface TimeSeriesCellLightProps<V extends Value> {
  value: V['value'] | undefined;
  showComparisonHighlight?: boolean;
  color: string;
  alert?: React.ReactNode;
  width: number;
  inView?: boolean;
}

export interface ActiveCellLightProps<V extends Value> {
  value: V['value'] | undefined;
  disabled?: boolean;
  isPlaceholder?: boolean;
}

interface Props {
  color: string;
  children?: React.ReactNode;
  width: number;
  bgColor?: string;
}

const TimeSeriesCellWrapperLight: React.FC<Props> = ({ color, width, children }) => {
  const { cellId } = useCellRef();
  const { definedBgColor } = useContext(CellStyleContext);
  const theme = useTheme();

  const inlineCss = useMemo(() => {
    const bgColorVar = getCSSVar(theme, 'colors', definedBgColor) as string;
    const colorVar = getCSSVar(theme, 'colors', color) as string;
    const height = toPxString(CELL_HEIGHT_IN_PX);
    const textDecorationColor = getCSSVar(
      theme,
      'colors',
      color !== 'forecast' ? 'gray.500' : 'blue.400',
    ) as string;
    const widthCssString = toPxString(width);

    return {
      backgroundColor: bgColorVar,
      color: colorVar,
      height,
      textDecorationColor,
      width: widthCssString,
    };
  }, [definedBgColor, color, theme, width]);

  return (
    <div
      data-testid="time-series-cell-light"
      data-cellid={cellId}
      className={styles.timeSeriesCellWrapper}
      style={inlineCss}
    >
      <div className={styles.timeSeriesCellWrapperOverflowHidden}>{children}</div>
      <CellBorder />
    </div>
  );
};

TimeSeriesCellWrapperLight.displayName = 'TimeSeriesCellWrapperLight';

export default React.memo(TimeSeriesCellWrapperLight);
