import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { ValueType } from 'generated/graphql';
import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import {
  SelectorWithLayerParam,
  addLayerParams,
  getCacheKeyForLayerSelector,
} from 'helpers/layerSelectorFactory';
import { EntityTable } from 'reduxStore/models/common';
import { Attribute, AttributeId, Dimension, DimensionId } from 'reduxStore/models/dimensions';
import { ExtObjectKey } from 'reduxStore/models/extObjectSpecs';
import { DEFAULT_LAYER_ID } from 'reduxStore/models/layers';
import { denormalizeDimension } from 'reduxStore/reducers/helpers/dimensions';
import { dimensionIdSelector } from 'selectors/constSelectors';
import { extObjectsByKeySelector } from 'selectors/extObjectsSelector';
import { layersSelector } from 'selectors/layerSelector';
import { ParametricSelector, Selector } from 'types/redux';

const EMPTY_DIMENSION_ENTITY_TABLE: EntityTable<Dimension> = { byId: {}, allIds: [] };
const EMPTY_ATTRIBUTE_ENTITY_TABLE: EntityTable<Attribute> = { byId: {}, allIds: [] };

const attributesEntityTableForLayer: SelectorWithLayerParam<EntityTable<Attribute>> =
  createCachedSelector(layersSelector, (layers) => {
    const defaultLayer = layers[DEFAULT_LAYER_ID];
    return defaultLayer.attributes ?? EMPTY_ATTRIBUTE_ENTITY_TABLE;
  })(getCacheKeyForLayerSelector);

const dimensionEntityTableSelector: SelectorWithLayerParam<EntityTable<Dimension>> =
  createCachedSelector(layersSelector, attributesEntityTableForLayer, (layers, attributes) => {
    const layer = layers[DEFAULT_LAYER_ID];
    if (layer == null) {
      return EMPTY_DIMENSION_ENTITY_TABLE;
    }

    const allIds = layer.dimensions.allIds;
    const byId: Record<DimensionId, Dimension> = {};
    allIds.forEach((dimId) => {
      const dim = layer.dimensions.byId[dimId];
      if (dim == null) {
        return;
      }
      const denormalizedDimension = denormalizeDimension(dim, attributes.byId);
      byId[dimId] = denormalizedDimension;
    });

    return {
      allIds,
      byId,
    };
  })(getCacheKeyForLayerSelector);

export const dimensionsForLayerSelector: Selector<Dimension[]> = createSelector(
  dimensionEntityTableSelector,
  (dimensions) => {
    return dimensions.allIds.map((dimId) => dimensions.byId[dimId]);
  },
);

export const validDimensionsSelector: Selector<Dimension[]> = createDeepEqualSelector(
  dimensionsForLayerSelector,
  (dimensions) => {
    return dimensions.filter((d) => !d.deleted);
  },
);

export const dimensionsByIdSelector: Selector<Record<DimensionId, Dimension>> = createSelector(
  dimensionEntityTableSelector,
  function dimensionsByIdSelector(dimensions) {
    return dimensions.byId;
  },
);

export const dimensionSelector: ParametricSelector<DimensionId, Dimension | undefined> =
  createCachedSelector(
    dimensionsByIdSelector,
    dimensionIdSelector,
    (dimensionsById, dimensionId) => {
      return dimensionsById[dimensionId];
    },
  )((_state, dimensionId) => dimensionId);

export const attributesSelector: Selector<Attribute[]> = createDeepEqualSelector(
  attributesEntityTableForLayer,
  (attributes) => {
    return Object.values(attributes.byId);
  },
);

export const validAttributesSelector: Selector<Attribute[]> = createDeepEqualSelector(
  attributesSelector,
  (attributes) => {
    return attributes.filter((a) => !a.deleted);
  },
);

export const attributesByIdSelector: Selector<Record<AttributeId, Attribute>> = createSelector(
  attributesEntityTableForLayer,
  function attributesByIdSelector(attributes) {
    return attributes.byId;
  },
);

export const validAttributesForDimensionSelector: ParametricSelector<DimensionId, Attribute[]> =
  createCachedSelector(dimensionSelector, (dimension) => {
    return dimension?.attributes.filter((a) => !a.deleted) ?? [];
  })((_state, dimensionId) => dimensionId);

export const attributesByExtKeySelector: SelectorWithLayerParam<
  Record<ExtObjectKey, Record<string, Attribute>>
> = createCachedSelector(
  addLayerParams(extObjectsByKeySelector),
  addLayerParams(attributesByIdSelector),
  function attributesByExtKeySelector(extObjectsByKey, attributesById) {
    const attributeByKey: Record<string, Record<string, Attribute>> = {};
    Object.values(extObjectsByKey).forEach((extObject) => {
      const attributeByExtKey: Record<string, Attribute> = {};
      extObject.fields.forEach((extField) => {
        const sortedMonthKeys = Object.keys(extField.timeSeries);
        sortedMonthKeys.sort().reverse();
        let attributeId: AttributeId | undefined;
        for (const monthKey of sortedMonthKeys) {
          const value = extField.timeSeries[monthKey];
          if (value.type !== ValueType.Attribute) {
            continue;
          }
          if (value.value.length === 0) {
            continue;
          }
          if (attributesById[value.value] == null) {
            continue;
          }
          attributeId = value.value;
          break;
        }
        if (attributeId == null) {
          return;
        }
        attributeByExtKey[extField.extFieldSpecKey] = attributesById[attributeId];
      });
      attributeByKey[extObject.extKey] = attributeByExtKey;
    });

    return attributeByKey;
  },
)(getCacheKeyForLayerSelector);
