import { createCachedSelector } from 're-reselect';

import { ValueType } from 'generated/graphql';
import { evaluateFormulaDisplay } from 'helpers/formulaEvaluation/ForecastCalculator/ForecastCalculator';
import FormulaDisplayListener, {
  FormulaDisplay,
} from 'helpers/formulaEvaluation/ForecastCalculator/FormulaDisplayListener';
import { FormulaEntityTypedId } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import {
  SelectorWithLayerParam,
  addLayerParams,
  getCacheKeyForLayerSelector,
} from 'helpers/layerSelectorFactory';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { DimensionId } from 'reduxStore/models/dimensions';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import { DriverId } from 'reduxStore/models/drivers';
import { ExtDriverId } from 'reduxStore/models/extDrivers';
import { ExtQueryId } from 'reduxStore/models/extQueries';
import { LayerId } from 'reduxStore/models/layers';
import { SubmodelId } from 'reduxStore/models/submodels';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import {
  FieldSpecForLayerProps,
  cacheKeyForFieldSpecForLayer,
  fieldSpecDefaultForecastFormulaSelector,
  fieldSpecValueTypeSelector,
} from 'selectors/businessObjectFieldSpecsSelector';
import {
  businessObjectSpecsByFieldSpecIdForLayerSelector,
  businessObjectSpecsByIdForLayerSelector,
} from 'selectors/businessObjectSpecsSelector';
import { businessObjectsByIdForLayerSelector } from 'selectors/businessObjectsSelector';
import {
  databaseFormulaPropertiesByIdSelector,
  dimensionalPropertyEvaluatorSelector,
} from 'selectors/collectionSelector';
import { fieldSelector } from 'selectors/constSelectors';
import { deletedIdentifiersSelector } from 'selectors/deletedIdentifierSelector';
import { attributesByIdSelector, dimensionsByIdSelector } from 'selectors/dimensionsSelector';
import { driverGroupsByIdSelector } from 'selectors/driverGroupSelector';
import {
  DriverFormulaProps,
  cacheKeyForDriverFormulaSelector,
  dimensionalDriversBySubDriverIdSelector,
  driverActualsFormulaSelector,
  driverForecastFormulaSelector,
  driverNamesByIdSelector,
} from 'selectors/driversSelector';
import { extDriversByIdSelector } from 'selectors/extDriversSelector';
import { extQueryDisplayByIdSelector } from 'selectors/extQueriesSelector';
import { submodelNamesByIdSelector } from 'selectors/submodelPageSelector';
import { ParametricSelector } from 'types/redux';

type FormulaDisplayListenerProps = {
  // This is used to ensure that we don't have the same listener for different
  // drivers. It is currently just used as part of the cache key.
  type: FormulaEntityTypedId['type'];
  id: FormulaEntityTypedId['id'];
  layerId?: LayerId | undefined;
};

interface IdentifierResolver {
  getDriverNameById: (id: DriverId) => string;
  getDeletedIdentifiersById: (id: string) => string;
  getObjectNameById: (id: BusinessObjectId) => string | undefined;
  getObjectSpecNameById: (id: BusinessObjectSpecId) => string | undefined;
  getObjectSpecIdByObjId: (id: BusinessObjectSpecId) => BusinessObjectSpecId | undefined;
  getObjectSpecIdByFieldSpecId: (
    fieldSpecId: BusinessObjectFieldSpecId,
  ) => BusinessObjectSpecId | undefined;
}

const identifierResolverSelector: SelectorWithLayerParam<IdentifierResolver> = createCachedSelector(
  addLayerParams(driverNamesByIdSelector),
  (state: RootState) => deletedIdentifiersSelector(state),
  (state: RootState) => businessObjectsByIdForLayerSelector(state),
  (state: RootState) => businessObjectSpecsByIdForLayerSelector(state),
  (state: RootState) => businessObjectSpecsByFieldSpecIdForLayerSelector(state),
  function identifierResolverSelector(
    driverNamesById,
    deletedIdentifiers,
    businessObjectsById,
    businessObjectSpecsById,
    businessObjectSpecsByFieldSpecId,
  ) {
    return {
      getDriverNameById: (id: DriverId) => {
        return driverNamesById[id];
      },
      getDeletedIdentifiersById: (id: string) => {
        return deletedIdentifiers[id];
      },
      getObjectNameById: (id: BusinessObjectId): string | undefined => {
        return businessObjectsById[id]?.name;
      },
      getObjectSpecNameById: (id: BusinessObjectSpecId): string | undefined => {
        return businessObjectSpecsById[id]?.name;
      },
      getObjectSpecIdByObjId: (id: BusinessObjectId): BusinessObjectSpecId | undefined => {
        return businessObjectsById[id]?.specId;
      },
      getObjectSpecIdByFieldSpecId: (
        fieldSpecId: BusinessObjectFieldSpecId,
      ): BusinessObjectSpecId | undefined => {
        return businessObjectSpecsByFieldSpecId[fieldSpecId]?.id;
      },
    } as IdentifierResolver;
  },
)(getCacheKeyForLayerSelector);

export const getFormulaDisplayForFormula = (
  state: RootState,
  formula: string,
  entityId: FormulaEntityTypedId,
): FormulaDisplay | null => {
  const formulaDisplayListener = formulaDisplayListenerSelector(state, entityId);
  return evaluateFormulaDisplay(formula, formulaDisplayListener);
};

export const formulaDisplayListenerSelector: ParametricSelector<
  FormulaDisplayListenerProps,
  FormulaDisplayListener
> = createCachedSelector(
  addLayerParams(identifierResolverSelector),
  dimensionalDriversBySubDriverIdSelector,
  attributesByIdSelector,
  databaseFormulaPropertiesByIdSelector,
  extDriversByIdSelector,
  dimensionsByIdSelector,
  driverGroupsByIdSelector,
  submodelNamesByIdSelector,
  extQueryDisplayByIdSelector,
  dimensionalPropertyEvaluatorSelector,
  fieldSelector<FormulaDisplayListenerProps, 'id'>('id'),
  fieldSelector<FormulaDisplayListenerProps, 'type'>('type'),
  // eslint-disable-next-line max-params
  function formulaDisplayListenerSelector(
    identifierResolver,
    dimDriverBySubDriverId,
    attrById,
    databaseFormulaPropertiesById,
    extDriversById,
    dimensionsById,
    driverGroupsById,
    submodelNamesById,
    extQueryDisplayById,
    dimensionalPropertyEvaluator,
    entityId,
    type,
  ) {
    return new FormulaDisplayListener({
      getDriverName: (id) => {
        return identifierResolver.getDriverNameById(id);
      },
      getDeletedIdentifier: (id) => {
        return identifierResolver.getDeletedIdentifiersById(id);
      },
      getAttr: (attrId) => {
        return attrById[attrId];
      },
      getSubDriverId: (dimDriverId, attrIds) =>
        dimensionalPropertyEvaluator.getSubDriverIdForAttributeIds(dimDriverId, attrIds),
      getParentDimensionalDriver: (subDriverId) => dimDriverBySubDriverId[subDriverId],
      getObjectFieldName: (fieldId) => {
        return databaseFormulaPropertiesById[fieldId]?.name;
      },
      getObjectFieldType: (fieldId) => {
        const databaseFormulaProperty = databaseFormulaPropertiesById[fieldId];
        if (databaseFormulaProperty?.type === 'dimensionalProperty') {
          return ValueType.Attribute;
        } else if (databaseFormulaProperty?.type === 'driverProperty') {
          return ValueType.Number;
        }
        return databaseFormulaProperty?.fieldSpec?.type;
      },
      getObjectFieldDimensionId: (fieldId) => {
        const databaseFormulaProperty = databaseFormulaPropertiesById[fieldId];
        if (databaseFormulaProperty?.type === 'dimensionalProperty') {
          return databaseFormulaProperty.dimensionalProperty.dimension.id;
        } else if (
          databaseFormulaProperty?.type === 'fieldSpec' &&
          databaseFormulaProperty?.fieldSpec.type === ValueType.Attribute
        ) {
          return databaseFormulaProperty?.fieldSpec.dimensionId;
        }
        return undefined;
      },
      getObjectName: (objectId) => {
        return identifierResolver.getObjectNameById(objectId);
      },
      getObjectSpecName: (objectSpecId) => {
        return identifierResolver.getObjectSpecNameById(objectSpecId);
      },
      getExtDriver(id: ExtDriverId) {
        return extDriversById[id];
      },
      getDimension(id: DimensionId) {
        return dimensionsById[id];
      },
      getDriverGroupName(id: DriverGroupId) {
        return driverGroupsById[id]?.name;
      },
      getSubmodelName(id: SubmodelId) {
        return submodelNamesById[id];
      },
      getObjectSpecId(id: BusinessObjectId) {
        return identifierResolver.getObjectSpecIdByObjId(id);
      },
      getObjectSpecIdByFieldSpecId(fieldSpecId: BusinessObjectFieldSpecId) {
        return identifierResolver.getObjectSpecIdByFieldSpecId(fieldSpecId);
      },
      getExtQueryDisplay: (id: ExtQueryId) => {
        return extQueryDisplayById[id];
      },
      entityId: {
        id: entityId,
        type,
      },
    });
  },
)(
  (_state, formulaEntityId: FormulaDisplayListenerProps) =>
    `${formulaEntityId.id}${formulaEntityId.type}`,
);

export const driverForecastFormulaDisplaySelector: ParametricSelector<
  DriverFormulaProps,
  FormulaDisplay | null
> = createCachedSelector(
  (state: RootState, props: DriverFormulaProps) =>
    formulaDisplayListenerSelector(state, {
      type: 'driver',
      id: props.id,
      layerId: props.layerId,
    }),
  driverForecastFormulaSelector,
  function driverForecastFormulaDisplaySelector(formulaDisplayListener, formula) {
    return formula != null ? evaluateFormulaDisplay(formula, formulaDisplayListener) : null;
  },
)(cacheKeyForDriverFormulaSelector);

const emptyFormulaDisplay: FormulaDisplay = {
  chunks: [],
  isEditingSupported: true,
};

export const driverActualsFormulaDisplaySelector: ParametricSelector<
  DriverFormulaProps,
  FormulaDisplay | null
> = createCachedSelector(
  (state: RootState, props: DriverFormulaProps) =>
    formulaDisplayListenerSelector(state, {
      type: 'driver',
      id: props.id,
      layerId: props.layerId,
    }),
  driverActualsFormulaSelector,
  (formulaDisplayListener, formula) => {
    return formula != null
      ? evaluateFormulaDisplay(formula, formulaDisplayListener)
      : emptyFormulaDisplay;
  },
)(cacheKeyForDriverFormulaSelector);

export const fieldSpecFormulaDisplaySelector: ParametricSelector<
  FieldSpecForLayerProps,
  FormulaDisplay | null
> = createCachedSelector(
  (state: RootState, { id }: FieldSpecForLayerProps) =>
    formulaDisplayListenerSelector(state, { type: 'objectFieldSpec', id }),
  fieldSpecValueTypeSelector,
  fieldSpecDefaultForecastFormulaSelector,
  (formulaDisplayListener, valueType, formula) => {
    return formula != null && formula !== ''
      ? evaluateFormulaDisplay(formula, formulaDisplayListener, valueType)
      : {
          chunks: [],
          isEditingSupported: true,
        };
  },
)(cacheKeyForFieldSpecForLayer);

export const driverHasFormulaErrorSelector: ParametricSelector<DriverId, boolean> =
  createCachedSelector(
    (state: RootState, id: DriverId) => driverActualsFormulaDisplaySelector(state, { id }),
    (state, id) => driverForecastFormulaDisplaySelector(state, { id }),
    (actualsFormulaDisplay, forecastFormulaDisplay) => {
      return actualsFormulaDisplay?.error != null || forecastFormulaDisplay?.error != null;
    },
  )((_state, driverId) => driverId);
