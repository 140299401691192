import * as Sentry from '@sentry/nextjs';
import { keyBy } from 'lodash';
import { ReactElement } from 'react';

import {
  InvalidColumnError,
  InvalidColumnErrors,
  InvalidColumnErrorType,
  InvalidDatabaseConfigError,
  InvalidDatabaseError,
} from 'components/DatabasePageContent/DatabaseConfigurationEditor/DatabaseConfigErrorsProvider';
import { DatabaseSource } from 'components/DatabasePageContent/DatabaseConfigurationEditor/DatabaseConfigProvider';
import { OverDBLimitModal } from 'components/DatabasePageContent/DatabaseConfigurationEditor/updateDatabase/OverDBLimitModal';
import {
  BusinessObjectSpecUpdateInput,
  DatabaseConfig,
  DatabaseConfigDeleteInput,
  DatabaseConfigSourceType,
  DatabaseDimensionColumn,
  DatabaseDriverColumn,
  DatabaseFilter,
  DatasetMutationInput,
  DimensionalPropertyCreateInput,
  DimensionalPropertyUpdateInput,
  DimensionCreateInput,
  ExtQueryDeleteInput,
  ExtTableDimensionColumn,
  ExtTableDriverColumn,
  ExtTableFilter,
  TimeColumn,
} from 'generated/graphql';
import { backendUrl } from 'helpers/environment';
import { safeObjGet } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import { submitAutoLayerizedMutations } from 'reduxStore/actions/submitDatasetMutation';
import { BusinessObjectSpec, BusinessObjectSpecId } from 'reduxStore/models/businessObjectSpecs';
import { DimensionalProperty } from 'reduxStore/models/collections';
import { Dimension, DimensionId } from 'reduxStore/models/dimensions';
import { Layer, LayerId } from 'reduxStore/models/layers';
import { AppThunk } from 'reduxStore/store';
import { DatabaseConfigId } from 'selectors/databaseConfigSelector';

export function isExtTableDimensionColumn(
  value: DatabaseDimensionColumn | ExtTableDimensionColumn,
): value is ExtTableDimensionColumn {
  return (
    value.__typename === 'ExtTableDimensionColumn' ||
    (value as ExtTableDimensionColumn).extTableColumnId !== undefined
  );
}

export function isExtTableDriverColumn(
  value: DatabaseDriverColumn | ExtTableDriverColumn,
): value is ExtTableDriverColumn {
  return (
    value.__typename === 'ExtTableDriverColumn' ||
    (value as ExtTableDriverColumn).extTableColumnId !== undefined
  );
}

export function isDatabaseDriverColumn(
  value: DatabaseDriverColumn | ExtTableDriverColumn,
): value is DatabaseDriverColumn {
  return (
    value.__typename === 'DatabaseDriverColumn' ||
    (value as DatabaseDriverColumn).sourceDriverPropertyId !== undefined
  );
}

export function isDatabaseDimensionColumn(
  value: DatabaseDimensionColumn | ExtTableDimensionColumn,
): value is DatabaseDimensionColumn {
  return (
    value.__typename === 'DatabaseDimensionColumn' ||
    (value as DatabaseDimensionColumn).sourceDimensionalPropertyId !== undefined
  );
}

export function isExtTableFilter(value: DatabaseFilter | ExtTableFilter): value is ExtTableFilter {
  return (
    value.__typename === 'ExtTableFilter' ||
    (value as ExtTableFilter).extTableColumnId !== undefined
  );
}

export function isDatabaseFilter(value: DatabaseFilter | ExtTableFilter): value is DatabaseFilter {
  return (
    value.__typename === 'DatabaseFilter' ||
    (value as DatabaseFilter).dimensionalPropertyId !== undefined
  );
}

export async function configureDatabase(
  {
    orgId,
    specId,
    layerId,
    mutationChangeId,
  }: {
    orgId: string;
    specId: BusinessObjectSpecId;
    layerId: LayerId;
    mutationChangeId: string;
  },
  newConfig: DatabaseConfig,
  shouldConfigureNew?: boolean,
): Promise<{ databaseId: BusinessObjectSpecId; layerId: LayerId } | null> {
  if (shouldConfigureNew) {
    newConfig.id = uuidv4();
  }
  const endpoint = shouldConfigureNew
    ? `databases/configure/${specId}`
    : `databases/${specId}/configure`;

  const params = new URLSearchParams({
    layerId,
    draft: 'true',
    mutationChangeId,
  });

  const response = await fetch(
    `${backendUrl}/client_api/orgs/${orgId}/${endpoint}?${params.toString()}`,
    {
      method: 'POST',
      body: JSON.stringify(newConfig),
      credentials: 'include',
    },
  );
  if (response.status !== 200) {
    const { errors: serverErrors } = (await response.json()) as ErrorsResponse;
    const errors = Object.entries(serverErrors).flatMap(([type, messages]) => {
      const isColumnError = InvalidColumnErrors.includes(type as InvalidColumnErrorType);
      return messages.map((message) => {
        if (isColumnError) {
          return new InvalidColumnError(message as string, type as InvalidColumnErrorType);
        }
        return new InvalidDatabaseError(message as string, type);
      });
    });
    throw new InvalidDatabaseConfigError(errors);
  }

  const { databaseId, layerId: newLayerId } = (await response.json()) as ConfigureDatabaseResponse;
  return { databaseId, layerId: newLayerId };
}

type ConfigureDatabaseResponse = {
  databaseId: BusinessObjectSpecId;
  configuration: DatabaseConfig;
  layerId: LayerId;
};

export async function onDatabaseRefresh(
  {
    orgId,
    databaseId,
    mutationChangeId,
    layerId,
    onDraftLayer = true,
  }: {
    orgId: string;
    databaseId: BusinessObjectSpecId;
    mutationChangeId: string;
    layerId: LayerId;
    onDraftLayer?: boolean;
  },
  openModal: (content: ReactElement) => void,
) {
  try {
    return await refreshDatabase(
      orgId,
      databaseId,
      mutationChangeId,
      layerId,
      onDraftLayer ?? true,
    );
  } catch (error) {
    if (error instanceof OverLimitError) {
      openModal(
        <OverDBLimitModal databaseId={databaseId} count={error.count} limit={error.limit} />,
      );
    }
  }
  return null;
}

async function refreshDatabase(
  orgId: string,
  specId: BusinessObjectSpecId,
  mutationChangeId: string,
  layerId: LayerId,
  draft: boolean = false,
): Promise<LayerId | null> {
  const params = new URLSearchParams({
    submit: 'true',
    layerId,
    draft: draft.toString(),
    mutationChangeId,
  });

  const response = await fetch(
    `${backendUrl}/client_api/orgs/${orgId}/databases/${specId}/refresh?${params.toString()}`,
    { credentials: 'include' },
  );
  if (response.ok) {
    const { layerId: newLayerId } = (await response.json()) as RefreshDatabaseResponse;
    return newLayerId;
  }

  try {
    throw new ServerError('Failed to refresh database!', (await response.json()) as ErrorsResponse);
  } catch (error) {
    if (error instanceof ServerError && error.isOverLimitError()) {
      throw error.getOverLimitError();
    }
    reportError(error);
  }
  return null;
}

type RefreshDatabaseResponse = {
  layerId: LayerId;
};

export async function removeDatabaseConfig(
  orgId: string,
  specId: BusinessObjectSpecId,
  shouldDeleteLinkedObjects: boolean,
  layerId: LayerId,
): Promise<LayerId | null> {
  const response = await fetch(
    `${backendUrl}/client_api/orgs/${orgId}/databases/${specId}/remove?layerId=${layerId}`,
    { credentials: 'include', method: 'POST', body: JSON.stringify({ shouldDeleteLinkedObjects }) },
  );

  if (response.ok) {
    const { layerId: newLayerId } = (await response.json()) as RemoveDatabaseConfigResponse;
    return newLayerId;
  }

  try {
    throw new ServerError(
      'Failed to remove database config!',
      (await response.json()) as ErrorsResponse,
    );
  } catch (error) {
    reportError(error);
  }
  return null;
}

type RemoveDatabaseConfigResponse = {
  layerId: LayerId;
};

const OVER_DB_ROW_LIMIT_ERROR = 'overDBRowLimit';
class ServerError extends Error {
  errors: ErrorsResponse;

  constructor(message: string, blob: ErrorsResponse) {
    super(message);

    this.errors = blob;
  }

  isOverLimitError() {
    return OVER_DB_ROW_LIMIT_ERROR in this.errors.errors;
  }

  getOverLimitError() {
    if (!this.isOverLimitError()) {
      return null;
    }

    if (this.errors.errors[OVER_DB_ROW_LIMIT_ERROR]?.length === 0) {
      return null;
    }

    const [{ count, limit }] = this.errors.errors[OVER_DB_ROW_LIMIT_ERROR] as Array<{
      count: number;
      limit: number;
    }>;
    return new OverLimitError(count, limit);
  }
}

class OverLimitError extends Error {
  count: number;
  limit: number;

  constructor(count: number, limit: number) {
    super('Over row limit');
    this.count = count;
    this.limit = limit;
  }
}

function reportError(error: unknown) {
  const extra: Partial<{ errors: string }> = {};
  if (error instanceof ServerError) {
    extra.errors = JSON.stringify(error.errors);
  }
  console.error(error);
  Sentry.captureException(error, { extra });
}

type ErrorsResponse = {
  errors: Record<string, unknown[]>;
};

export function buildDatabaseConfig(
  databaseConfig: DatabaseConfig,
  fields: {
    source: DatabaseSource;
    timeColumn: TimeColumn | null;
    driverColumns: ExtTableDriverColumn[] | DatabaseDriverColumn[];
    dimensionColumns: ExtTableDimensionColumn[] | DatabaseDimensionColumn[];
    metadataColumns: ExtTableDimensionColumn[] | DatabaseDimensionColumn[];
    filters: ExtTableFilter[] | DatabaseFilter[];
  },
) {
  if (fields.source == null) {
    return null;
  }
  if (fields.source.type === DatabaseConfigSourceType.Database) {
    return buildDatabaseConfigForDatabase(databaseConfig, fields);
  }
  return buildDatabaseConfigForExtTable(databaseConfig, fields);
}

function buildDatabaseConfigForDatabase(
  databaseConfig: DatabaseConfig,
  fields: {
    source: DatabaseSource;
    timeColumn: TimeColumn | null;
    driverColumns: ExtTableDriverColumn[] | DatabaseDriverColumn[];
    dimensionColumns: ExtTableDimensionColumn[] | DatabaseDimensionColumn[];
    metadataColumns: ExtTableDimensionColumn[] | DatabaseDimensionColumn[];
    filters: ExtTableFilter[] | DatabaseFilter[];
  },
) {
  if (databaseConfig == null) {
    return null;
  }
  const newConfig = structuredClone(databaseConfig);
  newConfig.source = DatabaseConfigSourceType.Database;

  if (databaseConfig.database == null || newConfig.database == null) {
    newConfig.database = {
      sourceBusinessObjectSpecId: fields.source.id,
      driverColumns: fields.driverColumns.filter(isDatabaseDriverColumn),
      segmentedByColumns: fields.dimensionColumns.filter(isDatabaseDimensionColumn),
      metadataColumns: fields.metadataColumns.filter(isDatabaseDimensionColumn),
      filters: fields.filters.filter(isDatabaseFilter),
    };
    return newConfig;
  }

  if (databaseConfig.database.sourceBusinessObjectSpecId !== fields.source.id) {
    newConfig.database = {
      sourceBusinessObjectSpecId: fields.source.id,
      driverColumns: fields.driverColumns.filter(isDatabaseDriverColumn),
      segmentedByColumns: fields.dimensionColumns.filter(isDatabaseDimensionColumn),
      metadataColumns: fields.metadataColumns.filter(isDatabaseDimensionColumn),
      filters: fields.filters.filter(isDatabaseFilter),
    };
    return newConfig;
  }

  newConfig.extTable = undefined;
  newConfig.database.filters = fields.filters.filter(isDatabaseFilter);

  const existingDriverPropertyByPropertyId = keyBy(
    databaseConfig.database.driverColumns ?? [],
    ({ sourceDriverPropertyId }) => sourceDriverPropertyId,
  );
  newConfig.database.driverColumns = fields.driverColumns
    .filter(isDatabaseDriverColumn)
    .map((column) => {
      const existingProperty = existingDriverPropertyByPropertyId[column.sourceDriverPropertyId];
      if (existingProperty != null) {
        column.driverPropertyId = existingProperty.driverPropertyId;
      }
      return column;
    });

  const existingDimensionColumnsByColumnId = keyBy(
    databaseConfig.database.segmentedByColumns ?? [],
    ({ sourceDimensionalPropertyId }) => sourceDimensionalPropertyId,
  );
  newConfig.database.segmentedByColumns = fields.dimensionColumns
    .filter(isDatabaseDimensionColumn)
    .map((column) => {
      const existingColumn = existingDimensionColumnsByColumnId[column.sourceDimensionalPropertyId];
      if (existingColumn != null) {
        column.dimensionalPropertyId = existingColumn.dimensionalPropertyId;
      }
      return column;
    });

  const updatedDimensionColumnsByColumnId = keyBy(
    newConfig.database.segmentedByColumns,
    ({ sourceDimensionalPropertyId }) => sourceDimensionalPropertyId,
  );
  const existingMetadataColumnsByColumnId = keyBy(
    databaseConfig.database.metadataColumns ?? [],
    ({ sourceDimensionalPropertyId }) => sourceDimensionalPropertyId,
  );
  newConfig.database.metadataColumns = fields.metadataColumns
    .filter(isDatabaseDimensionColumn)
    // filter out segmented by columns as we don't want duplicates
    .filter((column) => !(column.sourceDimensionalPropertyId in updatedDimensionColumnsByColumnId))
    .map((column) => {
      const existingColumn = existingMetadataColumnsByColumnId[column.sourceDimensionalPropertyId];
      if (existingColumn != null) {
        column.dimensionalPropertyId = existingColumn.dimensionalPropertyId;
      }
      return column;
    });
  return newConfig;
}

function buildDatabaseConfigForExtTable(
  databaseConfig: DatabaseConfig,
  fields: {
    source: DatabaseSource;
    timeColumn: TimeColumn | null;
    driverColumns: ExtTableDriverColumn[] | DatabaseDriverColumn[];
    dimensionColumns: ExtTableDimensionColumn[] | DatabaseDimensionColumn[];
    metadataColumns: ExtTableDimensionColumn[] | DatabaseDimensionColumn[];
    filters: ExtTableFilter[] | DatabaseFilter[];
  },
) {
  if (
    databaseConfig == null ||
    databaseConfig.source !== DatabaseConfigSourceType.ExtTable ||
    fields.source.type !== DatabaseConfigSourceType.ExtTable
  ) {
    return null;
  }
  const newConfig = structuredClone(databaseConfig);
  if (databaseConfig.extTable == null || newConfig.extTable == null) {
    newConfig.extTable = {
      extTableSourceKey: fields.source.table.sourceKey,
      timeColumn: fields.timeColumn,
      driverColumns: fields.driverColumns.filter(isExtTableDriverColumn),
      segmentedByColumns: fields.dimensionColumns.filter(isExtTableDimensionColumn),
      metadataColumns: fields.metadataColumns.filter(isExtTableDimensionColumn),
      filters: fields.filters.filter(isExtTableFilter),
    };
    return newConfig;
  }

  if (databaseConfig.extTable.extTableSourceKey !== fields.source.id) {
    newConfig.extTable = {
      extTableSourceKey: fields.source.id,
      timeColumn: fields.timeColumn,
      driverColumns: fields.driverColumns.filter(isExtTableDriverColumn),
      segmentedByColumns: fields.dimensionColumns.filter(isExtTableDimensionColumn),
      metadataColumns: fields.metadataColumns.filter(isExtTableDimensionColumn),
      filters: fields.filters.filter(isExtTableFilter),
    };
    return newConfig;
  }

  newConfig.extTable.timeColumn = fields.timeColumn;
  newConfig.extTable.filters = fields.filters.filter(isExtTableFilter);

  const existingDriverColumnsByColumnId = keyBy(
    databaseConfig.extTable.driverColumns ?? [],
    ({ extTableColumnId }) => extTableColumnId,
  );
  newConfig.extTable.driverColumns = fields.driverColumns
    .filter(isExtTableDriverColumn)
    .map((column) => {
      const existingColumn = existingDriverColumnsByColumnId[column.extTableColumnId];
      if (existingColumn != null) {
        column.extQueryId = existingColumn.extQueryId;
        column.driverPropertyId = existingColumn.driverPropertyId;
      }
      return column;
    });

  const existingDimensionColumnsByColumnId = keyBy(
    databaseConfig.extTable.segmentedByColumns ?? [],
    ({ extTableColumnId }) => extTableColumnId,
  );
  newConfig.extTable.segmentedByColumns = fields.dimensionColumns
    .filter(isExtTableDimensionColumn)
    .map((column) => {
      const existingColumn = existingDimensionColumnsByColumnId[column.extTableColumnId];
      if (existingColumn != null) {
        column.extQueryId = existingColumn.extQueryId;
        column.dimensionalPropertyId = existingColumn.dimensionalPropertyId;
      }
      return column;
    });

  const existingMetadataColumnsByColumnId = keyBy(
    databaseConfig.extTable.metadataColumns ?? [],
    ({ extTableColumnId }) => extTableColumnId,
  );
  newConfig.extTable.metadataColumns = fields.metadataColumns
    .filter(isExtTableDimensionColumn)
    .map((column) => {
      const existingColumn = existingMetadataColumnsByColumnId[column.extTableColumnId];
      if (existingColumn != null) {
        column.extQueryId = existingColumn.extQueryId;
        column.dimensionalPropertyId = existingColumn.dimensionalPropertyId;
      }
      return column;
    });
  return newConfig;
}

export function getMutationsForDatabaseConfigDeletion(
  layer: Layer,
  databaseConfigId: DatabaseConfigId | null | undefined,
): DatabaseConfigDeletionDatasetMutationInput {
  const mutation: DatabaseConfigDeletionDatasetMutationInput = {
    deleteDatabaseConfigs: [],
    deleteExtQueries: [],
  };

  if (databaseConfigId == null) {
    return mutation;
  }

  const config = layer.databaseConfigs.byId[databaseConfigId];
  if (config == null) {
    return mutation;
  }

  mutation.deleteDatabaseConfigs.push({ id: config.id });
  for (const column of config.extTable?.segmentedByColumns ?? []) {
    if (column.extQueryId == null) {
      continue;
    }
    mutation.deleteExtQueries.push({ id: column.extQueryId });
  }
  for (const column of config.extTable?.driverColumns ?? []) {
    if (column.extQueryId == null) {
      continue;
    }
    mutation.deleteExtQueries.push({ id: column.extQueryId });
  }

  return mutation;
}

export interface DatabaseConfigDeletionDatasetMutationInput extends DatasetMutationInput {
  deleteDatabaseConfigs: DatabaseConfigDeleteInput[];
  deleteExtQueries: ExtQueryDeleteInput[];
}

export function updateDatabaseSegments(
  database: BusinessObjectSpec,
  dimensionsById: Record<DimensionId, Dimension>,
  updatedSegments: DimensionalProperty[],
): AppThunk<void> {
  return (dispatch) => {
    const mutation = buildUpdateForDatabaseSegments(database, dimensionsById, updatedSegments);
    if (mutation == null) {
      return;
    }
    dispatch(submitAutoLayerizedMutations('update-database-segments', [mutation]));
  };
}

// TODO: add a test for this
function buildUpdateForDatabaseSegments(
  database: BusinessObjectSpec,
  dimensionsById: Record<DimensionId, Dimension>,
  updatedSegments: DimensionalProperty[],
): DatasetMutationInput | null {
  const updateDatabase: BusinessObjectSpecUpdateInput = {
    id: database.id,
    updateCollection: {
      addDimensionalProperties: [],
      updateDimensionalProperties: [],
    },
  };

  const mutation: DatasetMutationInput = {
    updateBusinessObjectSpecs: [updateDatabase],
    newDimensions: [],
  };

  // NOTE:
  // - If there's an existing property for a segment, but it's not a key, mark it as a key
  // - If there's no existing property:
  //    - Add a new dimensional property
  //    - Add a dimension if there's not an existing one for the property
  const existingPropertiesById = keyBy(
    database.collection?.dimensionalProperties ?? [],
    ({ id }) => id,
  );
  for (const segment of updatedSegments) {
    const existingProperty = safeObjGet(existingPropertiesById[segment.id]);
    if (existingProperty != null) {
      if (!existingProperty.isDatabaseKey) {
        updateDatabase.updateCollection?.updateDimensionalProperties?.push(
          toggleDimensionalPropertyKey(segment, true),
        );
      }
      continue;
    }

    const dimension = safeObjGet(dimensionsById[segment.dimension.id]);
    if (dimension == null) {
      mutation.newDimensions?.push(buildNewDimension(segment));
    }

    updateDatabase.updateCollection?.addDimensionalProperties?.push(
      buildNewDimensionalProperty(segment),
    );
  }

  // NOTE: Change any properties that don't have a corresponding segment to not be keys
  const segmentsById = keyBy(updatedSegments, ({ id }) => id);
  for (const property of Object.values(existingPropertiesById)) {
    if (!property.isDatabaseKey) {
      continue;
    }

    const foundSegment = safeObjGet(segmentsById[property.id]);
    if (foundSegment != null) {
      continue;
    }

    updateDatabase.updateCollection?.updateDimensionalProperties?.push(
      toggleDimensionalPropertyKey(property, false),
    );
  }

  if (
    updateDatabase.updateCollection?.addDimensionalProperties?.length === 0 &&
    updateDatabase.updateCollection?.updateDimensionalProperties?.length === 0 &&
    mutation.newDimensions?.length === 0
  ) {
    return null;
  }
  return mutation;
}

function buildNewDimensionalProperty(segment: DimensionalProperty): DimensionalPropertyCreateInput {
  return {
    id: segment.id,
    dimensionId: segment.dimension.id,
    name: segment.name,
    isDatabaseKey: true,
  };
}

function toggleDimensionalPropertyKey(
  property: DimensionalProperty,
  isKey: boolean,
): DimensionalPropertyUpdateInput {
  return {
    id: property.id,
    isDatabaseKey: isKey,
  };
}

function buildNewDimension(segment: DimensionalProperty): DimensionCreateInput {
  return {
    id: segment.dimension.id,
    name: segment.dimension.name,
  };
}
