import { Dictionary, uniqWith } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import { DateTime } from 'luxon';

import {
  BusinessObjectFieldCellRef,
  BusinessObjectTimeSeriesCellRef,
  CellRef,
  CellSelection,
  CellType,
} from 'config/cells';
import { INITIAL_VALUE_COLUMN_TYPE, PROPERTY_COLUMN_TYPE } from 'config/modelView';
import { BusinessObjectCreateInput, DatasetMutationInput, ValueType } from 'generated/graphql';
import { getDefaultNamesForObjects } from 'helpers/businessObjects';
import {
  isBusinessObjectFieldCellRef,
  isBusinessObjectFieldTimeSeriesCellRef,
  isColumnKeyEqual,
  isMonthColumnKey,
  isObjectFieldColumnKey,
  isObjectFieldNameColumnKey,
  isRowKeyEqual,
} from 'helpers/cells';
import { getMonthKey, shortMonthFormat } from 'helpers/dates';
import { getAttributeValueString } from 'helpers/dimensionalDrivers';
import { convertTimeSeriesToGql } from 'helpers/gqlDataset';
import { getContiguousKeys } from 'helpers/gridKeys';
import { mergeMutations } from 'helpers/mergeMutations';
import { isEqualIgnoringNullish } from 'helpers/object';
import { HIDDEN_DATA_TEXT } from 'helpers/permissions';
import { peekMutationStateChange } from 'helpers/sortIndex';
import { isNotNull } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import {
  ObjectFieldValueCumulativeUpdate,
  getMutationInputForCreateBusinessObject,
  updateBusinessObjectFieldTimeSeriesMutation,
  updateBusinessObjectFieldValueCumulativeMutation,
  updateBusinessObjectMutations,
} from 'reduxStore/actions/businessObjectMutations';
import { TAB } from 'reduxStore/actions/cellCopyPaste';
import { createAttributeMutation } from 'reduxStore/actions/dimensionMutations';
import { BlockId } from 'reduxStore/models/blocks';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { ValueTimeSeriesWithEmpty } from 'reduxStore/models/timeSeries';
import { Value, formatValueToString, toNumberValue } from 'reduxStore/models/value';
import { setCopiedCells } from 'reduxStore/reducers/pageSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { AppThunk } from 'reduxStore/store';
import { selectedBlockCellKeysSelector } from 'selectors/activeCellSelectionSelector';
import { blockConfigObjectFieldSpecAsTimeSeriesIdSelector } from 'selectors/blocksSelector';
import { isBusinessObjectFieldSpecRestrictedSelector } from 'selectors/businessObjectFieldSpecRestrictedSelector';
import { businessObjectFieldSpecSelector } from 'selectors/businessObjectFieldSpecsSelector';
import { isBusinessObjectNameRestrictedSelector } from 'selectors/businessObjectNameRestrictionsSelector';
import {
  businessObjectFieldForecastTimeSeriesSelector,
  businessObjectFieldTransitionValueSelector,
} from 'selectors/businessObjectTimeSeriesSelector';
import {
  businessObjectFieldInitialValueForSpecIdAndObjectIdSelector,
  businessObjectSelector,
} from 'selectors/businessObjectsSelector';
import {
  computedAttributePropertySelector,
  dimensionalPropertyEvaluatorSelector,
  dimensionalPropertySelector,
  driverPropertySelector,
} from 'selectors/collectionSelector';
import { dimensionSelector } from 'selectors/dimensionsSelector';
import { driverValueForMonthKeySelector } from 'selectors/driverTimeSeriesSelector';
import { fieldSpecDisplayConfigurationSelector } from 'selectors/entityDisplayConfigurationSelector';
import {
  blockDateRangeDateTimeSelector,
  blockDateRangeTranslatedViewAtTimeSelector,
} from 'selectors/pageDateRangeSelector';
import {
  prevailingCellSelectionBlockIdSelector,
  prevailingCellSelectionSelector,
} from 'selectors/prevailingCellSelectionSelector';
import { isResourceRestrictedForUserSelector } from 'selectors/restrictedResourcesSelector';

export function isObjectFieldSelection(
  cellSelection: CellSelection<CellRef> | null,
): cellSelection is
  | CellSelection<BusinessObjectFieldCellRef>
  | CellSelection<BusinessObjectTimeSeriesCellRef> {
  if (cellSelection == null) {
    return false;
  }
  return cellSelection.selectedCells.every((cell) => isBusinessObjectFieldCellRef(cell));
}

export const handleObjectTableCellCopy =
  ({ setClipboardData }: { setClipboardData: (data: string) => void }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const cellSelection = prevailingCellSelectionSelector(state);
    if (!isObjectFieldSelection(cellSelection)) {
      return;
    }

    const { activeCell, selectedCells, blockId } = cellSelection;
    let timeSeriesBlockId = blockId;
    if (blockId == null) {
      timeSeriesBlockId = prevailingCellSelectionBlockIdSelector(state);
    }
    const { orderedRowKeys, orderedColumnKeys } = selectedBlockCellKeysSelector(state);

    const selectedRowKeys = uniqWith(
      selectedCells.map((c) => c.rowKey),
      isEqualIgnoringNullish,
    );

    const selectedColumnKeys = uniqWith(
      selectedCells.map((c) => c.columnKey),
      isEqualIgnoringNullish,
    );

    const contiguousRows = getContiguousKeys(
      selectedRowKeys.map((key) => ({
        key,
        position: orderedRowKeys.findIndex((rk) => isRowKeyEqual(rk, key)),
      })),
      activeCell.rowKey,
    );

    const contiguousColumnKeys = getContiguousKeys(
      selectedColumnKeys.map((key) => ({
        key,
        position: orderedColumnKeys.findIndex((ck) => isColumnKeyEqual(ck, key)),
      })),
      activeCell.columnKey,
    );

    const isNonContiguousRangeSelected =
      selectedCells.length !== contiguousRows.length * contiguousColumnKeys.length;

    const copiedCells = isNonContiguousRangeSelected ? [activeCell] : selectedCells;
    const orderedSelectedRowKeys = orderBy(
      uniqWith(
        copiedCells.map((c) => c.rowKey),
        isEqualIgnoringNullish,
      ),
      (key) => orderedRowKeys.findIndex((rk) => isRowKeyEqual(rk, key)),
    );

    const clipboardData = orderedSelectedRowKeys
      .map((rowKey) => {
        const cellsInRow = orderBy(
          copiedCells.filter((ref) => isRowKeyEqual(ref.rowKey, rowKey)),
          (ref) => orderedColumnKeys.findIndex((ck) => isColumnKeyEqual(ck, ref.columnKey)),
        );
        return cellsInRow
          .map((cell) => formatObjectCellValuesForClipboard(state, cell, timeSeriesBlockId ?? ''))
          .join(TAB);
      })
      .join('\n');

    setClipboardData(clipboardData);
    dispatch(setCopiedCells({ blockId: timeSeriesBlockId, selectedCells: copiedCells }));
  };

export type ObjectTablePasteUpdate =
  | {
      type: 'rename';
      objectId: BusinessObjectId;
      name: string;
    }
  // newAttribute is set when a new attribute is created from a paste (no
  // existing one matched the name)
  | ({ type: 'field'; newAttribute?: string } & ObjectFieldValueCumulativeUpdate)
  | ObjectTimeSeriesUpdate
  | ObjectPasteCreateUpdate;

export type ObjectTimeSeriesUpdate = {
  type: 'timeSeries';
  objectSpecId: BusinessObjectSpecId;
  objectId: BusinessObjectId;
  fieldSpecId: BusinessObjectFieldSpecId;
  values: ValueTimeSeriesWithEmpty;
  newAttributes: Dictionary<string>;
};

type ObjectPasteCreateUpdate = {
  type: 'create';
  objectId: BusinessObjectId;
  objectSpecId: BusinessObjectSpecId;
  fields: ObjectTablePasteUpdate[];
};

export function isObjectPasteCreateUpdate(
  update: ObjectTablePasteUpdate,
): update is ObjectPasteCreateUpdate {
  return update.type === 'create';
}

// Handles pasting both fields and names. When pasting an attribute in a dimensional field, it will
export function objectTablePasteUpdateMutations({
  state,
  objectSpecId,
  updates,
}: {
  state: RootState;
  objectSpecId: BusinessObjectSpecId;
  updates: ObjectTablePasteUpdate[];
}) {
  let currState = state;

  const defaultNames = getDefaultNamesForObjects({ state, objectSpecId, count: updates.length });

  const { layerUpdates, defaultLayerUpdates } = updates
    .map((input) => {
      const mutations = handleObjectTablePasteUpdates(currState, input, objectSpecId, defaultNames);
      currState = peekMutationStateChange(
        currState,
        mergeMutations(mutations.objMutation ?? {}, mutations.attrMutation ?? {}),
      );
      return mutations;
    })
    .reduce(
      (res, { objMutation, attrMutation }) => {
        return {
          layerUpdates: mergeMutations(res.layerUpdates, objMutation ?? {}),
          defaultLayerUpdates: mergeMutations(res.defaultLayerUpdates, attrMutation ?? {}),
        };
      },
      { layerUpdates: {}, defaultLayerUpdates: {} },
    );

  return {
    layerMutation: layerUpdates,
    defaultLayerMutation: defaultLayerUpdates,
  };
}

const newAttrIfNecessary = (
  state: RootState,
  input: ObjectTablePasteUpdate & { type: 'field' },
): DatasetMutationInput | undefined => {
  if (input.newAttribute == null) {
    return undefined;
  }

  if (input.newValue.type !== ValueType.Attribute || input.newValue?.value == null) {
    return undefined;
  }

  const attributeId = input.newValue.value;
  return newAttrForInput({
    state,
    fieldSpecId: input.fieldSpecId,
    attributeId,
    attributeValue: input.newAttribute,
  });
};

const newAttrsIfNecessary = (
  state: RootState,
  input: ObjectTablePasteUpdate & { type: 'timeSeries' },
): DatasetMutationInput | undefined => {
  if (input.newAttributes == null) {
    return undefined;
  }

  let reducedMutation: DatasetMutationInput | undefined;
  return Object.entries(input.newAttributes).reduce((mutation, [attributeId, attributeValue]) => {
    if (attributeValue == null) {
      return mutation;
    }
    const newMutation = newAttrForInput({
      state,
      fieldSpecId: input.fieldSpecId,
      attributeId,
      attributeValue,
    });
    if (newMutation != null) {
      return mutation == null ? newMutation : mergeMutations(mutation, newMutation);
    }
    return mutation;
  }, reducedMutation);
};

const newAttrForInput = ({
  state,
  fieldSpecId,
  attributeId,
  attributeValue,
}: {
  state: RootState;
  fieldSpecId: string;
  attributeId: string;
  attributeValue: string;
}): DatasetMutationInput | undefined => {
  const field = businessObjectFieldSpecSelector(state, { id: fieldSpecId });
  const dimProperty = dimensionalPropertySelector(state, fieldSpecId);

  let dimensionId: string | undefined;
  if (dimProperty != null) {
    dimensionId = dimProperty.dimension.id;
  } else if (field?.type === ValueType.Attribute) {
    dimensionId = field.dimensionId;
  } else {
    return undefined;
  }
  const dimension = dimensionSelector(state, dimensionId);

  return (
    createAttributeMutation({
      dimension,
      attributeId,
      attributeValue,
    }) ?? undefined
  );
};

const handleObjectTablePasteUpdates = (
  state: RootState,
  input: ObjectTablePasteUpdate,
  objectSpecId: BusinessObjectSpecId,
  defaultNames: string[],
): { objMutation?: DatasetMutationInput; attrMutation?: DatasetMutationInput } => {
  if (input.type === 'rename') {
    const { objectId, name } = input;
    return {
      objMutation: updateBusinessObjectMutations({ state, updateInput: { id: objectId, name } }),
    };
  } else if (input.type === 'field') {
    let attrMutation = newAttrIfNecessary(state, input);
    const { defaultLayerMutation, draftMutation } =
      updateBusinessObjectFieldValueCumulativeMutation(state, input) ?? {};
    if (defaultLayerMutation != null) {
      // this mutation technically isn't an attribute mutation, but we need to process it as a default
      // layer mutation so we piggyback on this existing response
      attrMutation =
        attrMutation == null
          ? defaultLayerMutation
          : mergeMutations(attrMutation, defaultLayerMutation);
    }
    return { attrMutation, objMutation: draftMutation };
  } else if (input.type === 'timeSeries') {
    const attrMutation = newAttrsIfNecessary(state, input);

    const objMutation = updateBusinessObjectFieldTimeSeriesMutation(state, [input]) ?? undefined;
    return { attrMutation, objMutation };
  } else if (input.type === 'create') {
    const { objectId, fields } = input;
    const name =
      fields
        .map((f) => (f?.type === 'rename' && !isEmpty(f.name) ? f.name : null))
        .find(isNotNull) ?? defaultNames.shift();
    let newAttributes: DatasetMutationInput = {};
    const createInput: BusinessObjectCreateInput = {
      id: objectId,
      name: name ?? '',
      specId: objectSpecId,
      fields: fields
        .map((f) => {
          if (f?.type === 'field') {
            const attrMutation = newAttrIfNecessary(state, f);
            newAttributes = mergeMutations(newAttributes, attrMutation);
            return {
              fieldSpecId: f.fieldSpecId,
              id: uuidv4(),
              value: {
                actuals: {},
                initialValue: f.newValue.value?.toString(),
                type: f.newValue.type,
              },
            };
          } else if (f?.type === 'timeSeries') {
            // N.B. there's some duplication here with updateBusinessObjectFieldTimeSeriesMutation()
            // but this will soon be deprecated after we switch database fields to drivers
            const attrMutation = newAttrsIfNecessary(state, f);
            newAttributes = mergeMutations(newAttributes, attrMutation);
            const timeSeriesPoints = convertTimeSeriesToGql(f.values);
            const values = Object.values(f.values).filter(isNotNull);
            const type = values.length === 0 ? null : values[0].type;
            if (type == null) {
              return null;
            }
            return {
              fieldSpecId: f.fieldSpecId,
              id: uuidv4(),
              value: {
                actuals: {
                  timeSeries: timeSeriesPoints,
                },
                type,
              },
            };
          } else {
            return null;
          }
        })
        .filter(isNotNull),
    };

    const mutations = getMutationInputForCreateBusinessObject({
      state,
      newObject: createInput,
      withEventGroup: { type: 'default' },
    });

    return { attrMutation: newAttributes, objMutation: mutations };
  } else {
    return {};
  }
};

// Copy data as it appears to the user. So, instead of copying attribute ids,
// copy the value itself. This means that we can handle the pastes in a way
// that is not reliant on copying from within Runway (e.g. from sheets).
// Attributes of dimensions will have to be created lazily on paste.
function formatObjectCellValuesForClipboard(
  state: RootState,
  cell: BusinessObjectFieldCellRef | BusinessObjectTimeSeriesCellRef,
  blockId: BlockId,
): string | null {
  const { objectId } = cell.rowKey;
  if (objectId == null) {
    return null;
  }

  const object = businessObjectSelector(state, objectId);
  if (object == null) {
    return null;
  }

  if (isRestrictedObjectTableCell(state, cell, blockId)) {
    return HIDDEN_DATA_TEXT;
  }

  if (isObjectFieldNameColumnKey(cell.columnKey)) {
    return object.name;
  }

  let value: Value | undefined;
  let fieldSpecId = 'fieldSpecId' in cell.rowKey ? cell.rowKey.fieldSpecId : undefined;
  const layerId = 'layerId' in cell.rowKey ? cell.rowKey.layerId : undefined;

  if (isMonthColumnKey(cell.columnKey)) {
    // The case where there is a field showing as a time series
    const { monthKey } = cell.columnKey;
    if (fieldSpecId == null) {
      fieldSpecId =
        blockId != null
          ? blockConfigObjectFieldSpecAsTimeSeriesIdSelector(state, blockId) ?? undefined
          : undefined;
      if (fieldSpecId == null) {
        return null;
      }
    }

    const matchingObjField = object.fields.find((f) => f.fieldSpecId === fieldSpecId);
    if (matchingObjField == null) {
      return null;
    }

    const ts = businessObjectFieldForecastTimeSeriesSelector(state, {
      businessObjectId: objectId,
      businessObjectFieldSpecId: fieldSpecId,
      blockId,
      layerId,
    });

    value = ts[monthKey];
  } else if (isObjectFieldColumnKey(cell.columnKey)) {
    const { objectFieldSpecId } = cell.columnKey;
    const dimProperty = dimensionalPropertySelector(state, objectFieldSpecId);
    const dimDriverIdForProperty = driverPropertySelector(state, objectFieldSpecId)?.driverId;
    const dimensionalPropertyEvaluator = dimensionalPropertyEvaluatorSelector(state);

    const attributes =
      dimensionalPropertyEvaluator.getKeyAttributePropertiesForBusinessObject(objectId);

    const matchingSubDriverId =
      dimDriverIdForProperty == null
        ? null
        : dimensionalPropertyEvaluator.getSubDriverIdForAttributeIds(
            dimDriverIdForProperty,
            attributes.map((attr) => attr.attribute.id),
          );

    const field = object.fields.find((f) => f.fieldSpecId === objectFieldSpecId);
    if (isResourceRestrictedForUserSelector(state, { resourceId: objectFieldSpecId, blockId })) {
      return HIDDEN_DATA_TEXT;
    }
    if (matchingSubDriverId != null) {
      const [_, endMonth] = blockDateRangeDateTimeSelector(state, blockId);
      const viewAtTime = blockDateRangeTranslatedViewAtTimeSelector(state, blockId);
      const monthKeyToShow = getMonthKey(viewAtTime || endMonth);
      const valueInCell = driverValueForMonthKeySelector(state, {
        driverId: matchingSubDriverId,
        monthKey: monthKeyToShow,
      });

      if (valueInCell == null) {
        return null;
      }

      return formatValueToString(toNumberValue(valueInCell));
    } else if (dimProperty != null) {
      const computedAttributeProperty = computedAttributePropertySelector(state, {
        objectId,
        dimensionalPropertyId: dimProperty.id,
      });
      return computedAttributeProperty != null
        ? getAttributeValueString(computedAttributeProperty.attribute)
        : null;
    } else if (field != null) {
      const transitionValue = businessObjectFieldTransitionValueSelector(state, {
        businessObjectId: objectId,
        businessObjectFieldSpecId: objectFieldSpecId,
        blockId: blockId ?? undefined,
      });

      if (transitionValue == null) {
        return null;
      }

      // Copy the initial value. When you paste the value it will set the initial
      // value so it feels more natural to copy the same one.
      const originalValue = transitionValue.originalValue;
      if (originalValue?.value == null) {
        return null;
      }

      value = originalValue;
      fieldSpecId = objectFieldSpecId;
    } else {
      return null;
    }
  } else if (fieldSpecId != null && 'columnType' in cell.columnKey) {
    if (isResourceRestrictedForUserSelector(state, { resourceId: fieldSpecId, blockId })) {
      return HIDDEN_DATA_TEXT;
    }
    if (cell.columnKey.columnType === INITIAL_VALUE_COLUMN_TYPE) {
      value = businessObjectFieldInitialValueForSpecIdAndObjectIdSelector(state, {
        businessObjectId: objectId,
        businessObjectFieldSpecId: fieldSpecId,
        layerId,
      });
    } else if (cell.columnKey.columnType === PROPERTY_COLUMN_TYPE) {
      const fieldSpec = businessObjectFieldSpecSelector(state, { id: fieldSpecId });
      return fieldSpec?.name ?? null;
    }
  }

  if (value == null) {
    return null;
  }

  const fieldValue = value;
  const fieldSpec =
    fieldSpecId != null ? businessObjectFieldSpecSelector(state, { id: fieldSpecId }) : undefined;
  if (fieldSpec == null || fieldSpecId == null) {
    return null;
  }

  if (fieldValue.type === ValueType.Timestamp) {
    const dt = DateTime.fromISO(fieldValue.value);
    return shortMonthFormat(dt);
  } else if (fieldValue.type === ValueType.Attribute) {
    if (fieldSpec.type !== ValueType.Attribute) {
      throw new Error('expected copied field to be an attribute field');
    }
    const { dimensionId } = fieldSpec;
    const dimension = dimensionSelector(state, dimensionId);
    if (dimension == null) {
      return null;
    }

    const attr = dimension.attributes.find(({ id }) => id === fieldValue.value);
    if (attr == null) {
      return null;
    }

    return getAttributeValueString(attr);
  } else if (fieldValue.type === ValueType.Number) {
    if (fieldSpec.type !== ValueType.Number) {
      throw new Error('expected copied field to be a number field');
    }

    const displayConfiguration = fieldSpecDisplayConfigurationSelector(state, fieldSpecId);
    return formatValueToString(fieldValue, displayConfiguration, {
      abbreviate: false,
      includeCents: true,
    });
  }

  return null;
}

function isRestrictedObjectTableCell(
  state: RootState,
  cell: BusinessObjectFieldCellRef | BusinessObjectTimeSeriesCellRef,
  blockId: BlockId,
): boolean {
  let isRestricted = false;
  if (cell.type === CellType.ObjectField) {
    if (isObjectFieldNameColumnKey(cell.columnKey)) {
      const objId = cell.rowKey.objectId;
      const specId = objId != null ? businessObjectSelector(state, objId)?.specId : undefined;
      if (specId != null) {
        isRestricted = isBusinessObjectNameRestrictedSelector(state, {
          objectSpecId: specId,
          blockId,
        });
      }
    } else if (isObjectFieldColumnKey(cell.columnKey)) {
      isRestricted = isBusinessObjectFieldSpecRestrictedSelector(state, {
        fieldSpecId: cell.columnKey.objectFieldSpecId,
        blockId,
      });
    }
  } else if (isBusinessObjectFieldTimeSeriesCellRef(cell)) {
    isRestricted = isBusinessObjectFieldSpecRestrictedSelector(state, {
      fieldSpecId: cell.rowKey.fieldSpecId ?? '',
      blockId,
    });
  }

  return isRestricted;
}
