import { difference, uniq } from 'lodash';
import mapValues from 'lodash/mapValues';

import {
  DatasetMutationInput,
  EventCreateInput,
  EventGroupCreateInput,
  EventGroupUpdateInput,
  EventUpdateInput,
} from 'generated/graphql';
import { extractMonthKey } from 'helpers/dates';
import { convertTimeSeriesToGql } from 'helpers/gqlDataset';
import { mergeMutations } from 'helpers/mergeMutations';
import {
  peekMutationStateChange,
  sortIndexMutationsForInsertion,
  stripInsertBeforeId,
} from 'helpers/sortIndex';
import { EventGroupId, EventId, isDriverEvent } from 'reduxStore/models/events';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import {
  eventGroupsWithoutLiveEditsByIdForLayerSelector,
  eventSelector,
  eventsForDriverSelector,
} from 'selectors/eventsAndGroupsSelector';

import { mapEventGroupInput, mapEventInput } from './events';

// If mutations include an event (set OR delta) on a month which has another event and that
// event will have no curve points after clearing the stacked impacts, then we delete it
export const getDeleteEventsToClearStackedEvents = (
  state: RootState,
  eventChanges: EventCreateInput[] | EventUpdateInput[],
) => {
  const eventIdsToDelete = new Set<EventId>();
  const remainingMonthKeysByEventId: Record<EventId, string[]> = {};

  eventChanges.forEach((item) => {
    const monthKeys = (item.customCurvePoints ?? [])
      .filter((point) => point.value != null)
      .map((point) => extractMonthKey(point.time));

    if (monthKeys.length === 0) {
      return;
    }

    let driverId = item.driverId;
    if (driverId == null) {
      const event = eventSelector(state, item.id);
      if (event != null && isDriverEvent(event)) {
        driverId = event.driverId;
      }
    }

    const eventsForDriver =
      driverId != null ? eventsForDriverSelector(state, { id: driverId }) : [];

    if (eventsForDriver.length > 0) {
      // Events to be cleared for months that overlap with updates
      const otherEvents = eventsForDriver.filter((ev) => ev.id !== item.id);

      otherEvents.forEach((otherEvent) => {
        // If we're also updating curve points for the other event in the mutation, we
        // don't consider it for deletion
        const otherEventUpdateEventInput = eventChanges.find(
          (event) => event.id === otherEvent.id && event.customCurvePoints != null,
        );
        if (otherEventUpdateEventInput != null) {
          return;
        }

        let originalRemainingMonthKeys = remainingMonthKeysByEventId[otherEvent.id];
        if (originalRemainingMonthKeys == null) {
          const otherEventCurvePoints = convertTimeSeriesToGql(otherEvent.customCurvePoints) ?? [];
          originalRemainingMonthKeys = otherEventCurvePoints.map((point) =>
            extractMonthKey(point.time),
          );
        }

        // If the other event started with no curve points, we leave it
        if (originalRemainingMonthKeys.length === 0) {
          return;
        }

        const newRemainingMonthKeys = uniq(difference(originalRemainingMonthKeys, monthKeys));

        if (newRemainingMonthKeys.length === 0) {
          eventIdsToDelete.add(otherEvent.id);
        }

        remainingMonthKeysByEventId[otherEvent.id] = newRemainingMonthKeys;
      });
    }
  });

  const deleteEvents = Array.from(eventIdsToDelete).map((id) => ({ id }));

  return deleteEvents;
};

type BulkCreateEventsAndGroupsReturnType = Pick<
  DatasetMutationInput,
  'newEvents' | 'newEventGroups' | 'updateEvents' | 'updateEventGroups' | 'deleteEvents'
>;
export const bulkCreateEventsAndGroupsMutations = (
  state: RootState,
  newItems: {
    events: EventCreateInput[];
    groups: EventGroupCreateInput[];
    extras?: DatasetMutationInput;
  },
  insertBeforeId: EventId | EventGroupId | 'start' | 'end' = 'end',
): BulkCreateEventsAndGroupsReturnType => {
  const hiddenByEventGroupId = mapValues(
    eventGroupsWithoutLiveEditsByIdForLayerSelector(state),
    'hidden',
  );

  const eventGroupUpdates: EventGroupUpdateInput[] = newItems.groups.map((eventGroup) => ({
    id: eventGroup.id,
    eventIds: eventGroup.eventIds,
    eventGroupIds: eventGroup.eventGroupIds,
    parentId: eventGroup.parentId,
    hidden: eventGroup.parentId == null ? undefined : hiddenByEventGroupId[eventGroup.parentId],
  }));

  const eventUpdates: EventUpdateInput[] = newItems.events.map((event) => ({
    id: event.id,
    parentId: event.parentId,
    hidden: event.parentId == null ? undefined : hiddenByEventGroupId[event.parentId],
  }));

  // The order that things will happen on the backend:
  // 1. create events so we know they all exist ahead of reparenting
  // 2. create empty event groups first so we don't have to reparent in any particular order
  // 3. updates establish hierarchy
  const newEvents = newItems.events.map((event) => removeEventInputHierarchy(mapEventInput(event)));
  const newEventGroups = newItems.groups.map((group) =>
    removeEventGroupInputHierarchy(mapEventGroupInput(group)),
  );

  const deleteEvents = getDeleteEventsToClearStackedEvents(state, newEvents);

  let mutations: BulkCreateEventsAndGroupsReturnType = {
    newEvents,
    newEventGroups,
    updateEventGroups: eventGroupUpdates,
    updateEvents: eventUpdates,
    deleteEvents,
  };
  mutations = newItems.extras != null ? mergeMutations(mutations, newItems.extras) : mutations;

  // In order to bulk backfill sortIndexes, it is easier to update the state
  // and then apply sort index updates (if necessary) afterwards.
  state = peekMutationStateChange(state, mutations);

  // N.B. mutations for events are handled before mutations for event groups
  // we want to maintain the sort order passed in the arguments
  const createInputs =
    insertBeforeId === 'end'
      ? [...newEvents, ...newEventGroups]
      : [...newEvents.toReversed(), ...newEventGroups.toReversed()];

  createInputs.forEach((create) => {
    const backfillData = sortIndexMutationsForInsertion(state, create.id, insertBeforeId);
    state = peekMutationStateChange(state, backfillData);
    mutations = mergeMutations(mutations, backfillData);
  });

  return stripInsertBeforeId(mutations);
};

function removeEventInputHierarchy(event: EventCreateInput) {
  delete event.parentId;
  return event;
}

function removeEventGroupInputHierarchy(group: EventGroupCreateInput) {
  delete group.parentId;
  delete group.eventIds;
  delete group.eventGroupIds;
  return group;
}

export default bulkCreateEventsAndGroupsMutations;
