import { deepEqual } from 'fast-equals';
import { useContext, useMemo } from 'react';

import { DriverRowContext } from 'config/driverRowContext';
import { CalculationErrorType, ComparisonColumn, ThresholdDirection } from 'generated/graphql';
import {
  getColorWithComparisonSources,
  getErrorForColumn,
  getValueColor,
  getValueForColumn,
} from 'helpers/blockComparisons';
import { applyRollupReducer } from 'helpers/rollups';
import { safeObjGet } from 'helpers/typescript';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { LayerId } from 'reduxStore/models/layers';
import { ErrorTimeSeries, NumericTimeSeries } from 'reduxStore/models/timeSeries';
import { baselineLayerIdForBlockSelector } from 'selectors/baselineLayerSelector';
import { entityLoadingByMonthKeySelector } from 'selectors/calculationsSelector';
import {
  driverErrorTimeSeriesForLayerSelector,
  driverTimeSeriesForLayerSelector,
  driverTimeSeriesSourceByMonthKeySelector,
} from 'selectors/driverTimeSeriesSelector';
import { currentLayerIdSelector } from 'selectors/layerSelector';
import { driverMilestoneThresholdSelector } from 'selectors/milestonesSelector';
import { CalculationError } from 'types/dataset';
import { MonthKey } from 'types/datetime';

const EMPTY_TIMESERIES: NumericTimeSeries = {};
const EMPTY_ERROR_TIMESERIES: ErrorTimeSeries = {};
const EMPTY_SOURCE_BY_MONTH_KEY: Record<string, 'actuals' | 'forecast'> = {};

export const useTimeSeriesBaseline = (): NumericTimeSeries => {
  const { blockId } = useBlockContext();
  const baselineLayerId = useAppSelector((state) =>
    baselineLayerIdForBlockSelector(state, blockId),
  );
  const { driverId, requiresBaselineLayer } = useContext(DriverRowContext);

  return useAppSelector(
    (state) =>
      requiresBaselineLayer
        ? driverTimeSeriesForLayerSelector(state, {
            id: driverId,
            layerId: baselineLayerId,
          })
        : EMPTY_TIMESERIES,
    deepEqual,
  );
};

export const useErrorTimeSeriesBaseline = (): ErrorTimeSeries => {
  const { blockId } = useBlockContext();
  const baselineLayerId = useAppSelector((state) =>
    baselineLayerIdForBlockSelector(state, blockId),
  );
  const { driverId, requiresBaselineLayer } = useContext(DriverRowContext);

  return useAppSelector(
    (state) =>
      requiresBaselineLayer
        ? driverErrorTimeSeriesForLayerSelector(state, {
            id: driverId,
            layerId: baselineLayerId,
          })
        : EMPTY_ERROR_TIMESERIES,
    deepEqual,
  );
};

export const useIsLoadingByMonthKeyBaseline = (): Record<string, boolean> => {
  const { blockId } = useBlockContext();
  const baselineLayerId = useAppSelector((state) =>
    baselineLayerIdForBlockSelector(state, blockId),
  );
  const { driverId, requiresBaselineLayer, monthKeys } = useContext(DriverRowContext);
  const notLoading = useMemo(
    () => monthKeys.reduce((acc, monthKey) => ({ ...acc, [monthKey]: false }), {}),
    [monthKeys],
  );
  return useAppSelector(
    (state) =>
      requiresBaselineLayer
        ? entityLoadingByMonthKeySelector(state, {
            id: driverId,
            layerId: baselineLayerId,
            monthKeys,
          })
        : notLoading,
    deepEqual,
  );
};

export const useSourceByMonthKeyBaseline = () => {
  const { blockId } = useBlockContext();

  const baselineLayerId = useAppSelector((state) =>
    baselineLayerIdForBlockSelector(state, blockId),
  );
  const { driverId, requiresBaselineLayer } = useContext(DriverRowContext);

  return useAppSelector(
    (state) =>
      requiresBaselineLayer
        ? driverTimeSeriesSourceByMonthKeySelector(state, {
            id: driverId,
            layerId: baselineLayerId,
          })
        : EMPTY_SOURCE_BY_MONTH_KEY,
    deepEqual,
  );
};

export const useThresholdDirection = () => {
  const { driverId, requiresVariance } = useContext(DriverRowContext);

  return useAppSelector((state) =>
    requiresVariance
      ? driverMilestoneThresholdSelector(state, driverId)
      : ThresholdDirection.AboveOrEqual,
  );
};

export type CellColor = 'actuals' | 'forecast' | 'red.600' | 'green.600';

export const useDriverCellValueAndColor = ({
  layerId,
  monthKeys,
  comparisonColumn,
  sourceByMonthKeyBaseline,
  sourceByMonthKeyLayer,
  thresholdDirection,
  timeSeriesBaseline,
  timeSeriesLayer,
  errorTimeSeriesBaseline,
  errorTimeSeriesLayer,
  isLoadingByMonthKeyBaseline,
  isLoadingByMonthKeyLayer,
}: {
  layerId: LayerId;
  monthKeys: MonthKey[];
  comparisonColumn: ComparisonColumn | undefined;
  sourceByMonthKeyBaseline: Record<string, 'forecast' | 'actuals'>;
  sourceByMonthKeyLayer: Record<string, 'forecast' | 'actuals'>;
  thresholdDirection?: ThresholdDirection;
  timeSeriesBaseline: NumericTimeSeries;
  timeSeriesLayer: NumericTimeSeries;
  errorTimeSeriesBaseline: ErrorTimeSeries;
  errorTimeSeriesLayer: ErrorTimeSeries;
  isLoadingByMonthKeyBaseline: Record<string, boolean>;
  isLoadingByMonthKeyLayer: Record<string, boolean>;
}): {
  value: number | undefined;
  error: CalculationError | undefined;
  isLoading: boolean;
  rowValue: number | undefined;
  baselineValue: number | undefined;
  color: CellColor;
} => {
  const {
    comparisonType: comparisonTypeRow,
    rollupReducer,
    driverId,
  } = useContext(DriverRowContext);
  // Depending on the comparison orientation, we either get the comparisonType from the Row or the Column
  const comparisonType = comparisonColumn ?? comparisonTypeRow;
  let value: number | undefined;
  let error: CalculationError | undefined;
  let isLoading: boolean;
  let rowValue: number | undefined;
  let baselineValue: number | undefined;
  let color: CellColor;

  const currentLayerId = useAppSelector(currentLayerIdSelector);

  switch (comparisonType) {
    case ComparisonColumn.LatestVersion:
    case ComparisonColumn.BaselineVersion: {
      const reduced = applyRollupReducer({
        monthKeys,
        values: timeSeriesBaseline,
        errors: errorTimeSeriesBaseline,
        reducer: rollupReducer,
      });
      value = reduced.value;
      error = reduced.error;
      isLoading = monthKeys.some(
        (monthKey) => safeObjGet(isLoadingByMonthKeyLayer[monthKey]) ?? true,
      );
      color = getValueColor(monthKeys, sourceByMonthKeyBaseline);
      break;
    }
    case ComparisonColumn.Variance:
    case ComparisonColumn.VariancePercentage: {
      isLoading = monthKeys.some(
        (monthKey) =>
          (safeObjGet(isLoadingByMonthKeyLayer[monthKey]) ?? true) ||
          (safeObjGet(isLoadingByMonthKeyBaseline[monthKey]) ?? true),
      );
      const reducedBaseline = applyRollupReducer({
        monthKeys,
        values: timeSeriesBaseline,
        errors: errorTimeSeriesBaseline,
        reducer: rollupReducer,
      });
      baselineValue = reducedBaseline.value;

      const reducedRow = applyRollupReducer({
        monthKeys,
        values: timeSeriesLayer,
        errors: errorTimeSeriesLayer,
        reducer: rollupReducer,
      });
      rowValue = reducedRow.value;

      value = getValueForColumn({
        rowValue,
        baselineValue,
        column: comparisonType,
      });

      error = getErrorForColumn({
        rowError: reducedRow.error,
        baselineError: reducedBaseline.error,
        column: comparisonType,
      });

      color = getColorWithComparisonSources({
        monthKeys,
        value,
        baselineValue,
        rowValue,
        subLabel: comparisonType,
        thresholdDirection: thresholdDirection ?? ThresholdDirection.AboveOrEqual,
        baselineVersionTimeSeriesSourceByMonthKey: sourceByMonthKeyBaseline,
        compareVersionTimeSeriesSourceByMonthKey: sourceByMonthKeyLayer,
      });
      break;
    }
    default: {
      const reduced = applyRollupReducer({
        monthKeys,
        values: timeSeriesLayer,
        errors: errorTimeSeriesLayer,
        reducer: rollupReducer,
      });
      value = reduced.value;
      error = reduced.error;
      isLoading = monthKeys.some(
        (monthKey) => safeObjGet(isLoadingByMonthKeyLayer[monthKey]) ?? true,
      );
      color = getValueColor(monthKeys, sourceByMonthKeyLayer);
    }
  }

  // A reference error that originates from the top level driver of a BvA cell
  // should show as "-" without the default color as it is common for drivers
  // to have not existed when a snapshot was taken.
  const isCurrentLayerCell = layerId === currentLayerId;
  const isOriginOfError = error?.originEntity != null && error.originEntity.id === driverId;
  const isRefErr = error?.error === CalculationErrorType.MissingEntity;
  const shouldSuppressErr = isRefErr && !isCurrentLayerCell && isOriginOfError;

  if (error != null && !shouldSuppressErr) {
    color = 'red.600';
  }

  return useMemo(
    () => ({ value, error, isLoading, rowValue, baselineValue, color }),
    [value, error, isLoading, rowValue, baselineValue, color],
  );
};
