import groupBy from 'lodash/groupBy';
import intersectionWith from 'lodash/intersectionWith';
import isString from 'lodash/isString';
import last from 'lodash/last';
import mapValues from 'lodash/mapValues';
import uniq from 'lodash/uniq';

import { BuiltInDimensionType, ValueType } from 'generated/graphql';
import { extractMonthKey } from 'helpers/dates';
import { DependencyListenerEvaluator } from 'helpers/formulaEvaluation/DependenciesListenerEvaluator';
import { DimensionalPropertyEvaluator } from 'helpers/formulaEvaluation/DimensionalPropertyEvaluator';
import {
  AttributeListContext,
  CalendarFilterContext,
  CohortRelativeTimeContext,
  ContextAttributeContext,
  DateContext,
  DateRelativeMonthsContext,
  DateRelativeQuartersContext,
  DateRelativeYearsContext,
  DimDriverRefContext,
  DriverFilterViewContext,
  DriverRefContext,
  ExtDriverRefContext,
  ExtQueryFilterViewContext,
  MatchFilterViewContext,
  ObjectFieldFilterContext,
  ObjectFieldRefContext,
  ObjectRefContext,
  ObjectSpecRefContext,
  RelativeFilterContext,
  SubmodelRefContext,
  SubmodelViewContext,
  VariableRelativeTimeContext,
} from 'helpers/formulaEvaluation/ForecastCalculator/CalculatorParser';
import { Listener } from 'helpers/formulaEvaluation/ForecastCalculator/ForecastCalculator';
import { filterMatchesAttr } from 'helpers/formulaEvaluation/ForecastCalculator/ForecastCalculatorListener';
import { getUuidListFromContext } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaDisplayListener';
import { getObjectFieldUUID } from 'helpers/object';
import { safeObjGet } from 'helpers/typescript';
import { BusinessObject } from 'reduxStore/models/businessObjects';
import { AttributeId } from 'reduxStore/models/dimensions';
import { DriverId, DriverType } from 'reduxStore/models/drivers';
import { RelativeDate, TimeUnit } from 'types/datetime';
import {
  ALL_MONTH_DEPENDENCY_DATE_RANGE,
  Dependency,
  DependencyDateRange,
  THIS_MONTH_RELATIVE_RANGE,
} from 'types/dependencies';
import { ANY_ATTR, COHORT_MONTH, DateReference, NO_ATTR } from 'types/formula';

export type AttributeIdFilter = {
  dimId: string;
  attrId: string | { type: typeof ANY_ATTR | typeof NO_ATTR | typeof COHORT_MONTH };
};

// TODO: for consistency might want to use stack on the listeners
export function getAttributeIdFilters(
  ctx: DriverFilterViewContext | ExtQueryFilterViewContext,
): AttributeIdFilter[] {
  const filters = ctx.attributeFilter();

  const dimDriverFilters: AttributeIdFilter[] = filters.map((filterCtx) => {
    const builtIn = filterCtx.builtInAttributeFilter();
    const calendar =
      builtIn != null && builtIn instanceof CalendarFilterContext ? builtIn : undefined;
    const relative =
      builtIn != null && builtIn instanceof RelativeFilterContext ? builtIn : undefined;
    const userFilter = filterCtx.userAttributeFilter();

    const userFilterUuids = userFilter?.UUID();
    const dimId =
      userFilterUuids != null && userFilterUuids.length > 0
        ? userFilterUuids[0].text
        : calendar != null
          ? BuiltInDimensionType.CalendarTime
          : BuiltInDimensionType.RelativeTime;

    let attrId: string | null = null;
    let attrType: typeof ANY_ATTR | typeof NO_ATTR | typeof COHORT_MONTH = ANY_ATTR;
    if (userFilter != null && userFilterUuids != null) {
      const hasRhs = userFilterUuids.length > 1;
      if (hasRhs) {
        attrId = userFilterUuids[1].text ?? null;
      }
      attrType = userFilter.ANY() != null ? ANY_ATTR : NO_ATTR;
    } else if (relative != null) {
      const hasRhs = relative.NUMBER() != null;
      if (hasRhs) {
        attrId = relative.NUMBER()?.text ?? null;
      }
      attrType =
        relative.ANY() != null
          ? ANY_ATTR
          : relative.COHORT_MONTH() != null
            ? COHORT_MONTH
            : NO_ATTR;
    } else if (calendar != null) {
      const hasRhs = calendar.DATE() != null;
      if (hasRhs) {
        attrId = calendar.DATE()?.text ?? null;
      }
      attrType = calendar.ANY() != null ? ANY_ATTR : NO_ATTR;
    }

    return {
      dimId,
      attrId: attrId == null ? { type: attrType } : attrId,
    };
  });
  return dimDriverFilters;
}

export interface DependencyListenerArgs {
  entityId: string;
  evaluator: DependencyListenerEvaluator;
  dimensionalPropertyEvaluator: DimensionalPropertyEvaluator;
}

type Argument =
  | { type: 'date'; value: DateReference }
  | {
      type: 'dateRange';
      value: DependencyDateRange;
    }
  | {
      type: 'text';
      value: string;
    }
  | {
      type: 'key';
      value: string;
    }
  | {
      type: 'keySet';
      value: Set<string>;
    };

export class DependencyListener implements Listener<Dependency[]> {
  private entityId: string;
  private dependencyStack: Dependency[];
  private argStack: Argument[];
  private evaluator: DependencyListenerEvaluator;
  private dimensionalPropertyEvaluator: DimensionalPropertyEvaluator;

  constructor({ entityId, evaluator, dimensionalPropertyEvaluator }: DependencyListenerArgs) {
    this.dependencyStack = [];
    this.argStack = [];
    this.entityId = entityId;
    this.evaluator = evaluator;
    this.dimensionalPropertyEvaluator = dimensionalPropertyEvaluator;
  }

  getResult(): Dependency[] {
    return this.dependencyStack;
  }

  getTimestampResult(): Dependency[] {
    return this.getResult();
  }

  getSubDriverId(dimDriverId: DriverId, attributeIds: AttributeId[]) {
    const dimDriver = this.evaluator.getDriverById(dimDriverId);
    if (dimDriver == null || dimDriver.type !== DriverType.Dimensional) {
      return undefined;
    }
    const subDriverId = this.dimensionalPropertyEvaluator.getSubDriverIdForAttributeIds(
      dimDriverId,
      attributeIds,
    );
    if (subDriverId == null) {
      return undefined;
    }
    return subDriverId;
  }

  exitDriverRef(ctx: DriverRefContext) {
    const id = ctx.UUID().text;
    const dateRange = this.popDateRangeArg();
    if (dateRange != null) {
      this.pushDep({
        type: 'driver',
        id,
        dateRange,
      });
    }

    // If this is a direct ref to a sub-driver then this will return an array
    // of attributes
    const attributes = this.evaluator.getAttributesByDriverId(id);
    if (attributes == null) {
      return;
    }

    attributes?.forEach((attr) => this.pushDep({ type: 'attribute', id: attr.id }));
    const dimIds = uniq(attributes?.map(({ dimensionId }) => dimensionId) ?? []);
    dimIds.forEach((dimId) => this.pushDep({ type: 'dimension', id: dimId }));
  }

  exitAttributeList(_ctx: AttributeListContext) {
    const keys: string[] = [];

    let arg: Argument | undefined;
    while ((arg = this.argStack.pop())?.type === 'key') {
      keys.push(arg!.value);
    }

    this.argStack.push({ type: 'keySet', value: new Set(keys) });
  }

  exitContextAttribute(ctx: ContextAttributeContext) {
    const subDriverId = this.entityId;
    const dimensionId = ctx.UUID().text;

    const subDriver = this.evaluator.getDriverById(subDriverId);
    if (!subDriver) {
      return;
    }

    const attribute = this.evaluator
      .getAttributesByDriverId(subDriverId)
      .find((attr) => attr.dimensionId === dimensionId);
    if (!attribute) {
      return;
    }

    this.argStack.push({ type: 'key', value: attribute.id });
  }

  exitDimDriverRef(ctx: DimDriverRefContext) {
    const id = ctx.UUID().text;
    const dateRange = this.popDateRangeArg();

    if (dateRange == null) {
      return;
    }

    this.pushDep({
      type: 'driver',
      id,
      dateRange,
    });
  }

  exitSubmodelRef(ctx: SubmodelRefContext) {
    this.pushArg({
      type: 'text',
      value: ctx.UUID().text,
    });
  }

  exitSubmodelView(ctx: SubmodelViewContext) {
    const groupIdFilter = ctx.submodelFilter().driverGroupFilter().UUID().text;
    const submodelArg = this.argStack.pop();
    const submodelId = submodelArg?.type === 'text' ? submodelArg.value : undefined;

    if (submodelId == null) {
      // Submodel must be included as part of the filter
      return;
    }

    // Don't include the driver itself
    const driversForGroup = this.evaluator
      .getDriversByGroupId(groupIdFilter)
      ?.filter((d) => d.id !== this.entityId);
    if (driversForGroup == null) {
      return;
    }

    const driversForSubmodel = this.evaluator
      .getDriversBySubmodelId(submodelId)
      ?.filter((d) => d.id !== this.entityId);
    if (driversForSubmodel == null) {
      return;
    }

    this.pushDep({
      type: 'driverGroup',
      id: groupIdFilter,
    });

    // Drivers referenced should be in both the group and the submodel
    const matchDrivers = intersectionWith(
      driversForSubmodel,
      driversForGroup,
      (a, b) => a.id === b.id,
    );

    matchDrivers.forEach((driver) => {
      if (driver.id === this.entityId || driver.type === DriverType.Dimensional) {
        // Prevent creating a dependency loop. When evaluating, we skip the
        // evaluated driver so it isn't a dependency of itself.
        //
        // Also, skip dim drivers as these can sneak in to a group because they
        // are invisible in the product but if they were actually being
        // referenced they would be handled by the dim driver reference
        // listener.
        return;
      }
      this.pushDep({
        type: 'driver',
        id: driver.id,
        dateRange: THIS_MONTH_RELATIVE_RANGE,
      });
    });
  }

  exitDriverFilterView(ctx: DriverFilterViewContext) {
    const dimDriverDep = this.popDep('driver') as Dependency & { type: 'driver' };
    if (dimDriverDep == null) {
      return;
    }
    const dimDriver = this.evaluator.getDriverById(dimDriverDep.id);
    if (dimDriver?.type !== DriverType.Dimensional) {
      return;
    }

    const dimDriverFilters: AttributeIdFilter[] = getAttributeIdFilters(ctx);

    const filterByDimId = mapValues(groupBy(dimDriverFilters, 'dimId'), (fs) =>
      fs.map((f) => f.attrId),
    );

    const subDrivers = dimDriver.subdrivers.filter((subdriver) => {
      return Object.entries(filterByDimId).every(([dimId, attrIds]) => {
        const attrId = subdriver.attributes.find((attr) => attr.dimensionId === dimId)?.id ?? null;
        return filterMatchesAttr(attrIds, attrId);
      });
    });

    subDrivers.forEach((subDriver) => {
      this.pushDep({
        type: 'driver',
        id: subDriver.driverId,
        dateRange: dimDriverDep.dateRange,
      });
    });

    Object.entries(filterByDimId).forEach(([dimId, attrIds]) => {
      this.pushDep({
        type: 'dimension',
        id: dimId,
      });
      attrIds.forEach((attrId) => {
        if (isString(attrId)) {
          // Only add strict dependencies (no, ALL or NONE)
          this.pushDep({
            type: 'attribute',
            id: attrId,
          });
        }
      });
    });
  }

  exitMatchFilterView(_: MatchFilterViewContext) {
    const dimDriverDep = this.dependencyStack[this.dependencyStack.length - 1] as
      | (Dependency & {
          type: 'driver';
        })
      | undefined;

    if (dimDriverDep == null) {
      return;
    }

    const dimDriver = this.evaluator.getDriverById(dimDriverDep.id);
    if (dimDriver?.type !== DriverType.Dimensional) {
      return;
    }

    const keys = this.argStack.pop()?.value;
    if (!(keys instanceof Set)) {
      return;
    }

    const subDriver = dimDriver.subdrivers.find(({ attributes }) =>
      attributes.every((attr) => keys.has(attr.id)),
    );
    if (!subDriver) {
      return;
    }

    this.pushDep({
      type: 'driver',
      id: subDriver.driverId,
      dateRange: dimDriverDep.dateRange,
    });

    for (const attr of subDriver.attributes) {
      this.pushDep({
        type: 'dimension',
        id: attr.dimensionId,
      });
      this.pushDep({
        type: 'attribute',
        id: attr.id,
      });
    }
  }

  exitObjectFieldRef(ctx: ObjectFieldRefContext) {
    // This is a pretty heavy-handed approach. We count any object field
    // references in a formula as the same level of dependency, regardless of
    // whether it is used directly or as a filter.
    const id = ctx.UUID().text;

    const dateRange = this.popDateRangeArg();
    if (dateRange != null) {
      this.pushDep({
        type: 'businessObjectFieldSpec',
        id,
        dateRange,
      });
    }

    // It is possible that a field was deleted. If it's still present then we
    // can get its dimension dependency.
    const fieldSpec = this.evaluator.getFieldSpecById(id);
    if (fieldSpec?.type === ValueType.Attribute) {
      this.pushDep({ type: 'dimension', id: fieldSpec.dimensionId });
    }

    if (fieldSpec?.isFormula) {
      this.pushDep({ type: 'objectFieldSpecFormula', id: fieldSpec.id, dateRange });
    }
  }

  exitObjectRef(ctx: ObjectRefContext) {
    let obj: BusinessObject | undefined;

    const uuid = ctx.UUID()?.text;
    const isThis = ctx.THIS() != null;
    if (uuid != null) {
      obj = this.evaluator.getObjectById(uuid);
    } else if (isThis) {
      // Get this object
      obj = safeObjGet(this.evaluator.getObjectByFieldId(this.entityId));
    } else {
      throw new Error('expected UUID or THIS object reference');
    }

    if (obj == null) {
      return;
    }

    const mostRecentFieldDep = this.dependencyStack.find(
      (d) => d.type === 'businessObjectFieldSpec',
    ) as (Dependency & { type: 'businessObjectFieldSpec' }) | undefined;

    if (mostRecentFieldDep == null) {
      return;
    }

    const fieldSpecId = mostRecentFieldDep.id;

    const dateRange = mostRecentFieldDep.dateRange ?? THIS_MONTH_RELATIVE_RANGE;

    const objField = obj.fields.find((f) => f.fieldSpecId === fieldSpecId);

    // Need to handle case where the object field hasn't been created yet.
    const objFieldId = objField?.id ?? getObjectFieldUUID(obj.id, fieldSpecId);

    this.pushDep({
      type: 'businessObjectField',
      id: objFieldId,
      dateRange,
    });
  }

  exitObjectSpecRef(ctx: ObjectSpecRefContext) {
    const id = ctx.UUID().text;
    let dateRange: DependencyDateRange | undefined;

    if (ctx.timeRange() != null) {
      // In the case where we are doing a filter on the spec itself (not a
      // field), then we will have a time range in this context.
      dateRange = this.popDateRangeArg();
    } else {
      // In the case where we are filtering a field reference the time range
      // would have been within that field reference.
      const mostRecentFieldDep = this.dependencyStack.find(
        (d) => d.type === 'businessObjectFieldSpec',
      ) as (Dependency & { type: 'businessObjectFieldSpec' }) | undefined;
      dateRange = mostRecentFieldDep?.dateRange;
    }

    this.pushDep({
      type: 'businessObjectSpec',
      id,
      dateRange,
    });
  }

  /**
   * Note: we don't need to add an explicit dependency for the filter, since
   * its dependencies are already captured via the objectFieldRef node. We do,
   * however, need to pop the filter time range off the arg stack as it isn't
   * used elsewhere.
   */
  exitObjectFieldFilter(ctx: ObjectFieldFilterContext) {
    if (ctx.timeRange() != null) {
      this.popDateRangeArg();
    }

    // In order to determine if the variableOrUUID is an attribute dependency,
    // we need to peek at current deps. Due to how the grammar is processed,
    // the last dependency would be a dimension in this case.
    //
    // TODO: An alternative that doesn't rely on the grammar ordering: We could
    // push this dependency in exitVariableOrUUID if we have all known
    // attribute IDs in the listener and check against those.
    const uuids = getUuidListFromContext(ctx);

    const latestDep = last(this.dependencyStack);

    if (uuids.length > 0 && latestDep?.type === 'dimension') {
      uuids.map((uuid) => {
        this.pushDep({ type: 'attribute', id: uuid });
      });
    }
  }

  exitDateRange() {
    const end = this.popDateArg();
    const start = this.popDateArg();
    if (start == null || end == null) {
      return;
    }

    this.pushArg({
      type: 'dateRange',
      value: {
        start,
        end,
      },
    });
  }

  exitDate(ctx: DateContext) {
    const dateLiteral = ctx.DATE().text;
    const monthKey = extractMonthKey(dateLiteral);
    const dateRef: DateReference = { type: 'absolute', val: monthKey };
    this.pushArg({
      type: 'date',
      value: dateRef,
    });
  }

  exitDateRelativeMonths(ctx: DateRelativeMonthsContext) {
    this.handleExitOffsetTime(ctx, TimeUnit.Month, 'today');
  }

  exitDateRelativeQuarters(ctx: DateRelativeQuartersContext) {
    this.handleExitOffsetTime(ctx, TimeUnit.Quarter, 'quarterStart');
  }

  exitDateRelativeYears(ctx: DateRelativeYearsContext) {
    this.handleExitOffsetTime(ctx, TimeUnit.Year, 'yearStart');
  }

  private handleExitOffsetTime(
    ctx: DateRelativeMonthsContext | DateRelativeQuartersContext | DateRelativeYearsContext,
    unit: RelativeDate['unit'],
    reference: RelativeDate['reference'],
  ) {
    const relativeNum = ctx.NUMBER()?.text;
    if (relativeNum == null) {
      throw Error(`Missing number for relative ${unit}`);
    }
    const offset = parseInt(`${ctx.SUB()?.text ?? ''}${relativeNum}`);

    const dateRef: DateReference = {
      type: 'relative',
      val: { unit, val: offset, reference },
    };

    this.pushArg({ type: 'date', value: dateRef });
  }

  exitExtDriverRef(ctx: ExtDriverRefContext) {
    const id = ctx.UUID().text;
    const dateRange = this.popDateRangeArg();
    if (dateRange != null) {
      this.pushDep({
        type: 'extDriver',
        id,
        dateRange,
      });
    }
  }

  exitRelativeTime(ctx: VariableRelativeTimeContext | CohortRelativeTimeContext) {
    const children = ctx.children;
    if (children == null) {
      throw Error(`Relative time missing start/end`);
    }
    const start = parseInt(children[1].text);
    const end = parseInt(children[3].text);
    if (isNaN(start) || isNaN(end)) {
      // This is likely a variable driver reference. Since the range can vary from month to month,
      // just use all months as the range.
      this.pushArg({
        type: 'dateRange',
        value: ALL_MONTH_DEPENDENCY_DATE_RANGE,
      });
      return;
    }
    this.pushArg({
      type: 'dateRange',
      value: {
        start: { type: 'relative', val: { unit: TimeUnit.Month, val: start, reference: 'today' } },
        end: { type: 'relative', val: { unit: TimeUnit.Month, val: end, reference: 'today' } },
      },
    });
  }

  exitVariableRelativeTime(ctx: VariableRelativeTimeContext) {
    this.exitRelativeTime(ctx);
  }

  exitCohortRelativeTime(ctx: CohortRelativeTimeContext) {
    this.exitRelativeTime(ctx);
  }

  private pushDep(dep: Dependency) {
    this.dependencyStack.push(dep);
  }

  private pushArg(arg: Argument) {
    this.argStack.push(arg);
  }

  private popDateRangeArg(): DependencyDateRange | undefined {
    const arg = this._popArg('dateRange');
    if (arg == null || arg.type !== 'dateRange') {
      return undefined;
    }
    return arg.value;
  }

  private popDateArg(): DateReference | undefined {
    const arg = this._popArg('date');
    if (arg == null || arg.type !== 'date') {
      return undefined;
    }
    return arg.value;
  }

  private popTextArg(): string | undefined {
    const arg = this._popArg('text');
    if (arg == null || arg.type !== 'text') {
      return undefined;
    }
    return arg.value;
  }

  private _popArg(type: Argument['type']): Argument | undefined {
    const arg = this.argStack.pop();
    if (arg != null && arg.type !== type) {
      throw new Error(`expected argument of ${type} but got ${arg.type}`);
    }
    return arg;
  }

  private popDep(type: Dependency['type']): Dependency | undefined {
    const dep = this.dependencyStack.pop();
    if (dep != null && dep.type !== type) {
      throw new Error(`expected dependency ${type} but got ${dep.type}`);
    }

    return dep;
  }
}
