import { CellRange, GridApi, IRowNode } from 'ag-grid-community';
import { min } from 'lodash';

import {
  Row,
  isColValueCalculationError,
} from 'components/AgGridComponents/types/DatabaseColumnDef';
import { isNotNull } from 'helpers/typescript';
import { CalculationError } from 'types/dataset';

export function getNewValue(
  monthKeysInRange: string[],
  valueResolver: (monthKey: string) => string | number | CalculationError | undefined,
): Row['data'][string] {
  let firstValue;
  let latestValue;
  for (const monthKey of monthKeysInRange) {
    const value = valueResolver(monthKey);

    // Stop on first error encountered
    if (isColValueCalculationError(value)) {
      return value;
    }

    if (value == null) {
      continue;
    }

    if (firstValue == null) {
      firstValue = value;
    }
    latestValue = value;
  }

  if (firstValue == null || latestValue == null) {
    return null;
  }

  if (firstValue === latestValue) {
    return firstValue;
  }
  return [firstValue, latestValue];
}

export function getCellPaletteAnchorCellFromSelectedRanges(
  selectedCellRanges: CellRange[] | null,
  api: GridApi,
):
  | {
      rowNode: IRowNode;
      colId: string;
    }
  | undefined {
  if (selectedCellRanges == null || selectedCellRanges.length === 0) {
    return undefined;
  }

  const lastCellRange = selectedCellRanges[selectedCellRanges.length - 1];

  // Take the row that's higher on the screen (don't want palette to cover selected cells)
  const { startRow, endRow } = lastCellRange;
  const anchorCellRowIndex = min([startRow?.rowIndex, endRow?.rowIndex].filter(isNotNull));
  if (anchorCellRowIndex == null) {
    return undefined;
  }

  const anchorCellRow = api.getDisplayedRowAtIndex(anchorCellRowIndex);
  if (anchorCellRow == null) {
    return undefined;
  }

  // Take the column where the user ended their selection, regardless of whether they
  // selected left-to-right or right-to-left
  const startColumnId = lastCellRange.startColumn.getColId();
  const firstColumnId = lastCellRange.columns[0].getColId();

  const endColumn =
    firstColumnId === startColumnId
      ? lastCellRange.columns[lastCellRange.columns.length - 1]
      : lastCellRange.columns[0];
  const anchorCellColumnId = endColumn.getColId();

  return {
    rowNode: anchorCellRow,
    colId: anchorCellColumnId,
  };
}
