import { DimensionalPropertyMapping } from 'generated/graphql';
import { AttributeFingerprint, getAttributesFingerprint } from 'helpers/dimensions';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import { BusinessObjectSpecId } from 'reduxStore/models/businessObjectSpecs';
import { BusinessObject, BusinessObjectId } from 'reduxStore/models/businessObjects';
import {
  AttributeProperty,
  Collection,
  DimensionalProperty,
  DimensionalPropertyId,
} from 'reduxStore/models/collections';
import { Attribute, AttributeId, DimensionId } from 'reduxStore/models/dimensions';
import { DimensionalDriver, DriverId } from 'reduxStore/models/drivers';

export type AttributePropertyKey = string;

type AttributePropertyProps = {
  objectId: BusinessObjectId;
  dimensionalPropertyId: DimensionalPropertyId;
};

export type ComputedAttributeProperty = {
  attribute: Attribute;
  dimensionalPropertyId: DimensionalPropertyId;
  isReadOnly: boolean;
  disabledReason?: string;
};

type MappedPropertiesKey = string;

type DimensionalPropertyEvaluatorArgs = {
  businessObjectsById: Record<BusinessObjectId, BusinessObject>;
  businessObjectsBySpecId: Record<BusinessObjectSpecId, BusinessObject[]>;
  dimensionalPropertiesById: Record<DimensionalPropertyId, DimensionalProperty>;
  attributePropertyByKey: Record<AttributePropertyKey, AttributeProperty>;
  collectionsByObjectSpecId: Record<BusinessObjectSpecId, Collection>;
  attributesByExtKey: Record<string, Record<string, Attribute>>;
  allDimensionalDrivers: DimensionalDriver[];
};

export class DimensionalPropertyEvaluator {
  private businessObjectsById: Record<BusinessObjectId, BusinessObject>;
  private businessObjectsBySpecId: Record<BusinessObjectSpecId, BusinessObject[]>;
  private dimensionalPropertiesById: Record<DimensionalPropertyId, DimensionalProperty>;
  private attributePropertyByKey: Record<AttributePropertyKey, AttributeProperty>;
  private collectionsByObjectSpecId: Record<BusinessObjectSpecId, Collection>;
  private cache: Record<AttributePropertyKey, ComputedAttributeProperty>;
  private attributesByExtKey: Record<string, Record<string, Attribute>>;

  // Used to cache the mapping of dimensional properties between two collections.
  // Specifically, we cache which properties are shared between the two collections, which
  // are used to compare and lookup the appropriate match.
  private mappedPropertiesCache: Record<
    MappedPropertiesKey,
    Record<DimensionalPropertyId, DimensionalPropertyId>
  >;

  // Maps a dimensional driver ID to a lookup of [attributeIDs] -> subdriver IDs
  private subdriverLookupCache: Record<DriverId, Record<AttributeFingerprint, DriverId>> = {};

  private keyDimensionsBySpecIdSet = false;
  private keyDimensionsBySpecId: NullableRecord<
    BusinessObjectSpecId,
    Array<{ dimensionalPropertyId: DimensionalPropertyId; dimensionId: DimensionId }>
  > = {};

  // Maps a spec ID to a lookup of [attributeIDs] -> object IDs
  private businessObjectLookupCache: NullableRecord<
    BusinessObjectSpecId,
    NullableRecord<AttributeFingerprint, BusinessObjectId[]>
  > = {};

  // Maps a subdriver ID to an object ID.
  private subdriverObjectLookupCache: Record<DriverId, BusinessObjectId> = {};

  constructor({
    businessObjectsById,
    businessObjectsBySpecId,
    dimensionalPropertiesById,
    attributePropertyByKey,
    collectionsByObjectSpecId,
    attributesByExtKey,
    allDimensionalDrivers,
  }: DimensionalPropertyEvaluatorArgs) {
    this.businessObjectsById = businessObjectsById;
    this.businessObjectsBySpecId = businessObjectsBySpecId;
    this.dimensionalPropertiesById = dimensionalPropertiesById;
    this.attributePropertyByKey = attributePropertyByKey;
    this.collectionsByObjectSpecId = collectionsByObjectSpecId;
    this.cache = {};

    this.attributesByExtKey = attributesByExtKey;
    // This needs to be set before calling the other helpers.
    this.keyDimensionsBySpecId = this.preCalculateKeyDimensionsBySpecId(collectionsByObjectSpecId);
    this.mappedPropertiesCache = this.mapLookupPropertiesByObjectSpecId(collectionsByObjectSpecId);
    this.businessObjectLookupCache =
      this.preCalculateBusinessObjectLookupCache(businessObjectsById);
    this.subdriverLookupCache = this.preCalculateSubdriverLookupCache(
      collectionsByObjectSpecId,
      allDimensionalDrivers,
    );
  }

  private preCalculateSubdriverLookupCache(
    collectionsByObjectSpecId: Record<BusinessObjectSpecId, Collection>,
    allDimensionalDrivers: DimensionalDriver[],
  ): Record<DriverId, Record<AttributeFingerprint, DriverId>> {
    if (!this.keyDimensionsBySpecIdSet) {
      throw new Error('preCalculateKeyDimensionsBySpecId needs to be called before this');
    }
    const res: Record<DriverId, Record<AttributeFingerprint, DriverId>> = {};

    // Even though this class is primarily used for database calculations, we also use
    // it for efficient lookup of subdrivers (by attribute ids) for subdrivers that don't
    // belong to databases.
    allDimensionalDrivers.forEach((dimDriver) => {
      const subdriverLookup: Record<AttributeFingerprint, DriverId> = {};

      dimDriver.subdrivers.forEach((subdriver) => {
        const attributeIds = subdriver.attributes.map((a) => a.id);
        const fingerprint = getAttributesFingerprint(attributeIds);
        subdriverLookup[fingerprint] = subdriver.driverId;
      });

      res[dimDriver.id] = subdriverLookup;
    });

    Object.entries(collectionsByObjectSpecId).forEach(([specId, collection]) => {
      const keyDimIds = this.keyDimensionsBySpecId[specId];
      if (keyDimIds == null || keyDimIds.length === 0) {
        return;
      }

      collection.driverProperties.forEach((driverProperty) => {
        const dimDriverId = driverProperty.driverId;

        // Use the computed subdriver lookup to map every subdriver to an object.
        safeObjGet(this.businessObjectsBySpecId[specId])?.forEach((object) => {
          const attributes = this.getKeyAttributePropertiesForBusinessObject(object.id);
          const attributeIds = attributes.map((attr) => attr.attribute.id);

          const fingerprint = getAttributesFingerprint(attributeIds);
          const fingerprintsForDimDriver = safeObjGet(res[dimDriverId]);
          const subdriverId = fingerprintsForDimDriver?.[fingerprint];
          if (subdriverId == null) {
            return;
          }

          this.subdriverObjectLookupCache[subdriverId] = object.id;
        });
      });
    });

    return res;
  }

  private preCalculateKeyDimensionsBySpecId(
    collectionsByObjectSpecId: Record<BusinessObjectSpecId, Collection>,
  ): NullableRecord<
    BusinessObjectSpecId,
    Array<{ dimensionalPropertyId: DimensionalPropertyId; dimensionId: DimensionId }>
  > {
    this.keyDimensionsBySpecIdSet = true;
    return Object.entries(collectionsByObjectSpecId).reduce(
      (
        acc: Record<
          BusinessObjectSpecId,
          Array<{ dimensionalPropertyId: DimensionalPropertyId; dimensionId: DimensionId }>
        >,
        [specId, collection],
      ) => {
        const keyDimIds = collection.dimensionalProperties
          .filter((dimProperty) => dimProperty.isDatabaseKey)
          .map((dimProperty) => ({
            dimensionalPropertyId: dimProperty.id,
            dimensionId: dimProperty.dimension.id,
          }));
        acc[specId] = keyDimIds;
        return acc;
      },
      {},
    );
  }

  private preCalculateBusinessObjectLookupCache(
    businessObjectsById: Record<BusinessObjectId, BusinessObject>,
  ) {
    if (!this.keyDimensionsBySpecIdSet) {
      throw new Error('preCalculateKeyDimensionsBySpecId needs to be called before this');
    }
    const res: Record<BusinessObjectSpecId, Record<AttributeFingerprint, BusinessObjectId[]>> = {};

    Object.values(businessObjectsById).forEach((object) => {
      const specId = object.specId;

      const keyDimIds = this.keyDimensionsBySpecId[specId];
      if (keyDimIds == null || keyDimIds.length === 0) {
        return;
      }

      const attributes = this.getKeyAttributePropertiesForBusinessObject(object.id);
      if (attributes.length === 0) {
        return;
      }

      const fingerprint = getAttributesFingerprint(attributes.map((attr) => attr.attribute.id));
      res[specId] ??= {};
      res[specId][fingerprint] ??= [];
      res[specId][fingerprint].push(object.id);
    });

    return res;
  }

  private mapLookupPropertiesByObjectSpecId(
    collectionsBySpecId: Record<BusinessObjectSpecId, Collection>,
  ): Record<MappedPropertiesKey, Record<DimensionalPropertyId, DimensionalPropertyId>> {
    const lookupMap: Record<
      MappedPropertiesKey,
      Record<DimensionalPropertyId, DimensionalPropertyId>
    > = {};
    Object.entries(collectionsBySpecId).forEach(([specId, collection]) => {
      const dimProperties = collection.dimensionalProperties ?? [];
      dimProperties.forEach((prop) => {
        if (prop.mapping != null) {
          const { lookupSpecId } = prop.mapping;
          const lookupCollection = collectionsBySpecId[lookupSpecId];
          if (lookupCollection == null) {
            return;
          }
          const lookupKey = this.getLookupKey(specId, lookupSpecId);
          if (lookupKey in lookupMap) {
            return;
          }
          const lookupPropertiesMapping = this.getLookupPropertyIdMapping(
            collection,
            lookupCollection,
          );
          lookupMap[lookupKey] = lookupPropertiesMapping;
        }
      });
    });
    return lookupMap;
  }

  private getLookupPropertyIdMapping(
    sourceCollection: Collection,
    targetCollection: Collection,
  ): Record<DimensionalPropertyId, DimensionalPropertyId> {
    const thisSpecToLookupSpecPropertyIdMapping: Record<
      DimensionalPropertyId,
      DimensionalPropertyId
    > = {};
    const allLookupPropertyIds = new Set<DimensionalPropertyId>();
    sourceCollection.dimensionalProperties.forEach((prop) => {
      // don't use mapped properties as lookup properties
      if (prop.mapping != null) {
        return;
      }
      const lookupProperty = targetCollection.dimensionalProperties.find(
        (lp) => lp.dimension.id === prop.dimension.id && !allLookupPropertyIds.has(lp.id),
      );
      if (lookupProperty == null) {
        return;
      }
      thisSpecToLookupSpecPropertyIdMapping[prop.id] = lookupProperty.id;
      allLookupPropertyIds.add(lookupProperty.id);
    });
    return thisSpecToLookupSpecPropertyIdMapping;
  }

  private getLookupKey(
    sourceSpecId: BusinessObjectSpecId,
    targetSpecId: BusinessObjectSpecId,
  ): MappedPropertiesKey {
    return `${sourceSpecId}-${targetSpecId}`;
  }

  getKeyAttributePropertiesForBusinessObject(
    objectId: BusinessObjectId,
  ): ComputedAttributeProperty[] {
    const object = this.businessObjectsById[objectId];
    if (object == null) {
      return [];
    }

    const computedAttributeProperties = (this.keyDimensionsBySpecId[object.specId] ?? [])
      .map(({ dimensionalPropertyId }) => {
        return this.getAttributeProperty({
          objectId,
          dimensionalPropertyId,
        });
      })
      .filter(isNotNull);

    return computedAttributeProperties;
  }

  getBusinessObjectIdBySubDriverId(subdriverId: DriverId): string | undefined {
    return this.subdriverObjectLookupCache[subdriverId];
  }

  getAttributeProperty(props: AttributePropertyProps): ComputedAttributeProperty | null {
    const { objectId, dimensionalPropertyId } = props;
    const key = getAttributePropertyKey(props);
    if (key in this.cache) {
      return this.cache[key];
    }
    const object = this.businessObjectsById[objectId];
    if (object == null) {
      return null;
    }

    const dimensionalProperty = this.dimensionalPropertiesById[dimensionalPropertyId];
    if (dimensionalProperty == null) {
      return null;
    }

    const mapping = dimensionalProperty?.mapping;
    if (mapping != null) {
      const useSearchDimensionPropertyId = mapping.searchDimensionPropertyId != null;
      const mappedAttribute = useSearchDimensionPropertyId
        ? this.calculateLookupProperty(object, mapping)
        : this.calculateMappedProperty(object, mapping);
      if (mappedAttribute == null) {
        return null;
      }
      const computedAttributeProperty = {
        attribute: mappedAttribute,
        dimensionalPropertyId: dimensionalProperty.id,
        isReadOnly: true,
        disabledReason: 'Value is autofilled',
      };
      this.cache[key] = computedAttributeProperty;
      return computedAttributeProperty;
    }

    const extFieldSpecKey = dimensionalProperty.extFieldSpecKey;
    if (extFieldSpecKey != null) {
      const mappedAttribute = this.fetchExtMappedProperty(object, extFieldSpecKey);
      if (mappedAttribute != null) {
        const computedAttributeProperty = {
          attribute: mappedAttribute,
          dimensionalPropertyId: dimensionalProperty.id,
          isReadOnly: true,
          disabledReason: 'Value is sourced from an integration',
        };
        this.cache[key] = computedAttributeProperty;
        return computedAttributeProperty;
      }
    }

    // Last so dimensional properties have precedence
    if (this.attributePropertyByKey[key] != null) {
      const attributeProperty = {
        attribute: this.attributePropertyByKey[key].attribute,
        dimensionalPropertyId: dimensionalProperty.id,
        isReadOnly: false,
      };
      this.cache[key] = attributeProperty;
      return attributeProperty;
    }

    return null;
  }

  public getSubDriverIdForAttributeIds(
    driverId: DriverId,
    attributeIds: AttributeId[],
  ): DriverId | undefined {
    const fingerprint = getAttributesFingerprint(attributeIds);
    const res = this.subdriverLookupCache[driverId]?.[fingerprint];
    return res;
  }

  public getObjectsWithDuplicateKeysForBusinessObjectSpec(
    specId: BusinessObjectSpecId,
  ): BusinessObjectId[] {
    const objectsByKey = this.businessObjectLookupCache[specId];
    if (objectsByKey == null) {
      return [];
    }

    return Object.values(objectsByKey)
      .filter(isNotNull)
      .filter((ids) => ids.length > 1)
      .flat();
  }

  private fetchExtMappedProperty(
    object: BusinessObject,
    extFieldSpecKey: string,
  ): Attribute | null {
    if (object.extKey == null || this.attributesByExtKey[object.extKey] == null) {
      return null;
    }
    return this.attributesByExtKey[object.extKey][extFieldSpecKey];
  }

  /**
   * Calculates the mapped property for a given object and mapping.
   *
   * @param object - The business object.
   * @param mapping - The dimensional property mapping.
   * @returns The attribute corresponding to the mapped property, or null if not found.
   * @deprecated should use calculateLookupProperty going forward
   */
  private calculateMappedProperty(
    object: BusinessObject,
    mapping: DimensionalPropertyMapping,
  ): Attribute | null {
    const { lookupSpecId, resultPropertyId } = mapping;

    const specId = object.specId;
    // This fetches a precomputed mapping of matching dimensional properties
    // between the source and target databases. These will be the properties
    // used for comparison and finding the appropiate match to lookup.
    const thisSpecToLookupSpecPropertyIdMapping: Record<
      DimensionalPropertyId,
      DimensionalPropertyId
    > = this.mappedPropertiesCache[this.getLookupKey(specId, lookupSpecId)] ?? {};
    const allLookupPropertyIds = Object.values(thisSpecToLookupSpecPropertyIdMapping);

    const attributeIdByLookupPropertyIdMap: Record<DimensionalPropertyId, AttributeId> = {};
    Object.keys(thisSpecToLookupSpecPropertyIdMapping).forEach((propertyId) => {
      const lookupPropertyId = thisSpecToLookupSpecPropertyIdMapping[propertyId];
      const thisSpecAttributeValue = this.getAttributeProperty({
        objectId: object.id,
        dimensionalPropertyId: propertyId,
      });
      if (thisSpecAttributeValue == null) {
        return;
      }
      attributeIdByLookupPropertyIdMap[lookupPropertyId] = thisSpecAttributeValue.attribute.id;
    });

    const lookupObjects = this.businessObjectsBySpecId[lookupSpecId] ?? [];
    const allLookupPropertyIdsArr = Array.from(allLookupPropertyIds);
    const matchingLookupObject = lookupObjects.find((obj) => {
      return allLookupPropertyIdsArr.every((lookupPropertyId) => {
        const lookupProperty = obj.collectionEntry?.attributeProperties.find(
          (a) =>
            a.dimensionalPropertyId === lookupPropertyId &&
            a.attribute?.id === attributeIdByLookupPropertyIdMap[lookupPropertyId],
        );
        return lookupProperty != null;
      });
    });
    if (matchingLookupObject == null) {
      return null;
    }
    const resultProperty = matchingLookupObject.collectionEntry?.attributeProperties.find(
      (a) => a.dimensionalPropertyId === resultPropertyId,
    );
    if (resultProperty == null) {
      return null;
    }
    return resultProperty.attribute;
  }

  /**
   * Calculates the lookup property for a given object and mapping.
   *
   * @param object - The business object for which to calculate the lookup property.
   * @param mapping - The dimensional property mapping used to calculate the lookup property.
   * @returns The attribute representing the lookup property, or null if the lookup property cannot be calculated.
   */
  private calculateLookupProperty(
    object: BusinessObject,
    mapping: DimensionalPropertyMapping,
  ): Attribute | null {
    const { lookupSpecId, resultPropertyId, searchDimensionPropertyId } = mapping;

    const specId = object.specId;

    // Step 1. Find and validate the existence of the lookup and current collections
    const lookupCollection = safeObjGet(this.collectionsByObjectSpecId[lookupSpecId]);
    const currentCollection = safeObjGet(this.collectionsByObjectSpecId[specId]);

    if (lookupCollection == null || currentCollection == null) {
      return null;
    }

    const lookupObjects = this.businessObjectsBySpecId[lookupSpecId] ?? [];

    // Don't allow lookup if there are no objects in the lookup spec
    if (lookupObjects.length === 0) {
      return null;
    }

    // Step 2. Find and validate that the search dimension exists in the lookup spec and current spec
    const searchPropertyInCurrentSpec = currentCollection.dimensionalProperties.find(
      (d) => d.dimension.id === searchDimensionPropertyId,
    );

    if (searchPropertyInCurrentSpec == null) {
      return null;
    }

    const searchPropertyInLookupSpec = lookupCollection.dimensionalProperties.find(
      (d) => d.dimension.id === searchDimensionPropertyId,
    );

    if (searchPropertyInLookupSpec == null) {
      return null;
    }

    // Step 3. Find and Validate that the result property exists in the lookup spec
    const resultPropertyInLookupSpec = lookupCollection.dimensionalProperties.find(
      (d) => d.id === resultPropertyId,
    );

    if (resultPropertyInLookupSpec == null) {
      return null;
    }

    /** Step 4:
     * Iterate over lookup spec objects, find the first object that that shares the same search dimension attribute as the current object search dimension
     * and return the result property dimension attribute value for that object to complete the mapping
     */

    const currentSpecAttributeValue = this.getAttributeProperty({
      objectId: object.id,
      dimensionalPropertyId: searchPropertyInCurrentSpec.id,
    });

    if (currentSpecAttributeValue == null) {
      return null;
    }

    const matchingLookupObject = lookupObjects.find((obj) => {
      const lookupSpecAttributeValue = this.getAttributeProperty({
        dimensionalPropertyId: searchPropertyInLookupSpec.id,
        objectId: obj.id,
      });
      return lookupSpecAttributeValue?.attribute.id === currentSpecAttributeValue.attribute.id;
    });

    if (matchingLookupObject == null) {
      return null;
    }

    const resultProperty = this.getAttributeProperty({
      dimensionalPropertyId: resultPropertyId,
      objectId: matchingLookupObject.id,
    });

    if (resultProperty == null) {
      return null;
    }
    return resultProperty.attribute;
  }
}

export function getAttributePropertyKey(props: AttributePropertyProps): AttributePropertyKey {
  return `${props.objectId}:${props.dimensionalPropertyId}`;
}
