import Router from 'next/router';
import { ParsedUrlQueryInput } from 'querystring';
import React from 'react';
import { UrlObject, format } from 'url';

import { platform } from 'helpers/environment';

export type NavigationOptions = { openInNewTab: boolean; query?: ParsedUrlQueryInput };

export function getNavigationOpts(
  ev: React.MouseEvent | KeyboardEvent | undefined,
): NavigationOptions {
  return { openInNewTab: isNewTabClick(ev) };
}

function isNewTabClick(ev: React.MouseEvent | KeyboardEvent | undefined): boolean {
  if (ev == null || 'key' in ev) {
    return false;
  }

  return (platform() === 'Mac' && ev.metaKey) || ev.ctrlKey;
}

export function routeTo(
  url: UrlObject | string,
  { openInNewTab, query }: NavigationOptions = { openInNewTab: false },
) {
  const urlObject = typeof url === 'string' ? ({ pathname: url } as UrlObject) : url;

  if (query != null) {
    if (urlObject.query == null) {
      urlObject.query = query;
    } else if (typeof urlObject.query === 'object') {
      urlObject.query = { ...urlObject.query, ...query };
    }
  }

  if (openInNewTab) {
    window.open(format(urlObject), '_blank');
  } else {
    Router.push(urlObject, undefined, { shallow: true });
  }
}
