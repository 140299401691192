import { omit, partition } from 'lodash';
import { DateTime } from 'luxon';

import {
  DatasetMutationInput,
  EventCreateInput,
  EventUpdateInput,
  ImpactType,
  TimeSeriesPointInput,
} from 'generated/graphql';
import { mergeAllMutations } from 'helpers/mergeMutations';
import { uuidv4 } from 'helpers/uuidv4';
import { Event, isSetImpactEvent } from 'reduxStore/models/events';
import { LayerId } from 'reduxStore/models/layers';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { eventsByIdForLayerSelector } from 'selectors/eventsAndGroupsSelector';

function hasNewMutationField(input: EventCreateInput | EventUpdateInput) {
  return input.time != null;
}

function eventHasNewMutationField(event: Event) {
  return event.monthKey != null;
}

function hasCurvePoints(
  input: EventCreateInput | EventUpdateInput,
): input is (EventCreateInput | EventUpdateInput) & { customCurvePoints: TimeSeriesPointInput[] } {
  return (input.customCurvePoints ?? []).length > 0;
}

function getCurvePointMutationFields(curvePoint: TimeSeriesPointInput) {
  const start = curvePoint.time;
  const end = DateTime.fromISO(start).endOf('month').startOf('second').toISO();

  const curvePointRelatedFields = {
    start,
    end,

    customCurvePoints: [curvePoint],

    time: curvePoint.time,
    value: curvePoint.value,
  };

  return curvePointRelatedFields;
}

/**
 * If the EventCreateInput has multiple custom curve points, split the newEvent into multiple newEvents
 */
function convertEventCreateInputToSingleCurvePoints(
  eventCreateInput: EventCreateInput,
): DatasetMutationInput {
  if (hasNewMutationField(eventCreateInput)) {
    return { newEvents: [eventCreateInput] };
  }

  if (!hasCurvePoints(eventCreateInput)) {
    return {
      newEvents: [
        {
          ...eventCreateInput,
          // Even if there are no curve points, we still need to add in the time field
          time: eventCreateInput.start,
        },
      ],
    };
  }

  if (eventCreateInput.customCurvePoints.length === 1) {
    const curvePoint = eventCreateInput.customCurvePoints[0];
    return {
      newEvents: [
        {
          ...eventCreateInput,
          // Add in the new fields
          time: curvePoint.time,
          value: curvePoint.value,
        },
      ],
    };
  }

  // This event could be referenced in a later mutation, so we don't throw it away completely.
  // Instead, just update it to have the first curve point, and create new events for the rest.
  const firstCurvePoint = eventCreateInput.customCurvePoints[0];
  const originalEventCreateInput = {
    ...eventCreateInput,
    ...getCurvePointMutationFields(firstCurvePoint),
  };

  const newEvents: EventCreateInput[] = eventCreateInput.customCurvePoints
    .slice(1)
    .map((curvePoint) => ({
      ...eventCreateInput,
      ...getCurvePointMutationFields(curvePoint),
      id: uuidv4(),
    }));

  return {
    newEvents: [originalEventCreateInput, ...newEvents],
  };
}

function createEventInputWithSingleTimeSeriesPoint(
  baseEvent: Event,
  eventUpdateInput: EventUpdateInput,
  point: TimeSeriesPointInput,
): EventCreateInput {
  // Fields to take from eventUpdateInput
  const impactType = eventUpdateInput.impactType ?? baseEvent.impactType;
  const setValueType =
    eventUpdateInput.setValueType ??
    (isSetImpactEvent(baseEvent) ? baseEvent.valueType : undefined);

  // Fields to take from baseEvent
  const { id: _id, version: _, monthKey: _mk, value: _value, ...baseEventProperties } = baseEvent;

  const curvePointMutationFields = getCurvePointMutationFields(point);

  const commonMutationFields = {
    ...omit(baseEventProperties, 'valueType'),
    ...curvePointMutationFields,
    impactType,
    id: uuidv4(),
  };

  if (impactType === ImpactType.Delta) {
    return {
      ...commonMutationFields,
    };
  }

  return {
    ...commonMutationFields,
    setValueType,
  };
}

/**
 * If the EventUpdateInput has multiple custom curve points, split it into multiple newEvents
 */
function convertEventUpdateInputToSingleCurvePoints(
  state: RootState,
  eventUpdateInput: EventUpdateInput,
  layerId?: LayerId,
): DatasetMutationInput {
  if (hasNewMutationField(eventUpdateInput)) {
    // If mutation is already using the new schema, just return the mutation
    return { updateEvents: [eventUpdateInput] };
  }

  const existingEventsById = eventsByIdForLayerSelector(state, { layerId });
  const existingEvent = existingEventsById[eventUpdateInput.id];
  if (existingEvent == null) {
    return { updateEvents: [eventUpdateInput] };
  }

  if (!hasCurvePoints(eventUpdateInput)) {
    return {
      updateEvents: [
        {
          ...eventUpdateInput,
          // Even if there are no curve points, we still need to add in the time field if it doesn't already have it
          ...(eventHasNewMutationField(existingEvent) ? {} : { time: existingEvent.start }),
        },
      ],
    };
  }

  if (eventUpdateInput.customCurvePoints.length === 1) {
    const curvePoint = eventUpdateInput.customCurvePoints[0];
    return {
      updateEvents: [
        {
          ...eventUpdateInput,
          // Add in the new fields
          time: curvePoint.time,
          value: curvePoint.value,
        },
      ],
    };
  }

  // Update original event to only have the first curve point, and create new events for the rest
  const firstCurvePoint = eventUpdateInput.customCurvePoints[0];
  const originalEventUpdateInput = {
    ...structuredClone(eventUpdateInput),
    ...getCurvePointMutationFields(firstCurvePoint),
  };

  const newEvents: EventCreateInput[] = eventUpdateInput.customCurvePoints
    .slice(1)
    .map((curvePoint) => {
      return createEventInputWithSingleTimeSeriesPoint(existingEvent, eventUpdateInput, curvePoint);
    });

  return {
    updateEvents: [originalEventUpdateInput],
    newEvents,
  };
}

/**
 * This is a temporary helper that will be removed after we migrate code completely to use `event.curvePoint` instead of
 * `event.customCurvePoints`. It takes a mutation and enforces that all events created/updated by newEvents and updateEvents
 * only have a single curve point–splitting them up as necessary.
 */
export function getMutationWithSingleCurvePointEvents(
  state: RootState,
  mutation: DatasetMutationInput,
  layerId?: LayerId,
): DatasetMutationInput {
  let res: DatasetMutationInput = structuredClone(mutation);

  if (mutation.newEvents != null) {
    delete res.newEvents;
    const creates = mutation.newEvents.map((eventCreateInput) =>
      convertEventCreateInputToSingleCurvePoints(eventCreateInput),
    );
    res = mergeAllMutations([res, ...creates]);
  }

  if (mutation.updateEvents != null) {
    delete res.updateEvents;

    const [sortIndexUpdates, nonSortIndexUpdates] = partition(
      mutation.updateEvents ?? [],
      (eventUpdateInput) =>
        // We don't need to process events that only have sortIndex and id fields
        eventUpdateInput.sortIndex != null && Object.keys(eventUpdateInput).length <= 2,
    );

    const sortIndexUpdateMutationInput = {
      updateEvents: sortIndexUpdates,
    };

    const nonSortIndexUpdateMutationInputs = nonSortIndexUpdates.map((eventUpdateInput) => {
      return convertEventUpdateInputToSingleCurvePoints(state, eventUpdateInput, layerId);
    });
    res = mergeAllMutations([
      res,
      sortIndexUpdateMutationInput,
      ...nonSortIndexUpdateMutationInputs,
    ]);
  }

  return res;
}
