import { NextPageContext } from 'next';
import { NextUrqlPageContext } from 'next-urql';
import { destroyCookie, parseCookies, setCookie } from 'nookies';

import { backendUrl } from 'helpers/environment';
import { RunwayPageContext } from 'types/RunwayPageContext';

const RUNWAY_DEFAULT_SELECTED_ORG = 'runway_default_selected_org';
const IMPERSONATION_COOKIE = 'impersonation_cookie';

export const LOGIN_REDIRECT = 'login_redirect';
export const DEFAULT_COOKIE_OPTS = { path: '/' };

interface RunwayCookies {
  auth?: string;
  preview_auth?: string;
  dev_auth?: string;
  runway_default_selected_org?: string;
  login_redirect?: string;
  impersonation_cookie?: string;
}

const DAY_IN_SECONDS = 86400;
export const setLoginRedirect = (ctx: NextPageContext) => {
  setCookie(ctx, LOGIN_REDIRECT, ctx.asPath ?? '/', {
    ...DEFAULT_COOKIE_OPTS,
    maxAge: DAY_IN_SECONDS,
  });
};

export const getCookies = (ctx: NextPageContext): RunwayCookies => {
  return parseCookies(ctx) as RunwayCookies;
};

export const getOrgCookie = (ctx: NextPageContext): string | undefined => {
  return getCookies(ctx).runway_default_selected_org;
};

export const setOrgCookie = (ctx: RunwayPageContext, orgSlug: string) => {
  setCookie(ctx, RUNWAY_DEFAULT_SELECTED_ORG, orgSlug, DEFAULT_COOKIE_OPTS);
};

const getImpersonationDomain = () => {
  if (backendUrl?.includes('localhost')) {
    return 'localhost';
  } else if (backendUrl?.includes('runwaydev')) {
    return 'runwaydev.com';
  } else {
    return 'runway.com';
  }
};

const getImpersonationCookie = (
  ctx?: RunwayPageContext | NextUrqlPageContext,
): string | undefined => {
  return parseCookies(ctx).impersonation_cookie;
};

export const setImpersonationCookie = (userId: string, orgSlug: string) => {
  setCookie(null, IMPERSONATION_COOKIE, `${userId},${orgSlug}`, {
    maxAge: 1 * 24 * 60 * 60,
    domain: getImpersonationDomain(),
    ...DEFAULT_COOKIE_OPTS,
  });
};

export const destroyImpersonationCookieIfInvalid = (
  ctx?: RunwayPageContext | NextUrqlPageContext,
  currentOrgSlug?: string,
  callback?: () => void,
) => {
  const cookie = getImpersonationCookie(ctx);
  const isImpersonating = cookie != null;

  if (isImpersonating) {
    const cookieValues = cookie.split(',');
    const savedOrgId = cookieValues.length === 2 ? cookieValues[1] : null;
    if (savedOrgId != null && savedOrgId !== currentOrgSlug) {
      destroyImpersonationCookie(ctx);
      if (callback) {
        callback();
      }
    }
  }
};

export const destroyImpersonationCookie = (ctx?: RunwayPageContext | NextUrqlPageContext) => {
  destroyCookie(ctx, IMPERSONATION_COOKIE, {
    domain: getImpersonationDomain(),
    ...DEFAULT_COOKIE_OPTS,
  });
};

export const destroyOrgCookie = (ctx: RunwayPageContext) => {
  destroyCookie(ctx, RUNWAY_DEFAULT_SELECTED_ORG, DEFAULT_COOKIE_OPTS);
};
