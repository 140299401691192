import React from 'react';

import FormulaEntityChunk from 'components/Formula/FormulaEntityChunk';
import { ThisSegmentDisplayChunk } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaDisplayListener';
import NumberSignIcon from 'vectors/NumberSign';

interface Props {
  chunk: ThisSegmentDisplayChunk;
}

const ThisSegmentChunk: React.FC<Props> = ({ chunk }) => {
  const { displayName, subEntity } = chunk;
  return (
    <FormulaEntityChunk
      label={displayName}
      icon={<NumberSignIcon color="gray.500" />}
      timeRange={subEntity.timeRange}
      timeRangeDisplay={subEntity.timeRangeDisplay}
    />
  );
};

export default React.memo(ThisSegmentChunk);
