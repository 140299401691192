import { createSelector } from '@reduxjs/toolkit';

import { ExtQueryDisplay } from 'reduxStore/models/extQueries';
import { Layer, LayerId } from 'reduxStore/models/layers';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { extTablesByKeySelector } from 'selectors/extTablesSelector';
import { integrationQueriesByIdSelector } from 'selectors/integrationQueriesSelector';
import { currentLayerSelector } from 'selectors/layerSelector';

export const extQueryDisplayByIdSelector = createSelector(
  [
    (state: RootState, { layerId }: { layerId?: LayerId }): Layer =>
      layerId != null ? state.dataset.layers[layerId] : currentLayerSelector(state),
    extTablesByKeySelector,
    integrationQueriesByIdSelector,
  ],
  (currentLayer, extTablesByKey, integrationQueriesById) => {
    const extQueryDisplayById: Record<string, ExtQueryDisplay> = {};
    for (const extQuery of Object.values(currentLayer.extQueries.byId)) {
      const sourceKey = extQuery.parsed?.sourceKey;
      if (sourceKey == null) {
        continue;
      }

      const extTable = extTablesByKey[sourceKey];
      if (extTable == null || extTable.queryId == null) {
        continue;
      }

      const integrationQuery = integrationQueriesById[extTable.queryId];
      if (integrationQuery == null) {
        continue;
      }

      let name = integrationQuery.name;
      const driverColumnName = extQuery.parsed?.driverColumns?.[0];
      if (driverColumnName != null) {
        name += `.${driverColumnName}`;
      }

      extQueryDisplayById[extQuery.id] = {
        name,
        source: integrationQuery.source,
      };
    }
    return extQueryDisplayById;
  },
);
