import { createSelector } from '@reduxjs/toolkit';
import { createCachedSelector } from 're-reselect';

import {
  SelectorWithLayerParam,
  addLayerParams,
  getCacheKeyForLayerSelector,
} from 'helpers/layerSelectorFactory';
import { DriverGroupId, DriverGroupIdOrNull } from 'reduxStore/models/driverGroup';
import { Driver } from 'reduxStore/models/drivers';
import { Submodel, SubmodelId } from 'reduxStore/models/submodels';
import {
  driversBySubmodelIdSelector,
  submodelsWithDefaultByIdSelector,
} from 'selectors/submodelSelector';
import { Selector } from 'types/redux';

export const navSubmodelIdSelector: Selector<SubmodelId | undefined> = (state) => {
  return state.page.type === 'submodelPage' ? state.page.id : undefined;
};

const navSubmodelSelector: SelectorWithLayerParam<Submodel | undefined> = createCachedSelector(
  navSubmodelIdSelector,
  addLayerParams(submodelsWithDefaultByIdSelector),
  (navSubmodelId, allSubmodels) =>
    navSubmodelId != null ? allSubmodels[navSubmodelId] : undefined,
)(getCacheKeyForLayerSelector);

const EMPTY_ARRAY: Driver[] = [];
export const driversForNavSubmodelSelector: Selector<Driver[]> = createSelector(
  navSubmodelSelector,
  driversBySubmodelIdSelector,
  (navSubmodel, driversBySubmodelId) => {
    return navSubmodel != null ? driversBySubmodelId[navSubmodel.id] ?? EMPTY_ARRAY : EMPTY_ARRAY;
  },
);

const EMPTY_DRIVER_GROUP_ID_ARRAY: DriverGroupId[] = [];
export const sortedDriverGroupIdsForNavSubmodelSelector: Selector<DriverGroupIdOrNull[]> =
  createSelector(navSubmodelSelector, (navSubmodel) => {
    return navSubmodel?.sortedDriverGroupIds ?? EMPTY_DRIVER_GROUP_ID_ARRAY;
  });
