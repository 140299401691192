import { createContext } from 'react';

import { EventEntity } from '@features/Plans/EventEntity';
import { OpaqueCellId } from 'components/CellSelectionManager/CellSelectionManagerContext';
import { ExtDriverColumnType } from 'config/extDriverTable';
import { ColumnLayerId, ModelViewColumnType } from 'config/modelView';
import { ObjectGridViewColumnType } from 'config/objectGridView';
import { ComparisonColumn, ComparisonTimePeriod, RollupType } from 'generated/graphql';
import { createRefContext } from 'helpers/createRefContext';
import { ExtSource } from 'helpers/integrations';
import { BlockId } from 'reduxStore/models/blocks';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import { DriverId } from 'reduxStore/models/drivers';
import { EventGroupId } from 'reduxStore/models/events';
import { ExtDriverId } from 'reduxStore/models/extDrivers';
import { LayerId } from 'reduxStore/models/layers';
import { CalculationError } from 'types/dataset';
import { MonthKey } from 'types/datetime';

export const CELL_HEIGHT_IN_PX = 32;
export const CELL_HEIGHT_IN_PX_STR = `${CELL_HEIGHT_IN_PX}px`;
export const CELL_DATA_COLUMN_WIDTH_IN_PX = 120;
export const COLUMN_HEADER_CELL_HEIGHT_PX = CELL_HEIGHT_IN_PX;

export enum CellType {
  /**
   * Represents impacts (events).
   */
  Impact,
  /**
   * Represents a driver time series in the the impact inspector.
   */
  ImpactDriver,
  /**
   * Represents a cell in a Plan / Event Group row.
   */
  EventGroup,
  /**
   * Represents a driver time series series.
   */
  Driver,
  /**
   * Represents an object field in the inspector.
   */
  ObjectFieldTimeSeries,
  /**
   * Represents the selection of a database row cell (for
   * instance, in the details pane "direct inputs")
   */
  DatabaseTrace,
  /**
   * Represents the selection of an object instance in a database (for
   * instance, in the details pane "direct inputs")
   */
  ObjectTrace,
  /**
   * Represents an object field in the object table
   */
  ObjectField,
  /**
   * Represents an ExtDriver time series
   */
  ExtDriver,
  /**
   * Represents a cell from pasted data
   */
  PasteData,
  /**
   * Represents a column from a SQL row generated by an integration Query
   */
  SqlResultCell,
  /**
   * Represents a block header cell
   */
  BlockHeaderCell,
}

export type MonthColumnKey = {
  monthKey: MonthKey;
  rollupType: RollupType;
  comparisonColumn?: ComparisonColumn;
  layerId?: string;
};

export type BusinessObjectFieldSpecColumnKey = {
  objectFieldSpecId: BusinessObjectFieldSpecId;
};

export type BusinessObjectFieldMonthColumnKey = MonthColumnKey & BusinessObjectFieldSpecColumnKey;

export type StickyColumnKey = {
  columnType: ModelViewColumnType | ExtDriverColumnType | ObjectGridViewColumnType;
  columnLayerId: ColumnLayerId;
};

type FormulaColumnKey = {
  columnType: 'formula' | 'actualsFormula';
};

export const ACTUALS_FORMULA_COLUMN_KEY: FormulaColumnKey = {
  columnType: 'actualsFormula',
};

export const FORECAST_FORMULA_COLUMN_KEY: FormulaColumnKey = {
  columnType: 'formula',
};

export type NameColumnKey = {
  columnType: 'name';
  columnLayerId: undefined;
};

type SqlResultColumnKey = {
  sqlColumn: string;
};

export const OBJECT_FIELD_NAME_COLUMN_KEY: NameColumnKey = {
  columnType: 'name',
  columnLayerId: undefined,
};

type OptionsColumnKey = {
  columnType: 'options';
};

export const OBJECT_FIELD_OPTIONS_COLUMN_KEY: OptionsColumnKey = {
  columnType: 'options',
};

type AddNewColumnColumnKey = {
  columnType: 'addNewColumn';
};

export const OBJECT_FIELD_ADD_NEW_COLUMN_COLUMN_KEY: AddNewColumnColumnKey = {
  columnType: 'addNewColumn',
};

export type DriverRowKey = {
  driverId: DriverId | undefined;
  layerId: LayerId | undefined;
  groupId: DriverGroupId | undefined;
  subDriverId?: DriverId | undefined;
  comparisonType?: ComparisonColumn;
  comparisonTimePeriod?: ComparisonTimePeriod;
};

export type ObjectFieldRowKey = {
  objectId: BusinessObjectId | null;
  groupKey: string;
};

export type ObjectFieldTimeSeriesRowKey = {
  objectId: BusinessObjectId;
  // fieldSpecId is undefined for the "Add property" button in the Database Grid view
  fieldSpecId: BusinessObjectFieldSpecId | undefined;
  layerId: LayerId | undefined;
};

export type CellRef = {
  disableClearing?: boolean; // whether or not the backspace/delete key can clear the cells
} & (
  | {
      type: CellType.Driver;
      rowKey: DriverRowKey;
      columnKey: MonthColumnKey | StickyColumnKey | FormulaColumnKey;
    }
  | {
      type: CellType.ObjectFieldTimeSeries;
      rowKey: {
        objectId: BusinessObjectId;
        // fieldSpecId is undefined for the "Add property" button in the Database Grid view
        fieldSpecId: BusinessObjectFieldSpecId | undefined;
        layerId: LayerId | undefined;
      };
      columnKey: MonthColumnKey | StickyColumnKey;
    }
  | {
      type: CellType.DatabaseTrace;
      rowKey: {
        specId: BusinessObjectId;
        fieldSpecId: BusinessObjectFieldSpecId | undefined;
      };
      columnKey: MonthColumnKey | StickyColumnKey;
    }
  | {
      type: CellType.ObjectTrace;
      rowKey: {
        objectId: BusinessObjectId;
        specId: BusinessObjectId;
        fieldSpecId: BusinessObjectFieldSpecId | undefined;
      };
      columnKey: MonthColumnKey | StickyColumnKey;
    }
  | {
      type: CellType.ObjectField;
      rowKey: ObjectFieldRowKey;
      // Can be a month key due to the fact that we can display a column as a
      // time series on the object table.
      //
      // NOTE: We are not using CellType.ObjectFieldTimeSeries in those cases
      // because that assumes that the rows are fields of object instances as
      // opposed to object instances.
      columnKey:
        | BusinessObjectFieldSpecColumnKey
        | NameColumnKey
        | AddNewColumnColumnKey
        | OptionsColumnKey
        | BusinessObjectFieldMonthColumnKey;
    }
  | {
      type: CellType.ImpactDriver;
      rowKey: {
        driverId: DriverId;
      };
      columnKey: MonthColumnKey;
    }
  | {
      type: CellType.Impact;
      rowKey: EventEntity;
      columnKey: MonthColumnKey;
    }
  | {
      type: CellType.EventGroup;
      rowKey: {
        eventGroupId: EventGroupId;
      };
      columnKey: MonthColumnKey;
    }
  | {
      type: CellType.ExtDriver;
      rowKey: {
        // N.B. these may be path keys if not all parts of an ext driver's path are defined as drivers
        extDriverId: ExtDriverId;
      };
      columnKey: StickyColumnKey | MonthColumnKey;
    }
  | {
      type: CellType.SqlResultCell;
      rowKey: {
        rowIndex: number;
      };
      columnKey: SqlResultColumnKey;
    }
  | {
      type: CellType.BlockHeaderCell;
      rowKey: {
        groupId: DriverGroupId | undefined;
      };
      columnKey: {
        columnType: ModelViewColumnType | MonthKey;
      };
    }
);

export type CellRowKey = CellRef['rowKey'];
export type CellColumnKey = CellRef['columnKey'];

export type CellSelection<T extends CellRef = CellRef> = {
  type: 'cell';
  // a null block ID indicates a selection in a popover
  blockId: BlockId | null;
  activeCell: T & {
    // This means the cell has been "double-clicked" into and user can type into cell
    isEditing?: boolean;
  };
  selectedCells: T[];
  isTracing?: boolean;
  // Selection was made indirectly (e.g. the result of a paste)
  isBackground?: boolean;
  isDragging?: boolean;
};

export type CopiedCells = Pick<CellSelection, 'selectedCells' | 'blockId'> | null;

export type BusinessObjectFieldCellRef = CellRef & { type: CellType.ObjectField };
export type BusinessObjectPropertyFieldCellRef = BusinessObjectFieldCellRef & {
  columnKey: BusinessObjectFieldSpecColumnKey;
};

export type BusinessObjectTimeSeriesCellRef = CellRef & {
  type: CellType.ObjectFieldTimeSeries;
};

export type DatabaseTraceTimeSeriesCellRef = CellRef & {
  type: CellType.DatabaseTrace;
};

export type ObjectTraceTimeSeriesCellRef = CellRef & {
  type: CellType.ObjectTrace;
};

export type ImpactDriverCellRef = CellRef & { type: CellType.ImpactDriver };
export type ImpactCellRef = CellRef & { type: CellType.Impact };
export type EventGroupCellRef = CellRef & { type: CellType.EventGroup };
export type DriverCellRef = CellRef & { type: CellType.Driver };
export type DriverCellNameRef = CellRef & { type: CellType.Driver } & MonthColumnKey;
export type BlockHeaderCellRef = CellRef & { type: CellType.BlockHeaderCell };

export type DriverDataCellRef = Pick<DriverCellRef, 'type' | 'rowKey'> & {
  columnKey: MonthColumnKey;
};
export type ExtDriverCellRef = CellRef & { type: CellType.ExtDriver };
export type SqlCellRef = CellRef & { type: CellType.SqlResultCell };
/**
 * This is isn't a great pattern, but it works fine for a performance-senstive drag use case
 * that would otherwise require re-rendering all the cells
 */
export const [useIsDraggingToSelectCellsRef, CellDraggingContextProvider] = createRefContext<{
  isDraggingToSelect: boolean;
  isMouseDown: boolean;
  hasMouseLeftMouseDownCell: boolean;
  isMouseUpEventListenerAdded: boolean;
}>({
  isDraggingToSelect: false,
  isMouseDown: false,
  hasMouseLeftMouseDownCell: true,
  isMouseUpEventListenerAdded: false,
});

export type CellSelectionState = {
  isAboveCellSelected: boolean;
  isActive: boolean;
  isBelowCellSelected: boolean;
  isCopied: boolean;
  isHighlighted: boolean;
  isLeftCellSelected: boolean;
  isPopoverReferenceCell: boolean;
  isRightCellSelected: boolean;
  isSelected: boolean;
  isBackgroundSelected: boolean;
};

export const DEFAULT_CELL_SELECTION_STATE: CellSelectionState = {
  isAboveCellSelected: false,
  isActive: false,
  isBelowCellSelected: false,
  isCopied: false,
  isHighlighted: false,
  isLeftCellSelected: false,
  isPopoverReferenceCell: false,
  isRightCellSelected: false,
  isSelected: false,
  isBackgroundSelected: false,
};

export const CellSelectionStateContext = createContext<CellSelectionState>(
  DEFAULT_CELL_SELECTION_STATE,
);

CellSelectionStateContext.displayName = 'CellSelectionStateContext';

export const CellRefContext = createContext<{ cellRef: CellRef; cellId: OpaqueCellId } | null>(
  null,
);

export type CellValueCalcErrTooltip = {
  type: 'driver_calc_error';
  error: CalculationError;
  detailDriverId: string;
};

type CellValueExtSourceTooltip = {
  type: 'ext_source';
  extSource: ExtSource;
};

type CellValueSimpleMsgTooltip = {
  type: 'simple_msg';
  msg: string;
};

// Structured data used to display a tooltip that wraps a cell value. The case
// of "simple_msg" is a fallback that just renders a string in a Tooltip. The
// other cases are used to render components that are a bit more structured.
export type CellValueTooltipData = {
  content: CellValueCalcErrTooltip | CellValueExtSourceTooltip | CellValueSimpleMsgTooltip;
  hasUnderline?: boolean;
};

type CellValueState = {
  valueTooltip?: CellValueTooltipData;
  extSource?: ExtSource;
  justEdited: boolean;
  clearJustEdited: () => void;
  markJustEdited: () => void;
};

export const CellValueContext = createContext<CellValueState>({
  justEdited: false,
  clearJustEdited: () => {},
  markJustEdited: () => {},
});

type CellStyleState = {
  defaultBgColor: string;
  definedBgColor: string | undefined;
};

export const CellStyleContext = createContext<CellStyleState>({
  defaultBgColor: 'white',
  definedBgColor: undefined,
});
