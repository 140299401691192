import {
  Center,
  Circle,
  Flex,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import React, { useCallback } from 'react';

import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import SelectMenu, { Section, SelectItem } from 'components/SelectMenu/SelectMenu';
import SelectMenuContainer from 'components/SelectMenuContainer/SelectMenuContainer';
import { stopEventPropagation } from 'helpers/browserEvent';
import { ColorLocation, formatColorForUsage } from 'helpers/color';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { COLOR_SECTION_ID, useColorSelectMenuItem } from 'hooks/useColorSelectMenuItem';
import useControlledPopover from 'hooks/useControlledPopover';
import { updateCellSelectionDriverColors } from 'reduxStore/actions/driverMutations';
import { setCellPaletteState } from 'reduxStore/reducers/pageSlice';
import { uniqueDriverCellSelectionColorsSelector } from 'selectors/driversSelector';
import ArrowIcon from 'vectors/Arrowhead';

const SECTIONS: Section[] = [
  {
    id: COLOR_SECTION_ID,
    name: 'Background Color',
  },
];

function getBgColorFromUniqueCellColors(uniqueColors: Set<string | null>): string {
  const colors = Array.from(uniqueColors);
  const bgColor = colors.length === 1 ? colors[0] : undefined;

  return bgColor ?? 'white';
}

const ColorPicker: React.FC = () => {
  const dispatch = useAppDispatch();

  const uniqueCellColors = useAppSelector(uniqueDriverCellSelectionColorsSelector);
  const bgColor = getBgColorFromUniqueCellColors(uniqueCellColors);
  const displayColor = formatColorForUsage(bgColor, ColorLocation.Accessory);

  const { isOpen, onOpen, onClose, contentRef, triggerRef } = useControlledPopover();

  const items = useColorSelectMenuItem({ bgColor, showHashtag: true });

  const onSelect = useCallback(
    (item: SelectItem) => {
      dispatch(updateCellSelectionDriverColors({ color: item.id }));
      onClose();
    },
    [dispatch, onClose],
  );

  const onOpenCallback = useCallback(() => {
    dispatch(setCellPaletteState('color'));
    onOpen();
  }, [dispatch, onOpen]);

  return (
    <Flex onClick={stopEventPropagation}>
      <Popover
        isLazy
        placement="bottom-start"
        returnFocusOnClose={false}
        closeOnBlur
        isOpen={isOpen}
        onOpen={onOpenCallback}
        onClose={onClose}
      >
        <PopoverTrigger>
          <Flex ref={triggerRef} zIndex="modal" alignItems="center">
            <IconButton
              icon={
                <>
                  <Circle size="4" bg={displayColor} border="px" color="gray.300" />
                  <Center width={4} height={4}>
                    <ArrowIcon direction="down" boxSize={2} color="gray.500" />
                  </Center>
                </>
              }
              variant="text"
              size="md"
              aria-label="Color picker button"
              p="4px"
              borderRadius="md"
              _hover={{
                bgColor: 'gray.300',
              }}
            />
          </Flex>
        </PopoverTrigger>
        <Portal>
          <PopoverContent ref={contentRef} padding={0} boxShadow="none">
            <SelectMenuContainer>
              <SelectMenu
                items={items}
                onSelect={onSelect}
                onClose={onClose}
                width="16rem"
                sections={SECTIONS}
              >
                {BaseSelectMenuItem}
              </SelectMenu>
            </SelectMenuContainer>
          </PopoverContent>
        </Portal>
      </Popover>
    </Flex>
  );
};

export default React.memo(ColorPicker);
