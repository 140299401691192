import { DateTime } from 'luxon';

import { ComparisonTimePeriod } from 'generated/graphql';

/**
 * For a given time period and month key (shifted according to the time period), returns the equivalent month key corresponding to the baseline period (current).
 * i.e. 2021-02 + previous-period (shifted by 1 month) => 2021-03 (header column month key)
 */
export function getCurrentPeriodMonthKeyFromTimePeriodMonthKey(
  monthKey: string,
  timePeriod: ComparisonTimePeriod,
): string {
  const monthKeyDatetime = DateTime.fromISO(monthKey);
  let equivalentMonth = monthKeyDatetime;
  switch (timePeriod) {
    case ComparisonTimePeriod.PreviousPeriod:
      equivalentMonth = monthKeyDatetime.plus({ months: 1 });
      break;
    case ComparisonTimePeriod.ThreeMonthsAgo:
      equivalentMonth = monthKeyDatetime.plus({ months: 3 });
      break;
    case ComparisonTimePeriod.SixMonthsAgo:
      equivalentMonth = monthKeyDatetime.plus({ months: 6 });
      break;
    case ComparisonTimePeriod.TwelveMonthsAgo:
      equivalentMonth = monthKeyDatetime.plus({ months: 12 });
      break;
    default:
      equivalentMonth = monthKeyDatetime;
  }
  return equivalentMonth.toFormat('yyyy-MM');
}

export function getDateRangeForTimePeriod(
  timePeriod: ComparisonTimePeriod,
  start: DateTime,
  end: DateTime,
) {
  switch (timePeriod) {
    case ComparisonTimePeriod.PreviousPeriod:
      return [start.minus({ months: 1 }), end.minus({ months: 1 })];
    case ComparisonTimePeriod.ThreeMonthsAgo:
      return [start.minus({ months: 3 }), end.minus({ months: 3 })];
    case ComparisonTimePeriod.SixMonthsAgo:
      return [start.minus({ months: 6 }), end.minus({ months: 6 })];
    case ComparisonTimePeriod.TwelveMonthsAgo:
      return [start.minus({ months: 12 }), end.minus({ months: 12 })];
    case ComparisonTimePeriod.CurrentPeriod:
    default:
      return [start, end];
  }
}
